import httpClient from './httpClient';
import { toCustomerView } from '@commonServices/utils/converter';

export default {
	// #region requests

	/**
	 *
	 * @param {String} searchTerms value to search against
	 */
	getCustomerSearchResult (searchTerms, paging) {
		return httpClient(`/api/claims/customer?searchTerms=${searchTerms}&${paging.toQuery()}`)
			.get();
	},
	/**
	 *
	 * @param {Number} customerId Identificator of customer for which details is requsted
	 */
	getCustomerInformation (customerId) {
		return httpClient(`/api/claims/customer/${customerId}`)
			.get()
			.then(toCustomerView);
	},

	getRecentlyViewedCustomers () {
		return httpClient('/api/claims/customer/recently-viewed')
			.get();
	},

	getCareFlags (customerId) {
		return httpClient(`/api/claims/customer/${customerId}/care-flags`)
			.get();
	},

	updateCareFlags (id, careFlags) {
		return httpClient(`/api/claims/customer/${id}/care-flags`)
			.put({ careFlags });
	},

	// #endregion

	// #region logic

	toCustomerFullName ({ title, firstName, lastName }) {
		return [title || '',
			firstName,
			lastName]
			.join(' ');
	},

	// #endregion
};
