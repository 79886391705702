import WorkQueueModel from '@commonServices/models/WorkQueueModel';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';

export default class ClaimToChaseModel extends WorkQueueModel {
	constructor (
		brandId,
		brandName,
		brandLocale,
		claimId,
		claimStatus,
		customerFirstName,
		customerLastName,
		customerId,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		vets,
		claimAmount,
		ailments,
		missingInformation,
		chased,
		chaseCount,
		requestedBy,
		dateRequested,
		chaseLimit,
		policyStatus,
		policies,
		policyStatusDisplay,
		assignedTo,
		formType,
		latestDashboardNote,
		latestInteraction,
	) {
		super(
			brandId,
			brandName,
			brandLocale,
			claimId,
			claimStatus,
			customerFirstName,
			customerLastName,
			customerId,
			petId,
			petName,
			petType,
			petGender,
			petDateOfDeath,
			vets,
			claimAmount,
			ailments,
			requestedBy,
			dateRequested,
			policyStatus,
			policies,
			policyStatusDisplay,
			assignedTo,
			WorkQueueType.Chasing,
			latestDashboardNote,
			latestInteraction,
		);

		this.missingInformation = missingInformation;
		this.chased = chased;
		this.chaseCount = chaseCount;
		this.chaseLimit = chaseLimit;
		this.formType = formType;
	}
}
