<template>
	<div class="d-flex align-items-center aq-page-control">
		<div data-qa="aqPageControl_text_pageCount">
			<span>Displaying {{ rangeStart }}-{{ rangeEnd }} of {{ total }} {{ label | pluralize(total) }}</span>
		</div>
		<div class="ml-20">
			<button
				class="btn btn-row btn-sq mr-6"
				data-qa="aqPageControl_button_back"
				:disabled="backDisabled"
				@click="goBack"
			>
				<i :class="['fas fs-16', vertical ? 'fa-caret-up' : 'fa-caret-left']" />
			</button>
			<button
				class="btn btn-row btn-sq"
				data-qa="aqPageControl_button_forward"
				:disabled="forwardDisabled"
				@click="goForward"
			>
				<i :class="['fas fs-16', vertical ? 'fa-caret-down' : 'fa-caret-right']" />
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		vertical: {
			type: Boolean,
			required: false,
			default: true,
		},
		label: {
			type: String,
			required: false,
			default () { return this.vertical ? 'row' : 'column'; },
		},
		pageSize: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
	},
	data () {
		return {
			currentPage: 1,
		};
	},
	watch: {
		currentPage (page) {
			this.$emit('page-changed', page);
		},
		total () {
			this.currentPage = 1;
		},
	},
	methods: {
		goBack () {
			if (this.currentPage !== 1) {
				this.currentPage--;
			}
		},
		goForward () {
			if (this.currentPage !== this.pageCount) {
				this.currentPage++;
			}
		},
	},
	computed: {
		pageCount () { return Math.ceil(this.total / this.pageSize); },
		rangeStart () { return (this.currentPage - 1) * this.pageSize + 1; },
		rangeEnd () { return Math.min(this.currentPage * this.pageSize, this.total); },
		backDisabled () { return this.currentPage === 1; },
		forwardDisabled () { return this.currentPage === this.pageCount; },
	},
};
</script>
