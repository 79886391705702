<template>
	<aq-sandwich
		:header="header"
		:sub-header="$options.subHeader"
		ref="sandwich"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template slot="content">
			<div class="section-content flex-fill">
				<div class="container-fluid">
					<payee-capture
						:selected-payees="selectedPayees"
						:is-read-only-mode="isReadonly"
						:validator="$v"
						:ui-locale-settings="uiLocaleSettings"
						@input="onInputPayees"
					/>
				</div>
			</div>
			<div
				v-if="!isReadonly"
				class="section-footer mb-2"
				style="position: relative;"
			>
				<div class="container-fluid">
					<button
						class="btn btn-primary btn-radius--less mr-0 ml-auto d-block "
						@click="onProceed()"
					>
						Proceed
					</button>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import { PayeeType } from '@commonServices/utils/payeeOptions';
import PayeeCapture from '@commonView/ClaimPage/ClaimEnter/PayeeCapture';
import { helpers, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';

export default {
	name: 'ClaimPayeeScan',
	components: {
		PayeeCapture,
	},
	props: {
		recognizedData: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
		header: {
			type: String,
			required: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			selectedPayees: [],
			payeeInformation: {
				payPolicyHolder: {
					accuracy: this.recognizedData.payPolicyHolder?.confidence ?? 0,
					selected: false,
				},
				payVeterinaryHospital: {
					accuracy: this.recognizedData.payVeterinaryHospital?.confidence ?? 0,
					selected: false,
				},
			},
		};
	},
	mounted () {
		if (this.getOriginalOrDefaultIfNull(this.recognizedData.payPolicyHolder) ?? false) {
			this.selectedPayees.push({ id: PayeeType.PolicyHolder });
		}
		if (this.getOriginalOrDefaultIfNull(this.recognizedData.payVeterinaryHospital) ?? false) {
			this.selectedPayees.push({ id: PayeeType.Vet });
		}
	},
	computed: {
		...mapState(['appSettings']),
		isPayeesInvalid () {
			return this.$v.selectedPayees.$error;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	validations () {
		const requiredIfNotDraft = this.isDraft ? () => true : required;
		const payeeMustBeSelected = this.isDraft ? () => true : (payees) => !helpers.req(payees) || payees.length > 0;

		return {
			selectedPayees: {
				required: requiredIfNotDraft,
				payeeMustBeSelected,
			},
		};
	},
	subHeader: 'Select preferred payee type',
	methods: {
		onProceed () {
			if (!this.isPayeesInvalid) {
				this.$refs.sandwich.onToggle();
				this.payeeInformation.payPolicyHolder.selected = this.selectedPayees.some(item => item.id === PayeeType.PolicyHolder);
				this.payeeInformation.payVeterinaryHospital.selected = this.selectedPayees.some(item => item.id === PayeeType.Vet);
				this.$emit('passed', this.payeeInformation);
			}
		},
		getOriginalOrDefaultIfNull (property) {
			if (property === null) {
				return null;
			}
			return property.value ? property.value : property.originalValue;
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		highlight (field) {
			const { page, boundingBox } = field;
			this.$emit('focused', { page, boundingBox });
		},
		onInputPayees (value) {
			this.selectedPayees = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-radius--less {
  border-radius: 2px;
}
</style>
