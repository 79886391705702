<template>
	<div class="care-flag d-flex align-items-center">
		<CareIconFlag class="flag-icon" />
		<v-popover
			class="flag-container"
			trigger="hover"
			placement="top"
			offset="8"
		>
			<component
				class="flag-care-icon tooltip-target b3"
				:is="getCareFlagIcon"
			/>
			<p
				class="flag-counter"
				v-if="isMultiFlag"
				v-text="getCareFlagCount"
			/>
			<template slot="popover">
				<div
					v-if="isMultiFlag"
					class="d-flex p-5"
				>
					<div
						class="d-flex mr-8 align-items-center comma"
						:key="flag"
						v-for="flag in careFlags"
					>
						<component
							class="flag-icon-popover mr-5 w-100 h-100"
							:is="getCareFlagIconById(flag)"
						/>
						<span> {{ getCareFlagNameById(flag) }} </span>
					</div>
				</div>
				<div
					v-else
					class="py-3"
				>
					<span> {{ getCareFlagNameById(careFlags) }} </span>
				</div>
			</template>
		</v-popover>
	</div>
</template>
<script>
import { CareFlagEnumLabel, CareFlagEnumIcon } from '@commonServices/models/CareFlagEnum';
import { CareIconFlag, CareIconMultiFlag } from '@assets/icons';
export default {
	components: {
		CareIconFlag,
	},
	props: {
		careFlags: {
			type: Array,
			required: true,
		},
	},
	computed: {
		getCareFlagCount () {
			return this.careFlags.length;
		},
		getCareFlagIcon () {
			if (this.isMultiFlag) {
				return CareIconMultiFlag;
			}
			return CareFlagEnumIcon[this.careFlags];
		},
		isMultiFlag () {
			return this.careFlags.length > 1;
		},
	},
	methods: {
		getCareFlagIconById (flag) {
			return CareFlagEnumIcon[flag];
		},
		getCareFlagNameById (flag) {
			return CareFlagEnumLabel[flag];
		},
	},
};
</script>

<style lang="scss" scoped>
.care-flag {
  position: absolute;
  top: 0;
  right: 0;
  margin: -12.2px -12.4px 0;
  z-index: 5;
  pointer-events: none;

  .flag-icon {
    width: 100px;
  }

  .flag-container {
    position: absolute;
    left: 55px;
    top: 15px;
    width: 35px;
    height: 35px;
    padding: 0 3px;
    align-items: center;
    pointer-events: all;

    .flag-counter {
      position: absolute;
      font-size: 11px;
      left: 23px;
      top: 8px;
      color: $primary;
      font-weight: bold;
    }

    .flag-care-icon {
      width: 30px;
      height: 35px;
    }
  }
}

.flag-icon-popover {
  max-width: 12px;
  max-height: 15px;
}

span,
svg {
  color: $body-color;
}

svg {
  fill: $body-color;
}
</style>
<style lang="scss">
.comma:not(:last-child)::after {
  content: ", ";
  color: $body-color;
}
</style>
