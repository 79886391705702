<template>
	<div class="d-flex sorting-selector fs-24">
		<i
			class="fas fa-long-arrow-alt-down"
			:class="[direction === $options.sortingDirection.Descending ? 'text-primary' : 'text-light', { 'sort-disabled': disabled }]"
		/>
		<i
			class="fas fa-long-arrow-alt-up"
			:class="[direction === $options.sortingDirection.Ascending ? 'text-primary' : 'text-light', { 'sort-disabled': disabled }]"
		/>
	</div>
</template>

<script>
import SortingDirection from '@commonServices/models/SortingDirection';

export default {
	name: 'SortDirection',
	props: {
		direction: {
			type: Number,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	sortingDirection: SortingDirection,
};
</script>

<style lang="scss" scoped>
  .sorting-selector {
    &:hover {
      filter: brightness(120%);
    }

    i {
      transition: color 0.2s ease-out;
    }

    .fa-long-arrow-alt-down {
      transform: translate(15%, 25%) scale(0.85, 1);
    }

    .fa-long-arrow-alt-up {
      transform: translate(-15%, -25%) scale(0.85, 1);
    }

    .sort-disabled {
      color: var(--disabledText) !important;
      opacity: 0.3;
    }
  }
</style>
