import { TaskTypeTitles } from '@commonServices/models/WorkQueueType';

export default class TasksItemsModel {
	constructor (
		claimId,
		claimStatus,
		claimSource,
		customerId,
		customerFirstName,
		customerLastName,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		brandId,
		brandName,
		brandLocale,
		claimAmount,
		ailments,
		createdDate,
		reassessmentDateTime,
		policyStatus,
		policies,
		policyStatusDisplay,
		workQueueType,
		workQueueName,
		prioritizedWorkQueue,
		daysOpen,
		missingInformation,
		fraudCheckReasons,
		rejectionReason,
		payees,
		payeeTypes,
		chaseCount,
		chased,
		chaseLimit,
		canProceedPayment,
		isReassessment,
		autoAssigned,
		rejectionStatus,
		isFastTrackReviewed,
		missingInfoCompleted,
		latestDashboardNote,
		latestInteraction,
		formType,
		assignmentAcknowledgedDate,
	) {
		this.claimId = claimId;
		this.claimStatus = claimStatus;
		this.claimSource = claimSource;
		this.customerId = customerId;
		this.customerFirstName = customerFirstName;
		this.customerLastName = customerLastName;
		this.petId = petId;
		this.petName = petName;
		this.petType = petType;
		this.petGender = petGender;
		this.petDateOfDeath = petDateOfDeath;
		this.brandId = brandId;
		this.brandName = brandName;
		this.brandLocale = brandLocale;
		this.claimAmount = claimAmount;
		this.ailments = ailments;
		this.createdDate = createdDate;
		this.reassessmentDateTime = reassessmentDateTime;
		this.policyStatus = policyStatus;
		this.policies = policies;
		this.policyStatusDisplay = policyStatusDisplay;
		this.workQueueType = workQueueType;
		this.workQueueName = workQueueName;
		this.prioritizedWorkQueue = prioritizedWorkQueue;
		this.daysOpen = daysOpen;
		this.missingInformation = missingInformation;
		this.fraudCheckReasons = fraudCheckReasons;
		this.rejectionReason = rejectionReason;
		this.payees = payees;
		this.payeeTypes = payeeTypes;
		this.chaseCount = chaseCount;
		this.chased = chased;
		this.chaseLimit = chaseLimit;
		this.taskTypeTitle = TaskTypeTitles[workQueueType];
		this.canProceedPayment = canProceedPayment;
		this.isReassessment = isReassessment;
		this.autoAssigned = autoAssigned;
		this.rejectionStatus = rejectionStatus;
		this.isFastTrackReviewed = isFastTrackReviewed;
		this.missingInfoCompleted = missingInfoCompleted;
		this.latestDashboardNote = latestDashboardNote;
		this.latestInteraction = latestInteraction;
		this.formType = formType;
		this.assignmentAcknowledgedDate = assignmentAcknowledgedDate;
	}
}
