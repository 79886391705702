<template>
	<aq-header-toggler
		class="form-type-select"
		:title="title"
		:sub-title="value.description"
		:disabled="!editable"
	>
		<template #default="props">
			<div>
				<div
					class="form-type-select_option d-flex"
					v-for="claimFormType in claimFormTypeOptions"
					:key="claimFormType.id"
					@click="props.closeContent(); onSelected(claimFormType)"
				>
					<div class="form-type-select_option-icon">
						<i
							v-if="value.id === claimFormType.id"
							class="fas fa-check mr-10"
						/>
					</div>
					<div>
						{{ claimFormType.description }}
					</div>
				</div>
			</div>
		</template>
	</aq-header-toggler>
</template>

<script>
import { ClaimFormTypeOptions } from '@commonServices/models/ClaimFormType';

export default {
	name: 'ClaimFormTypeSelector',
	props: {
		editable: {
			type: Boolean,
			required: true,
		},
		value: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data () {
		return {
			claimFormTypeOptions: [...ClaimFormTypeOptions],
		};
	},
	computed: {
		title () {
			return `${this.editable ? 'Select ' : ''}Form Type`;
		},
	},
	methods: {
		onSelected (claimFormType) {
			this.$emit('input', { ...claimFormType });
		},
	},
};
</script>

<style lang="scss" scoped>
.form-type-select {
  height: 58px;
  flex: 0 0;
  background: rgb(0 0 0 / 20%);

  &_option {
    padding: 10px;
    padding-left: 30px;
    cursor: pointer;

    &-icon {
      flex-basis: 30px;
    }

    i {
      color: var(--cardIconColour);
    }

    &:hover {
      background-color: var(--searchBg);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--disabledText);
    }
  }

  ::v-deep p {
    color: var(--sectionTextColour) !important;
  }

  &.header-toggler {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.header-toggler.active {
    background: rgb(0 0 0 / 40%);
  }

  ::v-deep .header-toggler_icon {
    fill: var(--sectionTextColour);
  }

  ::v-deep .section-header__title {
    font-size: 13px;
    font-weight: lighter;
    margin-bottom: 2px;
  }

  ::v-deep .header-subtitle {
    font-size: 16px;
    font-weight: 500;
  }
}

</style>
