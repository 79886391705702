<template>
	<aq-table-popover
		class="d-flex"
		:disabled="!showPopup"
		:open="showPopup"
		:boundaries-element-selector="boundariesElementSelector"
		@hide="showPopup = false"
		popover-class="claim-invoices-details"
	>
		<div>
			<div class="section-title">
				Invoices
			</div>
			<div class="table-wrapper">
				<table>
					<tbody>
						<tr
							v-for="(invoice, index) in invoices"
							:key="index"
						>
							<td>
								<div
									class="claim-link text-truncate"
									@click="openInvoice(invoice)"
								>
									{{ invoice.invoiceNumber ? invoice.invoiceNumber : invoice.fileName }}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<template slot="title">
			<div
				class="claim-link text-truncate"
				@click="onInvoicesClick"
				v-tooltip="invoiceNumbers"
			>
				{{ invoiceNumbers }}
			</div>
		</template>
	</aq-table-popover>
</template>

<script>
import AqTablePopover from '@/clients/clientCommon/components/widgets/AqTablePopover';
import FileService from '@commonServices/fileService';
import { MimeTypes, getFileExtension } from '@commonServices/utils/general';

export default {
	name: 'ClaimInvoicesDetails',
	components: {
		AqTablePopover,
	},
	props: {
		invoices: {
			type: Array,
			required: false,
			default: () => [],
		},
		boundariesElementSelector: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			showPopup: false,
		};
	},
	computed: {
		invoiceNumbers () {
			return this.invoices.map(i => i.invoiceNumber ? i.invoiceNumber : i.fileName).join(', ');
		},
	},
	methods: {
		onInvoicesClick (e) {
			e.stopPropagation();
			if (this.invoices.length === 0) return;
			if (this.invoices.length > 1) {
				this.showPopup = true;
				return;
			}

			this.openInvoice(this.invoices[0]);
		},
		async openInvoice (invoice) {
			const invoiceUrl = await FileService.getTempSrc(invoice.fileId, MimeTypes[getFileExtension(invoice.fileName)]);
			window.open(invoiceUrl, invoice.fileId);
		},
	},
};
</script>

<style lang="scss">
.claim-invoices-details.table-popover {
  width: 320px;
  background-color: var(--contentBg);
  box-shadow: 0 3px 10px #0008;

  .wrapper {
    border: 3px solid var(--headerBg);
  }

  .section-title {
    background: var(--headerBg);
    color: var(--sectionTextColour);
    display: flex;
    padding-left: 10px;
    line-height: 34px;
  }

  .table-wrapper {
    padding: 5px;
  }

  table {
    width: 100%;
    border: none;
    table-layout: fixed;

    td {
      font-size: 13px;
      border: none;
    }

    tr {
      height: 25px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--tableBorderColor);
      }
    }
  }

  .tooltip-arrow {
    border-color: var(--headerBg);
  }
}
</style>

<style lang="scss" scoped>
::v-deep .claim-link {
  color: var(--bodyColour);

  &:hover {
    color: $primary-d-20;
  }
}
</style>
