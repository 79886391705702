<template>
	<button class="btn small">
		<i class="fa fa-search fa-rotate-90" />
	</button>
</template>

<script>
export default {
	name: 'AqSearchButton',
};
</script>

<style lang="scss" scoped>
i.fa.fa-search {
  font-size: 15px;
  position: relative;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.btn {
  height: 100%;
  border: none;

  &:focus {
    outline: none;
  }

  &:active {
    background: $active-color;
  }

  &.active {
    background: $primary !important;
  }
}
</style>
