export default class ClaimItemDeduction {
	constructor (
		deductionId,
		deductionType,
		originalLimit) {
		this.deductionId = deductionId;
		this.deductionType = deductionType;
		this.originalLimit = originalLimit;
	}
}
