<template>
	<div>
		<div class="section-content pt-10">
			<div class="container-fluid">
				<item-heading
					:heading="claimNumberStatusDisplayMap[claim.status]"
					heading-class="claim-status text-truncate"
					paragraph-class="text-truncate"
				>
					<template #besideHeadingArea>
						<i
							v-if="claim.isFastTrackReviewed"
							data-qa="aqClaimCard-icon-isFastTrackReviewed"
							class="aqv-fast-track highlight-icon fs-25 mt-2 ml-10"
							v-tooltip="'Fast track reviewed'"
						/>
						<i
							v-if="claim.missingInfoCompleted"
							data-qa="aqClaimCard-icon-missingInfoCompleted"

							class="aqv-missing-info-complete highlight-icon mt-2 ml-10 fs-25"
							v-tooltip="'Missing Info Complete'"
						/>
					</template>
					<template #underHeadingArea>
						<div
							data-qa="aqClaimCard-text-claimId"
							class="d-inline-flex fs-16"
						>
							<div class="font-weight-bold mr-3">
								Claim ID:
							</div>
							{{ claim.id | claimIdFormat }}
						</div>
					</template>
					<template #rightArea>
						<div class="logo">
							<component
								v-if="statusIcon"
								:is="statusIcon.element"
								:class="statusIcon.class"
							/>
						</div>
					</template>
				</item-heading>
				<div
					data-qa="aqClaimCard-attributes"
					class="attributes d-inline-flex"
				>
					<div class="task-attribute d-flex col1 mr-3">
						<div class="text-left text-truncate">
							<div class="attribute-title">
								Ailment
							</div>
							<div
								class="text-truncate"
								v-tooltip.top-start="ailments || ''"
							>
								{{ ailments || 'No ailment' }}
							</div>
						</div>
					</div>
					<div class="task-attribute d-flex col2 mr-3">
						<div class="text-left text-truncate">
							<div class="attribute-title">
								Amount
							</div>
							<div class="text-truncate">
								{{ claim.amount | currency(claim.brand.locale) }}
							</div>
						</div>
					</div>
					<div class="task-attribute d-flex mr-0 col3">
						<div class="text-left text-truncate">
							<div class="attribute-title">
								Claim Date
							</div>
							<div class="text-truncate">
								{{ claim.createdDate | shortDate }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section-footer">
			<div class="container-fluid d-flex align-items-center">
				<aq-brand-image-avatar
					class="mr-4"
					:brand-name="claim.brand.name"
					:brand-id="claim.brand.id"
				/>
				<router-link
					data-qa="aqClaimCard-icon-viewCustomer"
					class="mr-4"
					:to="{ name: 'customer', params: { customerId: claim.customer.id } }"
				>
					<aq-icon-avatar
						class="small cursor-pointer"
						:value="[claim.customer.firstName, claim.customer.lastName]"
					/>
				</router-link>
				<router-link
					data-qa="aqClaimCard-icon-viewPet"
					:to="{ name: 'pet', params: { customerId: claim.customer.id, petId: claim.pet.id } }"
				>
					<aq-pet-avatar
						class="small cursor-pointer"
						:pet="{
							name: claim.pet.name,
							type: claim.pet.type,
							gender: claim.pet.gender,
							dateOfDeath: claim.pet.dateOfDeath
						}"
					/>
				</router-link>
				<router-link
					data-qa="aqClaimCard-button-view"
					class="ml-auto"
					:to="{ name: 'pet', params: { customerId: claim.customer.id, petId: claim.pet.id, claimId: claim.id } }"
				>
					<div class="btn btn-primary">
						View
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { claimNumberStatus, claimNumberStatusDisplayMap, rejectedclaimStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import { claimIdFormat } from '@commonServices/utils/filters';
import {
	DraftClaimsLogo,
	FraudCheckLogo,
	FraudCheckFailedLogo,
	FraudCheckOkLogo,
	ChasingLogo,
	RejectionLogo,
	ClosedPaidClaimsLogo,
	RejectedClaimsLogo,
	SettledClaimsLogo,
	ReassessClaimsLogo,
} from '@assets/icons';

const claimStatusIcon = {
	[claimNumberStatus.Draft]: { element: 'DraftClaimsLogo' },
	[claimNumberStatus.FraudCheck]: { element: 'FraudCheckLogo' },
	[claimNumberStatus.FraudCheckFailed]: { element: 'FraudCheckFailedLogo' },
	[claimNumberStatus.FraudCheckOk]: { element: 'FraudCheckOkLogo' },
	[claimNumberStatus.RejectionPending]: { element: 'RejectionLogo' },
	[claimNumberStatus.ClosedPaid]: { element: 'ClosedPaidClaimsLogo' },
	[claimNumberStatus.Settled]: { element: 'SettledClaimsLogo' },
	[claimNumberStatus.MissingInfo]: { element: 'ChasingLogo' },
	[claimNumberStatus.ReassessmentPending]: { element: 'ReassessClaimsLogo' },
	[claimNumberStatus.PreAuthorisationCompleted]: { element: 'div', class: 'aqv-pre-authorisation' },
};

export default {
	name: 'AqClaimCard',
	components: {
		DraftClaimsLogo,
		FraudCheckLogo,
		FraudCheckFailedLogo,
		FraudCheckOkLogo,
		ChasingLogo,
		RejectionLogo,
		ClosedPaidClaimsLogo,
		RejectedClaimsLogo,
		SettledClaimsLogo,
		ReassessClaimsLogo,
	},
	props: {
		claim: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			claimNumberStatusDisplayMap,
			claimNumberStatus,
		};
	},
	methods: {
		claimIdFormat,
		isRejectedStatus (status) {
			return rejectedclaimStatuses.some(s => status === s);
		},
	},
	computed: {
		ailments () {
			if (this.claim.conditions) {
				return this.claim.conditions.map(condition => condition.ailment?.firstCause)
					.filter(ailment => !!ailment)
					.join('; ');
			}

			return '';
		},
		statusIcon () {
			if (this.isRejectedStatus(this.claim.status)) {
				return { element: 'RejectedClaimsLogo' };
			}

			return claimStatusIcon[this.claim.status];
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .claim-status {
  font-size: 25px;
  letter-spacing: -0.02em;
  font-weight: bold;
  margin-bottom: 3px;
}

.section-footer {
  padding-top: 7px;
  padding-bottom: 7px;
}

.highlight-icon:hover {
  color: var(--cardIconColour);
}

.attributes {
  width: inherit;
}

.logo {
  color: var(--cardIconSVG);
  height: 60px;
  margin-left: auto;

  .aqv-pre-authorisation {
    height: 60px;
    width: 76px;
    font-size: 60px;
  }
}

.col1 {
  width: 38%;
}

.col2 {
  width: 27%;
}

.col3 {
  width: 35%;
}
</style>
