import httpClient from '@commonServices/httpClient';
import Interaction from '@commonServices/models/Interaction';
import {
	interactionDetailType,
	interactionType,
	isUploadedDocumentAction,
	outCommunicationsDisplayMap as outCommunications,
} from '@commonServices/models/InteractionTypeEnum';
import { claimNumberStatus } from '@commonServices/models/ClaimStatusActionEnum';
import { AllDateTypes, fromStringToDate } from '@commonServices/utils/dateUtils';
import { communicationType } from '@commonServices/models/CommunicationType';

function toInteractions ({ result, ...paging }) {
	return {
		...paging,
		items: result.map(toInteraction),
	};
}

export function toInteraction (interaction) {
	const value = specifyInteractionValue(interaction);
	const description = specifyInteractionDescription(interaction);
	const iconType = specifyInteractionIconType(interaction);
	const fileName = specifyInteractionFileName(interaction);
	const arrowDirectionIcon = specifyArrowDirectionIcon(interaction);
	const details = specifyDetails(interaction);
	const commDetails = specifyCommDetails(interaction);
	const commType = specifyCommType(interaction);
	const attachments = Object.entries(interaction.attachments).map(([attachmentId, attachmentName]) => ({ attachmentId, attachmentName }));
	const vetName = specifyInteractionVetName(interaction);
	return new Interaction(
		interaction.id,
		interaction.claimId,
		interaction.actionType,
		new Date(interaction.dateTime),
		interaction.user,
		value,
		description,
		interaction.pet,
		interaction.cause,
		interaction.fileId,
		fileName,
		details,
		iconType,
		arrowDirectionIcon,
		interaction.linkedClaims,
		commDetails,
		commType,
		attachments,
		interaction.externalDocUrl,
		interaction.comment,
		vetName,
	);
}

function specifyDetails ({ details }) {
	if (!details.length) {
		return null;
	}

	const adjusterComment = details.find(({ type }) => type === interactionDetailType.AdjusterComment)?.comment;

	const rejectionDetails = details.filter(({ type }) => type === interactionDetailType.Rejection);
	if (rejectionDetails.length) {
		return {
			detailsName: 'Rejection Details',
			adjusterComment,
			data: rejectionDetails.map(detail => {
				return {
					item1: detail.rejectionReason,
					item2: detail.rejectionComment,
				};
			}),
		};
	}

	const missingInfoDetails = details.filter(({ type }) => type === interactionDetailType.MissingInfo);
	if (missingInfoDetails.length) {
		return {
			detailsName: 'Missing Items',
			data: missingInfoDetails.map(detail => {
				return {
					missingInfoDescription: detail.missingInformation,
					contactName: detail.recipient,
					contactType: detail.recipientType,
					missingInfoComment: detail.missingInformationComment,
					resolved: detail.resolved,
				};
			}),
		};
	}

	const fraudCheckDetails = details.filter(({ type }) => type === interactionDetailType.FraudCheck);
	if (fraudCheckDetails.length) {
		return {
			detailsName: 'Fraud Check Flags',
			data: fraudCheckDetails.map(detail => {
				return {
					item1: detail.fraudCheckDescription,
				};
			}),
		};
	}

	const claimDetails = details.filter(({ type }) => type === interactionDetailType.Claim || type === interactionDetailType.HospitalClaim);
	if (claimDetails.length) {
		return {
			detailsName: claimDetails[0].type === interactionDetailType.Claim ? 'Online Claim Submission' : 'Hospital Claim Submission',
			data: claimDetails.map(detail => {
				return {
					claimAmount: detail.claimAmount,
					dateofLoss: fromStringToDate(detail.dateOfLoss),
					treatmentFrom: fromStringToDate(detail.treatmentFrom),
					treatmentTo: fromStringToDate(detail.treatmentTo),
					vetName: detail.vetName,
					vetAddress: detail.vetAddress,
					documentsUploaded: detail.documentsUploaded,
					dateOfDeath: fromStringToDate(detail.dateOfDeath),
					paymentTarget: detail.paymentTarget,
					ailment: detail.ailment,
					continuation: detail.continuation,
					preAuthOrClaim: detail.preAuthOrClaim,
					conditionType: detail.conditionType,
					paymentMethod: detail.paymentMethod,
				};
			}),
		};
	}

	const paymentDetails = details.filter(({ type }) => type === interactionDetailType.Payment);
	if (paymentDetails.length) {
		return {
			detailsName: 'Ex-Gratia Payment Comment',
			data: paymentDetails.map(detail => {
				return {
					item1: detail.comment,
					item2: detail.amount,
				};
			}),
		};
	}

	if (adjusterComment) {
		return {
			detailsName: 'Interaction Details',
			adjusterComment,
			data: [],
		};
	}

	const petDetails = details.filter(({ type }) => type === interactionDetailType.PreExistingCondition);
	if (petDetails.length) {
		return {
			detailsName: 'Pre-existing condition changes',
			data: petDetails.map(detail => {
				return {
					item1: detail.details,
				};
			}),
		};
	}

	return null;
}

function specifyCommType ({ actionType }) {
	switch (actionType) {
	case interactionType.ClaimFormDocument:
	case interactionType.InvoiceDocument:
	case interactionType.MedicalHistoryDocument:
	case interactionType.DisputeDocument:
	case interactionType.DeclarationPageDocument:
	case interactionType.ProofOfSecondaryInsuranceDocument:
	case interactionType.MedicalRecordSummaryDocument:
	case interactionType.OtherDocument:
	case interactionType.EditedDocument:
	case interactionType.DeletedDocument:
		return communicationType.Document;
	case interactionType.InboundEmailDocument:
		return communicationType.InboundEmail;
	case interactionType.GeneratedWaitPeriodLetter:
	case interactionType.GeneratedMissingInfoLetter:
	case interactionType.GeneratedNoLimitLetter:
	case interactionType.GeneratedSettlementLetter:
	case interactionType.GeneratedReassessmentLetter:
	case interactionType.GeneratedRejectedManuallyLetter:
	case interactionType.GeneratedChaseLetter:
	case interactionType.GeneratedRejectedPreExistingConditionLetter:
	case interactionType.GeneratedRejectedNoActiveCoverPeriodLetter:
	case interactionType.GeneratedPreAuthorisationCompletedLetter:
	case interactionType.GeneratedMedicalRecordsSummaryLetter:
	case interactionType.GeneratedGreyMedicalRecordsSummaryLetter:
	case interactionType.GeneratedRejectedNoProductCoverageLetter:
	case interactionType.GeneratedRejectedMultipleLetter:
	case interactionType.GeneratedOpenClaimReminderLetter:
	case interactionType.GeneratedDuplicateClaimRejectionLetter:
	case interactionType.GeneratedDuplicateInvoiceRejectionLetter:
		return communicationType.Letter;
	case interactionType.GeneratedChaseEmail:
	case interactionType.GeneratedWaitPeriodEmail:
	case interactionType.GeneratedAcknowledgementEmail:
	case interactionType.GeneratedMissingInfoEmail:
	case interactionType.GeneratedNoLimitEmail:
	case interactionType.GeneratedSettlementEmail:
	case interactionType.GeneratedReassessmentEmail:
	case interactionType.GeneratedRejectedManuallyEmail:
	case interactionType.GeneratedRejectedPreExistingConditionEmail:
	case interactionType.GeneratedRejectedNoActiveCoverPeriodEmail:
	case interactionType.GeneratedPreAuthorisationCompletedEmail:
	case interactionType.GeneratedReassessmentAcknowledgementEmail:
	case interactionType.GeneratedMedicalRecordsSummaryEmail:
	case interactionType.GeneratedGreyMedicalRecordsSummaryEmail:
	case interactionType.GeneratedRejectedNoProductCoverageEmail:
	case interactionType.GeneratedRejectedMultipleEmail:
	case interactionType.GeneratedOpenClaimReminderEmail:
	case interactionType.GeneratedDuplicateClaimRejectionEmail:
	case interactionType.GeneratedDuplicateInvoiceRejectionEmail:
		return communicationType.Email;
	case interactionType.GeneratedAcknowledgementSms:
	case interactionType.GeneratedChaseSms:
	case interactionType.GeneratedSettlementSms:
	case interactionType.GeneratedReassessmentAcknowledgementSms:
	case interactionType.GeneratedOpenClaimReminderSms:
	case interactionType.GeneratedMissingInfoSms:
		return communicationType.Sms;
	case interactionType.HospitalClaimSubmission:
	case interactionType.ClaimBotRequest:
	case interactionType.ClaimBotResponseDenial:
	case interactionType.ClaimBotResponseApproval:
	case interactionType.ClaimBotResponseFailed:
		return communicationType.Html;
	default: return null;
	}
}

function specifyCommDetails ({ details }) {
	if (!details.length) {
		return null;
	}
	const commDetails = details.filter(({ type }) => type === interactionDetailType.Email || type === interactionDetailType.Sms);
	return commDetails.length ? commDetails[0] : null;
}

function specifyInteractionValue (interaction) {
	switch (interaction.actionType) {
	case interactionType.ChangedClaimStatus:
	case interactionType.ExGratiaPayment:
	case interactionType.OnlineClaimSubmission:
	case interactionType.HospitalNotification:
	case interactionType.ClaimReassessmentCancelled:
	case interactionType.ExtTranscriptionSuccessful:
	case interactionType.ExtTranscriptionFailed:
		return interaction.value;
	case interactionType.ClaimFormDocument:
	case interactionType.InvoiceDocument:
	case interactionType.MedicalHistoryDocument:
	case interactionType.DisputeDocument:
	case interactionType.DeclarationPageDocument:
	case interactionType.ProofOfSecondaryInsuranceDocument:
	case interactionType.MedicalRecordSummaryDocument:
	case interactionType.OtherDocument:
	case interactionType.HospitalClaimSubmission:
	case interactionType.InboundEmailDocument:
	case interactionType.EditedDocument:
	case interactionType.DeletedDocument:
		// for files-related interactions the actual value is a file name
		// e.g. 'The Email has been generated | fileName.pdf'
		return specifyInteractionFileName(interaction);
	case interactionType.GeneratedAcknowledgementEmail:
	case interactionType.RequestedAcknowledgementEmail:
	case interactionType.GeneratedAcknowledgementSms:
	case interactionType.RequestedAcknowledgementSms:
		return outCommunications.ClaimAcknowledgement;
	case interactionType.GeneratedMissingInfoEmail:
	case interactionType.GeneratedMissingInfoLetter:
	case interactionType.GeneratedMissingInfoSms:
	case interactionType.RequestedMissingInfoEmail:
	case interactionType.RequestedMissingInfoLetter:
	case interactionType.RequestedMissingInfoSms:
		return outCommunications.MissingInformation;
	case interactionType.GeneratedChaseEmail:
	case interactionType.GeneratedChaseLetter:
	case interactionType.GeneratedChaseSms:
	case interactionType.RequestedChaseEmail:
	case interactionType.RequestedChaseLetter:
	case interactionType.RequestedChaseSms:
		return outCommunications.MissingInformationChase;
	case interactionType.GeneratedWaitPeriodEmail:
	case interactionType.GeneratedWaitPeriodLetter:
	case interactionType.RequestedWaitPeriodEmail:
	case interactionType.RequestedWaitPeriodLetter:
		return outCommunications.WaitPeriodRejection;
	case interactionType.GeneratedNoLimitEmail:
	case interactionType.GeneratedNoLimitLetter:
	case interactionType.RequestedNoLimitEmail:
	case interactionType.RequestedNoLimitLetter:
		return outCommunications.NoSectionLimitRejection;
	case interactionType.GeneratedRejectedManuallyEmail:
	case interactionType.GeneratedRejectedManuallyLetter:
	case interactionType.RequestedRejectedManuallyEmail:
	case interactionType.RequestedRejectedManuallyLetter:
		return outCommunications.ManualRejection;
	case interactionType.GeneratedSettlementEmail:
	case interactionType.GeneratedSettlementLetter:
	case interactionType.RequestedSettlementEmail:
	case interactionType.RequestedSettlementLetter:
	case interactionType.RequestedSettlementSms:
	case interactionType.GeneratedSettlementSms:
		return outCommunications.ClaimSettlement;
	case interactionType.ClaimReassessment:
		return outCommunications.ClaimReassessment;
	case interactionType.GeneratedReassessmentEmail:
	case interactionType.GeneratedReassessmentLetter:
	case interactionType.RequestedReassessmentEmail:
	case interactionType.RequestedReassessmentLetter:
		return outCommunications.ClaimReassessmentCommunication;
	case interactionType.GeneratedRejectedPreExistingConditionEmail:
	case interactionType.GeneratedRejectedPreExistingConditionLetter:
	case interactionType.RequestedRejectedPreExistingConditionEmail:
	case interactionType.RequestedRejectedPreExistingConditionLetter:
		return outCommunications.PreExistingConditionRejection;
	case interactionType.GeneratedRejectedNoActiveCoverPeriodEmail:
	case interactionType.GeneratedRejectedNoActiveCoverPeriodLetter:
	case interactionType.RequestedRejectedNoActiveCoverPeriodEmail:
	case interactionType.RequestedRejectedNoActiveCoverPeriodLetter:
		return outCommunications.NoActiveCoverPeriodRejection;
	case interactionType.GeneratedRejectedNoProductCoverageEmail:
	case interactionType.GeneratedRejectedNoProductCoverageLetter:
	case interactionType.RequestedRejectedNoProductCoverageEmail:
	case interactionType.RequestedRejectedNoProductCoverageLetter:
		return outCommunications.NoProductCoverageRejection;
	case interactionType.RequestedDuplicateClaimRejectionEmail:
	case interactionType.GeneratedDuplicateClaimRejectionEmail:
	case interactionType.RequestedDuplicateClaimRejectionLetter:
	case interactionType.GeneratedDuplicateClaimRejectionLetter:
		return outCommunications.DuplicateClaimRejection;
	case interactionType.RequestedDuplicateInvoiceRejectionEmail:
	case interactionType.GeneratedDuplicateInvoiceRejectionEmail:
	case interactionType.RequestedDuplicateInvoiceRejectionLetter:
	case interactionType.GeneratedDuplicateInvoiceRejectionLetter:
		return outCommunications.DuplicateInvoiceRejection;
	case interactionType.GeneratedRejectedMultipleEmail:
	case interactionType.GeneratedRejectedMultipleLetter:
	case interactionType.RequestedRejectedMultipleEmail:
	case interactionType.RequestedRejectedMultipleLetter:
		return outCommunications.MultipleRejection;
	case interactionType.OverrideRejection:
		return outCommunications.OverrideRejection;
	case interactionType.TaskAssignment:
		return 'Task Assignment';
	case interactionType.TaskUnAssignment:
		return 'Task Unassignment';
	case interactionType.GeneratedPreAuthorisationCompletedEmail:
	case interactionType.GeneratedPreAuthorisationCompletedLetter:
	case interactionType.RequestedPreAuthorisationCompletedEmail:
	case interactionType.RequestedPreAuthorisationCompletedLetter:
		return outCommunications.PreAuthorisationCompleted;
	case interactionType.ClaimBotRequest:
		return 'ClaimBot Request';
	case interactionType.ClaimBotResponse:
	case interactionType.ClaimBotResponseApproval:
	case interactionType.ClaimBotResponseDenial:
	case interactionType.ClaimBotResponseFailed:
		return 'ClaimBot Response';
	case interactionType.FailedAutomation:
		return 'Failed Automation Process';
	case interactionType.RequestedReassessmentAcknowledgementEmail:
	case interactionType.GeneratedReassessmentAcknowledgementEmail:
	case interactionType.RequestedReassessmentAcknowledgementSms:
	case interactionType.GeneratedReassessmentAcknowledgementSms:
		return outCommunications.ReassessmentAcknowledgement;
	case interactionType.RequestedMedicalRecordsSummaryLetter:
	case interactionType.RequestedMedicalRecordsSummaryEmail:
	case interactionType.GeneratedMedicalRecordsSummaryLetter:
	case interactionType.GeneratedMedicalRecordsSummaryEmail:
		return outCommunications.MedicalRecordsSummaryCommunication;
	case interactionType.ClaimPayeeUpdated:
		return 'Claim Payee Updated';
	case interactionType.RequestedGreyMedicalRecordsSummaryLetter:
	case interactionType.RequestedGreyMedicalRecordsSummaryEmail:
	case interactionType.GeneratedGreyMedicalRecordsSummaryLetter:
	case interactionType.GeneratedGreyMedicalRecordsSummaryEmail:
		return outCommunications.GreyMedicalRecordsSummaryCommunication;
	case interactionType.PaymentEdit:
		return 'Payment Edited';
	case interactionType.StopAndReissuePayment:
		return 'Payment Stopped And Reissued';
	case interactionType.EscalatedClaim:
		return outCommunications.EscalatedClaim;
	case interactionType.DeEscalatedClaim:
		return outCommunications.DeEscalatedClaim;
	case interactionType.FastTrackReviewedClaim:
		return outCommunications.FastTrackReviewedClaim;
	case interactionType.PreExCondition:
		return 'Pre Existing Condition';
	case interactionType.RequestedOpenClaimReminderEmail:
	case interactionType.RequestedOpenClaimReminderLetter:
	case interactionType.RequestedOpenClaimReminderSms:
	case interactionType.GeneratedOpenClaimReminderEmail:
	case interactionType.GeneratedOpenClaimReminderLetter:
	case interactionType.GeneratedOpenClaimReminderSms:
		return outCommunications.OpenClaimReminder;
	default: return '';
	}
}

function specifyInteractionFileName (interaction) {
	if (noFileInteraction(interaction)) return null;
	return interaction.value.split('|')[1];
}

function specifyInteractionVetName (interaction) {
	const interactionValues = interaction.value.split('|');
	if (noFileInteraction(interaction) || interactionValues.length < 3) return null;
	return interactionValues[2];
}

function specifyInteractionDescription (interaction) {
	let reassesDetails = null;
	switch (interaction.actionType) {
	case interactionType.ClaimReassessmentCancelled:
	case interactionType.ExGratiaPayment:
	case interactionType.HospitalNotification:
	case interactionType.ExtTranscriptionSuccessful:
	case interactionType.ExtTranscriptionFailed:
		return null;
	case interactionType.ChangedClaimStatus:
		if (interaction.claimStatus === claimNumberStatus.Settled) {
			return interaction.details.find(({ type }) => type === interactionDetailType.SettledReason)?.settledReason;
		}
		return null;
	case interactionType.ClaimReassessment:
		reassesDetails = interaction.value.split('|');
		return defaultValue(reassesDetails[0], '') + ' for ' + defaultValue(reassesDetails[1], 'Correction');
	default: return interaction.value.split('|')[0];
	}
}

function defaultValue (obj, def) {
	if (obj === undefined || obj === null) {
		return def;
	}
	return obj;
}

function specifyInteractionIconType ({ actionType, claimStatus }) {
	switch (actionType) {
	case interactionType.ChangedClaimStatus:
		return specifyClaimStatusIcon(claimStatus);
	case interactionType.ClaimReassessment:
	case interactionType.ClaimReassessmentCancelled:
	case interactionType.OnlineClaimSubmission:
		return 'general';
	case interactionType.HospitalNotification:
		return 'fas fa-bell';
	case interactionType.OverrideRejection:
	case interactionType.PreExCondition:
		return 'fa fa-band-aid';
	case interactionType.ClaimFormDocument:
	case interactionType.InvoiceDocument:
	case interactionType.MedicalHistoryDocument:
	case interactionType.MedicalRecordSummaryDocument:
	case interactionType.OtherDocument:
	case interactionType.DisputeDocument:
	case interactionType.DeclarationPageDocument:
	case interactionType.ProofOfSecondaryInsuranceDocument:
	case interactionType.HospitalClaimSubmission:
	case interactionType.InboundEmailDocument:
	case interactionType.ClaimBotRequest:
	case interactionType.ClaimBotResponseDenial:
	case interactionType.ClaimBotResponseApproval:
	case interactionType.ClaimBotResponseFailed:
		return 'file';
	case interactionType.FailedAutomation:
		return 'aqv-failed-scan';
	case interactionType.EditedDocument:
		return 'aqv-doc-edit';
	case interactionType.DeletedDocument:
		return 'aqv-doc-delete';
	case interactionType.GeneratedWaitPeriodLetter:
	case interactionType.GeneratedMissingInfoLetter:
	case interactionType.GeneratedNoLimitLetter:
	case interactionType.GeneratedSettlementLetter:
	case interactionType.GeneratedReassessmentLetter:
	case interactionType.GeneratedRejectedManuallyLetter:
	case interactionType.GeneratedChaseLetter:
	case interactionType.GeneratedRejectedPreExistingConditionLetter:
	case interactionType.GeneratedRejectedNoActiveCoverPeriodLetter:
	case interactionType.RequestedWaitPeriodLetter:
	case interactionType.RequestedMissingInfoLetter:
	case interactionType.RequestedNoLimitLetter:
	case interactionType.RequestedSettlementLetter:
	case interactionType.RequestedReassessmentLetter:
	case interactionType.RequestedRejectedManuallyLetter:
	case interactionType.RequestedChaseLetter:
	case interactionType.RequestedRejectedPreExistingConditionLetter:
	case interactionType.RequestedRejectedNoActiveCoverPeriodLetter:
	case interactionType.RequestedPreAuthorisationCompletedLetter:
	case interactionType.GeneratedPreAuthorisationCompletedLetter:
	case interactionType.GeneratedMedicalRecordsSummaryLetter:
	case interactionType.RequestedMedicalRecordsSummaryLetter:
	case interactionType.GeneratedGreyMedicalRecordsSummaryLetter:
	case interactionType.RequestedGreyMedicalRecordsSummaryLetter:
	case interactionType.RequestedRejectedNoProductCoverageLetter:
	case interactionType.GeneratedRejectedNoProductCoverageLetter:
	case interactionType.RequestedRejectedMultipleLetter:
	case interactionType.GeneratedRejectedMultipleLetter:
	case interactionType.GeneratedOpenClaimReminderLetter:
	case interactionType.RequestedOpenClaimReminderLetter:
	case interactionType.RequestedDuplicateClaimRejectionLetter:
	case interactionType.GeneratedDuplicateClaimRejectionLetter:
	case interactionType.RequestedDuplicateInvoiceRejectionLetter:
	case interactionType.GeneratedDuplicateInvoiceRejectionLetter:
		return 'envelopeOpen';
	case interactionType.GeneratedWaitPeriodEmail:
	case interactionType.GeneratedAcknowledgementEmail:
	case interactionType.GeneratedMissingInfoEmail:
	case interactionType.GeneratedNoLimitEmail:
	case interactionType.GeneratedSettlementEmail:
	case interactionType.GeneratedReassessmentEmail:
	case interactionType.GeneratedRejectedManuallyEmail:
	case interactionType.GeneratedChaseEmail:
	case interactionType.GeneratedRejectedPreExistingConditionEmail:
	case interactionType.GeneratedRejectedNoActiveCoverPeriodEmail:
	case interactionType.RequestedWaitPeriodEmail:
	case interactionType.RequestedAcknowledgementEmail:
	case interactionType.RequestedMissingInfoEmail:
	case interactionType.RequestedNoLimitEmail:
	case interactionType.RequestedSettlementEmail:
	case interactionType.RequestedReassessmentEmail:
	case interactionType.RequestedRejectedManuallyEmail:
	case interactionType.RequestedChaseEmail:
	case interactionType.RequestedRejectedPreExistingConditionEmail:
	case interactionType.RequestedRejectedNoActiveCoverPeriodEmail:
	case interactionType.RequestedPreAuthorisationCompletedEmail:
	case interactionType.GeneratedPreAuthorisationCompletedEmail:
	case interactionType.RequestedReassessmentAcknowledgementEmail:
	case interactionType.GeneratedReassessmentAcknowledgementEmail:
	case interactionType.GeneratedMedicalRecordsSummaryEmail:
	case interactionType.RequestedMedicalRecordsSummaryEmail:
	case interactionType.GeneratedGreyMedicalRecordsSummaryEmail:
	case interactionType.RequestedGreyMedicalRecordsSummaryEmail:
	case interactionType.RequestedRejectedNoProductCoverageEmail:
	case interactionType.GeneratedRejectedNoProductCoverageEmail:
	case interactionType.RequestedRejectedMultipleEmail:
	case interactionType.GeneratedRejectedMultipleEmail:
	case interactionType.RequestedOpenClaimReminderEmail:
	case interactionType.GeneratedOpenClaimReminderEmail:
	case interactionType.RequestedDuplicateClaimRejectionEmail:
	case interactionType.GeneratedDuplicateClaimRejectionEmail:
	case interactionType.RequestedDuplicateInvoiceRejectionEmail:
	case interactionType.GeneratedDuplicateInvoiceRejectionEmail:
		return 'envelope';
	case interactionType.GeneratedAcknowledgementSms:
	case interactionType.GeneratedChaseSms:
	case interactionType.GeneratedSettlementSms:
	case interactionType.RequestedAcknowledgementSms:
	case interactionType.RequestedChaseSms:
	case interactionType.RequestedSettlementSms:
	case interactionType.RequestedReassessmentAcknowledgementSms:
	case interactionType.GeneratedReassessmentAcknowledgementSms:
	case interactionType.RequestedOpenClaimReminderSms:
	case interactionType.GeneratedOpenClaimReminderSms:
	case interactionType.RequestedMissingInfoSms:
	case interactionType.GeneratedMissingInfoSms:
		return 'sms';
	case interactionType.TaskAssignment:
		return 'fa-tasks';
	case interactionType.TaskUnAssignment:
		return 'fa-tasks text-danger';
	case interactionType.ExGratiaPayment:
		return 'fa-file-invoice-dollar';
	case interactionType.ClaimPayeeUpdated:
		return 'fas fa-circle text-primary';
	case interactionType.PaymentEdit:
	case interactionType.StopAndReissuePayment:
		return 'fa-file-signature';
	case interactionType.FastTrackReviewedClaim:
		return 'fas fa-circle aqv-fast-track';
	case interactionType.EscalatedClaim:
	case interactionType.DeEscalatedClaim:
		return 'fas fa-circle aqv-escalate-manager fs-25';
	case interactionType.ExtTranscriptionSuccessful:
		return 'fas fa-circle text-success';
	case interactionType.ExtTranscriptionFailed:
		return 'fas fa-circle text-danger';
	}
}

function specifyClaimStatusIcon (claimStatus) {
	let iconColor = '';
	switch (claimStatus) {
	case claimNumberStatus.NewClaim:
		iconColor = 'text-success';
		break;
	case claimNumberStatus.DraftClaim:
	case claimNumberStatus.FraudCheckOk:
		iconColor = 'text-primary';
		break;
	case claimNumberStatus.ClosedPaid:
	case claimNumberStatus.Rejected:
	case claimNumberStatus.RejectedByConditionLimit:
	case claimNumberStatus.RejectedByMultipleConditions:
	case claimNumberStatus.RejectedManually:
	case claimNumberStatus.FraudCheckFailed:
	case claimNumberStatus.RejectedSectionLimit:
	case claimNumberStatus.RejectedWaitingPeriod:
	case claimNumberStatus.RejectedByPreExistingCondition:
	case claimNumberStatus.RejectedByNoActiveCoverPeriod:
	case claimNumberStatus.RejectedByNoProductCoverage:
	case claimNumberStatus.RejectedMultiple:
	case claimNumberStatus.RejectedByDuplicateClaim:
	case claimNumberStatus.RejectedByDuplicateInvoice:
		iconColor = 'text-white-50';
		break;
	case claimNumberStatus.Settled:
	case claimNumberStatus.RejectionPending:
	case claimNumberStatus.PreAuthorisationCompleted:
		iconColor = 'text-purple';
		break;
	case claimNumberStatus.FraudCheck:
	case claimNumberStatus.ReassessmentPending:
		iconColor = 'text-yellow';
		break;
	case claimNumberStatus.MissingInfo:
		iconColor = 'text-pink';
	}
	return `fas fa-circle ${iconColor}`;
}

function specifyArrowDirectionIcon (interaction) {
	const isDocumentAction = [interactionType.EditedDocument, interactionType.DeletedDocument].includes(interaction.actionType);
	if (noFileInteraction(interaction) || isDocumentAction) return '';
	if (isUploadedDocumentAction(interaction.actionType)) {
		return 'fa fa-arrow-up text-danger in';
	}
	if (interaction.value === 'Comms suppressed') {
		return 'fa fa-times text-danger';
	}
	return 'fa fa-arrow-up text-success out';
}

const noFileInteraction = ({ actionType }) => [
	interactionType.ChangedClaimStatus,
	interactionType.ClaimReassessment,
	interactionType.ClaimReassessmentCancelled,
	interactionType.TaskAssignment,
	interactionType.TaskUnAssignment,
	interactionType.ExGratiaPayment,
	interactionType.OverrideRejection,
	interactionType.ClaimPayeeUpdated,
	interactionType.HospitalNotification,
	interactionType.PaymentEdit,
	interactionType.StopAndReissuePayment,
	interactionType.FastTrackReviewedClaim,
	interactionType.PreExCondition,
	interactionType.EscalatedClaim,
	interactionType.DeEscalatedClaim,
	interactionType.ExtTranscriptionSuccessful,
	interactionType.ExtTranscriptionFailed,
	interactionType.FailedAutomation,
].includes(actionType);

export function toInteractionPreview ({ id, commType, dateTime, fileId, commDetails, attachments, fileName }) {
	return {
		id,
		commType,
		dateTime,
		fileId,
		commDetails,
		attachments,
		fileName,
	};
}

export default {
	getInteractions (payload, paging) {
		return httpClient(`/api/claims/interactions?${paging.toQuery()}`, { omitSpinner: true })
			.post(payload)
			.then(toInteractions);
	},
	getFilterOptions () {
		return [
			{
				filterLabel: 'Type',
				filterOptions: ['Claim'],
			},
			{
				filterLabel: 'Status',
				filterOptions: [
					{ key: 1, value: 'Draft' },
					{ key: 2, value: 'Rejected' },
					{ key: 3, value: 'Closed/Paid' },
					{ key: 4, value: 'Settled' },
					{ key: 5, value: 'Fraud Check' },
					{ key: 6, value: 'Reassessing' },
					{ key: 7, value: 'Rejection Pending' },
					{ key: 8, value: 'Missing Info' },
					{ key: 9, value: 'Rejected Manually' },
					{ key: 10, value: 'Fraud Check Ok' },
					{ key: 11, value: 'Fraud Check Failed' },
					{ key: 12, value: 'Reassessment Pending' },
					{ key: 13, value: 'Reassessment Cancelled' },
					{ key: 15, value: 'Pre-Authorisation Completed' },
				],
			},
			{
				filterLabel: 'Communications',
				filterOptions: [
					{ key: 1, value: 'Inbound' },
					{ key: 2, value: 'Outbound' },
					{ key: 3, value: 'Letter' },
					{ key: 4, value: 'Email' },
					{ key: 5, value: 'SMS' },
					{ key: 6, value: 'Invoice' },
					{ key: 7, value: 'Claim Form' },
					{ key: 8, value: 'Medical History' },
					{ key: 9, value: 'Assignment' },
					{ key: 10, value: 'Medical Record Summary' },
					{ key: 11, value: 'Other' },
					{ key: 12, value: 'Dispute' },
					{ key: 13, value: 'Declaration Page' },
					{ key: 14, value: 'Proof Of Secondary Insurance' },
				],
			},
			{
				filterLabel: 'Date',
				filterOptions: AllDateTypes,
			},
		];
	},
};
