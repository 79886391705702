<script>

export default {
	name: 'PagedColumns',
	props: {
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		freezeFirstColumn: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	functional: true,
	render (createElement, context) {
		const startIndex = (context.props.currentColumnPage - 1) * context.props.columnsPerPage;
		const children = context.props.freezeFirstColumn ? context.children.slice(1) : context.children;
		const firstColumn = context.props.freezeFirstColumn ? [context.children[0]] : [];
		return [...firstColumn, ...children.slice(startIndex, startIndex + context.props.columnsPerPage)];
	},
};
</script>
