<template>
	<div class="d-flex align-items-center h-100">
		<button
			data-qa="baseBar_button_cancel"
			type="button"
			:class="['btn d-block', cancelClass]"
			:disabled="isCancelDisabled"
			@click="$emit('cancel')"
			v-shortkey="isDdlOpen ? null : $shortkeys.cancel"
			@shortkey="$emit('cancel')"
		>
			{{ cancelLabel }}
		</button>
		<div class="font-13 flex-fill align-items-center">
			<slot />
		</div>
		<div class="flex-shrink-0 d-flex">
			<button
				v-if="isBackVisible"
				data-qa="baseBar_button_back"
				type="button"
				:class="['btn d-block', backClass]"
				:disabled="isBackDisabled"
				@click="$emit('back')"
				v-shortkey="$shortkeys.back"
				@shortkey="$emit('back')"
			>
				{{ backLabel }}
			</button>
			<aq-dropdown
				data-qa="baseBar_button_proceed"
				v-if="splitToggle"
				:label="nextLabel"
				:options="optionList"
				:btn-class="nextClass"
				:disabled="isNextDisabled"
				:is-read-only-mode="isReadOnlyMode"
				@select="$emit('select-action', $event)"
				@main-action="$emit('next')"
				@open="onToggleDropdown"
				:split-toggle="true"
				:height="37"
				class="ml-20"
				is-shortkey-context
			/>
			<button
				v-else
				type="button"
				:class="['btn d-block ml-20', nextClass]"
				:disabled="isNextDisabled"
				@click="$emit('next')"
				data-qa="baseBar_button_proceed"
				v-shortkey="$shortkeys.proceed"
				@shortkey="$emit('next')"
			>
				{{ nextLabel }}
			</button>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		cancelLabel: {
			type: String,
			required: false,
			default: 'Cancel',
		},
		cancelClass: {
			type: String,
			required: false,
			default: 'btn-row',
		},
		isCancelDisabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		backLabel: {
			type: String,
			required: false,
			default: 'Back',
		},
		backClass: {
			type: String,
			required: false,
			default: 'btn-row',
		},
		isBackDisabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		isBackVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		nextLabel: {
			type: String,
			required: false,
			default: 'Next',
		},
		nextClass: {
			type: String,
			required: false,
			default: 'btn-row',
		},
		isNextDisabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		optionList: {
			type: Array,
			required: false,
		},
		splitToggle: {
			type: Boolean,
			required: false,
			default: false,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isDdlOpen: false,
		};
	},
	methods: {
		onToggleDropdown (isOpen) {
			this.isDdlOpen = isOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
.font-13 {
  font-size: 13px;
}
</style>
