const DEFAUT_PAGE_SIZE = 10;
const DEFAUT_PAGE_NUMBER = 1;
export default class Paging {
	constructor (pageSize = DEFAUT_PAGE_SIZE, pageNumber = DEFAUT_PAGE_NUMBER) {
		this.pageSize = pageSize; // TODO: Delete when back changed
		this.pageNumber = pageNumber;
		this.rowCount = 0;
	}

	toQuery () {
		return Object.entries(this.toQueryObject()).map(([key, value]) => `${key}=${value}`).join('&');
	}

	toQueryObject () {
		return Object.fromEntries([this.pageSize === DEFAUT_PAGE_SIZE ? '' : ['pageSize', this.pageSize],
			this.pageNumber === DEFAUT_PAGE_NUMBER ? '' : ['pageNumber', this.pageNumber]]
			.filter(Boolean));
	}

	get rangeStart () {
		return (this.pageNumber - 1) * this.pageSize + 1;
	}
};
