import MenuItem from './models/MenuItem';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';
import PermissionType from '@commonServices/settings/PermissionType';

const TaskPotPermissions = [
	PermissionType.ViewManualRejectClaim,
	PermissionType.ViewFraudCheck,
	PermissionType.ViewClaimPayment,
	PermissionType.ViewMissingInformation,
	PermissionType.ViewReassessPendingCard,
	PermissionType.ViewDraftClaims,
];
const TaskPotPermissionsStatusMap = {
	[WorkQueueType.RejectionApproval]: PermissionType.ViewManualRejectClaim,
	[WorkQueueType.FraudCheck]: PermissionType.ViewFraudCheck,
	[WorkQueueType.PaymentApproval]: PermissionType.ViewClaimPayment,
	[WorkQueueType.Chasing]: PermissionType.ViewMissingInformation,
	[WorkQueueType.ReassessmentPending]: PermissionType.ViewReassessPendingCard,
	[WorkQueueType.DraftClaims]: PermissionType.ViewDraftClaims,
};

const MenuItemsLabel = {
	Home: 'Home',
	Tasks: 'Tasks',
	Reports: 'Reports',
	Letters: 'Letters',
};

export { MenuItemsLabel };

export default {
	getMenuItems (can) {
		return [
			new MenuItem(MenuItemsLabel.Home, '/dashboard', 'fa-house-chimney', false),
			...(this.anyTasksPotIsAvailable(can) ? [new MenuItem(MenuItemsLabel.Tasks, '/tasks', 'fa-tasks', true)] : []),
			new MenuItem(MenuItemsLabel.Reports, '/reports', 'fa-table', true),
			...can.ViewLetters ? [new MenuItem(MenuItemsLabel.Letters, '/letters', 'fa-print', true)] : [],
		];
	},
	anyTasksPotIsAvailable (can) {
		return TaskPotPermissions.some(potPermission => can[potPermission]);
	},
	checkTasksPagePermission (can, { pot }) {
		return can[TaskPotPermissionsStatusMap[pot]];
	},
};
