<template>
	<div :class="[{ 'copy': isCopy }, 'payment-claim-view d-flex flex-column h-100 py-14 px-24 container-fluid']">
		<div class="row no-gutters align-items-center justify-content-between">
			<div class="d-flex align-items-center">
				<div
					data-qa="paymentClaimView_icon_enter"
					class="circle mr-8 cursor-default"
				>
					<i class="fas fa-credit-card cursor-default" />
				</div>
				<span class="font-weight-bold fs-16">Payment</span>
			</div>
			<div
				v-if="isCopy"
				class="d-flex"
			>
				<div class="status-info p-6">
					<span>Status:</span>
					<span class="font-weight-bold">{{ claimStatus }}</span>
				</div>
				<div />
			</div>
		</div>
		<div class="row no-gutters my-10">
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Payment Recipients
			</div>
		</div>
		<div class="p-16">
			<template v-if="claim.payments.recipients.length">
				<div
					v-for="(recipient, index) of claim.payments.recipients"
					:key="index"
					class="row no-gutters mb-10"
				>
					<div
						class="col-2 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonPaymentRecipients[index], 'recipientType')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_recipientType"
							label="Recipient"
							:description="recipient.recipientType"
						/>
					</div>
					<div
						class="col-2 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonPaymentRecipients[index], 'payee')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_payee"
							label="Payee"
							:description="recipient.payee"
						/>
					</div>
					<div
						class="col-2 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonPaymentRecipients[index], 'paymentType')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_paymentType"
							label="Payment Type"
							:description="recipient.paymentType"
						/>
					</div>
					<div
						class="col-4 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonPaymentRecipients[index], 'paymentDetails')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_paymentDetails"
							label="Payment Details"
							:description="recipient.paymentDetails"
						/>
					</div>
					<div
						class="col"
						:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonPaymentRecipients[index], 'displayAmount')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_displayAmount"
							label="Split"
							:description="recipient.displayAmount"
						/>
					</div>
				</div>
				<div
					class="row no-gutters my-10"
					v-if="claim.payments.recipients.length"
				>
					<div
						class="col-2 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(claim.payments, comparisonPayments, 'total')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_total"
							label="Total"
							:description="claim.payments.total"
						/>
					</div>
					<div
						class="col-2 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(claim.payments, comparisonPayments, 'remaining')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_remaining"
							label="Remaining"
							:description="claim.payments.remaining"
						/>
					</div>
					<div
						class="col-2 mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(claim.payments, comparisonPayments, 'totalAmount')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_totalAmount"
							label="Total Amount"
							:description="claim.payments.totalAmount"
						/>
					</div>
					<div class="col mr-10" />
					<div class="col" />
				</div>
			</template>
			<template v-else>
				<div class="row no-gutters mb-10">
					<span class="mx-auto font-weight-bold">No Payments</span>
				</div>
			</template>
		</div>
		<div class="row no-gutters my-10">
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Transaction History
			</div>
		</div>
		<div class="p-16">
			<template v-if="claim.payments.transactions.length">
				<div
					v-for="(transaction, index) of claim.payments.transactions"
					:key="index"
					class="row no-gutters mb-10"
				>
					<div
						class="col mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(transaction, comparisonPaymentTransactions[index], 'payee')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_transactionPayee"
							label="Payee"
							:description="transaction.payee"
						/>
					</div>
					<div
						class="col mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(transaction, comparisonPaymentTransactions[index], 'paymentMethod')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_transactionPaymentMethod"
							label="Payment Method"
							:description="transaction.paymentMethod"
						/>
					</div>
					<div
						class="col mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(transaction, comparisonPaymentTransactions[index], 'paymentType')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_transactionPaymentType"
							label="Payment Type"
							:description="transaction.paymentType"
						/>
					</div>
					<div
						class="col mr-10"
						:class="{ 'warning-border': isObjectValueDifferent(transaction, comparisonPaymentTransactions[index], 'paymentAccountName')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_transactionPaymentAccountName"
							label="Payment Acct Name"
							:description="transaction.paymentAccountName"
						/>
					</div>
					<div
						class="col"
						:class="{ 'warning-border': isObjectValueDifferent(transaction, comparisonPaymentTransactions[index], 'amount')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_transactionPaymentAmount"
							label="Payment Amount"
							:description="transaction.amount"
						/>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="row no-gutters mb-10">
					<span class="mx-auto font-weight-bold">No Transactions </span>
				</div>
			</template>
		</div>
		<div
			v-if="claim.payments.exGratiaPayments.length"
			class="row no-gutters my-10"
		>
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Ex-Gratia Payments
			</div>
		</div>
		<div class="p-16">
			<div
				v-for="(recipient, index) of claim.payments.exGratiaPayments"
				:key="index"
				class="row no-gutters mb-10"
			>
				<div
					class="col-2 mr-10"
					:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonExGratiaPayments[index], 'recipientType')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_exGrRecipientType"
						label="Recipient"
						:description="recipient.recipientType"
					/>
				</div>
				<div
					class="col-2 mr-10"
					:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonExGratiaPayments[index], 'payee')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_exGrPayee"
						label="Payee"
						:description="recipient.payee"
					/>
				</div>
				<div
					class="col-2 mr-10"
					:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonExGratiaPayments[index], 'paymentType')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_exGrPaymentType"
						label="Payment Type"
						:description="recipient.paymentType"
					/>
				</div>
				<div
					class="col-4 mr-10"
					:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonExGratiaPayments[index], 'paymentDetails')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_exGrPaymentDetails"
						label="Payment Details"
						:description="recipient.paymentDetails"
					/>
				</div>
				<div
					class="col"
					:class="{ 'warning-border': isObjectValueDifferent(recipient, comparisonExGratiaPayments[index], 'displayAmount')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_exGrDisplayAmount"
						label="Split"
						:description="recipient.displayAmount"
					/>
				</div>
			</div>
		</div>
		<div class="row no-gutters my-10">
			<div class="col fs-18 section-label px-23 py-10 text-white">
				Payment Breakdown
			</div>
		</div>
		<div class="p-16 scrollbar">
			<div
				v-for="(paymentBreakdown, index) of claim.payments.paymentBreakdown.summary"
				:key="index"
				class="mb-10 p-16 claim-item-info"
			>
				<div
					data-qa="paymentClaimView_infoTerm_breakDownPolicySectionName"
					class="font-weight-bold mb-10"
					:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'policySectionName')}"
				>
					{{ paymentBreakdown.policySectionName }}
				</div>
				<div class="row no-gutters mb-10">
					<div
						class="col-4 mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'treatmentPeriod')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTreatmentPeriod"
							label="Treatment Period"
							:description="paymentBreakdown.treatmentPeriod"
						/>
					</div>
					<div
						class="col-3 mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'amount')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownAmount"
							label="Amount"
							:description="paymentBreakdown.amount"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'userDeductionAmountDisplay')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownUserDeductionAmountDisplay"
							label="User"
							:description="paymentBreakdown.userDeductionAmountDisplay"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'systemDeductionAmountDisplay')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownSystemDeductionAmountDisplay"
							label="System"
							:description="paymentBreakdown.systemDeductionAmountDisplay"
						/>
					</div>
					<div
						class="col"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'totalAmount')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalAmount"
							label="Total"
							:description="paymentBreakdown.totalAmount"
						/>
					</div>
				</div>
				<div class="font-weight-bold mb-10">
					Deductions
				</div>
				<div class="row no-gutters mb-5">
					<div
						class="col-3 mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown, comparisonPaymentBreakdownSummary[index], 'userDeductionAmountDisplay')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownUserDeductionAmountDisplay"
							label="User"
							:description="paymentBreakdown.userDeductionAmountDisplay"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalExcess, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalExcess'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalExcess"
							label="Excess"
							:description="paymentBreakdown.totalExcess.deductedValue"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalCoInsurance, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalCoInsurance'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalCoInsurance"
							:label="getDeductionOriginalLimitDescription('Co-ins', paymentBreakdown.totalCoInsurance.originalLimit)"
							:description="paymentBreakdown.totalCoInsurance.deductedValue"
						/>
					</div>
					<div
						class="col-2 mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalPartialCoverage, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalPartialCoverage'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalPartialCoverage"
							:label="getDeductionOriginalLimitDescription('Section % Cover', paymentBreakdown.totalPartialCoverage.originalLimit)"
							:description="paymentBreakdown.totalPartialCoverage.deductedValue"
						/>
					</div>
					<div
						class="col"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalNonFinancialLimit, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalNonFinancialLimit'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalNonFinancialLimit"
							label="Non Financial Limit"
							:description="paymentBreakdown.totalNonFinancialLimit.deductedValue"
						/>
					</div>
				</div>
				<div class="row no-gutters mb-10">
					<div
						class="col-3 mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalLimit, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalLimit'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalLimit"
							label="Section/Subsection Limit"
							:description="paymentBreakdown.totalLimit.deductedValue"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalConditionLimit, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalConditionLimit'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalConditionLimit"
							label="Condition"
							:description="paymentBreakdown.totalConditionLimit.deductedValue"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalNoCoverage, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalNoCoverage'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalNoCoverage"
							label="No Coverage"
							:description="paymentBreakdown.totalNoCoverage.deductedValue"
						/>
					</div>
					<div
						class="col-2 mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalRejected, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalRejected'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalRejected"
							label="Rejected"
							:description="paymentBreakdown.totalRejected.deductedValue"
						/>
					</div>
					<div
						class="col mr-5"
						:class="{ 'warning-border': isObjectValueDifferent(paymentBreakdown.totalTax, getPropertyFromObject(comparisonPaymentBreakdownSummary[index], 'totalTax'), 'deductedValue')}"
					>
						<claim-info-term
							data-qa="paymentClaimView_infoTerm_breakDownTotalTax"
							label="Tax"
							:description="paymentBreakdown.totalTax.deductedValue"
						/>
					</div>
				</div>
			</div>
			<div class="row no-gutters mt-40 p-16">
				<div class="col-4 mr-5 d-flex justify-content-end align-items-center">
					<div class="font-weight-bold">
						Totals
					</div>
				</div>
				<div
					class="col-3 mr-5"
					:class="{ 'warning-border': isObjectValueDifferent(claim.payments.paymentBreakdown, comparisonPaymentBreakdown, 'amount')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_breakDownAmount"
						label=""
						:description="claim.payments.paymentBreakdown.amount"
					/>
				</div>
				<div
					class="col mr-5"
					:class="{ 'warning-border': isObjectValueDifferent(claim.payments.paymentBreakdown, comparisonPaymentBreakdown, 'totalUserAmount')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_breakDownTotalUserAmount"
						label=""
						:description="claim.payments.paymentBreakdown.totalUserAmount"
					/>
				</div>
				<div
					class="col mr-5"
					:class="{ 'warning-border': isObjectValueDifferent(claim.payments.paymentBreakdown, comparisonPaymentBreakdown, 'totalSystemAmount')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_breakDownTotalSystemAmount"
						label=""
						:description="claim.payments.paymentBreakdown.totalSystemAmount"
					/>
				</div>
				<div
					class="col"
					:class="{ 'warning-border': isObjectValueDifferent(claim.payments.paymentBreakdown, comparisonPaymentBreakdown, 'totalAmount')}"
				>
					<claim-info-term
						data-qa="paymentClaimView_infoTerm_breakDownTotalAmount"
						label=""
						:description="claim.payments.paymentBreakdown.totalAmount"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClaimInfoTerm from '@commonView/ClaimPage/ClaimVersions/ClaimInfoTerm';
import { toClaimDisplayStatus } from '@commonServices/utils/converter';
import { isObjectValueDifferent, getPropertyFromObject } from '@commonServices/utils/general';

export default {
	name: 'PaymentClaimView',
	components: {
		ClaimInfoTerm,
	},
	props: {
		isCopy: {
			type: Boolean,
			required: false,
			default: false,
		},
		claim: {
			type: Object,
			required: true,
		},
		claimComparison: {
			type: Object,
			required: false,
		},
	},
	computed: {
		claimStatus () {
			return toClaimDisplayStatus(this.claim.status);
		},
		comparisonPayments () {
			return this.claimComparison?.payments;
		},
		comparisonPaymentRecipients () {
			return this.claimComparison?.payments.recipients ?? [];
		},
		comparisonPaymentTransactions () {
			return this.claimComparison?.payments.transactions ?? [];
		},
		comparisonExGratiaPayments () {
			return this.claimComparison?.payments.exGratiaPayments ?? [];
		},
		comparisonPaymentBreakdown () {
			return this.claimComparison?.payments.paymentBreakdown;
		},
		comparisonPaymentBreakdownSummary () {
			return this.claimComparison?.payments.paymentBreakdown.summary ?? [];
		},
	},
	methods: {
		isObjectValueDifferent,
		getPropertyFromObject,
		getDeductionOriginalLimitDescription (label, originalLimit) {
			return originalLimit ? `${label} (${originalLimit}%)` : label;
		},
	},
};
</script>
<style lang="scss" scoped>
  .payment-claim-view {
    .scrollbar {
      overflow-y: auto;
      height: 50%;
    }

    .circle {
      background: rgba($primary, 0.2);

      i {
        color: var(--cardIconColour);
      }
    }

    .claim-item-info {
      border: 2px solid #cecece;
      border-radius: 3px;
    }

    .status-info {
      border-radius: 3px;
      background: var(--claimInfoTerm);
    }

    .section-label {
      background: $primary;
    }

    &.copy {
      .section-label {
        background: var(--claimVersionsSection);
      }

      .circle {
        background: var(--copyStepIcon);

        i {
          color: var(--bodyColour);
        }
      }

      ::v-deep .claim-info-term {
        background: var(--claimInfoTerm);

        .label {
          color: var(--bodyColour);
        }

        .description {
          color: var(--claimInfoTermText);
        }
      }
    }

    .warning-border {
      border: 2px solid $warning;
      border-radius: 3px;
    }
  }
</style>
