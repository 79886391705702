import { FileCategoryEnum } from '@commonServices/models/FileCategoryEnum';
import { FileIconClaim, FileIconInvoice, FileIconMedicalHistory, FileIconDefault, FileIconMedicalRecordSummary, FileIconDispute, FileIconDeclarationPage, FileIconProofOfSecondaryInsurance, FileIconInboundEmail } from '@assets/icons';

const fileCategoryEnumIcon = new Map([
	[FileCategoryEnum.Invoice, FileIconInvoice],
	[FileCategoryEnum.MedicalCertificate, FileIconMedicalHistory],
	[FileCategoryEnum.Other, FileIconDefault],
	[FileCategoryEnum.ClaimForm, FileIconClaim],
	[FileCategoryEnum.MedicalRecordSummary, FileIconMedicalRecordSummary],
	[FileCategoryEnum.HospitalInvoice, FileIconInvoice],
	[FileCategoryEnum.Dispute, FileIconDispute],
	[FileCategoryEnum.DeclarationPage, FileIconDeclarationPage],
	[FileCategoryEnum.ProofOfSecondaryInsurance, FileIconProofOfSecondaryInsurance],
	[FileCategoryEnum.InboundEmail, FileIconInboundEmail],
]);

export default fileCategoryEnumIcon;
