<template>
	<div class="align-items-center flex-grow-1">
		<div class="flex-grow-1 pr-10">
			<h4 class="fs-13 my-2">
				{{ header }}
			</h4>
			<div
				class="text-truncate"
				v-if="rejectionComment"
				style="max-width: 300px;"
				v-tooltip="rejectionComment"
			>
				{{ rejectionComment }}
			</div>
			<p
				v-if="paragraph"
				class="fs-12"
			>
				{{ paragraph }}
			</p>
		</div>
		<div
			v-if="$can.ViewClaimData"
			class="btn small btn-row text-center"
			@click="onNavigateToClaim"
		>
			<i :class="iconClass" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		iconClass: {
			type: String,
			required: false,
			default: 'fas fa-file-export',
		},
		header: {
			type: String,
			required: true,
		},
		rejectionComment: {
			type: String,
			required: false,
		},
		paragraph: {
			type: String,
			required: false,
		},
	},
	methods: {
		onNavigateToClaim () {
			this.$emit('navigate');
		},
	},
};
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
}
</style>
