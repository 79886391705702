<template>
	<aq-select-filter
		data-qa="filter_input_product"
		class="w-100"
		v-model="filter.value"
		:options="products"
		:loading="productLoading"
		:async-find="searchProduct"
		:label="filter.label"
		:multiple="filter.multiselect"
		:excluded="filter.excluded"
		@toggle-mode="filter.excluded = $event"
		no-result-message="No Products found"
	/>
</template>
<script>

import debounce from 'lodash.debounce';
import ExtraService from '@commonServices/extraService';
import { updateProductOnBrandSelection } from '@commonServices/filterServices';

export default {
	name: 'ProductFilter',
	props: {
		filter: {
			type: Object,
			required: true,
		},
		brand: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data () {
		return {
			productLoading: false,
			products: [],
		};
	},
	mounted () {
		this.products = (this.filter.value ? [this.filter.value] : []).flat();
	},
	watch: {
		'brand.value' (val) {
			if (val.length && this.filter.value) {
				this.filter.value = updateProductOnBrandSelection(this.filter.value, val);
			}
		},
	},
	methods: {
		searchProduct: debounce(async function (term) {
			if (term.length >= 3) {
				this.productLoading = true;
				this.products = (await ExtraService.searchProduct(term))
					.filter(product => !this.brand.value || this.brand.value?.length === 0 || this.brand.value?.some(b => b.id === product.brandId))
					.map(product => ({
						id: product.id,
						description: product.name,
						brandId: product.brandId,
					}),
					);
				this.productLoading = false;
			}
		}, 500),
	},
};
</script>
