<template>
	<div class="d-flex">
		<aq-expandable-card
			v-for="(level, index) of levelItems"
			:key="index"
			:text="level.text"
			:is-active="level.isActive"
			:class="{ 'flex-grow-1': level.isActive }"
			class="expandable-card attribute p-10"
			@click.native="onSwitchLevel(index)"
		>
			<template>
				<aq-icon-avatar
					v-if="level.value === levels.customer"
					class="small flex-shrink-0 cursor-pointer"
					:class="{ 'active' : levelFilter.customerId }"
					:value="[level.details.firstName,level.details.lastName]"
				/>
				<aq-pet-avatar
					v-if="level.value === levels.pet"
					class="small flex-shrink-0 cursor-pointer"
					:class="{ 'active' : levelFilter.petId }"
					:pet="{
						name: level.details.petName,
						type: level.details.petType,
						photo: level.details.petPhoto,
						dateOfDeath: level.details.petDateOfDeath
					}"
				/>
				<div
					v-if="level.value === levels.policy"
					class="card-icon small flex-shrink-0 "
					:class="{ 'active' : levelFilter.policyIds }"
				>
					<i
						class="fas aqv-policy1"
						v-tooltip="level.text"
					/>
				</div>
				<div
					v-if="level.value === levels.claim"
					class="card-icon small flex-shrink-0 "
					:class="{ 'active' : levelFilter.claimId }"
				>
					<i
						class="fas fa-file-invoice"
						v-tooltip="level.text"
					/>
				</div>
			</template>
		</aq-expandable-card>
	</div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import { claimIdFormat } from '@commonServices/utils/filters';
import { unique } from '@commonServices/utils/general';

export default {
	props: {
		showCustomer: {
			type: Boolean,
			default: true,
		},
		showPolicy: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			levels: {
				customer: 'Customer',
				pet: 'Pet',
				claim: 'Claim',
				policy: 'Policy',
			},
		};
	},
	computed: {
		...mapState(['interactionContext', 'levelFilter']),
		levelItems () {
			const levelItems = [];
			const { customer, pet, claim } = this.interactionContext;

			if (customer && this.showCustomer) {
				const customerLevel = {
					details: {
						id: customer.id,
						firstName: customer.firstName,
						lastName: customer.lastName,
					},
					text: `${customer.firstName} ${customer.lastName}`,
					isActive: this.levelFilter.customerId !== null,
					value: this.levels.customer,
				};
				levelItems.push(customerLevel);
			}

			if (pet) {
				const petLevel = {
					details: {
						id: pet.id,
						petName: pet.name,
						petGender: pet.gender,
						petType: pet.type,
						petPhoto: pet.photo,
					},
					text: pet.name,
					isActive: this.levelFilter.petId !== null,
					value: this.levels.pet,
				};
				levelItems.push(petLevel);

				if (this.showPolicy) {
					const policies = pet.policies ? pet.policies.map(p => p.id) : pet.policyTerms.map(term => term.policyId);

					const policyLevel = {
						details: {
							policies,
						},
						text: `${pet.name} policies`,
						isActive: this.levelFilter.policyIds !== null,
						value: this.levels.policy,
					};
					levelItems.push(policyLevel);
				}
			}

			if (claim) {
				const claimLevel = {
					details: {
						id: claim.id,
					},
					isActive: this.levelFilter.claimId !== null,
					text: `Claim ${claimIdFormat(claim.id)}`,
					value: this.levels.claim,
				};
				levelItems.push(claimLevel);
			}
			return levelItems;
		},
	},
	methods: {
		...mapActions(['setLevelFilter']),
		onSwitchLevel (selectedIndex) {
			const selectedLevel = this.levelItems[selectedIndex];
			switch (selectedLevel.value) {
			case this.levels.customer:
				this.setLevelFilter({ customerId: selectedLevel.details.id });
				break;
			case this.levels.pet:
				this.setLevelFilter({ petId: selectedLevel.details.id });
				break;
			case this.levels.policy:
				this.setLevelFilter({ policyIds: unique(selectedLevel.details.policies) });
				break;
			case this.levels.claim:
				this.setLevelFilter({ claimId: selectedLevel.details.id });
				break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .card-icon {
    i {
      font-size: 14px;
    }
  }

  .small:hover {
    width: 28px !important;
    height: 28px !important;
    font-size: 13px !important;
    line-height: 27px !important;
    color: $body-color;
    background: $primary !important;
  }

  .expandable-card {
    transition: flex-grow 0.5s;
    margin: 0 5px;
    min-width: 48px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .card-icon {
      &.icon-avatar:hover {
        color: white;
      }

      &.pet-avatar {
        background: rgba($primary, 0.2);

        &:hover {
          color: white;
        }

        :hover {
          color: white;
        }
      }
    }
  }
</style>
