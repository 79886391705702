export default class ClaimMissingItemInput {
	constructor (claimMissingItemId, missingItemDescription, missingItemId, contactName, contactId, contactType, comment) {
		this.claimMissingItemId = claimMissingItemId;
		this.missingItemDescription = missingItemDescription;
		this.missingItemId = missingItemId;
		this.contactName = contactName;
		this.contactId = contactId;
		this.contactType = contactType;
		this.comment = comment;
	}
}
