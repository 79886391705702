export default class WorkQueueModel {
	constructor (
		brandId,
		brandName,
		brandLocale,
		claimId,
		claimStatus,
		customerFirstName,
		customerLastName,
		customerId,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		vets,
		claimAmount,
		ailments,
		requestedBy,
		dateRequested,
		policyStatus,
		policies,
		policyStatusDisplay,
		assignedTo,
		workQueueType,
		latestDashboardNote,
		latestInteraction,
		missingInfoCompleted,
	) {
		this.brandId = brandId;
		this.brandName = brandName;
		this.brandLocale = brandLocale;
		this.claimId = claimId;
		this.claimStatus = claimStatus;
		this.customerFirstName = customerFirstName;
		this.customerLastName = customerLastName;
		this.customerId = customerId;
		this.petId = petId;
		this.petName = petName;
		this.petType = petType;
		this.petGender = petGender;
		this.petDateOfDeath = petDateOfDeath;
		this.vets = vets;
		this.claimAmount = claimAmount;
		this.ailments = ailments;
		this.requestedBy = requestedBy;
		this.dateRequested = dateRequested;
		this.policyStatus = policyStatus;
		this.policies = policies;
		this.policyStatusDisplay = policyStatusDisplay;
		this.assignedTo = assignedTo;
		this.workQueueType = workQueueType;
		this.latestDashboardNote = latestDashboardNote;
		this.latestInteraction = latestInteraction;
		this.missingInfoCompleted = missingInfoCompleted;
	}
}
