import PetService from '@commonServices/petService';
import { mapState } from 'vuex';
import { shortDateTime } from '@commonServices/utils/filters';

const diagnosisConditionsMixin = {
	data () {
		return {
			tabItems: [],
			activeTab: undefined,
			selectedCondition: null,
			showMoreList: [],
		};
	},
	props: {
		conditions: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState(['currentUser', 'interactionContext']),
		isActiveTab () {
			return this.activeTab.value === 'active';
		},
		isArchivedTab () {
			return this.activeTab.value === 'archived';
		},
		filteredConditions () {
			if (this.isActiveTab) {
				return this.conditions.filter(cond => !cond.archived).sort(PetService.conditionsComparer);
			} else if (this.isArchivedTab) {
				return this.conditions.filter(cond => cond.archived).sort(PetService.conditionsComparer);
			}
			return [];
		},
	},
	beforeMount () {
		this.tabItems = [
			{
				value: 'active',
				label: 'Active',
				active: true,
			},
			{
				value: 'archived',
				label: 'Archived',
				active: false,
			},
		];
		this.activeTab = this.tabItems[0];
	},
	methods: {
		onSelectTab (value) {
			this.hideAllShowMore();
			this.activeTab = this.tabItems.find(item => item.value === value);
		},
		onCancel () {
			this.$emit('cancel');
		},
		getSubName (condition) {
			return `Created ${shortDateTime(condition.createdDate)}`;
		},
		getSubNameTooltip (condition) {
			return condition.updatedBy ? `Updated ${shortDateTime(condition.updatedDate)} by ${condition.updatedBy.firstName} ${condition.updatedBy.lastName}` : '';
		},
		onUpdateShowMoreList (id, event) {
			this.$set(this.showMoreList, id, event);
		},
		hideAllShowMore () {
			this.showMoreList = [];
		},
	},
};

export default diagnosisConditionsMixin;
