const NotificationType = Object.freeze({
	None: 0,
	SettledClaimPendingApproval: 1,
	SettledClaimApproved: 2,
	SettledClaimRejected: 3,
	ClaimRejectionPendingApproval: 4,
	ClaimRejectionApproved: 5,
	ClaimRejectionRejected: 6,
	FraudCheckPendingApproval: 7,
	FraudCheckPassed: 8,
	FraudCheckFailed: 9,
	ClaimStillChased: 10,
	ClaimNoLongerChased: 11,
	TaskAssigned: 12,
	TaskUnAssigned: 13,
	ClaimCreated: 14,
});

const NotificationMessage = Object.freeze({
	[NotificationType.SettledClaimPendingApproval]: claimIdFormatted => `Claim Payment for ${claimIdFormatted} is pending approval`,
	[NotificationType.SettledClaimApproved]: claimIdFormatted => `Claim Payment for ${claimIdFormatted} has been approved`,
	[NotificationType.SettledClaimRejected]: claimIdFormatted => `Claim Payment for ${claimIdFormatted} has been rejected`,
	[NotificationType.ClaimRejectionPendingApproval]: claimIdFormatted => `Claim Rejection for ${claimIdFormatted} is pending approval`,
	[NotificationType.ClaimRejectionApproved]: claimIdFormatted => `Claim Rejection for ${claimIdFormatted} has been approved`,
	[NotificationType.ClaimRejectionRejected]: claimIdFormatted => `Claim Rejection for ${claimIdFormatted} has been rejected`,
	[NotificationType.FraudCheckPendingApproval]: claimIdFormatted => `Pending Fraud Check for ${claimIdFormatted}`,
	[NotificationType.FraudCheckPassed]: claimIdFormatted => `Fraud Check Passed for ${claimIdFormatted}`,
	[NotificationType.FraudCheckFailed]: claimIdFormatted => `Fraud Check Failed for ${claimIdFormatted}`,
	[NotificationType.ClaimStillChased]: claimIdFormatted => `Missing Information has been returned for ${claimIdFormatted}`,
	[NotificationType.ClaimNoLongerChased]: claimIdFormatted => `All Missing Information has been returned for ${claimIdFormatted}`,
	[NotificationType.TaskAssigned]: claimIdFormatted => `Task Assigned for ${claimIdFormatted}`,
	[NotificationType.TaskUnAssigned]: claimIdFormatted => `Task Unassigned for ${claimIdFormatted}`,
	[NotificationType.ClaimCreated]: claimIdFormatted => `Claim Created ${claimIdFormatted}`,
});

const NotificationDescription = Object.freeze({
	[NotificationType.SettledClaimPendingApproval]: 'Claim Payment is pending approval',
	[NotificationType.SettledClaimApproved]: 'Claim Payment has been approved',
	[NotificationType.SettledClaimRejected]: 'Claim Payment has been rejected',
	[NotificationType.ClaimRejectionPendingApproval]: 'Claim Rejection is pending approval',
	[NotificationType.ClaimRejectionApproved]: 'Claim Rejection has been approved',
	[NotificationType.ClaimRejectionRejected]: 'Claim Rejection has been rejected',
	[NotificationType.FraudCheckPendingApproval]: 'Fraud Check is pending approval',
	[NotificationType.FraudCheckPassed]: 'Fraud Check has passed',
	[NotificationType.FraudCheckFailed]: 'Fraud Check has failed',
	[NotificationType.ClaimStillChased]: 'Claim is still being chased',
	[NotificationType.ClaimNoLongerChased]: 'Claim is no longer being chased',
	[NotificationType.TaskAssigned]: 'Task has been assigned',
	[NotificationType.TaskUnAssigned]: 'Task has been unassigned',
	[NotificationType.ClaimCreated]: 'Claim Created',
});

export { NotificationType, NotificationMessage, NotificationDescription };
