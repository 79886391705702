<template>
	<div
		class="position-absolute d-flex"
	>
		<div
			data-qa="timeLinePeriod_button_period"
			class="time-line-period"
			:class="[
				getTypeClass('period', state),
				{'selected': (selected)},
				selectedElement !== 'period' ? 'cursor-pointer' : 'cursor-default'
			]"
			@click="selectElement('period')"
		/>
		<div
			data-qa="timeLinePeriod_button_startMark"
			v-if="showStartMark"
			class="time-line-mark"
			:class="[getTypeClass('mark', state), (selected && selectedElement !== 'start') ? 'cursor-pointer' : 'cursor-default']"
			@click="selectElement('start')"
		/>
		<div
			data-qa="timeLinePeriod_button_endMark"
			v-if="showEndMark"
			class="time-line-mark end-mark"
			:class="[getTypeClass('mark', state), (selected && selectedElement !== 'end') ? 'cursor-pointer' : 'cursor-default']"
			@click="selectElement('end')"
		/>
	</div>
</template>

<script>

export default {
	name: 'AqTimeLinePeriod',
	props: {
		state: {
			type: String,
			required: false,
			default: 'default',
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		showStartMark: {
			type: Boolean,
			required: false,
			default: false,
		},
		showEndMark: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			selectedElement: 'period',
		};
	},
	methods: {
		selectElement (element) {
			if (this.selectedElement !== element) {
				this.selectedElement = element;
				this.$emit('select', element);
			}
		},
		getTypeClass (baseclass, type) {
			switch (type) {
			case 'active': return `${baseclass}-active`;
			case 'danger': return `${baseclass}-danger`;
			case 'warning': return `${baseclass}-warning`;
			default: return `${baseclass}-default`;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.time-line-period {
  border-radius: 8px;
  flex-grow: 1;
  opacity: 0.35;

  &.selected {
    opacity: 1;
  }
}

.period-active {
  background-color: rgba($primary, 0.7);
}

.period-danger {
  background: rgba($danger, 0.7);
}

.period-warning {
  background: rgba($warning, 0.7);
}

.period-default {
  background: rgba($gray, 0.7);
}

.time-line-mark {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;

  &.end-mark {
    left: calc(100% - 8px);
  }

  &.mark-active {
    background-color: $primary;
    box-shadow: 0 0 1px 5px rgba($primary, 0.4);
  }

  &.mark-warning {
    background-color: $warning;
    box-shadow: 0 0 1px 5px rgba($warning, 0.4);
  }

  &.mark-danger {
    background-color: $danger;
    box-shadow: 0 0 1px 5px rgba($danger, 0.4);
  }

  &.mark-default {
    background-color: $gray;
    box-shadow: 0 0 1px 5px rgba($gray, 0.4);
  }
}
</style>
