<template>
	<aq-panel-wrapper :use-overlay="useOverlay">
		<div
			class="panel-container"
			:class="{'scan-page': isScanOpen}"
		>
			<aq-confirmation-modal
				ref="copyInvoiceModal"
				name="confirm-copy-invoice"
				title="Copy Vet Invoice?"
				:description="copyInvoiceModalDescription"
				data-qa="documentPanel_modal_copyInvoice"
			/>
			<!-- max-width not working for resizing to the left, component doesn't work for max-width
				when inner 'left' property, that depends on :left is less than 0,
				can be fixed by expanding panel-container div width to 100%
				setting :width and calculating :left to offset panel to the right side of screen -->
			<div class="panel">
				<div class="h-100 d-flex flex-column">
					<template v-if="documentAreaAvailable">
						<div
							class="header upload-section position-relative"
							:class="{
								'minimised': !isUploadExpanded || tempFile,
								'selected': selectedDoc && !tempFile
							}"
						>
							<template v-if="selectedDoc && !tempFile">
								<button
									class="btn btn-primary upload-toggle large"
									@click.stop="selectedDoc = null"
									data-qa="documentPanel_button_unselectDocument"
								>
									Unselect Document
								</button>
								<div class="file-options p-20 mt-50">
									<div class="d-flex">
										<button
											v-if="isPreviewAvailable"
											class="btn btn-panel py-15 mb-16 w-100 d-flex align-items-center justify-content-center"
											@click.stop="onPreviewDocument"
											data-qa="documentPanel_button_previewDocument"
										>
											<i class="fas fa-eye mr-10" />
											<span class="d-block mt-4">
												Preview
											</span>
										</button>
										<div
											class="ml-10 mb-16 w-100"
											v-if="isScanAvailable"
											v-tooltip="disabledScanBtnTooltip"
										>
											<button
												class="btn btn-panel  py-15 w-100 d-flex align-items-center justify-content-center"
												@click.stop="onScanDocument(selectedDoc.id)"
												data-qa="documentPanel_button_scanDocument"
												:disabled="isScanButtonDisabled"
											>
												<i class="fas fa-search mr-10" />
												<span class="d-block mt-4">
													{{ scanButtonText }}
												</span>
											</button>
										</div>
									</div>
									<button
										class="btn btn-panel py-15 w-100 d-flex align-items-center justify-content-center"
										@click.stop="onDownloadFile(selectedDoc)"
										data-qa="documentPanel_button_downloadDocument"
									>
										<i class="fas fa-cloud-download-alt d-block mr-10" />
										<span class="d-block mt-4">
											Download
										</span>
									</button>
								</div>
							</template>
							<template v-else-if="this.$can.FilesManagement">
								<button
									data-qa="documentPanel_button_toggleCollapse"
									class="btn btn-primary upload-toggle"
									:class="[isUploadExpanded ? 'small' : 'large']"
									@click.stop="isUploadExpanded = !isUploadExpanded"
									v-if="!tempFile"
								>
									{{ isUploadExpanded ? 'Hide' : 'Expand Upload Area' }}
								</button>
								<aq-file-upload
									@file-added="onFileAdded($event)"
									:claim-id="claimId"
									:temp-file="tempFile"
									:temp-files="tempFiles"
									:is-upload-expanded="isUploadExpanded"
									class="pt-30 px-20 pb-40"
								/>
							</template>
						</div>
						<aq-level-switcher
							class="p-10"
							:class="{ 'display-none': tempFile }"
						/>
						<document-list
							v-if="!tempFile"
							:collapsed="!isUploadExpanded"
							:selected="selectedDoc && !tempFile"
							:uploaded-files="uploadedFiles"
							:selected-doc="selectedDoc"
							:requested-files="requestedFiles"
							:is-read-only-mode="isClaimReadOnly"
							@edit="onEditDocument"
							@select="selectedDoc = $event"
						/>
						<document-edit
							v-else
							:file="tempFile"
							:requested-files="requestedFiles"
							:is-claim-context="isClaimContext"
							:is-pet-context="isPetContext"
							:is-read-only-mode="isClaimReadOnly"
							@cancel="onCancelEdit"
							@finish="onFinishDocument"
							@delete="onConfirmDelete"
							class="flex-grow-1"
						/>
					</template>
					<template v-else>
						<div class="mt-5" v-if="isScanOpen && selectedDoc">
							<AqCardModeHeader class="w-100" title="Invoice Scan" />
							<div class="d-flex pet-info-wrapper">
								<customer-card-info
									class="position-relative bordered-wrapper breadcrumb-header--bordered flex-1 min-width-0"
									:class="{'special-highlight-card': requireSpecialLicensing(customer.address.regionCounty)}"
									:user="customer"
									disable-customer-link
								/>
								<template v-if="selectedPet">
									<pet-card-info
										class="position-relative bordered-wrapper breadcrumb-header--bordered flex-1 min-width-0"
										:pet="selectedPet"
										:pets="customer.pets"
										:customer-id="customer.id"
										:policy-term="currentPolicyTerm"
										selectable
										disable-pet-link
									/>
									<bordered-button-wrapper
										:btn-label="false"
										content-class="breadcrumb-header"
										class="px-0 flex-1 bordered-product min-width-0"
										:class="{'bordered-product--expanded': !isExpandCardMode }"
									>
										<template #content>
											<product-card-info
												:pet="selectedPet"
												@update-policy-term="onUpdatePolicyTerm"
											/>
										</template>
									</bordered-button-wrapper>
								</template>
							</div>
						</div>
						<div class="d-flex h-100 w-100 overflow-hidden">
							<div
								class="d-flex flex-column navbar"
								v-if="showSideMenu"
							>
								<button
									class="btn btn-nav d-flex flex-column align-items-center justify-content-center py-15"
									@click="onClose"
								>
									<IconBack class="mb-5 navbar-icon" /> Back
								</button>
								<button
									v-if="!showDocumentPreview"
									class="btn btn-nav d-flex flex-column align-items-center justify-content-center py-15"
									@click="showDocumentPreview=true"
								>
									<IconInvoice class="mb-5 navbar-icon" /> Invoice
								</button>
								<button
									class="btn btn-nav d-flex flex-column align-items-center justify-content-center py-15"
									@click="openClaimHistory"
								>
									<FileIconClaim class="file-icon clear" />Claim History
								</button>
							</div>
							<document-preview
								ref="docPreview"
								v-if="selectedDoc && (documentSrc || emailData)"
								class="document-preview d-flex flex-column"
								:class="{'document-preview--hide': !showDocumentPreview, 'w-100': !showSideMenu}"
								:doc-src="documentSrc"
								:file-name="selectedDoc.fileName"
								:is-preview-scan-available="isPreviewScanAvailable"
								:show-close-button="showCloseButton"
								:show-side-menu="showSideMenu"
								@close="onClosePreview"
								@hide="showDocumentPreview=false"
								@scan="onScanDocument(selectedDoc.id)"
								:highlight-area="highlightArea"
								:doc-mime-type="docMimeType"
								:email-data="emailData"
							/>
							<div
								class="h-100 line-items"
								:class="{'w-100':!showDocumentPreview}"
								v-if="isScanOpen"
							>
								<component
									v-if="selectedDoc"
									class="h-100"
									:is="getCategoryComponent()"
									:file="selectedDoc"
									:locale="brand.brandLocale"
									:multicondition="brand.multicondition"
									:claim-id="claimId"
									@highlight-doc="onHighlightDocument"
									:is-read-only-mode="isReadOnlyMode"
									@show-close-button="onShowCloseButton"
									@show-document-preview="showDocumentPreview = true"
								/>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
import FileService from '@commonServices/fileService';
import ClaimService from '@commonServices/claimService';
import fileCategoriesMap from '@commonServices/settings/fileCategoriesMap';
import FilePreview from '@commonServices/models/FilePreview';
import StepNameEnum from '@commonServices/models/steps/StepNameEnum';
import { mapState, mapGetters } from 'vuex';
import eventBus from '@commonServices/eventBus';
import { FileCategoryEnum } from '@commonServices/models/FileCategoryEnum';
import { formatBytes } from '@commonServices/utils/filters';
import { getFileType, FileTypes, MimeTypes, getFileExtension } from '@commonServices/utils/general';
import AqLevelSwitcher from '@commonView/Shared/AqLevelSwitcher';
import VueResizable from 'vue-resizable';
import { IconBack, IconInvoice, FileIconClaim } from '@assets/icons';
import BorderedButtonWrapper from '@commonView/CustomerPage/PetsLayout/BorderedButtonWrapper';
import AqCardModeHeader from '@commonWidgets/AqCardModeHeader';
import { openPetNewWindow } from '@commonServices/utils/routerUtils';

export default {
	name: 'DocumentPanel',
	components: {
		AqLevelSwitcher,
		VueResizable,
		IconBack,
		IconInvoice,
		FileIconClaim,
		BorderedButtonWrapper,
		AqCardModeHeader,
	},
	data () {
		return {
			documentSrc: undefined,
			isScanOpen: false,
			highlightArea: null,
			selectedDoc: null,
			tempFile: null,
			fileInput: null,
			scanFileId: this.defaultScanFileId,
			isUploadExpanded: true,
			uploadedFiles: [],
			isUploadInProgress: false,
			tempFiles: [],
			requestedFiles: [],
			useOverlay: true,
			copyInvoiceModalDescription: '',
			level: null,
			resizable: {
				width: 450,
				left: 0,
				minWidth: 450,
			},
			emailData: null,
			showDocumentPreview: true,
			showCloseButton: true,
			showSideMenu: true,
			currentPolicyTerm: null,
			customer: null,
			selectedPet: null,
		};
	},
	props: {
		defaultScanFileId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	mounted () {
		if (this.scanFileId) {
			this.onScanDocument(this.scanFileId);
		}

		this.customer = { ...this.interactionContext.customer, brandId: this.brand.brandId, brandName: this.brand.brandName };
		this.selectedPet = this.interactionContext.customer.pets.find(pet => pet.id === this.interactionContext.pet.id);
	},
	watch: {
		isScanOpen (nextValue, previousValue) {
			if (previousValue && !nextValue && this.isClaimContext) { // if scan panel was just closed
				this.loadClaimFiles();
			}
		},
		levelFilter: {
			handler: function () {
				this.loadFiles();
			},
			immediate: true,
		},
	},
	computed: {
		...mapState(['currentClaimStepName', 'appSettings', 'interactionContext', 'brand', 'levelFilter']),
		...mapGetters(['requireSpecialLicensing', 'isExpandCardMode']),
		isClaimReadOnly () {
			if (this.levelFilter.claimId) {
				return this.interactionContext.claim.isReadOnly;
			}
			return false;
		},
		isPreviewAvailable () {
			if (this.selectedDoc?.externalDocUrl != null) return false;

			if (this.selectedDoc?.category === FileCategoryEnum.InboundEmail) {
				return true;
			}

			return this.selectedDoc
			&& [FileCategoryEnum.ClaimForm, FileCategoryEnum.Invoice, FileCategoryEnum.MedicalCertificate, FileCategoryEnum.Other, FileCategoryEnum.MedicalRecordSummary, FileCategoryEnum.HospitalInvoice].includes(this.selectedDoc.category)
			&& this.isFileTypeScannable;
		},
		isScanAvailable () {
			if (!this.isClaimContext) return false;
			if (this.selectedDoc.externalDocUrl != null) return false;

			if (this.selectedDoc && this.isFileTypeScannable && (!this.isClaimReadOnly || this.selectedDoc.scanned)) {
				return (this.selectedDoc.category === FileCategoryEnum.ClaimForm &&	this.$can.ScanClaimForm)
				|| (this.selectedDoc.category === FileCategoryEnum.Invoice && (this.$can.ScanVetInvoice || this.canViewScannedData))
				|| (this.selectedDoc.category === FileCategoryEnum.HospitalInvoice);
			}
			return false;
		},
		isScanButtonDisabled () {
			const isClaimFormScanDisabled = this.selectedDoc.category === FileCategoryEnum.ClaimForm && this.currentClaimStepName !== StepNameEnum.ClaimEnterBasic;
			const isInvoiceScanDisabled = this.selectedDoc.category === FileCategoryEnum.Invoice && this.currentClaimStepName !== StepNameEnum.ClaimProcess;
			if ((!this.appSettings.fileScanEnabled && !this.selectedDoc.scanned)
				|| (this.isScanAvailable && !this.selectedDoc.scanned && (isClaimFormScanDisabled || isInvoiceScanDisabled))) {
				return true;
			}
			return false;
		},
		disabledScanBtnTooltip () {
			if (!this.isScanButtonDisabled) {
				return null;
			}
			return this.appSettings.fileScanEnabled || !this.canViewScannedData ? `To scan the data please proceed to the ${this.selectedDoc.category === FileCategoryEnum.Invoice ? 'Process' : 'Enter'} page`
				: 'File Scan is not available';
		},
		isFileTypeScannable () {
			return [FileTypes.pdf, FileTypes.image].includes(getFileType(this.selectedDoc.fileName)) || this.selectedDoc?.category === FileCategoryEnum.HospitalInvoice;
		},
		isClaimContext () {
			return !!this.claimId;
		},
		isPetContext () {
			return !!this.petId;
		},
		isCustomerContext () {
			return !!this.customerId;
		},
		claimId () {
			return this.levelFilter.claimId;
		},
		petId () {
			return this.levelFilter.petId;
		},
		customerId () {
			return this.levelFilter.customerId;
		},
		docMimeType () {
			return MimeTypes[getFileExtension(this.selectedDoc.fileName)];
		},
		resizeActive () {
			return (this.documentSrc && !this.isScanOpen) || this.emailData ? ['l'] : null;
		},
		documentAreaAvailable () {
			return !this.documentSrc && !this.isScanOpen && !this.emailData;
		},
		isPreviewScanAvailable () {
			return !this.isScanOpen && this.isScanAvailable && !this.isScanButtonDisabled;
		},
		canViewScannedData () {
			return this.$can.ViewScannedData;
		},
		scanButtonText () {
			return this.selectedDoc.scanned ? 'View Data' : 'Scan';
		},
		isReadOnlyMode () {
			const isReadOnly = this.isClaimReadOnly || !this.appSettings.fileScanEnabled;
			if (this.selectedDoc.category === FileCategoryEnum.Invoice || this.selectedDoc.category === FileCategoryEnum.HospitalInvoice) {
				return isReadOnly || !this.$can.EditInvoiceRecognition || !this.$can.PopulateClaimItemsFromInvoiceScan;
			}
			return isReadOnly;
		},
	},
	methods: {
		loadFiles () {
			if (this.isClaimContext) {
				return this.loadClaimFiles();
			} else if (this.isPetContext) {
				return this.loadPetFiles();
			} else if (this.isCustomerContext) {
				return this.loadCustomerFiles();
			}
		},
		async loadClaimFiles () {
			const [uploadedFiles, requestedFiles] = await Promise.all([FileService.getClaimFiles(this.claimId), ClaimService.getClaimMissingItems(this.claimId)]);
			this.uploadedFiles = uploadedFiles;
			this.requestedFiles = requestedFiles;
		},
		async loadPetFiles () {
			this.uploadedFiles = await FileService.getPetFiles(this.petId);
		},
		async loadCustomerFiles () {
			this.uploadedFiles = await FileService.getCustomerFiles(this.customerId);
		},
		async onFinishDocument (document) {
			if (this.fileInput) {
				let copyInvoice = false;
				if (document.category === FileCategoryEnum.Invoice && this.isClaimContext) {
					const linkedData = await ClaimService.getLinkedClaimData(this.claimId);
					if (linkedData && linkedData.linkedClaims.length) {
						let message = `The system has detected a linked ${linkedData.linkedClaims.length > 1 ? 'claims' : 'claim'}. Do you wish to Copy the Vet Invoice to `;
						message += linkedData.linkedClaims.map(linkedClaim => `${linkedClaim.claimId} - ${linkedClaim.ailment ? linkedClaim.ailment : 'no ailment'}`).join('; ');
						this.copyInvoiceModalDescription = message + '?';

						const userChoice = await this.$refs.copyInvoiceModal.show();
						if (userChoice === 'yes') {
							copyInvoice = true;
						} else if (userChoice === 'cross') {
							return;
						}
					}
				}
				this.uploadFile(this.fileInput, document, copyInvoice);
			} else {
				await FileService.updateFileMetadata(this.tempFile.id, document, this.isClaimContext);

				const itemIndex = this.uploadedFiles.findIndex((item) => item.id === this.tempFile.id);
				this.selectedDoc = this.uploadedFiles[itemIndex];
				this.selectedDoc.category = document.category;
				if (document.category !== FileCategoryEnum.Invoice && this.isClaimContext) {
					eventBus.$emit('delete-claim-items', { fileId: this.tempFile.id });
				}
				this.tempFile = null;
				this.isUploadExpanded = true;
			}
			await this.loadFiles();
		},
		uploadFile (file, document, copyInvoice) {
			this.isUploadInProgress = true;
			this.$emit('changeUploadStatus', this.isUploadInProgress);

			const tempFile = this.tempFile;
			this.tempFiles.push(tempFile);
			const formData = new FormData();
			formData.append('file', file);
			const vm = this;

			let uploadFn = null;
			if (this.isClaimContext) {
				uploadFn = FileService.uploadClaimFile.bind(this, formData, this.claimId, document, copyInvoice);
			} else if (this.isPetContext) {
				uploadFn = FileService.uploadPetFile.bind(this, formData, this.petId, document);
			} else {
				uploadFn = FileService.uploadCustomerFile.bind(this, formData, this.customerId, document);
			}
			const uploadFnCallback = (progressEvent) => {
				const percent = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
				vm.$set(tempFile, 'percent', percent);
			};
			uploadFn(uploadFnCallback)
				.then(fileId => {
					this.selectedDoc = this.tempFile;
					this.selectedDoc.id = fileId;
					this.selectedDoc.category = document.category;
					this.selectedDoc.description = document.description;
					this.selectedDoc.vet = document.vet;
					this.selectedDoc.createDate = new Date();
					this.uploadedFiles.push(this.selectedDoc);
				}).finally(() => {
					this.restorePanel();
					this.$emit('changeUploadStatus', this.isUploadInProgress);
				});
		},
		onEditDocument (filePreview, fileInput) {
			this.tempFile = { ...filePreview, claimId: this.claimId, petId: this.petId, customerId: this.customerId };
			this.fileInput = fileInput;
			this.isUploadExpanded = false;
		},
		onCancelEdit () {
			this.tempFile = null;
			this.fileInput = null;
			this.isUploadExpanded = true;
		},
		async onConfirmDelete (file) {
			const result = await FileService.deleteFile(file.id);
			if (result && this.isClaimContext) {
				eventBus.$emit('delete-claim-items', { fileId: file.id });
			}
			const itemIndexToDelete = this.uploadedFiles.findIndex((item) => item.id === file.id);
			this.uploadedFiles.splice(itemIndexToDelete, 1);
			this.restorePanel();
		},
		async onPreviewDocument () {
			if (this.selectedDoc.externalDocUrl) {
				window.open(this.selectedDoc.externalDocUrl, '_blank');
			} else if (this.selectedDoc.category === FileCategoryEnum.InboundEmail) {
				this.emailData = await FileService.getEmailData(this.selectedDoc.id);
			} else {
				this.documentSrc = await FileService.getTempSrc(this.selectedDoc.id, this.docMimeType);
				this.useOverlay = false;
				this.showSideMenu = false;
				this.showDocumentPreview = true;
				this.showCloseButton = false;
			}
		},
		onClose () {
			this.onClosePreview();
			this.hidePanel();
		},
		onClosePreview: function () {
			this.documentSrc = undefined;
			this.isScanOpen = false;
			this.useOverlay = true;
			this.resizable.width = this.resizable.minWidth;
			this.resizable.left = 0;
			this.emailData = null;
			this.showDocumentPreview = false;
		},
		onFileAdded (fileInput) {
			const fileSize = formatBytes(fileInput.size);
			const filePreview = new FilePreview(fileInput.name, fileSize);
			this.onEditDocument(filePreview, fileInput);
		},
		hidePanel () {
			if (this.useOverlay) this.$emit('hide-panel');
		},
		async onScanDocument (scanFileId) {
			if (this.selectedDoc?.externalDocUrl != null) {
				return;
			}

			this.isScanOpen = true;
			if (!this.selectedDoc) {
				await this.loadFiles();
				this.selectedDoc = this.uploadedFiles.find((file) => file.id === this.scanFileId);
			}
			this.scanFileId = scanFileId;
			this.documentSrc = await FileService.getTempSrc(this.scanFileId, this.docMimeType);
			this.selectedDoc.scanned = true;
			this.showSideMenu = true;
			this.showCloseButton = true;
		},
		async onDownloadFile (file) {
			if (file.externalDocUrl) {
				window.open(file.externalDocUrl, '_blank');
			} else {
				await FileService.downloadFile(file.id, file.fileName, MimeTypes[getFileExtension(file.fileName)]);
			}
		},
		getCategoryComponent () {
			return fileCategoriesMap.get(this.selectedDoc?.category);
		},
		onHighlightDocument ({ boundingBox, page }) {
			if (boundingBox && boundingBox.length) {
				this.highlightArea = {
					page,
					points: {
						topLeft: { x: boundingBox[0], y: boundingBox[1] },
						bottomRight: boundingBox.length === 4 ? { x: boundingBox[2], y: boundingBox[3] } : { x: boundingBox[4], y: boundingBox[5] },
					},
				};
				this.page = page;
			} else {
				this.highlightArea = null;
			}
		},
		onResize (data) {
			this.resizable.width = data.width;
			this.resizable.left = data.left;
		},
		restorePanel () {
			this.tempFile = null;
			this.tempFiles = [];
			this.fileInput = null;
			this.isUploadExpanded = true;
			this.isUploadInProgress = false;
			this.selectedDoc = null;

			if (this.isClaimContext) {
				ClaimService.getClaimMissingItems(this.claimId).then(files => {
					this.requestedFiles = files;
				});
			}
		},
		onShowCloseButton (value) {
			this.showCloseButton = value;
		},
		openClaimHistory () {
			openPetNewWindow(this.$router, this.interactionContext.customer.id, this.interactionContext.pet.id);
		},
		onUpdatePolicyTerm (policyTerm) {
			this.currentPolicyTerm = policyTerm;
		},
	},
	provide () {
		return {
			hideDocumentPanel: this.hidePanel,
		};
	},
};
</script>

<style lang="scss" scoped>
.scan-page {
  width: calc(100% - 80px);
}

.upload-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  transition: 0.4s ease;
  width: 55px;
  overflow: hidden;
  white-space: nowrap;

  &.large {
    width: calc(100% - 40px);
  }
}

.file-options {
  .btn {
    i {
      color: $primary;
      font-size: 30px;
      transition: 0.2s ease;
    }

    &:hover {
      i {
        color: $body-color;
      }
    }
  }
}

.upload-section {
  margin-right: -10px;
  overflow: hidden;
  flex: 0 0 370px;
  transition: flex 0.3s;

  &.minimised {
    flex: 0 0 77px;

    .upload {
      display: none;
    }
  }

  &.closed {
    height: 0;
  }

  &.selected {
    flex: 0 0 230px;
  }
}

.display-none {
  display: none !important;
}

.document-preview {
  width: 40%;
  transition: all .3s ease-in-out;

  &--hide {
    width: 0;
    opacity: 0;
  }

  :deep(canvas) {
    width: 100%!important;
    height: 100%!important;
  }
}

.line-items {
  width: 60%;
}

.navbar{
  background-color: $primary;
  width: 80px;

  &-icon{
    width: 30px;
  }
}

.btn-nav {
  background-color: transparent;
  color: $body-color;

  &:hover {
    transform: scale(1.05);
  }
}

.pet-info-wrapper {
  background: var(--contentBg);
  padding: 15px 15px 20px;
  display: flex;
  flex-basis: auto;
  gap: 15px;

  ::v-deep .title {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.06em;
    font-weight: bold;
  }

  .bordered-product {
    z-index: 1;

    &--expanded{
      &:hover {
        min-width: fit-content;
      }
    }
  }
}
</style>
