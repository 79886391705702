import claimItemsValidationResultEnum from '@commonServices/models/ClaimItemsValidationResultEnum';

const pageStatusEnum = Object.freeze({
	Success: 'success',
	Warning: 'warning',
	Incomplete: 'incomplete',
	Danger: 'danger',
});

const allPageStatuses = [
	pageStatusEnum.Success,
	pageStatusEnum.Warning,
	pageStatusEnum.Incomplete,
	pageStatusEnum.Danger,
];

const invalidPageStatuses = [
	pageStatusEnum.Incomplete,
	pageStatusEnum.Danger,
];

const claimItemsValidationResultToPageStatusMap = Object.freeze({
	[claimItemsValidationResultEnum.PROCEED]: pageStatusEnum.Success,
	[claimItemsValidationResultEnum.INVALID]: pageStatusEnum.Danger,
	[claimItemsValidationResultEnum.INVALID_TOTAL_AMOUNT]: pageStatusEnum.Danger,
	[claimItemsValidationResultEnum.REJECTED_BY_NO_POLICY_COVER]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_BY_NO_PRODUCT_COVER]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_MANUALLY]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_WAITING_PERIOD]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_BY_PREEXISTING_CONDITION]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_BY_RELATED_CONDITION]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_SECTION_LIMIT]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_BY_CONDITION_LIMIT]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_BY_MULTIPLE_CONDITIONS]: pageStatusEnum.Warning,
	[claimItemsValidationResultEnum.REJECTED_MULTIPLE]: pageStatusEnum.Warning,
});

export {
	pageStatusEnum, allPageStatuses, invalidPageStatuses, claimItemsValidationResultToPageStatusMap,
};
