<template>
	<aq-table-popover
		v-if="policyTerm.policyOffer"
		class="popover text-left"
		placement="left"
	>
		<table
			class="introducer-popover-table"
			aria-label="Introducer Info"
		>
			<tr>
				<th>Offer Type</th>
				<td>{{ policyTerm.policyOfferType }}</td>
			</tr>
			<tr>
				<th>Associate Name</th>
				<td>{{ policyTerm.policyAssociateName }}</td>
			</tr>
			<tr>
				<th>Promo Code</th>
				<td>{{ policyTerm.policyPromoCode }}</td>
			</tr>
		</table>
		<template slot="title">
			<div class="d-flex introducer-header-text cursor-pointer">
				<i class="aqv-line-discount" />
				<span class="text-truncate text-underline">
					{{ policyTerm.policyOffer }}
				</span>
			</div>
		</template>
	</aq-table-popover>
</template>

<script>
import AqTablePopover from '@/clients/clientCommon/components/widgets/AqTablePopover';

export default {
	name: 'IntroducerInfo',
	components: {
		AqTablePopover,
	},
	props: {
		policyTerm: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss">
.policy-details-popover {
  top: -10px !important;
  left: -8px !important;
}

.introducer-popover-table {
  td,
  th {
    height: 38px;
  }

  th {
    width: 150px;
    text-align: left;
    padding-left: 10px;
  }

  td {
    padding: 10px;
    width: 350px;
    background: var(--bodyBg);
  }
}

.introducer-header-text {
  max-width: 110px;
}
</style>

<style lang="scss" scoped>
.aqv-line-discount {
  transform: rotate(90deg);
}
</style>
