<template>
	<div>
		<aq-modal name="user-assignment">
			<task-assignment-modal
				:user-options="userOptions"
				:prioritized-queue="isPrioritized"
				@assign-user="onAssignUser"
			/>
		</aq-modal>
		<div class="current-assignment">
			<div
				class="d-inline-flex"
				v-if="assignedUser"
			>
				Currently Assigned to
				<div
					class="font-weight-bold px-4"
					:class="{ 'text-underline cursor-pointer': hasAssignmentPermission}"
					@click="onOpenModal"
				>
					{{ assignedUser.fullName }}
				</div>
				<div
					v-if="taskType"
					class="d-inline-flex"
				>
					for
					<div class="font-weight-bold px-4">
						{{ taskType }}
					</div>
				</div>
			</div>
			<div
				v-else
				class="d-inline-flex"
			>
				Currently
				<div
					class="font-weight-bold px-4"
					:class="{ 'text-underline cursor-pointer': hasAssignmentPermission }"
					@click="onOpenModal"
				>
					Unassigned
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import TaskAssignmentModal from '@commonView/TasksPage/TaskAssignmentModal';
import { sortComparer } from '@commonServices/utils/general';
import SortingDirection from '@clientCommon/services/models/SortingDirection';
import UsersService from '@clientCommon/services/usersService';
import WorkflowService from '@commonServices/workflowService';
import { getTaskNameByStatus } from '@commonServices/utils/workflowConverter';
import { claimNumberStatusToPotTypeMap } from '@commonServices/models/ClaimStatusActionEnum';

export default {
	name: 'CurrentAssignment',
	components: {
		TaskAssignmentModal,
	},
	props: {
		assignment: {
			type: Object,
			required: false,
			dafault: null,
		},
		claimId: {
			type: Number,
			required: false,
			default: null,
		},
		claimStatus: {
			type: Number,
			required: true,
		},
	},
	data () {
		return {
			users: null,
		};
	},
	computed: {
		...mapState(['currentUser']),
		isPrioritized () {
			return !!this.assignment?.isPrioritized;
		},
		assignedUser () {
			return this.assignment?.user;
		},
		userOptions () {
			const permissions = {
				assignToMe: this.$can.AssignTaskToMe,
				assignToUser: this.$can.AssignTaskToUser,
				unassign: this.$can.UnAssignTask && this.assignedUser,
			};

			return UsersService.getAssignmentUserOptions(this.users, this.currentUser, permissions);
		},
		potId () {
			return claimNumberStatusToPotTypeMap[this.claimStatus];
		},
		taskType () {
			return getTaskNameByStatus(this.claimStatus);
		},
		hasAssignmentPermission () {
			return this.$can.AssignTaskToMe || this.$can.AssignTaskToUser || this.$can.UnAssignTask;
		},
	},
	methods: {
		async onOpenModal () {
			if (!this.hasAssignmentPermission) {
				return;
			}

			if (this.users === null) {
				this.users = await UsersService.getUsers();
				this.users.sort(sortComparer('description', SortingDirection.Ascending));
			}
			this.$modal.show('user-assignment');
		},
		async onAssignUser (selectedUser) {
			await (selectedUser ? WorkflowService.assignTask : WorkflowService.unAssignTask)(this.potId, [this.claimId], selectedUser);
			this.$emit('user-assigned');
		},
	},
};
</script>
