<template>
	<div class="d-flex scrollbar flex-column">
		<div class="file-edit flex-grow-1">
			<div class="p-20">
				<div class="d-flex">
					<button
						class="btn btn-secondary mr-10 py-15 px-0 w-100"
						data-qa="documentEdit_button_claimFormCategory"
						:class="{ 'active': document.category === FileCategoryEnum.ClaimForm }"
						:disabled="disableCategory(FileCategoryEnum.ClaimForm)"
						@click="selectCategory(FileCategoryEnum.ClaimForm)"
						v-if="isClaimContext"
					>
						Claim Form
					</button>

					<button
						class="btn btn-secondary mr-10 py-15 px-0 w-100"
						data-qa="documentEdit_button_invoiceCategory"
						:class="{ 'active': document.category === FileCategoryEnum.Invoice }"
						:disabled="disableCategory(FileCategoryEnum.Invoice)"
						@click="selectCategory(FileCategoryEnum.Invoice)"
						v-if="isClaimContext"
					>
						Invoice
					</button>

					<button
						class="btn btn-secondary py-15 px-0 w-100"
						data-qa="documentEdit_button_medicalCategory"
						:class="{ 'active': document.category === FileCategoryEnum.MedicalCertificate }"
						:disabled="disableCategory(FileCategoryEnum.MedicalCertificate)"
						@click="selectCategory(FileCategoryEnum.MedicalCertificate)"
					>
						Medical History
					</button>
				</div>

				<div class="d-flex mt-10">
					<button
						class="btn btn-secondary mr-10 py-15 px-0 w-100"
						data-qa="documentEdit_button_medicalRecordSummaryCategory"
						:class="{ 'active': document.category === FileCategoryEnum.MedicalRecordSummary }"
						:disabled="disableCategory(FileCategoryEnum.MedicalRecordSummary)"
						@click="selectCategory(FileCategoryEnum.MedicalRecordSummary)"
						v-if="isPetContext && appSettings.allowMedicalRecordSummary"
					>
						Medical Record Summary
					</button>

					<button
						class="btn btn-secondary py-15 px-0 w-100"
						data-qa="documentEdit_button_inboundEmailCategory"
						:class="{ 'active': document.category === FileCategoryEnum.InboundEmail }"
						:disabled="disableCategory(FileCategoryEnum.InboundEmail)"
						@click="selectCategory(FileCategoryEnum.InboundEmail)"
						v-if="isPetContext || isClaimContext"
					>
						Inbound Email
					</button>
				</div>

				<div class="d-flex mt-10">
					<button
						class="btn btn-secondary py-15 px-0 w-100"
						data-qa="documentEdit_button_dispute"
						:class="{ 'active': document.category === FileCategoryEnum.Dispute }"
						:disabled="disableCategory(FileCategoryEnum.Dispute)"
						@click="selectCategory(FileCategoryEnum.Dispute)"
						v-if="isClaimContext"
					>
						Dispute
					</button>
				</div>

				<div class="d-flex">
					<button
						class="btn btn-secondary mr-10 py-15 px-0 w-100"
						data-qa="documentEdit_button_declarationPage"
						:class="{ 'active': document.category === FileCategoryEnum.DeclarationPage }"
						:disabled="disableCategory(FileCategoryEnum.DeclarationPage)"
						@click="selectCategory(FileCategoryEnum.DeclarationPage)"
						v-if="isPetContext"
					>
						Declaration Page
					</button>

					<button
						class="btn btn-secondary py-15 px-0 w-100"
						data-qa="documentEdit_button_proofOfSecondaryInsurance"
						:class="{ 'active': document.category === FileCategoryEnum.ProofOfSecondaryInsurance }"
						:disabled="disableCategory(FileCategoryEnum.ProofOfSecondaryInsurance)"
						@click="selectCategory(FileCategoryEnum.ProofOfSecondaryInsurance)"
						v-if="isPetContext"
					>
						Proof of Secondary Insurance
					</button>
				</div>

				<div class="d-flex mt-10">
					<button
						class="btn btn-secondary py-15 px-0 w-100"
						data-qa="documentEdit_button_otherCategory"
						:class="{ 'active': document.category === FileCategoryEnum.Other }"
						:disabled="disableCategory(FileCategoryEnum.Other)"
						@click="selectCategory(FileCategoryEnum.Other)"
					>
						Other
					</button>
				</div>
				<div class="d-flex mt-40">
					<div class="w-100">
						<div>
							<div class="d-flex align-items-center">
								<div class="bar flex-grow-1" />
								<button
									class="active registered-vet-card-icon small ml-5 mr-5 border-0"
									v-tooltip="selectedVet != null ? selectedVet.practiceName : ''"
								>
									<i class="aqv-vet aqv-icon fs-22" />
								</button>
								<div class="bar flex-grow-1" />
							</div>
							<aq-select
								class="mt-10 container-fluid"
								data-qa="documentEdit_input_vet"
								:options="vets"
								label="Search for a vet"
								option-label="practiceName"
								:async-find="updateVetSearchTerm"
								:loading="isVetLoading"
								@input="onVetSelected"
								:value="selectedVet"
								track-by="id"
								no-result-message="No Vet found"
								hide-tags
								:clear-on-select="false"
								:is-sorted="false"
							>
								<template #selectedOption="selectedScope">
									<span
										class="multiselect__single"
										v-if="selectedScope.props.values.length && !selectedScope.props.isOpen"
									>
										{{ vetSearchTerm }}
									</span>
								</template>
								<template #default="props">
									<div class="px-10">
										<div class="d-flex align-items-center">
											<div class="col">
												<div class="select-option-header text-break text-truncate">
													{{ props.option.practiceName }}
												</div>
												<div class="py-6 select-option-info text-truncate">
													<span>
														{{ toFullAddress(props.option) }}
													</span>
												</div>
											</div>
										</div>
									</div>
								</template>
								<template #after-list>
									<div
										v-if="vets.length"
										class="text-right p-10"
									>
										<span class="text-primary">
											{{ vets.length }} {{ 'match' | pluralize(vets.length ) }}
										</span>
									</div>
								</template>
							</aq-select>
						</div>
						<div class="mt-20">
							<div class="d-flex align-items-center">
								<div class="bar flex-grow-1" />
								<button
									class="active registered-vet-card-icon small ml-5 mr-5 border-0"
									v-tooltip="selectedVet != null ? selectedVet.practiceName : ''"
								>
									<i class="aqv-description aqv-icon fs-20" />
								</button>
								<div class="bar flex-grow-1" />
							</div>
							<aq-form-input
								class="mt-10"
								data-qa="documentEdit_input_documentDescription"
								label="Document Description"
								type="textarea"
								:is-valid="!$v.document.description.$error"
								v-model="document.description"
								@input="$v.document.description.$touch"
								:number-visible-lines="6"
							/>
							<aq-form-input-error
								class="mt-4"
								:error-messages="$options.errorMessages"
								:validator="$v.document"
								property="description"
							/>
						</div>
					</div>
				</div>
				<div
					class="mt-20"
					v-if="requestedFiles.length > 0 && isClaimContext"
				>
					<div class="text-subtitle fs-12 mb-10">
						Missing Information
					</div>
					<div
						class="d-flex attribute px-20 py-10 align-items-center mb-10 no-gutters"
						v-for="(requestedFile, key) of requestedFiles"
						:key="key"
					>
						<div class="col-6">
							<div
								class="text-truncate"
								v-tooltip="requestedFile.missingItemDescription"
							>
								{{ requestedFile.missingItemDescription }}
							</div>
							<div
								class="text-truncate text-subtitle fs-12"
								v-tooltip="requestedFile.contactName"
							>
								{{ requestedFile.contactName }}
							</div>
						</div>
						<div
							v-if="!brand.brandSuppressMissingInfo"
							class="col-6 d-flex justify-content-end"
						>
							<button
								class="btn btn-row"
								:class="{ 'active': isSelected(requestedFile) }"
								:disabled="isReadOnlyMode"
								@click="onSelectMissingItem(requestedFile)"
								data-qa="documentEdit_button_requestFile"
							>
								{{ isSelected(requestedFile) ? 'Selected' : 'Select' }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section-footer">
			<div class="container-fluid d-flex">
				<div>
					<button
						class="btn btn-row"
						@click="$emit('cancel')"
						data-qa="documentEdit_button_back"
					>
						Back
					</button>
				</div>
				<div class="flex-grow-1 text-right">
					<button
						class="btn btn-danger mr-10"
						@click="onDeleteDocument()"
						v-if="file.id && $can.DeleteFiles"
						:disabled="isReadOnlyMode"
						data-qa="documentEdit_button_delete"
					>
						Delete
					</button>
					<button
						class="btn btn-primary"
						@click="onFinish"
						:disabled="finishDisabled || $v.$invalid"
						data-qa="documentEdit_button_finish"
					>
						Finish
					</button>
				</div>
			</div>
		</div>
		<aq-confirmation-modal
			ref="deleteFileModal"
			name="confirm-file-delete"
			title="Confirm Delete"
			:description="deleteFileModalDescription"
			data-qa="documentEdit_modal_confirmDelete"
		/>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import { FileCategoryEnum } from '@commonServices/models/FileCategoryEnum';
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { toFullAddress } from '@commonServices/utils/converter';
import { required, maxLength } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';

const errorMessages = {
	description: {
		maxLength: ValidationMessages.maxLength200,
	},
};

export default {
	name: 'DocumentEdit',
	data () {
		return {
			FileCategoryEnum,
			missingItemIds: [],
			deleteFileModalDescription: 'Are you sure you want to delete this document?',
			finishDisabled: false,
			vets: [],
			selectedVet: null,
			isVetLoading: false,
			vetSearchTerm: undefined,
			document: {
				category: null,
				missingItemIds: [],
				description: null,
				vetId: null,
			},
			previousCategory: null,
		};
	},
	props: {
		file: {
			type: Object,
			required: true,
		},
		requestedFiles: {
			type: Array,
			required: false,
			default: () => [],
		},
		isClaimContext: {
			type: Boolean,
			required: true,
		},
		isPetContext: {
			type: Boolean,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
	},
	mounted () {
		if (this.requestedFiles.length > 0 && this.file && this.isClaimContext) {
			this.requestedFiles.forEach(file => {
				const index = file.fileIds.findIndex(id => this.file.id === id);
				if (index >= 0) this.document.missingItemIds.push(file.claimMissingItemId);
			});
		}
		if (this.file) {
			if (!this.file.category) {
				this.selectCategory(FileCategoryEnum.Other);
			} else {
				this.document.category = this.file.category;
			}
			if (this.file.vet) {
				this.vets = [...this.vets, this.file.vet];
			}
			this.document.description = this.file.description;
			this.document.vetId = this.file.vet?.id;
			this.selectedVet = this.file.vet;
			this.previousCategory = this.document.category;
		}
	},
	computed: {
		...mapState(['appSettings', 'brand']),
	},
	errorMessages,
	validations () {
		return {
			document: {
				description: {
					maxLength: maxLength(200),
				},
				category: {
					required,
				},
			},
		};
	},
	methods: {
		toFullAddress,
		selectCategory (category) {
			this.document.category = category;
		},
		async onDeleteDocument () {
			if (this.document.category === FileCategoryEnum.ClaimForm
				&& this.file.scanned) {
				const linkedData = await ClaimService.getLinkedClaimData(this.file.claimId);
				if (linkedData
				&& linkedData.linkedClaimForm
				&& linkedData.initialClaim.linkedClaimFormId
				&& linkedData.initialClaim.linkedClaimFormId === this.file.id) {
					this.deleteFileModalDescription = `By deleting the Claim Form the ${linkedData.linkedClaimForm.claimId} ${linkedData.linkedClaimForm.ailment ? linkedData.linkedClaimForm.ailment : 'no ailment'} will be un-linked. Do you wish to continue?`;
				} else {
					this.deleteFileModalDescription = 'Are you sure you want to delete this document?';
				}
			}

			this.$refs.deleteFileModal.show()
				.then(data => {
					if (data === 'yes') this.$emit('delete', this.file);
				});
		},
		onSelectMissingItem (file) {
			if (this.isSelected(file)) {
				this.document.missingItemIds = this.document.missingItemIds.filter(id => file.claimMissingItemId !== id);
			} else {
				this.document.missingItemIds.push(file.claimMissingItemId);
			}
		},
		isSelected (file) {
			return this.document.missingItemIds.some(id => file.claimMissingItemId === id);
		},
		updateVetSearchTerm (term) {
			if (term.length > 0) {
				this.vetSearchTerm = term;
				this.searchVetsAsync();
			}
		},
		searchVetsAsync: debounce(function () {
			if (this.vetSearchTerm.length >= 3) {
				this.isVetLoading = true;
				ClaimService.getVets(this.vetSearchTerm).then(res => {
					this.vets = [...res];
					this.isVetLoading = false;
				});
			}
		}, 500),
		onVetSelected (value) {
			this.selectedVet = value !== null ? value : null;
			this.document.vetId = value?.id;
			this.document.vet = this.selectedVet;
		},
		onFinish () {
			if (this.$v.$invalid) {
				return;
			}
			if (!this.document.description || !this.document.description?.trim()) {
				this.document.description = null;
			}
			this.finishDisabled = true;
			this.$emit('finish', this.document);
		},
		disableCategory (category) {
			const isNewFile = !this.file.id;
			return !isNewFile && this.isReadOnlyMode && (this.isClaimFormOrInvoice(this.previousCategory) || this.isClaimFormOrInvoice(category));
		},
		isClaimFormOrInvoice (category) {
			return category === FileCategoryEnum.ClaimForm || category === FileCategoryEnum.Invoice;
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollbar {
  overflow-y: auto;

  .file-edit {
    .active {
      background: $active-color !important;
    }
  }

  .registered-vet-card-icon {
    height: 40px;
    width: 40px;
    align-items: center;
    border-radius: 50%;

    &.active {
      background: rgba($primary, 0.2) !important;
      fill: var(--cardIconColour);
    }
  }
}

.attribute {
  font-size: 13px;
}

.bar {
  background: rgba($primary, 0.2) !important;
  height: 2px;
}

.aqv-icon {
  color: var(--cardIconSVG);
}
</style>
