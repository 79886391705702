const claimSource = Object.freeze({
	None: 0,
	Manual: 1,
	Automatic: 2,
	Portal: 3,
	Hospital: 4,
	External: 5,
	Other: 6,
	HospitalLite: 7,
	EzyVet: 8,
	Pulse: 9,
});

const claimSourceDisplayMap = Object.freeze({
	[claimSource.None]: 'None',
	[claimSource.Manual]: 'Manual',
	[claimSource.Automatic]: 'Automatic',
	[claimSource.Portal]: 'Portal',
	[claimSource.Hospital]: 'Vet Portal',
	[claimSource.External]: 'External',
	[claimSource.Other]: 'Other',
	[claimSource.HospitalLite]: 'Vet Portal Lite',
	[claimSource.EzyVet]: 'EzyVet',
	[claimSource.Pulse]: 'Pulse',
});

const claimSourceLabelMap = Object.freeze({
	[claimSource.Portal]: 'P',
	[claimSource.Hospital]: 'VP',
	[claimSource.HospitalLite]: 'VPL',
	[claimSource.EzyVet]: 'EZY',
	[claimSource.Pulse]: 'PUL',
});

const isHospitalSource = (source) => {
	return [claimSource.Hospital, claimSource.HospitalLite, claimSource.EzyVet].includes(source);
};

export {
	claimSource,
	claimSourceDisplayMap,
	claimSourceLabelMap,
	isHospitalSource,
};
