<template>
	<div class="d-flex align-items-end">
		<date-picker
			ref="datePicker"
			calendar-class="date-picker-calendar"
			input-class="date-picker-input"
			clear-button-icon="pr-6 pt-6 fas fa-times"
			:clear-button="!disabled"
			:value="value"
			:format="formatter"
			:disabled="disabled"
			:class="{ 'input-disabled' : disabled }"
			:highlighted="highlighted"
			:open-date="highlighted.dates[0]"
			typeable
			@opened="onCalendarOpen"
			@input="updateValue"
			@focusout.native="handleFocusOut"
			@changedMonth="delayedAlign"
		/>
		<div
			v-if="!disabled"
			@click="openCalendar"
			class="icon-wrapper calendar-icon"
		>
			<i
				class="far fa-calendar-alt text-primary fa-lg"
				:class="[iconClass, isValid ? 'text-primary': 'text-danger']"
			/>
		</div>
	</div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import { shortDate } from '@commonServices/utils/filters';
import { fromStringToDate, areDatesEqual } from '@commonServices/utils/dateUtils';
import { getPopupElementSize, getRelativeVerticalPosition, getVerticalPositionByPlacement } from '@commonServices/utils/domUtils';

export default {
	components: {
		DatePicker,
	},
	props: {
		value: {
			type: [String, Number, Date],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		iconClass: {
			type: String,
		},
		isValid: {
			type: Boolean,
			required: false,
			default: true,
		},
		highlighted: {
			type: Object,
			required: false,
			default: function () {
				return {
					dates: [
						new Date(),
					],
				};
			},
		},
		relativeElement: {
			type: HTMLDivElement,
			required: false,
		},
	},
	data () {
		return {
			placement: null,
		};
	},
	mounted () {
		this.$watch(() => this.$refs.datePicker.showDayView, (value) => {
			value && this.alignDatepicker(true);
		});

		this.$watch(() => this.$refs.datePicker.showMonthView, (value) => {
			value && this.alignDatepicker();
		});

		this.$watch(() => this.$refs.datePicker.showYearView, (value) => {
			value && this.alignDatepicker();
		});
	},
	methods: {
		// special case for month changing
		delayedAlign () {
			if (this.placement === 'top') {
				setTimeout(this.alignDatepicker.bind(this), 0);
			}
		},
		// calcPlacement for initial popup opening, so next views (month, year selections) should be opened in same position as initial
		alignDatepicker (calcPlacement) {
			// relative element for popup positioning
			const refEl = this.relativeElement || this.$refs.datePicker.$el.querySelector('input');
			// as all views (day, month, year) are separate divs with same classnames distinguish and select them by display style
			const popup = this.$refs.datePicker.$el.querySelector('.vdp-datepicker__calendar:not([style*="display: none;"])');
			const { height } = getPopupElementSize(popup);
			let position;
			if (calcPlacement || !this.placement) { // initial placement calculation
				position = getRelativeVerticalPosition(refEl, height);
				this.placement = position.placementY;
			} else { // placement calculatated so open popup according this placement
				position = getVerticalPositionByPlacement(refEl, height, this.placement);
			}
			popup.style.top = position.top;
		},
		onFocus (value) {
			this.$emit('focus', value);
		},
		onCalendarOpen () {
			this.onFocus(true);
		},
		openCalendar () {
			this.$refs.datePicker.showCalendar();
			this.$refs.datePicker.$el.querySelector('input').focus();
		},
		handleFocusOut () {
			const typedDate = this.parseTypedDate();
			if (typedDate) {
				this.updateValue(typedDate);
			}
			this.onFocus(false);
		},
		parseTypedDate () {
			if (this.$refs.datePicker) {
				const date = this.$refs.datePicker.$el.querySelector('input').value;
				return fromStringToDate(date);
			}
		},
		updateValue (value) {
			if (value) {
				value.setHours(0, 0, 0, 0);
			}
			if (!areDatesEqual(value, this.value)) {
				this.$emit('input', value);
			}
		},
		formatter (date) {
			return shortDate(date);
		},
	},

};
</script>

<style lang="scss" scoped>
/* Calendar Styles */
::v-deep .vdp-datepicker {
  width: 100%;
  position: initial !important;

  div:first-child {
    display: flex;
  }

  .date-picker-input {
    background: inherit;
    border: none;
    outline: none;
    flex: 1;
  }

  .vdp-datepicker__clear-button {
    position: absolute;
    right: 40px;
    top: 17px;
    margin-right: 7px;
  }

  .cell:not(.blank, .disabled).day:hover {
    border-color: $primary;
  }

  header {
    .up:not(.disabled):hover {
      background: $primary;
    }

    .next:not(.disabled):hover {
      background: $primary;
    }

    .prev:not(.disabled):hover {
      background: $primary;
    }
  }

  .prev {
    &::after {
      border-right-color: $primary;
    }

    &:hover::after {
      border-right-color: $body-color;
    }
  }

  .next {
    &::after {
      border-left-color: $primary;
    }

    &:hover::after {
      border-left-color: $body-color;
    }
  }

  .cell.highlighted {
    background: $info;
  }

  .vdp-datepicker__calendar {
    width: 100%;
    left: 0;
    border: none;
  }
}

.icon-wrapper {
  line-height: normal;

  &.calendar-icon {
    padding-bottom: 9px;
  }
}

</style>
