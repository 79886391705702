<template>
	<div class="component-wrapper">
		<div
			class="time-line-wrapper position-relative"
		>
			<div
				v-if="selectedItem"
				class="period-label text-center position-absolute"
				:style="getItemLabelPosition(selectedItem)"
			>
				<div class="font-weight-bold mb-4">
					{{ itemLabelContent }}
				</div>
				<div class="fs-12">
					{{ itemLabelDescription }}
				</div>
			</div>
			<div v-if="!periods.length">
				<aq-timeline-period
					show-start-mark
					show-end-mark
					:style="getItemStyle(fullPeriod)"
				/>
			</div>
			<div
				v-for="(item, index) of periods"
				:key="index"
			>
				<aq-timeline-period
					:selected="item.isSelected"
					:state="item.periodState"
					:show-start-mark="index === 0 || !periods[index-1].isSelected"
					:show-end-mark="index === periods.length - 1 || item.isSelected"
					:style="getItemStyle(item)"
					@select="labelType => setItemLabel(item, labelType)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { shortDate } from '@commonServices/utils/filters';
import AqTimelinePeriod from '@commonWidgets/AqTimeline/AqTimelinePeriod';

export default {
	name: 'AqTimeLine',
	components: { AqTimelinePeriod },
	props: {
		fullPeriod: {
			type: Object,
			required: true,
		},
		periods: {
			type: Array,
			required: false,
		},
	},
	data () {
		return {
			itemLabelContent: null,
			itemLabelDescription: null,
		};
	},
	computed: {
		selectedItem () {
			return this.periods.find(item => item.isSelected);
		},
	},
	watch: {
		selectedItem () {
			this.setItemLabel(this.selectedItem, 'period');
		},
	},
	methods: {
		getItemLabelPosition (item) {
			let itemLeftOffset = 0;
			const fullWidth = this.fullPeriod.endCoordinate - this.fullPeriod.startCoordinate;
			itemLeftOffset = ((item.startCoordinate - this.fullPeriod.startCoordinate) / fullWidth * 100).toFixed(4);
			return {
				left: `${itemLeftOffset}%`,
			};
		},
		getItemStyle (item) {
			let itemWidth = 0;
			let itemLeftOffset = 0;
			const fullWidth = this.fullPeriod.endCoordinate - this.fullPeriod.startCoordinate;
			itemWidth = ((item.endCoordinate - item.startCoordinate) / fullWidth * 100).toFixed(4);
			itemLeftOffset = ((item.startCoordinate - this.fullPeriod.startCoordinate) / fullWidth * 100).toFixed(4);
			return {
				width: `${itemWidth}%`,
				left: `${itemLeftOffset}%`,
				top: 0,
				height: '100%',
			};
		},
		setItemLabel (item, labelType) {
			if (!item) {
				this.itemLabelContent = null;
				this.itemLabelDescription = null;
				return;
			}

			switch (labelType) {
			case 'start': {
				this.itemLabelContent = shortDate(item.start);
				this.itemLabelDescription = item.startLabel;
				break;
			}
			case 'period': {
				this.itemLabelContent = `${shortDate(item.start)} - ${shortDate(item.end)}`;
				this.itemLabelDescription = item.periodLabel;
				break;
			}
			case 'end': {
				this.itemLabelContent = shortDate(item.end);
				this.itemLabelDescription = item.endLabel;
				break;
			}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .time-line-wrapper {
    height: 8px;

    .period-label {
      top: -78px;
      border-radius: 3px;
      color: $body-color;
      padding: 15px 20px;
    }
  }
</style>
