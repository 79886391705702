<template>
	<div>
		<div class="mx-25">
			<div class="section-content py-10">
				<div class="container-fluid">
					<aq-list-navigation-control
						:list="data"
						:current-item="currentItem"
						@current-changed="onSelect"
					>
						<template slot-scope="slotProps">
							<el-table
								class="task-table mb-10"
								:data="slotProps.list"
								empty-text="There is no data to display"
								@row-click="onSelect"
								:row-class-name="getRowClassName"
								:cell-class-name="getCellClassName"
							>
								<el-table-column
									v-if="showMissingInfoCompleted"
									width="46"
									column-key="missingInfoCompleted"
								>
									<template slot-scope="scope">
										<icon-cell
											v-if="scope.row.missingInfoCompleted"
											icon-class="aqv-missing-info-complete fs-20"
											tooltip="Missing Info Complete"
										/>
									</template>
								</el-table-column>
								<el-table-column
									label="Claim ID"
									width="80"
								>
									<template slot-scope="scope">
										<div>{{ scope.row.claimId | claimIdFormat }}</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Customer"
									width="75"
								>
									<template slot-scope="scope">
										<router-link
											:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
											class="d-inline-block"
										>
											<aq-icon-avatar
												class="small cursor-pointer"
												:value="[scope.row.customerFirstName, scope.row.customerLastName]"
											/>
										</router-link>
									</template>
								</el-table-column>
								<el-table-column
									label="Pet"
									width="40"
								>
									<template slot-scope="scope">
										<router-link
											:to="{ name: 'pet', params: { customerId: scope.row.customerId, petId: scope.row.petId } }"
											class="d-inline-block"
										>
											<aq-pet-avatar
												class="small cursor-pointer"
												:pet="{
													name: scope.row.petName,
													type: scope.row.petType,
													gender: scope.row.petGender,
													dateOfDeath: scope.row.petDateOfDeath
												}"
											/>
										</router-link>
									</template>
								</el-table-column>
								<slot name="column-six" />
								<el-table-column label="Vet Name">
									<template slot-scope="scope">
										<template v-if="scope.row.vets.length > 0">
											<truncated-text-cell :value="scope.row.vets.join(', ')" />
										</template>
										<template v-else>
											<div class="text-subtitle">
												No vets
											</div>
										</template>
									</template>
								</el-table-column>
								<el-table-column
									label="Amount"
									width="100"
								>
									<template slot-scope="scope">
										<div :class="{ 'text-danger-20': ((scope.row.claimAmount > scope.row.suggestedClaimAmount) && scope.row.suggestedClaimAmount) }">
											{{ scope.row.claimAmount | currency(scope.row.brandLocale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Settled Amount"
									width="75"
									v-if="showSettledData"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.settledAmount | currency(scope.row.brandLocale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Sug. Amount"
									width="100"
									v-if="showExtraPaymentDetails && appSettings.machineLearningEnabled"
								>
									<template slot-scope="scope">
										<div v-if="scope.row.suggestedClaimAmount">
											{{ scope.row.suggestedClaimAmount | currency(scope.row.brandLocale) }}
										</div>
										<div
											v-else
											class="text-gray"
										>
											No Amount
										</div>
									</template>
								</el-table-column>
								<el-table-column label="Ailment">
									<template slot-scope="scope">
										<truncated-text-cell :value="scope.row.ailments || 'No Ailment'" />
									</template>
								</el-table-column>
								<slot name="payees" />
								<slot name="adjuster-comment" />
								<el-table-column
									label="Days Open"
									width="50"
									v-if="showExtraPaymentDetails"
								>
									<template slot-scope="scope">
										<div>{{ scope.row.daysOpen }}</div>
									</template>
								</el-table-column>

								<slot name="column-fifteen" />

								<template v-if="showRequestedDetails">
									<el-table-column
										label="Requested By"
										width="80"
									>
										<template slot-scope="scope">
											<aq-icon-avatar
												v-if="scope.row.requestedBy"
												class="small cursor-inherit"
												:value="[scope.row.requestedBy.firstName, scope.row.requestedBy.lastName]"
											/>
										</template>
									</el-table-column>
									<el-table-column
										:label="isRequestedDate ? 'Requested' : 'Date'"
										width="100"
									>
										<template slot-scope="scope">
											<div>{{ scope.row.dateRequested | shortDate }}</div>
										</template>
									</el-table-column>
								</template>

								<el-table-column
									label="Assigned To"
									width="100"
								>
									<template slot-scope="scope">
										<div class="d-flex align-items-center">
											<aq-checkbox
												v-if="$can.AssignTaskToUser && multiClaimsAssignmentEnabled"
												class="round medium thin"
												:value="getSelectedState(scope.row.claimId)"
												@input="onSelectClaimToAssign(scope.row.claimId, $event)"
											/>
											<div
												class="cell-user-assignment"
												@click.stop="$emit('user-assign', [scope.row.claimId])"
											>
												<aq-icon-avatar
													v-if="scope.row.assignedTo"
													class="small cursor-pointer"
													:value="[scope.row.assignedTo.firstName, scope.row.assignedTo.lastName]"
												/>
												<div
													v-else
													class="text-danger-20 text-underline"
												>
													Unassigned
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<slot name="reasons" />
								<el-table-column
									label="Reassessment Date"
									width="130"
									v-if="showReassessmentDate"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.reassessmentDateTime | shortDate }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Latest"
									width="420"
									column-key="noteInteractionPopover"
								>
									<template
										slot-scope="scope"
									>
										<aq-task-details-popover
											v-if="scope.row.latestInteraction || scope.row.latestDashboardNote"
											:interaction="scope.row.latestInteraction"
											:note="scope.row.latestDashboardNote"
										/>
										<div v-else>
											-
										</div>
									</template>
								</el-table-column>
								<el-table-column width="115">
									<template slot-scope="scope">
										<aq-dropdown
											:options="getDropdownOptions(scope.row)"
											label="View"
											btn-class="small btn-row"
											@select="$emit('row-action', $event, scope.row)"
											@main-action="onMainAction(scope.row)"
											:split-toggle="true"
											:is-shortkey-context="scope.row === currentItem"
											:boundaries-selector="tableBoundariesSelector"
										/>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</aq-list-navigation-control>
				</div>
			</div>
			<div
				v-if="paging.pageCount > 1"
				class="section-footer pl-45 pr-20 d-flex justify-content-end"
			>
				<aq-page-control
					v-if="paging.rowCount"
					:total="paging.rowCount"
					:page-size="paging.pageSize"
					@page-changed="onPageChanged"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import { getDropdownOptionsForPot, dropdownActions } from '@commonServices/models/WorkQueueType';
import AqTaskDetailsPopover from '@commonView/AqTaskDetailsPopover';

export default {
	name: 'TaskTable',
	components: {
		AqTaskDetailsPopover,
	},
	mixins: [rowHighlightMixin],
	props: {
		data: {
			type: Array,
			required: true,
		},
		multiClaimsAssignmentEnabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		claimsToAssign: {
			type: Array,
			required: false,
			default: () => [],
		},
		pot: {
			type: Number,
			required: true,
		},
		showExtraPaymentDetails: {
			type: Boolean,
			required: false,
			default: false,
		},
		showRequestedDetails: {
			type: Boolean,
			required: false,
			default: true,
		},
		isRequestedDate: {
			type: Boolean,
			required: false,
			default: false,
		},
		showSettledData: {
			type: Boolean,
			required: false,
			default: false,
		},
		showMissingInfoCompleted: {
			type: Boolean,
			required: false,
			default: false,
		},
		showReassessmentDate: {
			type: Boolean,
			required: false,
			default: false,
		},
		paging: {
			type: Object,
			required: true,
		},
	},
	mounted () {
		this.currentItem = this.data[0];
		this.resetInteractionContext();
	},
	computed: {
		...mapState(['appSettings']),
	},
	methods: {
		...mapActions(['changeInteractionContext', 'resetInteractionContext']),
		getDropdownOptions (data) {
			return getDropdownOptionsForPot(this.pot, this.$can, data);
		},
		onMainAction (data) {
			this.$emit('row-action', dropdownActions.view.value, data);
		},
		onPageChanged (pageNumber) {
			this.currentItem = null;
			this.$emit('page-changed', pageNumber);
		},
		getSelectedState (id) {
			return this.claimsToAssign.includes(id);
		},
		onSelectClaimToAssign (id) {
			this.$emit('select-claim-to-assign', id);
		},
		onSelect (newCurrentItem) {
			this.currentItem = newCurrentItem;
			const customer = {
				id: this.currentItem.customerId,
				brandId: this.currentItem.brandId,
				firstName: this.currentItem.customerFirstName,
				lastName: this.currentItem.customerLastName,
			};
			const pet = {
				id: this.currentItem.petId,
				name: this.currentItem.petName,
				policies: this.currentItem.policies,
			};
			const claim = { id: this.currentItem.claimId, status: this.currentItem.claimStatus };
			this.changeInteractionContext(
				{
					claim,
					pet,
					customer,
				});
		},
		getRowClassName ({ row }) {
			if (this.currentItem) {
				return this.currentItem === row ? 'border-highlighted shadow row-selected' : 'row-unselected opacity-66';
			}
			return 'row-unselected';
		},
		getCellClassName ({ row, column }) {
			if (column.columnKey === 'noteInteractionPopover' && (row.latestInteraction || row.latestDashboardNote)) {
				return 'table__popover-cell';
			}
			if (column.columnKey === 'missingInfoCompleted' && row.missingInfoCompleted) {
				return 'table__icon-cell';
			}
		},
	},
	data () {
		return {
			tableBoundariesSelector: '.task-table',
		};
	},
	watch: {
		data: {
			deep: true,
			handler () {
				this.currentItem = null;
				this.resetInteractionContext();
			},
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .cell-user-assignment {
  cursor: pointer;
  margin-left: 10px;
}

::v-deep .row-selected {
  cursor: default;
}

::v-deep .row-unselected {
  cursor: pointer;
}
</style>
