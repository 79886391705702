export default class FilePreview {
	constructor (fileName, fileSize, scanned, fileId, category, missingItemId) {
		this.fileName = fileName;
		this.fileSize = fileSize;
		this.scanned = scanned;
		this.id = fileId;
		this.category = category;
		this.missingItemId = missingItemId;
	}
};
