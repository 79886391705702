import httpClient from '@commonServices/httpClient';

export default {
	getTeams () {
		return httpClient('/api/claims/teams', { omitSpinner: true })
			.get();
	},
	getUserTeams (userId) {
		return httpClient(`/api/claims/teams/user/${userId}/associated-teams`, { omitSpinner: true })
			.get();
	},
	getQueueTeams (queueId) {
		return httpClient(`/api/claims/teams/queues/${queueId}/associated-teams`)
			.get();
	},
	createTeam (team, handleErrorCodes = []) {
		return httpClient('/api/claims/teams', { handleErrorCodes })
			.post(team);
	},
	deleteTeam (id) {
		return httpClient(`/api/claims/teams/${id}`)
			.delete();
	},
	getTeam (id) {
		return httpClient(`/api/claims/teams/${id}`)
			.get()
			.then(toTeamModel);
	},
	updateTeam (team, handleErrorCodes = []) {
		return httpClient(`/api/claims/teams/${team.id}`, { handleErrorCodes })
			.put(team);
	},
};

function toTeamModel ({ id, name, description, queues, users }) {
	return {
		id,
		name,
		description,
		selectedQueues: queues,
		selectedBuckets: [],
		selectedMembers: users.map((user) => ({
			id: user.id,
			description: `${user.firstName} ${user.lastName}`,
			associatedTeams: user.associatedTeams,
		}))
			.sort((a, b) => a.description < b.description ? 1 : -1),
	};
}

export const TeamsActions = {
	view: { name: 'View', value: 'view', iconClass: 'fa-eye text-primary' },
	delete: { name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' },
};
