<template>
	<div>
		<div>
			<p class="text-primary fs-12">
				Address
			</p>
			<p class="fs-13">
				{{ [vetInfo.address1, vetInfo.address2, vetInfo.address3].filter(Boolean).join(', ') }}
			</p>
			<p class="fs-13">
				{{ [vetInfo.townCity, vetInfo.regionCounty, vetInfo.postalCode].filter(Boolean).join(', ') }}
			</p>
		</div>
		<div class="mt-10">
			<p class="text-primary fs-12">
				Contact
			</p>
			<p class="fs-13">
				tel: {{ vetInfo.phoneNumber || '-' }}
			</p>
			<p class="fs-13">
				email: {{ vetInfo.email || '-' }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VetDetailsInfo',
	props: {
		vetInfo: {
			type: Object,
			required: true,
		},
	},
};
</script>
