export default class Note {
	constructor (
		id,
		title,
		details,
		type,
		customerId,
		petId,
		claimId,
		createdBy,
		createdDate,
		updatedBy,
		updatedDate,
		subtype,
	) {
		this.id = id;
		this.title = title;
		this.details = details;
		this.type = type;
		this.customerId = customerId;
		this.petId = petId;
		this.claimId = claimId;
		this.createdBy = createdBy;
		this.createdDate = createdDate;
		this.updatedBy = updatedBy;
		this.updatedDate = updatedDate;
		this.subtype = subtype;
	}
}
