<template>
	<div />
</template>

<script>
import resolveService from '@commonServices/resolveService';

const NavigateSubject = {
	Customer: 'customer',
	Pet: 'pet',
	Policy: 'policy',
	Claim: 'claim',
};

export default {
	name: 'AqNavigate',
	mounted () {
		const subject = this.$route.params.subject;
		const identifier = this.$route.params.identifier;
		let navigatePromise = null;
		if (subject === NavigateSubject.Customer) {
			navigatePromise = this.navigateToCustomer(identifier);
		} else if (subject === NavigateSubject.Pet) {
			navigatePromise = this.navigateToPet(identifier);
		} else if (subject === NavigateSubject.Policy) {
			navigatePromise = this.navigateToPolicy(identifier);
		} else if (subject === NavigateSubject.Claim) {
			navigatePromise = this.navigateToClaim(identifier);
		} else {
			navigatePromise = Promise.reject(new Error('invalid params'));
		}
		navigatePromise.catch(() => this.fallbackNavigate(subject, identifier));
	},
	methods: {
		async navigateToCustomer (identifier) {
			const context = await resolveService.toCustomerContext(identifier);
			return this.$router.replace({ name: 'customer', params: { customerId: context.customerId } });
		},
		async navigateToPet (identifier) {
			const context = await resolveService.toPetContext(identifier);
			return this.$router.replace({ name: 'pet', params: { customerId: context.customerId, petId: context.petId } });
		},
		async navigateToPolicy (identifier) {
			const context = await resolveService.toPolicyContext(identifier);
			return this.$router.replace({ name: 'pet', params: { customerId: context.customerId, petId: context.petId } });
		},
		async navigateToClaim (identifier) {
			const context = await resolveService.toClaimContext(identifier);
			return this.$router.replace({ name: 'claim-page', params: { customerId: context.customerId, claimId: context.claimId } });
		},
		fallbackNavigate (subject, identifier) {
			this.$toasted.global.common_error({ message: `Can't navigate to the ${subject} with ${identifier} identifier` });
			this.$router.replace({ name: 'dashboard' });
		},
	},

};

</script>
