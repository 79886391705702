<template>
	<div
		class="d-flex tab-header"
		:class="{ 'selected-inactive': isPolicyTermInactive(currentPolicyTerm)}"
		data-qa="productTabs_card_products min-width-0"
	>
		<div
			v-for="(group, index) in policyTermGroups"
			:key="index"
			@click="$emit('select', group)"
			class="d-flex position-relative min-width-0 product-tab"
			:class="[isActiveGroup(group) ? 'flex-1 focused' : 'unfocused', isPolicyTermInactive(getPolicyTermForGroup(group)) ? 'inactive' : '', isExpandCardMode ? 'expanded': '']"
			:data-qa="`productTabs_card_${group.group}`"
		>
			<div class="d-flex align-items-center p-8 min-width-0">
				<div class="d-flex position-relative cursor-pointer">
					<div
						data-qa="productTabs_label_count"
						class="counter text-white"
						v-if="getAddonsCount(group) > 0"
					>
						{{ getAddonsCount(group) }}
					</div>
					<component
						:is="getIcon(group)"
						@click="isActiveGroup(group) ? $emit('show-product-details') : () => {}"
						:data-qa="`productTabs_icon_${group.group}`"
						class="product-tab-icon"
						v-tooltip="getPolicyTermProductName(group)"
					/>
				</div>
				<div
					class="ml-10 min-width-0"
					v-if="isActiveGroup(group)"
				>
					<div v-if="isExpandCardMode || !allowCollapse">
						<aq-tooltip-on-truncate
							:tooltip-text="getPolicyTermProductName(group)"
							class="product-title text-truncate"
							data-qa="productTabs_label_name"
						>
							<div
								class="product-title text-truncate"
								data-qa="productTabs_label_name"
							>
								{{ getPolicyTermProductName(group) }}
							</div>
						</aq-tooltip-on-truncate>
						<div
							class="fs-13"
							data-qa="productTabs_label_inceptionDate"
						>
							Inception Date: {{ getInceptionDateText(group) }}
						</div>
					</div>
					<div v-else>
						<div
							class="product-title text-truncate pr-20"
							data-qa="productTabs_label_name"
						>
							{{ getPolicyTermProductName(group) }} - {{ currentPolicyTerm.policyReference }}
							<span
								class="fs-13 font-weight-normal ml-5"
								data-qa="productTabs_label_inceptionDate"
							>
								(<b>Inception Date: </b> {{ getInceptionDateText(group) }})
							</span>
						</div>
						<div class="d-flex mt-5 align-items-center">
							<div
								class="pr-10 d-flex"
								v-if="currentPolicyTerm.product.fixedExcess"
							>
								<span class="text-bold pr-5">DD:</span> {{ currentPolicyTerm.product.fixedExcess.limit | currency(locale) }}
							</div>
							<div
								class="pr-10 d-flex"
								v-if="currentPolicyTerm.product.coInsurance"
							>
								<span class="text-bold pr-5">Copay:</span> {{ currentPolicyTerm.product.coInsurance.limit }}%
							</div>
							<div
								class="text-truncate"
								v-if="waitingPeriods.length"
							>
								<span class="text-bold">Wait: </span>
								<span
									class="pr-5 align-items-center"
									v-for="waitPeriod of waitingPeriods"
									:key="waitPeriod.id"
								>
									{{ waitPeriod.conditionType.charAt(0) }} -
									(<span v-if="waitPeriod.endDateOverride">
										{{ waitPeriod.endDateOverride | shortDateNoCentury }}
									</span>
									<span v-else>{{ waitPeriod.endDate | shortDateNoCentury }}</span>)
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="currentPolicyTerm.policyOffer && !isExpandCardMode && allowCollapse"
				class="position-absolute coupon"
			>
				<CouponIcon v-tooltip="currentPolicyTerm.policyOffer" />
			</div>
		</div>
	</div>
</template>

<script>

import { longDate } from '@commonServices/utils/filters';
import { PolicyStatus } from '@commonServices/models/PolicyStatus';
import { ProductCardInsurance, ProductCardWellness, ProductCardWellnessCancelled, ProductCardInsuranceCancelled, CouponIcon } from '@assets/icons';
import ProductType from '@clientCommon/services/models/ProductType';
import { mapGetters } from 'vuex';

export default {
	name: 'AqProductTabs',
	props: {
		policyTermGroups: {
			type: Array,
			required: true,
		},
		currentPolicyTerm: {
			type: Object,
			required: true,
		},
		currentPolicyTermGroup: {
			type: Object,
			required: true,
		},
		waitingPeriods: {
			type: Array,
			required: false,
		},
		allowCollapse: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	components: {
		CouponIcon,
	},
	computed: {
		...mapGetters(['isExpandCardMode']),
		locale () {
			return this.$store.state.brand.brandLocale;
		},
	},
	methods: {
		getPolicyTermForGroup (group) {
			return this.isActiveGroup(group) ? this.currentPolicyTerm : group.policyTerms[group.policyTerms.length - 1];
		},
		getPolicyTermProductName (group) {
			const policyTerm = this.getPolicyTermForGroup(group);
			return policyTerm.product.name;
		},
		getInceptionDateText (group) {
			const policyTerm = this.getPolicyTermForGroup(group);
			return longDate(policyTerm.policyInceptionDate);
		},
		isPolicyTermInactive (policyTerm) {
			return [PolicyStatus.CancellationPending, PolicyStatus.Canceled].includes(policyTerm.policyStatus);
		},
		isActiveGroup (group) {
			return group === this.currentPolicyTermGroup;
		},
		getIcon (group) {
			const policyTerm = this.getPolicyTermForGroup(group);
			if (policyTerm.product.type === ProductType.Insurance) {
				return policyTerm.policyStatus === PolicyStatus.Canceled ? ProductCardInsuranceCancelled : ProductCardInsurance;
			}

			return policyTerm.policyStatus === PolicyStatus.Canceled ? ProductCardWellnessCancelled : ProductCardWellness;
		},
		getAddonsCount (group) {
			return this.getPolicyTermForGroup(group).addons.length;
		},
	},
};
</script>

<style lang="scss" scoped>
// these styles override colors in svg icons
.background {
  fill: var(--bodyBg);
}

.b,
.d,
.e {
  fill: var(--cardIconColour);
}

.c,
.f {
  fill: var(--sectionTextColour);
}

.product-tab {
  &.focused {
    &.inactive {
      .background {
        fill: var(--policyCancelledItemBg);
      }

      .b {
        fill: var(--policyCancelledCardButton);
      }
    }
  }

  &.unfocused {
    background: var(--tabIconBg);
    opacity: 0.65;

    .background {
      fill: var(--tabIcon);
    }

    &.inactive {
      background-color: var(--policyCancelledCard);

      .b {
        fill: var(--policyCancelledCardButton);
      }

      .background {
        fill: var(--contentBg);
      }
    }

    &:hover {
      background: var(--tabIconActive);
      opacity: 1;
    }
  }
}

// Different behavior when on customer page - pet card;
.pet-header-card {
  flex-basis: auto;

  .tab-header {
    .focused {
      &:first-child {
        border-radius: 3px 0 0 3px;

        &.expanded {
          border-radius: 3px 3px 0 0;
        }
      }

      &:last-child {
        border-radius: 0 3px 3px 0;

        &.expanded {
          border-radius: 3px 3px 0 0;
        }
      }

      border: 2px solid $primary;

      &.expanded {
        border-bottom: 0;
      }
    }

    .unfocused {
      &:first-child {
        border-left: 2px solid #0184ce;
        border-top: 2px solid #0184ce;
        border-radius: 3px 0 0 3px;

        &.expanded {
          border-left: 2px solid var(--tabIconActive);
          border-top: 2px solid var(--tabIconActive);
        }
      }

      &:last-child {
        border-right: 2px solid #0184ce;
        border-top: 2px solid #0184ce;
        border-radius: 0 3px 3px 0;

        &.expanded {
          border-right: 2px solid var(--tabIconActive);
          border-top: 2px solid var(--tabIconActive);
        }
      }

      border-bottom: 2px solid $primary;
    }

    &.selected-inactive {
      .focused {
        border-radius: 3px 3px 0 0;
        border: 2px solid var(--policyCancelledCardButton);

        &.expanded {
          border-bottom: 0;
        }
      }

      .unfocused {
        &.expanded {
          border-bottom: 2px solid var(--policyCancelledCardButton);
        }
      }
    }
  }
}

.counter {
  background: $active-color;
  position: absolute;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  top: 10px;
  right: 10px;
  transition: 0.2s ease;
  box-shadow: 0 0 2px 0 $secondary;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
}

.coupon {
  right: -10px;
  top: -10px;
}
</style>
