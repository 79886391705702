import { shortDate, shortDateTime } from '@commonServices/utils/filters';
import { ParseDateFormats } from '@commonServices/settings/localeSettings';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import diffInWeeks from 'date-fns/differenceInWeeks';
import diffInMonths from 'date-fns/differenceInMonths';
import diffInYears from 'date-fns/differenceInYears';
import diffInDays from 'date-fns/differenceInDays';
import isBothSameDay from 'date-fns/isSameDay';
import isDateAfter from 'date-fns/isAfter';
import add from 'date-fns/add';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import formatDate from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isDate from 'date-fns/isDate';
import isEqual from 'date-fns/isEqual';

function getDayStart (date = new Date()) {
	return startOfDay(date);
}

function getDayEnd (date = new Date()) {
	return endOfDay(date);
}

function addMonths (date, months) {
	return add(date, { months });
}

function addDays (date, days) {
	return add(date, { days });
}

const DateTypes = Object.freeze({
	BetweenDates: 'Between Dates',
	SelectDate: 'Select Date',
	AfterDate: 'After Date',
	BeforeDate: 'Before Date',
	PastDay: 'Past Day',
	PastWeek: 'Past Week',
	PastMonth: 'Past Month',
	PastYear: 'Past Year',
});

const AllDateTypes = Object.freeze([DateTypes.PastDay, DateTypes.PastWeek, DateTypes.PastMonth, DateTypes.PastYear, DateTypes.SelectDate, DateTypes.AfterDate, DateTypes.BeforeDate, DateTypes.BetweenDates]);

function toApiDateFilter (dateType, dateFrom, dateTo) {
	const filter = {};

	const now = new Date();
	switch (dateType) {
	case DateTypes.BetweenDates:
		filter.dateFrom = getDayStart(dateFrom);
		filter.dateTo = getDayEnd(dateTo);
		break;
	case DateTypes.SelectDate:
		filter.dateFrom = getDayStart(dateFrom);
		filter.dateTo = getDayEnd(dateFrom);
		break;
	case DateTypes.AfterDate:
		filter.dateFrom = getDayStart(add(dateFrom, { days: 1 }));
		filter.dateTo = null;
		break;
	case DateTypes.BeforeDate:
		filter.dateFrom = null;
		filter.dateTo = getDayEnd(add(dateFrom, { days: -1 }));
		break;
	case DateTypes.PastDay: {
		filter.dateFrom = getDayStart(add(now, { days: -1 }));
		filter.dateTo = getDayEnd();
		break;
	}
	case DateTypes.PastWeek: {
		filter.dateFrom = getDayStart(add(now, { days: -7 }));
		filter.dateTo = getDayEnd();
		break;
	}
	case DateTypes.PastMonth: {
		filter.dateFrom = getDayStart(add(now, { months: -1 }));
		filter.dateTo = getDayEnd();
		break;
	}
	case DateTypes.PastYear: {
		filter.dateFrom = getDayStart(add(now, { years: -1 }));
		filter.dateTo = getDayEnd();
		break;
	}
	}

	return filter;
}

function toDateSearchTerm (dateFrom, dateTo) {
	return [dateFrom, dateTo]
		.filter(Boolean)
		.map(shortDate)
		.join(' - ');
}
function fromStringToDate (stringDate) {
	if (!stringDate) return null;
	if (isDate(stringDate)) return stringDate;
	const parsed = parseISO(stringDate);
	if (isValid(parsed)) return parsed;
	for (let index = 0; index < ParseDateFormats.length; index++) {
		const parsed = parse(stringDate, ParseDateFormats[index], new Date());
		if (isValid(parsed)) return parsed;
	}
	return null;
}

function areDatesEqual (date1, date2) {
	return isEqual(date1, date2);
}

function lifetimeInfo ({ createdDateTime, updatedBy, updatedDateTime }) {
	return updatedBy ? `Last Updated ${shortDateTime(updatedDateTime)}` : `Created ${shortDateTime(createdDateTime)}`;
}

function lifetimeInfoToolTip ({ createdBy, createdDateTime, updatedBy }) {
	return updatedBy ? `Created ${shortDateTime(createdDateTime)} by ${createdBy.firstName} ${createdBy.lastName}` : '';
}

function isFutureDate (dateToCheck) {
	const now = new Date();
	const dateToCheckParsed = fromStringToDate(dateToCheck);
	return dateToCheckParsed > now;
}
function secondsToHHmm (seconds) {
	return new Date(seconds * 1000).toISOString().substring(11, 16);
}
function timeBetweenFormatted (date1, date2) {
	const diffInSeconds = Math.floor(Math.abs((date1 - date2)) / 1000);
	const days = Math.floor(diffInSeconds / 86400);
	const hours = Math.floor((diffInSeconds % 86400) / 3600);
	const minutes = Math.floor((diffInSeconds % 3600) / 60);
	const remainderSeconds = diffInSeconds % 60;

	let displayDays = '';
	if (days !== 0) {
		displayDays = `${days}d `;
	}

	const displayHours = hours.toString().padStart(2, '0');
	const displayMinutes = minutes.toString().padStart(2, '0');
	const displaySeconds = remainderSeconds.toString().padStart(2, '0');

	return `${displayDays}${displayHours}:${displayMinutes}:${displaySeconds}`;
}
const minDate = new Date(1900, 0, 1);

export {
	DateTypes,
	AllDateTypes,
	lifetimeInfo,
	lifetimeInfoToolTip,
	toApiDateFilter,
	toDateSearchTerm,
	fromStringToDate,
	areDatesEqual,
	getDayStart,
	getDayEnd,
	diffInWeeks,
	diffInMonths,
	differenceInCalendarMonths,
	diffInYears,
	diffInDays,
	isBothSameDay,
	isDateAfter,
	addDays,
	addMonths,
	formatDate,
	isDate,
	isFutureDate,
	minDate,
	secondsToHHmm,
	timeBetweenFormatted,
};
