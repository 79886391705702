<template>
	<div class="d-flex">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AqWizard',
	props: {
		active: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	data () {
		return {
			steps: [],
		};
	},
	methods: {
	},
};
</script>
