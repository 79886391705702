<template>
	<div>
		<div
			class="rounded-2"
			:class="bgLight ? 'bg-progress-light' : 'bg-progress'"
		>
			<div
				class="rounded-2 animate"
				:class="colorClass"
				:style="barStyle"
				data-qa="aqProgress_block_bar"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {};
	},

	props: {
		percentage: {
			type: Number,
			default: 0,
			required: true,
			validator: val => val >= 0 && val <= 100,
		},
		colorClass: {
			type: String,
			required: true,
		},
		height: {
			type: Number,
			default: 11,
		},
		bgLight: {
			type: Boolean,
			require: false,
		},
	},
	computed: {
		barStyle () {
			const style = {};
			style.maxWidth = `${this.percentage}%`;
			style.height = `${this.height}px`;
			return style;
		},
	},
};
</script>

<style lang="scss" scoped>
  .animate {
    transition: width 0.2s;
    animation: progress 2s 1;
  }
</style>
