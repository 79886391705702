import httpClient from '@commonServices/httpClient';

const BASE_PATH = '/api/claims/resolve';

export default {
	toCustomerContext (customerIdentifier) {
		return httpClient(`${BASE_PATH}/customer/${customerIdentifier}`)
			.get();
	},
	toPetContext (petIdentifier) {
		return httpClient(`${BASE_PATH}/pet/${petIdentifier}`)
			.get();
	},
	toPolicyContext (policyIdentifier) {
		return httpClient(`${BASE_PATH}/policy/${policyIdentifier}`)
			.get();
	},
	toClaimContext (claimId) {
		return httpClient(`${BASE_PATH}/claim/${claimId}`)
			.get();
	},
};
