<template>
	<div>
		<bar-chart
			style="height: 70vh;"
			v-if="dataCollection"
			:chart-data="dataCollection"
			:options="chartOptions"
		/>
	</div>
</template>

<script>

export default {
	components: {
		BarChart: () => import('@commonView/ReportsPage/Charts/BarChart'),
	},
	props: {
		chartData: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			dataCollection: null,
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				pan: {
					enabled: false,
					mode: 'x',
				},
				zoom: {
					enabled: true,
					mode: 'x',
				},
			},
		};
	},
	mounted () {
		this.renderChart();
	},
	watch: {
		chartData () {
			this.renderChart();
		},
	},
	methods: {
		renderChart () {
			this.dataCollection = {
				labels: this.chartData.labels,
				datasets: [
					{
						label: this.chartData.title,
						backgroundColor: 'rgba(1, 132, 206, 1)',
						borderColor: 'rgb(5,54,141)',
						borderWidth: 1,
						data: this.chartData.data,
						maxBarThickness: 40,
					},
				],
			};
			this.chartOptions.scales = this.chartData.scaleOptions;
		},
	},
};
</script>
