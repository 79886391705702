const HistoryPanelEnum = Object.freeze({
	Notes: 0,
	Interactions: 1,
});
const HistoryPanelEnumMap = Object.freeze({
	[HistoryPanelEnum.Notes]: 'Notes',
	[HistoryPanelEnum.Interactions]: 'Interactions',
});

export { HistoryPanelEnum, HistoryPanelEnumMap };
