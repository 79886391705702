<template>
	<aq-modal
		:name="name"
		:click-to-close="false"
	>
		<aq-search-term-container
			class="search-term-container px-25 pt-5 pb-10"
			v-if="searchTerms.length"
			:search-terms="searchTerms"
			readonly
		/>
		<div
			v-if="searchTerms.length"
			class="position-relative"
		>
			<UpArrow class="up-arrow" />
		</div>
		<div class="modal-wrapper">
			<div>
				<div class="p-15 d-flex flex-column align-items-center header-title">
					<div>
						<i class="warning-icon fas fa-exclamation-triangle" />
					</div>
					<div class="fs-18 my-3">
						Warning - Changes to filters/Queues
					</div>
					<div class="fs-14 mt-2">
						Please review your filter/queue changes above and other changes below
					</div>
				</div>
				<div class="p-15">
					<template v-if="associatedTeams.length">
						<div class="row no-gutters">
							<div class="col-12 card-grid-header">
								Associated Teams
							</div>
						</div>
						<div class="mb-10 teams-container">
							<div class="row no-gutters">
								<div
									class="col-6 card-grid-cell"
									v-for="team in associatedTeams"
									:key="team.id"
								>
									<div class="card-grid-item">
										<div class="text-truncate">
											{{ team.name }}
										</div>
										<div class="text-primary">
											{{ team.description }}
										</div>
									</div>
								</div>
								<div
									v-if="associatedTeams.length % 2"
									class="col-6 card-grid-cell"
								>
									<div class="card-grid-item h-100" />
								</div>
							</div>
						</div>
					</template>
					<div class="row no-gutters">
						<div class="col-6 card-grid-cell">
							<div class="card-grid-header">
								Auto-assigned or not
							</div>
						</div>
						<div class="col-6 card-grid-cell">
							<div class="card-grid-header">
								Volume of claims in queue
							</div>
						</div>
						<div class="col-6 card-grid-cell">
							<div class="card-grid-item">
								Auto-assigned:
								<span class="font-weight-bold">
									{{ prioritizedQueue ? 'Yes' : 'No' }}
								</span>
							</div>
						</div>
						<div class="col-6 card-grid-cell">
							<div class="card-grid-item">
								Claims in Queue:
								<span class="font-weight-bold">
									{{ totalTasksCount }}
								</span>
							</div>
						</div>
					</div>
					<div class="d-flex flex-column align-items-center mt-15">
						<button
							class="btn btn-primary"
							@click="close('yes')"
						>
							Yes I'm happy with the above changes
						</button>
					</div>
				</div>
			</div>
			<div class="px-20 py-10 modal-bottom">
				<button
					class="btn btn-secondary"
					@click="close"
				>
					Cancel
				</button>
			</div>
		</div>
	</aq-modal>
</template>

<script>
import teamsService from '@commonServices/teamsService';
import AqSearchTermContainer from '@commonView/Shared/AqSearchTermContainer';
import { UpArrow } from '@assets/icons';

export default {
	components: {
		AqSearchTermContainer,
		UpArrow,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		queueId: {
			type: Number,
			required: true,
		},
		totalTasksCount: {
			type: Number,
			required: true,
		},
		searchTerms: {
			type: Array,
			required: false,
			default: () => [],
		},
		prioritizedQueue: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			promiseResolver: null,
			associatedTeams: [],
		};
	},
	methods: {
		async show () {
			if (this.queueId) {
				this.associatedTeams = await teamsService.getQueueTeams(this.queueId);
			}
			this.$modal.show(this.name);
			return new Promise((resolve) => {
				this.promiseResolver = resolve;
			});
		},
		close (result) {
			this.$modal.hide(this.name);
			this.promiseResolver(result);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  background: var(--contentBg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  width: 650px;
  margin: 0 auto;
  margin-top: 25px;
}

.header-title {
  border: var(--backgroundWarning) solid 2px;
  background: var(--tabIcon);
  border-radius: 0 0 2px 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.warning-icon {
  color: var(--backgroundWarning);
  font-size: 35px;
}

.search-term-container {
  width: 85%;
  margin: 0 auto;
  border: 2px solid var(--backgroundWarning);
}

.up-arrow {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -8px;
}

.teams-container {
  max-height: 180px;
  overflow-y: auto;
}

.card-grid-header {
  background: var(--gridHeaderBg);
  color: #fff;
}

.card-grid-cell {
  &:nth-child(odd)  {
    padding-right: 5px;
    padding-bottom: 5px;
  }

  &:last-child {
    padding-bottom: 5px;
  }
}

.card-grid-item,
.card-grid-item-empty {
  background: var(--gridItemBg);
}

.card-grid-item,
.card-grid-header {
  padding: 10px;
}

</style>
