export class ClaimFormCondition {
	constructor (description, dateOfLoss, dateOfDeath, treatmentStart, treatmentEnd, ailment, claimAmount, claimId, claimFormType) {
		this.description = description;
		this.dateOfLoss = dateOfLoss;
		this.dateOfDeath = dateOfDeath;
		this.treatmentStart = treatmentStart;
		this.treatmentEnd = treatmentEnd;
		this.ailment = ailment;
		this.claimAmount = claimAmount;
		this.claimId = claimId;
		this.claimFormType = claimFormType;
	}
}
