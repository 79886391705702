<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="row no-gutters">
						<div class="col-12 pb-12">
							Please select a reason to initiate a fraud check against this claim
						</div>
						<div class="col-12 pb-12">
							<aq-select
								:options="filteredReasons"
								label="Select Reasons"
								option-label="description"
								@input="onReasonSelected"
								:value="selectedReasons"
								:async-find="onSearch"
								track-by="id"
								no-result-message="No Reason found"
							>
								<template #default="props">
									<div class="px-10">
										<div class="select-option-header text-truncate">
											{{ props.option.description }}
										</div>
									</div>
								</template>
								<template slot="after-list">
									<div
										v-if="fraudReasons.length"
										class="text-right p-10"
									>
										<span class="text-primary">
											{{ filteredReasons.length }} {{ 'match' | pluralize(filteredReasons.length) }}
										</span>
									</div>
								</template>
								<template slot="singleLabel">
									<div />
								</template>
							</aq-select>
							<div class="items-list scrollbar">
								<div
									class="list-item--bg mt-10 d-flex py-15 px-20 align-items-center"
									v-for="item of selectedReasons"
									:key="item.id"
								>
									<div class="flex-grow-1 pt-10">
										{{ item.description }}
										<aq-claims-list
											v-if="isDuplicateInvoiceReason(item.type) && !!claimIds.length"
											:claim-ids="claimIds"
										/>
									</div>
									<div class="pl-20">
										<button
											type="button"
											class="btn small btn-secondary"
											@click="onDeleteReason(item.id)"
										>
											<i class="fas fa-times" />
										</button>
									</div>
								</div>
							</div>
							<aq-form-input-error
								class="error mt-8"
								:error-messages="$options.errorMessages"
								:validator="$v"
								property="selectedReasons"
							/>
						</div>
						<div
							v-if="requireComment"
							class="col-12 pb-12"
						>
							<aq-form-input
								label="Comment"
								type="textarea"
								v-model="comment"
								@input="$v.comment.$touch"
								:is-valid="!$v.comment.$error"
								data-qa="fraudCheckModal_input_comment"
							/>
							<aq-form-input-error
								class="error mt-8"
								:error-messages="$options.errorMessages"
								:validator="$v"
								property="comment"
							/>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onContinue"
						:disabled="$v.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Continue
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import ExtraService from '@commonServices/extraService';
import { notEmpty } from '@commonServices/utils/validators';
import { maxLength, required } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import FraudCheckReason from '@commonServices/models/FraudCheckReason';
import AqClaimsList from '@commonWidgets/AqClaimsList';

export default {
	components: {
		AqClaimsList,
	},
	data () {
		return {
			title: 'Fraud Check',
			fraudReasons: [],
			selectedReasons: [],
			filteredReasons: [],
			comment: null,
			duplicationErrorMessage: 'Current claim already contains selected rejection reason',
		};
	},
	errorMessages: {
		selectedReasons: {
			notEmpty: 'The reason should be selected',
		},
		comment: {
			maxLength: ValidationMessages.maxLength2000,
			required: ValidationMessages.required,
		},
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
	},
	async mounted () {
		this.fraudReasons = (await ExtraService.getFraudCheckReasons())
			.filter(p => !p.isSystemOnly);
		this.filteredReasons = this.fraudReasons;
		this.selectedReasons = await ExtraService.getClaimFraudCheckReasons(this.claimId);
		this.comment = this.selectedReasons.find(r => r.type === FraudCheckReason.Other)?.comment;
	},
	methods: {
		onContinue () {
			const reasonIds = this.selectedReasons.map(({ id }) => id);
			const fraudComment = this.requireComment ? this.comment : null;
			this.$emit('fraud-check', reasonIds, fraudComment);
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onReasonSelected (value) {
			if (!value) {
				return;
			} else {
				const index = this.selectedReasons.findIndex(reason => reason.id === value.id);
				if (index >= 0) return;
			}
			this.$v.selectedReasons.$touch();
			this.selectedReasons.push(value);
		},
		onDeleteReason (id) {
			this.selectedReasons = this.selectedReasons.filter(reason => reason.id !== id);
			this.$v.selectedReasons.$touch();
		},
		isDuplicateInvoiceReason (reason) {
			return reason === FraudCheckReason.DuplicateInvoices;
		},
		onSearch (searchQuery) {
			const normalizedQuery = searchQuery.toLowerCase().trim();
			this.filteredReasons = this.fraudReasons
				.filter(reason => reason.description.toLowerCase().includes(normalizedQuery));
		},
	},
	validations () {
		return {
			selectedReasons: {
				notEmpty: notEmpty(this.selectedReasons),
			},
			comment: {
				maxLength: maxLength(2000),
				...this.requireComment && { required },
			},
		};
	},
	computed: {
		requireComment () {
			return this.selectedReasons.findIndex(reason => reason.type === FraudCheckReason.Other) >= 0;
		},
		claimIds () {
			return this.selectedReasons.find(r => r.type === FraudCheckReason.DuplicateInvoices)?.comment?.split(',') ?? [];
		},
	},
};
</script>

<style lang="scss" scoped>
.items-list.scrollbar {
  max-height: 400px;
  overflow-y: auto;
}
</style>
