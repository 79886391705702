<template>
	<div
		class="edit-pet"
	>
		<aq-confirmation-modal
			:ref="dateOfDeathDialogReference"
			:name="dateOfDeathDialogReference"
			title="Date of Death"
			description="do not show"
			yes-label="Proceed"
			no-label="Revert"
			v-if="pet.dateOfDeath"
		>
			<template>
				<div class="p-22 text-center section-content">
					<div class="pet-alert-logo">
						<aq-pet-avatar
							class="large mx-auto"
							:pet="pet"
							:show-alert="true"
						/>
					</div>
					<div>
						The date of death previously recorded is:
					</div>
					<strong
						class="text-highlight"
						data-qa="enterBasic_dod_modal_prev_date"
					>{{ shortDateOrNoDate(previousDateOfDeath) }}</strong>
					<div>
						Do you wish to proceed updating the current Date of Death to:
					</div>
					<strong
						class="text-highlight"
						data-qa="enterBasic_dod_modal_to_date"
					>{{ shortDateOrNoDate(selectedDateOfDeath) }}</strong> or revert back to <strong
						class="text-highlight"
						data-qa="enterBasic_dod_modal_revert_date"
					>{{ shortDateOrNoDate(previousDateOfDeath) }}</strong>
				</div>
			</template>
		</aq-confirmation-modal>
		<v-popover
			ref="calendarPopover"
			style="position: absolute; left: -45px; top: 45px;"
			v-if="!!pet.dateOfDeath && $can.UpdatePetDateOfDeath"
			:auto-hide="true"
			:handle-resize="false"
			popover-class="z-index-2"
		>
			<i class="fas fa-pencil-alt cursor-pointer" />
			<template slot="popover">
				<div
					class="flex-grow-1"
					style="width: 175px;"
				>
					<aq-form-input
						class="aq-popover"
						data-qa="petAvatar_input_dateOfDeath"
						label="Date of Death"
						type="date"
						v-model="selectedDateOfDeath"
						@input="onDateOfDeathChange"
						:is-valid="true"
						:disabled="false"
					/>
					<aq-form-input-error
						class="pb-4"
						:error-messages="$options.errorMessages"
						:validator="$v"
						property="selectedDateOfDeath"
					/>
				</div>
			</template>
		</v-popover>
		<aq-pet-avatar
			:pet="pet"
			:class="petAvatarClass"
			:tooltip="false"
		/>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import PetService from '@commonServices/petService';
import { minValue, maxValue } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { shortDate } from '@clientCommon/services/utils/filters';

const errorMessages = {
	selectedDateOfDeath: {
		minValue: ValidationMessages.dateCannotBeBefore('Date of Birth'),
		maxValue: ValidationMessages.dateInFuture,
	},
};

export default {
	name: 'AqEditPetDateOfDeath',
	props: {
		pet: {
			type: Object,
			required: true,
		},
		petAvatarClass: {
			type: [String, Array],
			required: false,
			default: () => ([]),
		},
	},
	validations () {
		return {
			selectedDateOfDeath: {
				minValue: minValue(this.pet.birthDate),
				maxValue: maxValue(this.dateForFutureValidation),
			},
		};
	},
	errorMessages,
	data () {
		return {
			selectedDateOfDeath: this.pet.dateOfDeath,
			previousDateOfDeath: this.pet.dateOfDeath,
		};
	},
	computed: {
		...mapGetters(['dateForFutureValidation']),
		dateOfDeathDialogReference () {
			return `dateOfDeathDialog_${this.pet.id}`;
		},
	},
	mounted () {
		this.previousDateOfDeath = this.pet.dateOfDeath;
		this.selectedDateOfDeath = this.pet.dateOfDeath;
	},
	methods: {
		async onDateOfDeathChange (value) {
			this.$v.selectedDateOfDeath.$touch();
			if (this.$v.selectedDateOfDeath.$error) {
				return;
			}
			this.$refs.calendarPopover.hide();

			if (await this.$refs[this.dateOfDeathDialogReference].show() === 'yes') {
				try {
					await PetService.updatePetDateOfDeath(this.pet.id, value);
					this.pet.dateOfDeath = value;
				} catch (error) {
					this.selectedDateOfDeath = this.previousDateOfDeath;
				}
			} else {
				this.selectedDateOfDeath = this.previousDateOfDeath;
			}
		},
		shortDateOrNoDate (dt) {
			return shortDate(dt) || 'No Date';
		},
	},
};
</script>
<style lang="scss" scoped>
.edit-pet {
  position: relative;
}

.fas.fa-pencil-alt {
  position: absolute;
  font-size: 18px;
  z-index: 2;
  color: $primary;
  transform: scale(1.3);
}

.pet-card {
  position: absolute !important;
  top: 0;
  right: 0;

  .fas.fa-pencil-alt {
    top: -13px;
    right: -23px;
  }
}
</style>
