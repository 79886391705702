<template>
	<div class="container-fluid">
		<div class="section-header row">
			<div class="icon">
				<i class="fas fa-table" />
			</div>
			<div class="section-header__title">
				Edit Filters
			</div>
		</div>
		<div class="section-content row">
			<div class="col-3">
				<aq-form-input
					data-qa="zipFilesFilters_input_dateFrom"
					:label="filter.dateFrom.label"
					type="date"
					v-model="filter.dateFrom.value"
				/>
			</div>
			<div class="col-3">
				<aq-form-input
					data-qa="zipFilesFilters_input_dateTo"
					:label="filter.dateTo.label"
					type="date"
					v-model="filter.dateTo.value"
				/>
			</div>
		</div>
		<div class="row section-header d-flex justify-content-end">
			<button
				class="btn btn-row mr-8"
				@click="$emit('cancel')"
				data-qa="zipFilesFilters_button_cancel"
			>
				Cancel
			</button>
			<button
				class="btn btn-primary"
				@click="$emit('apply-filters')"
				data-qa="zipFilesFilters_button_run"
			>
				Run
			</button>
		</div>
	</div>
</template>
<script>

export default {
	props: {
		filter: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
  .icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: $primary;
    font-size: 26px;
  }

  .section-header {
    background: var(--headerItemBg);
  }
</style>
