<template>
	<div
		class="control-container"
		:class="[
			isRowControl ? 'row-control position-absolute' : 'position-relative',
			{ 'disabled': disabled, 'default': !isToggled && !value && label && !disabled }
		]"
	>
		<label
			class="label"
			v-if="label"
			:class="[labelClass, placeholderLabel]"
		>
			{{ label }}
		</label>
		<multiselect
			:options="sortedOptions"
			:value="value"
			@input="onValueChanged"
			@open="setToggled(true)"
			@close="setToggled(false)"
			@remove="$emit('remove', $event)"
			:placeholder="placeholder"
			:multiple="multiple"
			:label="optionLabel"
			:option-height="optionHeight"
			:disabled="disabled"
			:max-height="maxHeight"
			:class="[
				{ 'multiselect__error__highlight' : !isValid, disabled },
				{ 'hide-tags' : hideTags }
			]"
			:track-by="trackBy"
			:close-on-select="closeOnSelect"
			:show-labels="false"
			:group-values="groupValues"
			:group-label="groupLabel"
			:group-select="groupSelect"
			:searchable="searchable"
			:allow-empty="allowEmpty"
			:clear-on-select="clearOnSelect"

			:internal-search="!asyncFind"
			:loading="!!asyncFind && loading"
			@search-change="asyncFindWrap"
		>
			<template
				v-if="truncateOptions"
				#option="props"
			>
				<slot :option="props.option">
					<aq-tooltip-on-truncate
						:tooltip-text="getOptionLabel(props.option)"
						offset="12"
					>
						<div class="text-truncate">
							{{ getOptionLabel(props.option) }}
						</div>
					</aq-tooltip-on-truncate>
				</slot>
			</template>
			<template #tag>
				<slot name="tag">
					<div v-if="hideTags" />
				</slot>
			</template>
			<template #singleLabel="props">
				<slot
					name="singleLabel"
					:option="props.option"
				>
					<aq-tooltip-on-truncate
						:tooltip-text="getOptionLabel(props.option)"
						offset="12"
					>
						<div class="text-truncate">
							{{ getOptionLabel(props.option) }}
						</div>
					</aq-tooltip-on-truncate>
				</slot>
			</template>
			<template
				#selection="{ values, search, isOpen }"
			>
				<slot
					name="selectedOption"
					:props="{ values, search, isOpen }"
				/>
			</template>
			<template #beforeList>
				<slot name="before-list" />
			</template>
			<template #afterList>
				<slot name="after-list" />
			</template>

			<template #noOptions>
				<span>{{ noOptionsMessage }}</span>
			</template>
			<template #noResult>
				<span>{{ noResultMessage }}</span>
			</template>
		</multiselect>
		<div
			class="locked"
			v-if="disabled"
		>
			<i class="fas fa-lock" />
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
	name: 'AqSelect',
	components: { Multiselect },
	props: {
		value: {
			type: [Array, Object, String],
			required: false,
			default: null,
		},
		searchable: {
			type: Boolean,
			required: false,
			default: true,
		},
		placeholder: {
			type: String,
			required: false,
			default: '',
		},
		label: {
			type: String,
			required: false,
			default: null,
		},
		optionLabel: {
			type: String,
			required: false,
			default: null,
		},
		options: {
			type: Array,
			required: false,
			default: () => [],
		},
		multiple: {
			type: Boolean,
			required: false,
			default: false,
		},
		asyncFind: {
			type: Function,
			required: false,
			default: null,
		},
		openDirection: {
			type: String,
			required: false,
			default: 'bottom',
			validator: (value) => {
				return ['bottom', 'top'].indexOf(value) !== -1;
			},
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		clearOnSelect: {
			type: Boolean,
			required: false,
			default: true,
		},
		closeOnSelect: {
			type: Boolean,
			required: false,
			default: true,
		},
		optionHeight: {
			type: Number,
			required: false,
			default: 50,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		maxHeight: {
			type: Number,
			required: false,
		},
		isValid: {
			type: Boolean,
			required: false,
			default: true,
		},
		trackBy: {
			type: String,
			required: false,
		},
		noResultMessage: {
			type: String,
			required: false,
			default: 'No Elements found',
		},
		noOptionsMessage: {
			type: String,
			required: false,
			default: '',
		},
		hideTags: {
			type: Boolean,
			required: false,
			default: false,
		},
		isRowControl: {
			type: Boolean,
			required: false,
		},
		isSorted: {
			type: Boolean,
			required: false,
			default: true,
		},
		groupValues: {
			type: String,
			required: false,
		},
		groupLabel: {
			type: String,
			required: false,
		},
		groupSelect: {
			type: Boolean,
			required: false,
			default: false,
		},
		truncateOptions: {
			type: Boolean,
			required: false,
			default: true,
		},
		allowEmpty: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data () {
		return {
			isToggled: false,
			isDisabled: false,
			isTouched: false,
		};
	},
	computed: {
		placeholderLabel: function () {
			if (this.isToggled || this.disabled
				|| (Array.isArray(this.value) && this.value.length)
				|| (this.value && !Array.isArray(this.value))) {
				return '';
			}
			return 'label-untouch';
		},
		labelClass () {
			const classes = [];

			if (this.disabled && !this.value) {
				classes.push('disabled-text');
			} else {
				this.isValid ? classes.push('text-primary') : classes.push('invalid');
			}

			return classes;
		},
		sortedOptions: function () {
			if (this.isSorted) {
				const sortedArray = [...this.options];
				if (this.optionLabel) {
					return sortedArray.sort((a, b) => a[this.optionLabel] > b[this.optionLabel] ? 1 : -1);
				}
				return sortedArray.sort((a, b) => a > b ? 1 : -1);
			}
			return this.options;
		},
	},
	methods: {
		onValueChanged (newValue) {
			this.$emit('input', newValue);
		},
		getOptionLabel (option) {
			if (!option) return '';
			if (option && this.optionLabel) return option[this.optionLabel];
			return option;
		},
		asyncFindWrap (...args) {
			if (this.asyncFind) {
				this.asyncFind(...args);
			}
		},
		setToggled (toggled) {
			this.isToggled = toggled;
			this.$emit('update-toggled', toggled);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect {
  color: var(--bodyColour) !important;
  min-height: 30px;

  &__content-wrapper {
    background: var(--headerBg);
    border-top: none;

    .multiselect__content {
      max-width: 100%;
    }

    .multiselect__element {
      &:first-child {
        border-top: 1px solid $gray;
      }

      .multiselect__option {
        overflow: hidden;
        text-overflow: ellipsis;

        &.multiselect__option--group {
          background: var(--searchBg);
          color: var(--paragraphColour);
          font-weight: bold;
        }
      }
    }
  }

  .multiselect__tag {
    background: $active-color;
    font-size: 13px;

    .multiselect__tag-icon {
      color: $danger !important;

      &:hover {
        background: $danger-l-5;
        color: $body-color;
      }

      &::after {
        font-size: 16px;
      }
    }
  }

  .select-option-info {
    color: var(--subSectionColour);
  }
}

.row-control {
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 20px);

  ::v-deep .multiselect {
    height: 100%;
  }

  ::v-deep .multiselect__tags {
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
  }

  ::v-deep .multiselect__select {
    top: 7px;
  }
}

.control-container {
  padding-right: 0;

  &:not(.row-control) {
    .multiselect {
      margin-top: 7px;

      ::v-deep .multiselect__option {
        color: #fff;
      }
    }
  }
}

.disabled-text {
  color: $gray;
  font-size: 14px;
  top: 15px;
}

::v-deep .multiselect__single {
  font-size: 14px;
  margin: 5px 0 -0;
}

::v-deep .multiselect--disabled {
  .multiselect__select {
    display: none;
  }
}
</style>
