const ExclusionTypeEnum = Object.freeze({
	SixMonths: 1,
	TwelveMonths: 2,
	Renewal: 3,
	Permanently: 4,
	PossibleSign: 5,
	OvertCondition: 6,
});

const ExclusionTypeEnumMap = Object.freeze({
	[ExclusionTypeEnum.SixMonths]: 'Six month exclusion period',
	[ExclusionTypeEnum.TwelveMonths]: 'Twelve month exclusion period',
	[ExclusionTypeEnum.Renewal]: 'Renewal period',
	[ExclusionTypeEnum.Permanently]: 'Permanently excluded',
	[ExclusionTypeEnum.PossibleSign]: 'Possible Sign',
	[ExclusionTypeEnum.OvertCondition]: 'Overt Condition',
});

export { ExclusionTypeEnum, ExclusionTypeEnumMap };
