import httpClient from './httpClient';

const BASE_PATH = '/api/claims/vets';

export default {
	getVetAlerts (externalVetIds) {
		return httpClient(`${BASE_PATH}/alerts`, { omitSpinner: true })
			.post(externalVetIds);
	},
	addVetAlert (vetId, request) {
		return httpClient(`${BASE_PATH}/${vetId}/alerts`, { omitSpinner: true })
			.post(request);
	},
	getVetInfo (externalVetIds) {
		return httpClient(`${BASE_PATH}/vetinfos`, { omitSpinner: true })
			.post(externalVetIds);
	},

	// #region logic
	isDirectPay (vet) {
		return vet.vetDirectPay === null || vet.vetDirectPay;
	},
	// #endregion
};
