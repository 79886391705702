import querystring from 'querystring';
import httpClient from '@commonServices/httpClient';
import { toZipFileView } from '@commonServices/utils/lettersConverter';

export default {
	// #region requests

	filterZippedFiles ({ dateFrom, dateTo }, paging) {
		const params = { ...paging.toQueryObject() };

		if (dateFrom) {
			params.dateFrom = dateFrom.toISOString();
		}
		if (dateTo) {
			params.dateTo = dateTo.toISOString();
		}

		return httpClient(`/api/claims/zipfiles/filter?${querystring.stringify(params)}`)
			.get()
			.then(toZipFileView);
	},

	// #endregion

	// #region logic

	async downloadZipFile (id, fileName) {
		const link = window.document.createElement('a');
		const documentSrc = `/api/claims/zipfiles/${id}`;
		const fileBlob = await this.getZipFileBlob(documentSrc);
		link.href = window.URL.createObjectURL(fileBlob);
		link.download = fileName;
		link.click();
	},
	getZipFileBlob (src) {
		return httpClient(src, { omitSpinner: true })
			.getBlob()
			.then((data) => new Blob([data], { type: 'octet/stream' }));
	},

	// #endregion
};
