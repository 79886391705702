<template>
	<div class="claim-info-term h-100">
		<div class="label">
			{{ label }}
		</div>
		<slot name="description">
			<div
				class="description"
			>
				{{ description || 'N/A' }}
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	name: 'AqInfoTerm',
	props: {
		label: {
			type: String,
			required: true,
		},
		description: {
			type: [String, Number, null],
			required: false,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.claim-info-term {
  background: var(--attributeBg);
  padding: 8px 12px;
  border-radius: 3px;

  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    color: var(--cardIconColour);
  }

  .description {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }
}
</style>
