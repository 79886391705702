<template>
	<div>
		<aq-select
			:options="options"
			:label="dateFilter.label"
			class="w-100"
			:max-height="330"
			:value="dateFilter.type"
			@input="update('type', $event)"
			:is-sorted="false"
		/>
		<div
			v-if="showDatePicker"
			:class="itemContainerClass"
		>
			<aq-form-input
				:label="isRangeDate ? 'Date From' : 'Date'"
				type="date"
				:value="dateFilter.dateFrom"
				@input="update('dateFrom', $event)"
			/>
			<aq-form-input-error
				class="error my-4"
				:error-messages="this.$options.errorMessages"
				:validator="$v.dateFilter"
				property="dateFrom"
			/>
		</div>
		<div
			v-if="isRangeDate"
			:class="itemContainerClass"
		>
			<aq-form-input
				label="Date To"
				type="date"
				:value="dateFilter.dateTo"
				@input="update('dateTo', $event)"
			/>
			<aq-form-input-error
				class="error my-4"
				:error-messages="this.$options.errorMessages"
				:validator="$v.dateFilter"
				property="dateTo"
			/>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DateTypes, AllDateTypes, getDayEnd } from '@commonServices/utils/dateUtils';
import { requiredIf, maxValue } from 'vuelidate/lib/validators';
import { moreThenOrEqual } from '@commonServices/utils/validators';
import { shownErrors } from '@commonServices/utils/general';
import { ValidationMessages } from '@commonServices/utils/constants';

const errorMessages = {
	dateFrom: {
		requiredIf: ValidationMessages.required,
		maxValue: ValidationMessages.dateInFuture,
	},
	dateTo: {
		maxValue: ValidationMessages.dateInFuture,
		minValue: ValidationMessages.dateShouldBeAfter('Date From'),
		requiredIf: ValidationMessages.required,
	},
};

export default {
	name: 'DateFilter',
	errorMessages,
	data () {
		return {
			dateFilter: this.value,
		};
	},
	props: {
		options: {
			type: Array,
			required: false,
			default () { return AllDateTypes; },
		},
		value: {
			type: Object, // {type, label, dateFrom, dateTo }
			default () { return {}; },
		},
		itemContainerClass: {
			type: String,
			default: 'mt-20',
		},
	},
	validations () {
		return {
			dateFilter: {
				dateFrom: {
					requiredIf: requiredIf(() => {
						return this.showDatePicker;
					}),
					maxValue: maxValue(getDayEnd()),
				},
				dateTo: {
					requiredIf: requiredIf(() => {
						return this.isRangeDate;
					}),
					maxValue: maxValue(getDayEnd(this.dateForFutureValidation)),
					minValue: moreThenOrEqual(this.dateFilter.dateFrom || new Date(null)),
				},
			},
		};
	},
	computed: {
		...mapGetters(['dateForFutureValidation']),
		showDatePicker () {
			return [DateTypes.SelectDate, DateTypes.AfterDate, DateTypes.BeforeDate, DateTypes.BetweenDates].includes(this.dateFilter.type);
		},
		isRangeDate () {
			return this.dateFilter.type === DateTypes.BetweenDates;
		},
		isValid () {
			return !this.$v.dateFilter.$invalid;
		},
	},
	methods: {
		update (key, value) {
			this.dateFilter = {
				...this.dateFilter,
				[key]: value,
				...key === 'type' && value !== this.dateFilter.type && { dateFrom: null, dateTo: null },
			};
			this.$v.dateFilter.$touch();
			if (this.isValid) {
				this.$emit('input', this.dateFilter);
			}
		},
		errors (property) {
			return shownErrors(
				this.$v.dateFilter,
				property,
				errorMessages.dateFilter[property],
			);
		},
	},
};
</script>
