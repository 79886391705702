<template>
	<div />
</template>
<script>
import { mapState } from 'vuex';
import { toastActions } from '@commonServices/settings/toastSettings';
import PANELS from '@commonServices/models/PanelEnum';
import NotesService from '@commonServices/notesService';
import VetService from '@commonServices/vetService';
import { unique } from '@commonServices/utils/general';

const toastOptions = {
	className: ['toast-alert', 'toast-alert-note'],
	duration: 5000,
	containerClass: 'toast-note',
	position: 'top-right',
};

export default {
	props: {
		suppressNotification: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			notesStats: {},
		};
	},
	computed: {
		...mapState(['interactionContext', 'appSettings']),
		interactionContextHash () {
			return `${this.interactionContext.customer?.id}${this.interactionContext.pet?.id}${this.interactionContext.claim?.id}${this.interactionContext.claim?.registeredVets?.length}`;
		},
	},
	watch: {
		interactionContextHash () {
			this.$toasted.clear();
			this.checkNotes();
		},
	},
	methods: {
		async checkNotes () {
			this.notesStats = await this.getNotesStats();
			this.$emit('notes-changed', this.notesStats);
			this.showNotifications(this.notesStats);
		},
		async getNotesStats () {
			const { customer, pet, claim } = this.interactionContext;

			const claimVetIds = claim?.registeredVets?.filter(registeredVet => registeredVet.id)
				.map(registeredVet => registeredVet.id) ?? [];
			const petVetIds = pet?.registeredVets?.map(registeredVet => registeredVet.id) ?? [];
			const vetIds = unique([...claimVetIds, ...petVetIds]);

			const canViewNotes = this.$can.ViewNotes;
			const [claimCount, petCount, customerCount, vetAlerts] = await Promise.all([
				claim && claim.id && canViewNotes ? NotesService.getNotesCount({ claimId: claim.id }) : Promise.resolve(0),
				pet && canViewNotes ? NotesService.getNotesCount({ petId: pet.id }) : Promise.resolve(0),
				customer && canViewNotes ? NotesService.getNotesCount({ customerId: customer.id }) : Promise.resolve(0),
				this.appSettings.vetExternalServices && vetIds.length ? VetService.getVetAlerts(vetIds) : Promise.resolve([]),
			]);

			const activeVetIds = pet?.registeredVets?.filter(v => !v.isHistorical).map(v => v.id);
			const countableVetAlerts = vetAlerts.filter(alert => activeVetIds.includes(alert.externalVetId) || claimVetIds.includes(alert.externalVetId));
			return { claimCount, petCount, customerCount, vetCount: countableVetAlerts.length };
		},
		showNotifications (allNotesStats) {
			if (!this.appSettings.alertNotesNotification
			||	this.suppressNotification
			|| (allNotesStats.customerCount + allNotesStats.petCount + allNotesStats.claimCount + allNotesStats.vetCount === 0)) {
				return;
			}

			const toastNotificationActions = [];
			if (allNotesStats.customerCount) {
				toastNotificationActions.push({
					text: `Customer alerts ${allNotesStats.customerCount}`,
					class: 'toast-alert-item',
					onClick: () => this.onClickAlertItem(PANELS.Interactions, { customerId: this.interactionContext.customer.id }),
				});
			}
			if (allNotesStats.petCount) {
				toastNotificationActions.push({
					text: `Pet alerts ${allNotesStats.petCount}`,
					class: 'toast-alert-item',
					onClick: () => this.onClickAlertItem(PANELS.Interactions, { petId: this.interactionContext.pet.id }),
				});
			}
			if (allNotesStats.claimCount) {
				toastNotificationActions.push({
					text: `Claim alerts ${allNotesStats.claimCount}`,
					class: 'toast-alert-item',
					onClick: () => this.onClickAlertItem(PANELS.Interactions, { claimId: this.interactionContext.claim.id }),
				});
			}
			if (allNotesStats.vetCount) {
				toastNotificationActions.push({
					text: `Vet alerts ${allNotesStats.vetCount}`,
					class: 'toast-alert-item',
					onClick: () => this.onClickAlertItem(PANELS.Vet),
				});
			}
			toastNotificationActions.push(toastActions.close);

			this.$toasted.show('<div class="px-10 mb-5"><i class="aqv-info fs-18 mr-5"></i>Please review alert notes:</div>',
				{ ...toastOptions, action: toastNotificationActions });
		},
		onClickAlertItem (panel, levelFilter) {
			this.$emit('alert-click', { panel, levelFilter });
		},
	},
};
</script>

<style lang="scss">
.toast-alert-note {
  display: grid !important;
}

.toast-alert-item {
  margin: 0 25px !important;
  padding: 4px 0 !important;
}

.toast-alert-item:hover {
  font-weight: bold;
}

.toast-note {
  top: 63px !important;
  right: 72px !important;
  font-weight: 300;
  width: 293px;

  .action.ripple.icon {
    position: absolute;
    right: 0;
    width: 80px;
  }
}
</style>
