<template>
	<div
		class="bookmark"
		:class="{ 'large': largeIcon }"
		v-tooltip="tooltip"
	>
		<BookmarkCard
			v-if="largeIcon"
			class="icon"
		/>
		<BookmarkList
			v-else
			class="icon"
		/>
		<label>{{ label }}</label>
	</div>
</template>

<script>
import { BookmarkCard, BookmarkList } from '@assets/icons';

export default {
	name: 'AqBookmark',
	components: {
		BookmarkCard,
		BookmarkList,
	},
	props: {
		largeIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: true,
		},
		tooltip: {
			type: String,
			required: false,
			default: '',
		},
	},
};
</script>

<style scoped lang="scss">
.bookmark {
  position: absolute;

  label {
    position: absolute;
    color: white;
    width: 38px;
    left: 0;
    text-align: center;
  }

  .icon {
    fill: #203543;
  }

  &:hover {
    .icon {
      fill: #096AA3;
    }
  }

  &.large {
    width: 65px;
    height: 35px;

    label {
      bottom: -5px;
      left: 8px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      width: 80px;
    }

    .icon {
      fill: var(--cardIconColour);
    }
  }

}

</style>
