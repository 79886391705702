import statusEnum from '@commonServices/models/steps/StepStatusEnum';
import StepTypeEnum from '@commonServices/models/steps/StepTypeEnum';
import StepNameEnum from '@commonServices/models/steps/StepNameEnum';

function claimStepsFabric (isReadOnlyMode, hasClaimItems, hasPayments) {
	const steps = initializeClaimSteps();
	if (isReadOnlyMode) {
		markStepAsPassed(steps[0]);
		markStepAsPassed(steps[1]);

		if (hasClaimItems) {
			markStepAsPassed(steps[2]);
		}
		if (hasPayments) {
			markStepAsPassed(steps[3]);
		}
	}

	markStepAsCurrent(steps[0]);
	return steps;
}

function initializeClaimSteps () {
	return [
		{
			title: 'Claim',
			iconClass: 'fas fa-file-invoice',
			status: statusEnum.PROCESS,
			stepName: StepNameEnum.ClaimEnterBasic,
			isVisible: true,
			type: StepTypeEnum.MAJOR,
		},
		{
			title: 'Request Info',
			iconClass: 'fas fa-question',
			status: statusEnum.WAIT,
			stepName: StepNameEnum.ClaimMissingItems,
			isVisible: false,
			type: StepTypeEnum.MINOR,
		},
		{
			title: 'Process',
			iconClass: 'fas fa-sync',
			status: statusEnum.WAIT,
			stepName: StepNameEnum.ClaimProcess,
			isVisible: true,
			type: StepTypeEnum.MAJOR,
		},
		{
			title: 'Payment',
			iconClass: 'fas fa-credit-card',
			status: statusEnum.WAIT,
			stepName: StepNameEnum.ClaimPayment,
			isVisible: true,
			type: StepTypeEnum.MAJOR,
		},
	];
}

function markStepAsPassed (step) {
	step.status = statusEnum.SUCCESS;
	step.isPassed = true;
}
function markStepAsCurrent (step) {
	step.status = statusEnum.PROCESS;
}

export {
	claimStepsFabric,
	initializeClaimSteps,
};
