const FileCategoryEnum = Object.freeze({
	ClaimForm: 1,
	Invoice: 2,
	MedicalCertificate: 3,
	AcknowledgementEmail: 4,
	AcknowledgementSms: 5,
	WaitPeriodEmail: 6,
	LetterRejectedWaitPeriod: 7,
	EmailInformationRequest: 8,
	LetterInformationRequest: 9,
	EmailRejectedNoLimit: 10,
	LetterRejectedNoLimit: 11,
	SettlementEmail: 12,
	SettlementLetter: 13,
	RejectedManuallyEmail: 14,
	RejectedManuallyLetter: 15,
	ChaseLetter: 16,
	ChaseEmail: 17,
	ChaseSms: 18,
	ReassessmentEmail: 19,
	ReassessmentLetter: 20,
	RejectedByPreExistingConditionEmail: 21,
	RejectedByPreExistingConditionLetter: 22,
	RejectedNoActiveCoverPeriodEmail: 23,
	RejectedNoActiveCoverPeriodLetter: 24,
	SettlementSms: 25,
	EmailAttachment: 26,
	PreAuthorisationCompletedEmail: 27,
	PreAuthorisationCompletedLetter: 28,
	HospitalInvoice: 29,
	Other: 30,
	MedicalRecordSummary: 31,
	Dispute: 32,
	DeclarationPage: 33,
	ProofOfSecondaryInsurance: 34,
	InboundEmail: 35,
	ReassessmentAcknowledgementEmail: 36,
	ReassessmentAcknowledgementSms: 37,
	MedicalRecordsSummaryLetter: 38,
	MedicalRecordsSummaryEmail: 39,
	ClaimBotResponse: 40,
	GreyMedicalRecordsSummaryLetter: 41,
	GreyMedicalRecordsSummaryEmail: 42,
	RejectedNoProductCoverageEmail: 43,
	RejectedNoProductCoverageLetter: 44,
	RejectedMultipleEmail: 45,
	RejectedMultipleLetter: 46,
});

const FileCategoryEnumLabel = Object.freeze({
	[FileCategoryEnum.ClaimForm]: 'Claim Form',
	[FileCategoryEnum.Invoice]: 'Invoice',
	[FileCategoryEnum.MedicalCertificate]: 'Medical History',
	[FileCategoryEnum.AcknowledgementEmail]: 'Acknowledgement Email',
	[FileCategoryEnum.AcknowledgementSms]: 'Acknowledgement SMS',
	[FileCategoryEnum.WaitPeriodEmail]: 'Wait Period Email',
	[FileCategoryEnum.LetterRejectedWaitPeriod]: 'Letter Rejected Wait Period',
	[FileCategoryEnum.EmailInformationRequest]: 'Email Information Request',
	[FileCategoryEnum.LetterInformationRequest]: 'Letter Information Request',
	[FileCategoryEnum.EmailRejectedNoLimit]: 'Email Rejected No Limit',
	[FileCategoryEnum.LetterRejectedNoLimit]: 'Letter Rejected No Limit',
	[FileCategoryEnum.SettlementEmail]: 'Settlement Email',
	[FileCategoryEnum.SettlementLetter]: 'Settlement Letter',
	[FileCategoryEnum.RejectedManuallyEmail]: 'Rejected Manually Email',
	[FileCategoryEnum.RejectedManuallyLetter]: 'Rejected Manually Letter',
	[FileCategoryEnum.ChaseLetter]: 'Chase Letter',
	[FileCategoryEnum.ChaseEmail]: 'Chase Email',
	[FileCategoryEnum.ChaseSms]: 'Chase SMS',
	[FileCategoryEnum.ReassessmentEmail]: 'Reassessment Email',
	[FileCategoryEnum.ReassessmentLetter]: 'Reassessment Letter',
	[FileCategoryEnum.RejectedByPreExistingConditionEmail]: 'Rejected by Preexisting Condition Email',
	[FileCategoryEnum.RejectedByPreExistingConditionLetter]: 'Rejected by Preexisting Condition Letter',
	[FileCategoryEnum.RejectedNoActiveCoverPeriodEmail]: 'Rejected No Active Cover Period Email',
	[FileCategoryEnum.RejectedNoActiveCoverPeriodLetter]: 'Rejected No Active CoverPeriod Letter',
	[FileCategoryEnum.Other]: 'Other',
	[FileCategoryEnum.MedicalRecordSummary]: 'Medical Record Summary',
	[FileCategoryEnum.HospitalInvoice]: 'Hospital Invoice',
	[FileCategoryEnum.PreAuthorisationCompletedEmail]: 'Pre-Authorisation Completed Email',
	[FileCategoryEnum.PreAuthorisationCompletedLetter]: 'Pre-Authorisation Completed Letter',
	[FileCategoryEnum.Dispute]: 'Dispute',
	[FileCategoryEnum.DeclarationPage]: 'Declaration Page',
	[FileCategoryEnum.ProofOfSecondaryInsurance]: 'Proof Of Secondary Insurance',
	[FileCategoryEnum.InboundEmail]: 'Inbound Email',
	[FileCategoryEnum.ReassessmentAcknowledgementEmail]: 'Reassessment Acknowledgement Email',
	[FileCategoryEnum.ReassessmentAcknowledgementSms]: 'Reassessment Acknowledgement Sms',
	[FileCategoryEnum.MedicalRecordsSummaryLetter]: 'Medical Records Summary Letter',
	[FileCategoryEnum.MedicalRecordsSummaryEmail]: 'Medical Records Summary Email',
	[FileCategoryEnum.ClaimBotResponse]: 'ClaimBot Response',
	[FileCategoryEnum.GreyMedicalRecordsSummaryLetter]: 'Grey Medical Records Summary Letter',
	[FileCategoryEnum.GreyMedicalRecordsSummaryEmail]: 'Grey Medical Records Summary Email',
	[FileCategoryEnum.RejectedNoProductCoverageEmail]: 'Rejected No Product Coverage Email',
	[FileCategoryEnum.RejectedNoProductCoverageLetter]: 'Rejected No Product Coverage Letter',
	[FileCategoryEnum.RejectedMultipleEmail]: 'Rejected by Multiple Reasons Email',
	[FileCategoryEnum.RejectedMultipleLetter]: 'Rejected by Multiple Reasons Letter',
});

export { FileCategoryEnum, FileCategoryEnumLabel };
