<template>
	<div>
		<aq-sandwich
			ref="sandwich"
			header="Payment Recipients"
			:active-class="''"
			is-active-on-start
			header-class="pl-25"
			class="mb-50 mr-25"
		>
			<template slot="content">
				<div>
					<div class="section-content ml-25">
						<div class="container-fluid">
							<el-table
								ref="paymentRecipients"
								empty-text="The table is empty"
								:data="regularPayments"
								data-qa="paymentRecipients_table_recipients"
							>
								<el-table-column
									label="Recipient"
									width="200"
									min-width="10"
									data-qa="paymentRecipients_column_recipient"
								>
									<template slot-scope="scope">
										<div class="font-weight-bold">
											{{ toRecipientLabel(scope.row.recipient.recipientType) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payee"
									prop="recipient.recipientName"
									min-width="20"
									data-qa="paymentRecipients_column_payee"
								/>
								<el-table-column
									label="Payment Type"
									min-width="25"
									data-qa="paymentRecipients_column_paymentType"
								>
									<template>
										<div>Claim</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payment Details"
									min-width="30"
									data-qa="paymentRecipients_column_paymentDetails"
								>
									<template slot-scope="scope">
										<div
											:key="scope.row.selectedPayment ? scope.row.selectedPayment.id : 0"
											class="mr-10"
										>
											<aq-select
												:options="getActivePaymentAccounts(scope.row.recipient.accounts)"
												option-label="payee"
												placeholder="Payment Details"
												:disabled="!$can.AddPayment || isReadOnlyMode || (totalRemainingInitial === 0 && scope.row.selectedPayment == null)"
												v-model="scope.row.selectedPayment"
												@remove="onPaymentDetailsRemoved(scope.row.uniqId)"
												@input="touch(scope.row.uniqId, 'paymentAmount'); autoSetSplitAmount(scope.row);"
												track-by="id"
												no-result-message="No Payment Details found"
												data-qa="paymentRecipients_select_paymentDetails"
											>
												<template #default="props">
													<div class="select-option-header text-truncate d-flex align-items-center">
														<div class="payment-icon">
															<component
																:is="iconSettings[props.option.paymentMethod]"
															/>
														</div>
														{{ props.option.payee }}
													</div>
													<div
														class="select-option-info text-truncate"
														v-if="isBacsPaymentMethod(props.option.paymentMethod)"
													>
														<span v-if="props.option.bankName">
															{{ props.option.bankName }},&nbsp;
														</span>
														<span>{{ props.option.sortCode }},&nbsp;</span>
														<span>{{ props.option.accountNumber }}</span>
													</div>
													<div />
												</template>
												<template
													slot="singleLabel"
													slot-scope="props"
												>
													<div>
														<span>{{ props.option.payee }}</span>
														<span v-if="isBacsPaymentMethod(props.option.paymentMethod)">
															<span v-if="props.option.bankName">
																,&nbsp;{{ props.option.bankName }},
															</span>
															{{ props.option.sortCode }},
															{{ props.option.accountNumber }}
														</span>
													</div>
													<div
														v-if="isReadOnlyMode && !props.option.isActive"
														class="text-warning fs-12"
													>
														Account marked as inactive
													</div>
												</template>
												<template slot="before-list">
													<div
														class="p-20 create-new"
														@click="$emit('open-payee', scope.row.recipient)"
														v-if="$can.ManagePaymentAccount"
													>
														<button
															type="button"
															class="btn small btn-primary mr-10"
															data-qa="paymentRecipients_button_openPayee"
														>
															<i class="fas fa-plus text-white" />
														</button>
														<span>Create New Account</span>
													</div>
												</template>
											</aq-select>
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Split"
									min-width="15"
									data-qa="paymentRecipients_column_splitAmount"
								>
									<template slot-scope="scope">
										<aq-form-input
											v-model="scope.row.paymentAmount"
											:disabled="!$can.AddPayment || isReadOnlyMode || !scope.row.selectedPayment"
											icon-class="fa-sm"
											input-class="table-control"
											type="money"
											:locale="locale"
											@input="touch(scope.row.uniqId, 'paymentAmount')"
											:is-valid="!hasError(scope.row.uniqId, 'paymentAmount')"
											data-qa="paymentRecipients_input_paymentAmount"
										/>
										<div
											class="error mt-4"
											v-if="hasError(scope.row.uniqId, 'paymentAmount')"
										>
											<div
												class="text-danger"
												v-for="value in errors(scope.row.uniqId, 'paymentAmount')"
												:key="value"
											>
												{{ value }}
											</div>
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<div class="d-flex ml-25 section-footer p-0">
						<div class="total-remaining d-flex align-items-center justify-content-end px-20 ml-auto">
							Total {{ totalRemainingInitial | currency(locale) }}
						</div>
						<div :class="[{ 'valid' : tableValid }, 'total-remaining d-flex align-items-center justify-content-end px-20']">
							Remaining {{ totalRemaining | currency(locale) }}
						</div>
						<div
							:class="[{ 'valid bg-success' : tableValid, 'bg-danger': !tableValid }, 'total mr-20 px-20 d-flex align-items-center']"
						>
							<div class="text-truncate">
								{{ totalAmount | currency(locale) }}
							</div>
						</div>
					</div>
				</div>
			</template>
		</aq-sandwich>
	</div>
</template>

<script>
import { minValue, maxValue, required, maxLength } from 'vuelidate/lib/validators';
import { shownErrors, areNumbersEqual } from '@commonServices/utils/general';
import { ValidationMessages } from '@commonServices/utils/constants';
import { PaymentMethodIconSettings } from '@commonServices/settings/themeSettings';
import { PaymentMethod } from '@commonServices/models/PaymentMethod';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

const errorMessages = {
	regularPayments: {
		paymentAmount: {
			required: ValidationMessages.required,
			minValue: ValidationMessages.noNegative,
			maxValue: 'This field cannot be positive',
			exceedValue: 'Value cannot exceed original split amount',
			valid: 'Value cannot be 0',
			maxLength: 'The max length is 18 digits',
		},
	},
};

export default {
	name: 'PaymentRecipients',
	props: {
		regularPayments: {
			type: Array,
			required: true,
		},
		totalRemainingInitial: {
			type: Number,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		isPositiveNet: {
			type: Boolean,
			required: false,
		},
		paymentHistoryItems: {
			type: Array,
			required: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			tableValid: false,
		};
	},
	computed: {
		...mapState(['appSettings']),
		totalAmount () {
			return this.regularPayments
				.reduce((accum, currentValue) => accum + Number(currentValue.paymentAmount), 0);
		},
		totalRemaining () {
			return this.totalRemainingInitial - this.totalAmount;
		},
		isRecipientsValid () {
			return Object.values(this.$v.regularPayments.$each.$iter)
				.every((item) => {
					return !item.paymentAmount.$error;
				});
		},
		isRecipientsValidForDraft () {
			return Object.values(this.$v.regularPayments.$each.$iter)
				.every((item) => {
					if (this.isPositiveNet) {
						return item.paymentAmount.minValue && item.paymentAmount.maxLength;
					}
					return item.paymentAmount.maxValue && item.paymentAmount.maxLength;
				});
		},
		compound () {
			return this.totalAmount + this.isRecipientsValid + Date.now();
		},
		iconSettings () {
			return PaymentMethodIconSettings;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	methods: {
		validate () {
			this.$v.$touch();
			return !this.$v.$invalid;
		},
		hasError (id, propName) {
			const v = this.getValidator(id);
			return v[propName].$error;
		},
		errors (id, propName) {
			const v = this.getValidator(id);
			return shownErrors(
				v,
				propName,
				errorMessages.regularPayments[propName],
			);
		},
		touch (id, propName) {
			const v = this.getValidator(id);
			v[propName].$touch();
			this.$emit('dirty');
		},
		getValidator (param) {
			return Object.values(this.$v.regularPayments.$each.$iter)
				.find((item) => item.$model.uniqId === param);
		},
		onPaymentDetailsRemoved (id) {
			this.regularPayments.find((item) => item.uniqId === id).paymentAmount = 0;
		},
		autoSetSplitAmount (row) {
			if (this.regularPayments.length === 1 && row.selectedPayment) {
				row.paymentAmount = this.totalRemainingInitial;
			}
			if (!row.selectedPayment) {
				row.paymentAmount = 0;
			}
		},
		autoSetPaymentDetailsAndSplitAmount () {
			const activePaymentAccounts = this.getActivePaymentAccounts(this.regularPayments[0].recipient.accounts);
			if (!this.isReadOnlyMode && this.regularPayments.length === 1 && activePaymentAccounts.length === 1 && this.totalRemainingInitial !== 0) {
				this.regularPayments[0].selectedPayment = {
					...activePaymentAccounts[0],
				};
				this.regularPayments[0].paymentAmount = this.totalRemainingInitial;
			}
		},
		isBacsPaymentMethod (paymentMethod) {
			return paymentMethod === PaymentMethod.Bacs;
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
		getActivePaymentAccounts (paymentAccounts) {
			return paymentAccounts.filter(a => a.isActive);
		},
	},
	watch: {
		compound () {
			this.tableValid = areNumbersEqual(this.totalRemaining, 0) && this.isRecipientsValid;
			this.$emit('amount-changed', this.tableValid);
		},
		regularPayments () {
			this.autoSetPaymentDetailsAndSplitAmount();
		},
	},
	validations () {
		if (this.isPositiveNet) {
			return {
				regularPayments: {
					$each: {
						paymentAmount: {
							required,
							minValue: minValue(0),
							valid: function (value, recipient) {
								if (recipient.selectedPayment && value === 0) return false;
								return true;
							},
							maxLength: maxLength(18),
						},
					},
				},
			};
		}
		return {
			regularPayments: {
				$each: {
					paymentAmount: {
						required,
						maxValue: maxValue(0),
						exceedValue: function (value, recipient) {
							const amount = parseFloat(value);
							const previousPayments = this.paymentHistoryItems.filter((item) => item.uniqId === recipient.uniqId);

							if (amount !== 0) {
								if (previousPayments.length === 0) return false;

								if (previousPayments) {
									const total = previousPayments.map(item => isNaN(+item.paymentAmount) ? 0 : +item.paymentAmount).reduce((a, b) => a + b);
									const difference = amount + parseFloat(total);
									return difference >= 0;
								}
							}

							return true;
						},
						valid: function (value, recipient) {
							if (recipient.selectedPayment && value === 0) return false;
							return true;
						},
						maxLength: maxLength(18),
					},
				},
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.total-remaining {
  height: 57px;
  color: $body-color;
}

.total {
  height: 57px;
  width: 128px;
  color: $body-color;
  overflow-x: auto;

  &.valid {
    background: rgba($success, 0.23);
  }
}

::v-deep .el-table tr {
  td {
    &:nth-child(3),
    &:last-child {
      padding: 0;

      > .cell {
        padding: 0;
      }
    }
  }
}

::v-deep .el-table .el-table__body {
  margin-top: -25px;
  border-spacing: 0 30px;
}

.error {
  white-space: nowrap;
  position: absolute;
  left: 0;
}

.payment-icon {
  fill: $body-color;
  width: 32px;
  margin: 0 15px;
}
</style>
