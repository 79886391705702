<template>
	<div
		class="pet-avatar card-icon"
	>
		<div
			class="pet-icon"
			:class="[{ deceased: !!pet.dateOfDeath }, className]"
			v-tooltip="tooltipComputed"
		>
			<PetDeathCloudLogo
				v-if="!!pet.dateOfDeath"
				class="pet-cloud"
				:class="{ 'show-alert' : showAlert }"
			/>
			<img
				v-if="pet.photo"
				:src="`data:image/jpeg;base64,${pet.photo}`"
				alt="Pet Photo"
			>
			<i
				v-else
				class="photo-icon"
				:class="[photoIcon, showAlert ? 'show-alert' : '']"
			/>
			<i
				v-if="showAlert"
				class="fas fa-exclamation"
			/>
		</div>
	</div>
</template>

<script>

import PetGender from '@commonServices/models/PetGender';
import { PetDeathCloudLogo } from '@assets/icons';
import { longDate } from '@commonServices/utils/filters';

export default {
	name: 'AqPetAvatar',
	components: {
		PetDeathCloudLogo,
	},
	props: {
		pet: {
			type: Object,
			required: true,
		},
		tooltip: {
			type: [String, Boolean], // 'false' disables tooltip
			required: false,
			default: null,
		},
		showAlert: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		className () {
			return { female: this.pet.gender === PetGender.Female };
		},
		photoIcon () {
			switch (this.pet.type) {
			case 'Dog':
				return 'aqv-defaultdog';
			case 'Cat':
				return 'aqv-defaultcat';
			case 'Rabbit':
				return 'aqv-defaultrabbit';
			default:
				return 'aqv-defaultdog';
			}
		},
		tooltipComputed () {
			return this.tooltip === false ? '' : this.tooltip ?? this.pet.name + (this.pet.dateOfDeath ? `: ${longDate(this.pet.dateOfDeath)}` : '');
		},
	},
};
</script>

<style lang="scss" scoped>
.pet-icon {
  position: relative;
  display: flex;

  &.deceased {
    color: var(--cardIconSecondaryColour);
  }
}

.pet-avatar {
  position: relative;
  cursor: default;

  .photo-icon {
    max-width: none;
    font-size: 34px;

    &:hover {
      color: var(--cardIconColourHover);
    }

    &.show-alert {
      font-size: 40px;
    }
  }

  .female {
    i {
      color: var(--cardIconColour);

      &:hover {
        color: var(--cardIconColourHover);
      }
    }

    &.active {
      i {
        color: var(--bodyColour);
      }
    }
  }

  &.small {
    background: var(--cellIconSmallBg);

    i {
      font-size: 14px;
    }

    &:hover {
      background: var(--cellIconSmallBg);
    }
  }

  &:hover {
    color: var(--cardIconColourHover);
  }

  &.card-icon {
    &.cursor-pointer {
      cursor: pointer;
    }

    &.cursor-inherit {
      cursor: inherit;
    }
  }
}

.pet-cloud {
  pointer-events: none;
  position: absolute;
  top: 47%;
  left: 22%;
  width: 85%;

  &.show-alert {
    left: 5%;
    width: 100%;
  }
}

.right-align {
  &.pet-avatar {
    z-index: 1;
    position: absolute !important;
    top: 0;
    right: 12px;
    background: none !important;
  }

  .edit-pet {
    position: absolute !important;
    top: 0;
    right: 0;
  }
}

.fa-exclamation {
  position: absolute;
  left: 43%;
  top: 57%;
}
</style>
