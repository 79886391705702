import ReportModel from './ReportModel';

export default class ClaimPaymentsReportModel extends ReportModel {
	constructor (
		brandName,
		brandLocale,
		customerId,
		customerRef,
		policyId,
		policyReference,
		claimId,
		customerFirstName,
		customerLastName,
		vetBusinessNames,
		paymentMethod,
		accountName,
		sortCode,
		accountNumber,
		payee,
		claimAmountPaid,
		claimExGratiaAmountPaid,
		claimDatePaid,
	) {
		super(brandName,
			brandLocale,
			customerId,
			policyId,
			policyReference,
			claimId,
			customerFirstName,
			customerLastName,
		);
		this.customerRef = customerRef;
		this.vetBusinessNames = vetBusinessNames;
		this.paymentMethod = paymentMethod;
		this.accountName = accountName;
		this.sortCode = sortCode;
		this.accountNumber = accountNumber;
		this.payee = payee;
		this.claimAmountPaid = claimAmountPaid;
		this.claimExGratiaAmountPaid = claimExGratiaAmountPaid;
		this.claimDatePaid = claimDatePaid;
	}
};
