<template>
	<div>
		<aq-select
			data-qa="aqAilments_input_ailment"
			:loading="isAilmentLoading"
			:options="ailments"
			:async-find="searchAilmentsAsync"
			label="Ailment"
			option-label="firstCause"
			:is-valid="isValid"
			class="w-100"
			:value="value"
			@input="input($event)"
			no-result-message="No Ailment found"
			:disabled="disabled"
		>
			<template #default="props">
				<div>
					<div class="py-4 select-option-header text-truncate">
						{{ props.option.firstCause }}
					</div>
					<div class="py-4 select-option-info text-truncate">
						{{ props.option.secondCause }}
					</div>
				</div>
			</template>
		</aq-select>
	</div>
</template>

<script>

import debounce from 'lodash.debounce';
import ClaimService from '@commonServices/claimService';

export default {
	name: 'AqAilments',
	props: {
		value: {
			type: Object,
			required: false,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			ailments: [],
			isAilmentLoading: false,
		};
	},
	methods: {
		searchAilmentsAsync: debounce(function (term) {
			if (term.length >= 3) {
				this.isAilmentLoading = true;
				ClaimService.getAilments(term).then(res => {
					this.ailments = res;
					this.isAilmentLoading = false;
				});
			}
		}, 300),
		input (value) {
			this.$emit('input', value);
		},
	},
};
</script>
