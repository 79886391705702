<template>
	<div class="position-relative">
		<div :class="['card-button-wrapper', 'h-100', contentClass, { 'has-button': !!btnLabel } ] ">
			<slot name="content" />
		</div>
		<button
			v-if="btnLabel"
			class="btn btn-primary"
			@click="$emit('btn-click')"
		>
			{{ btnLabel }}
		</button>
	</div>
</template>

<script>

export default {
	name: 'BorderedButtonWrapper',
	props: {
		btnLabel: {
			type: [String, Boolean],
			required: false,
			default: null,
		},
		contentClass: {
			type: [String, Array],
			required: false,
			default: 'breadcrumb-header--bordered',
		},
	},
};
</script>

<style lang="scss" scoped>
  .card-button-wrapper {
    box-shadow: 0 0 10px rgba($body-bg, 0.4);
  }

  button.btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
