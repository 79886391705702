<template>
	<div class="header d-flex align-items-center">
		<div class="logo-wrapper">
			<router-link to="/dashboard">
				<img
					alt="Header Logo"
					:src="logo"
				>
			</router-link>
		</div>
		<aq-search-input
			v-if="$can.Search"
			class="input-wrapper mx-auto"
			:value="searchValue"
			@search="onSearch"
		/>
		<div class="menu">
			<aq-panel-items v-if="showPanelItems" />
			<aq-user-info-basic
				v-if="user"
				:user-info="user"
				class="user-info-block"
			/>
		</div>
	</div>
</template>

<script>
import AqUserInfoBasic from '@commonWidgets/AqUserInfoBasic';
import logo from '@assets/icons/logo.png';

export default {
	name: 'AqHeader',
	components: {
		AqUserInfoBasic,
	},
	props: {
		user: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			logo,
			searchValue: '',
		};
	},

	methods: {
		onSearch (value) {
			this.$router.push({ name: 'dashboard', query: { search: value } });
		},
	},
	computed: {
		showPanelItems () {
			return ['customer', 'pet', 'claim-page', 'dashboard', 'tasks'].includes(this.$route.name);
		},
	},
	watch: {
		$route (to, from) {
			if (from.name === 'dashboard' && to.name !== 'dashboard') {
				this.searchValue = '';
			}
			if (to.name === 'dashboard' && to.query.search) {
				this.searchValue = to.query.search;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.header {
  height: $headerHeight;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 2px rgb(0 0 0 / 40%);
}

.input-wrapper {
  position: relative;
}

.logo-wrapper {
  width: 80px;
  height: $headerHeight;
  text-align: center;
  padding: 14px 0;
}

.menu {
  position: absolute;
  right: 0;
  top: 0;

  > div {
    display: inline-block;
    vertical-align: top;
  }
}

.user-info-block {
  cursor: pointer;
  transition: 0.2s ease;
  width: $headerItemWidth;
}
</style>
