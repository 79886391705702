class PersistentStorage {
	constructor () {
		this._properties = {};
		this._namespace = '';
		this._isSupported = true;
	}

	get namespace () {
		return this._namespace;
	}

	set namespace (value) {
		this._namespace = value ? `${value}.` : '';
	}

	_getLsKey (lsKey) {
		return `${this._namespace}${lsKey}`;
	}

	_lsSet (lsKey, rawValue, type) {
		const key = this._getLsKey(lsKey);
		const value = type && [Array, Object].includes(type)
			? JSON.stringify(rawValue)
			: rawValue;

		window.localStorage.setItem(key, value);
	}

	_lsGet (lsKey) {
		const key = this._getLsKey(lsKey);

		return window.localStorage[key];
	}

	get (lsKey, defaultValue = null, defaultType = String) {
		if (!this._isSupported) {
			return null;
		}

		if (this._lsGet(lsKey)) {
			let type = defaultType;

			for (const key in this._properties) {
				if (key === lsKey) {
					type = this._properties[key].type;
					break;
				}
			}

			return this._process(type, this._lsGet(lsKey));
		}

		return defaultValue !== null ? defaultValue : null;
	}

	set (lsKey, value) {
		if (!this._isSupported) {
			return null;
		}

		for (const key in this._properties) {
			const type = this._properties[key].type;

			if ((key === lsKey)) {
				this._lsSet(lsKey, value, type);

				return value;
			}
		}
		if (typeof value === 'object') {
			this._lsSet(lsKey, value, Object);
		} else {
			this._lsSet(lsKey, value);
		}

		return value;
	}

	remove (lsKey) {
		if (!this._isSupported) {
			return null;
		}

		return window.localStorage.removeItem(lsKey);
	}

	addProperty (key, type, defaultValue = undefined) {
		type = type || String;

		this._properties[key] = { type };

		if (!this._lsGet(key) && defaultValue !== null) {
			this._lsSet(key, defaultValue, type);
		}
	}

	_process (type, value) {
		switch (type) {
		case Boolean:
			return value === 'true';
		case Number:
			return parseFloat(value);
		case Array:
			try {
				const array = JSON.parse(value);

				return Array.isArray(array) ? array : [];
			} catch (e) {
				return [];
			}
		case Object:
			try {
				return JSON.parse(value);
			} catch (e) {
				return {};
			}
		default:
			return value;
		}
	}
}

const defaultStorage = new PersistentStorage();
defaultStorage.namespace = 'vision';

export default defaultStorage;
