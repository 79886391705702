import WorkQueueModel from '@commonServices/models/WorkQueueModel';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';

export default class DraftClaimModel extends WorkQueueModel {
	constructor (
		brandId,
		brandName,
		brandLocale,
		claimId,
		claimStatus,
		customerFirstName,
		customerLastName,
		customerId,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		vets,
		claimAmount,
		ailments,
		requestedBy,
		dateRequested,
		policyStatus,
		policies,
		policyStatusDisplay,
		products,
		claimType,
		daysOpen,
		createdBy,
		dateCreated,
		assignedTo,
		reassessmentDateTime,
		formType,
		latestDashboardNote,
		latestInteraction,
		missingInfoCompleted,
	) {
		super(
			brandId,
			brandName,
			brandLocale,
			claimId,
			claimStatus,
			customerFirstName,
			customerLastName,
			customerId,
			petId,
			petName,
			petType,
			petGender,
			petDateOfDeath,
			vets,
			claimAmount,
			ailments,
			requestedBy,
			dateRequested,
			policyStatus,
			policies,
			policyStatusDisplay,
			assignedTo,
			WorkQueueType.DraftClaims,
			latestDashboardNote,
			latestInteraction,
			missingInfoCompleted,
		);

		this.products = products.join(', ') || '';
		this.claimType = claimType;
		this.daysOpen = daysOpen;
		this.createdBy = createdBy;
		this.dateCreated = dateCreated;
		this.formType = formType;
		this.reassessmentDateTime = reassessmentDateTime;
	}
}
