<template>
	<div :class="{ 'auto-assigned-card': task.prioritizedWorkQueue, 'ml-10': $options.claimSourceLabelMap[task.claimSource]}">
		<div
			v-if="task.prioritizedWorkQueue"
			class="auto-assigned-card__icon"
		>
			<AutoAssignStarCard />
		</div>
		<div class="section-content pt-10 pb-15 px-0">
			<div class="container-fluid">
				<item-heading
					:heading="taskHeading"
					heading-class="mb-1 user-name text-truncate"
					paragraph-class="text-truncate"
				>
					<template
						#besideHeadingArea
					>
						<i
							v-if="task.isFastTrackReviewed"
							class="aqv-fast-track highlight-icon fs-25 mt-2 ml-10"
							v-tooltip="'Fast track reviewed'"
						/>
						<i
							v-if="task.missingInfoCompleted"
							class="aqv-missing-info-complete highlight-icon mt-2 ml-10 fs-25"
							v-tooltip="'Missing Info Complete'"
						/>
					</template>
					<template #underHeadingArea>
						<div
							v-if="task.workQueueName"
							class="text-truncate text-bold"
							v-tooltip="task.workQueueName"
						>
							Queue: {{ task.workQueueName }}
						</div>
					</template>
					<template #rightArea>
						<div class="logo">
							<PaymentsLogo v-if="task.workQueueType === workQueueType.PaymentApproval" />
							<FraudCheckLogo v-else-if="task.workQueueType === workQueueType.FraudCheck" />
							<RejectionLogo v-if="task.workQueueType === workQueueType.RejectionApproval" />
							<ChasingLogo v-if="task.workQueueType === workQueueType.Chasing" />
							<ReassessClaimsLogo v-if="task.workQueueType === workQueueType.ReassessmentPending" />
							<DraftClaimsLogo v-if="task.workQueueType === workQueueType.DraftClaims" />
						</div>
					</template>
				</item-heading>
				<div class="d-flex">
					<div class="attributes text-truncate claim-properties col1 mr-3">
						<div class="task-attribute d-flex mb-3">
							<div class="text-left text-truncate">
								<div class="attribute-title">
									Ailment
								</div>
								<div
									class="text-truncate"
									v-tooltip.top-start="task.ailments"
								>
									{{ task.ailments || 'No ailment' }}
								</div>
							</div>
						</div>
						<div class="task-attribute d-flex">
							<div class="text-left text-truncate">
								<div class="attribute-title">
									{{ task.taskTypeTitle }}
								</div>
								<template v-if="task.workQueueType === workQueueType.PaymentApproval">
									<div
										class="link text-primary"
										@click="$emit('select-payees', task)"
									>
										<strong>{{ task.payees.length }}</strong>
										{{ 'Payee' | pluralize(task.payees.length) }}
									</div>
								</template>
								<div
									v-else-if="task.workQueueType === workQueueType.FraudCheck"
									class="link text-danger-20"
									@click="$emit('select-flags', task.fraudCheckReasons)"
								>
									<strong>{{ task.fraudCheckReasons.length }}</strong>
									{{ 'Flag ' | pluralize(task.fraudCheckReasons.length) }}
								</div>
								<template v-else-if="task.workQueueType === workQueueType.RejectionApproval">
									<truncated-text-cell
										v-if="task.rejectionReason || task.rejectionStatus"
										:value="task.rejectionReason || task.rejectionStatus"
										class="text-danger-20"
									/>
									<div v-else>
										-
									</div>
								</template>
								<template v-else-if="task.workQueueType === workQueueType.Chasing">
									<div
										v-if="task.missingInformation.length > 0"
										class="link text-primary"
										@click="$emit('select-missing', task.missingInformation)"
									>
										<strong>{{ task.missingInformation.length }}</strong>
										{{ 'Item' | pluralize(task.missingInformation.length ) }}
									</div>
								</template>
								<template v-else-if="task.workQueueType === workQueueType.DraftClaims && isPreAuth(task.formType)">
									<div>{{ getPreAuthLabel() }}</div>
								</template>
								<template v-else-if="task.workQueueType === workQueueType.ReassessmentPending || task.workQueueType === workQueueType.DraftClaims">
									<div>-</div>
								</template>
							</div>
						</div>
					</div>
					<div class="attributes text-truncate claim-properties col2 mr-3">
						<div class="task-attribute d-flex mb-3">
							<div class="text-left text-truncate">
								<div class="attribute-title">
									Amount
								</div>
								{{ task.claimAmount | currency(task.brandLocale) }}
							</div>
						</div>
						<div class="task-attribute d-flex">
							<div class="text-left text-truncate">
								<div class="attribute-title text-truncate">
									Policy Status
								</div>
								{{ task.policyStatusDisplay }}
							</div>
						</div>
					</div>
					<div class="attributes text-truncate claim-properties col3 mr-0">
						<div class="task-attribute d-flex mb-3 mr-0">
							<div class="text-left text-truncate">
								<div class="attribute-title">
									Claim Date
								</div>
								{{ task.createdDate | shortDate }}
							</div>
						</div>
						<div class="task-attribute d-flex mr-0">
							<div class="text-left text-truncate">
								<div class="attribute-title text-truncate">
									Days Open
								</div>
								{{ task.daysOpen }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section-footer">
			<div class="container-fluid d-flex align-items-center">
				<div
					v-if="$options.claimSourceLabelMap[task.claimSource]"
					class="source-container"
				>
					<aq-bookmark
						class="claim-source"
						large-icon
						:label="$options.claimSourceLabelMap[task.claimSource]"
						:tooltip="`Source: ${$options.claimSourceDisplayMap[task.claimSource]}`"
					/>
				</div>
				<aq-brand-image-avatar
					class="mr-4 cursor-inherit"
					:brand-name="task.brandName"
					:brand-id="task.brandId"
				/>
				<aq-icon-avatar
					class="small mr-4 cursor-pointer"
					:value="[task.customerFirstName, task.customerLastName]"
					@click.native="viewCustomer"
				/>
				<aq-pet-avatar
					class="small mr-4 cursor-pointer"
					:pet="{
						name: task.petName,
						type: task.petType,
						gender: task.petGender,
						dateOfDeath: task.petDateOfDeath
					}"
					@click.native="viewPet"
				/>
				<aq-dropdown
					:options="getDropdownOptions(task)"
					label="View"
					btn-class="btn btn-primary"
					class="ml-auto"
					@select="$emit('row-action', $event, task)"
					@main-action="onMainAction"
					:split-toggle="true"
					:height="37"
				/>
			</div>
		</div>
	</div>
</template>

<script>

import { WorkQueueType, WorkQueueDisplayType, getDropdownOptionsForPot, dropdownActions } from '@commonServices/models/WorkQueueType';
import TruncatedTextCell from '../views/Shared/Table/Cells/TruncatedTextCell';
import { ClaimFormTypeDisplayMap, ClaimFormType } from '@commonServices/models/ClaimFormType';
import { claimSourceLabelMap, claimSourceDisplayMap } from '@clientCommon/services/models/ClaimSource';
import {
	AutoAssignStarCard,
	ChasingLogo,
	DraftClaimsLogo,
	FraudCheckLogo,
	PaymentsLogo,
	ReassessClaimsLogo,
	RejectionLogo,
} from '@assets/icons';

export default {
	components: {
		TruncatedTextCell,
		ChasingLogo,
		DraftClaimsLogo,
		FraudCheckLogo,
		PaymentsLogo,
		ReassessClaimsLogo,
		RejectionLogo,
		AutoAssignStarCard,
	},
	name: 'AqTaskCard',
	props: {
		task: {
			type: Object,
			required: true,
		},
	},
	claimSourceLabelMap,
	claimSourceDisplayMap,
	data () {
		return {
			workQueueType: WorkQueueType,
			workQueueDisplayType: WorkQueueDisplayType,
		};
	},
	computed: {
		taskHeading: function () {
			return this.workQueueDisplayType[this.task.workQueueType];
		},
		itemLabel: function () {
			return this.workQueueType[this.task.workQueueType];
		},
	},
	methods: {
		viewCustomer () {
			this.$router.push({ name: 'customer', params: { customerId: this.task.customerId } });
		},
		viewPet () {
			this.$router.push({ name: 'pet', params: { customerId: this.task.customerId, petId: this.task.petId } });
		},
		getDropdownOptions (data) {
			return getDropdownOptionsForPot(data.workQueueType, this.$can, data);
		},
		onMainAction () {
			this.$emit('row-action', dropdownActions.view.value, this.task);
		},
		isPreAuth (formType) {
			return formType === ClaimFormTypeDisplayMap[ClaimFormType.PreAuthorisation];
		},
		getPreAuthLabel () {
			return ClaimFormTypeDisplayMap[ClaimFormType.PreAuthorisation];
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .user-name {
  font-size: 26px;
  letter-spacing: -0.06em;
  font-weight: bold;
}

.claim-properties {
  display: flex;
  flex-direction: column;
}

.logo {
  color: var(--cardIconSVG);
  margin-left: auto;
}

.col1 {
  flex: 200px;
}

.col2 {
  flex: 140px;
}

.col3 {
  flex: 180px;
}

.source-container {
  position: relative;
  width: 65px;

  .claim-source {
    left: -38px;
    bottom: -6px;
  }
}

.auto-assigned-card {
  border: 5px solid $warning !important;
  position: relative;

  &__icon {
    width: 83.108px;
    height: 76.306px;
    position: absolute;
    top: -15.3px;
    left: -19px;
    z-index: 2;
  }

  .source-container {
    width: 60px;

    .claim-source {
      left: -43px;
    }
  }

}

.highlight-icon {
  &:hover {
    color: var(--cardIconColour);
  }
}

</style>
