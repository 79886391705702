export default class ClaimItemView {
	constructor (
		claimItemId,
		claimConditionId,
		policySectionId,
		policySectionName,
		treatmentPeriod,
		amount,
		amountExpression,
		userDeductions,
		systemDeductions,
		totalAmount,
		fileId,
		isValid,
		nonFinancialNumber,
		rebalanceExcess,
		taxAmount,
		deductions) {
		this.claimItemId = claimItemId;
		this.claimConditionId = claimConditionId;
		this.policySectionId = policySectionId;
		this.policySectionName = policySectionName;
		this.treatmentPeriod = treatmentPeriod;
		this.amount = amount;
		this.amountExpression = amountExpression;
		this.userDeductions = userDeductions;
		this.systemDeductions = systemDeductions;
		this.totalAmount = totalAmount;
		this.fileId = fileId;
		this.isValid = isValid;
		this.nonFinancialNumber = nonFinancialNumber;
		this.rebalanceExcess = rebalanceExcess;
		this.taxAmount = taxAmount;
		this.deductions = deductions;
	}
}
