import WorkQueueModel from '@commonServices/models/WorkQueueModel';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';

export default class ClaimRejectionToApproveModel extends WorkQueueModel {
	constructor (
		brandId,
		brandName,
		brandLocale,
		claimId,
		claimStatus,
		customerFirstName,
		customerLastName,
		customerId,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		vets,
		claimAmount,
		suggestedClaimAmount,
		ailments,
		payees,
		daysOpen,
		rejectionReason,
		comments,
		requestedBy,
		dateRequested,
		policyStatus,
		policies,
		policyStatusDisplay,
		assignedTo,
		formType,
		isReassessment,
		rejectionStatus,
		latestDashboardNote,
		latestInteraction,
	) {
		super(
			brandId,
			brandName,
			brandLocale,
			claimId,
			claimStatus,
			customerFirstName,
			customerLastName,
			customerId,
			petId,
			petName,
			petType,
			petGender,
			petDateOfDeath,
			vets,
			claimAmount,
			ailments,
			requestedBy,
			dateRequested,
			policyStatus,
			policies,
			policyStatusDisplay,
			assignedTo,
			WorkQueueType.RejectionApproval,
			latestDashboardNote,
			latestInteraction,
		);

		this.suggestedClaimAmount = suggestedClaimAmount;
		this.payees = payees;
		this.daysOpen = daysOpen;
		this.rejectionReason = rejectionReason;
		this.comments = comments;
		this.formType = formType;
		this.isReassessment = isReassessment;
		this.rejectionStatus = rejectionStatus;
	}
}
