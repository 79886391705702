<template>
	<aq-panel-wrapper>
		<div class="panel-container panel-container--left">
			<aq-confirmation-modal
				ref="deleteReportModal"
				name="delete-report-modal"
				title="Confirm Delete Report"
				:description="`You are about to delete '${reportForDeletion.name}' report. Do you wish to continue?`"
				yes-label="Delete"
				no-label="Cancel"
				data-qa="reports-panel_modal_delete-report"
			/>
			<div class="panel panel--left d-flex flex-column">
				<div class="section-header">
					<button
						class="btn btn-row px-20 mr-20"
						data-qa="reportPanel_button_back"
						v-if="reportList"
						@click="reportList = null"
					>
						<i class="fas fa-chevron-left" />
					</button>
					<div class="section-header__title">
						Reporting
					</div>
					<button
						class="btn-row btn ml-auto"
						data-qa="reportPanel_button_close"
						@click="onClose"
					>
						Close
					</button>
				</div>

				<div
					class="section-content d-flex flex-column report-groups"
					v-if="!reportList"
				>
					<div class="pl-15 pr-15 pb-10">
						<aq-form-input
							data-qa="reportPanel_input_search"
							label="Search"
							v-model="reportSearchTerm"
							@input="searchReportsAsync"
							show-clear-btn
						/>
					</div>
					<div
						class="text-center pb-10"
						v-if="searchResult && searchResult.length === 0"
					>
						<div>
							Sorry, no results here
						</div>
						<p>Try changing your search or select a group below to find what you're looking for</p>
					</div>
					<div
						class="container-fluid scrollbar"
						v-if="!searchResult || searchResult.length === 0"
					>
						<aq-card
							v-for="group of reportGroups"
							:key="group.group"
							:name="group.name"
							:description="getGroupDescription(group)"
							type="folder"
							:action="groupAction"
							@select-action="onSelectGroup(group)"
							data-qa="reportPanel_card_reportGroup"
							class="mb-10"
						/>
					</div>
					<div
						class="flex-grow-1 d-flex flex-column pl-15"
						v-if="searchResult"
					>
						<div class="content-wrapper">
							<aq-card
								v-for="(report, key) of searchResult"
								:key="key"
								:name="report.name"
								:description="getSearchDescription(report)"
								:type="report.type === 1 ? 'report' : 'chart'"
								:action="menuOptionAction(report)"
								@select-action="onSelectAction(report, $event)"
								data-qa="reportPanel_card_reportSearchResult"
								class="mb-10"
							>
								<template slot="content">
									<div
										class="bottom mt-2 py-10 px-20 d-flex justify-content-between"
										v-if="report.createdBy"
									>
										<div class="text-subtitle fs-13 d-flex align-items-center">
											Last Updated {{ report.updatedDateTime | shortDateTime }}
										</div>
										<aq-icon-avatar
											class="small"
											:value="[report.createdBy.firstName, report.createdBy.lastName]"
										/>
									</div>
									<div
										class="bottom mt-2 py-10 px-20"
										v-else
									>
										<div class="text-subtitle fs-13">
											System Generated
										</div>
									</div>
								</template>
							</aq-card>
						</div>
					</div>
				</div>
				<aq-tab-selector
					:items="tabItems"
					:active-tab="activeTab"
					@select-tab="onSelectTab"
					v-if="reportList && !searchResult"
					data-qa="reportPanel_select_reportType"
					class="flex-grow-1 d-flex flex-column"
				>
					<div class="section-content flex-grow-1 d-flex flex-column">
						<div class="content-wrapper">
							<aq-card
								v-for="report of filteredReports"
								:key="report.id"
								:name="report.name"
								:description="report.typeDescription"
								:type="report.type === 1 ? 'report' : 'chart'"
								:action="menuOptionAction(report)"
								@select-action="onSelectAction(report, $event)"
								@click-name="onSelectAction(report, 'view' )"
								data-qa="reportPanel_card_report"
								class="mb-10 clickable-name"
							>
								<template slot="content">
									<div
										class="bottom mt-2 py-10 px-20 d-flex justify-content-between"
										v-if="report.createdBy"
									>
										<div class="text-subtitle fs-13 d-flex align-items-center">
											<span v-tooltip="lifetimeInfoTooltip(report)">
												{{ lifetimeInfo(report) }}
											</span>
										</div>
										<aq-icon-avatar
											class="small"
											:value="[report.createdBy.firstName, report.createdBy.lastName]"
										/>
									</div>
									<div
										class="bottom mt-2 py-10 px-20"
										v-else
									>
										<div class="text-subtitle fs-13">
											System Generated
										</div>
									</div>
								</template>
							</aq-card>
						</div>
					</div>
				</aq-tab-selector>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
import ReportService from '@commonServices/reportService';
import { pluralize, shortDateTime } from '@commonServices/utils/filters';
import eventBus from '@commonServices/eventBus';
import debounce from 'lodash.debounce';

const ReportType = {
	System: 'system',
	User: 'user',
};

const userFilterActions = {
	view: { name: 'View', value: 'view', iconClass: 'fa-eye text-primary' },
	delete: { name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' },
};

export default {
	data () {
		return {
			tabItems: [],
			activeTab: undefined,
			reportGroups: [],
			reportList: null,
			groupAction: null,
			selectedGroup: null,
			searchResult: null,
			reportSearchTerm: undefined,
			reportForDeletion: {},
		};
	},
	computed: {
		filteredReports () {
			let options = [];
			if (this.activeTab.value === ReportType.System) {
				options = this.reportList.systemOptions;
			} else if (this.activeTab.value === ReportType.User) {
				options = this.reportList.userOptions;
			}

			return options;
		},
	},
	async mounted () {
		this.tabItems = [
			{
				value: ReportType.System,
				label: 'System',
			},
			{
				value: ReportType.User,
				label: 'User',
			},
		];
		this.groupAction = { label: 'Open', value: 'open' };

		this.reportGroups = await ReportService.getGroups();
		this.activeTab = this.tabItems[0];
	},
	methods: {
		onClose () {
			this.$emit('close');
		},
		onSelectTab (value) {
			this.activeTab = this.tabItems.find(item => item.value === value);
		},
		menuOptionAction (report) {
			const actions = [userFilterActions.view];
			if (report.createdBy && this.$can.DeleteReport) {
				actions.push(userFilterActions.delete);
			}

			return actions;
		},
		async onSelectAction (report, action) {
			switch (action) {
			case userFilterActions.view.value: {
				const routerPushData = {
					name: 'reports', params: { group: this.selectedGroup || report.group },
				};
				if (report.createdBy) {
					routerPushData.params.reportId = report.id;
				}
				this.$router.push(routerPushData);
				this.onClose();
				break;
			}
			case userFilterActions.delete.value: {
				this.reportForDeletion = report;
				const userChoise = await this.$refs.deleteReportModal.show();
				if (userChoise === 'yes') {
					await ReportService.deleteUserReport(report.id);
					if (this.reportList) {
						this.reportList.userOptions = this.reportList.userOptions.filter(uo => uo.id !== report.id);
					} else {
						this.searchResult = this.searchResult.filter(sr => sr.id !== report.id && report.createdBy);
					}

					eventBus.$emit('user-report-deleted', { userReportId: report.id });
				}

				break;
			}

			default: break;
			}
		},
		getGroupDescription (group) {
			const { systemReportsCount, userReportsCount } = group;
			const description = [systemReportsCount && `${systemReportsCount} System`, userReportsCount && `${userReportsCount} User`]
				.filter(Boolean)
				.join(' and ');

			return `${description} ${pluralize('report', systemReportsCount + userReportsCount)}`;
		},
		getSearchDescription (report) {
			return `${report.groupDescription} / ${report.typeDescription}`;
		},
		async onSelectGroup (group) {
			this.selectedGroup = group.group;
			this.searchResult = null;
			this.reportSearchTerm = null;
			this.reportList = await ReportService.getMenuOptions(group.group);
		},
		searchReportsAsync: debounce(async function () {
			if (!this.reportSearchTerm || this.reportSearchTerm.length === 0) {
				this.searchResult = null;
				return;
			}
			if (this.reportSearchTerm.length >= 3) {
				this.searchResult = await ReportService.searchReports(this.reportSearchTerm);
			}
		}, 500),
		lifetimeInfo (report) {
			return report.updatedBy ? `Last Updated ${shortDateTime(report.updatedDateTime)}` : `Created ${shortDateTime(report.createdDateTime)}`;
		},
		lifetimeInfoTooltip (report) {
			return report.updatedBy ? `Created ${shortDateTime(report.createdDateTime)}` : '';
		},
	},
};
</script>

<style lang="scss" scoped>
  .panel-container {
    max-width: 500px;
    animation: slide 0.5s forwards;

    .report-groups {
      height: calc(100% - 65px);
      padding-bottom: 20px;
    }

    .scrollbar {
      overflow-y: auto;
    }

    .content-wrapper {
      flex: 1 1 auto;
      overflow-y: auto;
      height: 0;
      padding-right: 10px;
    }

    ::v-deep .card {
      &.clickable-name {
        .name-info span {
          cursor: pointer;
        }
      }
    }
  }

</style>
