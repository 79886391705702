<template>
	<div
		class="icons-wrapper"
		@click="onClick"
	>
		<div
			:class="iconClass"
			class="position-relative"
		>
			<div
				:class="badgeClass"
				v-if="count > 0"
				v-tooltip="limitCountTooltip(count)"
			>
				{{ count | limitCount }}
			</div>
		</div>
	</div>
</template>

<script>
import { limitCount, limitCountTooltip } from '@commonServices/utils/filters';

export default {
	props: {
		iconClass: {
			type: String,
			required: false,
			default: 'fas fa-bell fa-2x',
		},
		badgeClass: {
			type: String,
			required: false,
			default: 'badge',
		},
		count: {
			type: Number,
			required: true,
		},
	},
	methods: {
		limitCount,
		limitCountTooltip,
		onClick () {
			this.$emit('click');
		},
	},
};
</script>

<style lang="scss" scoped>
  .icons-wrapper {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
      transition: all 0.2s;
    }

    .badge {
      background-color: $primary;
      border-radius: 9.5px;
      display: inline-block;
      line-height: 19px;
      width: fit-content;
      min-width: 19px;
      padding-left: 2px;
      padding-right: 2px;
      height: 19px;
      font-size: 12px;
      position: absolute;
      top: -30%;
      left: 83%;
      z-index: 100;
      font-family: "Open Sans", Arial, sans-serif;
    }
  }

  .light {
    .icons-wrapper > div {
      color: $body-color;
    }
  }
</style>
