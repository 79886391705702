<template>
	<div>
		<div class="sidebar ">
			<nav class="nav d-flex flex-column">
				<ul class="flex-grow-1">
					<li
						class="active mx-auto"
						v-for="menuItem of navBarItems"
						:key="menuItem.label"
						v-shortkey="shortkey(menuItem)"
						@shortkey="onNavigateMenuItem(menuItem)"
					>
						<router-link
							:to="menuItem.routerLink"
							v-slot="{ href, navigate, isActive }"
							custom
						>
							<span>
								<a
									:href="href"
									@click="onNavItemClick(menuItem, navigate, $event)"
									:class="{ 'router-link-active': isActive }"
								>
									<div
										v-if="showCountOfAutoAssignedTasks(menuItem)"
										class="icon mb-6 auto-assigned-task"
									>
										<i
											class="fas fs-32 fa-bell"
											@animationend="$emit('home-animation-end')"
										/>
										<label>{{ autoAssignedTasks.length }}</label>
									</div>
									<div
										v-else
										class="icon mb-6"
									>
										<i
											:class="['fas fa-2x', menuItem.iconClass]"
											@animationend="$emit('home-animation-end')"
										/>
									</div>
									<span>{{ menuItem.label }}</span>
								</a>
							</span>
						</router-link>
					</li>
				</ul>
				<div class="bottom-action">
					<div
						class="text-center my-35 shortkey"
						:class="{ 'enabled': isShortkeyEnabled }"
						@click="$emit('toggle-shortkey')"
					>
						<i class="fa-sharp fa-solid fa-keyboard fa-2x" />
					</div>
					<aq-notifier
						:count="notificationsCount"
						@click="onOpenNotificationsPanel"
						class="text-center my-35"
					/>
					<aq-toggler
						@input="$emit('toggle-theme')"
						:value="isToggleTheme"
						:slider-class="'theme-toggle vertical'"
						class="theme-selector mb-20 text-center"
					/>
				</div>
			</nav>
		</div>
		<notifications-panel
			v-if="isNotificationsOpen"
			:grouped-notifications="notificationItems"
			@close="onNotificationsClose"
		/>
		<reports-panel
			v-if="isCurrentPanel('Reports')"
			@close="onClosePanel"
		/>
		<letters-panel
			v-if="isCurrentPanel('Letters')"
			@close="onClosePanel"
		/>
		<tasks-panel
			v-if="isCurrentPanel('Tasks')"
			@close="onClosePanel"
		/>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import NotificationsService from '@commonServices/notificationsService';
import TasksPanel from '@commonLayout/TasksPanel';
import LettersPanel from '@commonLayout/LettersPanel';
import ReportsPanel from '@commonLayout/ReportsPanel';
import NotificationsPanel from '@commonLayout/NotificationsPanel';
import { MenuItemsLabel } from '@commonServices/commonService';

export default {
	name: 'AqNavbar',
	components: {
		TasksPanel,
		LettersPanel,
		ReportsPanel,
		NotificationsPanel,
	},
	data () {
		return {
			currentPanel: null,
			isNotificationsOpen: false,
			notificationItems: [],
		};
	},
	props: {
		isToggleTheme: {
			type: Boolean,
			required: true,
		},
		navBarItems: {
			type: Array,
			required: true,
		},
		isShortkeyEnabled: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapState(['notificationsCount']),
		...mapGetters(['autoAssignedTasks']),
	},
	methods: {
		...mapActions(['setNotificationsCountAsync']),
		onNavItemClick (navItem, navigate, event) {
			if (navItem.isPanelItem) {
				event.preventDefault();
				this.currentPanel = navItem;
			} else {
				navigate(event);
			}
			if (navItem.label === MenuItemsLabel.Home) {
				this.$emit('home-page-navigate');
			}
		},
		onNavigateMenuItem (menuItem) {
			if (menuItem.isPanelItem) {
				if (this.currentPanel === menuItem) {
					this.currentPanel = null;
				} else {
					this.currentPanel = menuItem;
				}
			} else {
				this.$router.push({ path: menuItem.routerLink });
			}
		},
		isCurrentPanel (panel) {
			return this.currentPanel && this.currentPanel.label === panel;
		},
		async onOpenNotificationsPanel () {
			this.isNotificationsOpen = true;
			this.notificationItems = await NotificationsService.getNotifications();
		},
		async onNotificationsClose () {
			this.isNotificationsOpen = false;
			const markAsViewedIds = this.getMarkViewedNotifications();
			if (markAsViewedIds.length) {
				await NotificationsService.updateNotificationsStatus(markAsViewedIds);
				await this.setNotificationsCountAsync();
			}
		},
		onClosePanel () {
			this.currentPanel = null;
		},
		getMarkViewedNotifications () {
			return this.notificationItems
				.filter((group) => group.notificationItems.length)
				.reduce((acc, group) => {
					return [...group.notificationItems
						.filter((item) => !item.isViewed)
						.map((item) => item.id), ...acc];
				}, []);
		},
		showCountOfAutoAssignedTasks ({ label }) {
			return label === MenuItemsLabel.Home && this.autoAssignedTasks.length > 0;
		},
		shortkey (navBarItem) {
			switch (navBarItem.label) {
			case (MenuItemsLabel.Home):
				return this.$shortkeys.home;
			case (MenuItemsLabel.Letters):
				return this.$shortkeys.letters;
			case (MenuItemsLabel.Reports):
				return this.$shortkeys.reports;
			case (MenuItemsLabel.Tasks):
				return this.$shortkeys.tasks;
			default: return [];
			}
		},
	},
	watch: {
		async $route () {
			await this.setNotificationsCountAsync();
		},
	},
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: $headerHeight;
  width: 80px;
  bottom: 0;
}

.nav {
  position: relative;
  height: calc(100% - 30px);
}

.nav ul {
  list-style: none;

  li {
    position: relative;
    margin: 5px 0;

    &:first-child {
      margin-top: 0;
    }

    a {
      text-decoration: none;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 13px;
      padding: 11px 0;
      opacity: 0.5;
      transition: 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }

    .router-link-active {
      opacity: 1;
      border-left: 2px solid $primary;

      &::after {
        content: "\25C2";
        right: -3px;
        position: absolute;
        top: 33%;
        font-size: 17px;
      }
    }

  }

  .pulse-menu-item {
    width: 26px;
    height: 25px;
    border-radius: 50%;
    animation: shadow-pulse 1s 3;
  }
}

.icon-count-wrapper {
  color: $active-color;
  position: absolute;
  top: 12px;
  right: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .icon-count {
    font-size: 13px;
    color: $body-color;
    position: absolute;
    padding-top: 2px;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
  }
}

.auto-assigned-task {
  color: #FE7606;
  transform: scale(1.0);

  &:hover {
    transform: scale(1.1);
  }

  label {
    width: 100%;
    color: #fff;
    position: absolute;
    font-weight: 800;
    font-size: 15px;
    top: 5px;
    left: 0;
  }
}

.shortkey {
  color: $primary;
  cursor: pointer;

  &.enabled {
    color: #fff;
  }
}

</style>
