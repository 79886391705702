<template>
	<div>
		<div
			class="panel-item"
			:class="{ 'active': panel === $options.PANELS.CareFlags}"
			@click="switchPanel($options.PANELS.CareFlags)"
			v-tooltip="'Care Flags'"
			v-if="showCareFlags"
		>
			<i
				class="fas"
				:class="[panel === $options.PANELS.CareFlags ? 'fa-times' : 'aqv-flag']"
			/>
		</div>
		<div
			class="panel-item"
			:class="{ 'active': panel === $options.PANELS.Vet }"
			@click.stop="switchPanel($options.PANELS.Vet)"
			@shortkey="switchPanel($options.PANELS.Vet)"
			v-tooltip="'Vet Details'"
			v-shortkey="$shortkeys.vets"
			v-if="showVets"
		>
			<div
				class="counter text-white"
				v-if="vetAlertsCount > 0 && panel !== $options.PANELS.Vet"
				v-tooltip.right="limitCountTooltip(vetAlertsCount)"
			>
				{{ vetAlertsCount | limitCount }}
			</div>
			<i :class="{ 'aqv-vet': panel !== $options.PANELS.Vet, 'fas fa-times': panel === $options.PANELS.Vet }" />
		</div>
		<div
			class="panel-item"
			:class="{ 'active': panel === $options.PANELS.Conditions }"
			@click="switchPanel($options.PANELS.Conditions)"
			v-shortkey="{
				preEx: $shortkeys.conditions,
				eligible: $shortkeys.eligibleConditions,
			}"
			@shortkey="switchPanel($options.PANELS.Conditions, $event)"
			v-tooltip="'Conditions Management'"
			v-if="showConditions"
		>
			<div
				class="counter text-white"
				v-if="conditionCount > 0 && panel !== $options.PANELS.Conditions"
				v-tooltip.right="limitCountTooltip(conditionCount)"
			>
				{{ conditionCount | limitCount }}
			</div>
			<i
				class="fas"
				:class="{ 'fa-briefcase-medical': panel !== $options.PANELS.Conditions, 'fa-times': panel === $options.PANELS.Conditions }"
			/>
		</div>
		<div
			class="panel-item"
			:class="{ 'active': isOpenDocPanel}"
			@click="switchPanel($options.PANELS.Documents)"
			v-shortkey="$shortkeys.documents"
			@shortkey="switchPanel($options.PANELS.Documents)"
			v-tooltip="'Upload Files'"
			v-if="showDocuments && $can.ViewFiles"
		>
			<i
				class="fas"
				:class="{ 'fa-cloud-upload-alt': !isOpenDocPanel, 'fa-times':isOpenDocPanel }"
			/>
		</div>
		<div
			class="panel-item"
			:class="{ 'active': panel === $options.PANELS.Interactions }"
			v-shortkey="{
				interactions: $shortkeys.interactions,
				notes: $shortkeys.notes,
			}"
			@shortkey="switchPanel($options.PANELS.Interactions, $event)"
			@click="switchPanel($options.PANELS.Interactions)"
			v-tooltip="'History'"
			v-if="showInteractions"
		>
			<div
				class="counter text-white"
				v-if="totalNotesCount && panel !== $options.PANELS.Interactions"
				v-tooltip.right="limitCountTooltip(totalNotesCount)"
			>
				{{ totalNotesCount | limitCount }}
			</div>
			<i
				class="fas"
				:class="{ 'fa-history': panel !== $options.PANELS.Interactions, 'fa-times': panel === $options.PANELS.Interactions, 'pulse-interaction': animateNewInteraction, 'require-attention': hasNewInteractions }"
				@animationend="animateNewInteraction = false"
			/>
		</div>

		<care-flags-panel v-if="panel === $options.PANELS.CareFlags" />
		<document-panel
			v-if="isOpenDocPanel"
			@hide-panel="closeDocPanel"
			:default-scan-file-id="fileMetadataId"
		/>
		<history-panel
			v-if="panel === $options.PANELS.Interactions"
			@hide-panel="hideCurrentPanel"
			@on-alert-notes-changed="onAlertNotesChanged"
			:initial-tab="panelSubTab"
		/>
		<conditions-panel
			v-if="panel === $options.PANELS.Conditions"
			:initial-tab="panelSubTab"
		/>
		<vet-panel
			v-if="panel === $options.PANELS.Vet"
			:vet-alerts="vetAlerts"
			:vet-infos="vetInfos"
			:initial-tab="panelSubTab"
			@alerts-changed="onAlertsChanged"
		/>

		<AqNotesAlert
			:suppress-notification="isPanelOpen"
			@notes-changed="notesStats=$event"
			@alert-click="onNotesAlertClick"
		/>
	</div>
</template>

<script>

import { mapGetters, mapMutations, mapState } from 'vuex';
import PetService from '@commonServices/petService';
import eventBus from '@commonServices/eventBus';
import VetService from '@commonServices/vetService';
import VetPanel from '@commonView/Shared/VetPanel';
import CareFlagsPanel from '@commonView/Shared/CareFlagsPanel';
import ConditionsPanel from '@commonView/Shared/ConditionsPanel';
import HistoryPanel from '@commonView/Shared/HistoryPanel';
import DocumentPanel from '@commonView/Shared/DocumentPanel';
import AqNotesAlert from './AqNotesAlert';
import PANELS from '@commonServices/models/PanelEnum';
import { limitCount, limitCountTooltip } from '@commonServices/utils/filters';
import { ConditionManagementEnum, ConditionManagementEnumMap } from '@commonServices/models/ConditionManagementEnum';
import { HistoryPanelEnum, HistoryPanelEnumMap } from '@commonServices/models/HistoryPanelEnum';
import { unique } from '@commonServices/utils/general';

export default {
	name: 'AqPanelItems',
	components: {
		VetPanel,
		AqNotesAlert,
		CareFlagsPanel,
		ConditionsPanel,
		HistoryPanel,
		DocumentPanel,
	},
	data () {
		return {
			panel: null,
			panelSubTab: undefined, // to make default value work inside panel as null is treated as value
			conditionCount: 0,
			vetAlerts: [],
			vetInfos: [],
			fileMetadataId: 0,
			animateNewInteraction: false,
			notesStats: {
				customerCount: 0,
				petCount: 0,
				claimCount: 0,
				vetCount: 0,
			},
			hasNewInteractions: false,
		};
	},
	PANELS,
	watch: {
		panel (newPanel, oldPanel) {
			if (oldPanel === PANELS.Conditions) {
				this.updateConditionCounter();
				this.notifyConditionsPanelState(false);
			}
			if (newPanel === PANELS.Conditions) {
				this.notifyConditionsPanelState(true);
			}
		},
		$route () {
			this.hideAllPanels();
		},
		petId (value) {
			if (value) {
				this.updateConditionCounter();
			}
		},
		interactionContext: {
			handler () {
				this.getVetData();
			},
			deep: true,
		},
	},
	mounted () {
		eventBus.$on('document-panel_open-scan-file', this.openDocumentPanelForFile);
		eventBus.$on('interaction-added', this.onInteractionAdded);
		eventBus.$on('hide-panels', this.hideAllPanels);
	},
	beforeDestroy () {
		eventBus.$off('document-panel_open-scan-file', this.openDocumentPanelForFile);
		eventBus.$off('interaction-added', this.onInteractionAdded);
		eventBus.$off('hide-panels', this.hideAllPanels);
	},
	computed: {
		...mapState(['isOpenDocPanel', 'interactionContext', 'appSettings', 'levelFilter']),
		...mapGetters(['isCustomerContext', 'isOpenClaimContinuation']),
		showDocuments () {
			return ['claim-page', 'customer', 'pet'].includes(this.$route.name);
		},
		petId () {
			return this.interactionContext.pet?.id;
		},
		customerId () {
			return this.interactionContext.customer?.id;
		},
		claimId () {
			return this.interactionContext.claim?.id;
		},
		showConditions () {
			return !['dashboard', 'tasks'].includes(this.$route.name) && this.petId && (this.$can.ViewPreExistingCondition || this.$can.ViewEligibleCondition);
		},
		showVets () {
			return !this.isCustomerContext && (this.interactionContext.claim?.registeredVets?.length || this.interactionContext.pet?.registeredVets?.length);
		},
		showCareFlags () {
			return this.appSettings.careFlagManagement && (this.petId || this.customerId) && !this.claimId && this.$can.EditCareFlags;
		},
		showInteractions () {
			return (this.customerId || (this.claimId && this.petId && this.customerId)) && this.$can.ViewInteractions;
		},
		vetAlertsCount () {
			return this.notesStats.vetCount;
		},
		totalNotesCount () {
			return this.notesStats.petCount + this.notesStats.claimCount + this.notesStats.customerCount;
		},
		isPanelOpen () {
			return this.panel !== null;
		},
	},
	methods: {
		...mapMutations(['toggleDocPanel', 'setLevelFilter']),
		limitCount,
		limitCountTooltip,
		onInteractionAdded (interaction) {
			this.hasNewInteractions = true;
			this.animateNewInteraction = false;
			const customerId = interaction.pet?.customerId || interaction.customer.id;
			if (customerId === this.interactionContext.customer?.id) {
				this.animateNewInteraction = this.panel !== PANELS.Interactions;
			}
		},
		updateConditionCounter () {
			if (!this.$can.ViewPreExistingCondition) {
				return;
			};

			PetService.getConditionCount(this.petId).then(data => (this.conditionCount = data));
		},
		switchPanel (panel, event) {
			if (panel === PANELS.Documents) {
				if (this.isOpenDocPanel) this.closeDocPanel();
				else {
					this.hideCurrentPanel();
					this.openDocPanel();
				}
				return;
			}

			if (this.panel === panel) {
				this.panel = null;
				this.panelSubTab = undefined;
				if (panel === PANELS.Interactions) {
					this.hasNewInteractions = false;
				}
			} else {
				this.panel = panel;
				this.switchPanelSubTab(event);
				if (panel === PANELS.Interactions) {
					this.animateNewInteraction = false;
					this.hasNewInteractions = false;
				}
			}
		},
		hideCurrentPanel () { // hides current opened panel except doc panel (has own state)
			this.panel = null;
			this.panelSubTab = undefined;
		},
		hideAllPanels () {
			this.hideCurrentPanel();
			this.closeDocPanel();
		},
		openDocumentPanelForFile ({ fileId }) {
			this.fileMetadataId = fileId;
			this.openDocPanel();
		},
		openDocPanel () {
			this.toggleDocPanel(true);
		},
		notifyConditionsPanelState (opened) {
			eventBus.$emit('condition-panel-updated', opened);
		},
		async getVetData () {
			this.vetAlerts = [];
			if (!this.appSettings.vetExternalServices || this.levelFilter.customerId) {
				this.vetAlerts = [];
				this.vetInfos = [];
				return;
			}
			const claimVetIds = this.interactionContext.claim?.registeredVets?.filter(registeredVet => registeredVet.id)
				.map(registeredVet => registeredVet.id) ?? [];
			const petVetIds = this.interactionContext.pet?.registeredVets?.map(registeredVet => registeredVet.id) ?? [];
			const vetIds = unique([...claimVetIds, ...petVetIds]);
			if (vetIds.length === 0) {
				return;
			}

			const [vetAlerts, vetInfos] = await Promise.all([VetService.getVetAlerts(vetIds), VetService.getVetInfo(vetIds)]);
			this.vetAlerts = vetAlerts;
			this.vetInfos = vetInfos;
			const activeVetIds = this.interactionContext.pet?.registeredVets?.filter(v => !v.isHistorical).map(v => v.id);
			const countableVetAlerts = this.vetAlerts.filter(alert => activeVetIds.includes(alert.externalVetId) || claimVetIds.includes(alert.externalVetId));
			this.notesStats = { ...this.notesStats, vetCount: countableVetAlerts.length };
		},
		closeDocPanel () {
			this.fileMetadataId = null;
			this.toggleDocPanel(false);
		},
		onNotesAlertClick ({ panel, levelFilter }) {
			if (levelFilter) {
				this.setLevelFilter(levelFilter);
			}

			if (panel === PANELS.Interactions) {
				this.panelSubTab = HistoryPanelEnumMap[HistoryPanelEnum.Notes];
			} else if (panel === PANELS.Vet) {
				this.panelSubTab = 'alerts';
			}
			this.panel = panel;
		},
		onAlertNotesChanged (notesCount) {
			if (this.levelFilter.claimId) {
				this.notesStats = { ...this.notesStats, claimCount: notesCount };
			}
			if (this.levelFilter.petId) {
				this.notesStats = { ...this.notesStats, petCount: notesCount };
			}
			if (this.levelFilter.customerId) {
				this.notesStats = { ...this.notesStats, customerCount: notesCount };
			}
		},
		onAlertsChanged () {
			this.getVetData();
		},
		switchPanelSubTab (event) {
			switch (event?.srcKey) {
			case 'preEx':
				this.panelSubTab = ConditionManagementEnumMap[ConditionManagementEnum.PreExisting];
				break;
			case 'eligible':
				this.panelSubTab = ConditionManagementEnumMap[ConditionManagementEnum.Eligible];
				break;
			case 'notes':
				this.panelSubTab = HistoryPanelEnumMap[HistoryPanelEnum.Notes];
				break;
			case 'interactions':
				this.panelSubTab = HistoryPanelEnumMap[HistoryPanelEnum.Interactions];
				break;
			default:
				break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.panel-item {
  display: inline-block;
  width: $headerItemWidth;
  text-align: center;
  line-height: 73px;
  border-right: 1px solid $body-bg;
  font-size: 19px;
  cursor: pointer;
  transition: 0.2s ease;
  color: $body-color;
  position: relative;
  vertical-align: top;

  .aq-fa-icon {
    vertical-align: middle;
    transition: width 0.2s ease;
    fill: var(--aqFaIcon);
  }

  &:hover {
    color: $primary;
    font-size: 24px;

    .aq-fa-icon {
      width: 24px;
      fill: $primary;
    }

    &.active {
      color: $active-color;
    }

    .counter {
      top: 12px;
      left: 43px;
      border-radius: 8.5px;
      width: fit-content;
      min-width: 17px;
      height: 17px;
      line-height: 17px;
      font-size: 11px;
    }
  }

  &.active {
    color: $active-color;
  }

  .counter {
    background: $active-color;
    position: absolute;
    border-radius: 9.5px;
    height: 19px;
    width: fit-content;
    min-width: 19px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    line-height: 19px;
    font-size: 12px;
    font-weight: bold;
    top: 18px;
    left: 38px;
    transition: 0.2s ease;
    box-shadow: 0 0 2px 0 $secondary;
  }

  .pulse-interaction {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    animation: shadow-pulse 1s 2;
  }
}
</style>
