import { getDayStart, fromStringToDate, isDateAfter, addMonths, diffInDays } from '@commonServices/utils/dateUtils';

export function progressColor (policyTermEndDate) {
	const today = getDayStart();
	const policyEnd = fromStringToDate(policyTermEndDate);
	if (isDateAfter(addMonths(today, 3), policyEnd)) return 'danger';
	if (isDateAfter(addMonths(today, 6), policyEnd)) return 'warning';
	return 'primary';
}

export function policyprogressPercentage (policyTermStartDate, policyTermEndDate) {
	const policyStart = fromStringToDate(policyTermStartDate);
	const policyEnd = fromStringToDate(policyTermEndDate);

	const percentage = (diffInDays(getDayStart(), policyStart) * 100)	/ diffInDays(policyEnd, policyStart);

	return percentage > 100 ? 100 : percentage;
};
