<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="fluid-container">
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Amount:
							</div>
							<div class="col text-right">
								{{ details.item2 | currency(locale) }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Comment:
							</div>
							<div class="col text-right">
								{{ details.item1 }}
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			details: Object,
		};
	},
	mounted () {
		this.details = this.data[0];
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-content {
  min-width: 600px;
}

.row-icon {
  margin-right: 5px;
  font-size: 17px;
}
</style>
