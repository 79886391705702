<template>
	<div
		class="icon-avatar card-icon"
		v-tooltip="tooltip"
	>
		<slot>
			{{ displayValue }}
		</slot>
	</div>
</template>

<script>
import { initials } from '@commonServices/utils/general';

export default {
	name: 'AqIconAvatar',
	props: {
		value: {
			type: [Array, String],
			required: false,
			default: '',
		},
	},
	computed: {
		displayValue () {
			return initials(this.value);
		},
		tooltip () {
			return Array.isArray(this.value) ? this.value.filter(Boolean).join(' ') : this.value;
		},
	},
};
</script>

<style lang="scss" scoped>
.aqv-customer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-avatar {
  cursor: default;

  &:hover {
    background: var(--cardIconBg);
    color: var(--cardIconColourHover);
  }

  &.card-icon {
    &.cursor-inherit {
      cursor: inherit;
    }

    &.cursor-pointer {
      cursor: pointer;
    }
  }
}
</style>
