<template>
	<div class="attribute task-attribute text-truncate d-flex align-items-center">
		<div class="text-left text-truncate">
			<div class="attribute-title text-truncate">
				{{ header }}
			</div>
			{{ text }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'ItemAttribute',
	props: {
		header: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.attribute {
  height: 3.8rem;
}
</style>
