<template>
	<div>
		<aq-modal
			v-if="paymentMethods && paymentMethods.length > 0"
			name="new-ex-gratia-payee"
		>
			<new-payee-modal
				:recipient="exGratiaPayment.recipient"
				:payment-types="paymentMethods"
				:locale="locale"
				modal-name="new-ex-gratia-payee"
				@added-payee="onAddPayee"
				data-qa="exGratiaPaymentModal_modal_addPayee"
			/>
		</aq-modal>
		<aq-modal-container
			@close="$emit('cancel-ex-gratia')"
			:title="title"
			modal-width="700"
		>
			<div class="modal-content w-100 p-20">
				<div class="container p-0">
					<div class="row no-gutters">
						<div class="col-6 p-10">
							<aq-select
								label="Recipient"
								option-label="recipientName"
								:options="recipients"
								:max-height="150"
								@input="$v.exGratiaPayment.recipient.$touch"
								v-model="exGratiaPayment.recipient"
								no-result-message="No Recipients found"
								data-qa="exGratiaModal_select_recipient"
							>
								<template #default="props">
									<div>{{ props.option.recipientName }} - {{ toRecipientLabel(props.option.recipientType) }}</div>
								</template>
							</aq-select>
							<aq-form-input-error
								class="mt-8"
								:error-messages="$options.errorMessages.exGratiaPayment"
								:validator="$v.exGratiaPayment"
								property="recipient"
							/>
						</div>
						<div class="col-6 p-10">
							<aq-form-input
								label="Amount"
								type="money"
								v-model="exGratiaPayment.paymentAmount"
								:disabled="!exGratiaPayment.recipient"
								:locale="locale"
								@input="onInputAmount"
								:is-valid="!$v.exGratiaPayment.paymentAmount.$error"
								class="amount-input"
								data-qa="exGratiaModal_input_payee"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="$options.errorMessages.exGratiaPayment"
								:validator="$v.exGratiaPayment"
								property="paymentAmount"
							/>
						</div>
					</div>
					<div class="row no-gutters">
						<div class="col p-10">
							<aq-select
								label="Payment Details"
								:options="paymentAccounts"
								v-model="exGratiaPayment.selectedPayment"
								@input="$v.exGratiaPayment.selectedPayment.$touch"
								track-by="id"
								:disabled="!exGratiaPayment.recipient"
								no-result-message="No Payment Details found"
								data-qa="exGratiaPaymentModal_select_paymentDetails"
							>
								<template #default="props">
									<div class="select-option-header text-truncate d-flex align-items-center">
										<div class="payment-icon">
											<component :is="iconSettings[props.option.paymentMethod]" />
										</div>
										{{ props.option.payee }}
									</div>
									<div
										class="select-option-info text-truncate"
										v-if="isBacsPaymentMethod(props.option.paymentMethod)"
									>
										<span v-if="props.option.bankName">
											{{ props.option.bankName }},&nbsp;
										</span>
										<span>{{ props.option.sortCode }},&nbsp;</span>
										<span>{{ props.option.accountNumber }}</span>
									</div>
									<div />
								</template>
								<template
									slot="singleLabel"
									slot-scope="props"
								>
									<div>
										<span>{{ props.option.payee }}</span>
										<span v-if="isBacsPaymentMethod(props.option.paymentMethod)">
											<span v-if="props.option.bankName">
												,&nbsp;{{ props.option.bankName }},
											</span>
											{{ props.option.sortCode }},
											{{ props.option.accountNumber }}
										</span>
									</div>
								</template>
								<template slot="before-list">
									<div
										class="p-20 create-new"
										@click="$modal.show('new-ex-gratia-payee')"
										v-if="$can.ManagePaymentAccount"
									>
										<button
											type="button"
											class="btn small btn-primary mr-10"
											data-qa="exGratiaPaymentModal_button_openPayee"
										>
											<i class="fas fa-plus text-white" />
										</button>
										<span>Create New Account</span>
									</div>
								</template>
							</aq-select>
							<aq-form-input-error
								class="mt-8"
								:error-messages="$options.errorMessages.exGratiaPayment"
								:validator="$v.exGratiaPayment"
								property="selectedPayment"
							/>
						</div>
					</div>
					<div class="row no-gutters">
						<div class="col m-10">
							<aq-form-input
								label="Comments"
								type="textarea"
								v-model="exGratiaPayment.comment"
								@input="$v.exGratiaPayment.comment.$touch"
								:is-valid="!$v.exGratiaPayment.comment.$error"
								data-qa="exGratiaModal_input_comment"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="$options.errorMessages.exGratiaPayment"
								:validator="$v.exGratiaPayment"
								property="comment"
							/>
						</div>
					</div>
				</div>
				<div
					class="modal-bottom d-flex align-items-center px-20 py-10"
				>
					<div class="ml-auto">
						<button
							@click="onCancel"
							type="button"
							class="btn btn-secondary mr-10"
							data-qa="exGratiaPaymentModal_button_cancel"
						>
							Cancel
						</button>
						<button
							@click="onFinish"
							type="button"
							class="btn btn-primary"
							data-qa="exGratiaPaymentModal_button_finish"
						>
							Finish
						</button>
					</div>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { maxAmount, moreThenOrEqual, notEqual } from '@commonServices/utils/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { mapState } from 'vuex';
import { PaymentMethodIconSettings } from '@commonServices/settings/themeSettings';
import { PaymentMethod } from '@commonServices/models/PaymentMethod';
import RecipientType from '@commonServices/models/RecipientType';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { toRecipientLabel } from '@commonServices/utils/converter';

export default {
	name: 'ExGratiaPaymentModal',
	props: {
		recipients: {
			type: Array,
			required: true,
		},
		paymentMethods: {
			type: Array,
			required: true,
		},
		exGratiaPaymentForEdit: {
			type: Object,
			required: false,
			default: null,
		},
		allExGratiaPayments: {
			type: Array,
			required: true,
		},
		allRegularPayments: {
			type: Array,
			required: true,
		},
		isPositiveNet: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	errorMessages: {
		exGratiaPayment: {
			recipient: {
				required: ValidationMessages.required,
				isValid: ValidationMessages.exGratiaAlreadyExists,
			},
			selectedPayment: {
				required: ValidationMessages.required,
			},
			paymentAmount: {
				required: ValidationMessages.required,
				maxAmount: ValidationMessages.maxAmount,
				isValid: ValidationMessages.negativeExGratia,
				minValue: ValidationMessages.noNegative,
				notEqual: ValidationMessages.notEqual(0),
			},
			comment: {
				required: ValidationMessages.required,
				maxLength: ValidationMessages.maxLength2000,
			},
		},
	},
	RecipientType,
	data () {
		return {
			exGratiaPayment: {
				recipient: null,
				selectedPayment: null,
				comment: null,
				paymentAmount: 0,
			},
			paymentAmountValid: true,
		};
	},
	mounted () {
		if (this.exGratiaPaymentForEdit) {
			this.exGratiaPayment = this.exGratiaPaymentForEdit;
		}
	},
	watch: {
		'exGratiaPayment.recipient': {
			handler (newVal, oldVal) {
				if (oldVal) {
					this.exGratiaPayment.selectedPayment = null;
				}
			},
		},
	},
	computed: {
		...mapState(['appSettings']),
		isEdit () {
			return this.exGratiaPaymentForEdit !== null;
		},
		title () {
			if (this.isEdit) {
				return 'Edit Ex-Gratia payment';
			}
			return 'Add a new Ex-Gratia payment';
		},
		paymentAccounts () {
			return this.exGratiaPayment.recipient ? this.exGratiaPayment.recipient.accounts.filter(a => a.isActive) : [];
		},
		isExGratiaAlreadyExists () {
			if (!this.exGratiaPayment.recipient) {
				return false;
			}
			const uniqId = `${this.exGratiaPayment.recipient.recipientType}${this.exGratiaPayment.recipient.recipientId}`;
			const ex = this.allExGratiaPayments.find(exPayment => exPayment.uniqId === uniqId);
			if (!this.isEdit) {
				return ex !== undefined;
			} else {
				return ex !== undefined && ex.uniqId !== this.exGratiaPaymentForEdit.uniqId;
			}
		},
		isPaymentInvalid () {
			return this.$v.exGratiaPayment.recipient.$error
			|| this.$v.exGratiaPayment.selectedPayment.$error
			|| this.$v.exGratiaPayment.paymentAmount.$error
			|| this.$v.exGratiaPayment.comment.$error;
		},
		iconSettings () {
			return PaymentMethodIconSettings;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	validations () {
		const validation = {
			exGratiaPayment: {
				recipient: {
					required,
					isValid: () => !this.isExGratiaAlreadyExists,
				},
				selectedPayment: {
					required,
				},
				comment: {
					required,
					maxLength: maxLength(2000),
				},
				paymentAmount: {
					required,
					maxAmount: maxAmount(16),
					notEqual: notEqual(0),
					isValid: () => this.paymentAmountValid,
				},
			},
		};
		if (!this.isPositiveNet || !this.appSettings.allowNegativeExGratiaPayment) {
			validation.exGratiaPayment.paymentAmount.minValue = moreThenOrEqual(0);
		}
		return validation;
	},
	methods: {
		onInputAmount () {
			this.$v.exGratiaPayment.paymentAmount.$touch();
			const uniqId = `${this.exGratiaPayment.recipient.recipientType}${this.exGratiaPayment.recipient.recipientId}`;
			const matchPayment = this.allRegularPayments.find(rp => rp.uniqId === uniqId);
			if (matchPayment && matchPayment.paymentAmount >= 0 && this.exGratiaPayment.paymentAmount < 0) {
				this.paymentAmountValid = matchPayment.paymentAmount + this.exGratiaPayment.paymentAmount >= 0;
			} else {
				this.paymentAmountValid = true;
			}
		},
		onAddPayee (payment) {
			const account = {
				accountNumber: payment.accountNumber,
				bankName: payment.bankName,
				id: payment.id,
				payee: payment.payee,
				paymentMethod: payment.paymentMethod,
				sortCode: payment.sortCode,
				isActive: payment.isActive,
			};

			this.exGratiaPayment.recipient.accounts.push(account);

			this.exGratiaPayment.selectedPayment = account;

			this.$modal.hide('new-ex-gratia-payee');
		},
		onFinish () {
			this.$v.exGratiaPayment.$touch();
			if (this.isPaymentInvalid) return;

			const eventName = this.isEdit ? 'edit-ex-gratia' : 'add-ex-gratia';
			this.$emit(eventName, this.exGratiaPayment);
			this.$modal.hide('ex-gratia');
		},
		onCancel () {
			this.$emit('cancel-ex-gratia');
		},
		isBacsPaymentMethod (paymentMethod) {
			return paymentMethod === PaymentMethod.Bacs;
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
	},
};
</script>
