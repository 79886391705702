<template>
	<aq-sandwich
		ref="sandwich"
		header="Vet Details"
		sub-header="Validate extracted data to populate vet details"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template slot="content">
			<aq-modal name="scan-new-vet">
				<new-vet-modal
					:vet="searchVet"
					@added-vet="onAddVet"
				/>
			</aq-modal>
			<div class="section-content flex-fill">
				<div class="container-fluid">
					<aq-scan-form-select
						label="Vet Practice"
						:value="searchVet"
						class="'attribute px-20"
						:loading="isVetLoading"
						@search-async="searchVetsDebounce"
						@focused="$emit('focused', { boundingBox: veterinaryPracticeName.boundingBox, page: veterinaryPracticeName.page } )"
						:disabled="isReadonly"
					/>

					<div>
						<div class="font-weight-bold px-25 py-14">
							Found {{ vets.length }} matching Vets
						</div>
						<div class="row no-gutters">
							<div
								v-if="searchVet && !isReadonly && appSettings.allowUIVetCreation && $can.CreateNewVet"
								class="px-25 py-14 search-item create-new mb-10"
								@click="$modal.show('scan-new-vet')"
							>
								<button
									type="button"
									class="btn small btn-primary mr-10"
								>
									<i class="fas fa-plus text-white" />
								</button>
								<span>
									Create <span class="text-primary">
										"{{ searchVet }}"
									</span> as a new vet
								</span>
							</div>

							<div
								v-for="(vet, index) in vets"
								:key="index"
								@click="selectVet(vet)"
								class="search-item mb-10 px-25 py-14"
								:class="selectedVet && selectedVet.id === vet.id ? 'selected' : ''"
							>
								<div class="cause pb-4">
									{{ vet.practiceName }}
								</div>
								<p>
									<span v-if="vet.address1">
										{{ vet.address1 }},&nbsp;
									</span>
									<span v-if="vet.address2">
										{{ vet.address2 }},&nbsp;
									</span>
									<span v-if="vet.address3">
										{{ vet.address3 }},&nbsp;
									</span>
									<span v-if="vet.townCity">
										{{ vet.townCity }},&nbsp;
									</span>
									<span v-if="vet.postalCode">
										{{ vet.postalCode }}
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<button
						class="btn btn-primary btn-radius--less mr-0 ml-auto d-block"
						@click="onProceed()"
					>
						Proceed
					</button>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import debounce from 'lodash.debounce';
import ClaimService from '@commonServices/claimService';
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
	name: 'ClaimVetScan',
	data () {
		return {
			vets: [],
			selectedVet: null,
			isVetLoading: false,
			searchVet: null,
			isLoaded: false,
		};
	},
	props: {
		veterinaryPracticeName: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	async mounted () {
		if (this.veterinaryPracticeName) {
			this.searchVet = this.veterinaryPracticeName.value ? this.veterinaryPracticeName.value : this.veterinaryPracticeName.originalValue;
		}
		await this.searchVetsAsync(this.searchVet);
		if (this.veterinaryPracticeName.id) {
			this.selectedVet = this.vets.find(vet => vet.id === this.veterinaryPracticeName.id);
		} else {
			this.selectedVet = this.vets.find(vet => vet.practiceName === this.searchVet);
		}
		this.isLoaded = true;
	},
	computed: {
		...mapState(['appSettings']),
	},
	methods: {
		searchVetsDebounce: debounce(async function (term) {
			await this.searchVetsAsync(term);
		}, 500),
		async searchVetsAsync (term) {
			this.searchVet = term;
			if (term && term.length >= 3) {
				this.isVetLoading = true;
				this.vets = await ClaimService.getVets(term).then(vets =>
					vets.filter(vet => vet.approved || vet.active));
				this.isVetLoading = false;
			}
		},
		selectVet (vet) {
			if (this.isReadonly) {
				return;
			}
			if (this.selectedVet === vet) {
				this.selectedVet = null;
			} else {
				this.selectedVet = vet;
			}
		},
		onProceed () {
			this.$refs.sandwich.onToggle();
			this.$emit('passed', cloneDeep(this.selectedVet));
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		onAddVet (newVet) {
			this.selectedVet = newVet;
			this.vets.push(newVet);
		},
	},
	watch: {
		vets (newValue) {
			if (this.selectedVet && this.isLoaded) {
				if (!newValue || !newValue.some(x => x.id === this.selectedVet.id)) {
					this.selectedVet = null;
				}
			}
		},
		selectedVet () {
			this.$emit('changed');
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-danger-darken {
  color: $body-color;
}

.search-item {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease;

  &:last-child {
    border: none;
  }

  &:hover,
  &.selected {
    p {
      color: $body-color;
    }
  }

  &:hover {
    background: $primary;
  }

  &.selected {
    background: $active-color;
  }

  &.create-new {
    &:hover {
      color: $body-color;
      background: $primary-d-15;
    }
  }
}
</style>
