const letterTypeMap = Object.freeze({
	RejectedWaitPeriod: 'RejectedWaitPeriod',
	MissingInfo: 'MissingInfo',
	MissingInfoVet: 'MissingInfoVet',
	Chase: 'Chase',
	ChaseFinal: 'ChaseFinal',
	ChaseVet: 'ChaseVet',
	ChaseVetFinal: 'ChaseVetFinal',
	RejectedNoLimit: 'RejectedNoLimit',
	Settlement: 'Settlement',
	Reassessment: 'Reassessment',
	RejectedManually: 'RejectedManually',
	RejectedByPreExistingCondition: 'RejectedByPreExistingCondition',
	RejectedNoActiveCoverPeriod: 'RejectedNoActiveCoverPeriod',
	MedicalRecordsSummary: 'MedicalRecordsSummary',
	GreyMedicalRecordsSummary: 'GreyMedicalRecordsSummary',
	PreAuthorisationCompleted: 'PreAuthorisationCompleted',
	RejectedMultiple: 'RejectedMultiple',
	RejectedByNoProductCoverage: 'RejectedByNoProductCoverage',
	OpenClaimReminder: 'OpenClaimReminder',
	DuplicateClaimRejection: 'DuplicateClaimRejection',
	DuplicateInvoiceRejection: 'DuplicateInvoiceRejection',
});

const letterTypeDisplayMap = Object.freeze({
	[letterTypeMap.RejectedWaitPeriod]: 'Claim Rejected Wait Period',
	[letterTypeMap.MissingInfo]: 'Missing Information',
	[letterTypeMap.MissingInfoVet]: 'Missing Information Vet',
	[letterTypeMap.Chase]: 'Missing Info Chase',
	[letterTypeMap.ChaseFinal]: 'Final Missing Info Chase',
	[letterTypeMap.ChaseVet]: 'Missing Info Chase Vet',
	[letterTypeMap.ChaseVetFinal]: 'Final Missing Info Chase Vet',
	[letterTypeMap.RejectedNoLimit]: 'Claim Rejected No Limit',
	[letterTypeMap.Settlement]: 'Claim Settlement',
	[letterTypeMap.Reassessment]: 'Claim Reassessment',
	[letterTypeMap.RejectedManually]: 'Claim Rejected Manually',
	[letterTypeMap.RejectedByPreExistingCondition]: 'Claim Rejected By Pre Existing Condition',
	[letterTypeMap.RejectedNoActiveCoverPeriod]: 'Claim Rejected No Active Cover Period',
	[letterTypeMap.MedicalRecordsSummary]: 'Medical Records Summary',
	[letterTypeMap.GreyMedicalRecordsSummary]: 'Grey Medical Records Summary',
	[letterTypeMap.PreAuthorisationCompleted]: 'PreAuthorisation Completed',
	[letterTypeMap.RejectedMultiple]: 'Claim Rejected By Multiple Reasons',
	[letterTypeMap.RejectedByNoProductCoverage]: 'Claim Rejected By No Product Coverage',
	[letterTypeMap.OpenClaimReminder]: 'Open Claim Reminder',
	[letterTypeMap.DuplicateClaimRejection]: 'Duplicate Claim Rejection',
	[letterTypeMap.DuplicateInvoiceRejection]: 'Duplicate Invoice Rejection',
});

const letterTypes = Object.entries(letterTypeDisplayMap)
	.map(([key, value]) => {
		return {
			value: key,
			description: value,
		};
	});

export { letterTypeMap, letterTypeDisplayMap, letterTypes };
