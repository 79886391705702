<template>
	<aq-list-items-popover
		label="Policy wordings"
		:items="policyWordings"
		item-label="fileName"
		track-by="hash"
		:hide-on-select="true"
		@input="onPolicyWordingSelected"
		:disabled="popupDisabled"
	>
		<div
			class="d-flex cursor-pointer align-items-center"
			@click="viewPolicyWording"
		>
			<i
				class="aqv-policy1 fas fs-18"
				:class="{'cursor-pointer': popupDisabled}"
			/>
			<div class="header-card-item-text text-underline">
				{{ policyReference }} - ({{ policyStatus }})
			</div>
		</div>
	</aq-list-items-popover>
</template>

<script>
import fileService from '@clientCommon/services/fileService';
import { toastActions } from '@commonServices/settings/toastSettings';
import { productFileType } from '@commonServices/models/ProductFileTypeEnum';
import AqListItemsPopover from '@commonWidgets/AqListItemsPopover';

const toastOptions = {
	action: toastActions.close,
	duration: 3000,
};

export default {
	components: { AqListItemsPopover },
	props: {
		product: {
			type: Object,
			required: true,
		},
		policyReference: {
			type: String,
			required: true,
		},
		policyStatus: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			policyWordings: null,
		};
	},
	computed: {
		popupDisabled () {
			return !this.policyWordings || this.policyWordings.length <= 1;
		},
	},
	watch: {
		product () {
			this.policyWordings = null;
		},
	},
	methods: {
		async viewPolicyWording () {
			if (!this.policyWordings) {
				this.policyWordings = (await fileService.getProductFiles(this.product.id)).filter(this.isSupportedFile);
			}

			if (this.popupDisabled) {
				this.tryOpenPolicyWordingFile(this.policyWordings[0]);
			}
		},
		isSupportedFile (file) {
			return file.type === productFileType.PolicyWording;
		},
		onPolicyWordingSelected (policyWording) {
			this.tryOpenPolicyWordingFile(policyWording);
		},
		async tryOpenPolicyWordingFile (file) {
			const wordingUrl = await fileService.getPolicyWordingUrl(this.product.id, file);
			if (wordingUrl) {
				window.open(wordingUrl);
			} else {
				this.$toasted.show('No available policy wording document', toastOptions);
			}
		},
	},
};
</script>
