import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import eventBus from '@commonServices/eventBus';
import SecurityService from '@commonServices/securityService';

export default {
	async install (Vue) {
		// Provide methods to connect/disconnect from the SignalR hub
		let connection = null;
		let startedPromise = null;
		let manuallyClosed = false;

		const connectionOptions = {
			accessTokenFactory: async () => await SecurityService.getAccessToken(),
			...ClaimsConfig.SELF_HOSTED_SIGNALR && {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets,
			},
		};
		connection = new HubConnectionBuilder()
			.withUrl(`${ClaimsConfig.CLAIMS_ROOT_API}/hubs`, connectionOptions)
			.configureLogging(LogLevel.Information)
			.build();
		// Forward hub events through the event, so we can listen for them in the Vue components
		connection.on('InteractionAdded', (interaction) => {
			eventBus.$emit('interaction-added', interaction);
		});

		connection.on('TaskAssigned', (payload) => {
			eventBus.$emit('task-assigned', payload);
		});

		connection.on('TaskUnAssigned', (userId) => {
			eventBus.$emit('task-unassigned', userId);
		});

		connection.on('TaskAcknowledged', (payload) => {
			eventBus.$emit('task-acknowledged', payload);
		});

		connection.on('UserOptInStatusChanged', (payload) => {
			eventBus.$emit('userOptIn-changed', payload);
		});

		connection.on('NotifyClaimChanged', (payload) => {
			eventBus.$emit('notify-claim-changed', payload);
		});

		// You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
		// Docs recommend listening onclose and handling it there.
		// This is the simplest of the strategies
		function start () {
			startedPromise = connection.start()
				.catch(err => {
					console.error('Failed to connect with hub', err);
					return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
				});
			return startedPromise;
		}
		connection.onclose(() => {
			if (!manuallyClosed) start();
		});

		// Start everything
		manuallyClosed = false;
		start();
	},
};
