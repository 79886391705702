<template>
	<div />
</template>

<script>

// use web worker for intervals to make it work in inactive browser tabs
const intervalWorker = new Worker(new URL('@workers/intervalWorker.js', import.meta.url));

export default {
	name: 'AqRepeat',
	props: {
		action: {
			type: Function,
			required: true,
		},
		interval:	{
			type: Number,
			required: true,
		},
	},
	data: function () {
		return {
		};
	},
	created () {
		this.action();
		const self = this;
		intervalWorker.onmessage = function (e) {
			const { id, command } = e.data;
			if (id === self._uid && command === 'timer-tick') {
				self.action();
			}
		};
		intervalWorker.postMessage({
			id: this._uid,
			command: 'timer-start',
			interval: this.interval,
		});
	},
	methods: {
		repeat () {
			this.action();
			setTimeout(this.repeat, this.interval * 1000);
		},
	},
	beforeDestroy () {
		intervalWorker.postMessage({
			id: this._uid,
			command: 'timer-stop',
		});
	},
};
</script>
