<template>
	<div
		v-tooltip="{
			content: isTextTruncated ? `${tooltipText}` : '',
			offset: offset
		}"
		class="text-truncate"
	>
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AqTooltipOnTruncate',
	props: {
		tooltipText: {
			type: String,
			required: false,
			default: '',
		},
		offset: {
			type: String,
			required: false,
			default: '0',
		},
	},
	data: () => ({
		isTextTruncated: false,
	}),
	methods: {
		checkScrollWidth () {
			if (this.$slots.default[0].elm.scrollWidth > this.$slots.default[0].elm.offsetWidth) {
				this.isTextTruncated = true;
			} else {
				this.isTextTruncated = false;
			}
		},
	},
	mounted () {
		this.checkScrollWidth();
	},
	updated () {
		this.checkScrollWidth();
	},
};
</script>
