import ReportModel from './ReportModel';

export default class BdxReportModel extends ReportModel {
	constructor (
		brandName,
		brandLocale,
		customerId,
		policyId,
		customerExtRef,
		customerExtId,
		policyReference,
		claimId,
		customerFirstName,
		customerLastName,
		customerAddress,
		customerPostalCode,
		policyStatus,
		policyStatusDisplay,
		productName,
		vetAddresses,
		vetBusinessNames,
		vetPostalCodes,
		petExtRef,
		petExtId,
		petType,
		petGender,
		petBreed,
		petDateOfBirth,
		petAgeInception,
		petAgeTreatmentStart,
		policyInceptionDate,
		datePolicyStart,
		ailments,
		bodyParts,
		conditionTypes,
		dateOnSet,
		dateTreatmentStart,
		dateTreatmentEnd,
		sections,
		datePaid,
		claimCreatedBy,
		claimCreatedDate,
		claimAssessedBy,
		claimApprovedBy,
		amountPaid,
		amountExGratia,
		amountSubmitted,
		userDeductions,
		amountUserDeducted,
		amountNonInsurable,
		amountExcess,
		amountCoInsurance,
		claimStatus,
		rejectionReason,
		rejectionOverriddenBy,
		claimFormType,
		daysOpenClosed,
		closedDate,
		reassessmentDate,
		reassessmentCreatedBy,
		reassessmentReason,
		reassessmentOutcome,
		reassessmentClosedDate,
		reassessmentClosedBy,
		claimSource,
		eligibleConditions,
		isFastTrackReviewed,
		fastTrackReason,
	) {
		super(brandName,
			brandLocale,
			customerId,
			policyId,
			policyReference,
			claimId,
			customerFirstName,
			customerLastName,
		);
		this.customerExtRef = customerExtRef;
		this.customerExtId = customerExtId;
		this.customerAddress = customerAddress;
		this.customerPostalCode = customerPostalCode;
		this.policyStatus = policyStatus;
		this.policyStatusDisplay = policyStatusDisplay;
		this.productName = productName;
		this.vetAddresses = vetAddresses;
		this.vetBusinessNames = vetBusinessNames;
		this.vetPostalCodes = vetPostalCodes;
		this.petExtRef = petExtRef;
		this.petExtId = petExtId;
		this.petType = petType;
		this.petGender = petGender;
		this.petBreed = petBreed;
		this.petDateOfBirth = petDateOfBirth;
		this.petAgeInception = petAgeInception;
		this.petAgeTreatmentStart = petAgeTreatmentStart;
		this.policyInceptionDate = policyInceptionDate;
		this.datePolicyStart = datePolicyStart;
		this.ailments = ailments;
		this.bodyParts = bodyParts;
		this.conditionTypes = conditionTypes;
		this.dateOnSet = dateOnSet;
		this.dateTreatmentStart = dateTreatmentStart;
		this.dateTreatmentEnd = dateTreatmentEnd;
		this.sections = sections;
		this.datePaid = datePaid;
		this.claimCreatedBy = claimCreatedBy;
		this.claimCreatedDate = claimCreatedDate;
		this.claimAssessedBy = claimAssessedBy;
		this.claimApprovedBy = claimApprovedBy;
		this.amountPaid = amountPaid;
		this.amountExGratia = amountExGratia;
		this.amountSubmitted = amountSubmitted;
		this.userDeductions = userDeductions;
		this.amountUserDeducted = amountUserDeducted;
		this.amountNonInsurable = amountNonInsurable;
		this.amountExcess = amountExcess;
		this.amountCoInsurance = amountCoInsurance;
		this.claimStatus = claimStatus;
		this.rejectionReason = rejectionReason;
		this.rejectionOverriddenBy = rejectionOverriddenBy;
		this.claimFormType = claimFormType;
		this.daysOpenClosed = daysOpenClosed;
		this.closedDate = closedDate;
		this.reassessmentDate = reassessmentDate;
		this.reassessmentCreatedBy = reassessmentCreatedBy;
		this.reassessmentReason = reassessmentReason;
		this.reassessmentOutcome = reassessmentOutcome;
		this.reassessmentClosedDate = reassessmentClosedDate;
		this.reassessmentClosedBy = reassessmentClosedBy;
		this.claimSource = claimSource;
		this.eligibleConditions = eligibleConditions;
		this.isFastTrackReviewed = isFastTrackReviewed;
		this.fastTrackReason = fastTrackReason;
	}
};
