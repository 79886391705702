const InvoiceLineType = Object.freeze({
	/// <summary>
	/// Treatment line type.
	/// </summary>
	Treatment: 0,
	/// <summary>
	/// Tax line type.
	/// </summary>
	SalesTax: 1,
	/// <summary>
	/// Payment line type.
	/// </summary>
	Payment: 2,
	/// <summary>
	/// Discount line type.
	/// </summary>
	Discount: 3,
});

const InvoiceLineTypeDisplayMap = Object.freeze({
	[InvoiceLineType.Treatment]: 'Treatment',
	[InvoiceLineType.SalesTax]: 'Sales tax',
	[InvoiceLineType.Payment]: 'Payment',
	[InvoiceLineType.Discount]: 'Discount',
});

const LineTypeOptions = Object.keys(InvoiceLineTypeDisplayMap).map(key => ({ key: parseInt(key), value: InvoiceLineTypeDisplayMap[key] }));

export { InvoiceLineType, InvoiceLineTypeDisplayMap, LineTypeOptions };
