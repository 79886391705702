<template>
	<div>
		<button
			class="btn btn-primary w-100 my-10"
			v-if="$can.EditTeams"
			@click="$emit('create-team')"
			:class="{ 'opacity-05' : selectedId !== null}"
			data-qa="teams_button_addNewTeam"
		>
			Add New Team
		</button>
		<div
			v-if="teams.length !== 0"
			class="content-wrapper"
		>
			<team
				v-for="team of teams"
				:key="team.id"
				:team="team"
				:actions="teamsActions"
				:class="{ 'not-selected' : selectedId !== null && team.id !== selectedId, 'selected' : selectedId !== null && team.id === selectedId}"
				class="team-card m-10"
				@team-action="$emit('team-action', { action: $event, team })"
			/>
		</div>
		<img
			v-else
			class="p-90 m-auto"
			alt="No Teams Image"
			:src="require(`@/assets/images/${themeIconSettings.NoTeams}.svg`)"
		>
	</div>
</template>
<script>
import Team from '@commonLayout/teams/Team';
import { TeamsActions } from '@commonServices/teamsService';
import { mapState } from 'vuex';

export default {
	name: 'AqTeams',
	components: {
		team: Team,
	},
	props: {
		teams: {
			type: Array,
			required: true,
		},
		selectedId: {
			type: Number,
			required: false,
		},
	},
	computed: {
		...mapState(['themeIconSettings']),
		teamsActions () {
			const actions = [];
			if (this.$can.ViewTeams) {
				actions.push(TeamsActions.view);
			}
			if (this.$can.DeleteTeams) {
				actions.push(TeamsActions.delete);
			}
			return actions;
		},
	},
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
}

.team-card {
  background: var(--bodyColour);

  &.selected {
    border: 1px solid var(--selectedBorder);
  }

  &.not-selected {
    opacity: 0.5;
  }
}

.p-90 {
  padding: 90px;
}

.opacity-05 {
  opacity: 0.5;
}
</style>
