<template>
	<div class="section-header mx-auto d-flex justify-content-center">
		<div class="mx-auto d-flex align-items-center">
			<div class="section-header__title">
				<span> {{ title }}</span>
			</div>
			<div
				class="card-mode-toggle"
				@click="toggleCardView"
				@mouseover="isMouseover = true"
				@mouseleave="isMouseover = false"
				data-qa="cardMode_header_toggler"
			>
				<component
					:is="cardViewModeIcon"
					class="cursor-pointer mr-5"
				/>
				{{ cardViewModeDisplay }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CardViewMode from '@clientCommon/services/settings/CardViewMode';
import { ExpandCard, CollapseCard } from '@assets/icons';

export default {
	name: 'AqCardModeHeader',
	components: {
		ExpandCard,
		CollapseCard,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			isMouseover: false,
		};
	},
	computed: {
		...mapGetters(['isExpandCardMode']),
		cardViewModeDisplay () {
			if (this.isExpandCardMode) {
				return this.isMouseover ? 'Collapse cards' : 'Expanded cards';
			}

			return this.isMouseover ? 'Expand cards' : 'Collapsed cards';
		},
		cardViewModeIcon () {
			if (this.isExpandCardMode) {
				return this.isMouseover ? CollapseCard : ExpandCard;
			}

			return this.isMouseover ? ExpandCard : CollapseCard;
		},
	},
	methods: {
		...mapActions(['updateCardViewMode']),
		toggleCardView () {
			const mode = this.isExpandCardMode ? CardViewMode.Collapsed : CardViewMode.Expanded;
			this.updateCardViewMode(mode);
		},
	},
};
</script>

<style lang="scss" scoped>

.card-mode-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 1.1rem;
  width: 12rem;
  cursor: pointer;
}
</style>
