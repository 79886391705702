import { currency, pluralize } from '@commonServices/utils/filters';
import groupBy from 'lodash.groupby';
import httpClient from './httpClient';

const baseDeduction = {
	condition: null,
	limit: null,
	deductible: null,
	coverage: null,
	nonFinancialLimit: null,
};

// #region requests

export default {
	getClaimDeductions (claimId) {
		return httpClient(`/api/claims/${claimId}/deduction-types`)
			.get();
	},
};

// #endregion

// #region logic

export function toPolicySections (policySections, locale, displayCoInsurancePayout) {
	return policySections?.map(section => {
		let sectionLimitDeductions = _buildSectionLimitDeductions(section.sectionLimitDeductions, locale);
		const conditionLimitDeductions = _buildConditionLimitDeductions(section.conditionLimitDeductions, locale);
		const fixedExcessDeductions = _buildFixedExcessDeductions(section.fixedExcessDeductions, locale);
		const coInsuranceDeductions = _buildCoInsuranceDeductions(section.coInsuranceDeductions, locale, displayCoInsurancePayout);
		const nonFinancialLimitDeductions = _buildNonFinancialLimitDeductions(section.nonFinancialLimitDeductions, locale);
		const partialCoverageDeductions = _buildPartialCoverageDeductions(section.partialCoverageDeductions, locale);

		sectionLimitDeductions = [...sectionLimitDeductions, ...conditionLimitDeductions];

		const tableRowsCount = Math.max(sectionLimitDeductions.length, fixedExcessDeductions.length, coInsuranceDeductions.length, nonFinancialLimitDeductions.length, partialCoverageDeductions.length);

		for (let index = 0; index < tableRowsCount; index++) {
			if (sectionLimitDeductions[index]) {
				sectionLimitDeductions[index].deductible = fixedExcessDeductions[index]?.deductible;
				sectionLimitDeductions[index].coverage = coInsuranceDeductions[index]?.coverage;
				sectionLimitDeductions[index].nonFinancialLimit = nonFinancialLimitDeductions[index]?.nonFinancialLimit;
				sectionLimitDeductions[index].partialCoverage = partialCoverageDeductions[index]?.partialCoverage;
			} else {
				sectionLimitDeductions[index] = {
					...baseDeduction,
					deductible: fixedExcessDeductions[index]?.deductible,
					coverage: coInsuranceDeductions[index]?.coverage,
					nonFinancialLimit: nonFinancialLimitDeductions[index]?.nonFinancialLimit,
					partialCoverage: partialCoverageDeductions[index]?.partialCoverage,
				};
			}
		}

		return {
			...section,
			allDeductions: sectionLimitDeductions,
		};
	});
}

function _buildSectionLimitDeductions (sectionLimitDeductions, locale) {
	if (!sectionLimitDeductions.length) return [];

	const limitLabel = _getDeductionDetails(sectionLimitDeductions, locale);

	return [{
		...baseDeduction,
		limit: limitLabel,
	}];
}

function _buildConditionLimitDeductions (conditionLimitDeductions, locale) {
	if (!conditionLimitDeductions.length) return [];

	const groupedByCondition = groupBy(conditionLimitDeductions, deduction => deduction.conditionType);
	return Object.keys(groupedByCondition).map(group => {
		const limitLabel = _getDeductionDetails(groupedByCondition[group], locale);

		return {
			...baseDeduction,
			condition: `- ${group}`,
			limit: limitLabel,
		};
	});
}

function _buildFixedExcessDeductions (fixedExcessDeductions, locale) {
	if (!fixedExcessDeductions.length) return [];

	const limitLabel = _getDeductionDetails(fixedExcessDeductions, locale);
	return [{
		...baseDeduction,
		deductible: limitLabel,
	}];
}

function _buildCoInsuranceDeductions (coInsuranceDeductions, locale, displayCoInsurancePayout) {
	if (!coInsuranceDeductions.length) return [];
	const percentFormat = (value) => displayCoInsurancePayout ? `${100 - value}%` : `${value}%`;

	const limitLabel = _getDeductionDetails(coInsuranceDeductions, locale, percentFormat);
	return [{
		...baseDeduction,
		coverage: limitLabel,
	}];
}

function _buildPartialCoverageDeductions (partialCoverageDeductions, locale) {
	if (!partialCoverageDeductions.length) return [];
	const percentFormat = (value) => `${value}%`;

	const limitLabel = _getDeductionDetails(partialCoverageDeductions, locale, percentFormat);
	return [{
		...baseDeduction,
		partialCoverage: limitLabel,
	}];
}

function _buildNonFinancialLimitDeductions (nonFinancialLimitDeductions, locale) {
	if (!nonFinancialLimitDeductions.length) return [];
	const unitFormat = (value, unit) => pluralize(unit, value, true);

	const limitLabel = _getDeductionDetails(nonFinancialLimitDeductions, locale, unitFormat);
	return [{
		...baseDeduction,
		nonFinancialLimit: limitLabel,
	}];
}

function _getDeductionDetails (deductions, locale, amountFormatFn = currency) {
	return deductions.map(deduction => {
		const parentLimitLabel = deduction.useParent ? 'Use parent limit ' : null;
		const amountLabel = parentLimitLabel ?? (deduction.noMaximumLimit
			? 'Unlimited'
			: `${deduction.unit
				? amountFormatFn(deduction.limit, deduction.unit)
				: amountFormatFn(deduction.limit, locale)}`);
		const ruleTypeLabel = _splitTheStringByCapitalCase(deduction.ruleType);

		const basedOnArr = [];
		if (deduction.basedOnBreed) {
			basedOnArr.push('based on breed');
		}
		if (deduction.basedOnPetType) {
			basedOnArr.push('based on pet type');
		}
		if (deduction.basedOnPostcode) {
			basedOnArr.push('based on postcode');
		}
		if (deduction.basedOnPetAge) {
			basedOnArr.push('based on pet age');
		}

		const basedOnLabel = basedOnArr.length > 0 ? `(${basedOnArr.join(', ')})` : '';
		return `${amountLabel} ${ruleTypeLabel} ${basedOnLabel}`;
	}).join(', ');
}

function _splitTheStringByCapitalCase (value) {
	const split = value.split(/(?=[A-Z])/);
	return split.map(word => word.toLowerCase()).join(' ');
}

// endregion
