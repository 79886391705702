<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-12 pb-12">
								Please select an export type.
							</div>
							<div class="col-12 pb-12">
								<aq-select
									label="Select Type"
									:options="exportTypes"
									option-label="description"
									v-model="selectedExportType"
								>
									<template #default="props">
										<div class="px-10">
											<div class="select-option-header text-truncate">
												{{ props.option.description }}
											</div>
										</div>
									</template>
								</aq-select>
								<div
									class="mt-8"
									v-if="$v.selectedExportType.$error"
								>
									<div
										class="text-danger"
										v-for="value in selectedTypeErrors"
										:key="value"
									>
										{{ value }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onExport"
						:disabled="$v.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Export
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { shownErrors } from '@commonServices/utils/general';
import { exportTypes } from '@commonServices/models/ExportType';

export default {
	data () {
		return {
			selectedExportType: null,
			exportTypes,
			errorsMessages: {
				selectedReason: {
					required: 'The export type should be selected',
				},
			},
		};
	},
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	validations () {
		return {
			selectedExportType: {
				required,
			},
		};
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onExport () {
			this.$emit('export', { type: this.selectedExportType });
		},
	},
	computed: {
		selectedTypeErrors () {
			return shownErrors(
				this.$v,
				'selectedExportType',
				this.errorMessages.selectedExportType,
			);
		},
	},
};
</script>
