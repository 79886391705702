<template>
	<div class="container-fluid">
		<aq-list-navigation-control
			:list="items"
			:current-item="currentItem"
			@current-changed="onSelect"
		>
			<el-table
				:data="items"
				fit
				:row-class-name="getRowClassName"
				empty-text="There is no data to display"
				cell-class-name="text-left"
				data-qa="claimPayments_table_reports"
				:key="currentColumnPage"
			>
				<paged-columns
					:current-column-page="currentColumnPage"
					:columns-per-page="columnsPerPage"
				>
					<el-table-column
						type="index"
						:index="getIndex"
						width="65"
					/>
					<el-table-column label="Brand">
						<template slot-scope="scope">
							<truncated-text-cell :value="scope.row.brandName" />
						</template>
					</el-table-column>
					<el-table-column
						prop="customerRef"
						label="Customer Ref"
						width="100"
					/>
					<el-table-column label="Policy Ref">
						<template slot-scope="scope">
							<truncated-text-cell :value="scope.row.policyReference" />
						</template>
					</el-table-column>
					<el-table-column
						label="Claim Ref"
						width="100"
					>
						<template slot-scope="scope">
							<div>{{ scope.row.claimId | claimIdFormat }}</div>
						</template>
					</el-table-column>
					<el-table-column
						label="Customer Full Name"
						width="136"
					>
						<template slot-scope="scope">
							<router-link
								:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
								class="d-inline-block"
							>
								<aq-icon-avatar
									class="small mr-auto cursor-inherit"
									:value="[scope.row.customerFirstName, scope.row.customerLastName]"
								/>
							</router-link>
						</template>
					</el-table-column>
					<el-table-column
						prop="vetBusinessNames"
						label="Vet Business Name"
					>
						<template slot-scope="scope">
							<truncated-text-cell :value="scope.row.vetBusinessNames" />
						</template>
					</el-table-column>
					<el-table-column
						prop="paymentMethod"
						label="Payment Type"
						width="100"
					/>
					<el-table-column label="Account Name">
						<template slot-scope="scope">
							<truncated-text-cell :value="scope.row.accountName" />
						</template>
					</el-table-column>
					<el-table-column
						prop="sortCode"
						label="Sort Code"
						width="100"
					/>
					<el-table-column label="Account Number">
						<template slot-scope="scope">
							<truncated-text-cell :value="scope.row.accountNumber" />
						</template>
					</el-table-column>
					<el-table-column label="Payee">
						<template slot-scope="scope">
							<truncated-text-cell :value="scope.row.payee" />
						</template>
					</el-table-column>
					<el-table-column
						prop="claimExGratiaAmountPaid"
						label="Amount Ex-Gratia"
						width="120"
					/>
					<el-table-column
						prop="claimAmountPaid"
						label="Amount"
						width="110"
					/>
					<el-table-column
						prop="claimDatePaid"
						label="Closed-Paid Date"
						width="140"
					/>
				</paged-columns>
			</el-table>
		</aq-list-navigation-control>
	</div>
</template>

<script>

import rowHighlightMixin from '@mixins/rowHighlightMixin';

export default {
	name: 'ClaimPayments',
	mixins: [rowHighlightMixin],
	props: {
		items: {
			type: Array,
			required: true,
		},
		currentColumnPage: {
			type: Number,
			required: true,
		},
		columnsPerPage: {
			type: Number,
			required: true,
		},
		startIndex: {
			type: Number,
			default: 1,
		},
	},
	methods: {
		getIndex (tableIndex) {
			return this.startIndex + tableIndex;
		},
	},
};
</script>
