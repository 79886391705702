<template>
	<aq-sandwich
		header="Vet Details"
		ref="sandwich"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template slot="content">
			<div class="section-content flex-grow-1">
				<div class="container-fluid">
					<aq-select
						data-qa="conditionForm_select_vetName"
						:loading="isVetLoading"
						:options="vets"
						label="Vet Name"
						option-label="practiceName"
						class="w-100"
						v-model="selectedVet"
						track-by="id"
						no-result-message="No Vet Names found"
						:async-find="searchVets"
						:disabled="data.hasRejectedClaims"
					/>
				</div>
				<div
					class="control-container mt-20 mb-20 ml-15 mr-15"
					v-if="selectedVet"
				>
					<vet-details-info
						:vet-info="selectedVet"
						class="mt-10 mb-10"
					/>
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<div class="d-flex text-right">
						<button
							class="btn btn-row mr-10 ml-auto"
							@click="$emit('cancel')"
						>
							Cancel
						</button>
						<button
							class="btn btn-primary"
							@click="onProceed"
						>
							Proceed
						</button>
					</div>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import debounce from 'lodash.debounce';
import ClaimService from '@commonServices/claimService';

export default {
	name: 'VetDetails',
	data () {
		return {
			isVetLoading: false,
			selectedVet: this.data.vet,
			vets: [],
		};
	},
	beforeDestroy () {
		this.onProceed();
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	methods: {
		searchVets: debounce(async function (term) {
			if (term && term.length >= 3) {
				this.isVetLoading = true;
				this.vets = await ClaimService.getVets(term).then(res =>
					res.filter(vet => vet.approved && vet.active));
				this.isVetLoading = false;
			}
		}, 500),
		onProceed () {
			this.$refs.sandwich.onToggle();
			this.$emit('passed', this.selectedVet);
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
	},
};
</script>
