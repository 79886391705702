export default class PreExistingConditionView {
	constructor (
		id,
		condition,
		bodyPart,
		exclusionType,
		exclusionTypeDisplay,
		firstSignsDate,
		createdBy,
		createdDate,
		updatedBy,
		updatedDate,
		comments,
		relatedConditions,
		archived,
		hasRejectedClaims,
		vet,
		documentCommentary,
		diagnosisOccurrences,
		isSystem,
		pageNumber,
	) {
		this.id = id;
		this.condition = condition;
		this.bodyPart = bodyPart;
		this.exclusionType = exclusionType;
		this.exclusionTypeDisplay = exclusionTypeDisplay;
		this.firstSignsDate = firstSignsDate;
		this.createdBy = createdBy;
		this.createdDate = createdDate;
		this.updatedBy = updatedBy;
		this.updatedDate = updatedDate;
		this.comments = comments;
		this.relatedConditions = relatedConditions;
		this.archived = archived;
		this.hasRejectedClaims = hasRejectedClaims;
		this.vet = vet;
		this.documentCommentary = documentCommentary;
		this.diagnosisOccurrences = diagnosisOccurrences;
		this.isSystem = isSystem;
		this.pageNumber = pageNumber;
	}
};
