<template>
	<div
		class="aq-modal-container w-100"
		tabindex="-1"
	>
		<div
			class="d-inline-block aq-modal"
			:style="{ 'transform': `translate( -50%, -0px)`, width: `${modalWidth}px` }"
		>
			<div ref="header">
				<slot name="header">
					<div class="aq-modal-header pl-26 pt-16 pb-12 ob-12 d-flex align-items-center">
						<div>
							<div
								class="aq-title text-white"
								v-if="title"
							>
								{{ title }}
							</div>
							<div
								class="aq-paragraph mt-6"
								v-if="paragraph"
							>
								{{ paragraph }}
							</div>
						</div>
						<div
							@click="$emit('close')"
							class="close text-white ml-auto mr-24"
						>
							<i class="fas fa-times-circle fa-lg" />
						</div>
					</div>
				</slot>
			</div>
			<div class="aq-modal-content">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			headerHeight: 0,
		};
	},
	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		paragraph: {
			type: String,
			required: false,
			default: '',
		},
		modalWidth: {
			type: String,
			required: false,
		},
	},
	methods: {
		matchHeight () {
			this.headerHeight = this.$refs.header.clientHeight;
		},
	},
	mounted () {
		this.matchHeight();
	},
};
</script>

<style lang="scss" scoped>
.aq-title {
  font-size: 16px;
}

.aq-paragraph {
  font-size: 12px;
}

.aq-modal-container {
  height: auto;
}

.aq-modal {
  box-shadow: 0 20px 60px -2px rgba(black, .4);
  margin-left: 50%;
}

.close {
  cursor: pointer;
}
</style>
