export default class NewRecipientInput {
	constructor (sortCode,
		accountNumber,
		bankName,
		payee,
		paymentMethod,
		paymentRecipientType,
		paymentRecipientId,
		locale,
	) {
		this.sortCode = sortCode;
		this.accountNumber = accountNumber;
		this.bankName = bankName;
		this.payee = payee;
		this.paymentMethod = paymentMethod;
		this.paymentRecipientType = paymentRecipientType;
		this.paymentRecipientId = paymentRecipientId;
		this.locale = locale;
	}
};
