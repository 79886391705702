export const PayeeType = Object.freeze({
	PolicyHolder: 1,
	Vet: 2,
});

export const PaymentType = Object.freeze({
	Claim: 1,
	ExGratia: 2,
});

export const PaymentTypeDisplayMap = Object.freeze({
	[PaymentType.Claim]: 'Claim',
	[PaymentType.ExGratia]: 'Ex Gratia',
});
