<template>
	<div>
		<div
			class="bottom text-right fs-12 mt-2 cursor-pointer py-2 px-2 font-weight-bold text-underline"
			@click="onClick"
		>
			Show <span v-if="opened"> less</span><span v-else> more</span>
			<i :class="['fas text-primary fs-14 ml-4 cursor-pointer', opened ? 'fa-caret-up' : 'fa-caret-down']" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		opened: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		onClick () {
			this.$emit('show-more-less', !this.opened);
		},
	},
};
</script>
