import { WorkQueueType } from '@commonServices/models/WorkQueueType';
import { TaskPageDraftFilters, TaskPageMissingInfoFilters, TaskPageFraudFilters, TaskPagePaymentApprovalFilters, TaskPageReassessmentPendingFilters, TaskPageRejectionApprovalFilters } from '@commonServices/models/WorkQueueFilters';
import { ClaimPaymentsReportFilters, ClaimStatusReportFilters, UserStatsReportFilters, QualityAuditReportFilters, ClaimReportFilters, ConditionsReportFilters } from '@commonServices/models/ReportFilters';

const FilterGroupEnum = Object.freeze({
	ClaimReport: 1,
	ClaimPayments: 2,
	ClaimStatus: 3,
	Letters: 4,
	WorkQueueDraft: 5,
	WorkQueueSettled: 6,
	WorkQueueFraudCheck: 7,
	WorkQueueRejectionPending: 8,
	WorkQueueMissingInfo: 9,
	WorkQueueReassessmentPending: 10,
	UserStats: 11,
	QualityAuditReport: 12,
	ConditionsReport: 13,
});

const ReportGroupDisplayMap = Object.freeze({
	[FilterGroupEnum.ClaimReport]: 'Claim Reports',
	[FilterGroupEnum.ClaimPayments]: 'Claim Payments',
	[FilterGroupEnum.ClaimStatus]: 'Claim Status',
	[FilterGroupEnum.UserStats]: 'User Statistics',
	[FilterGroupEnum.QualityAuditReport]: 'Claims QA Report',
	[FilterGroupEnum.ConditionsReport]: 'Eligible Conditions',
});

const ReportGroupFiltersMap = Object.freeze({
	[FilterGroupEnum.ClaimReport]: ClaimReportFilters,
	[FilterGroupEnum.ClaimPayments]: ClaimPaymentsReportFilters,
	[FilterGroupEnum.ClaimStatus]: ClaimStatusReportFilters,
	[FilterGroupEnum.UserStats]: UserStatsReportFilters,
	[FilterGroupEnum.QualityAuditReport]: QualityAuditReportFilters,
	[FilterGroupEnum.ConditionsReport]: ConditionsReportFilters,
});

function reportHasCharting (reportGroup) {
	return [FilterGroupEnum.ClaimReport, FilterGroupEnum.ClaimStatus, FilterGroupEnum.QualityAuditReport, FilterGroupEnum.ClaimPayments].includes(reportGroup);
}

const WorkQueueFiltersMap = Object.freeze({
	[WorkQueueType.DraftClaims]: TaskPageDraftFilters,
	[WorkQueueType.Chasing]: TaskPageMissingInfoFilters,
	[WorkQueueType.FraudCheck]: TaskPageFraudFilters,
	[WorkQueueType.PaymentApproval]: TaskPagePaymentApprovalFilters,
	[WorkQueueType.ReassessmentPending]: TaskPageReassessmentPendingFilters,
	[WorkQueueType.RejectionApproval]: TaskPageRejectionApprovalFilters,
});

export { FilterGroupEnum, ReportGroupDisplayMap, ReportGroupFiltersMap, WorkQueueFiltersMap, reportHasCharting };
