import PlaceItem from './models/PlaceItem';
import { Loader } from 'google-maps';
import { currentLocale, SupportedLocales } from '@commonServices/settings/localeSettings';

const loaderOptions = {
	libraries: ['places'],
	version: ClaimsConfig.GOOGLE_PLACES_VERSION || 'quarterly',
	language: ClaimsConfig.GOOGLE_PLACES_LANGUAGE || 'en',
	region: ClaimsConfig.GOOGLE_PLACES_REGION || 'GB',
};

const googleMapsLoader = new Loader(ClaimsConfig.GOOGLE_PLACES_API_KEY, loaderOptions);

function findPlaceAddressElement (place, type) {
	const addressItem = place.address_components.find(function (item) {
		return item.types.indexOf(type) >= 0;
	});

	return addressItem ? addressItem.long_name : '';
}

function mapPlaceEnGb (item, getPlacePart) {
	item.postalCode = getPlacePart('postal_code');
	item.country = getPlacePart('country');

	const premise = getPlacePart('premise');
	const streetNumberWithRoute = `${getPlacePart('street_number')} ${getPlacePart('route')}`;
	item.address1 = premise || streetNumberWithRoute;
	item.address2 = premise ? streetNumberWithRoute : getPlacePart('neighborhood');
	item.address3 = getPlacePart('sublocality_level_1') || getPlacePart('locality');

	const postalTown = getPlacePart('postal_town');
	const administrativeAreaLevel2 = getPlacePart('administrative_area_level_2');
	item.townCity = postalTown || administrativeAreaLevel2;
	item.regionCounty = postalTown ? administrativeAreaLevel2 : getPlacePart('administrative_area_level_1');

	item.addressDescription = [item.address1, item.address2, item.address3, item.townCity, item.postalCode].filter(Boolean).join(', ');
	return item;
}

function mapPlaceDefault (item, getPlacePart) {
	item.postalCode = getPlacePart('postal_code');
	item.country = getPlacePart('country');

	const premise = getPlacePart('premise');
	const streetNumberWithRoute = `${getPlacePart('street_number')} ${getPlacePart('route')}`;
	item.address1 = premise || streetNumberWithRoute;
	item.address2 = premise ? streetNumberWithRoute : getPlacePart('neighborhood');
	item.address3 = getPlacePart('administrative_area_level_2');

	item.townCity = getPlacePart('locality');
	item.regionCounty = getPlacePart('administrative_area_level_1');

	item.addressDescription = [item.address1, item.address2, item.address3, item.townCity, item.postalCode].filter(Boolean).join(', ');
	return item;
}

const mapPlace = currentLocale === SupportedLocales.enGB ? mapPlaceEnGb : mapPlaceDefault;

export default {
	searchVet (searchTerm, element, callback) {
		googleMapsLoader.load().then((google) => {
			const service = new google.maps.places.PlacesService(element);
			service.textSearch({ query: searchTerm, types: ['veterinary_care'] }, (results) => {
				callback(results.map(place => new PlaceItem(place.place_id, place.name, place.formatted_address)));
			});
		});
	},
	getPlace (id, element, callback) {
		const request = {
			placeId: id,
			fields: ['name', 'adr_address', 'address_components', 'formatted_phone_number'],
		};
		googleMapsLoader.load().then((google) => {
			const service = new google.maps.places.PlacesService(element);
			service.getDetails(request, (place) => {
				const getPlacePart = part => findPlaceAddressElement(place, part);
				let item = new PlaceItem(id, place.name, place.formatted_address);
				item.phone = place.formatted_phone_number;
				item = mapPlace(item, getPlacePart);
				callback(item);
			});
		});
	},
};
