export default class PolicyTermView {
	constructor (
		policyId,
		policyReference,
		policyStatus,
		policyStatusDisplay,
		policyInceptionDate,
		addons,
		policyTermId,
		product,
		policyTermStatus,
		policyTermStartDate,
		policyTermEndDate,
		yearStartDate,
		yearEndDate,
		policyPromoCode,
		policyAssociateName,
		policyOfferType,
		policyOffer) {
		this.policyId = policyId;
		this.policyReference = policyReference;
		this.policyStatus = policyStatus;
		this.policyStatusDisplay = policyStatusDisplay;
		this.policyInceptionDate = policyInceptionDate;
		this.addons = addons;
		this.policyTermId = policyTermId;
		this.product = product;
		this.policyTermStatus = policyTermStatus;
		this.policyTermStartDate = policyTermStartDate;
		this.policyTermEndDate = policyTermEndDate;
		this.yearStartDate = yearStartDate;
		this.yearEndDate = yearEndDate;
		this.policyPromoCode = policyPromoCode;
		this.policyAssociateName = policyAssociateName;
		this.policyOfferType = policyOfferType;
		this.policyOffer = policyOffer;
	}
}
