<template>
	<div>
		<slot />
		<div
			v-show="scrollPx"
			class="scroll-top"
			@click.stop="scrollToTop()"
		>
			<div class="label">
				Back to Top
			</div>
			<div class="scroll-top-icon d-flex justify-content-center align-items-center">
				<i class="fs-30 fas fa-arrow-up" />
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AqBackToTop',
	props: {
		selector: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			scrollContainer: null,
			scrollPx: 0,
		};
	},
	mounted () {
		this.scrollContainer = this.selector ? this.$el.querySelector(this.selector) : this.$slots.default[0].elm;
		this.setScrollListener();
	},
	methods: {
		setScrollPx () {
			this.scrollPx = this.scrollContainer.scrollTop;
		},
		setScrollListener () {
			this.scrollPx = 0;
			this.scrollContainer?.addEventListener('scroll', this.setScrollPx);
		},
		scrollToTop () {
			setTimeout(() => {
				this.scrollContainer?.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}, 0);
		},
	},
	beforeDestroy () {
		this.scrollContainer?.removeEventListener('scroll', this.setScrollPx);
	},
};
</script>

<style lang="scss" scoped>
$scrollTopWidth: 42px;
$scrollTopHeight: 45px;

.scroll-top {
  position: absolute;
  left: 15px;
  bottom: 10px;
  z-index: 5;
  transition: left 0.5s ease;
  width: $scrollTopWidth;
  height: $scrollTopHeight;

  &.max-width {
    left: calc(100% - 45px);
  }

  .label {
    display: none;
    position: absolute;
    left: $scrollTopWidth;
    top: 50%;
    transform: translateY(-50%);
    background: var(--backgroundWarningLight);
    padding: 8px;
    color: white;
    border-radius: 0 6px 6px 0;
    width: 100px;
  }

  &:hover > .label {
    display: block;
  }

  .scroll-top-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: var(--backgroundWarningLight);
    border-radius: 5px;
    color: var(--sectionTextColour);
    opacity: 0.7;
  }

  &:hover > .scroll-top-icon {
    opacity: 0.8;
  }
}
</style>
