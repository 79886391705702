<template>
	<aq-repeat
		:action="notify"
		:interval="activityRequestInterval"
	/>
</template>

<script>
import AqRepeat from '@commonWidgets/AqRepeat';
import UsersService from '@commonServices/usersService';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLatestActivityTime } from '@clientCommon/services/utils/Idle';
import { isKnownError } from '@clientCommon/services/utils/general';
import ErrorCode from '@commonServices/models/ErrorCode';

const DEFAULT_ACTIVITY_REQUEST_INTERVAL = 15;

export default {
	name: 'AqUserActivity',
	components: {
		AqRepeat,
	},
	watch: {
		userOptIn () {
			this.notify();
		},
	},
	computed: {
		...mapState(['appSettings']),
		...mapGetters(['userOptIn']),
		activityRequestInterval () {
			if (this.appSettings?.userActivityTimeThresholdSec > 0) {
				return Math.round(this.appSettings.userActivityTimeThresholdSec / 2);
			}
			return DEFAULT_ACTIVITY_REQUEST_INTERVAL;
		},
	},
	methods: {
		...mapActions(['setUserOptIn']),
		async notify () {
			try {
				await UsersService.notifyUserActive(this.userOptIn, new Date(getLatestActivityTime()));
			} catch (error) {
				if (isKnownError(error, ErrorCode.InvalidActionError)) {
					this.setUserOptIn(true);
				}
			}
		},
	},
};
</script>
