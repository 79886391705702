const rowHighlightMixin = {
	data () {
		return {
			currentItem: null,
		};
	},
	methods: {
		onSelect (newCurrentItem) {
			this.currentItem = newCurrentItem;
		},
		getRowClassName ({ row }) {
			return this.currentItem === row ? 'border-highlighted' : '';
		},
	},
};

export default rowHighlightMixin;
