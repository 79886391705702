const ExportType = Object.freeze({
	Csv: 1,
	Xlsx: 2,
});

const ExportTypeDisplayMap = Object.freeze({
	[ExportType.Csv]: 'CSV',
	[ExportType.Xlsx]: 'XLSX',
});

const exportTypes = Object.entries(ExportTypeDisplayMap)
	.map(([key, value]) => {
		return {
			value: parseInt(key),
			description: value,
		};
	});

function getDisabledExportMessage (rowCount, dataRowLimit) {
	if (rowCount <= dataRowLimit) {
		return null;
	}
	return `Max row count of ${dataRowLimit} rows exceeded`;
}

export {
	ExportType,
	ExportTypeDisplayMap,
	exportTypes,
	getDisabledExportMessage,
};
