<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid scrollbar">
						<div
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
							v-for="(detail, index) in data"
							:key="index"
						>
							<div class="col">
								{{ detail.item1 }}
							</div>
							<div
								v-if="detail.item2"
								class="col"
							>
								{{ detail.item2 }}
							</div>
						</div>
						<div
							class="multiline-content"
							v-if="adjusterComment"
						>
							<div
								class="row no-gutters list-item--bg pt-14 px-20 mr-2"
							>
								<div
									class="col font-weight-bold"
								>
									Adjuster Comment
								</div>
							</div>
							<div class="row no-gutters list-item--bg py-14 px-20 mr-2">
								<div class="col">
									{{ adjusterComment }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Array,
			required: true,
		},
		adjusterComment: {
			type: String,
			required: false,
			default: null,
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>
<style lang="scss" scoped>
.scrollbar {
  max-height: 400px;
  overflow-y: auto;
}
</style>
