<template>
	<div>
		<div>
			<div class="container-fluid">
				<div class="row">
					<div class="col-9 px-0 pt-0 pb-0 section-content border-right">
						<div class="section-header">
							<div class="icon mr-6">
								<i class="fa fa-table" />
							</div>
							<div class="section-header__title">
								Select Filters
							</div>
						</div>
						<AqDynamicFilters
							:filter="filter"
							:available-filters="availableFilters"
							:filter-dictionaries="filterDictionaries"
						/>
					</div>
					<div class="col-3 section-content px-0 pt-0">
						<aq-tab-selector
							:items="tabItems"
							:active-tab="activeTab"
							@select-tab="onSelectTab"
							v-if="activeTab"
							class="flex-grow-1 d-flex px-0 flex-column"
						>
							<sorting
								class="col py-0 pr-16 section-content"
								:sorting-columns="sortingColumns"
								:sorting="sorting"
								v-if="activeTab.value === 'sorting'"
							/>
							<charting
								v-else
								class="col py-0 section-content"
								:filter="filter"
								:filter-columns="$options.claimReportFilterColumns"
								:group-by-options="$options.claimReportFiltersGroupBy"
							/>
						</aq-tab-selector>
					</div>
				</div>
			</div>
			<div class="section-header d-flex justify-content-end">
				<button
					class="btn btn-row mr-8"
					@click="$emit('cancel')"
					data-qa="reportFiltersControl_button_cancel"
				>
					Cancel
				</button>
				<button
					class="btn btn-primary"
					@click="$emit('apply-filters')"
					data-qa="reportFiltersControl_button_run"
					:disabled="!allowRun"
				>
					Run
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { claimReportFilterColumns, claimReportFiltersGroupBy } from '@commonServices/models/ChartFilterModels';
import { ReportGroupFiltersMap, reportHasCharting } from '@commonServices/models/FilterGroup';
import { SortingColumnsByReportGroup } from '@commonServices/models/SortingColumns';

import Sorting from '@commonWidgets/Sorting';
import Charting from '@commonView/ReportsPage/Charts/Charting';
import AqDynamicFilters from '@commonWidgets/Filters/AqDynamicFilters';

export default {
	components: {
		Sorting,
		Charting,
		AqDynamicFilters,
	},
	props: {
		reportGroup: {
			type: Number,
			required: true,
		},
		filter: {
			type: Object,
			required: true,
		},
		sorting: {
			type: Array,
			required: true,
		},
		filterDictionaries: {
			type: Object,
			required: true,
		},
	},
	claimReportFilterColumns,
	claimReportFiltersGroupBy,
	data () {
		return {
			searchTerms: [],
			activeTab: null,
		};
	},
	mounted () {
		this.activeTab = this.tabItems[0];
	},
	computed: {
		allowRun () {
			return (this.filter.selectedColumn.value || this.filter.selectedAggregate.value) ? this.filter.selectedColumn.value && this.filter.selectedAggregate.value : true;
		},
		availableFilters () {
			return Object.entries(ReportGroupFiltersMap[this.reportGroup]).map(([key, value]) => {
				return {
					key,
					description: value,
				};
			});
		},
		sortingColumns () {
			return SortingColumnsByReportGroup[this.reportGroup];
		},
		tabItems () {
			const tabs = [
				{
					value: 'sorting',
					label: 'Sort',
					icon: 'aqv-sorting-filter fs-32 text-primary px-10',
					labelClass: 'fs-18 font-weight-bold',
				}];

			if (reportHasCharting(this.reportGroup)) {
				tabs.push({
					value: 'charting',
					label: 'Charting',
					icon: 'fas fa-chart-bar fs-20 text-primary px-10',
					labelClass: 'fs-18 font-weight-bold',
				});
			}

			return tabs;
		},
	},
	methods: {
		onSelectTab (value) {
			if (value === 'sorting') {
				this.filter.selectedAggregate.value = null;
				this.filter.selectedColumn.value = null;
				this.filter.selectedGroupBy.value = null;
			}
			this.activeTab = this.tabItems.find(item => item.value === value);
		},
	},
};
</script>

<style lang="scss" scoped>
.icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: $primary;
  font-size: 26px;
}

.section-header {
  background: var(--headerItemBg);
}

.border-right {
  border-width: 2px !important;
  border-color: var(--searchBg) !important;
}
</style>
