<template>
	<aq-modal :name="name">
		<aq-modal-container
			@close="close('cross')"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="reassessement-outcome-icon">
								<ReassessementOutcomesModalLogo />
							</div>
							<div class="col-12 pb-12">
								Your reassessment decision is to: <span class="font-weight-bold">{{ reassessmentGroup }}</span>
							</div>
							<div class="col-12 pb-12">
								<aq-select
									data-qa="reassessmentOutcomeModal_select_outcomes"
									:value="selectedReassessmentOutcome"
									:options="restructuredReassessmentOutcomes"
									:label="selectLabel"
									option-label="description"
									class="w-85"
									track-by="id"
									group-label="subgroup"
									group-values="reassessmentOutcomesValues"
									:group-select="false"
									:truncate-options="false"
									hide-tags
									:is-sorted="false"
									@input="$emit('input', $event)"
								>
									<template #default="props">
										<div class="px-10">
											<div class="select-option-header text-truncate">
												{{ props.option.description }}
											</div>
										</div>
									</template>
								</aq-select>
							</div>
							<div class="col-12 pb-12 font-weight-bold">
								Are you sure you wish to continue?
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="close('no')"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="close('yes')"
						:disabled="$v.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Proceed
					</button>
				</div>
			</div>
		</aq-modal-container>
	</aq-modal>
</template>

<script>
import extraService from '@commonServices/extraService';
import { required } from 'vuelidate/lib/validators';
import { ReassessementOutcomesModalLogo } from '@assets/icons';
import groupBy from 'lodash.groupby';

const reassessmentGroupSelectLabel = 'Please select outcomes for';

export default {
	components: {
		ReassessementOutcomesModalLogo,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		claimId: {
			type: [Number, String],
			required: true,
		},
		selectedReassessmentOutcome: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			title: 'Claims Reassessment Decision',
			reassessmentOutcomes: [],
			promiseResolver: null,
		};
	},
	validations () {
		return {
			selectedReassessmentOutcome: {
				required,
			},
		};
	},
	computed: {
		reassessmentGroup () {
			return this.reassessmentOutcomes[0]?.group;
		},
		selectLabel () {
			switch (this.reassessmentGroup) {
			case 'Reasons for Overturn':
				return `${reassessmentGroupSelectLabel} overturning initial decision`;
			case 'Reasons to Maintain Decision':
				return `${reassessmentGroupSelectLabel} maintaining decision`;
			default:
				return 'No Reassessment Outcome Group';
			};
		},
		restructuredReassessmentOutcomes () {
			const groupedOutcomes = groupBy(this.reassessmentOutcomes, 'subgroup');
			return Object.entries(groupedOutcomes)
				.map(([key, value]) => {
					return {
						subgroup: (key === 'null' ? ' ' : key),
						reassessmentOutcomesValues: value,
					};
				});
		},
	},
	methods: {
		async show (outcomeState) {
			this.reassessmentOutcomes = await extraService.getReassessmentOutcomes(this.claimId, outcomeState);
			if (this.reassessmentOutcomes && this.reassessmentOutcomes.length === 0) {
				return;
			}
			this.$modal.show(this.name);
			return new Promise((resolve) => {
				this.promiseResolver = resolve;
			});
		},
		close (result) {
			this.$modal.hide(this.name);
			this.promiseResolver(result);
		},
	},
};
</script>

<style scoped>
.reassessement-outcome-icon {
  fill: var(--bodyColour);
  width: 50px;
  position: relative;
  left: 45%;
}
</style>
