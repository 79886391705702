<template>
	<div
		class="file-list p-20"
		:class="{ 'collapsed' : collapsed, 'selected' : selected }"
	>
		<div v-if="requestedFiles.length > 0 && isClaimContext">
			<div class="text-subtitle fs-12 mb-10">
				Requested Information
			</div>
			<div
				class="d-flex attribute px-20 py-10 align-items-center mb-10 no-gutters"
				v-for="(file, key) of requestedFiles"
				:key="key"
			>
				<div class="col-7">
					<div
						class="text-truncate"
						v-tooltip="file.missingItemDescription"
					>
						{{ file.missingItemDescription }}
					</div>
					<div
						class="text-truncate text-subtitle fs-12"
						v-tooltip="file.contactName"
					>
						{{ file.contactName }}
					</div>
				</div>
				<div class="col-5 fs-12 pl-20 assign">
					<template v-if="file.fileIds.length > 0">
						<div
							class="text-truncate text-subtitle"
							v-tooltip="getAssignedDocs(file.fileIds)"
						>
							{{ getAssignedDocs(file.fileIds) }}
						</div>
					</template>
					<template v-else>
						<div class="text-subtitle d-flex justify-content-end">
							<template v-if="file.resolved">
								<div>Resolved</div>
								<div class="ml-4 circle-13 circle-green" />
							</template>
							<template v-else>
								<div>None Assigned</div>
								<div class="ml-4 circle-13" />
							</template>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div
			class="mt-20"
			v-if="uploadedFiles.length > 0"
		>
			<div class="text-subtitle fs-12 mb-10">
				Uploaded Files
			</div>
			<div
				class="mb-10 attribute"
				:class="{ 'active' : isSelected(file.id)}"
				@click.stop="onSelectFile(file)"
				v-for="(file, key) of uploadFilesShowVetInfo"
				:key="key"
				data-qa="documentList_button_select"
			>
				<div
					class="d-flex px-20 py-10 align-items-center"
					:class="{ 'cursor-pointer' : !isSelected(file.id)}"
				>
					<div class="mr-5">
						<component
							:is="fileIconComponent(file.category)"
							v-tooltip.top-start="getCategoryLabel(file.category)"
							class="file-icon"
						/>
					</div>
					<div
						class="flex-grow-1 text-truncate"
						v-tooltip.top-start="file.fileName"
					>
						{{ file.fileName }}
						<p class="fs-12 mt-1">
							Uploaded at {{ file.createDate }}
						</p>
					</div>
					<div v-if="!isHospitalInvoice(file.category) && $can.FilesManagement">
						<button
							class="btn small btn-row"
							@click.stop="$emit('edit', file)"
							data-qa="documentList_button_edit"
						>
							<i class="fas fa-edit" />
						</button>
					</div>
				</div>
				<div
					class="card-color bottom mt-2 py-15 px-20"
					v-if="file.vet"
				>
					<div class="fs-14">
						<strong>Vet Details</strong>
					</div>
					<div class="mt-5">
						<span class="fs-13">
							{{ file.vet.practiceName }}
						</span>
					</div>
					<div
						class="mt-10"
						v-if="file.showVetInfo"
					>
						<vet-details-info :vet-info="file.vet" />
					</div>
					<hr class="mt-15">
					<aq-more-less-toggler
						:opened="file.showVetInfo"
						@show-more-less="file.showVetInfo = !file.showVetInfo"
					/>
				</div>
				<div
					class="card-color bottom mt-2 py-15 px-20"
					v-if="file.description"
				>
					<div class="fs-14">
						<strong>Document Description</strong>
					</div>
					<div class="mt-5">
						<span class="mt-2 fs-13 text-break">
							{{ file.description }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { FileCategoryEnumLabel, FileCategoryEnum } from '@commonServices/models/FileCategoryEnum';
import fileCategoryEnumIcon from '@commonServices/models/FileCategoryEnumIcon';
import { shortDateTimeDay } from '@commonServices/utils/filters';
import { mapState } from 'vuex';

export default {
	name: 'DocumentList',
	props: {
		collapsed: {
			type: Boolean,
			required: false,
		},
		selected: {
			type: Boolean,
			required: false,
		},
		uploadedFiles: {
			type: Array,
			required: false,
		},
		selectedDoc: {
			type: Object,
			required: false,
		},
		requestedFiles: {
			type: Array,
			required: false,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		isSelected (id) {
			return this.selectedDoc?.id === id;
		},
		getAssignedDocs (fileIds) {
			const fileNames = [];

			fileIds.forEach((id) => {
				const matchedFile = this.uploadedFiles.find(file => file.id === id);
				if (matchedFile) {
					fileNames.push(matchedFile.fileName);
				}
			});

			return fileNames.join(', ');
		},
		onSelectFile (file) {
			this.$emit('select', file);
		},
		getCategoryLabel (category) {
			return FileCategoryEnumLabel[category];
		},
		fileIconComponent (category) {
			return fileCategoryEnumIcon.get(category);
		},
		isHospitalInvoice (category) {
			return category === FileCategoryEnum.HospitalInvoice;
		},
	},
	computed: {
		...mapState(['levelFilter']),
		uploadFilesShowVetInfo () {
			return this.uploadedFiles.map(file => {
				return {
					...file,
					createDate: shortDateTimeDay(file.createDate),
					showVetInfo: false,
				};
			});
		},
		isClaimContext () {
			return !!this.levelFilter.claimId;
		},
	},
};

</script>

<style lang="scss" scoped>
.file-list {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding-right: 15px;
}

.attribute {
  min-height: 50px;
  font-size: 13px;
  border: 1px solid transparent;

  &.active {
    border: 1px solid $active-color;
    cursor: default;
  }

  .assign {
    min-width: 130px;
    text-align: right;
  }
}

.circle-13 {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: $active-color;

  &.circle-green {
    background: $success !important;
  }
}

.card-color {
  background-color: var(--containerAdditionalInfo);
}

hr {
  border: 1px solid var(--contentBg);
  opacity: 0.2;
}
</style>
