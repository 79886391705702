const ClaimFilterEnum = Object.freeze({
	Ailment: 'ailment',
	BodyPart: 'bodyPart',
	ClaimStatus: 'claimStatus',
	ClaimSource: 'claimSource',
	ShowHistoricClaims: 'showHistoricClaims',
	ShowAllClaimsInGroup: 'showAllClaimsInGroup',
	HideAutoAssignedClaims: 'hideAutoAssignedClaims',
	Brand: 'brand',
	Customer: 'customer',
	Pet: 'pet',
	Provider: 'provider',
	SortBy: 'sortBy',
});

const ClaimFilterDisplayMap = Object.freeze({
	[ClaimFilterEnum.Ailment]: 'Ailment',
	[ClaimFilterEnum.BodyPart]: 'Body Part',
	[ClaimFilterEnum.ClaimStatus]: 'Claim Status',
	[ClaimFilterEnum.ClaimSource]: 'Claim Source',
	[ClaimFilterEnum.ShowHistoricClaims]: 'Historic claims / conditions',
	[ClaimFilterEnum.ShowAllClaimsInGroup]: 'All claims in group',
	[ClaimFilterEnum.HideAutoAssignedClaims]: 'Hide auto assigned claims',
	[ClaimFilterEnum.Brand]: 'Brand',
	[ClaimFilterEnum.Customer]: 'Customer',
	[ClaimFilterEnum.Pet]: 'Pet',
	[ClaimFilterEnum.Provider]: 'Provider',
	[ClaimFilterEnum.SortBy]: 'Sort By',
});

export {
	ClaimFilterEnum,
	ClaimFilterDisplayMap,
};
