<template>
	<toggle-card-info
		:attributes="attributes"
		:rows="2"
	/>
</template>

<script>
import { age, diffTwoDates, longDate } from '@commonServices/utils/filters';
import { isDateAfter, addMonths } from '@commonServices/utils/dateUtils';
import { PrePolicyHistoryMonthAmount } from '@commonServices/utils/constants';
import ProductType from '@commonServices/models/ProductType';
import { mapState } from 'vuex';
import { sortComparer } from '@clientCommon/services/utils/general';
import SortingDirection from '@clientCommon/services/models/SortingDirection';
import ToggleCardInfoVue from '@commonView/CustomerPage/ToggleCardInfo';

export default {
	name: 'PetAttributes',
	components: {
		'toggle-card-info': ToggleCardInfoVue,
	},
	props: {
		pet: {
			type: Object,
			required: true,
		},
		policyTerm: {
			type: Object,
			required: false,
			default: null,
		},
		hiddenAttributes: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	computed: {
		...mapState(['appSettings']),
		attributes: function () {
			const attributes = [
				{ name: 'policyReference', text: this.policyTerm && this.policyTerm.policyReference, header: 'Policy Reference' },
				{ name: 'dateOfBirth', text: longDate(this.pet.birthDate), header: 'Date of Birth' },
				{ name: 'petAge', text: age(this.pet.birthDate), header: 'Pet Age' },
				this.buildPrePolicyHistoryDateAttribute(this.pet),
				{ name: 'purchaseAdoptionDate', text: longDate(this.pet.purchaseAdoptionDate), header: 'Purchase/Adoption Date' },
				{ name: 'ageAtInception', text: `${diffTwoDates(this.pet.birthDate, this.policyTerm?.policyInceptionDate)}`, header: 'Age of Pet at Inception' },
				{ name: 'registeredVet', text: this.registeredVet, header: 'Registered Vet' },
				{ name: 'neutered', text: this.pet.neutered !== null ? this.pet.neutered ? 'Neutered - Yes' : 'Neutered - No' : null, header: 'Neutered Status' },
				{ name: 'colour', text: this.pet.color, header: 'Fur colour' },
				{ name: 'microchipNumber', text: this.pet.microchipNumber, header: 'Microchip Number' },
				{ name: 'preEnrollmentDentalExam', text: this.pet.hasPreEnrollmentDentalExam != null ? (this.pet.hasPreEnrollmentDentalExam ? 'Dental Exam - Yes' : 'Dental Exam - No') : null, header: 'Pre-enrollment Dental Exam' },
			];

			const filteredAttrs = attributes.filter(attr => !this.hiddenAttributes.includes(attr.name));
			return filteredAttrs;
		},
		registeredVet () {
			return this.pet.registeredVets?.find(rv => !rv.isHistorical)?.practiceName;
		},
	},
	methods: {
		buildPrePolicyHistoryDateAttribute (pet) { // VIS-16312
			const attribute = { name: 'prePolicyHistoryDate', text: null, header: 'Pre-Policy History Start Date' };

			if (!this.appSettings.calculatePrePolicyHistoryDate) {
				return attribute;
			}

			const insurancePolicyInceptionDate = this.getInsurancePolicyInceptionDate(pet);

			if (!insurancePolicyInceptionDate) {
				return attribute;
			}

			const prePolicyHistoryDate = addMonths(insurancePolicyInceptionDate, -PrePolicyHistoryMonthAmount);

			attribute.text = longDate(prePolicyHistoryDate);
			attribute.header = '18 Months Prior to the inception date';

			if (pet.purchaseAdoptionDate) {
				if (isDateAfter(pet.purchaseAdoptionDate, prePolicyHistoryDate)) {
					attribute.text = longDate(pet.purchaseAdoptionDate) + ' - DOA';
					attribute.header = 'Pre-Policy History Start Date';
				}
			} else if (isDateAfter(pet.birthDate, prePolicyHistoryDate)) {
				attribute.text = longDate(pet.birthDate) + ' - DOB';
				attribute.header = 'Pre-Policy History Start Date';
			}

			return attribute;
		},
		getInsurancePolicyInceptionDate (pet) {
			const orderedPolicyTerms = [...pet.policyTerms].sort(sortComparer('policyInceptionDate', SortingDirection.Ascending));
			const policy = orderedPolicyTerms.find(term => term.product.type === ProductType.Insurance);

			return policy?.policyInceptionDate;
		},
	},
};
</script>
