<template>
	<div>
		<aq-time-line
			v-if="fullPeriod"
			:full-period="fullPeriod"
			:periods="treatmentPeriods"
			class="mx-50"
		/>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import { areDatesEqual, addDays } from '@commonServices/utils/dateUtils';

export default {
	name: 'AqProcessTimeline',
	props: {
		periods: {
			type: Array,
			required: true,
		},
		selectedPeriod: {
			type: [Object],
			required: false,
			default: null,
		},
	},
	data () {
		return {
			treatmentPeriods: [],
			fullPeriod: null,
		};
	},
	async mounted () {
		const claimId = this.$route.params.claimId;
		const policyDates = await ClaimService.getPolicyDates(claimId);

		this.fullPeriod = {
			startCoordinate: policyDates.treatmentStartDate.getTime(),
			endCoordinate: addDays(policyDates.treatmentEndDate, 1).getTime(),
		};
	},
	watch: {
		periods () {
			this.onTreatmentPeriodsUpdated();
		},
		selectedPeriod () {
			this.onTreatmentPeriodSelected();
		},
	},
	methods: {
		onTreatmentPeriodsUpdated () {
			this.treatmentPeriods = [];
			this.periods.forEach((item) => {
				let isSelected = false;

				if (this.selectedPeriod) {
					if (areDatesEqual(this.selectedPeriod.end, item.end)
						&& areDatesEqual(this.selectedPeriod.start, item.start)
						&& this.selectedPeriod.treatmentPeriodType === item.treatmentPeriodType) {
						isSelected = true;
					}
				}

				this.treatmentPeriods.push(
					{
						startCoordinate: item.start.getTime(),
						endCoordinate: addDays(item.end, 1).getTime(), // adding one day to match end and start coordinates of adjacent treatment periods
						isSelected,
						periodState: this.getPeriodState(item.treatmentPeriodType),
						start: item.start,
						end: item.end,
						periodLabel: 'Treatment Period',
						startLabel: 'Treatment Period Start',
						endLabel: 'Treatment Period End',
					},
				);
			});
		},
		onTreatmentPeriodSelected () {
			this.treatmentPeriods.forEach((item) => {
				item.isSelected = false;
			});

			if (this.selectedPeriod) {
				const period = this.treatmentPeriods.find((period) => areDatesEqual(period.start, this.selectedPeriod.start));
				if (period) {
					period.isSelected = true;
				}
			}
		},
		getPeriodState (periodType) {
			switch (periodType) {
			case 1: return 'active';
			case 2: return 'danger';
			case 3: return 'warning';
			default: return 'default';
			}
		},
	},
};
</script>
