<template>
	<div class="policy-sections-details">
		<div
			v-for="section of policySectionsDetails"
			:key="section.id"
			class="pt-4"
		>
			<aq-sandwich
				:header="section.description"
				active-class=""
				header-class="ps-sub-header"
				section-header="ps-header"
				right-caret
			>
				<template #content>
					<el-table
						v-if="section.allDeductions.length > 0"
						:data="section.allDeductions"
						data-qa="productDetailsModal_table_deductions"
						empty-text="No Cover"
						header-row-class-name="table-deduction-header"
						row-class-name="table-deduction-row"
						class="table-deduction"
					>
						<el-table-column
							data-qa="productDetailsModal_column_condition"
							class-name="font-weight-bold"
						>
							<template #default="scope">
								<div
									class="text-truncate"
									v-tooltip="scope.row.condition"
								>
									{{ scope.row.condition }}
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Limit"
							data-qa="productDetailsModal_column_limit"
						>
							<template #default="scope">
								<div
									class="text-truncate"
									v-tooltip="scope.row.limit"
								>
									{{ scope.row.limit || 'N/A' }}
								</div>
							</template>
						</el-table-column>
						<el-table-column
							:label="uiLocaleSettings.deductibleLabel"
							data-qa="productDetailsModal_column_deductible"
						>
							<template #default="scope">
								<div
									class="text-truncate"
									v-tooltip="scope.row.deductible"
								>
									{{ scope.row.deductible || 'N/A' }}
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Coverage %"
							class-name="text-left"
							data-qa="productDetailsModal_column_coverage"
						>
							<template #default="scope">
								<div
									class="text-truncate"
									v-tooltip="scope.row.coverage"
								>
									{{ scope.row.coverage || 'N/A' }}
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Section % Cover"
							class-name="text-left"
							data-qa="productDetailsModal_column_partialCoverage"
						>
							<template #default="scope">
								<div
									class="text-truncate"
									v-tooltip="scope.row.partialCoverage"
								>
									{{ scope.row.partialCoverage || 'N/A' }}
								</div>
							</template>
						</el-table-column>
						<el-table-column
							label="Non Financial Limit"
							class-name="text-left"
							data-qa="productDetailsModal_column_NonFinancialLimit"
						>
							<template #default="scope">
								<div
									class="text-truncate"
									v-tooltip="scope.row.nonFinancialLimit"
								>
									{{ scope.row.nonFinancialLimit || 'N/A' }}
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div
						v-else
						class="text-center no-data-message"
					>
						No Cover
					</div>
				</template>
				<template #right-caret="slotProps">
					<div class="icon">
						<i :class="['fas', slotProps.isActive ? 'fa-minus-circle' : 'fa-plus-circle']" />
					</div>
				</template>
			</aq-sandwich>
		</div>
	</div>
</template>

<script>

import { toPolicySections } from '@commonServices/deductionService';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';

export default {
	name: 'PolicySectionsDetails',
	components: {
	},
	props: {
		policySections: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	computed: {
		policySectionsDetails () {
			return toPolicySections(this.policySections, this.locale, this.displayCoInsurancePayout);
		},
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
		displayCoInsurancePayout () {
			return this.$store.getters.displayCoInsurancePayout;
		},
	},
};
</script>

<style lang="scss" scoped>
  ::v-deep .ps-header {
    padding: 10px 20px;
    background: var(--tableGroupBg);
    display: flex;

    .ps-sub-header {
      font-size: 14px;
      color: var(--bodyColour) !important;
    }
  }

  ::v-deep .table-deduction {
    width: 100%;

    div {
      table {
        border-spacing: 0 6px !important;
      }
    }
  }

  ::v-deep .table-deduction-header {
    background: var(--tableGroupBg);

    th > div.cell {
      font-size: 14px !important;
      padding: 10px;
      font-weight: bold;
    }
  }

  ::v-deep .table-deduction-row {
    background: var(--attributeBg);

    td > div.cell {
      font-size: 14px !important;
      padding: 10px;
    }
  }

  .no-data-message {
    padding: 10px 0;
    margin-top: 6px;
    background: var(--attributeBg);
  }

  .icon {
    color: $body-color;
    margin-bottom: 6px;
    cursor: pointer;
  }
</style>
