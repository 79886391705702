<template>
	<div :class="[isActive ? activeContainerClass : '']">
		<div
			:class="[isActive ? activeClass : '', sectionHeader]"
		>
			<div
				v-if="leftCaret"
				@click="onToggle"
			>
				<slot
					name="left-caret"
				/>
			</div>
			<div
				v-if="header"
				class="flex-grow-1"
			>
				<div
					class="section-header__title"
					:class="headerClass"
				>
					{{ header }}
				</div>
				<p :class="subHeaderClass">
					{{ subHeader }}
				</p>
			</div>
			<slot
				v-else
				name="header"
			/>
			<div
				v-if="rightCaret"
				@click="onToggle"
			>
				<slot
					:is-active="isActive"
					name="right-caret"
				>
					<div class="icon mb-6 cursor-pointer">
						<i :class="['fas', isActive ? 'fa-chevron-down' : 'fa-chevron-right']" />
					</div>
				</slot>
			</div>
			<div
				v-if="!leftCaret && !rightCaret"
				class="caret pr-20 cursor-pointer"
				@click="onToggle()"
			>
				<span
					class="text-primary"
					v-if="!isActive"
				>
					&#9654;
				</span>
				<span v-else>
					&#9660;
				</span>
			</div>
		</div>
		<div
			class="aq-sandwich-content"
			:class="contentClass"
			v-if="isActive"
		>
			<slot name="content" />
		</div>
		<div
			class="aq-sandwich-content"
			:class="contentClass"
			v-else
		>
			<slot name="inactive-content" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'AqSandwich',
	props: {
		header: {
			type: String,
			required: false,
		},
		subHeader: {
			type: String,
			required: false,
		},
		headerClass: {
			type: String,
			required: false,
			default: 'aq-sandwich-header',
		},
		subHeaderClass: {
			type: String,
			required: false,
			default: 'aq-sandwich-sub-header',
		},
		activeContainerClass: {
			type: String,
			required: false,
		},
		contentClass: {
			type: String,
			required: false,
		},
		activeClass: {
			type: String,
			required: false,
			default: 'aq-sandwich--active',
		},
		isActiveOnStart: {
			type: Boolean,
			required: false,
			default: false,
		},
		leftCaret: {
			type: Boolean,
			required: false,
			default: false,
		},
		rightCaret: {
			type: Boolean,
			required: false,
			default: false,
		},
		sectionHeader: {
			type: String,
			required: false,
			default: 'section-header',
		},
	},
	data () {
		return {
			isActive: this.isActiveOnStart,
		};
	},
	methods: {
		onToggle () {
			this.isActive = !this.isActive;
			this.$emit('change-state', this.isActive);
		},
		onExpand () {
			this.isActive = true;
			this.$emit('change-state', this.isActive);
		},
		onCollapse () {
			this.isActive = false;
			this.$emit('change-state', this.isActive);
		},
	},
};
</script>

<style lang="scss" scoped>
  .aq-sandwich-header {
    font-size: 16px;
    font-weight: bold;
  }

  .aq-sandwich-sub-header {
    font-size: 13px;
    color: $body-color;
  }

  .aq-sandwich {
    cursor: pointer;
  }

  .caret {
    color: $primary;
    cursor: pointer;
  }

  .aq-sandwich--active {
    background-color: $primary;

    .caret {
      color: $body-color;
    }
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
</style>
