<template>
	<aq-highlight-border-with-icon
		:highlight-color="invalid ? 'danger' : null"
		icon="fa-exclamation-triangle"
	>
		<aq-read-only-input
			label="Invoice number"
			:description="uniqueInvoiceNumbers"
			:disabled="disabled"
		/>
		<template slot="tooltipContent">
			{{ iconTooltip }}
		</template>
	</aq-highlight-border-with-icon>
</template>

<script>
import AqReadOnlyInput from '@commonView/Shared/AqReadOnlyInput';
import { unique } from '@commonServices/utils/general';
import AqHighlightBorderWithIcon from '@commonWidgets/AqHighlightBorderWithIcon';

export default {
	name: 'InvoiceNumberField',
	components: {
		AqReadOnlyInput, AqHighlightBorderWithIcon,
	},
	props: {
		invoiceNumbers: {
			type: Array,
			required: true,
		},
		invalid: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		iconTooltip: {
			type: String,
			required: false,
			default: '',
		},
	},
	computed: {
		uniqueInvoiceNumbers () {
			const invoiceNumbers = this.invoiceNumbers.filter(n => n);
			return unique(invoiceNumbers).join(', ') || ' ';
		},
	},
};
</script>
