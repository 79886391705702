<!-- Usage:
		<aq-checkbox class="[appearance: default|round] [size: default|medium |large] [tick: default|thin|fill|tick]" v-model="model" ></aq-checkbox>
		e.g. <aq-checkbox class="round large thin" v-model="model" ></aq-checkbox>
			<aq-checkbox v-model="included" ></aq-checkbox> // with default styles
 -->
<template>
	<div :class="['checkbox', { disabled }]">
		<input
			type="checkbox"
			:checked="value"
			@change="$emit('input', $event.target.checked)"
			:disabled="disabled"
		>
		<label class="label">
			{{ label }}
		</label>
	</div>
</template>

<script>
export default {
	name: 'AqCheckbox',
	props: {
		value: {
			type: Boolean,
		},
		label: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
		},
	},
};
</script>

<style lang="scss" scoped>
.checkbox {
  --size: 16px;

  &.medium {
    --size: 22px;
  }

  &.large {
    --size: 28px;
  }

  position: relative;
  display: inline-block;
  white-space: nowrap;
  line-height: var(--size);

  input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  label {
    position: initial;
    display: inline-block;
    font-weight: 400;
    margin: 0;
    text-indent: calc(var(--size) + 5px);
    min-width: var(--size);
    line-height: var(--size);

    &::before,
    &::after {
      content: "";
      width: var(--size);
      height: var(--size);
      display: block;
      box-sizing: border-box;
      border-radius: 20%;
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
    }

    &::before {
      background-color: transparent;
      border: 1px solid var(--cardIconColour);
    }

    &::after {
      transform: scale(0.5);
      background-color: transparent;
    }
  }

  input:checked + label::after {
    background-color: var(--cardIconColour);
  }

  &.round {
    label {
      &::before,
      &::after {
        border-radius: 100%;
      }
    }
  }

  &.fill {
    label::after {
      transform: scale(1);
    }
  }

  &.thin {
    label::after {
      transform: scale(0.3);
    }
  }

  &.tick {
    input:checked + label::after {
      content: "✔";
      color: var(--cardIconColour);
      transform: translate(-110%, 3%);
      font-size: 12px;
      background-color: transparent;
    }

    &.medium {
      input:checked + label::after {
        font-size: 18px;
        transform: translate(-107%, 3%);
      }
    }

    &.large {
      input:checked + label::after {
        font-size: 23px;
        transform: translate(-100%, 1%);
      }
    }
  }

  &.disabled {
    input {
      cursor: default;
    }

    input:checked + label::after {
      background-color: var(--disabledText);
    }
  }
}
</style>
