export default class EligibleConditionView {
	constructor (
		id,
		condition,
		bodyPart,
		eligibleConditionType,
		eligibleConditionTypeDisplay,
		assessmentDate,
		createdBy,
		createdDate,
		updatedBy,
		updatedDate,
		comments,
		relatedConditions,
		archived,
		hasRejectedClaims,
		vet,
	) {
		this.id = id;
		this.condition = condition;
		this.bodyPart = bodyPart;
		this.eligibleConditionType = eligibleConditionType;
		this.eligibleConditionTypeDisplay = eligibleConditionTypeDisplay;
		this.assessmentDate = assessmentDate;
		this.createdBy = createdBy;
		this.createdDate = createdDate;
		this.updatedBy = updatedBy;
		this.updatedDate = updatedDate;
		this.comments = comments;
		this.relatedConditions = relatedConditions;
		this.archived = archived;
		this.hasRejectedClaims = hasRejectedClaims;
		this.vet = vet;
	}
};
