const CurrencyCodesEnum = {
	USD: 'USD',
	CAD: 'CAD',
	AUD: 'AUD',
};

const CountryCurrencyCodesMap = Object.freeze({
	USA: CurrencyCodesEnum.USD,
	CAN: CurrencyCodesEnum.CAD,
	AUS: CurrencyCodesEnum.AUD,
});

export { CurrencyCodesEnum, CountryCurrencyCodesMap };
