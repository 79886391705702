<template>
	<vue-pdf-embed
		ref="vuePdf"
		:page="page"
		:source="src"
		:disable-text-layer="true"
		:disable-annotation-layer="true"
		:scale="1"
		@rendered="rendered"
	/>
</template>

<script>

export default {
	name: 'AqPdfViewer',
	components: {
		'vue-pdf-embed': () => import('vue-pdf-embed/dist/vue2-pdf-embed'),
	},
	props: {
		src: {
			type: String,
			required: true,
		},
		page: {
			type: Number,
			required: false,
			default: 1,
		},
	},
	methods: {
		rendered () {
			const pageCount = this.$refs.vuePdf.pageCount;
			this.$emit('num-pages', pageCount);
		},
	},
};
</script>
