import httpClient from './httpClient';
import { toDocumentScanResult, convertScanResultDates } from '@commonServices/utils/documentScanConverter';

const recognitionPath = '/api/machine-learning/recognition';
export default {
	scanDocument (fileId) {
		return httpClient(`${recognitionPath}/${fileId}`)
			.post()
			.then(toDocumentScanResult);
	},
	openDocument (fileId) {
		return httpClient(`${recognitionPath}/${fileId}/result`)
			.get()
			.then(toDocumentScanResult);
	},
	saveDocumentScan (fileId, data) {
		return httpClient(`${recognitionPath}/${fileId}/result`)
			.put(convertScanResultDates(data));
	},
};
