const ClaimFormType = Object.freeze({
	Standard: 0,
	PreAuthorisation: 1,
	OtherLosses: 2,
});

const ClaimFormTypeDisplayMap = Object.freeze({
	[ClaimFormType.Standard]: 'Standard',
	[ClaimFormType.PreAuthorisation]: 'Pre-Authorisation',
	[ClaimFormType.OtherLosses]: 'Other Losses',
});

const ClaimFormTypeLabels = {
	[ClaimFormType.Standard]: {
		diagnosis: 'Diagnosis',
		ailment: 'Ailment',
		treatmentStart: 'Treatment From',
		treatmentEnd: 'Treatment To',
		bottomSectionDetails: 'Vet Details',
	},
	[ClaimFormType.PreAuthorisation]: {
		diagnosis: 'Diagnosis',
		ailment: 'Ailment',
		treatmentStart: 'Treatment From',
		treatmentEnd: 'Treatment To',
		bottomSectionDetails: 'Vet Details',
	},
	[ClaimFormType.OtherLosses]: {
		diagnosis: 'Loss Type',
		ailment: 'Loss Type',
		treatmentStart: 'Start Date',
		treatmentEnd: 'End Date',
		bottomSectionDetails: 'Loss Types',
	},
};

const ClaimFormTypeOptions = Object.freeze(Object.entries(ClaimFormTypeDisplayMap).map(([id, description]) => ({ id: parseInt(id), description })));

export { ClaimFormType, ClaimFormTypeDisplayMap, ClaimFormTypeOptions, ClaimFormTypeLabels };
