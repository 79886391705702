<template>
	<div>
		{{ value ? 'Yes' : 'No' }}
	</div>
</template>

<script>

export default {
	name: 'BooleanCell',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
