<template>
	<div>
		<div class="header text-white py-14">
			<strong>{{ title }}</strong>
		</div>
		<div class="breadcrumb-header py-20">
			<div class="total font-weight-light">
				{{ value }}
			</div>
			<div class="text-small pt-10">
				{{ valueText }}
			</div>
		</div>
		<div class="section-footer px-20">
			<div class="d-flex align-items-center">
				<div class="text-small text-white">
					{{ footerText }}
				</div>
				<div class="ml-auto">
					<button
						v-if="!isOpen"
						@click="$emit('toggle-table', true)"
						class="d-block btn btn-primary"
					>
						View
					</button>
					<button
						v-else
						@click="$emit('toggle-table', false)"
						class="d-block btn btn-warning"
					>
						Close
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'TaskCounter',
	props: {
		title: {
			type: String,
			required: true,
		},
		value: {
			type: Number,
			required: true,
		},
		valueText: {
			type: String,
			required: true,
		},
		footerText: {
			type: String,
			required: true,
		},
		isOpen: {
			type: Boolean,
			required: true,
		},
		status: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
  .total {
    font-size: 60px;
    line-height: 50px;
  }
</style>
