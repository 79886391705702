import { Filter } from '@commonServices/models/FilterType';

const TaskPageCommonFilters = [
	Filter.Brand,
	Filter.Product,
	Filter.Breed,
	Filter.Species,
	Filter.ClaimCreatedDate,
	Filter.Ailment,
	Filter.FormType,
	Filter.County,
	Filter.CareFlag,
	Filter.Vet,
	Filter.ClaimType,
	Filter.DeceasedPet,
	Filter.PolicySections,
	Filter.ClaimAmount,
	Filter.Escalated,
	Filter.EscalationType,
	Filter.LanguagePreference,
];

const TaskPageDraftFilters = [
	...TaskPageCommonFilters,
	Filter.Country,
	Filter.ConditionType,
	Filter.ClaimCreatedBy,
	Filter.FastTrackReviewed,
	Filter.DayCountToTreatmentStart,
	Filter.ReassessmentReason,
	Filter.MissingInfoCompleted,
	Filter.ClaimAssignedTo,
	Filter.IsReassessment,
	Filter.DaysOpen,
	Filter.PaymentRecipient,
];

const TaskPageMissingInfoFilters = [
	...TaskPageCommonFilters,
	Filter.MissingInfoRequestedDate,
	Filter.MissingItem,
	Filter.ChaseCount,
	Filter.RequestedBy,
];

const TaskPageFraudFilters = [
	...TaskPageCommonFilters,
	Filter.FraudCheckRequestedDate,
	Filter.FraudFlags,
];

const TaskPagePaymentApprovalFilters = [
	...TaskPageCommonFilters,
	Filter.PaymentAmount,
	Filter.ExGratiaAmount,
	Filter.RequestedBy,
	Filter.SettledReason,
	Filter.IsReassessment,
];

const TaskPageReassessmentPendingFilters = [
	...TaskPageCommonFilters,
	Filter.ReassessmentRequestedDate,
];

const TaskPageRejectionApprovalFilters = [
	...TaskPageCommonFilters,
	Filter.RejectionReasons,
	Filter.Rejection,
	Filter.IsReassessment,
];

export {
	TaskPageDraftFilters, TaskPageMissingInfoFilters, TaskPageFraudFilters, TaskPagePaymentApprovalFilters, TaskPageReassessmentPendingFilters,
	TaskPageRejectionApprovalFilters,
};
