const CommunicationPreferenceEnum = Object.freeze({
	Paper: 1,
	Email: 2,
	Eletronic: 3,
});

const CommunicationPreferenceEnumMap = Object.freeze({
	[CommunicationPreferenceEnum.Paper]: 'Paper',
	[CommunicationPreferenceEnum.Email]: 'Email',
	[CommunicationPreferenceEnum.Eletronic]: 'Eletronic',
});

export { CommunicationPreferenceEnum, CommunicationPreferenceEnumMap };
