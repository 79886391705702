<template>
	<div
		class="control-container"
		:class="{
			'focused' : isFocus,
			'default': !isFocus && value == null && label && !disabled,
			'highlight': highlightInput,
			'disabled': disabled,
			'pt-22': type==='textarea' && label
		}"
	>
		<div
			class="label"
			:class="labelClass"
			v-if="label"
		>
			{{ label }}
		</div>
		<div
			class="d-flex align-items-end pb-6 pt-4 input-border"
			:class="[isValid ? 'control-border': 'danger-border', { 'disabled-border pr-30' : disabled }]"
		>
			<aq-date-picker
				class="flex-fill"
				v-if="type==='date'"
				:value="value"
				@input="updateValue"
				:disabled="disabled"
				:icon-class="iconClass"
				:is-valid="isValid"
				@focus="focus"
				:highlighted="highlighted"
				:relative-element="$el"
			/>

			<template v-else-if="type==='money'">
				<aq-money-input
					class="flex-fill"
					@input="updateValue"
					@focus="focus"
					:value="value"
					:placeholder="placeholder"
					:disabled="disabled"
					:input-class="`${inputClass} aq-form-input d-block w-100`"
					:class="{ 'input-disabled' : disabled }"
				/>
				<div class="icon-wrapper">
					<i
						class="control-icon"
						:class="[iconCurrencyClass, iconClass, { 'text-danger' : !isValid}, { 'input-disabled' : disabled } ]"
					/>
				</div>
			</template>

			<template v-else-if="type==='textarea'">
				<div
					class="flex-fill"
					:class="{ 'input-disabled' : disabled }"
				>
					<textarea
						@input="updateValue($event.target.value)"
						@focus="focus(true)"
						@blur="focus(false)"
						:value="value"
						:class="[{ 'lock-resize' : lockResize }, { 'pt-0': !!label }, `${inputClass} aq-form-input d-block w-100` ]"
						:disabled="disabled"
						:placeholder="placeholder"
						:rows="numberVisibleLines"
						ref="textarea"
					/>
					<!-- ref textarea is used to focus on element outside -->
				</div>
			</template>

			<template v-else>
				<aq-input
					class="flex-fill"
					@input="updateValue"
					@focus="focus"
					:value="value"
					:placeholder="placeholder"
					:disabled="disabled"
					:input-class="`${inputClass} aq-form-input d-block w-100`"
					:class="{ 'input-disabled' : disabled }"
					:type="type"
				/>
				<i
					v-if="showClearBtn && value"
					class="fas fa-times"
					@click="updateValue(null)"
				/>
			</template>
			<div
				class="locked"
				v-if="disabled"
			>
				<i class="fas fa-lock" />
			</div>
		</div>
	</div>
</template>

<script>
import { getCurrencyByLocale } from '@commonServices/settings/localeSettings';
import AqDatePicker from '@commonWidgets/AqDatePicker';

const FontAwesomeCurrencyMap = {
	'$': 'fa-dollar-sign', // eslint-disable-line
	'£': 'fa-pound-sign',
};

export default {
	components: {
		AqDatePicker,
	},
	data () {
		return {
			isFocus: false,
		};
	},
	computed: {
		labelClass () {
			const classes = [];

			if (this.disabled && this.value == null) {
				classes.push('disabled-text');
			} else {
				if (!this.isValid) classes.push('text-danger');
			}

			return classes;
		},
		iconCurrencyClass () {
			const currency = getCurrencyByLocale(this.locale);
			return `cursor-default fas ${FontAwesomeCurrencyMap[currency.symbol]}`;
		},
	},
	methods: {
		focus (bool) {
			this.isFocus = bool;
			if (bool) {
				this.$emit('focused');
			} else {
				this.$emit('focusout');
			}
		},
		updateValue (value) {
			if (this.type === 'number') {
				const parsedNumber = parseFloat(value);
				this.$emit('input', parsedNumber === 0 ? parsedNumber : parsedNumber || null);
			} else {
				this.$emit('input', value);
			}
		},
	},
	props: {
		label: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: [String, Number, Date],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		iconClass: {
			type: String,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inputClass: {
			type: String,
			default: '',
		},
		highlighted: {
			type: Object,
			default: function () {
				return {
					dates: [
						new Date(),
					],
				};
			},
		},
		highlightInput: {
			type: Boolean,
			required: false,
		},
		numberVisibleLines: {
			type: Number,
			required: false,
			default: 5,
		},
		showClearBtn: {
			type: Boolean,
			required: false,
			default: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		lockResize: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  line-height: normal;
}

.disabled-text {
  color: $gray;
  top: 25px;
  font-size: 14px !important;
}

::v-deep .aq-form-input,
::v-deep .date-picker-input {
  background-color: inherit;
  font-size: 14px;
  border: none;
  height: 100%;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 0;

  &:focus {
    outline: none;
  }
}

textarea.lock-resize {
  resize: none;
}
</style>
