<template>
	<div class="flex-fill">
		<div>
			<aq-scan-form-select
				label="Loss Type"
				:value="searchValue"
				:class="[{ 'bg-danger-darken': false },'attribute']"
				@search-async="searchLossType"
				@focused="$emit('focused', recognizedLossType)"
				:disabled="isReadonly"
			/>
			<div v-if="showLossTypes">
				<div v-if="lossTypes.length > 0">
					<div class="row no-gutters mt-5 overflow-scroll">
						<div
							v-for="(lossType, index) in lossTypes"
							:key="index"
							@click="selectLossType(lossType)"
							class="search-item mb-10 px-25 py-14"
							:class="selectedLossType && selectedLossType.id === lossType.id ? 'selected' : ''"
						>
							<div class="cause pb-4">
								{{ lossType.firstCause }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
	name: 'ClaimLossTypeScan',
	data () {
		return {
			selectedLossType: null,
			searchValue: null,
			showLossTypes: false,
		};
	},
	props: {
		recognizedLossType: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		...mapState(['lossTypes']),
	},
	mounted () {
		this.loadLossTypes();
		if (this.recognizedLossType) {
			this.searchValue = this.recognizedLossType.value ?? this.recognizedLossType.originalValue;
		}

		this.searchLossType(this.searchValue);
		if (this.recognizedLossType.id) {
			this.selectLossType(this.lossTypes.find(lossType => lossType.id === this.recognizedLossType.id));
		} else {
			this.selectLossType(this.lossTypes.find(lossType => lossType.firstCause === this.searchValue));
		}
	},
	methods: {
		...mapActions(['loadLossTypes']),
		searchLossType (term) {
			this.searchValue = term;
			if (term) {
				this.showLossTypes = true;
			}
		},
		selectLossType (lossType) {
			if (this.isReadonly || !lossType) {
				return;
			}

			if (this.selectedLossType?.id === lossType?.id) {
				this.selectedLossType = null;
			} else {
				this.showLossTypes = false;
				this.selectedLossType = lossType;
				this.searchValue = lossType.firstCause;
			}

			this.$emit('loss-type-selected', { ...this.selectedLossType });
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-danger-darken {
  color: $body-color;
}

.search-item {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease;

  &:last-child {
    border: none;
  }

  &:hover,
  &.selected {
    p {
      color: $body-color;
    }
  }

  &:hover {
    background: $primary;
  }

  &.selected {
    background: $active-color;
  }
}

.overflow-scroll {
  overflow-y: scroll;
  max-height: 300px;
}
</style>
