import { MediaTypeMap } from '@commonServices/utils/general';

function generateDocument (client, exportModel, path) {
	return client(path, { config: { responseType: 'blob' } })
		.post(exportModel)
		.then((data) => new Blob([data], { type: MediaTypeMap[exportModel.exportType] }));
}

export default {
	async exportDocument (client, exportModel, fileName, path) {
		const link = window.document.createElement('a');
		const fileBlob = await generateDocument(client, exportModel, path);
		link.href = window.URL.createObjectURL(fileBlob);
		link.download = fileName;
		link.click();
	},
};
