<template>
	<div
		class="d-flex justify-content-center"
		v-tooltip="{
			content: tooltip,
			offset: 10,
		}"
	>
		<i :class="iconClass" />
	</div>
</template>

<script>

export default {
	name: 'IconCell',
	props: {
		iconClass: {
			type: String,
			default: null,
		},
		tooltip: {
			type: String,
			default: null,
		},
	},
};
</script>
