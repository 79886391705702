<template>
	<div>
		<aq-shifter
			v-if="policyYears"
			:items="policyYears"
			:initial-index="currentPolicyYearIndex"
			@change="onUpdatePolicyYear"
			class="pb-8"
		/>
		<div class="d-flex rounded-2">
			<PolicyTermProgressBar
				v-for="policyTerm of currentPolicyTerms"
				:key="policyTerm.policyTermId"
				:policy-term="policyTerm"
				:style="getPolicyTermStyle(policyTerm)"
				class="progress-bar rounded-2 no-animation"
				:class="isCurrentPolicyTerm(policyTerm) ? 'policy-term-selected' : 'policy-term'"
				:selected="isCurrentPolicyTerm(policyTerm)"
				@click.native="onPolicyTermClick(policyTerm)"
				data-qa="policyTermShifter_button_termProgress"
			/>
		</div>
	</div>
</template>

<script>
import groupBy from 'lodash.groupby';
import PetService from '@commonServices/petService';
import { policyprogressPercentage, progressColor } from '@commonView/CustomerPage/policyUtility';
import { isFutureDate, diffInDays } from '@commonServices/utils/dateUtils';
import { longDate } from '@commonServices/utils/filters';
import PolicyTermProgressBar from '@commonView/Shared/PolicyTermProgressBar';

export default {
	name: 'PolicyTermShifter',
	components: {
		PolicyTermProgressBar,
	},
	props: {
		policyTerms: {
			type: Array,
			required: true,
		},
		currentPolicyTerm: {
			type: Object,
			required: false,
		},
	},
	data () {
		return {
			currentPolicyYearIndex: 0,
		};
	},
	mounted () {
		this.initPolicyYear();
	},
	computed: {
		policyYears () {
			const groupedByYear = groupBy(this.policyTerms, 'yearEndDate'); // yearStartDate can be used equally, for proper setup it does not matter
			const result = Object.entries(groupedByYear).map(([key, items]) => {
				const yearStartDate = items[0].policyTermStartDate; // using first policy term start date as yearStartDate to display
				const yearEndDate = items[items.length - 1].policyTermEndDate; // using last policy term endDate as yearEndDate to display, because policy can be cancelled, and part of the year will become not convered by any policy term
				return {
					policyTerms: items,
					yearEndDate,
					yearStartDate,
					content: `${longDate(yearStartDate)} - ${longDate(yearEndDate)}`,
				};
			});

			return result;
		},
		currentPolicyYear () {
			return this.policyYears[this.currentPolicyYearIndex];
		},
		currentPolicyTerms () {
			return this.currentPolicyYear.policyTerms.map(term => ({
				...term,
				dayCount: diffInDays(term.policyTermEndDate, term.policyTermStartDate) + 1,
				progress: isFutureDate(term.policyTermStartDate) ? 0 : policyprogressPercentage(term.policyTermStartDate, term.policyTermEndDate),
				progressColor: progressColor(term.policyTermEndDate),
			}));
		},
	},
	watch: {
		policyTerms () {
			this.initPolicyYear();
		},
	},
	methods: {
		initPolicyYear () {
			const now = this.currentPolicyTerm?.policyTermStartDate ?? new Date();
			const currentPolicyYearIndex = this.policyYears.findLastIndex(year => year.yearStartDate <= now && now <= year.yearEndDate);

			this.onUpdatePolicyYear(currentPolicyYearIndex === -1 ? (this.policyYears.length - 1) : currentPolicyYearIndex);
		},
		onUpdatePolicyYear (index) {
			this.currentPolicyYearIndex = index;
			this.$emit('update-policy-term', this.getPolicyTermIndex(PetService.getCurrentPetPolicyTerm(this.currentPolicyTerms)));
		},
		getPolicyTermIndex (policyTerm) {
			return this.policyTerms.findIndex(term => term.policyTermId === policyTerm.policyTermId);
		},
		isCurrentPolicyTerm (policyTerm) {
			return !!policyTerm && this.currentPolicyTerm?.policyTermId === policyTerm.policyTermId;
		},
		onPolicyTermClick (policyTerm) {
			if (!this.isCurrentPolicyTerm(policyTerm)) {
				this.$emit('update-policy-term', this.getPolicyTermIndex(policyTerm));
			}
		},
		getPolicyTermStyle (policyTerm) {
			const isFirstTerm = this.currentPolicyTerms[0].policyTermId === policyTerm.policyTermId;
			const splitterWidth = isFirstTerm ? '0px' : '2px';

			return {
				width: '50px',
				marginLeft: splitterWidth,
				flexGrow: policyTerm.dayCount,
			};
		},
	},
};
</script>

<style lang='scss' scoped>
::v-deep .no-animation {
  .animate {
    transition: none;
    animation: none;
  }
}
</style>
