<template>
	<div>
		<div
			v-for="(diagnosisOccurrence, key) of condition.diagnosisOccurrences"
			:key="key"
		>
			<p class="fs-13">
				{{ buildDiagnosisOcurrenceText(diagnosisOccurrence) }}
			</p>
		</div>
	</div>
</template>

<script>
import { shortDate } from '@commonServices/utils/filters';

export default {
	props: {
		condition: {
			type: Object,
			required: true,
		},
	},
	methods: {
		buildDiagnosisOcurrenceText (diagnosisOccurrence) {
			let diagnosisOcurrenceText = `- ${shortDate(diagnosisOccurrence.date)}: ${diagnosisOccurrence.vet ? diagnosisOccurrence.vet.practiceName : 'Vet Unknown'}`;
			if (diagnosisOccurrence.pageNumber) {
				diagnosisOcurrenceText += `, Page: ${diagnosisOccurrence.pageNumber}`;
			}
			return diagnosisOcurrenceText;
		},
	},
};
</script>
