<template>
	<div>
		<task-counter
			v-for="(taskCounter, index) of taskCounters"
			:key="index"
			:title="taskCounter.title"
			:value="taskCounter.value"
			:value-text="taskCounter.valueText"
			:footer-text="taskCounter.footerText"
			:is-open="taskCounter === currentTaskCounter"
			:status="taskCounter.status"
			@toggle-table="onToggleTable($event, taskCounter)"
			:class="getCounterClass"
		/>
	</div>
</template>

<script>
export default {
	props: {
		taskCounters: {
			type: Array,
			required: true,
		},
		currentTaskCounter: {
			type: Object,
			required: false,
			default: null,
		},
	},
	methods: {
		onToggleTable (isOpen, taskCounter) {
			if (isOpen) {
				this.$emit('task-counter-toggled', taskCounter);
			} else {
				this.$emit('task-counter-toggled', null);
			}
		},
	},
	computed: {
		getCounterClass  () {
			return this.taskCounters.length < 5 ? 'col-3' : 'col';
		},
	},
};
</script>
