const ClaimConditionState = Object.freeze({
	Proceed: 1,
	RejectedManually: 2,
	RejectedWaitingPeriod: 3,
	RejectedByPreExistingCondition: 4,
	RejectedByRelatedCondition: 5,
	RejectedByNoProductCover: 6,
	RejectedByNoPolicyCover: 7,
});

export { ClaimConditionState };
