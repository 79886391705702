export default class ClaimGraphSegment {
	constructor (
		claimId,
		claimConditionId,
		netAmount,
		nonFinancialNumber,
	) {
		this.claimId = claimId;
		this.claimConditionId = claimConditionId;
		this.netAmount = netAmount;
		this.nonFinancialNumber = nonFinancialNumber;
	}
};
