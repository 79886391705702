<template>
	<div class="position-relative">
		<div class="header p-30">
			<div
				class="d-flex align-items-center"
				v-if="!isFiltersOpen"
			>
				<button
					class="btn btn-primary px-14"
					@click="onFiltersOpen"
					data-qa="reportsPage_button_filters"
				>
					<i class="fas fa-filter" />
					&nbsp;
					Filters
				</button>
			</div>
		</div>
		<zip-files-filters
			v-if="isFiltersOpen"
			:filter="filter"
			@apply-filters="onApplyFilters"
			@cancel="onCancel"
			class="position-absolute filters"
		/>
		<aq-search-term-container
			class="p-15"
			v-if="searchTerms.length"
			:search-terms="searchTerms"
			:filter="filter"
			@remove-search-term="onRemoveSearchTerm"
		/>
		<div class="section-content mx-25 mt-40 px-20 pb-10">
			<aq-tab-selector
				v-if="activeTab"
				:items="tabItems"
				:active-tab="activeTab"
				@select-tab="onSelectTab"
			>
				<zipped-letters-view
					class="section-content pb-0"
					:zipped-letters="zippedLetters"
					:start-index="rowStartIndex"
					:highlight-file-id="highlightZipFileId"
					@file-download="onFileDownload"
				/>
			</aq-tab-selector>
		</div>
		<div
			class="section-footer d-flex pl-45 mx-25"
			v-if="paging.rowCount"
		>
			<aq-page-control
				:total="paging.rowCount"
				:page-size="paging.pageSize"
				@page-changed="onPageChanged"
				class="ml-auto mr-20"
			/>
		</div>
	</div>
</template>
<script>
import LettersService from '@commonServices/lettersService';
import ZipFilesService from '@commonServices/zipFilesService';
import { toSearchTerms, toViewFilter } from '@commonServices/utils/lettersConverter';
import Paging from '@commonServices/models/Paging';
import ZippedLettersView from '@commonView/LettersPage/ZippedLettersView';
import ZipFilesFilters from '@commonView/LettersPage/ZipFilesFilters';
import AqSearchTermContainer from '@commonView/Shared/AqSearchTermContainer';
import { getDayEnd, getDayStart } from '@commonServices/utils/dateUtils';

export default {
	components: {
		ZippedLettersView,
		ZipFilesFilters,
		AqSearchTermContainer,
	},
	data () {
		return {
			isFiltersOpen: false,
			zippedLetters: [],
			filter: null,
			filters: [],
			searchTerms: [],
			paging: new Paging(),
			activeTab: null,
			tabItems: [
				{
					value: 'all',
					label: 'Pending',
					details: null,
				},
				{
					value: 'zipped',
					label: 'Zipped',
					details: null,
				},
			],
			highlightZipFileId: null,
		};
	},
	async mounted () {
		this.highlightZipFileId = this.$route.params.zipFileId;
		this.filter = toViewFilter({});
		this.activeTab = this.tabItems[1];
		await this.getPendingLettersCount();
		await this.loadData();
	},
	computed: {
		rowStartIndex () {
			return this.paging.rangeStart;
		},
	},
	methods: {
		onSelectTab (value) {
			this.$router.push({ name: 'letters' });
		},
		async onPageChanged (pageNumber) {
			this.paging.pageNumber = pageNumber;
			await this.loadData();
		},
		async getPendingLettersCount () {
			const pendingLettersCount = await LettersService.getPendingLettersCount();
			this.tabItems[0].details = `(${pendingLettersCount})`;
		},
		onFiltersOpen () {
			this.isFiltersOpen = true;
		},
		async onApplyFilters () {
			this.isFiltersOpen = false;
			this.paging.pageNumber = 1; // reset paging after filters applied
			this.searchTerms = toSearchTerms(this.filter);
			await this.loadData();
		},
		async loadData () {
			const { dateFrom, dateTo } = this.filter;
			const filtersPayload = {
				dateFrom: dateFrom.value ? getDayStart(dateFrom.value) : null,
				dateTo: dateTo.value ? getDayEnd(dateTo.value) : null,
			};

			const { items, ...paging } = await ZipFilesService.filterZippedFiles(
				filtersPayload,
				this.paging,
			);
			this.paging.rowCount = paging.rowCount;
			this.zippedLetters = items;
			this.activeTab.details = `(${this.paging.rowCount})`;
		},
		onCancel () {
			this.isFiltersOpen = false;
		},
		async onFileDownload ({ fileId, fileName }) {
			await ZipFilesService.downloadZipFile(fileId, fileName);
		},
		onRemoveSearchTerm () {
			this.onApplyFilters();
		},
	},
};
</script>

<style lang="scss" scoped>
  .filters {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
</style>
