<template>
	<v-popover
		auto-hide
		@auto-hide="toggled = false"
		popover-arrow-class="d-none"
		popover-base-class="d-none"
		class="grid"
	>
		<div
			class="vet-address untoggled rounded mb-20"
		>
			<RegisteredVet
				v-if="selectedVet.isRegisteredVet"
				class="registered-vet-icon"
			/>
			<div class="flex-grow-1">
				<div class="break-word">
					<strong>{{ selectedVet.practiceName }}</strong>
				</div>
				<div class="break-word">
					{{ addressLineDetails(selectedVet) }}
				</div>
			</div>
			<i
				v-if="vets.length > 1"
				@click="toggled = true"
				class="aqv-vet-history fs-26 toggler-icon"
				v-tooltip="description"
			/>
		</div>
		<div
			v-if="toggled"
			class="toggled-container"
		>
			<div class="toggled-header rounded d-flex p-15">
				<div class="w-100 fs-16 font-weight-bold align-self-center">
					{{ description }}
				</div>
				<i
					@click="toggled = false"
					class="fas fa-times fs-20 toggler-icon"
				/>
			</div>
			<div class="vet-addresses">
				<div
					v-for="(vet, index) of vets"
					@click="onVetSelected(vet)"
					:key="index"
					class="vet-address cursor-pointer toggled"
					:class="{ 'selected': selectedVet.id === vet.id}"
				>
					<RegisteredVet
						v-if="vet.isRegisteredVet"
						class="registered-vet-icon"
					/>
					<div class="w-100">
						<div>
							<strong>{{ vet.practiceName }}</strong>
						</div>
						<div class="address-details">
							{{ addressLineDetails(vet) }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</v-popover>
</template>

<script>
import { RegisteredVet } from '@assets/icons';
import { toFullAddress } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

export default {
	components: {
		RegisteredVet,
	},
	data () {
		return {
			toggled: false,
		};
	},
	props: {
		vets: {
			type: Array,
			required: true,
		},
		selectedVet: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState(['levelFilter']),
		description () {
			return this.levelFilter.petId ? 'Vet History' : 'Claim Vets';
		},
	},
	methods: {
		addressLineDetails (vet) {
			return toFullAddress(vet);
		},
		onVetSelected (vet) {
			this.toggled = false;
			this.$emit('update-vet', vet);
		},
	},
};
</script>

<style lang="scss" scoped>
.vet-address {
  display: flex;
  padding: 10px 15px;
  position: relative;
  background: var(--sectionFooterBg);
  color: var(--sectionTextColour);
  justify-content: space-between;

  .untoggled {
    &::after {
      content: "";
    }
  }

  &::after {
    position: absolute;
    left: calc(50% - 10px);
    top: 100%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--sectionFooterBg);
    clear: both;
  }
}

.toggler-icon {
  align-self: center;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.registered-vet-icon {
  align-self: center;
  fill: var(--sectionTextColour);
  min-width: 30px;
  height: 30px;
  padding: 0;
  margin-right: 10px;
}

.toggled-container {
  position: absolute;
  top: 0;
  width: 100%;

  .toggled-header {
    background: var(--sectionFooterBg);
    color: var(--sectionTextColour);
  }

  .vet-addresses {
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: var(--cardIconSecondaryColour);
    max-height: 210px;
    overflow: auto;

    .vet-address {
      min-height: 68px;
      align-items: center;

      .address-details {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; // stylelint-disable value-no-vendor-prefix -- 'box' is not supported
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &.selected {
        background: $success;

        &:hover {
          background: $danger !important;
        }
      }

      &.toggled {
        &:hover {
          background: $primary;
        }
      }
    }
  }

  .vet-addresses::before {
    content: "";
  }
}

.grid {
  display: grid;
}
</style>
