<template>
	<div class="col-12">
		<div class="d-flex">
			<div class="flex-grow-1">
				<aq-form-input
					data-qa="enterBasic_input_description"
					label="Description"
					v-model="validator.description.$model"
					:is-valid="!validator.description.$error"
					:disabled="isReadOnlyMode"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="validator"
					property="description"
				/>
			</div>
			<invoice-number-field
				v-if="invoiceData"
				:invoice-numbers="invoiceData.numbers"
				class="max-width-20 invoice-number"
				:class="{'readonly': isReadOnlyMode}"
				:invalid="invoiceData.invalid"
				icon-tooltip="Duplicate Invoice Check"
				:disabled="isReadOnlyMode"
			/>
		</div>
		<div class="w-100 mt-20" />
		<div class="d-flex w-100">
			<div class="date-input flex-grow-1">
				<aq-form-input
					data-qa="enterBasic_input_dateLoss"
					:label="dateOfLossLabel"
					type="date"
					v-model="validator.dateOfLoss.$model"
					:is-valid="!validator.dateOfLoss.$error"
					:disabled="isReadOnlyMode"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="validator"
					property="dateOfLoss"
				/>
			</div>
			<div class="date-input flex-grow-1">
				<aq-highlight-border-with-icon
					:highlight-color="dateOfDeathValid ? 'warning' : null"
					border-style="bold"
					icon="fa-exclamation"
					icon-size="small"
					icon-circle
					background-highlight
					shadow
				>
					<aq-form-input
						data-qa="enterBasic_input_dateDeath"
						label="Date of Death"
						type="date"
						v-model="validator.dateOfDeath.$model"
						:is-valid="!validator.dateOfDeath.$error"
						:highlighted="highlightedTreatmentStartDeath"
						:disabled="isReadOnlyMode"
					/>
					<template slot="tooltipContent">
						Date of Death Selected
					</template>
				</aq-highlight-border-with-icon>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="validator"
					property="dateOfDeath"
				/>
			</div>
			<div class="date-input flex-grow-1">
				<aq-form-input
					data-qa="enterBasic_input_treatmentFrom"
					:label="labels.treatmentStart"
					type="date"
					v-model="validator.treatmentStart.$model"
					:is-valid="!validator.treatmentStart.$error"
					:highlighted="highlightedTreatmentStartDeath"
					:disabled="isReadOnlyMode"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="validator"
					property="treatmentStart"
				/>
			</div>
			<div class="date-input flex-grow-1">
				<aq-form-input
					data-qa="enterBasic_input_treatmentTo"
					:label="labels.treatmentEnd"
					type="date"
					v-model="validator.treatmentEnd.$model"
					:is-valid="!validator.treatmentEnd.$error"
					:highlighted="highlightedTreatmentEnd"
					:disabled="isReadOnlyMode"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="validator"
					property="treatmentEnd"
				/>
			</div>
			<div
				class="max-width-20"
				:class="{'readonly': isReadOnlyMode}"
			>
				<aq-form-input
					data-qa="enterBasic_input_claimAmount"
					id="claimAmount"
					label="Amount"
					placeholder="Enter Amount"
					icon-class="text-primary fa-lg"
					v-model="validator.amount.$model"
					:is-valid="!validator.amount.$error"
					type="money"
					:disabled="isReadOnlyMode"
					:highlight-input="differenceAmount > 0"
					:locale="locale"
				/>
				<div v-if="differenceAmount > 0">
					<div class="pt-12 predicted-amount">
						The Claim Amount is <strong>{{ differenceAmount | currency(locale) }}</strong> more than expected
					</div>
				</div>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="validator"
					property="amount"
				/>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { ValidationMessages } from '@commonServices/utils/constants';
import { shortDate } from '@commonServices/utils/filters';
import { minDate } from '@commonServices/utils/dateUtils';
import invoiceNumberField from '@commonView/ClaimPage/ClaimEnter/InvoiceNumberField';
import AqHighlightBorderWithIcon from '@commonWidgets/AqHighlightBorderWithIcon';

const TODAY = new Date();

const errorMessagesFactory = (dateOfLossLabel) => {
	return {
		description: {
			required: ValidationMessages.required,
			maxLength: ValidationMessages.maxLength2000,
		},
		dateOfLoss: {
			required: ValidationMessages.required,
			maxValue: ValidationMessages.dateInFuture,
			minValue: ValidationMessages.dateShouldBeEqualOrAfter(shortDate(minDate)),
			isContinuationDolValid: 'This date should be before or including the Treatment Start date of any claims within the claim group',
		},
		dateOfDeath: {
			maxValue: ValidationMessages.dateInFuture,
			minValue: ValidationMessages.dateCannotBeBefore(dateOfLossLabel),
		},
		treatmentStart: {
			required: ValidationMessages.required,
			maxValue: ValidationMessages.dateInFuture,
			minValue: ValidationMessages.dateShouldBeAfter(dateOfLossLabel),
			deathValue: ValidationMessages.dateCannotBeAfter('Date of Death plus 14 days'),
		},
		treatmentEnd: {
			required: ValidationMessages.required,
			maxValue: ValidationMessages.dateInFuture,
			treatmentStart: ValidationMessages.dateShouldBeAfter(dateOfLossLabel),
			minValue: ValidationMessages.dateShouldBeAfter('Treatment From'),
			deathValue: ValidationMessages.dateCannotBeAfter('Date of Death plus 14 days'),
		},
		amount: {
			required: ValidationMessages.required,
			minValue: ValidationMessages.greaterThanZero('Claim Amount'),
			maxAmount: ValidationMessages.maxAmount,
		},
	};
};

export default {
	name: 'ClaimBasic',
	components: {
		invoiceNumberField, AqHighlightBorderWithIcon,
	},
	props: {
		basic: {
			type: Object,
			required: true,
		},
		validator: {
			type: Object,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		differenceAmount: {
			type: Number,
			required: false,
		},
		invoiceData: {
			type: Object,
			required: false,
			default: null,
		},
		labels: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters(['dateOfLossLabel']),
		highlightedTreatmentStartDeath: function () {
			return {
				dates: [
					this.basic.dateOfLoss ? this.basic.dateOfLoss : TODAY,
				],
			};
		},
		highlightedTreatmentEnd: function () {
			return {
				dates: [
					this.basic.treatmentStart ? this.basic.treatmentStart : TODAY,
				],
			};
		},
		errorMessages () {
			return errorMessagesFactory(this.dateOfLossLabel);
		},
		dateOfDeathValid () {
			return this.validator.dateOfDeath.$model && !this.validator.dateOfDeath.$error;
		},
	},
	methods: {
		touchDateFields () {
			if (this.basic.dateOfLoss) this.validator.dateOfLoss.$touch();
			if (this.basic.dateOfDeath) this.validator.dateOfDeath.$touch();
			if (this.basic.treatmentStart) this.validator.treatmentStart.$touch();
			if (this.basic.treatmentEnd) this.validator.treatmentEnd.$touch();
		},
	},
};
</script>

<style lang="scss">
.predicted-amount {
  color: $active-color;
}

.date-input {
  margin-right: 20px;
}

.max-width-20 {
  flex: 0 0 calc(20% - 15px);
  max-width: calc(20% - 15px);

  &.invoice-number {
    position: relative;
    margin-left: 20px;
  }

  &.readonly {
    flex: 0 0 calc(20% - 7px);
    max-width: calc(20% - 7px);
  }
}

</style>
