<template>
	<aq-modal-container
		@close="$modal.hide($parent.name)"
		:title="title"
	>
		<!-- Shortkey is used to prevent event propagate -->
		<div
			class="modal-content"
			v-shortkey="$shortkeys.proceed"
		>
			<div class="px-10 py-30">
				<div class="container-fluid">
					<slot class="row no-gutters" />
					<div class="row no-gutters mb-20">
						<div class="col form-field">
							<aq-select
								label="Recipient"
								option-label="recipientName"
								:options="paymentRecipients"
								@input="onInputPaymentRecipient"
								v-model="editedPayment.recipient"
								no-result-message="No Recipients found"
								data-qa="basePaymentModal_select_recipient"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="$options.errorMessages.payment"
								:validator="$v.editedPayment"
								property="recipient"
							/>
						</div>
					</div>
					<div class="row no-gutters">
						<div class="col form-field">
							<aq-select
								label="Payment Details"
								:options="paymentAccounts"
								option-label="payee"
								v-model="editedPayment.paymentAccount"
								@input="onInputPaymentAccount"
								track-by="id"
								:disabled="!editedPayment.recipient"
								no-result-message="No Payment Details found"
								data-qa="basePaymentModal_select_paymentDetails"
							>
								<template #default="props">
									<div class="select-option-header text-truncate d-flex align-items-center">
										<div class="payment-icon">
											<component :is="iconSettings[props.option.paymentMethod]" />
										</div>
										{{ props.option.payee }}
									</div>
									<div
										class="select-option-info text-truncate"
										v-if="isBacsPaymentMethod(props.option.paymentMethod)"
									>
										<span v-if="props.option.bankName">
											{{ props.option.bankName }},&nbsp;
										</span>
										<span>{{ props.option.sortCode }},&nbsp;</span>
										<span>{{ props.option.accountNumber }}</span>
									</div>
									<div />
								</template>
								<template
									slot="singleLabel"
									slot-scope="props"
								>
									<div>
										<span>{{ props.option.payee }}</span>
										<span v-if="isBacsPaymentMethod(props.option.paymentMethod)">
											<span v-if="props.option.bankName">
												,&nbsp;{{ props.option.bankName }},
											</span>
											{{ props.option.sortCode }},
											{{ props.option.accountNumber }}
										</span>
									</div>
								</template>
							</aq-select>
							<aq-form-input-error
								class="mt-8"
								:error-messages="$options.errorMessages.payment"
								:validator="$v.editedPayment"
								property="paymentAccount"
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal-bottom d-flex align-items-center justify-content-between px-20 py-10"
			>
				<button
					@click="$modal.hide($parent.name)"
					type="button"
					class="btn btn-secondary mr-10"
					data-qa="basePaymentModal_button_cancel"
				>
					Cancel
				</button>
				<button
					@click="onFinish"
					:disabled="isPaymentInvalid || disabled"
					type="button"
					class="btn btn-primary"
					data-qa="basePaymentModal_button_finish"
				>
					{{ finishBtnLabel }}
				</button>
			</div>
		</div>
	</aq-modal-container>
</template>

<script>
import { PaymentMethod } from '@commonServices/models/PaymentMethod';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { required } from 'vuelidate/lib/validators';
import { PaymentMethodIconSettings } from '@commonServices/settings/themeSettings';
import { ValidationMessages } from '@commonServices/utils/constants';

export default {
	name: 'BasePaymentModal',
	props: {
		recipients: {
			type: Array,
			required: true,
		},
		payment: {
			type: Object,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		finishBtnLabel: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			editedPayment: {},
			originalPayment: {},
		};
	},
	mounted () {
		const accountSearchFn = acc => acc.id === this.payment.paymentAccountId;
		const recipient = this.recipients.find(rec => rec.accounts.find(accountSearchFn));
		this.originalPayment.recipient = recipient;
		this.originalPayment.paymentAccount = recipient.accounts.find(accountSearchFn);
		this.originalPayment.paymentId = this.payment.claimPaymentId;
		this.editedPayment = {
			...this.originalPayment,
		};
	},
	errorMessages: {
		payment: {
			recipient: {
				required: ValidationMessages.required,
			},
			paymentAccount: {
				required: ValidationMessages.required,
				notTheSame: 'This account is already associated to the payment. Please select another account',
			},
		},
	},
	validations () {
		return {
			editedPayment: {
				recipient: {
					required,
				},
				paymentAccount: {
					required,
					notTheSame: () => !this.isSamePaymentAccount,
				},
			},
		};
	},
	methods: {
		onInputPaymentAccount (paymentAccount) {
			this.$v.editedPayment.paymentAccount.$touch();
			this.editedPayment.paymentAccount = paymentAccount;
		},
		onInputPaymentRecipient () {
			this.$v.editedPayment.recipient.$touch();
			this.editedPayment.paymentAccount = null;
		},
		isBacsPaymentMethod (paymentMethod) {
			return paymentMethod === PaymentMethod.Bacs;
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
		onFinish () {
			this.$v.editedPayment.$touch();
			if (this.isPaymentInvalid) return;
			this.$modal.hide(this.$parent.name);
			this.$emit('finish', this.originalPayment.paymentId, this.editedPayment.paymentAccount.id);
		},
	},
	computed: {
		paymentAccounts () {
			return this.editedPayment.recipient?.accounts?.filter(acc => acc.isActive) ?? [];
		},
		iconSettings () {
			return PaymentMethodIconSettings;
		},
		paymentRecipients () {
			return this.recipients.filter((el, index, self) => index === self.findIndex((t) => (t.recipientId === el.recipientId && t.recipientType === el.recipientType)));
		},
		isPaymentInvalid () {
			return this.$v.editedPayment.$invalid;
		},
		isSamePaymentAccount () {
			return this.originalPayment.paymentAccount?.paymentMethod === PaymentMethod.Bacs
			&& this.editedPayment.paymentAccount?.id === this.originalPayment.paymentAccount?.id;
		},
	},
};
</script>

<style lang="scss" scoped>
.form-field {
  height: 65px;
}
</style>
