<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
							v-for="(payee, index) in data.payees"
							:key="index"
						>
							<div class="col">
								<strong>{{ payee.type }}</strong> {{ payee.name }}
							</div>
							<div class="col text-right">
								{{ payee.gross | currency(data.locale) }}
							</div>
							<div
								v-if="payee.comment"
								class="col ml-10 text-right text-truncate"
								v-tooltip="payee.comment"
							>
								{{ payee.comment }}
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-content {
  width: 600px;
}
</style>
