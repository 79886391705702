<template>
	<aq-sandwich
		:header="header"
		:sub-header="subHeader"
		ref="sandwich"
		:is-active-on-start="isActiveOnStart"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template slot="content">
			<div class="section-content flex-fill">
				<div class="container-fluid">
					<div class="d-flex mb-16">
						<h3 class="fs-14 my-10">
							Claim Form Type:
						</h3>
						<aq-multi-checkbox
							:options="claimFormTypes"
							:disabled="isReadonly"
							:value="selectedFormTypes"
							:multiple="false"
							@input="onInputFormType"
						/>
					</div>
					<claim-loss-type-scan
						v-if="isOtherLosses"
						class="mb-10"
						:is-readonly="isReadonly"
						:recognized-loss-type="basic.ailment"
						@loss-type-selected="onAilmentSelected"
						@focused="highlight(recognizedData.ailment)"
					/>
					<claim-diagnosis-scan
						v-else
						class="mb-10"
						:is-readonly="isReadonly"
						:recognized-ailment="basic.ailment"
						@ailment-selected="onAilmentSelected"
						@focused="highlight(recognizedData.ailment)"
					/>
					<aq-scan-form-input
						class="mb-16"
						v-model="basic.description.value"
						label="Description"
						:is-valid="!$v.basic.description.$error"
						:error-messages="errors('description')"
						:disabled="isReadonly"
						@input="basic.description.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.description)"
					/>
					<aq-scan-form-input
						class="mb-16"
						v-model="basic.dateOfLoss.value"
						:label="dateOfLossLabel"
						:is-valid="!$v.basic.dateOfLoss.$error"
						:error-messages="errors('dateOfLoss')"
						:disabled="isReadonly"
						@input="basic.dateOfLoss.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.dateOfLoss)"
					/>
					<aq-scan-form-input
						class="mb-16"
						v-model="basic.dateOfDeath.value"
						label="Date of Death"
						:is-valid="!$v.basic.dateOfDeath.$error"
						:error-messages="errors('dateOfDeath')"
						:disabled="isReadonly"
						@input="basic.dateOfDeath.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.dateOfDeath)"
					/>
					<aq-scan-form-input
						class="mb-16"
						v-model="basic.treatmentStart.value"
						:label="labels.treatmentStart"
						:is-valid="!$v.basic.treatmentStart.$error"
						:error-messages="errors('treatmentStart')"
						:disabled="isReadonly"
						@input="basic.treatmentStart.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.treatmentStart)"
					/>
					<aq-scan-form-input
						class="mb-16"
						v-model="basic.treatmentEnd.value"
						:label="labels.treatmentEnd"
						:is-valid="!$v.basic.treatmentEnd.$error"
						:error-messages="errors('treatmentEnd')"
						:disabled="isReadonly"
						@input="basic.treatmentEnd.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.treatmentEnd)"
					/>
					<aq-scan-form-input
						class="mb-16"
						v-model="basic.amount.value"
						label="Amount"
						type="money"
						:locale="locale"
						:is-valid="!$v.basic.amount.$error"
						:error-messages="errors('amount')"
						:disabled="isReadonly"
						@input="basic.amount.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.claimAmount)"
					/>
				</div>
			</div>
			<div
				v-if="!isReadonly"
				class="section-footer mb-2"
				style="position: relative;"
			>
				<div class="container-fluid">
					<button
						class="btn btn-primary btn-radius--less mr-0 ml-auto d-block "
						@click="onProceed()"
					>
						Proceed
					</button>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, decimal, minValue } from 'vuelidate/lib/validators';
import { shownErrors } from '@commonServices/utils/general';
import { isDate, maxAmount } from '@commonServices/utils/validators';
import scanSettings from '@commonServices/settings/scanSettings';
import { DateFormats } from '@commonServices/settings/localeSettings';
import { ValidationMessages } from '@commonServices/utils/constants';
import { ClaimFormTypeOptions, ClaimFormType, ClaimFormTypeLabels } from '@commonServices/models/ClaimFormType';
import ClaimDiagnosisScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/ClaimDiagnosisScan';
import ClaimLossTypeScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/ClaimLossTypeScan';

const errorMessages = {
	basic: {
		description: {
			value: {
				maxLength: ValidationMessages.maxLength2000,
			},
		},
		dateOfLoss: {
			value: {
				isDate: `Please enter a date as ${DateFormats.ShortDate}`,
			},
		},
		dateOfDeath: {
			value: {
				isDate: `Please enter a date as ${DateFormats.ShortDate}`,
			},
		},
		treatmentStart: {
			value: {
				isDate: `Please enter a date as ${DateFormats.ShortDate}`,
			},
		},
		treatmentEnd: {
			value: {
				isDate: `Please enter a date as ${DateFormats.ShortDate}`,
			},
		},
		amount: {
			value: {
				decimal: 'Please enter amount as decimal (e.g. 100.00)',
				minValue: ValidationMessages.noNegative,
				maxAmount: ValidationMessages.maxAmount,
			},
		},
	},
};

export default {
	name: 'ClaimBasicScan',
	components: {
		ClaimDiagnosisScan,
		ClaimLossTypeScan,
	},
	data () {
		return {
			basic: {
				description: {
					accuracy: this.recognizedData.description.confidence,
					value: this.getOriginalOrDefaultIfNull(this.recognizedData.description),
				},
				dateOfLoss: {
					accuracy: this.recognizedData.dateOfLoss.confidence,
					value: this.getOriginalOrDefaultIfNull(this.recognizedData.dateOfLoss),
				},
				dateOfDeath: {
					accuracy: this.recognizedData.dateOfDeath.confidence,
					value: this.getOriginalOrDefaultIfNull(this.recognizedData.dateOfDeath),
				},
				treatmentStart: {
					accuracy: this.recognizedData.treatmentStart.confidence,
					value: this.getOriginalOrDefaultIfNull(this.recognizedData.treatmentStart),
				},
				treatmentEnd: {
					accuracy: this.recognizedData.treatmentEnd.confidence,
					value: this.getOriginalOrDefaultIfNull(this.recognizedData.treatmentEnd),
				},
				amount: {
					accuracy: !this.recognizedData.claimAmount.value && !this.recognizedData.claimAmount.originalValue ? 1 : this.recognizedData.claimAmount.confidence,
					value: Number(this.recognizedData.claimAmount.value),
				},
				ailment: {
					id: this.recognizedData.ailment.id,
					accuracy: this.recognizedData.ailment.confidence,
					value: this.getOriginalOrDefaultIfNull(this.recognizedData.ailment),
					boundingBox: this.recognizedData.ailment.boundingBox,
					selectedValue: null,
				},
				claimFormType: {
					accuracy: this.recognizedData.claimFormType.confidence,
					value: this.recognizedData.claimFormType.value,
				},
			},
			selectedFormTypes: [],
		};
	},
	props: {
		recognizedData: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
		header: {
			type: String,
			required: false,
		},
		isActiveOnStart: {
			type: Boolean,
			required: false,
			default: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	validations () {
		return {
			basic: {
				description: {
					value: {
						maxLength: maxLength(2000),
					},
					accuracy: {
						minValue: minValue(scanSettings.accuracyLimit),
					},
				},
				dateOfLoss: {
					value: {
						isDate,
					},
					accuracy: {
						minValue: minValue(scanSettings.accuracyLimit),
					},
				},
				dateOfDeath: {
					value: {
						isDate,
					},
					accuracy: {
						minValue: !this.basic.dateOfDeath.value ? scanSettings.accuracyLimit : minValue(scanSettings.accuracyLimit),
					},
				},
				treatmentStart: {
					value: {
						isDate,
					},
					accuracy: {
						minValue: minValue(scanSettings.accuracyLimit),
					},
				},
				treatmentEnd: {
					value: {
						isDate,
					},
					accuracy: {
						minValue: minValue(scanSettings.accuracyLimit),
					},
				},
				amount: {
					value: {
						decimal,
						minValue: minValue(0),
						maxAmount: maxAmount(16),
					},
					accuracy: {
						minValue: minValue(scanSettings.accuracyLimit),
					},
				},
				claimFormType: {
					value: {
						decimal,
					},
					accuracy: {
						minValue: minValue(scanSettings.accuracyLimit),
					},
				},
			},
		};
	},
	mounted () {
		this.$v.basic.$touch();
		this.selectedFormTypes = [{ id: this.basic.claimFormType.value }];
	},
	computed: {
		...mapGetters(['dateOfLossLabel']),
		isBasicInvalid () {
			return (
				this.$v.basic.description.$error
				|| this.$v.basic.dateOfLoss.$error
				|| this.$v.basic.dateOfDeath.$error
				|| this.$v.basic.treatmentStart.$error
				|| this.$v.basic.treatmentEnd.$error
				|| this.$v.basic.amount.$error
				|| this.$v.basic.claimFormType.$error
			);
		},
		subHeader () {
			return this.header.includes('Condition') ? '' : 'Validate extracted data to populate basic details form';
		},
		claimFormTypes () {
			return [...ClaimFormTypeOptions].map(claimFormType => {
				return {
					label: claimFormType.description,
					id: claimFormType.id,
				};
			});
		},
		isOtherLosses () {
			return this.selectedFormTypes.length && this.selectedFormTypes[0].id === ClaimFormType.OtherLosses;
		},
		labels () {
			return ClaimFormTypeLabels[this.basic.claimFormType.value];
		},
	},
	methods: {
		errors (property) {
			return shownErrors(
				this.$v.basic[property],
				'value',
				errorMessages.basic[property].value,
			);
		},
		onProceed () {
			if (!this.isBasicInvalid) {
				this.$refs.sandwich.onToggle();
				this.$emit('passed', {
					description: this.basic.description.value,
					dateOfLoss: this.basic.dateOfLoss.value,
					dateOfDeath: this.basic.dateOfDeath.value,
					treatmentStart: this.basic.treatmentStart.value,
					treatmentEnd: this.basic.treatmentEnd.value,
					claimAmount: this.basic.amount.value,
					ailment: this.basic.ailment.selectedValue,
					claimFormType: this.basic.claimFormType.value,
				});
			}
		},
		getOriginalOrDefaultIfNull (property) {
			return property.value ? property.value : property.originalValue;
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		onAilmentSelected (ailment) {
			if (ailment) {
				this.basic.ailment.id = ailment.id;
				this.basic.ailment.value = ailment.firstCause;
			}
			this.basic.ailment.accuracy = 1;
			this.basic.ailment.selectedValue = ailment;
		},
		highlight (field) {
			const { page, boundingBox } = field;
			this.$emit('focused', { page, boundingBox });
		},
		onInputFormType (values) {
			if (values[0]?.id === ClaimFormType.OtherLosses || this.basic.claimFormType.value === ClaimFormType.OtherLosses) {
				this.basic.ailment.id = null;
				this.basic.ailment.value = null;
			}

			this.selectedFormTypes = values;
			this.basic.claimFormType.value = values[0]?.id ?? null;

			this.$emit('form-type-updated', this.basic.claimFormType.value);
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-radius--less {
  border-radius: 2px;
}
</style>
