export default class PaymentInstruction {
	constructor (id,
		paymentIssueDate,
		payeeName,
		address,
		paymentMethod,
		batchDateTime,
		transactionId,
		status,
	) {
		this.id = id;
		this.paymentIssueDate = paymentIssueDate;
		this.payeeName = payeeName;
		this.address = address;
		this.paymentMethod = paymentMethod;
		this.batchDateTime = batchDateTime;
		this.transactionId = transactionId;
		this.status = status;
	}
};
