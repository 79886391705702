export default class VetInvoiceInput {
	constructor (date, amount, policySectionId, userDeductions, claimConditionId, nonFinancialNumber, taxAmount, discountAmount, lineType) {
		this.date = date;
		this.amount = amount;
		this.policySectionId = policySectionId;
		this.userDeductions = userDeductions;
		this.claimConditionId = claimConditionId;
		this.nonFinancialNumber = nonFinancialNumber;
		this.taxAmount = taxAmount;
		this.discountAmount = discountAmount;
		this.lineType = lineType;
	}
}
