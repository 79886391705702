<template>
	<div class="card">
		<div class="top py-15 px-20">
			<div class="d-flex">
				<slot name="card-icon">
					<div
						class="card-icon mr-20 medium align-self-center position-relative"
						:class="iconClass"
						v-tooltip="iconTooltip"
						@click="$emit('icon-click')"
					>
						<component
							class="file-icon align-items-self"
							v-if="!!iconSvgComponent"
							:is="iconSvgComponent"
						/>
						<i
							v-else
							:class="['fa', $options.typeIconMap[type] || type]"
						/>
						<i
							v-if="arrowClass && !iconSvgComponent"
							class="communication-direction"
							:class="arrowClass"
						/>
						<i
							v-if="iconClass === 'warning'"
							class="fas fa-exclamation-triangle"
						/>
					</div>
				</slot>
				<div
					class="card-main-content"
					:class="{ 'limit-width' : action }"
				>
					<h3
						class="m-0 text-truncate name-info"
					>
						<span
							v-tooltip="name"
							@click="$emit('click-name')"
						>
							{{ name }}
						</span>
					</h3>
					<slot name="subName" />
					<slot name="description">
						<aq-tooltip-paragraph
							v-if="description"
							:description-class="descriptionClass"
							:description="description"
						/>
					</slot>
				</div>
				<slot name="extra-content">
					<div
						class="align-self-center ml-15"
						v-if="user"
					>
						<aq-icon-avatar
							class="small cursor-default"
							:value="[user.firstName, user.lastName]"
						/>
					</div>
				</slot>
				<slot name="action-button">
					<div
						class="align-self-center ml-10"
						v-if="action"
					>
						<aq-dropdown
							v-if="action && action.length"
							@select="$emit('select-action', $event)"
							:options="action"
							:btn-class="'small btn-row'"
							data-qa="aqCard_dropdown_select-action"
						/>
						<button
							@click="$emit('select-action', action.value)"
							class="btn btn-primary mt-16"
							v-if="action && !Array.isArray(action)"
							data-qa="aqCard_button_select"
						>
							{{ action.label }}
							<i
								v-if="btnIconClass"
								class="fas ml-10"
								:class="btnIconClass"
							/>
						</button>
					</div>
				</slot>
			</div>
		</div>
		<slot name="list" />
		<slot name="content" />
	</div>
</template>

<script>

export default {
	name: 'AqCard',
	props: {
		name: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: false,
			default: null,
		},
		user: {
			type: Object,
			required: false,
			default: null,
		},
		descriptionClass: {
			type: String,
			required: false,
			default: 'fs-13',
		},
		type: {
			type: String,
			required: false,
		},
		action: {
			type: [Array, Object],
			required: false,
		},
		iconClass: {
			type: String,
			required: false,
		},
		btnIconClass: {
			type: String,
			required: false,
		},
		arrowClass: {
			type: String,
			required: false,
			default: '',
		},
		iconTooltip: {
			type: String,
			required: false,
			default: '',
		},
		iconSvgComponent: {
			type: Object,
			required: false,
		},
	},
	typeIconMap: {
		report: 'fa-table',
		chart: 'fa-chart-bar',
		note: 'fa-comment',
		envelope: 'fa-envelope',
		envelopeOpen: 'fa-envelope-open-text',
		file: 'fa-file',
		sms: 'fa-sms',
		folder: 'fa-folder',
		condition: 'fa-band-aid',
		letters: 'fa-copy',
		zipped: 'fa-file-archive',
		general: 'fa-circle',
		queue: 'fa-inbox',
	},
};
</script>

<style lang="scss" scoped>
.limit-width {
  max-width: calc(100% - 120px);
}

.card-main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0; // make text-truncate work
}

.communication-direction {
  font-size: 0.8rem !important;
  position: absolute;
  top: 10%;
  right: 10%;
}

.out {
  transform: rotate(45deg);
}

.in {
  transform: rotate(-145deg);
}

.w-60 {
  width: 60%;
}

.fa-exclamation-triangle {
  position: absolute;
  top: -10px;
  right: -10px;
}

.name-info {
  cursor: default;
}
</style>
