<template>
	<div>
		<input
			@input="updateValue($event.target.value)"
			@focus="focusInput()"
			@blur="blurInput()"
			@keydown="onKeyPressed"
			:type="type"
			:value="value"
			:class="inputClass"
			:id="id"
			:disabled="disabled"
			:placeholder="placeholder"
			title=""
			ref="input"
		>
		<!-- ref input is used to focus on element outside -->
	</div>
</template>

<script>
export default {
	name: 'AqInput',
	props: {
		value: {
			type: [String, Number, Date],
			required: false,
		},
		placeholder: {
			type: String,
			required: false,
		},
		inputClass: {
			type: String,
			required: false,
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		id: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	methods: {
		updateValue (value) {
			this.$emit('input', value.trim());
		},
		focusInput () {
			this.$emit('focus', true);
		},
		blurInput () {
			this.$emit('focus', false);
		},
		onKeyPressed (event) {
			if (this.type === 'number'
				&& (event.code === 'Comma' || event.code === 'Minus' || event.key === '+')) {
				event.preventDefault();
			}
		},
	},
};
</script>
