<template>
	<div>
		<div class="section-header position-relative">
			<div
				class="section-header__title text-truncate"
				v-tooltip="pet.name"
			>
				{{ pet.name }}
			</div>
			<i
				class="fas fa-lg ml-5 mt-1"
				:class="[isMale ? 'fa-mars':'fa-venus' ]"
			/>
			<p>{{ pet.breedName }}</p>
			<aq-edit-pet-date-of-death
				:pet="pet"
				:pet-avatar-class="['right-align', 'cursor-inherit']"
				class="pet-card"
			/>
		</div>
		<div
			class="section-content pb-25"
		>
			<div class="container-fluid">
				<div
					class="row no-gutters attributes mt-0"
					:class="{ 'cancelled': isCurrentPolicyCancelled }"
				>
					<div class="col-md-12 pb-10">
						<aq-product-tabs
							:policy-term-groups="policyTermGroups"
							:current-policy-term="currentPolicyTerm"
							:current-policy-term-group="currentPolicyTermGroup"
							@select="onSelectGroup"
						/>
						<div class="pb-0">
							<policy-term-shifter
								v-if="policyTerms.length"
								:policy-terms="policyTerms"
								:current-policy-term="currentPolicyTerm"
								@update-policy-term="onUpdatePolicyTerm"
							/>
						</div>
					</div>
					<pet-attributes
						:pet="pet"
						:policy-term="currentPolicyTerm"
						:locale="locale"
						class="col-md-12"
					/>
				</div>
			</div>
		</div>
		<div class="section-footer">
			<div class="container-fluid d-flex align-items-center">
				<div
					class="text-white"
					v-if="$can.ViewClaims"
					data-qa="petCard_label_claimAmount"
				>
					This pet has {{ pet.totalClaimsCount }} {{ 'claim' | pluralize(pet.totalClaimsCount ) }}
				</div>
				<button
					@click="$emit('view-click')"
					class="d-block btn btn-primary ml-auto mr-10"
					data-qa="petCard_button_view"
				>
					View
				</button>
				<button
					v-if="$can.ClaimEdit"
					@click="createClaim"
					class="d-block btn btn-primary"
					data-qa="petCard_button_create"
				>
					Create
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import claimService from '@commonServices/claimService';
import PetGender from '@commonServices/models/PetGender';
import { toPetViewPolicyTerms, toPolicyTerms } from '@commonServices/utils/converter';
import PetService from '@commonServices/petService';
import AqProductTabs from '@commonWidgets/Products/AqProductTabs';
import PetAttributes from '@commonView/CustomerPage/PetsLayout/PetAttributes';
import { PolicyStatus } from '@commonServices/models/PolicyStatus';
import PolicyTermShifter from '@commonView/CustomerPage/PolicyTermShifter';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		AqProductTabs,
		PetAttributes,
		PolicyTermShifter,
	},
	props: {
		pet: {
			type: Object,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		selectedPolicyNumber: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			currentPolicyTerm: null,
			policyTermGroups: null,
			currentPolicyTermGroup: null,
		};
	},
	beforeMount () {
		this.initPolicyTerms();
	},
	computed: {
		...mapGetters(['getSelectedPolicyType']),
		isMale: function () {
			return this.pet.gender === PetGender.Male;
		},
		policyTerms () {
			return toPolicyTerms(this.currentPolicyTermGroup.policyTerms);
		},
		product () {
			return this.currentPolicyTerm.product;
		},
		isCurrentPolicyCancelled () {
			return this.currentPolicyTerm.policyStatus === PolicyStatus.Canceled;
		},
	},
	methods: {
		...mapActions(['setCurrentPolicyTermGroup']),
		createClaim () {
			claimService.createClaim(this.pet.id)
				.then(data => this.$router.push({ name: 'claim-page', params: { claimId: data } }));
		},
		onUpdatePolicyTerm (index) {
			this.currentPolicyTerm = this.currentPolicyTermGroup.policyTerms[index];
		},
		onSelectGroup (policyTermGroup) {
			if (policyTermGroup === this.currentPolicyTermGroup) return;
			this.currentPolicyTermGroup = policyTermGroup;
			this.currentPolicyTerm = PetService.getCurrentPetPolicyTerm(this.currentPolicyTermGroup.policyTerms);
			this.setCurrentPolicyTermGroup({
				petId: this.pet.id,
				policyTermGroup: this.policyTermGroups[0] === policyTermGroup ? null : policyTermGroup.group, // null means default (first) so no need to store therefore delete
			});
		},
		initPolicyTerms () {
			this.policyTermGroups = toPetViewPolicyTerms(this.pet.policyTerms);
			const selectedPolicyType = this.getSelectedPolicyType(this.pet.id);

			let policyTerms = null;

			if (this.selectedPolicyNumber) { // select terms by policy number
				const policyTermsByPolicyNumber = this.pet.policyTerms.filter(policyTerm => policyTerm.policyReference === this.selectedPolicyNumber);
				policyTerms = policyTermsByPolicyNumber.length ? policyTermsByPolicyNumber : null;
			} else if (selectedPolicyType) { // or select terms by selected policy type (insurance or wellness)
				policyTerms = this.policyTermGroups.find(group => group.group === selectedPolicyType)?.policyTerms;
			}

			this.currentPolicyTerm = PetService.getCurrentPetPolicyTerm(policyTerms ?? this.pet.policyTerms);
			this.currentPolicyTermGroup = this.policyTermGroups.find(group => group.policyTerms.includes(this.currentPolicyTerm));
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .attribute {
  width: 100%;
}

.pet-avatar {
  position: absolute;
  right: 0;
  background: none !important;
}

.section-header {
  padding-left: 45px;

  &__title {
    max-width: calc(100% - 70px);
  }
}

.fa-venus {
  color: var(--cardIconColour);
}

.fa-mars {
  color: $primary;
  text-align: center;
}

::v-deep .product-tab {
  &.unfocused {
    .product-tab-icon {
      cursor: pointer;
    }
  }

  .product-tab-icon {
    cursor: default;
  }
}
</style>
