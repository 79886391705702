<template>
	<div>
		<aq-modal-container>
			<template #header>
				<div class="aq-modal-header pl-26 pt-16 pb-12 ob-12 d-flex align-items-center">
					<div class="aq-title text-white">
						Refresh Page
					</div>
				</div>
			</template>
			<div class="modal-content">
				<div class="p-20">
					<div class="d-flex flex-column align-items-center">
						<img
							alt="Please refresh page"
							:src="require('assets/images/RefreshPage.svg')"
						>
						<label class="fs-20 font-weight-bold">Please refresh page</label>
						<label class="fs-15 font-weight-bold mt-10">Changes to the data have occurred</label>
						<label class="fs-13 mt-5">Please be aware any previous updates may be overwritten</label>
					</div>
				</div>
				<div class="px-20 py-10">
					<hr class="tiny-hr btn-primary">
					<div class="d-flex justify-content-center mt-10">
						<button
							@click="onRefresh"
							type="button"
							class="btn btn-primary"
						>
							Refresh Page
						</button>
					</div>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
export default {
	methods: {
		onRefresh () {
			window.location.reload();
		},
	},
};
</script>

<style>
.tiny-hr {
  font-size: 10px;
  opacity: 0.4;
}
</style>
