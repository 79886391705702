const ConditionManagementEnum = Object.freeze({
	PreExisting: 0,
	Eligible: 1,
});
const ConditionManagementEnumMap = Object.freeze({
	[ConditionManagementEnum.PreExisting]: 'Pre-Existing',
	[ConditionManagementEnum.Eligible]: 'Eligible',
});

export { ConditionManagementEnum, ConditionManagementEnumMap };
