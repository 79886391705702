<template>
	<aq-select-filter
		data-qa="filter_input_vet"
		class="w-100"
		v-model="filter.value"
		:options="vets"
		:loading="vetLoading"
		:async-find="searchVet"
		:label="filter.label"
		:multiple="filter.multiselect"
		:excluded="filter.excluded"
		@toggle-mode="filter.excluded = $event"
		no-result-message="No Vets found"
	/>
</template>
<script>

import debounce from 'lodash.debounce';
import claimService from '@commonServices/claimService';

export default {
	name: 'VetFilter',
	props: {
		filter: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			vetLoading: false,
			vets: [],
		};
	},
	mounted () {
		this.vets = (this.filter.value ? [this.filter.value] : []).flat();
	},
	methods: {
		searchVet: debounce(async function (term) {
			if (term.length >= 3) {
				this.vetLoading = true;
				this.vets = (await claimService.getVets(term))
					.map((vet) => {
						return {
							...vet,
							description: [vet.practiceName, vet.regionCounty, vet.postalCode].filter(Boolean).join(', '),
						};
					});
				this.vetLoading = false;
			}
		}, 500),
	},
};
</script>
