<template>
	<div class="d-flex">
		<FlagIcon class="flag" />
		<div class="card mt-18">
			<slot>
				<div class="fs-18">
					{{ title }}
				</div>
				<div class="fs-25">
					{{ description }}
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
import { FlagIcon } from '@assets/icons';

export default {
	name: 'AqFlagCard',
	props: {
		title: {
			type: [String, Number],
			required: false,
		},
		description: {
			type: [String, Number],
			required: false,
		},
	},
	components: {
		FlagIcon,
	},
};
</script>

<style lang="scss" scoped>
.flag {
  margin-top: 4px;
  height: 95px;
  overflow: visible;
}

.card {
  position: absolute;
  background-color: transparent;
  color: white !important;
  width: 100%;
  text-align: center;
}
</style>
