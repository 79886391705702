<template>
	<div class="image-avatar">
		<img
			v-if="isLogoExist"
			class="small logo-image"
			:src="fileUri"
			:alt="altText"
			v-tooltip="altText"
			@error="isLogoExist = false"
		>
		<aq-icon-avatar
			v-else
			class="small"
			:value="altText.split(' ')"
		/>
	</div>
</template>

<script>

export default {
	name: 'AqImageAvatar',
	props: {
		fileUri: {
			type: String,
			required: true,
		},
		altText: {
			type: String,
			required: false,
			default: '',
		},
	},
	data () {
		return {
			isLogoExist: true,
		};
	},
};
</script>

<style lang="scss" scoped>
.logo-image {
  cursor: default;
  height: 28px;
  max-width: 100%;
}

.image-avatar {
  height: 28px;

  &.cursor-inherit {
    ::v-deep .icon-avatar {
      cursor: inherit;
    }

    ::v-deep .logo-image {
      cursor: inherit;
    }
  }

  &.cursor-pointer {
    ::v-deep .icon-avatar {
      cursor: pointer;
    }

    ::v-deep .logo-image {
      cursor: pointer;
    }
  }
}
</style>
