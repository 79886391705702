export default class PetVetSearchView {
	constructor (
		isHistorical,
		assignmentDate,
		isEditable,
		approved,
		id,
		externalVetId,
		ref,
		practiceName,
		address1,
		address2,
		address3,
		townCity,
		postalCode,
		regionCounty,
		country,
		email,
		phoneNumber,
		careFlag,
		hospitalContactName,
		faxNumber,
		claimPaymentMethod,
		preferredContactMethod,
		languagePref,
		active,
		vetDirectPay) {
		this.isHistorical = isHistorical;
		this.assignmentDate = assignmentDate;
		this.isEditable = isEditable;
		this.approved = approved;
		this.id = id;
		this.externalVetId = externalVetId;
		this.ref = ref;
		this.practiceName = practiceName;
		this.address1 = address1;
		this.address2 = address2;
		this.address3 = address3;
		this.townCity = townCity;
		this.postalCode = postalCode;
		this.regionCounty = regionCounty;
		this.country = country;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.careFlag = careFlag;
		this.hospitalContactName = hospitalContactName;
		this.faxNumber = faxNumber;
		this.claimPaymentMethod = claimPaymentMethod;
		this.preferredContactMethod = preferredContactMethod;
		this.languagePref = languagePref;
		this.active = active;
		this.vetDirectPay = vetDirectPay;
	}
}
