export const USER_STATE_PROPS = {
	SELECTED_POLICY_TYPE: 'spt',
	// part of the VIS-29936. It will be removed later with next release.
	SELECTED_POLICY_TYPE_OLD: 'selectedPolicyType',
	TASK_VIEW_MODE: 'taskViewMode',
	RECENTLY_VIEWED_MODE: 'recentlyViewedMode',
	CLAIM_VIEW_MODE: 'claimViewMode',
	USER_OPT_IN: 'userOptIn',
	GRAPH_VIEW_MODE: 'graphViewMode',
	SHORTKEY_ENABLED: 'shortKeyEnabled',
	CARD_VIEW_MODE: 'cardViewMode',
	TASKS_FILTER: 'tasksFilter',
	PET_CLAIMS_FILTER: 'petClaimsFilter',
};
