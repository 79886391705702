export default class WaitPeriodView {
	constructor (
		id,
		endDate,
		conditionType,
		conditionTypeId,
		policyId,
		waitAmountDays,
		hasOverride,
		waitAmountDaysOverride,
		endDateOverride,
	) {
		this.id = id;
		this.endDate = endDate;
		this.conditionType = conditionType;
		this.conditionTypeId = conditionTypeId;
		this.policyId = policyId;
		this.waitAmountDays = waitAmountDays;
		this.hasOverride = hasOverride;
		this.waitAmountDaysOverride = waitAmountDaysOverride;
		this.endDateOverride = endDateOverride;
	}
}
