<template>
	<div class="h-100">
		<aq-modal name="multi-edit-line-item-modal">
			<multi-edit-line-item-modal
				ref="multi-edit-line-item-modal"
				:deductions="claimDeductions"
				:policy-sections="allPolicySections"
				:conditions="conditions"
				:multicondition="multicondition"
				:selected-line-item="recognizedData.items[selectedLinesIndexes[0]]"
				@finish="onFinishMultiEdit"
			/>
		</aq-modal>
		<aq-modal
			name="add-claim-condition"
			v-if="multicondition"
		>
			<add-claim-condition-modal
				@add-claim-condition="addClaimCondition"
				:claim-id="claimId"
			/>
		</aq-modal>
		<aq-modal name="reset-invoice-modal">
			<reset-invoice-modal
				@reset-invoice="$emit('reset-invoice')"
			/>
		</aq-modal>
		<add-claim-layout
			v-if="isAddingNewClaim && !isReadOnlyMode"
			:claim-id="claimId"
			:locale="locale"
			:is-read-only-mode="isReadOnlyMode"
			@close="closeAddClaimLayout"
			@create="createNewClaim"
		/>
		<aq-modal name="split-lines-modal">
			<split-lines-modal
				data-qa="invoiceItemsScan_modal_treatmentLinesModal"
				:selected-line-items="selectedLineItemsForSplit"
				:policy-sections="allPolicySections"
				:conditions="conditions"
				:locale="locale"
				:deductions="claimDeductions"
				@create-line-item-copies="onCreateLineItemCopies"
			/>
		</aq-modal>
		<div
			class="invoice-items-scan"
			v-if="!isAddingNewClaim"
		>
			<div class="section-header flex-grow-1">
				<div class="section-header__title">
					Invoice Details
				</div>
				<p class="scan-sub-header">
					Please check the extracted data and make any corrections
				</p>
			</div>
			<div
				ref="container"
				class="section-content h-100 items-list p-0 my-10"
			>
				<div class="container-fluid">
					<div
						v-if="!isEditMode"
						class="row no-gutters pb-20 fs-12"
					>
						<div
							v-if="duplicateData.length > 0"
							class="duplicate-invoice-icon"
							v-tooltip="'Duplicate Invoice Lines Detected'"
						>
							<i class="mx-auto fas fa-exclamation" />
						</div>
						<div
							v-if="!isReadOnlyMode"
							class="col d-flex justify-content-end"
						>
							<div
								v-if="isProcessStep"
								class="px-10 d-flex align-items-center justify-content-end"
							>
								<div
									v-if="multicondition"
									@click="onAddClaimCondition()"
									class="btn btn-sm btn-primary d-flex align-items-center py-7 pl-20 close"
								>
									<i class="aqv-circle-plus mr-15 fs-30" />
									<span>Add New Ailment</span>
								</div>
								<div
									v-else
									@click="onAddClaim()"
									class="btn btn-sm btn-primary d-flex align-items-center py-7 pl-20 close"
								>
									<i class="aqv-circle-plus mr-15 fs-30" />
									<span>Create New Claim</span>
								</div>
							</div>
							<div
								class="d-flex align-items-center multiple-treatments rounded-4"
								:class="{ 'active': isMultiEditMode && selectedLinesIndexes.length }"
							>
								<aq-checkbox
									class="round large thin mx-10 my-5"
									:value="isMultiEditMode"
									@input="onSelectMultiEdit"
								/>
								<div class="mr-15">
									{{ isMultiEditMode && selectedLinesIndexes.length ? 'Selected Lines:' : 'Select Multiple Treatments' }}
								</div>
								<div
									v-if="isMultiEditMode && selectedLineItemsForSplit.length"
									class="multi-edit-action-container mr-4"
									@click="openSplitModal"
									v-tooltip="'Make copy'"
								>
									<i class="aqv-make-copy" />
								</div>
								<div
									v-if="isMultiEditMode && selectedLinesIndexes.length"
									class="multi-edit-action-container"
									@click="openMultiEditModal"
									v-tooltip="'Edit Lines'"
								>
									<i class="fas fa-pencil-alt" />
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="!isEditMode"
						class="header-data fs-12"
					>
						<div @click="highlightCustomerName">
							<aq-form-input
								data-qa="invoiceItemsScan_input_customerName"
								label="Customer Name"
								v-model="recognizedData.customerName.value"
								@input="$v.recognizedData.customerName.$touch"
								:disabled="isReadOnlyMode"
							/>
							<aq-form-input-error
								class="mt-4"
								:warning-messages="errorMessages.customerName"
								:validator="$v.recognizedData.customerName"
								property="value"
							/>
						</div>
						<div @click="highlightInvoiceNumber">
							<aq-form-input
								data-qa="invoiceItemsScan_input_invoiceNumber"
								label="Invoice Number"
								v-model="recognizedData.invoiceNumber.value"
								@input="onInputInvoiceNumber"
								:is-valid="!$v.recognizedData.invoiceNumber.$error"
								:disabled="isReadOnlyMode"
							/>
							<aq-form-input-error
								class="mt-4"
								:error-messages="errorMessages.invoiceNumber"
								:validator="$v.recognizedData.invoiceNumber"
								property="value"
							/>
						</div>
						<div @click="highlightVendorName">
							<aq-form-input
								data-qa="invoiceItemsScan_input_vetPractice"
								label="Vet Practice"
								v-model="recognizedData.vendorName.value"
								@input="$v.recognizedData.vendorName.$touch"
								:is-valid="!$v.recognizedData.vendorName.$error"
								:disabled="isReadOnlyMode"
							/>
							<aq-form-input-error
								class="mt-4"
								:error-messages="errorMessages.vendorName"
								:validator="$v.recognizedData.vendorName"
								property="value"
							/>
						</div>
						<div>
							<aq-form-input
								data-qa="invoiceItemsScan_input_petNames"
								label="Pet Name"
								v-model="petNames"
								disabled
							/>
						</div>
						<div>
							<aq-form-input
								data-qa="invoiceItemsScan_input_dateOfLoss"
								:label="dateOfLossLabel"
								type="date"
								v-model="claimBasic.dateOfLoss"
								disabled
							/>
						</div>
						<div>
							<div>
								<aq-form-input
									data-qa="invoiceItemsScan_input_treatmentFrom"
									label="Treatment From"
									type="date"
									v-model="claimBasic.treatmentStart"
									@input="$v.claimBasic.treatmentStart.$touch"
									:is-valid="!$v.claimBasic.treatmentStart.$error"
									:highlighted="highlightedTreatmentStartDate"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="mt-4"
									:error-messages="errorMessages"
									:validator="$v.claimBasic"
									property="treatmentStart"
								/>
							</div>
						</div>
						<div>
							<div>
								<aq-form-input
									data-qa="invoiceItemsScan_input_treatmentTo"
									label="Treatment To"
									type="date"
									v-model="claimBasic.treatmentEnd"
									@input="$v.claimBasic.treatmentEnd.$touch"
									:is-valid="!$v.claimBasic.treatmentEnd.$error"
									:highlighted="highlightedTreatmentEnd"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="mt-4"
									:error-messages="errorMessages"
									:validator="$v.claimBasic"
									property="treatmentEnd"
								/>
							</div>
						</div>
						<div>
							<div>
								<aq-form-input
									data-qa="invoiceItemsScan_input_amount"
									label="Amount"
									type="money"
									v-model="claimBasic.amount"
									@input="$v.claimBasic.amount.$touch"
									:is-valid="!$v.claimBasic.amount.$error"
									disabled
									:locale="locale"
								/>
								<div
									v-if="!totalAmountMatchInvoices"
									class="mt-4"
								>
									<span class="text-warning">
										{{ errorMessages.notMatchAmountWarning }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="!isEditMode"
						class="row no-gutters pb-11 fs-12"
					>
						<div
							class="d-flex align-items-center pl-8"
							:class="gridSizes[0]"
						>
							<aq-checkbox
								v-if="isSelectMultipleDisabled"
								class="round medium thin"
								v-model="toggleAllState"
								:disabled="isReadOnlyMode"
							/>
							<aq-checkbox
								v-else
								class="round medium thin"
								v-model="toggleMultiEditAllState"
							/>
							<div class="ml-13">
								Date
							</div>
						</div>
						<div
							class="pl-5 d-flex align-items-center"
							:class="gridSizes[1]"
						>
							Description
						</div>
						<div
							class="d-flex align-items-center"
							:class="gridSizes[2]"
						>
							Disc. {{ currencySymbol }}
						</div>
						<div
							class="d-flex align-items-center"
							:class="gridSizes[3]"
						>
							Tax
						</div>
						<div
							class="d-flex align-items-center"
							:class="gridSizes[4]"
						>
							Amount
						</div>
						<div
							v-if="multicondition"
							class="d-flex align-items-center"
							:class="gridSizes[5]"
						>
							Ailment
						</div>
						<div
							class="d-flex align-items-center"
							:class="gridSizes[6]"
						>
							Section
						</div>
						<div
							class="d-flex align-items-center justify-content-end"
							:class="gridSizes[7]"
						>
							<button
								v-if="!isReadOnlyMode && $can.EditInvoiceRecognition && !isEditMode"
								@click="$modal.show('reset-invoice-modal')"
								data-qa="invoiceItemsScan_button_reset"
								class="btn btn-warning pl-35 position-absolute btn-radius--less btn-reset"
							>
								<div class="reset-icon position-absolute">
									<UndoEditsIcon />
								</div>
								Undo Edits
							</button>
						</div>
					</div>

					<div>
						<div
							v-if="allPolicySections && claimDeductions"
							class="position-relative"
						>
							<div
								v-for="(group, index) in lineGroups"
								:key="index"
								data-qa="invoiceItemsScan_parent_container"
								:class="[group.items.length > 1 ? 'lines-group': 'line',
									group.items.some(i=> i.index === selectedItemIndex) ? 'highlighted' :
									checkLineDuplicate(group.items[0]) ? 'duplicate' :'default']"
							>
								<invoice-line-item
									data-qa="invoiceLineItem_line"
									v-for="item in group.items"
									:key="item.index"
									:data-item="item"
									:deductions="claimDeductions"
									:policy-sections="allPolicySections"
									:conditions="conditions"
									@startEdit="onStartEdit"
									@finish="onFinishEdit($event, item.index)"
									@line-type-selected="onLineTypeSelected($event, item.index)"
									@delete="onDelete(item.index)"
									@toggle="onToggle($event, item.index)"
									@multiEditToggle="onMultipleTreatmentSelected($event, item.index)"
									@setValidity="onSetValidity($event, item.index)"
									@highlight="onItemHighlight($event, item.index)"
									@cancel="onCancel"
									:selected="selectedItemIndex === item.index"
									:locale="locale"
									:grid-sizes="gridSizes"
									:multicondition="multicondition"
									:is-edit-multi-mode="!isSelectMultipleDisabled"
									:is-selected-multi-edit="selectedLinesIndexes.indexOf(item.index) !== -1"
									:is-excluded-multi-edit="isItemExcludedFromMultiEdit(item)"
									:pre-applied-discount="getPreAppliedDiscount(item.index)"
									:is-read-only-mode="isReadOnlyMode"
									@copy="onSplitLine(item.index)"
									:is-duplicate="checkLineDuplicate(item)"
								/>
							</div>
							<invoice-line-item
								data-qa="invoiceLineItem_line"
								v-if="isAddingNew"
								:data-item="newItem"
								:deductions="claimDeductions"
								:policy-sections="allPolicySections"
								:conditions="conditions"
								:locale="locale"
								:grid-sizes="gridSizes"
								:multicondition="multicondition"
								@finish="onFinishCreate($event)"
								@cancel="onCancel"
								@startEdit="scrollTop()"
								is-new
							/>
						</div>
						<invoice-total-discount-line
							label="Total Discount"
							:recognized-field="recognizedData.totalDiscount"
							:total-discount="totalDiscount"
							:is-read-only-mode="isReadOnlyMode || invoiceRecognizedDiscountItemsSum !== 0"
							:selected-line-items="selectedLineItemAmounts"
							:show-discount-split="isSplitDiscountMode"
							@on-total-item-edit="onTotalDiscountEdit"
							@select-split-discount="onSelectSplitDiscount"
							@discounts-split-update="discountsSplit = $event"
						/>
						<invoice-total-item-edit
							:label="`Total Tax ${taxesIncluded ? '(included)' :'' }`"
							:included-field="recognizedData.taxesIncluded"
							:recognized-field="recognizedData.totalTax"
							:invoice-recognized-amount="invoiceRecognizedTotalTax"
							:invoice-recognized-items-sum="invoiceRecognizedTaxItemsSum"
							:initial-total-sum="totalTax"
							:is-read-only-mode="isReadOnlyMode"
							@on-total-item-edit="onTotalTaxEdit"
						/>
						<div class="row no-gutters attribute py-16 mb-10 align-items-center">
							<div class="col-6 pl-10">
								Total
							</div>
							<div class="col-2">
								{{ invoiceItemsSum | currency(locale) }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="!(isReadOnlyMode || isEditMode)"
				class="section-footer"
			>
				<div class="container-fluid d-flex justify-content-end">
					<button
						class="btn btn-primary btn-radius--less mr-10"
						:disabled="isAddingNew"
						@click="addNewItem()"
					>
						Add Item
					</button>
					<button
						class="btn btn-primary btn-radius--less"
						:disabled="isInvoiceInvalid || isInvoiceNumberInvalid || isVendorNameInvalid || isClaimDetailsInvalid || recognizedData.items.length <= 0"
						@click="onProceed()"
					>
						Proceed
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ClaimService from '@commonServices/claimService';
import { currency } from '@commonServices/utils/filters';
import groupBy from 'lodash.groupby';
import { UndoEditsIcon } from '@assets/icons';
import { toInvoiceScanItem, toClaimConditionDescription } from '@commonServices/utils/converter';
import { maxValue, required } from 'vuelidate/lib/validators';
import { lessThenOrEqual, maxAmount, moreThen, moreThenOrEqual } from '@commonServices/utils/validators';
import { ValidationMessages, VetFeesPolicySection } from '@commonServices/utils/constants';
import { areDatesEqual, fromStringToDate } from '@commonServices/utils/dateUtils';
import ClaimInput from '@commonServices/models/ClaimInput';
import { unique } from '@commonServices/utils/general';
import maxLength from 'vuelidate/lib/validators/maxLength';
import eventBus from '@commonServices/eventBus';
import deductionService from '@commonServices/deductionService';
import { mapActions, mapState, mapGetters } from 'vuex';
import { getCurrencyByLocale } from '@commonServices/settings/localeSettings';
import InvoiceTotalItemEdit from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceTotalItemEdit';
import InvoiceTotalDiscountLine from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceTotalDiscountLine';
import AddClaimConditionModal from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/AddClaimConditionModal';
import AddClaimLayout from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/AddClaimLayout';
import ResetInvoiceModal from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/ResetInvoiceModal';
import MultiEditLineItemModal from '@commonView/Shared/MultiEditLineItemModal';
import StepNameEnum from '@commonServices/models/steps/StepNameEnum';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import { InvoiceLineType } from '@commonServices/models/InvoiceLineType';
import SplitLinesModal from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/SplitLinesModal';
import selectMultipleModeEnum from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/SelectMultipleModeEnum';

export default {
	name: 'InvoiceItemsScan',
	components: {
		InvoiceTotalItemEdit,
		MultiEditLineItemModal,
		AddClaimConditionModal,
		SplitLinesModal,
		UndoEditsIcon,
		ResetInvoiceModal,
		AddClaimLayout,
		InvoiceTotalDiscountLine,
	},
	data () {
		return {
			validated: [],
			isInvoiceInvalid: false,
			allPolicySections: undefined,
			selectedItemIndex: null,
			isAddingNew: false,
			isEditing: false,
			newItem: null,
			claimBasic: {},
			conditionType: null,
			isNoActiveCoverPeriodError: false,
			petId: null,
			claimData: null,
			claimDeductions: [],
			isLineItemTaxChanged: false,
			totalTax: null,
			totalDiscountValue: null,
			selectedLinesIndexes: [],
			isAddingNewClaim: false,
			selectMultipleMode: selectMultipleModeEnum.disabled,
			discountsSplit: [],
			isInvoiceNumberInvalid: false,
		};
	},
	props: {
		recognizedData: {
			type: Object,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		claimId: {
			type: Number,
			required: false,
			default: null,
		},
		fileId: {
			type: Number,
			required: true,
		},
		duplicateData: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	async mounted () {
		const [policySections, claimData, claimDeductions] = await Promise.all([ClaimService.getPolicySections(this.claimId), ClaimService.getClaim(this.claimId), deductionService.getClaimDeductions(this.claimId)]);
		this.allPolicySections = policySections;
		this.claimDeductions = claimDeductions;
		const { basicData, conditions, petId } = claimData;
		this.claimBasic = basicData;
		this.conditionType = conditions[0].conditionType;
		this.petId = petId;
		this.claimData = claimData;
		this.$v.recognizedData.customerName.$touch();
		this.changeBasicClaimAmount(this.claimBasic.amount);
		this.totalDiscount = this.invoiceRecognizedTotalDiscount;
	},
	validations () {
		const dateMaxValue = this.claimData?.formType === ClaimFormType.PreAuthorisation ? () => true : maxValue(this.dateForFutureValidation); // disable 'dateInFuture' check for pre-authorisation claims

		return {
			claimBasic: {
				treatmentStart: {
					required,
					maxValue: dateMaxValue,
					minValue: moreThenOrEqual(this.claimBasic.dateOfLoss),
					deathValue: lessThenOrEqual(this.claimBasic.dateOfDeath),
					matchInvoiceTreatmentStart: lessThenOrEqual(this.invoiceLeastDate),
				},
				treatmentEnd: {
					required,
					maxValue: dateMaxValue,
					treatmentStart: moreThenOrEqual(this.claimBasic.dateOfLoss),
					minValue: moreThenOrEqual(this.claimBasic.treatmentStart),
					deathValue: lessThenOrEqual(this.claimBasic.dateOfDeath),
					matchInvoiceTreatmentEnd: moreThenOrEqual(this.invoiceGreatestDate),
				},
				amount: {
					required,
					minValue: moreThen(0),
					maxAmount: maxAmount(16),
				},
			},
			recognizedData: {
				customerName: {
					value: {
						required,
						maxLength: maxLength(16),
						matchingExisting: (value) => value === this.existingCustomerName,
					},
				},
				invoiceNumber: {
					value: {
						required,
						maxLength: maxLength(64),
					},
				},
				vendorName: {
					value: {
						required,
						maxLength: maxLength(1000),
					},
				},
			},
		};
	},
	computed: {
		...mapState(['interactionContext', 'currentClaimStepName']),
		...mapGetters(['dateOfLossLabel', 'dateForFutureValidation']),
		lineGroups () {
			const groupedLines = groupBy(this.recognizedData.items.map((item, index) => ({ ...item, index })), 'parentId');
			return groupedLines.null?.reduce((aggr, next) => {
				const children = groupedLines[next.id] ?? []; // try find children
				const items = [next, ...children];
				return [...aggr, { items }];
			}, []);
		},
		gridSizes () {
			return this.multicondition ? ['col-2', 'col-3', 'col-1', /* 'col-1', */ 'col-1', 'col-1', 'col-1', 'col-1', 'col-2'] : ['col-2', 'col-3', 'col-1', /* 'col-1',  */ 'col-1', 'col-1', 'col-0', 'col-2', 'col-2'];
		},
		existingCustomerName () {
			return `${this.interactionContext.customer.firstName} ${this.interactionContext.customer.lastName}`;
		},
		isProcessStep () {
			return this.currentClaimStepName === StepNameEnum.ClaimProcess;
		},
		errorMessages () {
			return {
				treatmentStart: {
					required: ValidationMessages.required,
					maxValue: ValidationMessages.dateInFuture,
					minValue: ValidationMessages.dateShouldBeAfter(this.dateOfLossLabel),
					deathValue: ValidationMessages.dateCannotBeAfter('Date of Death'),
					matchInvoiceTreatmentStart: 'Line dates before treatment start date',
				},
				treatmentEnd: {
					required: ValidationMessages.required,
					maxValue: ValidationMessages.dateInFuture,
					treatmentStart: ValidationMessages.dateShouldBeAfter(this.dateOfLossLabel),
					minValue: ValidationMessages.dateShouldBeAfter('Treatment From'),
					deathValue: ValidationMessages.dateCannotBeAfter('Date of Death'),
					matchInvoiceTreatmentEnd: 'Line dates after treatment end date',
				},
				amount: {
					required: ValidationMessages.required,
					minValue: ValidationMessages.greaterThanZero('Claim Amount'),
					maxAmount: ValidationMessages.maxAmount,
				},
				invoiceNumber: {
					value: {
						required: ValidationMessages.required,
						maxLength: ValidationMessages.maxLength(64),
					},
				},
				vendorName: {
					value: {
						required: ValidationMessages.required,
						maxLength: ValidationMessages.maxLength(1000),
					},
				},
				customerName: {
					value: {
						matchingExisting: `Customer name does not match existing: ${this.existingCustomerName}`,
					},
				},
				noCoverWarning: 'No Cover in force - Reject Claim',
				notMatchAmountWarning: 'The total treatments do not match the claim amount',
			};
		},
		toggleAllState: {
			get () {
				return !this.recognizedData.items.some(item => item.excluded.value);
			},
			set (newValue) {
				this.$emit('toggle-all', newValue);
			},
		},
		toggleMultiEditAllState: {
			get () {
				return this.recognizedData.items.filter(item => !this.isItemExcludedFromMultiEdit(item))
					.length === this.selectedLinesIndexes.length;
			},
			set (newValue) {
				this.onToggleSelectedAllTreatments(newValue);
			},
		},
		totalDiscount: {
			get () {
				return this.invoiceRecognizedDiscountItemsSum !== 0
					? this.invoiceRecognizedDiscountItemsSum
					: this.totalDiscountValue;
			},
			set (newValue) {
				this.totalDiscountValue = newValue;
			},
		},
		invoiceItemsSum () {
			const sum = this.recognizedData.items.filter(item => !item.excluded.value && item.itemType?.value !== InvoiceLineType.Discount)
				.reduce((sum, item) => sum + this.getInvoiceLineAmount(item), 0);

			const totalTax = this.invoiceRecognizedTaxItemsSum ? 0 : (this.totalTax == null ? this.invoiceRecognizedTotalTax : this.totalTax);
			return parseFloat(currency(sum + (this.taxesIncluded ? 0 : totalTax) - this.totalDiscount, this.locale, true));
		},
		invoiceRecognizedTotalTax () {
			return parseFloat(currency(this.recognizedData.totalTax?.value || 0, this.locale, true));
		},
		taxesIncluded () {
			return !!this.recognizedData.taxesIncluded?.value || !!this.invoiceRecognizedTaxItemsSum;
		},
		invoiceRecognizedTaxItemsSum () {
			const sum = this.recognizedData.items.filter(item => !item.excluded.value)
				.reduce((sum, item) => sum + this.getInvoiceLineTaxValue(item), 0);
			return parseFloat(currency(Math.max(sum, 0), this.locale, true));
		},
		invoiceRecognizedTotalDiscount () {
			const positiveTotalDiscountValue = Math.abs(this.recognizedData.totalDiscount?.value || 0);
			return parseFloat(currency(positiveTotalDiscountValue, this.locale, true));
		},
		invoiceRecognizedDiscountItemsSum () {
			const sum = this.recognizedData.items.filter(item => !item.excluded.value)
				.reduce((sum, item) => sum + this.getInvoiceLineDiscountValue(item), 0);
			return parseFloat(currency(sum, this.locale, true));
		},
		highlightedTreatmentEnd: function () {
			return {
				dates: [
					this.invoiceGreatestDate ? this.invoiceGreatestDate : new Date(),
				],
			};
		},
		highlightedTreatmentStartDate: function () {
			return {
				dates: [
					this.invoiceLeastDate ? this.invoiceLeastDate : new Date(),
				],
			};
		},
		invoiceItemsDate () {
			return this.recognizedData.items.filter(item => !item.excluded.value)
				.map(item => fromStringToDate(item.date.value));
		},
		invoiceLeastDate () {
			if (this.invoiceItemsDate.length === 0) {
				return null;
			}
			return new Date(Math.min(...this.invoiceItemsDate));
		},
		invoiceGreatestDate () {
			if (this.invoiceItemsDate.length === 0) {
				return null;
			}
			return new Date(Math.max(...this.invoiceItemsDate));
		},
		totalAmountMatchInvoices () {
			return this.invoiceItemsSum === this.claimBasic.amount;
		},
		isClaimDetailsInvalid () {
			const { treatmentStart, treatmentEnd, amount } = this.$v.claimBasic;
			return treatmentStart.$error
				|| treatmentEnd.$error
				|| amount.$error;
		},
		isVendorNameInvalid () {
			this.$v.recognizedData.vendorName.$touch();
			return this.$v.recognizedData.vendorName.value.$error;
		},
		currencySymbol () {
			return getCurrencyByLocale(this.locale).symbol;
		},
		isSelectMultipleDisabled () {
			return this.selectMultipleMode === selectMultipleModeEnum.disabled;
		},
		isMultiEditMode () {
			return this.selectMultipleMode === selectMultipleModeEnum.multiEdit;
		},
		isSplitDiscountMode () {
			return this.selectMultipleMode === selectMultipleModeEnum.discountSplit;
		},
		isEditMode () {
			return this.isEditing || this.isAddingNew;
		},
		selectedLineItemAmounts () {
			return this.selectedLinesIndexes.map(index => ({
				index,
				amount: this.recognizedData.items[index].amount.value,
			}),
			);
		},
		selectedLineItemsForSplit () {
			return this.selectedLinesIndexes.map(index => this.recognizedData.items[index])
				.filter(line => line.id && !line.parentId); // allowed to split
		},
		petNames () {
			const petNames = this.recognizedData.items.map(item => item.petName?.value);
			return unique(petNames).filter(Boolean).join(', ');
		},
		conditions () {
			return this.claimData?.conditions.map(c => ({
				id: c.id,
				description: toClaimConditionDescription(c),
				parentConditionAilment: c.parentConditionAilment?.firstCause,
			})) ?? [];
		},
	},
	watch: {
		async 'claimBasic.treatmentStart' (newValue, oldValue) {
			if (newValue === null) {
				this.isNoActiveCoverPeriodError = false;
				return;
			}
			this.$v.claimBasic.treatmentStart.$touch();
			if (this.$v.claimBasic.treatmentStart.$invalid) {
				return;
			}
			if (!areDatesEqual(newValue, oldValue)) {
				await this.checkActiveCoverPeriod();
			}
		},
		'invoiceRecognizedTaxItemsSum' () {
			this.isLineItemTaxChanged = true;
		},
		'claimBasic.treatmentEnd' () {
			this.$v.claimBasic.treatmentEnd.$touch();
		},
	},
	methods: {
		...mapActions(['changeBasicClaimAmount']),
		highlightCustomerName () {
			const highlightSettings = {
				page: 1,
				boundingBox: this.recognizedData.customerName.boundingBox,
			};
			this.highlightArea(highlightSettings);
		},
		highlightInvoiceNumber () {
			const highlightSettings = {
				page: 1,
				boundingBox: this.recognizedData.invoiceNumber.boundingBox,
			};
			this.highlightArea(highlightSettings);
		},
		highlightVendorName () {
			const highlightSettings = {
				page: 1,
				boundingBox: this.recognizedData.vendorName.boundingBox,
			};
			this.highlightArea(highlightSettings);
		},
		checkLineDuplicate (line) {
			return this.duplicateData.some(item => item.amount === line.amount.value
                                                    && item.description === line.description.value
                                                    && item.date === line.date.value
                                                    && item.quantity === line.quantity.value);
		},
		async checkActiveCoverPeriod () {
			if (!this.claimBasic.treatmentStart || !this.conditionType) return;
			const conditionTypeId = this.conditionType.id;
			const res = await ClaimService.validatePolicy(this.petId, this.claimBasic.treatmentStart, this.claimBasic.dateOfLoss, conditionTypeId);
			this.isNoActiveCoverPeriodError = !res;
		},
		onProceed () {
			this.onInputInvoiceNumber();
			if (this.isInvoiceNumberInvalid || this.isClaimDetailsInvalid || this.isNoActiveCoverPeriodError) {
				return;
			}

			const claimData = this.toApiClaimBasicData();
			const isBasicDataSame = this.claimBasic.dateOfLoss === this.claimBasic.treatmentStart === this.claimData.basicData.treatmentStart
																&& this.claimBasic.treatmentEnd === this.claimData.basicData.treatmentEnd;
			this.$emit('invoice-passed', this.invoiceItemsSum, this.invoiceRecognizedTotalTax, this.invoiceRecognizedTaxItemsSum, this.totalTax, this.isLineItemTaxChanged, this.totalDiscountValue, isBasicDataSame, claimData);
		},
		onSetValidity (obj, index) {
			this.validated[index] = {
				index,
				isInvalid: obj.isInvalid,
			};
			this.isInvoiceInvalid = this.validated.some(x => x.isInvalid);
		},
		onToggle (value, index) {
			this.$emit('toggle', { value, index });
		},
		onItemHighlight (event, index) {
			this.selectedItemIndex = index;
			this.highlightArea(event);
		},
		highlightArea (highlightSettings) {
			this.$emit('highlight', highlightSettings);
		},
		onMultipleTreatmentSelected (value, index) {
			if (value) {
				this.selectedLinesIndexes.push(index);
			} else {
				const selectedTreatmentIdIndex = this.selectedLinesIndexes.indexOf(index);
				this.selectedLinesIndexes.splice(selectedTreatmentIdIndex, 1);
			}
		},
		isMultipleTreatmentSelected (index) {
			return this.selectedLinesIndexes.indexOf(index) !== -1;
		},
		onToggleSelectedAllTreatments (selectAll) {
			this.selectedLinesIndexes = [];
			if (selectAll) {
				this.recognizedData.items.forEach((item, index) => {
					if (!this.isItemExcludedFromMultiEdit(item)) {
						this.selectedLinesIndexes.push(index);
					}
				});
			}
		},
		onSelectSplitDiscount (isSelected) {
			this.onSelectMultipleModeUpdate(selectMultipleModeEnum.discountSplit, isSelected);
		},
		onSelectMultiEdit (isSelected) {
			this.onSelectMultipleModeUpdate(selectMultipleModeEnum.multiEdit, isSelected);
		},
		onSelectMultipleModeUpdate (mode, isSelected) {
			this.selectMultipleMode = isSelected ? mode : selectMultipleModeEnum.disabled;
			this.onToggleSelectedAllTreatments(false);
		},
		isItemExcludedFromMultiEdit (item) {
			return this.isSplitDiscountMode && item.itemType.value !== InvoiceLineType.Treatment;
		},
		getPreAppliedDiscount (index) {
			return this.isSplitDiscountMode
				? this.discountsSplit.find(item => item.index === index)
				: null;
		},
		addNewItem () {
			this.isAddingNew = true;
			const vetFeesPolicy = this.allPolicySections.find(policy => policy.description === VetFeesPolicySection);
			let defaultCondition = this.conditions[0];
			if (vetFeesPolicy) {
				defaultCondition = this.conditions.filter(c => c.id === vetFeesPolicy.claimConditionId)[0];
			}
			const defaults = {
				...vetFeesPolicy && { PolicySection: { fieldName: 'PolicySection', value: vetFeesPolicy } },
				...defaultCondition && { ClaimCondition: { fieldName: 'ClaimCondition', value: defaultCondition } },
			};
			this.newItem = toInvoiceScanItem(defaults);
			this.showDocumentPreview();
		},
		onFinishCreate (value) {
			this.isAddingNew = false;
			this.$emit('finish-edit', { value, newItem: this.newItem });
		},
		onStartEdit () {
			this.isEditing = true;
			this.scrollTop();
		},
		onFinishEdit (value, index) {
			this.isEditing = false;
			this.$emit('finish-edit', { value, index });
		},
		onLineTypeSelected (value, index) {
			this.$emit('line-type-selected', { value, index });
		},
		onCancel () {
			this.isEditing = false;
			this.isAddingNew = false;
		},
		onDelete (index) {
			this.$emit('delete', index);
			this.validated.splice(index, 1);
		},
		onAddClaimCondition () {
			this.$modal.show('add-claim-condition');
		},
		addClaimCondition (newClaimCondition) {
			this.$modal.hide('add-claim-condition');
			ClaimService.getPolicySections(this.claimId).then(policySections => {
				this.allPolicySections = policySections;
				this.claimData.conditions.push(newClaimCondition);
				eventBus.$emit('invoice-panel-add-claim-condition', { newClaimCondition, policySections });
			});
		},
		onAddClaim () {
			this.isAddingNewClaim = !this.isAddingNewClaim;
		},
		closeAddClaimLayout () {
			this.isAddingNewClaim = false;
		},
		async createNewClaim (claimData) {
			await ClaimService.createLinkedClaim(this.claimId, this.fileId, claimData);
			this.isAddingNewClaim = false;
		},
		scrollTop () {
			this.$refs.container.scrollTop = 0;
			this.showDocumentPreview();
		},
		toApiClaimBasicData () {
			const saveAsDraft = this.claimData.conditions.some(cc => cc.ailment === null || cc.bodyPart === null || cc.conditionType === null);
			return new ClaimInput(
				this.claimBasic.description,
				this.claimBasic.dateOfLoss,
				this.claimBasic.dateOfDeath,
				this.claimBasic.treatmentStart,
				this.claimBasic.treatmentEnd,
				this.claimBasic.amount,
				this.claimData.conditions.map(cc => ({
					id: cc.id,
					ailmentId: cc.ailment?.id,
					bodyPartId: cc.bodyPart?.id,
					conditionTypeId: cc.conditionType?.id,
					parentConditionId: cc.parentConditionId,
					ignorePreExistingCondition: cc.wasIgnoredPreExistingCondition,
					ignoreNoProductCoverage: cc.wasIgnoredNoProductCoverage,
					ignoreNoCoverPeriod: cc.wasIgnoredNoCoverPeriod,
					ignoreWaitingPeriod: cc.wasIgnoredWaitingPeriod,
					rejectionData: cc.rejectionData,
				})),
				this.claimData.vets.map(vet => vet.id),
				this.claimData.isRequestTriggered,
				saveAsDraft,
				false,
				[],
				null,
				this.claimData.formType,
				this.claimData.payees,
				null,
				true,
				this.claimData.wasIgnoredDuplicateClaim,
				this.claimData.wasIgnoredDuplicateInvoice,
			);
		},
		showDocumentPreview () {
			this.$emit('show-document-preview');
		},
		onTotalTaxEdit ({ value, included }) {
			this.totalTax = value;
			this.recognizedData.taxesIncluded.value = included;
			this.showDocumentPreview();
		},
		onTotalDiscountEdit ({ value }) {
			this.totalDiscount = value;

			if (this.discountsSplit?.length) {
				this.$emit('finish-discounts-split', this.discountsSplit);
			}
			this.showDocumentPreview();
		},
		openMultiEditModal () {
			this.$modal.show('multi-edit-line-item-modal');
		},
		onFinishMultiEdit (value) {
			this.$emit('finish-multi-edit', { indexes: this.selectedLinesIndexes, value });
			this.selectMultipleMode = selectMultipleModeEnum.disabled;
			this.onToggleSelectedAllTreatments(false);
		},
		openSplitModal () {
			this.$modal.show('split-lines-modal');
		},
		onSplitLine (index) {
			this.selectedLinesIndexes = [index];
			this.openSplitModal();
		},
		onCreateLineItemCopies (lineGroups) {
			this.selectMultipleMode = selectMultipleModeEnum.disabled;
			this.selectedItemIndex = lineGroups.length > 1 ? null : this.selectedItemIndex;
			this.$emit('finish-split-edit', lineGroups);
		},
		getInvoiceLineAmount (line) {
			const lineAmount = parseFloat(line.amount.value) || 0;
			return line.itemType?.value === InvoiceLineType.Discount ? -Math.abs(lineAmount) : lineAmount;
		},
		getInvoiceLineTaxValue (line) {
			const lineAmount = parseFloat(line.amount?.value) || 0;
			const lineTaxAmount = parseFloat(line.tax?.value) || 0;
			const lineType = line.itemType?.value;
			return lineType === InvoiceLineType.SalesTax ? lineAmount : lineType === InvoiceLineType.Discount ? -Math.abs(lineTaxAmount) : lineTaxAmount;
		},
		getInvoiceLineDiscountValue (line) {
			return Math.abs(parseFloat(line.itemType?.value === InvoiceLineType.Discount ? line.amount?.value : line.discountAmount?.value) || 0);
		},
		onInputInvoiceNumber () {
			this.$v.recognizedData.invoiceNumber.$touch();
			this.isInvoiceNumberInvalid = this.$v.recognizedData.invoiceNumber.value.$error;
		},
	},
};
</script>

<style lang="scss" scoped>
.invoice-sandwich {
  font-size: 13px;
}

.items-list {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
}

.multiple-treatments {
  border: 2px solid $primary;

  &.active {
    border: 2px solid $active-color;
  }

  .multi-edit-action-container {
    background-color: $warning;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      color: var(--aqFaIcon);
    }

    &:hover {
      background-color: $warning-d-5;
      cursor: pointer;
    }
  }
}

.active-sandwich-height {
  height: calc(100% - 67px);
}

.close {
  cursor: pointer;
}

.btn-reset {
  padding: 5px 7px;
  font-size: 12px;
  font-weight: normal;

  .reset-icon {
    width: 35px;
    height: 35px;
    top: 0;
    left: 0;
    transform: scale(0.7);
  }
}

.duplicate-invoice-icon {
  color: white;
  font-size: 20px;
  width: 43px;
  padding-left: 19px;
  padding-top: 11px;
  background-color: var(--backgroundWarning);
  border-radius: 30px;
}

.lines-group {
  margin-bottom: 10px;

  .invoice-line-item:not(:last-child) {
    margin-bottom: 6px;
  }

  &.default {
    border: 2px solid #3583C7;

    .invoice-line-item:first-child {
      border-bottom: 2px solid #3583C7;
    }
  }

  &.duplicate {
    border: 2px solid #FFD3BE;

    .invoice-line-item:first-child {
      border-bottom: 2px solid #FFD3BE;
    }
  }

  &.highlighted {
    border: 2px solid $active-color;

    .invoice-line-item:first-child {
      border-bottom: 2px solid $active-color;
    }
  }
}

.line {
  margin-bottom: 10px;
  border: 1px solid transparent;

  &.highlighted {
    border: 1px solid $active-color;
  }
}

.section-header {
  background-color: $primary !important;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .scan-sub-header {
    font-size: 13px;
    color: $body-color;
  }
}

.section-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.header-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  grid-gap: 5px;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 10px;
}

.invoice-items-scan {
  height: 80%;
}
</style>
