<template>
	<div>
		<aq-sandwich
			ref="sandwich"
			header="Payment History"
			:active-class="''"
			:header-class="'pl-25'"
			class="mb-50 mr-25"
		>
			<template slot="content">
				<div>
					<div class="section-content ml-25">
						<div class="container-fluid">
							<el-table
								ref="paymentHistory"
								empty-text="The table is empty"
								:data="paymentHistoryItems"
								data-qa="paymentHistory_table_paymentHistory"
							>
								<el-table-column
									label="Recipient"
									data-qa="paymentHistory_column_recipient"
								>
									<template slot-scope="scope">
										<div class="font-weight-bold">
											{{ toRecipientLabel(scope.row.recipient.recipientType) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payee"
									data-qa="paymentHistory_column_payee"
								>
									<template slot-scope="scope">
										<div>{{ scope.row.recipient.recipientName }}</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payment Type"
									data-qa="paymentHistory_column_paymentType"
								>
									<template slot-scope="scope">
										<div>{{ scope.row.paymentType === 1 ? 'Claim' : 'Ex Gratia' }}</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Payment Details"
									data-qa="paymentHistory_column_paymentDetails"
								>
									<template slot-scope="scope">
										<span class="font-weight-bold">
											{{ getPaymentMethod(scope.row.selectedPayment.paymentMethod) }}:&nbsp;
										</span>
										<template v-if="scope.row.selectedPayment.paymentMethod === 1">
											<span>{{ scope.row.selectedPayment.payee }}</span>
										</template>
										<template v-else-if="scope.row.selectedPayment.paymentMethod === 2">
											<span>{{ scope.row.selectedPayment.payee }},&nbsp;</span>
											<span v-if="scope.row.selectedPayment.bankName">
												{{ scope.row.selectedPayment.bankName }},&nbsp;
											</span>
											<span>{{ scope.row.selectedPayment.sortCode }},&nbsp;</span>
											<span>{{ scope.row.selectedPayment.accountNumber }}</span>
										</template>
									</template>
								</el-table-column>
								<el-table-column
									label="Paid"
									data-qa="paymentHistory_column_paid"
								>
									<template slot-scope="scope">
										<div class="text-left">
											{{ scope.row.paymentAmount | currency(locale) }}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</template>
		</aq-sandwich>
	</div>
</template>

<script>
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { mapState } from 'vuex';

export default {
	name: 'PaymentRecipients',
	props: {
		paymentHistoryItems: {
			type: Array,
			required: true,
		},
		paymentTypes: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	methods: {
		getPaymentMethod (paymentMethod) {
			return this.paymentTypes.find((type) => type.value === paymentMethod).description;
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
	},
	computed: {
		...mapState(['appSettings']),
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
};
</script>
