export default class ClaimPetView {
	constructor (
		id,
		photo,
		name,
		gender,
		color,
		purchasePrice,
		microchipNumber,
		isPreExistingConditions,
		type,
		breedName,
		registeredVets,
	) {
		this.id = id;
		this.photo = photo;
		this.name = name;
		this.gender = gender;
		this.color = color;
		this.purchasePrice = purchasePrice;
		this.microchipNumber = microchipNumber;
		this.isPreExistingConditions = isPreExistingConditions;
		this.type = type;
		this.breedName = breedName;
		this.registeredVets = registeredVets;
	}
}
