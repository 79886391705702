class Vet {
	constructor (id, practiceName, address1, address2, address3, townCity, postalCode) {
		this.id = id;
		this.practiceName = practiceName;
		this.address1 = address1;
		this.address2 = address2;
		this.address3 = address3;
		this.townCity = townCity;
		this.postalCode = postalCode;
	}
}

const VetInfoContactEnum = Object.freeze({
	1: 'Phone',
	2: 'Email',
	3: 'Fax',
});

const VetInfoMedicalRecordStatus = Object.freeze({
	100: 'Inactive',
	200: 'Inactive Validated T-Rex',
	300: 'Active',
	400: 'Validated',
	500: 'Active & Validated',
	600: 'MRC',
	700: 'TREX',
	0: 'Error state',
});

export { Vet, VetInfoContactEnum, VetInfoMedicalRecordStatus };
