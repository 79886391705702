import httpClient from '@commonServices/httpClient';
import { toNotificationItems } from '@commonServices/utils/converter';

export default {
	getUnreadNotificationsCount () {
		return httpClient('/api/claims/notifications/unread/count')
			.get();
	},
	getNotifications () {
		return httpClient('/api/claims/notifications')
			.get()
			.then(toNotificationItems);
	},
	updateNotificationsStatus (ids) {
		return httpClient('/api/claims/notifications/status')
			.put(ids);
	},
};
