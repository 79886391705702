<template>
	<div
		class="d-flex flex-column h-100 pet-header-card"
		:class="{'pet-header-card--inactive': isCurrentPolicyInactive, 'cancelled': isCurrentPolicyCancelled}"
		data-qa="petHeaderCard_card_policyInfo"
	>
		<aq-product-tabs
			allow-collapse
			:policy-term-groups="policyTermGroups"
			:current-policy-term="currentPolicyTerm"
			:current-policy-term-group="currentPolicyTermGroup"
			:waiting-periods="waitingPeriods"
			@select="onSelectGroup"
			@show-product-details="onShowProductDetails"
		/>
		<div
			v-show="isExpandCardMode"
			class="h-100"
		>
			<div class="policy-term-section pt-12 px-8 flex-grow-1 h-100 w-100">
				<policy-term-shifter
					v-if="policyTerms.length"
					:policy-terms="policyTerms"
					:current-policy-term="currentPolicyTerm"
					@update-policy-term="onUpdatePolicyTerm"
				/>
				<div class="attributes policy-attributes mt-0">
					<div class="d-flex my-8 attribute">
						<div class="icon">
							<policy-wordings-icon
								:product="product"
								:policy-reference="currentPolicyTerm.policyReference"
								:policy-status="currentPolicyTerm.policyStatusDisplay"
							/>
						</div>
					</div>
					<waiting-periods-info
						v-if="waitingPeriods.length"
						class="my-8 attribute"
						:waiting-periods="waitingPeriods"
					/>
					<IntroducerInfo
						class="my-8 attribute"
						:policy-term="currentPolicyTerm"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AqProductTabs from '@commonWidgets/Products/AqProductTabs';
import PolicyTermShifter from '@commonView/CustomerPage/PolicyTermShifter';
import PolicyWordingsIcon from '@commonView/CustomerPage/PetHeaderCard/PolicyWordingsIcon';
import IntroducerInfo from '@commonView/Shared/IntroducerInfo';
import WaitingPeriodsInfo from '@commonView/Shared/WaitingPeriodsInfo';
import PetService from '@commonServices/petService';
import { toPetViewPolicyTerms, toPolicyTerms, addEndDateToWaitingPeriods, toWaitingPeriodsView } from '@commonServices/utils/converter';
import { PolicyStatus } from '@commonServices/models/PolicyStatus';

export default {
	components: {
		AqProductTabs,
		PolicyTermShifter,
		PolicyWordingsIcon,
		IntroducerInfo,
		WaitingPeriodsInfo,
	},
	props: {
		pet: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			policyTermGroups: null,
			currentPolicyTermGroup: null,
			currentPolicyTerm: null,
		};
	},
	beforeMount () {
		this.initPolicyTerms();
	},
	mounted () {
		this.$emit('update-policy-term', this.currentPolicyTerm);
	},
	computed: {
		...mapGetters(['getSelectedPolicyType', 'isExpandCardMode']),
		product () {
			return this.currentPolicyTerm.product;
		},
		policyTerms () {
			return toPolicyTerms(this.currentPolicyTermGroup.policyTerms);
		},
		waitingPeriods () {
			return toWaitingPeriodsView(addEndDateToWaitingPeriods(this.product.waitingPeriods, this.currentPolicyTerm.policyInceptionDate));
		},
		isCurrentPolicyInactive () {
			return this.currentPolicyTerm.policyStatus === PolicyStatus.CancellationPending || this.isCurrentPolicyCancelled;
		},
		isCurrentPolicyCancelled () {
			return this.currentPolicyTerm.policyStatus === PolicyStatus.Canceled;
		},
	},
	methods: {
		...mapActions(['setCurrentPolicyTermGroup']),
		initPolicyTerms () {
			this.policyTermGroups = toPetViewPolicyTerms(this.pet.policyTerms);
			const selectedPolicyType = this.getSelectedPolicyType(this.pet.id);
			const hasCurrentGroup = selectedPolicyType && this.policyTermGroups.some(group => group.group === selectedPolicyType);
			if (hasCurrentGroup) {
				this.currentPolicyTermGroup = this.policyTermGroups.find(group => group.group === selectedPolicyType);
				this.currentPolicyTerm = PetService.getCurrentPetPolicyTerm(this.currentPolicyTermGroup.policyTerms);
			} else {
				this.currentPolicyTerm = PetService.getCurrentPetPolicyTerm(this.pet.policyTerms);
				this.currentPolicyTermGroup = this.policyTermGroups.find(group => group.policyTerms.includes(this.currentPolicyTerm));
			}
		},
		onSelectGroup (policyTermGroup) {
			if (policyTermGroup === this.currentPolicyTermGroup) return;
			this.currentPolicyTermGroup = policyTermGroup;
			this.currentPolicyTerm = PetService.getCurrentPetPolicyTerm(this.currentPolicyTermGroup.policyTerms);
			this.setCurrentPolicyTermGroup({
				petId: this.pet.id,
				policyTermGroup: this.policyTermGroups[0] === policyTermGroup ? null : policyTermGroup.group, // null means default (first) so no need to store therefore delete
			});
		},
		onUpdatePolicyTerm (index) {
			this.currentPolicyTerm = this.currentPolicyTermGroup.policyTerms[index];
			this.$emit('update-policy-term', this.currentPolicyTerm);
		},
		onShowProductDetails () {
			this.$modal.show('product-details-modal');
		},
	},
};
</script>

<style lang="scss" scoped>
.policy-attributes {
  &.attribute {
    height: 61px;
  }
}

.policy-term-section {
  border: 2px solid $primary;
  border-radius: 0 0 3px 3px;
  border-top: 0;
}

.pet-header-card {
  &--inactive {
    .policy-term-section {
      border-color: var(--policyCancelledCardButton);

      ::v-deep .aqv-policy1 {
        color: var(--policyCancelledButtonText);
      }

      .header-card-item-text {
        color: var(--policyCancelledButtonText);
      }
    }
  }
}
</style>
