import { evaluate } from 'mathjs';
import { helpers } from 'vuelidate/lib/validators';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { fromStringToDate, isDate as checkIsDate } from './dateUtils';

const MAX_INT_NUMBER = 2147483647;

const lessThenOrEqual = (param) => (value) => !helpers.req(value) || (!param && param !== 0) || value <= param;
const moreThenOrEqual = (param) => (value) => !helpers.req(value) || (!param && param !== 0) || value >= param;
const moreThen = (param) => (value) => !helpers.req(value) || (!param && param !== 0) || value > param;
const notEqual = (param) => (value) => !helpers.req(value) || (!param && param !== 0) || value !== param;
const betweenLength = (min, max) => (value) => !helpers.req(value) || (helpers.len(value) >= min && helpers.len(value) <= max);
const unique = (param, comparator) => (value) => !helpers.req(value) || !param || (!param.some((x) => comparator(x, value)));
const spaces = (value) => !helpers.req(value) || (!!value.trim());
const maxAmount = (param) => (value) => !helpers.req(value) || (helpers.len(value.toString().replace(/\D/g, '')) <= param);
const isDate = (value) => !helpers.req(value) || checkIsDate(value) || fromStringToDate(value) != null;
const notEmpty = (param) => (value) => helpers.req(value);
const alphaNumAndSpace = helpers.regex('alpha', /^[a-zA-Z0-9\s]*$/);
const isPostalCode = (locale) => (value) => !helpers.req(value) || getUiSettingsByLocale(locale).postalCodeRegex.test(value);
const isBankCode = (locale) => (value) => !helpers.req(value) || getUiSettingsByLocale(locale).bankCodeRegex.test(value);
const isAccountNumber = (locale) => (value) => !helpers.req(value) || getUiSettingsByLocale(locale).accountNumberRegex.test(value);
const integerOnly = (value) => !helpers.req(value) || (/^[0-9]*$/.test(value) && value <= MAX_INT_NUMBER);

const isMathExpression = (value) => !helpers.req(value) || checkIsMathExpression(value);

function checkIsMathExpression (value) {
	try {
		evaluate(value);
		return true;
	} catch {
		return false;
	}
}

export {
	lessThenOrEqual,
	moreThenOrEqual,
	moreThen,
	notEqual,
	betweenLength,
	unique,
	spaces,
	maxAmount,
	isDate,
	notEmpty,
	alphaNumAndSpace,
	isPostalCode,
	isBankCode,
	isAccountNumber,
	integerOnly,
	isMathExpression,
};
