<template>
	<aq-modal-container
		@close="$modal.hide($parent.name)"
		title="Add a new Vet"
	>
		<div class="modal-content">
			<div class="p-20">
				<div class="row no-gutters">
					<div class="col-12 pb-12">
						<aq-form-input
							label="Vet Practice Name"
							type="text"
							v-model="vetName"
							@input="$v.vetName.$touch"
						/>
						<aq-form-input-error
							class="mt-8"
							:error-messages="errorMessages"
							:validator="$v"
							property="vetName"
						/>
					</div>
					<div class="col-12 pb-12">
						<aq-select
							label="Search Vet Address"
							:async-find="updateAddressSearchTerm"
							:loading="isAddressLoading"
							@input="onAddressSelected"
							:options="addressOptions"
							option-label="address"
							v-model="selectedAddress"
							no-result-message="No Vet Address found"
						>
							<template #default="props">
								<div class="px-10">
									<div class="py-4 select-option-header text-truncate">
										{{ props.option.name }}
									</div>
									<div class="py-4 select-option-info text-truncate">
										{{ props.option.address }}
									</div>
								</div>
							</template>
						</aq-select>
					</div>
					<div
						v-if="!isManualEnter"
						class="col-12 pt-8"
					>
						<button
							type="button"
							class="btn btn-secondary"
							@click="isManualEnter = true"
						>
							Enter Manually
						</button>
					</div>
					<template v-else>
						<div class="col-6 pb-12 pr-16">
							<aq-form-input
								label="Address Line 1"
								type="text"
								v-model="address1"
								@input="$v.address1.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="address1"
							/>
						</div>
						<div class="col-6 pb-12 pl-16">
							<aq-form-input
								label="Address Line 2"
								type="text"
								v-model="address2"
								@input="$v.address2.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="address2"
							/>
						</div>
						<div class="col-6 pb-12 pr-16">
							<aq-form-input
								label="Address Line 3"
								type="text"
								v-model="address3"
								@input="$v.address3.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="address3"
							/>
						</div>
						<div class="col-6 pb-12 pl-16">
							<aq-form-input
								label="Town City"
								type="text"
								v-model="townCity"
								@input="$v.townCity.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="townCity"
							/>
						</div>
						<div class="col-6 pb-12 pr-16">
							<aq-form-input
								label="Postal Code"
								type="text"
								v-model="postalCode"
								@input="$v.postalCode.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="postalCode"
							/>
						</div>
						<div class="col-6 pb-12 pl-16">
							<aq-form-input
								:label="uiLocaleSettings.regionCountyLabel"
								type="text"
								v-model="regionCounty"
								@input="$v.regionCounty.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="regionCounty"
							/>
						</div>
						<div class="col-6 pb-12 pr-16">
							<aq-select
								label="Country"
								option-label="name"
								:option-height="10"
								v-model="country"
								:options="countryOptions"
								:searchable="false"
								placeholder=""
								no-result-message="No Country found"
							/>
						</div>
						<div class="col-6 pb-12 pl-16">
							<aq-form-input
								label="Email Address"
								type="text"
								v-model="email"
								@input="$v.email.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="email"
							/>
						</div>
						<div class="col-6 pb-12 pr-16">
							<aq-form-input
								label="Phone Number"
								type="text"
								v-model="phone"
								@input="$v.phone.$touch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="errorMessages"
								:validator="$v"
								property="phone"
							/>
						</div>
						<div class="col-6 pb-12 pl-16" />
					</template>
				</div>
			</div>
			<div
				v-if="isManualEnter"
				class="modal-bottom d-flex align-items-center px-20 py-10"
			>
				<div class="ml-auto">
					<button
						:disabled="$v.$invalid"
						@click="onSave"
						type="button"
						class="btn btn-success"
					>
						Save
					</button>
				</div>
			</div>
			<div id="service-helper" />
		</div>
	</aq-modal-container>
</template>

<script>
import { required, maxLength, email } from 'vuelidate/lib/validators';
import debounce from 'lodash.debounce';
import { isPostalCode } from '@commonServices/utils/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import claimService from '@commonServices/claimService';
import NewVetInput from '@commonServices/models/NewVetInput';
import PlaceService from '@commonServices/placeService';
import ExtraService from '@commonServices/extraService';
import UsageReportEnum from '@commonServices/models/UsageReport';
import { getUiSettingsByLocale, currentLocale } from '@commonServices/settings/localeSettings';

export default {
	data () {
		return {
			vetName: undefined,
			address1: undefined,
			address2: undefined,
			address3: undefined,
			townCity: undefined,
			postalCode: undefined,
			regionCounty: undefined,
			country: undefined,
			email: undefined,
			phone: undefined,
			isManualEnter: false,
			isAddressLoading: false,
			selectedAddress: null,
			addressOptions: [],
			countryOptions: [],
		};
	},
	props: {
		vet: {
			type: String,
			required: false,
			default: '',
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	async mounted () {
		this.vetName = this.vet;
		this.countryOptions = await ExtraService.getCountries();
	},
	computed: {
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
		errorMessages () {
			return {
				vetName: {
					required: ValidationMessages.required,
					maxLength: ValidationMessages.maxLength1000,
				},
				address1: {
					required: ValidationMessages.required,
					maxLength: ValidationMessages.maxLength100,
				},
				address2: {
					maxLength: ValidationMessages.maxLength100,
				},
				address3: {
					maxLength: ValidationMessages.maxLength100,
				},
				townCity: {
					required: ValidationMessages.required,
					maxLength: ValidationMessages.maxLength(60),
				},
				postalCode: {
					required: ValidationMessages.required,
					isPostalCode: this.uiLocaleSettings.postalCodeErrorMessage,
				},
				regionCounty: {
					maxLength: ValidationMessages.maxLength(30),
				},
				country: {
					required: ValidationMessages.required,
				},
				email: {
					email: 'The email is not valid',
					maxLength: ValidationMessages.maxLength(254),
				},
				phone: {
					maxLength: ValidationMessages.maxLength(26),
				},
			};
		},
	},
	validations () {
		return {
			vetName: {
				required,
				maxLength: maxLength(1000),
			},
			address1: {
				required,
				maxLength: maxLength(100),
			},
			address2: {
				maxLength: maxLength(100),
			},
			address3: {
				maxLength: maxLength(100),
			},
			townCity: {
				required,
				maxLength: maxLength(60),
			},
			postalCode: {
				required,
				isPostalCode: isPostalCode(this.locale),
			},
			regionCounty: {
				maxLength: maxLength(30),
			},
			country: {
				required,
			},
			email: {
				email,
				maxLength: maxLength(254),
			},
			phone: {
				maxLength: maxLength(26),
			},
		};
	},
	methods: {
		onSave () {
			const vetModel = new NewVetInput(
				this.vetName,
				this.address1,
				this.address2,
				this.address3,
				this.townCity,
				this.postalCode,
				this.regionCounty,
				this.country ? this.country.name : undefined,
				this.email,
				this.phone,
				this.locale ?? currentLocale,
			);
			claimService.addVet(vetModel)
				.then(data => {
					vetModel.id = data.value;
					this.$emit('added-vet', vetModel);
					this.$modal.hide(this.$parent.name);
				});
		},
		updateAddressSearchTerm (term) {
			this.addressSearchTerm = term;
			this.searchAddressesAsync();
		},
		populateAddressFields (place) {
			this.resetForm();
			this.vetName = place.name;
			this.address1 = place.address1;
			this.address2 = place.address2;
			this.address3 = place.address3;
			this.townCity = place.townCity;
			this.postalCode = place.postalCode;
			this.phone = place.phone;
			this.regionCounty = place.regionCounty;
			this.country = this.countryOptions.find(country => country.name === place.country);

			// now that the details of the address have been retrieved we can set the addressDescription
			// in order to show it as the drop down item that was selected
			this.addressOptions.find(address => address.id === place.id).addressDescription = place.addressDescription;
			this.isManualEnter = true;
			this.$v.$touch();
		},
		resetForm () {
			this.vetName = null;
			this.address1 = null;
			this.address2 = null;
			this.address3 = null;
			this.townCity = null;
			this.postalCode = null;
			this.phone = null;
			this.regionCounty = null;
			this.country = null;
			this.email = null;
		},
		onAddressSelected (value) {
			if (value) {
				PlaceService.getPlace(value.id, document.getElementById('service-helper'), this.populateAddressFields);
			}
		},
		addressSearchComplete (results) {
			this.addressOptions = results;
			this.isAddressLoading = false;
			ExtraService.addUsageReport(UsageReportEnum.AddressLookups);
		},
		searchAddressesAsync: debounce(function () {
			if (this.addressSearchTerm.length >= 3) {
				this.isAddressLoading = true;
				PlaceService.searchVet(this.addressSearchTerm, document.getElementById('service-helper'), this.addressSearchComplete);
			}
		}, 1000),
	},
};
</script>
