const clickOutside = {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// firstly check if target is a child of element with directive
			// if it's not a child - trigger click outside expression
			if (!(el === event.target || el.contains(event.target) || skipElements(event.target))) {
				// specific check for tooltip
				if (event.target.lastChild?.className?.includes
					&& event.target.lastChild?.className?.includes('tooltip')) {
					return;
				}
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent, { capture: true });
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent, { capture: true });
	},
};

const skipByClassNames = ['el-loading-mask', 'el-loading-spinner', 'skip-click-outside'];

// for some elements we don't want to trigger click outside even though they are not children
function skipElements (target) {
	return skipByClassNames.some(className => target.classList.contains(className));
}

const scroll = {
	inserted (el, binding) {
		el.wheelHandler = function (evt) {
			if (binding.value) {
				binding.value(evt, el);
			}
		};
		el.addEventListener('wheel', el.wheelHandler);
	},
	unbind (el, binding) {
		el.removeEventListener('wheel', el.wheelHandler);
	},
};

export default {
	clickOutside,
	scroll,
};
