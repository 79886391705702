import WorkQueueModel from '@commonServices/models/WorkQueueModel';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';

export default class ClaimToFraudCheckModel extends WorkQueueModel {
	constructor (
		brandId,
		brandName,
		brandLocale,
		claimId,
		claimStatus,
		customerFirstName,
		customerLastName,
		customerId,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		vets,
		claimAmount,
		suggestedClaimAmount,
		ailments,
		payees,
		daysOpen,
		fraudCheckReasons,
		fraudComment,
		requestedBy,
		dateRequested,
		policyStatus,
		policies,
		policyStatusDisplay,
		assignedTo,
		formType,
		isReassessment,
		latestDashboardNote,
		latestInteraction,
	) {
		super(
			brandId,
			brandName,
			brandLocale,
			claimId,
			claimStatus,
			customerFirstName,
			customerLastName,
			customerId,
			petId,
			petName,
			petType,
			petGender,
			petDateOfDeath,
			vets,
			claimAmount,
			ailments,
			requestedBy,
			dateRequested,
			policyStatus,
			policies,
			policyStatusDisplay,
			assignedTo,
			WorkQueueType.FraudCheck,
			latestDashboardNote,
			latestInteraction,
		);

		this.suggestedClaimAmount = suggestedClaimAmount;
		this.payees = payees;
		this.daysOpen = daysOpen;
		this.fraudCheckReasons = fraudCheckReasons;
		this.fraudComment = fraudComment;
		this.formType = formType;
		this.isReassessment = isReassessment;
	}
}
