<template>
	<div class="container-fluid">
		<div
			class="row p-10"
			v-for="(item, index) in sorting"
			:key="index"
		>
			<div class="col-11">
				<aq-select
					data-qa="sorting_input_sortBy"
					:options="getSortingColumns(index)"
					:label="index === 0 ? 'Sort By' : 'Then By'"
					option-label="fieldView"
					class="w-100"
					v-model="item.value"
					no-result-message="No Sorting Columns"
					track-by="field"
					:disabled="sortSelectionDisabled(index - 1)"
				/>
			</div>
			<div class="col-1">
				<sort-direction
					class="pt-16 cursor-pointer sorting-selector"
					:direction="item.direction"
					:disabled="sortSelectionDisabled(index - 1)"
					@click.native="onChangeSortingDirection(item)"
				/>
			</div>
		</div>
		<div
			class="text-right mr-48 mt-10"
		>
			<a
				class="text-underline cursor-pointer"
				@click="onClearSort"
			>
				Clear
			</a>
		</div>
	</div>
</template>

<script>
import SortDirection from '@commonWidgets/SortDirection';
import SortingDirection from '@commonServices/models/SortingDirection';

export default {
	name: 'AqSorting',
	components: {
		SortDirection,
	},
	props: {
		sortingColumns: {
			type: Array,
			required: true,
		},
		sorting: {
			type: Array,
			required: true,
		},
	},
	watch: {
		sorting: {
			handler: function (val) {
				const deselectedItemIndex = this.findDeselectedSortColumn(val);
				if (deselectedItemIndex !== -1) {
					this.clearAllSortItems(deselectedItemIndex);
				}
			},
			deep: true,
		},
	},
	methods: {
		sortSelectionDisabled (index) {
			if (index === -1) return false;
			return !this.sorting[index].value;
		},
		findDeselectedSortColumn (newSort) {
			return newSort.findIndex(item => item.value === null);
		},
		clearAllSortItems (index) {
			this.sorting
				.forEach((item, i) => {
					if (i > index) {
						item.value = null;
						item.direction = SortingDirection.Ascending;
					}
				});
		},
		getSortingColumns (index) {
			// get all other sorting dd (except itself)
			const allSortExceptForIndex = this.sorting.filter((sortItem, i) => i !== index);
			// filter out already used sorting column values
			return this.sortingColumns.filter((item) => {
				return allSortExceptForIndex.every((sortItem) => sortItem.value !== item);
			});
		},
		onClearSort () {
			this.sorting.forEach(item => {
				item.value = null;
				item.direction = SortingDirection.Ascending;
			});
		},
		onChangeSortingDirection (item) {
			const currentSortingDirection = item.direction;
			item.direction = currentSortingDirection === SortingDirection.Ascending
				? SortingDirection.Descending
				: SortingDirection.Ascending;
		},
	},
};
</script>

<style lang="scss" scoped>
  .text-underline {
    text-decoration: underline;
    color: var(--btnLink);

    &:hover {
      color: $primary;
    }
  }
</style>
