export const PaymentMethod = Object.freeze({
	Cheque: 1,
	Bacs: 2,
});

export const PaymentMethodDisplayMap = Object.freeze({
	[PaymentMethod.Cheque]: 'Cheque',
	[PaymentMethod.Bacs]: 'Bacs',
});

export const PaymentMethodBillingDisplayMap = Object.freeze({
	[PaymentMethod.Cheque]: 'Cheque',
	[PaymentMethod.Bacs]: 'ACH',
});
