<template>
	<div class="mx-auto d-flex align-items-center">
		<div class="section-header__title">
			<span v-if="viewMode">Recently Viewed {{ viewMode }}</span>
		</div>
		<div
			class="toggle-btn d-flex"
			v-if="viewMode"
		>
			<div
				class="customers pr-10 aqv-recent-customers"
				:class="{ 'active-toggle' : viewMode === RecentlyViewedModeEnum.Customers }"
				@click="switchView(RecentlyViewedModeEnum.Customers)"
			/>
			<div
				class="claims align-self-start aqv-recent-claims "
				:class="{ 'active-toggle' : viewMode === RecentlyViewedModeEnum.Claims }"
				@click="switchView(RecentlyViewedModeEnum.Claims)"
			/>
		</div>
	</div>
</template>

<script>
import { RecentlyViewedModeEnum } from '@commonServices/settings/recentlyViewedSettings';

export default {
	name: 'RecentlyViewedSandwichHeader',
	emits: ['toggle-recently-viewed'],
	props: {
		viewMode: {
			type: String,
			required: false,
			default: '',
		},
	},
	data () {
		return {
			isVisible: true,
			RecentlyViewedModeEnum,
		};
	},
	methods: {
		switchView (mode) {
			if (this.viewMode !== mode) {
				this.$emit('toggle-recently-viewed', mode);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .btn-primary {
    right: 1rem;
    letter-spacing: 0.5px;
    font-size: 16px;
    border-radius: 10px 50px 50px 10px;
  }

  .toggle-btn {
    position: absolute;
    right: 30px;
    cursor: pointer;

    .active-toggle {
      color: $body-color;
      cursor: default;
    }
  }

  .customers {
    font-size: 30px;
    color: var(--cardIconColour);

    &:hover:not(.active-toggle) {
      color: var(--subSectionColour);
    }
  }

  .claims {
    font-size: 32px;
    color: var(--cardIconColour);

    &:hover:not(.active-toggle) {
      color: var(--subSectionColour);
    }
  }

</style>
