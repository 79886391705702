<template>
	<div
		:class="{ 'expanded': isExpandCardMode }"
		class="tabbed-card"
		data-qa="tabbed_card"
	>
		<div v-if="!showRightCard">
			<div class="d-flex mr-n10 justify-content-between">
				<div
					class="flex-grow-1 header-container"
					:class="{ 'left-header-container': isTabbed }"
				>
					<slot name="left-header" />
				</div>
				<div
					v-if="isTabbed"
					class="icon-container right-icon-container"
					@click="onShowRightCard(true)"
				>
					<slot name="right-icon" />
				</div>
			</div>
			<div>
				<slot name="left-content" />
			</div>
		</div>
		<div v-else>
			<div class="d-flex ml-n10">
				<div
					class="icon-container left-icon-container"
					@click="onShowRightCard(false)"
				>
					<slot name="left-icon" />
				</div>
				<div class="header-container pl-8">
					<slot name="right-header" />
				</div>
			</div>

			<div>
				<slot :name="isRightContentExsist ? 'right-content' : 'left-content'" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'AqTabbedCard',
	data () {
		return {
			showRightCard: false,
		};
	},
	computed: {
		...mapGetters(['isExpandCardMode']),
		isTabbed () {
			return this.$slots['left-header'] && this.$slots['left-content'] && this.$slots['right-icon'] && this.$slots['right-header'] && this.$slots['left-icon'];
		},
		isRightContentExsist () {
			return !!this.$slots['right-content'];
		},
	},
	methods: {
		onShowRightCard (isRight) {
			this.showRightCard = isRight;
		},
	},
};

</script>

<style lang="scss" scoped>
.tabbed-card {
  .header-container {
    min-width: 0;
    z-index: 1;
  }

  &.selected {
    .icon-container {
      border: 2px solid $primary;
    }

    .right-icon-container {
      border-left: 2px solid $warning;
    }

    .left-icon-container {
      border-right: 2px solid $warning;
    }
  }

  &.expanded {
    .right-icon-container, .left-icon-container {
      border-top: 2px solid var(--tabIconActive);
      border-bottom: 2px solid $primary;
    }

    .right-icon-container {
      border-right: 2px solid var(--tabIconActive);
      border-left: 2px solid $primary;
    }

    .left-icon-container {
      border-right: 2px solid $primary;
      border-left: 2px solid var(--tabIconActive);
    }

    &.selected {
      .right-icon-container {
        border-bottom: 2px solid $warning;
        border-left: 2px solid $warning;
      }

      .left-icon-container {
        border-bottom: 2px solid $warning;
        border-right: 2px solid $warning;
      }
    }
  }

  .icon-container {
    display: flex;
    border-radius: 3px 3px 0 0;
    cursor: pointer;
    background-color: var(--tabIconBg);
    padding: 0 8px;
    border: 2px solid $primary;
    margin: -10px 0;
    align-items: center;

    &:hover {
      background-color: var(--tabIconActive) !important;
    }
  }

  .icon-container > * {
    display: flex;
    align-items: center;
    opacity: 0.65;

    &.card-icon {
      background-color: var(--tabIcon) !important;
    }

    .a {
      fill: var(--tabIcon) !important;
    }
  }

  .icon-container:hover > * {
    opacity: 1;
  }

  .left-header-container ::v-deep .care-flag {
    margin-right: 74.5px !important;
  }
}
</style>
