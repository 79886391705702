import { ClaimConditionState } from '@commonServices/models/ClaimConditionState';

export function hasNoActiveCover (condition, isReadOnlyMode) {
	return (condition.isNoActiveCoverPeriodError && !condition.ignoreNoCoverPeriod)
		|| (isReadOnlyMode
			&& (condition.state === ClaimConditionState.RejectedByNoPolicyCover && !condition.ignoreNoCoverPeriod));
}

export function hasNoProductCover (condition, isReadOnlyMode) {
	return (condition.isNoProductCoverageError && !condition.ignoreNoProductCoverage)
		|| (isReadOnlyMode
			&& (condition.state === ClaimConditionState.RejectedByNoProductCover && !condition.ignoreNoProductCoverage));
}

export function hasPreEx (condition, isReadOnlyMode) {
	return (condition.preExConditionValidationResult?.hasRejection && !condition.ignorePreExistingCondition)
		|| (isReadOnlyMode
			&& ((condition.state === ClaimConditionState.RejectedByPreExistingCondition && !condition.ignorePreExistingCondition)
			|| (condition.state === ClaimConditionState.RejectedByRelatedCondition && !condition.ignorePreExistingCondition)));
}

export function hasReleatedPreEx (condition, isReadOnlyMode) {
	return (condition.preExConditionValidationResult?.rejectedByRelatedCondition && !condition.ignorePreExistingCondition)
		|| (isReadOnlyMode
			&& (condition.state === ClaimConditionState.RejectedByRelatedCondition && !condition.ignorePreExistingCondition));
}

export function hasWaitingPeriod (condition, isReadOnlyMode) {
	return (condition.isWaitingPeriodError && !condition.ignoreWaitingPeriod)
		|| (isReadOnlyMode
			&& (condition.state === ClaimConditionState.RejectedWaitingPeriod && !condition.ignoreWaitingPeriod));
}

export function isConditionSystemRejected (condition, isReadOnlyMode) {
	return [hasNoActiveCover, hasNoProductCover, hasPreEx, hasWaitingPeriod].some(check => check(condition, isReadOnlyMode));
}

export function hasManualRejection (condition, isReadOnlyMode) {
	return condition.rejectionData?.isManuallyRejected
		|| (isReadOnlyMode && condition.state === ClaimConditionState.RejectedManually);
}

export function hasOverride (condition) {
	return condition.ignoreWaitingPeriod || condition.ignoreNoCoverPeriod || condition.ignoreNoProductCoverage || condition.ignorePreExistingCondition;
}

export function getConditionOverrideOption (condition) {
	let overrideName = null;
	if (hasPreEx(condition)) {
		overrideName = 'Override Pre-Existing Condition';
	} else if (hasNoActiveCover(condition)) {
		overrideName = 'Override No Cover Period';
	} else if (hasNoProductCover(condition)) {
		overrideName = 'Override No Product Coverage';
	} else if (hasWaitingPeriod(condition)) {
		overrideName = 'Override Waiting Period';
	}
	return overrideName;
}

export function createCondition () {
	return {
		selectedAilment: null,
		selectedConditionType: null,
		selectedBodyPart: null,
		parentConditionId: null,
		parentClaimId: null,
		parentClaimDateOfLoss: null,
		rejectionData: {
			isManuallyRejected: false,
			rejectionReasonId: 0,
			rejectionReasonDescription: null,
			comment: null,
		},
		isNoActiveCoverPeriodError: false,
		ignoreNoCoverPeriod: false,
		isDOLPriorPetDOBError: false,
		isNoProductCoverageError: false,
		ignoreNoProductCoverage: false,
		isWaitingPeriodError: false,
		waitingPeriodEndDate: null,
		ignoreWaitingPeriod: false,
		preExConditionValidationResult: null,
		ignorePreExistingCondition: false,
		eligibleConditionValidationResult: null,
		predictedConditionAmount: null,
	};
}
