const wrapperId = 'content-wrapper';

export default {
	name: 'Markdown',
	props: {
		content: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			rawHtml: null,
			compiledCss: null,
		};
	},
	mounted () {
		this.extractPageComponents();
	},
	methods: {
		extractPageComponents () {
			const cssLookupResult = /<style[^>]*>(?<css>[\s\S]*)<\/style>/ig.exec(this.content);
			const css = cssLookupResult ? cssLookupResult.groups.css : '';
			this.rawHtml = this.content.replace(css, '');
			const cleanedCss = css.replace('<style>', '')
				.replace('</style>', '')
				.replaceAll('body', '')
				.replaceAll('.html-template', '');
			this.compiledCss = cleanedCss && cleanedCss.length ? this.prefixCss(cleanedCss) : '';
		},
		prefixCss (css) {
			let id = `#${wrapperId}`;
			let char;
			let nextChar;
			let isAt;
			let isIn;
			const classLen = id.length;

			// makes sure the id will not concatenate the selector
			id += ' ';

			// removes comments
			css = css.replace(/\/\*(?:(?!\*\/)[\s\S])*\*\/|[\r\n\t]+/g, '');

			// makes sure nextChar will not target a space
			css = css.replace(/}(\s*)@/g, '}@');
			css = css.replace(/}(\s*)}/g, '}}');

			for (let i = 0; i < css.length - 2; i++) {
				char = css[i];
				nextChar = css[i + 1];

				if (char === '@' && nextChar !== 'f') isAt = true;
				if (!isAt && char === '{') isIn = true;
				if (isIn && char === '}') isIn = false;

				if (
					!isIn
					&& nextChar !== '@'
					&& nextChar !== '}'
					&& (char === '}'
						|| char === ','
						|| ((char === '{' || char === ';') && isAt))
				) {
					css = css.slice(0, i + 1) + id + css.slice(i + 1);
					i += classLen;
					isAt = false;
				}
			}

			// prefix the first select if it is not `@media` and if it is not yet prefixed
			if (css.indexOf(id) !== 0 && css.indexOf('@') !== 0) css = id + css;
			return css;
		},
	},
	render (createElement) {
		const styleElement = createElement('style', {
			domProps: {
				innerHTML: this.compiledCss,
			},
		});

		const markdownElement = createElement('div', {
			attrs: { id: wrapperId },
			domProps: {
				innerHTML: this.rawHtml,
			},
		});

		return createElement('div', {}, [markdownElement, styleElement]);
	},
};
