<template>
	<p
		:class="['description', descriptionClass]"
		ref="description"
		v-tooltip="isDescriptionOverflowing && description"
	>
		{{ description }}
	</p>
</template>

<script>
export default {
	props: {
		descriptionClass: {
			type: String,
			required: false,
			default: 'fs-13',
		},
		description: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			isDescriptionOverflowing: false,
		};
	},
	mounted () {
		this.isDescriptionOverflowing = this.$refs.description
            && ((this.$refs.description.offsetHeight < this.$refs.description.scrollHeight) || (this.$refs.description.offsetWidth < this.$refs.description.scrollWidth));
	},
};
</script>

<style lang="scss" scoped>
.description {
  &.text-truncate-ml-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; // stylelint-disable value-no-vendor-prefix -- 'box' is not supported
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
