<template>
	<div
		class="pb-30"
		data-qa="registeredVetContentCard_card_registeredVetInfo"
	>
		<div class="attributes ">
			<item-attribute
				class="w-100 first-row-attribute"
				header="Address"
				:text="formattedVetAdress"
			/>
		</div>
		<div class="attributes mt-0">
			<item-attribute
				class="flex-1"
				v-for="(contact, index) in mainVetDetails"
				:key="index"
				:header="contact.header"
				:text="contact.text"
			/>
		</div>
		<div :style="{ position:'relative' }">
			<div class="additional-attributes-wrapper">
				<div
					class="additional-attributes row no-gutters attributes mt-0"
					v-if="isShowingMoreDetails"
				>
					<item-attribute
						v-for="(contact, index) in additionalVetDetails"
						:key="index"
						:header="contact.header"
						:text="contact.text"
					/>
				</div>
				<button
					v-if="additionalVetDetails.length"
					@click.stop="toggleAdditionalDetais(!isShowingMoreDetails)"
					@blur="toggleAdditionalDetais(false)"
					class="container-fluid btn btn-primary btn-view-more"
				>
					{{ additionalVetInfoButtonText }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import ItemAttribute from '../ItemAttribute.vue';
import { toFullAddress } from '@commonServices/utils/converter';

const additionalVetDetailsStartIndex = 2;

export default {
	props: {
		registeredVet: {
			type: Object,
			required: true,
		},
	},
	components: {
		ItemAttribute,
	},
	computed: {
		vetDetails: function () {
			return [
				...this.registeredVet.email ? [{ header: 'Email', text: this.registeredVet.email }] : [],
				...this.registeredVet.phoneNumber ? [{ header: 'Phone', text: this.registeredVet.phoneNumber }] : [],
				...this.registeredVet.faxNumber ? [{ header: 'Fax', text: this.registeredVet.faxNumber }] : [],
			];
		},
		mainVetDetails: function () {
			return this.vetDetails.slice(0, additionalVetDetailsStartIndex);
		},
		additionalVetDetails: function () {
			return this.vetDetails.slice(additionalVetDetailsStartIndex, this.vetDetails.length);
		},
		additionalVetInfoButtonText: function () {
			return this.isShowingMoreDetails ? 'Put Away' : 'Show More';
		},
		formattedVetAdress: function () {
			return toFullAddress(this.registeredVet);
		},
	},
	data () {
		return {
			isShowingMoreDetails: false,
		};
	},
	methods: {
		toggleAdditionalDetais (value) {
			this.isShowingMoreDetails = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.additional-attributes-wrapper {
  margin: 0 10px;
  max-width: 580px;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: var(--contentBg);

  .additional-attributes {
    box-shadow: 0 3px 6px 4px rgba($body-bg, 0.2);
  }

  ::v-deep .attribute {
    width: 100%;
  }
}

.btn-view-more {
  box-shadow: 0 3px 6px 4px rgba($body-bg, 0.2);
  border-radius: 0 0 2px 2px;
  opacity: 1;
}

.first-row-attribute {
  border-bottom: 3px solid var(--contentBg);
}

</style>
