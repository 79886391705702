<template>
	<div
		class="pt-5"
		v-if="selectedPet"
	>
		<aq-modal name="product-details-modal">
			<product-details-modal
				v-if="currentPolicyTerm"
				:policy-term-id="currentPolicyTerm.policyTermId"
			/>
		</aq-modal>
		<AqCardModeHeader title="Pet Details" />
		<div class="d-flex pet-info-wrapper">
			<bordered-button-wrapper
				v-if="customer"
				:btn-label="customerBtnLabel"
				class="flex-1 min-width-0"
				:class="{'special-highlight-card': requireSpecialLicensing(customer.address.regionCounty)}"
				:content-class="[ isCustomerContext ? 'breadcrumb-header--selected' : 'breadcrumb-header--bordered cursor-pointer' ]"
				@btn-click="onGoToCustomerPage"
				@click.native="onCustomerCardClick"
			>
				<template #content>
					<customer-card-info :user="customer" />
				</template>
			</bordered-button-wrapper>
			<template v-if="selectedPet">
				<bordered-button-wrapper
					:btn-label="petBtnLabel"
					class="px-1 flex-1 min-width-0"
					:content-class="[ isPetContext ? 'breadcrumb-header--selected' : 'breadcrumb-header--bordered',
						{ 'cursor-pointer': !isPetContext }]"
					@click.native="onPetCardClick"
				>
					<template #content>
						<pet-card-info
							:pet="selectedPet"
							:pets="pets"
							:customer-id="customer.id"
							:policy-term="currentPolicyTerm"
							selectable
						/>
					</template>
				</bordered-button-wrapper>
				<bordered-button-wrapper
					:btn-label="productBtnLabel"
					content-class="breadcrumb-header"
					class="px-0 flex-1 bordered-product min-width-0"
					:class="{'bordered-product--expanded': !isExpandCardMode }"
					@btn-click="onHandleEditProduct"
				>
					<template #content>
						<product-card-info
							:pet="selectedPet"
							@update-policy-term="onUpdatePolicyTerm"
						/>
					</template>
				</bordered-button-wrapper>
			</template>
		</div>
		<div
			v-if="$can.ViewClaims && pets.length"
			class="row claims-view mx-0 flex-nowrap"
		>
			<pet-claims
				ref="claimData"
				class="p-0 pt-10 pet-claims overflow-hidden"
				:selected-claim-id="selectedClaimId"
				:class="{ 'full-width' : !graphVisible }"
				:pet="selectedPet"
				:locale="locale"
				:show-toggle-graph-button="showToggleGraphButton"
				:current-policy-term="currentPolicyTerm"
				@continue-claim="onSelectContinuationClaim"
				@update-selection-data="updateSelectionData"
				@toggle-graph-view="toggleGraphView"
				@create-claim="onCreateClaim"
			/>
			<div class="p-0 mt-10 section-graphs col-5">
				<section-graphs
					v-if="currentPolicyTerm && graphVisible"
					:show-graph-close="showGraphClose"
					:current-policy-term="currentPolicyTerm"
					:selection-data="selectionData"
					:locale="locale"
					:is-list-view-mode="isListViewMode"
					@toggle-graph-view="toggleGraphView"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import { mapActions, mapGetters, mapState } from 'vuex';
import CustomerService from '@commonServices/customerService';
import {
	claimStatusDisplayMap,
	completeFinalClaimDisplayStatuses,
} from '@commonServices/models/ClaimStatusActionEnum';
import ClaimService from '@commonServices/claimService';
import PetClaims from '@commonView/CustomerPage/PetsLayout/PetClaims';
import SectionGraphs from '@commonView/CustomerPage/PetsLayout/SectionGraphs';
import BorderedButtonWrapper from '@commonView/CustomerPage/PetsLayout/BorderedButtonWrapper';
import GraphViewModeEnum from '@commonServices/settings/GraphViewMode';
import ClaimViewMode from '@commonServices/settings/ClaimViewMode';
import AqCardModeHeader from '@commonWidgets/AqCardModeHeader';
import { paServiceInstance } from '@commonServices/plugins/policyAdminNavigateService';
import ProductDetailsModal from '@commonView/Shared/ProductDetailsModal';
import { changePageTitle } from '@commonServices/utils/domUtils';

export default {
	components: {
		BorderedButtonWrapper,
		PetClaims,
		'section-graphs': SectionGraphs,
		AqCardModeHeader,
		ProductDetailsModal,
	},
	mixins: [rowHighlightMixin],
	data () {
		return {
			pets: [],
			customer: null,
			continuationClaimId: null,
			continuationClaimStatus: undefined,
			continuationGroup: undefined,
			selectedPet: null,
			currentPolicyTerm: null,
			selectedClaimId: null,
			selectionData: null,
		};
	},
	async mounted () {
		this.customerId = parseInt(this.$route.params.customerId);
		const currentPetId = parseInt(this.$route.params.petId);
		this.customer = await CustomerService.getCustomerInformation(this.customerId);
		this.pets = this.customer.pets;
		this.selectedPet = this.pets.find(pet => pet.id === currentPetId);
		changePageTitle(`${this.selectedPet.name} ${this.customer.lastName}`);
		this.changeInteractionContext(
			{
				customer: this.customer,
				pet: this.selectedPet,
				claim: null,
			});
		this.selectedClaimId = parseInt(this.$route.params.claimId) || null;
		if (this.selectedClaimId) {
			this.selectionData = { claimId: this.selectedClaimId, claimIds: [this.selectedClaimId], graphs: [] };
		}
	},
	computed: {
		...mapState(['appSettings']),
		...mapGetters(['isCustomerContext', 'graphViewMode', 'claimViewMode', 'requireSpecialLicensing', 'isExpandCardMode', 'isPetContext']),
		petBtnLabel () {
			return (this.$can.PolicyAdminUpdatePet && ClaimsConfig.PA_ENABLED && this.isExpandCardMode) && 'Edit Pet';
		},
		productBtnLabel () {
			return (this.$can.PolicyAdminUpdateProduct && ClaimsConfig.PA_ENABLED) && 'Edit Product';
		},
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		multicondition () {
			return this.$store.state.brand.multicondition;
		},
		allowContinueClaim () {
			return completeFinalClaimDisplayStatuses.includes(this.continuationClaimStatus);
		},
		graphVisible () {
			return !this.appSettings.toggleGraphView || this.graphViewMode === GraphViewModeEnum.Open;
		},
		showToggleGraphButton () {
			return !!this.appSettings.toggleGraphView && this.graphViewMode === GraphViewModeEnum.Closed;
		},
		showGraphClose () {
			return this.appSettings.toggleGraphView && this.graphViewMode === GraphViewModeEnum.Open;
		},
		isListViewMode () {
			return this.claimViewMode === ClaimViewMode.List;
		},
		customerBtnLabel () {
			return this.isExpandCardMode && 'View Customer';
		},
	},
	methods: {
		...mapActions(['changeInteractionContext', 'updateGraphViewMode']),
		onHandleEditProduct () {
			paServiceInstance.navigatePolicyPage(this.currentPolicyTerm.policyId);
		},
		onSelectContinuationClaim ({ claimId, claimStatus, continuationGroup }) {
			if (this.multicondition) return; // this will prevent continuation functionality for multicondition mode
			this.continuationClaimId = claimId;
			this.continuationClaimStatus = claimStatus;
			this.continuationGroup = continuationGroup;
		},
		onCreateClaim () {
			if (this.continuationClaimId) {
				let errMessage = null;
				if (this.continuationGroup && this.continuationGroup.some((claim) => claim.status === claimStatusDisplayMap.ReassessmentPending)) {
					errMessage = 'You cannot create a Continuation Claim for this group until all Reassessment Pending claims have been reassessed';
				} else if (!this.allowContinueClaim) {
					errMessage = 'You cannot continue claim in case parent claim is not in final status.';
				}
				if (errMessage) {
					this.$toasted.global.common_error({ message: errMessage });
					return;
				}
			}
			ClaimService.createClaim(this.selectedPet.id, this.continuationClaimId)
				.then(data => this.$router.push({ name: 'claim-page', params: { customerId: this.customerId, claimId: data } }));
		},

		resetContinuationClaim () {
			this.continuationClaimId = null;
			this.continuationClaimStatus = null;
			this.continuationGroup = null;
		},
		onGoToCustomerPage () {
			this.$router.push({ name: 'customer', params: { customerId: this.customerId } });
		},
		updateSelectionData ({ claimId, claimConditionId, claimIds, graphs }) {
			this.selectionData = { claimId, claimConditionId, claimIds, graphs };
		},
		onUpdatePolicyTerm (policyTerm) {
			this.currentPolicyTerm = policyTerm;
		},
		onCustomerCardClick () {
			this.changeInteractionContext(
				{
					customer: this.customer,
					pet: null,
					claim: null,
				});
		},
		onPetCardClick () {
			this.changeInteractionContext(
				{
					customer: this.customer,
					pet: this.selectedPet,
					claim: null,
				});
		},
		toggleGraphView () {
			const mode = this.graphVisible ? GraphViewModeEnum.Closed : GraphViewModeEnum.Open;
			this.updateGraphViewMode(mode);
		},
	},
};
</script>

<style lang="scss" scoped>
.create-claim-btn {
  display: block;
  margin-right: auto;
  margin-left: auto;
  transform: translateY(-50%);
}

.pet-info-wrapper {
  background: var(--contentBg);
  padding: 15px 15px 20px;
  display: flex;
  flex-basis: auto;
  gap: 15px;

  ::v-deep .title {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.06em;
    font-weight: bold;
  }

  .bordered-product {
    z-index: 1;

    &--expanded{
      &:hover {
        min-width: fit-content;
      }
    }
  }
}

.pet-claims {
  transition: flex 0.5s ease;
  transition: max-width 0.5s ease;
  transition: all 0.5s ease;
  flex: 0 0 65%;

  &.full-width {
    flex: 0 0 100%;
  }
}

.section-graphs {
  flex: 0 0 35%;
  box-shadow: -10px 0 15px 0 rgb(0 0 0 / 30%);
  overflow: hidden;
}

.claims-view {
  background: var(--contentBg);
}

</style>
