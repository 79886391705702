export default class PlaceItem {
	constructor (id, name, address) {
		this.id = id;
		this.name = name;
		this.address = address;
		this.phone = null;
		this.postalCode = null;
		this.address1 = null;
		this.address2 = null;
		this.address3 = null;
		this.townCity = null;
		this.regionCounty = null;
		this.country = null;
		this.addressDescription = null;
	};
};
