<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Adjuster Comment"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-12 pb-12">
								<aq-form-input
									label="Comment"
									type="textarea"
									v-model="comment"
									@input="$v.comment.$touch"
									:number-visible-lines="15"
								/>
								<aq-form-input-error
									class="error mt-8"
									:error-messages="$options.errorMessages"
									:validator="$v"
									property="comment"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onContinue"
						:disabled="$v.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Continue
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import { maxLength } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';

const errorMessages = {
	comment: {
		maxLength: ValidationMessages.maxLength5000,
	},
};
export default {
	errorMessages,
	props: {
		claimId: {
			type: Number,
			required: true,
		},
	},
	data () {
		return {
			comment: null,
		};
	},
	async mounted () {
		const adjusterComment = await ClaimService.getAdjusterComment(this.claimId);
		this.comment = adjusterComment.comment;
	},
	validations () {
		return {
			comment: {
				maxLength: maxLength(5000),
			},
		};
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		async onContinue () {
			await ClaimService.saveAdjusterComment(this.claimId, this.comment);
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>
