export default class ClaimItemInput {
	constructor (
		claimItemId,
		claimConditionId,
		policySectionId,
		policySectionDescription,
		amount,
		amountExpression,
		totalAmount,
		treatmentPeriod,
		userDeductions,
		fileId,
		isValid,
		nonFinancialNumber,
		rebalanceExcess,
		taxAmount) {
		this.claimItemId = claimItemId;
		this.claimConditionId = claimConditionId;
		this.policySectionId = policySectionId;
		this.policySectionDescription = policySectionDescription;
		this.amount = amount;
		this.amountExpression = amountExpression;
		this.totalAmount = totalAmount;
		this.treatmentPeriod = treatmentPeriod;
		this.userDeductions = userDeductions;
		this.fileMetadataId = fileId;
		this.isValid = isValid;
		this.nonFinancialNumber = nonFinancialNumber;
		this.rebalanceExcess = rebalanceExcess;
		this.taxAmount = taxAmount;
	}
}
