<template>
	<div class="py-25 reports">
		<div class="section-header position-relative pl-45 mx-25 ">
			<div
				class="section-header__title mx-auto"
				data-qa="reportsNavControl_text_groupName"
			>
				{{ componentInfo.groupName }}
			</div>
			<aq-page-control
				v-if="componentInfo.totalColumns > componentInfo.columnsPerPage"
				:page-size="componentInfo.columnsPerPage"
				:total="componentInfo.totalColumns"
				:vertical="false"
				@page-changed="onColumnPageChanged"
			/>
		</div>
		<div class="section-content mx-25 pb-10">
			<component
				:is="componentInfo.component"
				:current-column-page="currentColumnPage"
				:columns-per-page="componentInfo.columnsPerPage"
				:start-index="rowStartIndex"
				:items="items"
			/>
		</div>
		<div class="section-footer position-relative pl-45 mx-25">
			<aq-page-control
				v-if="paging.rowCount"
				:total="paging.rowCount"
				:page-size="paging.pageSize"
				class="ml-auto mr-20"
				@page-changed="onPageChanged"
			/>
		</div>
	</div>
</template>

<script>

import { FilterGroupEnum } from '@commonServices/models/FilterGroup';
import ClaimPayments from './ClaimPayments/ClaimPayments';
import ClaimReportsBdx from './ClaimReportsBDX/ClaimReportsBdx';
import ClaimStatusReport from './ClaimStatusReport/ClaimStatusReport';
import UserStatsReport from './UserStatsReport/UserStatsReport';
import ClaimQualityAuditReport from './ClaimQualityAuditReport/ClaimQualityAuditReport';
import ConditionsReport from './ConditionsReport/ConditionsReport';

const Reports = {
	[FilterGroupEnum.ClaimReport]: {
		groupName: 'Claim Report',
		columnsPerPage: 10,
		totalColumns: 60,
		component: 'ClaimReportsBdx',
	},
	[FilterGroupEnum.ClaimPayments]: {
		groupName: 'Claim Payments',
		columnsPerPage: 8,
		totalColumns: 15,
		component: 'ClaimPayments',
	},
	[FilterGroupEnum.ClaimStatus]: {
		groupName: 'Claim Status Report',
		columnsPerPage: 10,
		totalColumns: 29,
		component: 'ClaimStatusReport',
	},
	[FilterGroupEnum.UserStats]: {
		groupName: 'User Statistics',
		columnsPerPage: 4,
		totalColumns: 4,
		component: 'UserStatsReport',
	},
	[FilterGroupEnum.QualityAuditReport]: {
		groupName: 'Claims QA Report',
		columnsPerPage: 9,
		totalColumns: 16,
		component: 'ClaimQualityAuditReport',
	},
	[FilterGroupEnum.ConditionsReport]: {
		groupName: 'Eligible Conditions (EC)',
		columnsPerPage: 10,
		totalColumns: 10,
		component: 'ConditionsReport',
	},
};

export default {
	components: {
		ClaimPayments,
		ClaimReportsBdx,
		ClaimStatusReport,
		UserStatsReport,
		ClaimQualityAuditReport,
		ConditionsReport,
	},
	props: {
		reportGroup: {
			type: Number,
			required: true,
		},
		paging: {
			type: Object,
			required: true,
		},
		items: {
			type: Array,
			required: true,
		},
	},
	data () {
		return {
			currentColumnPage: 1,
		};
	},
	computed: {
		rowStartIndex () {
			return this.paging.rangeStart;
		},
		componentInfo () {
			return Reports[this.reportGroup];
		},
	},
	methods: {
		onColumnPageChanged (page) {
			this.currentColumnPage = page;
		},
		onPageChanged (page) {
			this.$emit('page-changed', page);
		},
	},
};
</script>

<style lang="scss" scoped>
  .aq-page-control {
    position: absolute;
    right: 20px;
  }

  .section-footer {
    height: 60px;
  }
</style>
