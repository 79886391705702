<template>
	<div>
		<aq-select
			data-qa="aqBodyParts_input_bodyPart"
			:options="bodyParts"
			label="Body Part"
			option-label="description"
			:is-valid="isValid"
			class="w-100"
			:value="value"
			track-by="id"
			@input="input($event)"
			no-result-message="No Body Parts found"
			:disabled="disabled"
		/>
	</div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
	name: 'AqBodyParts',
	props: {
		value: {
			type: Object,
			required: false,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	mounted () {
		this.loadBodyParts();
	},
	computed: {
		...mapState(['bodyParts']),
	},
	methods: {
		...mapActions(['loadBodyParts']),
		input (value) {
			this.$emit('input', value);
		},
	},
};
</script>
