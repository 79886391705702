<template>
	<div class="d-flex align-items-center">
		<aq-table-popover class="d-flex justify-content-center align-items-center cursor-pointer">
			<table aria-label="Wait Periods">
				<tr>
					<th>Cond. Type</th>
					<th>No. of Days</th>
					<th>End Date</th>
				</tr>
				<tbody>
					<tr
						v-for="waitPeriod of waitingPeriods"
						:key="waitPeriod.id"
					>
						<td>
							{{ waitPeriod.conditionType }}
						</td>
						<td>
							<div class="d-flex justify-content-center align-items-center">
								<span :class="{'light': waitPeriod.hasOverride}">{{ waitPeriod.waitAmountDays }}</span>
								<div
									class="d-flex justify-content-center align-items-center"
									v-if="waitPeriod.hasOverride"
								>
									<i class="fas fa-long-arrow-alt-right m-5" />
									<span class="font-weight-bold danger">{{ waitPeriod.waitAmountDaysOverride }}</span>
								</div>
							</div>
						</td>
						<td>
							<div class="d-flex justify-content-center align-items-center">
								<span :class="{'light': waitPeriod.hasOverride}">{{ waitPeriod.endDate | shortDate }}</span>
								<div v-if="waitPeriod.hasOverride">
									<i class="fas fa-long-arrow-alt-right m-5" />
									<span class="font-weight-bold danger">{{ waitPeriod.endDateOverride | shortDate }}</span>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<template slot="title">
				<i
					class="aqv-wait-period cursor-pointer"
					data-qa="waitingPeriodInfo_icon_openPopover"
				/>
				<div class="text-underline">
					Wait Periods
				</div>
			</template>
		</aq-table-popover>
	</div>
</template>

<script>
import AqTablePopover from '@/clients/clientCommon/components/widgets/AqTablePopover';

export default {
	name: 'WaitingPeriodsInfo',
	components: {
		AqTablePopover,
	},
	props: {
		waitingPeriods: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.danger {
  color: var(--backgroundDanger);
}

.light {
  color: $light;
}
</style>
