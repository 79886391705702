export default class CustomerView {
	constructor (
		id,
		ref,
		title,
		firstName,
		lastName,
		email,
		phoneNumber,
		mobileNumber,
		address,
		brandId,
		brandName,
		brandLocale,
		brandSuppressMissingInfo,
		brandManualStopReissue,
		pets,
		externalBrandId,
		multicondition,
		careFlag,
		secondaryContacts,
		billingSyncEnabled,
		customerCode,
		communicationPreference,
		language,
		smsNotification,
	) {
		this.id = id;
		this.ref = ref;
		this.title = title;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.mobileNumber = mobileNumber;
		this.address = address;
		this.brandId = brandId;
		this.brandName = brandName;
		this.brandLocale = brandLocale;
		this.brandSuppressMissingInfo = brandSuppressMissingInfo;
		this.brandManualStopReissue = brandManualStopReissue;
		this.pets = pets;
		this.externalBrandId = externalBrandId;
		this.multicondition = multicondition;
		this.careFlag = careFlag;
		this.secondaryContacts = secondaryContacts;
		this.billingSyncEnabled = billingSyncEnabled;
		this.customerCode = customerCode;
		this.communicationPreference = communicationPreference;
		this.language = language;
		this.smsNotification = smsNotification;
	}
}
