<template>
	<div>
		<div class="switch d-flex">
			<div
				class="slider text-center text-white"
				:class="[getClass(), sliderWidth]"
			>
				{{ options.find(option => option.type === value).value }}
			</div>
			<div
				v-for="option in options"
				:key="option.type"
				class="option text-center align-self-center"
				:class="[getSlideClass(option), sliderWidth]"
				@click="$emit('input', option.type)"
			>
				<div>{{ option.value }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AqSlider',
	props: {
		value: {
			type: Number,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
	},
	computed: {
		sliderWidth () {
			return `col-${12 / this.options.length}`;
		},
	},
	methods: {
		getClass () {
			const classes = this.options.find(option => option.type === this.value).class;
			return classes + ' index-' + this.value;
		},
		getSlideClass (slide) {
			if (slide.disabled) {
				return 'disabled';
			}
			if (this.value === slide.type) {
				return 'active';
			}
			return '';
		},
	},
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  width: 100%;
  background-color: var(--attributeBg);
  height: 40px;
  line-height: 40px;
  border-radius: 3px;

  .option {
    cursor: pointer;

    &.active {
      box-shadow: none;
      border-radius: 3px;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.65;
    }
  }

  .slider {
    position: absolute;
    content: "";
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.4s;
    background-color: white;
    z-index: 1;
    border-radius: 3px;

    &.index-2 {
      transform: translateX(100%);
    }

    &.index-3 {
      transform: translateX(200%);
    }
  }
}
</style>
