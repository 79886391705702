<template>
	<div :class="[{ 'copy': isCopy }, 'process-claim-view d-flex flex-column h-100 py-14 px-24 container-fluid']">
		<div class="row no-gutters align-items-center justify-content-between">
			<div class="d-flex align-items-center">
				<div
					data-qa="processClaimView_icon_enter"
					class="circle mr-8 cursor-default"
				>
					<i class="fas fa-sync cursor-default" />
				</div>
				<span class="font-weight-bold fs-16">Process Claim</span>
			</div>
			<div
				v-if="isCopy"
				class="d-flex"
			>
				<div class="status-info p-6">
					<span>Status:</span>
					<span class="font-weight-bold">{{ claimStatus }}</span>
				</div>
				<div />
			</div>
		</div>
		<div class="row no-gutters my-10">
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Policy Sections
			</div>
		</div>
		<div class="px-16 py-8 scrollbar">
			<div
				class="mb-5"
				v-for="[conditionDescription, claimItems] of groupedClaimItems"
				:key="conditionDescription"
			>
				<div class="font-weight-bold mb-10">
					{{ conditionDescription }}
				</div>
				<div
					class="my-10 p-16 claim-item-info"
					v-for="claimItem of claimItems"
					:key="claimItem.id"
				>
					<div class="font-weight-bold mb-10">
						{{ claimItem.policySectionDescription }}
					</div>
					<div class="row no-gutters my-16">
						<div
							class="col mr-10"
							:class="{ 'warning-border': isObjectValueDifferent(claimItem, getClaimItemForComparison(claimItem), 'treatmentPeriod')}"
						>
							<claim-info-term
								data-qa="processClaimView_infoTerm_description"
								label="Treatment Period"
								:description="claimItem.treatmentPeriod"
							/>
						</div>
						<div
							class="col mr-10"
							:class="{ 'warning-border': isObjectValueDifferent(claimItem, getClaimItemForComparison(claimItem), 'amount')}"
						>
							<claim-info-term
								data-qa="processClaimView_infoTerm_amount"
								label="Amount"
								:description="claimItem.amount"
							/>
						</div>
						<div
							class="col"
							:class="{ 'warning-border': isObjectValueDifferent(claimItem, getClaimItemForComparison(claimItem), 'userDeductionsTotal')}"
						>
							<claim-info-term
								data-qa="processClaimView_infoTerm_userDeductionsTotal"
								:label="`User Deductions (${claimItem.userDeductions.length})`"
								:description="claimItem.userDeductionsTotal"
							/>
						</div>
					</div>
					<div class="row no-gutters my-16">
						<div
							class="col mr-10"
							:class="{ 'warning-border': isObjectValueDifferent(claimItem, getClaimItemForComparison(claimItem), 'systemDeductionsTotal')}"
						>
							<claim-info-term
								data-qa="processClaimView_infoTerm_systemDeductionsTotal"
								:label="`System Deductions (${claimItem.systemDeductions.length})`"
								:description="claimItem.systemDeductionsTotal"
							/>
						</div>
						<div
							class="col mr-10"
							:class="{ 'warning-border': isObjectValueDifferent(claimItem, getClaimItemForComparison(claimItem), 'nonFinancialNumber')}"
						>
							<claim-info-term
								data-qa="processClaimView_infoTerm_nonFinancialNumber"
								label="No of Days/Sessions"
								:description="claimItem.nonFinancialNumber"
							/>
						</div>
						<div
							class="col"
							:class="{ 'warning-border': isObjectValueDifferent(claimItem, getClaimItemForComparison(claimItem), 'totalAmount')}"
						>
							<claim-info-term
								data-qa="processClaimView_infoTerm_totalAmount"
								label="Total"
								:description="claimItem.totalAmount"
							/>
						</div>
					</div>
					<div class="row no-gutters">
						<div class="separator" />
					</div>
					<div class="row no-gutters my-16 justify-content-between">
						<div>
							<div class="fs-12 font-weight-bold">
								User Deductions:
							</div>
							<p v-if="!claimItem.userDeductions.length">
								N/A
							</p>
							<p
								v-for="(userDeduction, userDeductionIndex) of claimItem.userDeductions"
								:key="userDeductionIndex"
								class="fs-14 deduction-text"
								:class="{ 'warning-border': isObjectValueDifferent(userDeduction, claimComparisonUserDeductions[userDeductionIndex], 'description') ||
									isObjectValueDifferent(userDeduction, claimComparisonUserDeductions[userDeductionIndex], 'amount') }"
							>
								{{ userDeduction.description }}: {{ userDeduction.amount | currency }}
							</p>
						</div>
						<div class="mr-50 pr-50">
							<div class="fs-12 font-weight-bold">
								System Deductions:
							</div>
							<p v-if="!claimItem.systemDeductions.length">
								N/A
							</p>
							<p
								v-for="(systemDeduction, systemDeductionIndex) of claimItem.systemDeductions"
								:key="systemDeductionIndex"
								class="fs-14 deduction-text"
								:class="{ 'warning-border': isObjectValueDifferent(systemDeduction, claimComparisonSystemDeductions[systemDeductionIndex], 'description') ||
									isObjectValueDifferent(systemDeduction, claimComparisonSystemDeductions[systemDeductionIndex], 'amount') }"
							>
								{{ getDeductionDescription(systemDeduction, claimItem) }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import groupBy from 'lodash.groupby';
import ClaimInfoTerm from '@commonView/ClaimPage/ClaimVersions/ClaimInfoTerm';
import { toClaimDisplayStatus } from '@commonServices/utils/converter';
import { currency } from '@commonServices/utils/filters';
import { deductionTypeEnum, getDeductionDescription, getDeductionOriginalLimitDescription } from '@commonServices/models/DeductionTypeEnum';
import { isObjectValueDifferent } from '@commonServices/utils/general';

export default {
	name: 'ProcessClaimView',
	components: {
		ClaimInfoTerm,
	},
	props: {
		isCopy: {
			type: Boolean,
			required: false,
			default: false,
		},
		claim: {
			type: Object,
			required: true,
		},
		claimComparison: {
			type: Object,
			required: false,
		},
	},
	computed: {
		claimStatus () {
			return toClaimDisplayStatus(this.claim.status);
		},
		claimComparisonClaimItems () {
			return this.claimComparison?.claimItems ?? [];
		},
		claimComparisonUserDeductions () {
			return this.claimComparison?.userDeductions ?? [];
		},
		claimComparisonSystemDeductions () {
			return this.claimComparison?.systemDeductions ?? [];
		},
		groupedClaimItems () {
			return Object.entries(groupBy(this.claim.claimItems, 'claimConditionDescription'));
		},
	},
	methods: {
		isObjectValueDifferent,
		getComparisonClaimItems (index) {
			return this.getObjectFromArray(this.claimComparisonClaimItems, index);
		},
		getComparisonDeductions (comparisonDeductions, index) {
			return this.getObjectFromArray(comparisonDeductions, index);
		},
		getObjectFromArray (array, index) {
			if (!array || array.length === 0 || array[index] === undefined) {
				return null;
			} else {
				return array[index];
			}
		},
		getDeductionDescription (systemDeduction, claimItem) {
			const deductionDescription = getDeductionDescription(systemDeduction.description);
			let originalLimitDescription = '';
			if (systemDeduction.description === deductionTypeEnum.CoInsuranceDeduction
			|| systemDeduction.description === deductionTypeEnum.PartialCoverageDeduction) {
				originalLimitDescription = getDeductionOriginalLimitDescription(claimItem.claimItemDeductions, systemDeduction.description);
			}

			return `${deductionDescription} ${originalLimitDescription} : ${currency(systemDeduction.amount)}`;
		},
		getClaimItemForComparison (claimItem) {
			return this.claimComparisonClaimItems[this.claim.claimItems.indexOf(claimItem)];
		},
	},
};
</script>
<style lang="scss" scoped>
  .process-claim-view {
    padding: 14px 24px;

    .scrollbar {
      overflow-y: auto;
      height: 75%;
    }

    .circle {
      background: rgba($primary, 0.2);

      i {
        color: var(--cardIconColour);
      }
    }

    .claim-item-info {
      border: 2px solid #cecece;
      border-radius: 3px;
    }

    .deduction-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: var(--claimInfoTermText);
    }

    .separator {
      width: 100%;
      border-top: 1px solid #cecece;
    }

    .status-info {
      border-radius: 3px;
      background: var(--claimInfoTerm);
    }

    .section-label {
      background: $primary;
    }

    &.copy {
      .section-label {
        background: var(--claimVersionsSection);
      }

      .circle {
        background: var(--copyStepIcon);

        i {
          color: var(--bodyColour);
        }
      }

      ::v-deep .claim-info-term {
        background: var(--claimInfoTerm);

        .label {
          color: var(--bodyColour);
        }

        .description {
          color: var(--claimInfoTermText);
        }
      }
    }

    .warning-border {
      border: 2px solid $warning;
      border-radius: 3px;
    }
  }

</style>
