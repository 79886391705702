<template>
	<div :class="{ 'd-flex align-items-center' : label }">
		<div
			v-if="label"
			class="mr-20"
		>
			{{ label }}
		</div>
		<label
			class="switch flex-shrink-0"
			:class="sliderClass"
		>
			<input
				:checked="value"
				:disabled="disabled"
				type="checkbox"
				@click="onCheck"
				:class="bgColor"
			>
			<span
				class="slider round"
				:class="bgColor"
			/>
		</label>
	</div>
</template>

<script>
export default {
	name: 'AqToggler',
	props: {
		color: {
			type: String,
			required: false,
			default: 'bg-success',
		},
		label: {
			type: String,
			required: false,
		},
		value: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		sliderClass: {
			type: String,
			required: false,
		},
	},
	data () {
		return {
			checked: this.value,
		};
	},
	methods: {
		onCheck (event) {
			this.checked = event.target.checked;
			this.$emit('input', this.checked);
		},
	},
	watch: {
		value (newValue) {
			this.checked = newValue;
		},
	},
	computed: {
		bgColor () {
			return !this.checked || this.disabled ? '' : `${this.color}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider::before {
      transform: translateX(35px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: #ccc;
    transition: 0.4s;

    &.slider::before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: 3px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      box-shadow: 0 0 2px 0 #000;
    }

    &.round {
      border-radius: 28px;

      &::before {
        border-radius: 50%;
      }
    }
  }

  &.theme-toggle {
    .slider {
      &::before {
        content: "\f043";
        font-family: "Font Awesome 5 Free"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        font-weight: 900;
        line-height: 23px;
        font-size: 13px;
      }
    }
  }

  &.vertical {
    height: 65px;
    width: 28px;

    input {
      &:checked + .slider::before {
        transform: translateY(-35px);
      }
    }
  }
}
</style>
