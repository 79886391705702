<template>
	<aq-tabbed-card :class="{ 'selected': selectable && isPetContext }">
		<template #left-header>
			<pet-header
				:pet="pet"
				:pets="pets"
				:customer-id="customerId"
				:disable-pet-link="disablePetLink"
			/>
		</template>
		<template
			v-if="isExpandCardMode"
			#left-content
		>
			<div
				class="row no-gutters attributes"
				data-qa="petContentCard_card_petInfo"
			>
				<pet-attributes
					:pet="pet"
					:policy-term="policyTerm"
					:hidden-attributes="['policyReference']"
					class="col-md-12"
				/>
			</div>
		</template>
		<template
			v-if="registeredVet"
			#right-icon
		>
			<RegisteredVetAvatar class="registered-vet-avatar" />
		</template>
		<template
			v-if="registeredVet"
			#right-header
		>
			<registered-vet-header :registered-vet="registeredVet" />
		</template>
		<template
			v-if="registeredVet"
			#left-icon
		>
			<aq-pet-avatar
				:pet="{
					name: pet.name,
					type: pet.type,
					gender: pet.gender,
					dateOfDeath: pet.dateOfDeath
				}"
				class="cursor-pointer"
			/>
		</template>
		<template
			v-if="isExpandCardMode && registeredVet"
			#right-content
		>
			<registered-vet-content :registered-vet="registeredVet" />
		</template>
	</aq-tabbed-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AqTabbedCard from '@commonWidgets/AqTabbedCard';
import { RegisteredVetAvatar } from '@assets/icons';
import PetAttributes from '@commonView/CustomerPage/PetsLayout/PetAttributes';
import PetHeader from '@commonView/Shared/Pet/PetHeader';

export default {
	components: {
		AqTabbedCard,
		RegisteredVetAvatar,
		PetAttributes,
		PetHeader,
	},
	props: {
		pet: {
			type: Object,
			required: true,
		},
		pets: {
			type: Array,
			required: false,
			default: null,
		},
		customerId: {
			type: Number,
			required: false,
			default: null,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: false,
		},
		policyTerm: {
			type: Object,
			required: false,
			default: null,
		},
		disablePetLink: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			contacts: [],
			selectedContactId: 0,
		};
	},
	computed: {
		...mapState(['appSettings']),
		...mapGetters(['isPetContext', 'requireSpecialLicensing', 'isExpandCardMode']),
		registeredVet () {
			return this.pet.registeredVets.find(rv => !rv.isHistorical);
		},
	},
	methods: {
		onContactSelected (value) {
			this.selectedContactId = value.id;
		},
		onCustomerCareFlagsUpdated (careFlags) {
			this.user.careFlag = careFlags;
		},
	},
};
</script>

<style lang="scss" scoped>
.registered-vet-avatar .a {
  fill: var(--cardIconBg);
}
</style>
