const EligibleConditionTypeEnum = Object.freeze({
	MarkedEligible: 100,
	GuaranteeOfCover: 101,
});

const EligibleConditionTypeEnumMap = Object.freeze({
	[EligibleConditionTypeEnum.MarkedEligible]: 'Marked Eligible',
	[EligibleConditionTypeEnum.GuaranteeOfCover]: 'Guarantee of Cover',
});

export { EligibleConditionTypeEnum, EligibleConditionTypeEnumMap };
