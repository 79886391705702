export default class ClaimInput {
	constructor (
		fnolDescription,
		dateOfLoss,
		dateOfDeath,
		treatmentFromDate,
		treatmentToDate,
		amount,
		conditions,
		vetIds,
		triggeredRequest,
		saveAsDraft,
		fraudCheck,
		fraudCheckReasons,
		fraudCheckComment,
		claimForType,
		payeeTypes,
		reassessmentOutcomeId,
		refineRejection,
		ignoreDuplicateClaim,
		ignoreDuplicateInvoice) {
		this.fnolDescription = fnolDescription;
		this.dateOfLoss = dateOfLoss;
		this.dateOfDeath = dateOfDeath;
		this.treatmentFromDate = treatmentFromDate;
		this.treatmentToDate = treatmentToDate;
		this.amount = amount;
		this.conditions = conditions;
		this.vetIds = vetIds;
		this.triggeredRequest = triggeredRequest;
		this.saveAsDraft = saveAsDraft;
		this.fraudCheck = fraudCheck;
		this.fraudCheckReasons = fraudCheckReasons;
		this.fraudCheckComment = fraudCheckComment;
		this.formType = claimForType;
		this.payeeTypes = payeeTypes;
		this.reassessmentOutcomeId = reassessmentOutcomeId;
		this.refineRejection = refineRejection;
		this.ignoreDuplicateClaim = ignoreDuplicateClaim;
		this.ignoreDuplicateInvoice = ignoreDuplicateInvoice;
	}
};
