var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$can.ViewCustomerAndPet)?_c('div',[(_vm.customer)?_c('div',{staticClass:"pt-5"},[_c('AqCardModeHeader',{attrs:{"title":'Customer Details'}}),_c('div',{staticClass:"row customer-info-wrapper",class:{'special-highlight-card': _vm.requireSpecialLicensing(_vm.customer.address.regionCounty)}},[(_vm.customer)?_c('bordered-button-wrapper',{staticClass:"col-xl-4 col-lg-6",attrs:{"btn-label":_vm.customerBtnLabel},on:{"btn-click":_vm.onHandleEditCustomer},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('customer-card-info',{attrs:{"user":_vm.customer}})]},proxy:true}],null,false,2332941382)}):_vm._e(),(_vm.registeredVet)?_c('bordered-button-wrapper',{staticClass:"col-xl-4 col-lg-6",attrs:{"btn-label":_vm.customerBtnLabel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('registered-vet-header',{attrs:{"registered-vet":_vm.registeredVet}}),(_vm.isExpandCardMode)?_c('registered-vet-content',{attrs:{"registered-vet":_vm.registeredVet}}):_vm._e()]},proxy:true}],null,false,2375970898)}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"container-fluid"},[_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
				create: _vm.$shortkeys.createClaim,
				view: _vm.$shortkeys.proceed,
			}),expression:"{\n\t\t\t\tcreate: $shortkeys.createClaim,\n\t\t\t\tview: $shortkeys.proceed,\n\t\t\t}"}],on:{"shortkey":_vm.onCreateOrViewClaim}},[_c('aq-list-navigation-control',{staticClass:"pet-layout row",attrs:{"list":_vm.pets,"current-item":_vm.currentItem},on:{"current-changed":_vm.onSelect},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return _vm._l((slotProps.list),function(pet,index){return _c('div',{key:index,staticClass:"col-xl-4 col-lg-6",class:{ 'col-xl-6': slotProps.list.length < 3 }},[_c('pet-card',{class:[
								_vm.currentItem === pet ? 'border border-warning' : 'unselected-pet-card cursor-pointer',
								'mt-30 border-invisible',
							],attrs:{"pet":pet,"locale":_vm.locale,"selected-policy-number":_vm.selectedPolicyNumber},on:{"view-click":function($event){return _vm.onViewPet(index)}},nativeOn:{"click":function($event){return _vm.onSelect(pet)}}})],1)})}}],null,false,3344494395)})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }