<template>
	<div
		id="app"
		v-shortkey="$shortkeys.help"
		@shortkey="onHelpOpen"
	>
		<aq-modal name="shortcuts-modal">
			<aq-shortcuts-modal />
		</aq-modal>
		<aq-task-notification-container ref="taskNotificationContainer" />
		<div v-if="loaded">
			<aq-header :user="currentUser" />
			<aq-navbar
				@toggle-theme="onToggleTheme"
				@toggle-shortkey="onToggleShortkey"
				@home-animation-end="onFinishHomeBtnAnimation"
				@home-page-navigate="onHomePageNavigated"
				:is-toggle-theme="isDefaultTheme"
				:is-shortkey-enabled="shortKeyEnabled"
				:nav-bar-items="navBarItems"
			/>
			<transition
				name="fade"
				mode="out-in"
			>
				<div
					class="content"
					sticky-container
				>
					<router-view :key="$route.fullPath" />
				</div>
			</transition>
			<aq-footer />
			<aq-tasks />
			<aq-activity-tracker />
			<aq-user-activity />
		</div>
	</div>
</template>

<script>
import SecurityService from '@commonServices/securityService';
import CommonService, { MenuItemsLabel } from '@commonServices/commonService';
import { mapActions, mapState, mapGetters } from 'vuex';
import eventBus from '@commonServices/eventBus';
import { ThemeIconsSettings } from '@commonServices/settings/themeSettings';
import AqActivityTracker from '@commonWidgets/AqActivityTracker';
import AqUserActivity from '@commonView/Shared/AqUserActivity';
import { definePermissions } from '@commonServices/settings/access';
import persistentStorage from '@commonServices/utils/persistentStorage';
import AqTaskNotificationContainer from '@commonLayout/taskNotifications/AqTaskNotificationContainer';
import { resetPageTitle } from '@commonServices/utils/domUtils';

const StorageKeyTheme = 'appTheme';
const Themes = ['dark', 'light'];
const DefaultThemeIndex = 1;

export default {
	name: 'App',
	components: {
		AqActivityTracker,
		AqUserActivity,
		AqTaskNotificationContainer,
	},
	data () {
		return {
			source: '',
			currentThemeIndex: persistentStorage.get(StorageKeyTheme, DefaultThemeIndex, Number),
			navBarItems: [],
			user: null,
		};
	},
	computed: {
		...mapState(['currentUser']),
		...mapGetters(['stateLoaded', 'shortKeyEnabled']),
		isDefaultTheme () {
			return this.currentThemeIndex === DefaultThemeIndex;
		},
		loaded () {
			return this.user != null && this.currentUser != null && this.stateLoaded;
		},
		isDashboard () {
			return this.$route.name === 'dashboard';
		},
	},
	methods: {
		...mapActions(['setNotificationsCountAsync', 'changeThemeIconSettings', 'initialize', 'loadUserState', 'setShortKeyEnabled', 'loadAssignedTasks']),
		onHelpOpen () {
			this.$modal.show('shortcuts-modal');
		},
		onToggleTheme () {
			this.currentThemeIndex = this.currentThemeIndex === Themes.length - 1 ? 0 : this.currentThemeIndex + 1;
			persistentStorage.set(StorageKeyTheme, this.currentThemeIndex);
			this.applyTheme();
		},
		onToggleShortkey () {
			this.setShortKeyEnabled(!this.shortKeyEnabled);
		},
		applyTheme () {
			const el = document.body;
			for (let index = 0; index < Themes.length; index++) {
				el.classList.remove(Themes[index]);
			}
			const className = Themes[this.currentThemeIndex];
			el.classList.add(className);
			this.changeThemeIconSettings(ThemeIconsSettings[this.currentThemeIndex]);
		},
		setRouteGuards (to) {
			if (this.$can) {
				switch (to.name) {
				case 'user': return this.$can.ViewCustomerAndPet;
				case 'claim-page': return this.$can.ViewClaimData;
				case 'tasks': return CommonService.checkTasksPagePermission(this.$can, to.params);
				case 'reports': return this.$can.ViewReport;
				case 'letters': return this.$can.ViewLetters;
				case 'zippedLetters': return this.$can.ViewLetters;
				default: return true;
				}
			}
			return true;
		},
		onTaskAssigned (task) {
			if (this.currentUser?.id === task.userId) {
				this.animateHomeIcon();
				this.setNotificationsCountAsync();
				this.loadAssignedTasks();
			}
		},
		onUserOptInChanged ({ userId }) {
			if (this.currentUser?.id === userId) {
				this.loadUserState();
			}
		},
		onTaskUnAssigned (userId) {
			if (this.currentUser?.id === userId) {
				this.setNotificationsCountAsync();
				this.loadAssignedTasks();
			}
		},
		animateHomeIcon () {
			const homeMenuItem = this.navBarItems.find(item => item.label === MenuItemsLabel.Home);
			const currentIconClass = homeMenuItem.iconClass.split(' ');
			const animateClasses = ['pulse-menu-item', 'require-attention'];
			homeMenuItem.iconClass = [...currentIconClass, ...animateClasses].join(' ');
		},
		onFinishHomeBtnAnimation () {
			const homeMenuItem = this.navBarItems.find(item => item.label === MenuItemsLabel.Home);
			const currentIconClass = homeMenuItem.iconClass.split(' ');
			const animationClassesToExclude = ['pulse-menu-item'];
			if (this.$route.name === 'dashboard') {
				animationClassesToExclude.push('require-attention');
			}
			homeMenuItem.iconClass = currentIconClass.filter(iconClass => !animationClassesToExclude.includes(iconClass)).join(' ');
		},
		onHomePageNavigated () {
			this.$refs.taskNotificationContainer.acknowledgeAll();

			const homeMenuItem = this.navBarItems.find(item => item.label === MenuItemsLabel.Home);
			const currentIconClass = homeMenuItem.iconClass.split(' ');
			homeMenuItem.iconClass = currentIconClass.filter(iconClass => iconClass !== 'require-attention').join(' ');
		},
	},
	async mounted () {
		this.applyTheme();
		this.user = await SecurityService.getUser();
		if (!this.user) return;
		this.initialize();
		const permissions = this.user.profile.role;
		definePermissions(permissions);
		this.navBarItems = CommonService.getMenuItems(this.$can);
		this.$router.beforeEach((to, from, next) => {
			const isRouteAllowed = this.setRouteGuards(to);
			if (isRouteAllowed) {
				next();
				resetPageTitle();
			} else {
				this.$toasted.global.common_error({ message: 'You don\'t have permissions to see this data' });
				next(false);
			}
		});

		eventBus.$on('task-assigned', this.onTaskAssigned);
		eventBus.$on('task-unassigned', this.onTaskUnAssigned);
		eventBus.$on('userOptIn-changed', this.onUserOptInChanged);
	},
	beforeDestroy () {
		eventBus.$off('task-assigned', this.onTaskAssigned);
		eventBus.$off('task-unassigned', this.onTaskAssigned);
		eventBus.$off('userOptIn-changed', this.onUserOptInChanged);
	},
	destroyed () {
		const el = document.body;
		el.classList.remove('light');
		el.classList.remove('dark');
	},
};
</script>

<style lang="scss">
@import "../../styles/app";

#app {
  position: relative;
  min-height: 100%;
  overflow: hidden;
}

.content {
  margin-left: 80px;
  margin-top: $headerHeight;
  margin-bottom: 45px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.2s;
}
</style>
