<template>
	<div class="tab-container">
		<div class="d-flex">
			<button
				class="btn btn-tab py-20 text-white d-flex align-items-center justify-content-center position-relative"
				v-for="item in items"
				:key="item.label"
				:class="{ 'active' : activeTab.value === item.value }"
				@click="$emit('select-tab', item.value)"
			>
				<i
					v-if="item.icon"
					:class="item.icon"
				/>
				<span
					:class="item.labelClass"
				>
					{{ item.label }}
				</span>
				<slot
					name="details"
					:item="item"
				>
					<span v-if="item.details">
						{{ item.details }}
					</span>
				</slot>
			</button>
		</div>
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AqTabSelector',
	props: {
		items: {
			type: Array,
			required: true,
		},
		activeTab: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-tab {
  background: none;
  border-radius: 0;
  width: 100%;
  border-bottom: 2px solid;
  opacity: 0.6;

  &.active {
    border-color: $active-color;
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
