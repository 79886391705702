<template>
	<aq-image-avatar
		class="small brand-image"
		:file-uri="brandLogo"
		:alt-text="brandName"
	/>
</template>

<script>

import FileService from '@commonServices/fileService';

export default {
	name: 'AqBrandImageAvatar',
	props: {
		brandId: {
			type: Number,
			default: undefined,
			required: false,
		},
		brandName: {
			type: String,
			required: true,
		},
	},
	computed: {
		brandLogo () {
			return FileService.getBrandLogo(this.brandId);
		},
	},
};
</script>
