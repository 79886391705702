<template>
	<div>
		<aq-card
			class="note-card"
			:name="note.title"
			type="note"
			:action="noteActions"
			:icon-class="iconClass"
			:user="noteUser"
			@select-action="$emit('select-action', { id: note.id, action: $event })"
		>
			<template slot="subName">
				<p
					class="fs-12 my-3"
					v-tooltip="subNameTooltip"
				>
					{{ subName }}
				</p>
			</template>
			<template slot="content">
				<div class="bottom mt-2 py-10 px-20 d-flex align-items-center">
					<aq-more-less-toggle
						v-if="note.details.length < noteDetailsLengthThreshold"
						:text="note.details"
					/>
					<aq-comment
						v-else
						:data="note.details"
						:line-clamp="3"
						class="toggler-below"
						popover-class="note-details-popover"
					>
						<template #commentTrigger="props">
							<aq-more-less-toggler
								:opened="props.opened"
							/>
						</template>
						<template #commentTitle>
							<div class="text-truncate">
								<i class="fa fa-comments fs-20" />
								{{ note.title }}
							</div>
						</template>
					</aq-comment>
				</div>
			</template>
		</aq-card>
	</div>
</template>

<script>

import { shortDateTime } from '@commonServices/utils/filters';
import { NoteTypesEnum } from '@commonServices/models/NoteTypesEnum';

export default {
	name: 'AqNote',
	props: {
		note: {
			type: Object,
			required: true,
		},
		noteDetailsLengthThreshold: {
			type: Number,
			required: false,
			default: 2000,
		},
	},
	data () {
		return {
			noteActions: [],
		};
	},
	mounted () {
		const isAlertNote = this.note.type === NoteTypesEnum.Alert;
		if (this.$can.EditNotes || (isAlertNote && this.$can.EditAlertNotes)) {
			this.noteActions.push(
				{ name: 'Edit', value: 'edit', iconClass: 'fa-pen text-primary' },
			);
		}

		if (this.$can.DeleteNotes || (isAlertNote && this.$can.DeleteAlertNotes)) {
			this.noteActions.push(
				{ name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' },
			);
		}
	},
	computed: {
		iconClass () {
			switch (this.note.type) {
			case NoteTypesEnum.Standard: return 'text-primary';
			case NoteTypesEnum.Alert: return 'text-danger';
			case NoteTypesEnum.Appeal: return 'text-warning';
			default: return 'text-primary';
			}
		},
		noteUser () {
			return this.note.updatedBy || this.note.createdBy;
		},
		subName () {
			return this.note.updatedBy ? `Updated ${shortDateTime(this.note.updatedDate)}` : `Created ${shortDateTime(this.note.createdDate)}`;
		},
		subNameTooltip () {
			return this.note.updatedBy ? `Created ${shortDateTime(this.note.createdDate)} by ${this.note.createdBy.firstName} ${this.note.createdBy.lastName}` : '';
		},
	},
};
</script>

<style lang="scss">
  .details-popover.popover.note-details-popover {
    .details-popover-content {
      max-height: 250px;
    }
  }
</style>
