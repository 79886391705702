<template>
	<aq-sandwich
		header="Comments"
		ref="sandwich"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template slot="content">
			<div class="section-content flex-grow-1 h-100">
				<div class="container-fluid">
					<aq-form-input
						data-qa="conditionForm_input_conditionComments"
						label="Internal Comments"
						type="textarea"
						:is-valid="!$v.comments.$error"
						v-model="comments"
						@input="$v.comments.$touch"
						:number-visible-lines="10"
					/>
					<aq-form-input-error
						property="comments"
						:validator="$v"
						:error-messages="$options.errorMessages"
					/>
					<slot
						name="documentCommentary"
						v-if="appSettings.documentCommentary"
					>
						<div class="delimiter" />
						<aq-select
							data-qa="conditionComments_input_preXReasons"
							:options="preXReasons"
							label="Reason"
							option-label="reason"
							track-by="id"
							v-model="selectedReason"
							@input="onInputReason"
							class="mt-10"
						/>
						<aq-form-input
							data-qa="conditionComments_input_documentCommentary"
							class="mt-10"
							type="textarea"
							label="Document Commentary"
							v-model="documentCommentary"
							@input="$v.documentCommentary.$touch"
							:number-visible-lines="10"
						/>
						<aq-form-input-error
							property="documentCommentary"
							:validator="$v"
							:error-messages="$options.errorMessages"
						/>
					</slot>
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<div class="d-flex text-right">
						<button
							class="btn btn-row mr-10 ml-auto"
							@click="$emit('cancel')"
						>
							Cancel
						</button>
						<button
							class="btn btn-primary"
							@click="onProceed(false)"
						>
							Finish
						</button>
					</div>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import extraService from '@commonServices/extraService';
import { mapState } from 'vuex';

const errorMessages = {
	comments: {
		maxLength: ValidationMessages.maxLength2000,
	},
	documentCommentary: {
		maxLength: ValidationMessages.maxLength2000,
	},
};

export default {
	name: 'ConditionComments',
	data () {
		return {
			comments: this.data.comments,
			documentCommentary: this.data.documentCommentary,
			selectedReason: null,
			preXReasons: [],
		};
	},
	mounted () {
		if (this.appSettings.documentCommentary) {
			extraService.getPreXReasons()
				.then(reasons => {
					this.preXReasons = reasons;
				});
		}
	},
	beforeDestroy () {
		this.onProceed(true);
	},
	props: {
		data: {
			type: Object,
			required: false,
			default: () => {},
		},
	},
	computed: {
		...mapState(['appSettings']),
	},
	errorMessages,
	validations () {
		return {
			comments: {
				maxLength: maxLength(2000),
			},
			documentCommentary: {
				maxLength: maxLength(2000),
			},
		};
	},
	methods: {
		onProceed (isDraft) {
			if (!this.$v.$invalid) {
				this.$refs.sandwich.onToggle();
				this.$emit('passed', { comments: this.comments, documentCommentary: this.documentCommentary }, isDraft);
			}
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		onInputReason (value) {
			if (value) {
				this.documentCommentary = value.description;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .delimiter {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px dashed $primary;
    opacity: 0.5;
  }
</style>
