<template>
	<div
		v-if="underwritingStatusId || showDefaultPaw"
		class="d-flex align-items-center justify-content-center cursor-pointer"
	>
		<PetPaw
			class="paw-print-icon skip-click-outside"
			:class="showDefaultPaw ? defaultPaw.class : 'id-' + underwritingStatusId"
			v-tooltip="{
				content: showDefaultPaw ? defaultPaw.description : underwritingStatusDescription,
				trigger: 'hover',
			}"
			@click="onPetPawClick"
		/>
	</div>
</template>

<script>
import { toastActions } from '@commonServices/settings/toastSettings';
import PetService from '@commonServices/petService';
import { PetPaw } from '@assets/icons';
import { mapState } from 'vuex';

const toastOptions = {
	action: toastActions.close,
	duration: 3000,
};

export default {
	components: {
		PetPaw,
	},
	data () {
		return {
			defaultPaw: {
				class: 'default-paw',
				description: 'Unknown',
			},
		};
	},
	props: {
		underwritingStatusId: {
			type: Number,
			default: null,
		},
		underwritingStatusDescription: {
			type: String,
			default: null,
		},
		petId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapState(['appSettings']),
		showDefaultPaw () {
			return !this.underwritingStatusId && this.appSettings.defaultMedicalRecordsSummary;
		},
	},
	methods: {
		async onPetPawClick () {
			const { medicalRecordSummaryUrl, toastMessage } = await PetService.getLatestMedicalRecordSummuryUrl(this.petId);
			if (medicalRecordSummaryUrl) {
				window.open(medicalRecordSummaryUrl);
			} else {
				this.$toasted.show(toastMessage, toastOptions);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.paw-print-icon {
  height: 33px;
  width: 28px;

  $grayPaws: id-189, id-275, id-276, id-277, id-198, id-199, id-207,id-269, id-292, id-311, id-315;

  &.id-190 {
    fill: var(--greenPawColor);
  }

  &.id-202 {
    fill: var(--orangePawColor);
  }

  @each $grayPaw in $grayPaws {
    &.#{$grayPaw} {
      fill: var(--grayPawColor);
    }
  }

  &.id-312 {
    fill: var(--pinkPawColor);
  }

  &.default-paw {
    fill: var(--grayPawColor);
  }
}
</style>
