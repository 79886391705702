<template>
	<aq-panel-wrapper>
		<div
			class="panel-container"
		>
			<div class="section-header">
				<div class="section-header__title">
					Care Flag Management
				</div>
			</div>
			<div class="flex-grow-1 d-flex flex-column">
				<div class="d-flex py-20 text-white align-items-center justify-content-center position-relative section-sub-header">
					{{ subHeader }}
				</div>
				<div class="section-content d-flex flex-grow-1 flex-column">
					<aq-level-switcher class="p-10" />
					<div class="p-10 font-weight-bold">
						Select Flags:
						<div
							:key="flag.id"
							v-for="flag in careFlags"
							class="mt-8 p-10 d-flex section-flag-content"
						>
							<div class="d-flex align-items-center">
								<component
									class="flag-icon-popover mr-10"
									:is="getCareFlagIconById(flag.id)"
								/>
								<aq-checkbox
									v-if="editableFlag(flag.id)"
									class="tick"
									:label="getCareFlagNameById(flag.id)"
									v-model="flag.isSelected"
									@input="onSelectFlag(flag)"
								/>
								<div
									v-else
									class="d-flex"
								>
									{{ getCareFlagNameById(flag.id) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from '@commonServices/eventBus';
import { hasFlag } from '@commonServices/utils/general';
import { CareFlagEnum, getCareFlags, CareFlagEnumIcon, CareFlagEnumLabel } from '@commonServices/models/CareFlagEnum';
import PetService from '@commonServices/petService';
import CustomerService from '@commonServices/customerService';

export default {
	data () {
		return {
			careFlagsData: null,
		};
	},
	async mounted () {
		this.careFlagsData = await CustomerService.getCareFlags(this.customerId);
	},
	computed: {
		...mapState(['interactionContext', 'levelFilter']),
		petId () {
			return this.levelFilter.petId;
		},
		customerId () {
			return this.interactionContext.customer.id;
		},
		isPetContext () {
			return !!this.petId;
		},
		subHeader () {
			return `${this.isPetContext ? 'Pet' : 'Customer'} Flags`;
		},
		initialCareFlags () {
			return (this.isPetContext ? this.careFlagsData?.pets.filter(p => p.id === this.petId)[0].careFlag : this.careFlagsData?.careFlag) ?? 0;
		},
		careFlags () {
			let flags = this.initialCareFlags;
			let isSelectBlockAuto = true;
			if (!hasFlag(flags, CareFlagEnum.BlockAuto)) {
				flags |= CareFlagEnum.BlockAuto;
				isSelectBlockAuto = false;
			}

			return getCareFlags(flags).map(flag => ({ id: flag, isSelected: flag === CareFlagEnum.BlockAuto ? isSelectBlockAuto : true }));
		},
	},
	methods: {
		getCareFlagIconById (flagId) {
			return CareFlagEnumIcon[flagId];
		},
		getCareFlagNameById (flagId) {
			return CareFlagEnumLabel[flagId];
		},
		editableFlag (flagId) {
			return flagId === CareFlagEnum.BlockAuto;
		},
		async onSelectFlag (flag) {
			let flags = this.initialCareFlags;
			flag.isSelected ? flags |= flag.id : flags ^= flag.id;

			if (this.isPetContext) {
				await this.updatePetCareFlags(flags);
			} else {
				await this.updateCustomerCareFlags(flags);
			}
		},
		async updateCustomerCareFlags (flags) {
			await CustomerService.updateCareFlags(this.customerId, flags);
			this.careFlagsData.careFlag = flags;
			eventBus.$emit('customer-careFlags-updated', flags);
		},
		async updatePetCareFlags (flags) {
			await PetService.updateCareFlags(this.petId, flags);
			this.careFlagsData.pets.filter(p => p.id === this.petId)[0].careFlag = flags;
			eventBus.$emit('pet-careFlags-updated', flags);
		},
	},
};
</script>

<style lang="scss" scoped>
  .section-sub-header {
    background: var(--sectionFooterBg);
    border-bottom: 2px solid $active-color;
  }

  .section-flag-content {
    background: var(--attributeBg);
  }

  .flag-icon-popover {
    height: 30px;
    width: 30px;
  }

  ::v-deep .checkbox {
    label {
      font-weight: bold !important;
    }
  }

  svg {
    fill: var(--cardIconColour);
  }
</style>
