<template>
	<div>
		<currency-input
			:value="value"
			@input="updateValue"
			:currency="null"
			:precision="2"
			:class="inputClass"
			locale="en"
			auto-decimal-mode
			:disabled="disabled"
			@focus="focusInput()"
			@blur="blurInput()"
			v-scroll="onMouseWheel"
		/>
	</div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input';
import { Currency } from '@commonServices/settings/localeSettings';

export default {
	name: 'AqMoneyInput',
	components: { CurrencyInput },
	props: {
		value: {
			type: [String, Number],
			required: false,
			default: 0,
		},
		inputClass: {
			type: String,
			required: false,
			default: '',
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		updateOnScroll: {
			type: Boolean,
			default: true,
		},
		updateStep: {
			type: Number,
			required: false,
			default: Currency.updateStep || 0.01,
		},
	},
	data () {
		return {
			currencyConfig: {
				currency: null,
				locale: 'en',
				precision: Currency.precision,
				autoDecimalMode: true,
			},
			focus: false,
		};
	},
	methods: {
		updateValue (value) {
			this.$emit('input', value);
		},
		focusInput () {
			this.focus = true;
			this.$emit('focus', true);
		},
		blurInput () {
			this.focus = false;
			this.$emit('focus', false);
		},
		onMouseWheel (event, element) {
			if (this.updateOnScroll && this.focus) {
				event.preventDefault();
				const newValue = -1 * Math.sign(event.deltaY) * this.updateStep + this.value;
				this.$emit('input', newValue);
			}
		},
	},
};
</script>
