export const keysMap = {
	help: ['shift', '?'],
	search: ['s'],
	home: ['h'],
	tasks: ['t'],
	reports: ['r'],
	letters: ['l'],
	interactions: ['i'],
	documents: ['d'],
	conditions: ['x'],
	openDdl: ['ctrl', 'enter'],
	close: ['esc'],
	down: ['tab'],
	up: ['shift', 'tab'],
	select: ['space'],
	arrUp: ['arrowup'],
	arrDown: ['arrowdown'],
	proceed: ['enter'],
	back: ['backspace'],
	cancel: ['ctrl', 'alt', 'x'],
	notes: ['n'],
	vets: ['v'],
	eligibleConditions: ['e'],
	// old one
	createClaim: ['ctrl', 'alt', 'c'],
};

export const keysMapProxy = (store) => {
	return new Proxy(keysMap, {
		get (target, property) {
			if (store.getters.shortKeyEnabled && property && property in target) {
				return target[property];
			}
			return [];
		},
	});
};

export const shortcutsHelp = [
	{
		category: 'Global shortcuts',
		keys: [
			{
				description: 'Go to search',
				values: keysMap.search,
			},
			{
				description: 'Help',
				values: keysMap.help,
			},
		],
	},
	{
		category: 'Left sidebar',
		keys: [
			{
				description: 'Home',
				values: keysMap.home,
			},
			{
				description: 'Tasks',
				values: keysMap.tasks,
			},
			{
				description: 'Reports',
				values: keysMap.reports,
			},
			{
				description: 'Letters',
				values: keysMap.letters,
			},
		],
	},
	{
		category: 'List navigation',
		keys: [
			{
				description: 'Navigate forward',
				values: keysMap.down,
			},
			{
				description: 'Navigate back',
				values: keysMap.up,
			},
		],
	},
	{
		category: 'Customer search',
		keys: [
			{
				description: 'Go to details',
				values: keysMap.proceed,
			},
		],
	},
	{
		category: 'Pets navigation',
		keys: [
			{
				description: 'View pet claims',
				values: keysMap.proceed,
			},
			{
				description: 'Create claim',
				values: keysMap.createClaim,
			},
			{
				description: 'See claim actions',
				values: keysMap.openDdl,
			},
			{
				description: 'Select claim action',
				values: keysMap.proceed,
			},
			{
				description: 'Select/unselect claim group',
				values: keysMap.select,
			},
		],
	},
	{
		category: 'Claim page',
		keys: [
			{
				description: 'Proceed',
				values: keysMap.proceed,
			},
			{
				description: 'Back',
				values: keysMap.back,
			},
			{
				description: 'Cancel',
				values: keysMap.cancel,
			},
		],
	},
	{
		category: 'Open/Close Navigation panel',
		keys: [
			{
				description: 'Open/close document panel',
				values: keysMap.documents,
			},
			{
				description: 'Open/close conditions panel',
				values: keysMap.conditions,
			},
			{
				description: 'Open/close interactions panel',
				values: keysMap.interactions,
			},
			{
				description: 'Open/close notes panel',
				values: keysMap.notes,
			},
			{
				description: 'Open/close vets panel',
				values: keysMap.vets,
			},
			{
				description: 'Open/close eligible conditions panel',
				values: keysMap.eligibleConditions,
			},
		],
	},
];
