<template>
	<div class="d-flex align-items-center">
		<slot />
		<div class="text-truncate row m-0">
			<div class="text-truncate">
				<div class="d-flex">
					<aq-tooltip-on-truncate
						:tooltip-text="heading"
					>
						<div
							class="text-truncate"
							:class="headingClass"
							data-qa="itemHeading_text_heading"
						>
							{{ heading }}
						</div>
					</aq-tooltip-on-truncate>
					<slot name="besideHeadingArea" />
				</div>
				<slot name="underHeadingArea" />
				<aq-tooltip-on-truncate
					v-if="this.paragraph"
					:tooltip-text="paragraph"
				>
					<div
						class="text-truncate fs-13 text-subtitle"
						:class="paragraphClass"
						data-qa="itemHeading_text_paragraph"
					>
						{{ paragraph }}
					</div>
				</aq-tooltip-on-truncate>
				<aq-tooltip-on-truncate
					v-if="paragraphRight"
					:tooltip-text="paragraphRight"
				>
					<div
						class="ml-auto text-truncate fs-13 text-subtitle"
						:class="paragraphClass"
						data-qa="itemHeading_text_paragraph"
					>
						{{ paragraphRight }}
					</div>
				</aq-tooltip-on-truncate>
			</div>
		</div>
		<slot name="rightArea" />
	</div>
</template>

<script>
export default {
	name: 'ItemHeading',
	props: {
		heading: {
			type: String,
			required: true,
		},
		paragraph: {
			type: String,
			required: false,
			default: '',
		},
		paragraphRight: {
			type: String,
			required: false,
			default: '',
		},
		headingClass: {
			type: String,
			required: false,
			default: '',
		},
		paragraphClass: {
			type: String,
			required: false,
			default: '',
		},
	},
};
</script>
