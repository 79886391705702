<template>
	<aq-modal :name="name">
		<aq-modal-container
			@close="close('cross')"
			title="Escalate Reasons"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="aqv-escalate-manager escalate-manager-icon" />
							<div class="col-12 escalate-title">
								Please select reason for escalation
							</div>
							<div
								v-if="haveUnsavedChanges"
								class="my-10"
							>
								You have unsaved changes. Clicking proceed will escalate claim without saving these changes.
							</div>
							<div class="col-12 pb-12">
								<aq-select
									data-qa="escalateClaimModal_select_reasons"
									:value="selectedReason"
									:options="escalateReasons"
									label="Escalation reason"
									option-label="description"
									class="w-85"
									track-by="id"
									:truncate-options="false"
									hide-tags
									:is-sorted="false"
									@input="$emit('input', $event)"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="escalateClaimModal_button_cancel"
						@click="close('no')"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="escalateClaimModal_button_proceed"
						@click="close('yes')"
						type="button"
						class="btn btn-success ml-auto"
						:disabled="!selectedReason"
					>
						Proceed
					</button>
				</div>
			</div>
		</aq-modal-container>
	</aq-modal>
</template>

<script>
import extraService from '@commonServices/extraService';
import { sortComparer } from '@commonServices/utils/general';

export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		selectedReason: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			escalateReasons: [],
			haveUnsavedChanges: false,
			promiseResolver: null,
		};
	},
	methods: {
		async show (haveUnsavedChanges) {
			this.haveUnsavedChanges = haveUnsavedChanges;
			this.escalateReasons = await extraService.getEscalateReasons();
			this.escalateReasons.sort(sortComparer('description'));
			const otherReasonIndex = this.escalateReasons.findIndex(reason => reason.description.toLowerCase() === 'other');

			if (otherReasonIndex > -1) {
				this.escalateReasons.push(this.escalateReasons.splice(otherReasonIndex, 1)[0]);
			}
			this.$modal.show(this.name);
			return new Promise((resolve) => {
				this.promiseResolver = resolve;
			});
		},
		close (result) {
			this.$modal.hide(this.name);
			this.promiseResolver(result);
		},
	},
};
</script>

<style scoped>
.escalate-manager-icon {
  fill: var(--bodyColour);
  font-size: 50px;
  margin: 0 auto 10px;
}

.escalate-title {
  padding-bottom: 12px;
  font-weight: bold;
  text-align: center;
}
</style>
