<template>
	<aq-tab-selector
		:items="tabItems"
		:active-tab="activeTab"
		@select-tab="onSelectTab"
		v-if="onSelectTab"
		class="flex-grow-1 d-flex flex-column"
	>
		<div class="section-content flex-grow-1">
			<div class="container-fluid h-100 d-flex flex-column">
				<button
					class="btn btn-primary w-100"
					:class="[underwritingStatusId ? 'mb-10': 'mb-20']"
					@click="onEditCondition(null)"
					v-if="isActiveTab && this.$can.ModifyPreExistingCondition"
				>
					Add Pre-Existing Condition
				</button>
				<div class="d-flex align-items-center justify-content-center">
					<medical-record-summary-button
						class="medical-record-summary-btn mb-10"
						:underwriting-status-id="underwritingStatusId"
						:underwriting-status-description="interactionContext.pet.underwritingStatusDescription"
						:pet-id="interactionContext.pet.id"
					/>
				</div>
				<div
					v-if="filteredConditions.length > 0"
					class="content-wrapper"
				>
					<aq-card
						v-for="condition of filteredConditions"
						:key="condition.id"
						:name="`${condition.condition.firstCause} - ${condition.bodyPart.description}`"
						:description="condition.exclusionTypeDisplay"
						:user="condition.createdBy"
						type="condition"
						:action="getOptionsList(condition)"
						@select-action="onSelectAction(condition.id, $event)"
						class="mb-10 pre-ex-condition-card"
						:class="{ 'mt-20 bordered position-relative': condition.isSystem }"
						:icon-class="condition.exclusionType === ExclusionTypeEnum.PossibleSign ? 'warning' : ''"
					>
						<template slot="subName">
							<div
								class="align-self-end px-12 py-5 external-user"
								v-if="condition.isSystem"
							>
								External User
							</div>
							<p
								class="fs-11 my-3"
								v-tooltip="getSubNameTooltip(condition)"
							>
								{{ getSubName(condition) }}
							</p>
							<p class="fs-11 mt-3">
								First Signs Date {{ getFirstSignsDate(condition) }}
							</p>
							<p
								v-if="condition.pageNumber"
								class="fs-11 my-3"
							>
								Page: {{ condition.pageNumber }}
							</p>
						</template>
						<template slot="content">
							<div
								v-if="condition.diagnosisOccurrences && condition.diagnosisOccurrences.length !== 0"
								class="bottom mt-2 py-15 px-20"
							>
								<div data-qa="conditionForm_btn_occurrenceHistory">
									<strong class="fs-14">Occurrence History</strong>
								</div>
								<DiagnosisOcurrence
									v-show="showMoreList[`history_${condition.id}`]"
									:condition="condition"
									class="mt-10"
								/>
								<div class="horizontal-rule mt-15" />
								<aq-more-less-toggler
									:opened="!!showMoreList[`history_${condition.id}`]"
									@show-more-less="onUpdateShowMoreList(`history_${condition.id}`, $event)"
								/>
							</div>
							<div
								class="bottom mt-2 py-15 px-20"
								v-if="condition.vet"
							>
								<div class="fs-14">
									<strong>Vet Details</strong>
								</div>
								<span class="fs-13">
									{{ condition.vet.practiceName }}
								</span>
								<div
									class="mt-10"
									v-show="!!showMoreList[`details_${condition.id}`]"
								>
									<vet-details-info :vet-info="condition.vet" />
								</div>
								<div class="horizontal-rule mt-15" />
								<aq-more-less-toggler
									:opened="!!showMoreList[`details_${condition.id}`]"
									@show-more-less="onUpdateShowMoreList(`details_${condition.id}`, $event)"
								/>
							</div>
							<div
								class="bottom mt-2 py-15 px-20"
								v-if="condition.relatedConditions.length > 0"
							>
								<div class="fs-14">
									<strong>Related Conditions</strong>
								</div>
								<span
									class="fs-13"
									v-for="(relatedCondition, index) of condition.relatedConditions"
									:key="`${relatedCondition.condition.id}${relatedCondition.bodyPart.id}`"
									v-tooltip="condition.relatedConditions"
								>
									{{ relatedCondition.condition.firstCause }} - {{ relatedCondition.bodyPart.description }}<span v-if="index !== condition.relatedConditions.length -1">,</span>
								</span>
							</div>
							<div
								v-if="condition.comments"
								class="bottom mt-2 py-10 px-20 d-flex align-items-center"
							>
								<aq-more-less-toggle
									:text="condition.comments"
								/>
							</div>
						</template>
					</aq-card>
				</div>
				<div
					v-else
					class="text-center my-20"
				>
					<p v-if="isActiveTab">
						There are no Pre-Existing conditions recorded for this pet
					</p>
					<p v-if="isArchivedTab">
						There are no Archived conditions recorded for this pet
					</p>
				</div>
			</div>
		</div>
	</aq-tab-selector>
</template>
<script>
import PetService from '@commonServices/petService';
import { shortDate } from '@commonServices/utils/filters';
import { ExclusionTypeEnum } from '@commonServices/models/ExclusionTypeEnum';
import diagnosisConditionsMixin from '@mixins/diagnosisConditionsMixin';
import DiagnosisOcurrence from '@commonView/Shared/ConditionsPanel/DiagnosisOcurrence';

export default {
	components: {
		DiagnosisOcurrence,
	},
	mixins: [diagnosisConditionsMixin],
	computed: {
		ExclusionTypeEnum: () => ExclusionTypeEnum,
		underwritingStatusId () {
			return this.interactionContext.pet.underwritingStatusId;
		},
	},
	methods: {
		getOptionsList (condition) {
			const options = [];
			if (this.$can.ModifyPreExistingCondition) {
				const editOption = { name: 'Edit', value: 'edit', iconClass: 'fa-edit' };
				if (condition.hasRejectedClaims) {
					editOption.tooltip = 'Some values cannot be modified as an existent claim has been already rejected by this condition';
				}
				options.push(editOption);
			}
			if (this.isActiveTab && this.$can.ArchivePreExistingCondition) {
				options.push({ name: 'Archive', value: 'archive', iconClass: 'fa-archive' });
			} else if (this.isArchivedTab && this.$can.ArchivePreExistingCondition) {
				options.push({ name: 'Unarchive', value: 'un-archive', iconClass: 'fa-archive' });
			}
			return options;
		},
		async onSelectAction (id, action) {
			switch (action) {
			case 'archive': {
				const result = await PetService.archivePreExistingCondition(id);
				const archivedCondition = this.conditions.find(cond => cond.id === id);
				archivedCondition.archived = result.archived;
				archivedCondition.updatedDate = result.updatedDate;
				archivedCondition.updatedBy = this.currentUser;
				this.activeTab = this.tabItems[1];
				break;
			}
			case 'edit': {
				const condition = this.conditions.find(item => item.id === id);
				this.onEditCondition(condition);
				break;
			}
			case 'un-archive': {
				const condition = this.conditions.find(item => item.id === id);
				const isDuplicate = this.isDuplicateCondition(condition);
				if (isDuplicate) {
					this.$emit('duplication-error', 'Current pet already contains active Pre-Existing Condition with such pre-Existing condition and exclusion period.');
					return;
				}
				const result = await PetService.unarchivePreExistingCondition(id);
				const archivedCondition = this.conditions.find(cond => cond.id === id);
				archivedCondition.archived = result.archived;
				archivedCondition.updatedDate = result.updatedDate;
				archivedCondition.updatedBy = this.currentUser;
				this.activeTab = this.tabItems[0];
				break;
			}
			}
		},
		onEditCondition (condition) {
			if (condition) {
				this.selectedCondition = condition;
			} else {
				this.selectedCondition = {
					id: null,
					condition: null,
					bodyPart: null,
					exclusionType: null,
					createdBy: {},
					comments: null,
					relatedConditions: [],
					updatedDate: null,
					diagnosisOccurrences: [],
				};
			}
			this.$emit('edit-condition', this.selectedCondition);
		},
		isDuplicateCondition (condition) {
			return this.conditions
				.some(cond => !cond.archived
					&& cond.exclusionType === condition.exclusionType
					&& cond.bodyPart.id === condition.bodyPart.id
					&& cond.condition.id === condition.condition.id,
				);
		},
		getFirstSignsDate (condition) {
			return condition.firstSignsDate ? shortDate(condition.firstSignsDate) : '-';
		},
	},
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding-right: 15px;
}

.bordered {
  border: var(--cardCornerInfo);
  border-style: solid;
}

.external-user {
  position: absolute;
  right: -3px;
  top: -15px;
  background-color: var(--cardCornerInfo);
  color: $body-color;
}

.horizontal-rule {
  border-bottom: 2px solid var(--subSectionColour);
  opacity: 1;
  flex: 1;
}

.medical-record-summary-btn {
  border: 3px solid var(--subSectionColour);
  border-radius: 50%;
  display: block;
  position: relative;
  height: 41px;
  width: 41px;

  @mixin divider {
    content:" ";
    height: 2px;
    width: 178px;
    background: var(--subSectionColour);
    display: block;
    position: absolute;
    top: 50%;
  }

  &::after {
    @include divider;

    left: 45px;
  }

  &::before {
    @include divider;

    right: 45px;
  }
}
</style>
