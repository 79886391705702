import Oidc from 'oidc-client';

const mgr = new Oidc.UserManager({
	userStore: new Oidc.WebStorageStateStore(),
	authority: ClaimsConfig.CLAIMS_IDENTITY_API,
	client_id: 'vuejsclient',
	redirect_uri: window.location.origin + '/callback.html',
	silent_redirect_uri: window.location.origin + '/silent-renew.html',
	automaticSilentRenew: true,
	accessTokenExpiringNotificationTime: 20,
	response_type: 'id_token token',
	scope: 'openid profile api',
	post_logout_redirect_uri: window.location.origin + '/logout',
	loadUserInfo: true,
});

let signinRedirectPromise = null;

class SecurityService {
	async _getUserInternal () {
		const user = await mgr.getUser();
		if (user == null || user.expired) {
			signinRedirectPromise = signinRedirectPromise ?? mgr.signinRedirect({ state: window.location.href });
			await signinRedirectPromise;
			signinRedirectPromise = null;
			return null;
		}
		return user;
	}

	async getAccessToken () {
		const user = await this._getUserInternal();
		return user ? user.access_token : null;
	}

	async getUser () {
		return this._getUserInternal();
	}

	removeUser () {
		mgr.clearStaleState();
		return mgr.removeUser();
	}

	logout () {
		mgr.clearStaleState();
		return mgr.signoutRedirect();
	}
};
const instance = new SecurityService();
export default instance;
