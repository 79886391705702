<template>
	<v-popover
		:placement="placement"
		trigger="click"
		class="details-popover-component"
		:popover-class="['details-popover', popoverClass]"
		@hide="popupOpened = false"
		@show="popupOpened=true"
		:offset="offset"
	>
		<slot
			name="trigger"
			:popup-opened="popupOpened"
		>
			<div class="details-popover-target link text-primary fs-13">
				Show more
			</div>
		</slot>
		<template slot="popover">
			<div :style="{ width: width + 'px' }">
				<div class="header">
					<div class="header-title">
						<slot name="title">
							Comment
						</slot>
					</div>
					<div class="header-actions">
						<slot name="actions">
							<button
								v-close-popover
								class="btn btn-primary"
							>
								Close
							</button>
						</slot>
					</div>
				</div>
				<div class="details-popover-content content-wrapper scrollbar">
					<slot name="customText">
						<div>
							{{ flattenedText }}
						</div>
					</slot>
				</div>
			</div>
		</template>
	</v-popover>
</template>

<script>

export default {
	name: 'AqDetailsPopover',
	props: {
		data: {
			type: String,
			required: false,
			default: '',
		},
		width: {
			type: Number,
			required: false,
			default: 300,
		},
		placement: {
			type: String,
			required: false,
			default: 'auto',
		},
		popoverClass: {
			type: String,
			required: false,
			default: null,
		},
		offset: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			popupOpened: false,
		};
	},
	computed: {
		flattenedText () {
			return this.data?.replaceAll('\\n', '\n').replaceAll('\\r', '\r');
		},
	},
};
</script>

<style lang="scss">
.details-popover-component {
  flex-shrink: 0;
}

.details-popover {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;

  &.popover {
    .tooltip-inner {
      border-radius: 2px;
      padding: 6px;
      background: var(--contentBg);
      border: 2px solid var(--modalActive);
      color: var(--bodyColour);

      .header {
        background: var(--modalHeaderBg);
      }
    }

    .popover-arrow {
      display: none;
    }

    .header {
      padding: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-title {
        min-width: 0;
      }

      .header-actions {
        margin-left: 5px;
      }
    }

    .details-popover-content {
      max-height: 150px;
      overflow: hidden auto;
      margin: 6px;

      &.scrollbar {
        padding-right: 5px;
      }
    }
  }
}
</style>
