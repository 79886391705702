<template>
	<aq-panel-wrapper>
		<div class="panel-container panel-container--left">
			<div class="panel panel--left d-flex flex-column">
				<div class="flex-shrink-0">
					<div class="section-header">
						<div class="section-header__title">
							Notifications
						</div>
						<div
							class="btn-cross text-white ml-auto mr-10 fs-28 font-weight-bold"
							@click="onClose"
						>
							&times;
						</div>
					</div>
				</div>
				<div class="section-content scrollbar">
					<div class="container-fluid">
						<div
							v-for="(group, key) of groupedNotifications"
							:key="key"
						>
							<div
								v-if="group.notificationItems.length"
								class="m-20"
							>
								{{ group.dateDescription }}
							</div>
							<notification-item
								v-for="(item, notificationKey) of group.notificationItems"
								:notification="item"
								:key="notificationKey"
								@close="onClose"
								class="px-10 my-10"
							/>
						</div>
						<div class="text-center my-20">
							<p>There are no more notifications beyond this point</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
export default {
	props: {
		groupedNotifications: {
			type: Array,
			required: true,
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
  .btn-cross {
    cursor: pointer;
  }

  .panel-container {
    max-width: 500px;
    animation: slide 0.5s forwards;
  }

  .scrollbar {
    overflow-y: auto;
    height: calc(100% - 70px);
  }
</style>
