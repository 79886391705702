import Vue from 'vue';
import Router from 'vue-router';
import ClaimPage from '@commonView/ClaimPage';
import CustomerPage from '@commonView/CustomerPage';
import TasksPage from '@commonView/TasksPage';
import ReportsPage from '@commonView/ReportsPage';
import LettersPage from '@commonView/LettersPage';
import ZippedLettersPage from '@commonView/ZippedLettersPage';
import PetPage from '@commonView/CustomerPage/PetPage';
import Navigate from '@commonView/Navigate';
import AqDashboard from '@commonView/AqDashboard';
import AqSearchResult from '@commonView/AqSearchResult';

const originalPush = Router.prototype.push;

Router.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(error => {
		if (error.name !== 'NavigationDuplicated') {
			throw error;
		}
	});
};

Vue.use(Router);

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '',
			redirect: '/dashboard',
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: AqDashboard,
		},
		{
			path: '/search',
			name: 'search-result',
			component: AqSearchResult,
		},
		{
			path: '/customer/:customerId',
			name: 'customer',
			component: CustomerPage,
		},
		{
			path: '/customer/:customerId/pet/:petId',
			name: 'pet',
			component: PetPage,
		},
		{
			path: '/customer/:customerId/claim/:claimId',
			name: 'claim-page',
			component: ClaimPage,
		},
		{
			path: '/tasks/:pot/:queueId?',
			name: 'tasks',
			component: TasksPage,
		},
		{
			path: '/reports/:group/:reportId?',
			name: 'reports',
			component: ReportsPage,
		},
		{
			path: '/letters/all/:filterSetId?',
			name: 'letters',
			component: LettersPage,
		},
		{
			path: '/letters/zipped',
			name: 'zippedLetters',
			component: ZippedLettersPage,
		},
		{
			path: '/navigate/:subject/:identifier',
			name: 'navigate',
			component: Navigate,
		},
	],
});

export default router;
