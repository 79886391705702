<template>
	<div class="d-flex align-items-center">
		<slot />
		<div
			v-if="isActive"
			class="flex-grow-1 ml-10 text-truncate"
			:class="textClass"
		>
			{{ text }}
		</div>
		<div v-if="isRemovable && isActive">
			<div
				class="icon fs-18 ml-14 my-auto"
				@click="onRemove"
				data-qa="aqExpandableCard_button_remove"
			>
				&times;
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isRemovable: {
			type: Boolean,
			required: false,
			default: false,
		},
		isActive: {
			type: Boolean,
			required: false,
			default: true,
		},
		text: {
			type: String,
			required: true,
		},
		textClass: {
			type: String,
			required: false,
		},
	},
	methods: {
		onRemove () {
			if (this.isRemovable) {
				this.$emit('remove');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .icon:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: all 0.2s;
  }
</style>
