<template>
	<div class="position-relative">
		<aq-confirmation-modal
			ref="deleteReportModal"
			name="delete-report-modal"
			title="Confirm Delete Report"
			:description="`You are about to delete '${userReportName}' report. Do you wish to continue?`"
			yes-label="Delete"
			no-label="Cancel"
			data-qa="reports-page_modal_delete-report"
		/>
		<aq-modal name="save-report-modal">
			<save-filters-modal
				label="Report"
				title="Save report"
				description="Please enter a name for your report"
				:is-name-uniq="isReportNameUniq"
				@save="onSaveReport"
				@touch="isReportNameUniq = true"
			/>
		</aq-modal>
		<aq-modal name="export-report-modal">
			<export-modal
				title="Export Report"
				@export="onExportReport"
			/>
		</aq-modal>
		<div class="px-25 py-10">
			<aq-select
				class="col-2 position-absolute"
				v-if="isChartFiltersSelected"
				data-qa="reportsPage_select_reportView"
				@input="onChangeReportView"
				:options="reportViewOptions"
				label="Select view"
				v-model="selectedReportView"
			/>
			<div
				class="d-flex justify-content-end"
				v-if="!isFiltersOpen"
			>
				<button
					class="btn btn-primary px-14"
					@click="onFiltersOpen"
					data-qa="reportsPage_button_filters"
				>
					<i class="fas fa-filter" />
					&nbsp;
					{{ filtersButtonLabel }}
				</button>
				<aq-dropdown
					v-if="$can.SaveReport"
					data-qa="reportsPage_select_save"
					label="Save"
					:options="saveOptions"
					btn-class="btn btn-primary px-14"
					:is-read-only-mode="false"
					@select="onSelectAction"
					@main-action="onToggleSave"
					:split-toggle="true"
					:height="37"
					class="ml-6"
				/>
				<button
					v-else-if="$can.ExportReport"
					class="btn btn-primary px-14 ml-6"
					@click="$modal.show('export-report-modal')"
					data-qa="reportsPage_button_filters"
				>
					Export
				</button>
			</div>
		</div>
		<report-filters-control
			v-if="isFiltersOpen"
			class="position-absolute filters"
			:report-group="reportGroup"
			:filter="filter"
			:sorting="viewSorting"
			:filter-dictionaries="filterDictionaries"
			@cancel="onCancel"
			@apply-filters="onApplyFilters"
		/>
		<aq-search-term-container
			class="px-25 py-10"
			v-if="searchTerms.length"
			:search-terms="searchTerms"
			:filter="filter"
			@remove-search-term="onRemoveSearchTerm"
		/>
		<div v-if="!isChartView && reportGroup">
			<reports-nav-control
				:report-group="reportGroup"
				:items="reportItems"
				:paging="paging"
				@page-changed="onPageChanged"
			/>
		</div>
		<reports-chart
			v-else-if="chartResult"
			:chart-data="chartResult"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FilterGroupEnum, ReportGroupDisplayMap, reportHasCharting } from '@commonServices/models/FilterGroup';
import ReportService from '@commonServices/reportService';
import Paging from '@commonServices/models/Paging';
import {
	toApiSorting,
	toReportsApiFilters, toSortingTerms,
	toReportViewFilter,
	toViewSorting,
} from '@commonServices/utils/reportsConverter';
import ReportFiltersControl from './ReportsPage/ReportFiltersControl';
import ReportsNavControl from './ReportsPage/ReportsNavControl';
import ErrorCode from '@commonServices/models/ErrorCode';
import ExportModal from '@commonView/ReportsPage/ExportModal';
import eventBus from '@commonServices/eventBus';
import { FilterType } from '@commonServices/models/FilterType';
import ReportsChart from '@commonView/ReportsPage/Charts/ReportsChart';
import AqSearchTermContainer from '@commonView/Shared/AqSearchTermContainer';
import SaveFiltersModal from '@commonView/Shared/SaveFiltersModal';
import { isKnownError } from '@commonServices/utils/general';
import { DateTypes } from '@clientCommon/services/utils/dateUtils';
import { getDisabledExportMessage }	from '@commonServices/models/ExportType';
import fileService from '@commonServices/fileService';

const reportView = Object.freeze({
	TableView: 'Table View',
	ChartView: 'Chart View',
});

export default {
	components: {
		ReportsChart,
		ReportFiltersControl,
		ReportsNavControl,
		ExportModal,
		AqSearchTermContainer,
		SaveFiltersModal,
	},
	data () {
		return {
			reportItems: [],
			reportGroup: null,
			// if reportId is populated - it's a user report
			reportId: null,
			userReportName: null,
			filters: [],
			isFiltersOpen: false,
			filter: null,
			searchTerms: [],
			paging: new Paging(),
			totalRows: 0,
			isReportNameUniq: true,
			chartResult: null,
			selectedReportView: null,
			reportViewOptions: [
				reportView.ChartView,
				reportView.TableView,
			],
			apiSorting: [],
			viewSorting: null,
			filterDictionaries: {},
		};
	},
	async mounted () {
		this.resetInteractionContext();
		this.reportGroup = parseInt(this.$route.params.group);
		this.reportId = parseInt(this.$route.params.reportId);

		// if reportId exists user report is open
		// report id is need only on user reports => to get list of saved filters and update
		let userReport = {};
		if (this.reportId) {
			// getting user filters
			userReport = await ReportService.getUserReportFilters(this.reportId);
			this.userReportName = userReport.name;
		} else {
			userReport.filters = this.getDefaultReportFilters(this.reportGroup);
		}

		this.filter = toReportViewFilter(this.reportGroup, userReport.filters);
		this.viewSorting = toViewSorting(this.reportGroup, userReport.sorting);
		this.filterDictionaries = await ReportService.getFiltersDictionaries(this.reportGroup);
		eventBus.$on('user-report-deleted', this.onUserReportDeleted);
		this.onApplyFilters();
	},
	computed: {
		...mapState(['appSettings']),
		saveOptions () {
			const saveOptions = [];
			if (this.reportId) {
				saveOptions.push({ name: 'Save as', value: 'saveAs', iconClass: 'fa-save text-primary' });

				if (this.$can.DeleteReport) {
					saveOptions.push({ name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' });
				}
			}
			if (this.$can.ExportReport) {
				saveOptions.push({
					name: 'Export',
					value: 'export',
					iconClass: 'fa-file-export text-primary',
					disabled: this.disabledExportMessage != null,
					tooltip: this.disabledExportMessage,
				});
			}
			return saveOptions;
		},
		isChartFiltersSelected () {
			return this.filters.some((filter) => filter.type === FilterType.Aggregate)
				&& this.filters.some((filter) => filter.type === FilterType.Column);
		},
		isChartView () {
			return this.isChartFiltersSelected && this.selectedReportView === reportView.ChartView;
		},
		disabledExportMessage () {
			return getDisabledExportMessage(this.paging.rowCount, this.appSettings.dataRowLimit);
		},
		filtersButtonLabel () {
			return reportHasCharting(this.reportGroup) ? 'Filters and charting' : 'Filters';
		},
	},
	methods: {
		...mapActions(['resetInteractionContext']),
		getDefaultReportFilters (reportGroup) {
			const defaultFiltes = [];
			switch (reportGroup) {
			case FilterGroupEnum.ClaimReport:
			case FilterGroupEnum.ClaimStatus:
			case FilterGroupEnum.QualityAuditReport: {
				defaultFiltes.push(...[{ type: FilterType.ClaimCreatedDateFrom, description: DateTypes.PastMonth }, { type: FilterType.ClaimCreatedDateTo, description: DateTypes.PastMonth }]);
				break;
			}
			case FilterGroupEnum.UserStats: {
				defaultFiltes.push(...[{ type: FilterType.DateTimeFrom, description: DateTypes.PastMonth }, { type: FilterType.DateTimeTo, description: DateTypes.PastMonth }]);
				break;
			}
			case FilterGroupEnum.ClaimPayments: {
				defaultFiltes.push(...[{ type: FilterType.ClaimDatePaidFrom, description: DateTypes.PastMonth }, { type: FilterType.ClaimDatePaidTo, description: DateTypes.PastMonth }]);
				break;
			}
			case FilterGroupEnum.ConditionsReport: {
				defaultFiltes.push(...[{ type: FilterType.CreatedDateFrom, description: DateTypes.PastMonth }, { type: FilterType.CreatedDateTo, description: DateTypes.PastMonth }]);
				break;
			}
			}
			return defaultFiltes;
		},
		async onChangeReportView () {
			await this.loadData();
		},
		async onExportReport ({ type }) {
			const exportModel = {
				reportGroup: this.reportGroup,
				filters: this.filters,
				sorting: this.apiSorting,
				exportType: type.value,
			};
			const fileName = this.generateFileName(type);
			await ReportService.generateExportDocument(exportModel, fileName);
			this.$modal.hide('export-report-modal');
		},
		generateFileName (exportType) {
			// if user report gets report name, otherwise system report name
			const fileName = this.reportId ? this.userReportName : ReportGroupDisplayMap[this.reportGroup];
			return fileService.generateFileName(fileName, exportType);
		},
		onFiltersOpen () {
			this.isFiltersOpen = true;
		},
		onCancel () {
			this.isFiltersOpen = false;
		},
		onApplyFilters () {
			const { populatedFilters, searchTerms } = toReportsApiFilters(this.filter);
			const apiSorting = toApiSorting(this.viewSorting);
			const sortingTerms = toSortingTerms(this.viewSorting);

			this.filters = populatedFilters;
			this.apiSorting = apiSorting;
			this.searchTerms = [...searchTerms, ...sortingTerms];
			this.isFiltersOpen = false;
			this.paging.pageNumber = 1;
			this.selectedReportView = this.isChartFiltersSelected ? reportView.ChartView : reportView.TableView;
			// reset paging after filters applied
			this.loadData();
		},
		async onToggleSave () {
			if (this.reportId) {
				await ReportService.updateUserReport(this.reportId, this.filters, this.apiSorting);
			} else {
				this.$modal.show('save-report-modal');
			}
		},
		async onSaveReport (reportName) {
			try {
				const saveReportModel = {
					name: reportName,
					filters: this.filters,
					sorting: this.apiSorting,
				};
				const { value: reportId } = await ReportService.saveUserReport(this.reportGroup, saveReportModel, [ErrorCode.DuplicationErrorCode]);
				this.$modal.hide('save-report-modal');
				this.$router.push({ name: 'reports', params: { group: this.reportGroup, reportId } });
			} catch (error) {
				if (isKnownError(error, ErrorCode.DuplicationErrorCode)) {
					this.isReportNameUniq = false;
				}
			}
		},
		async onSelectAction (action) {
			switch (action) {
			case 'saveAs':
				this.$modal.show('save-report-modal');
				break;
			case 'delete': {
				const userChoise = await this.$refs.deleteReportModal.show();
				if (userChoise === 'yes') {
					await ReportService.deleteUserReport(this.reportId);
					this.onUserReportDeleted({ userReportId: this.reportId });
				}
				break;
			}
			case 'export':
				this.$modal.show('export-report-modal');
				break;
			}
		},
		async onPageChanged (pageNumber) {
			this.paging.pageNumber = pageNumber;
			await this.loadData();
		},
		async loadData () {
			if (this.isChartFiltersSelected && this.selectedReportView !== reportView.TableView) {
				this.chartResult = await ReportService.getChartForReport(this.reportGroup, this.filters);
			} else {
				const { items, ...paging } = await ReportService.runReport(this.reportGroup, this.filters, this.paging, this.apiSorting);
				this.paging.rowCount = paging.rowCount;
				this.reportItems = items;
			}
		},
		onUserReportDeleted ({ userReportId }) {
			if (this.reportId === userReportId) {
				this.$router.replace({ name: 'reports' });
			}
		},
		onRemoveSearchTerm () {
			this.onApplyFilters();
		},
	},
	beforeDestroy () {
		eventBus.$off('user-report-deleted', this.onUserReportDeleted);
	},
};
</script>

<style lang="scss" scoped>
  .filters {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
</style>
