<template>
	<div class="search-block d-flex">
		<aq-input
			class="w-100"
			input-class="p-14 search-input"
			:placeholder="placeholder"
			v-model="searchTerm"
			:type="type"
			@keyup.enter.native="onSearch"
			@input="$emit('input')"
			ref="search"
			data-qa="searchTerm_input_search"
		/>
		<button
			class="btn small"
			:class="btnClass"
			@click="onSearch"
			data-qa="searchBox_button_search"
		>
			<i class="fas fa-search fa-rotate-90" />
		</button>
		<button
			v-if="isFilterEnabled"
			class="ml-7 btn small"
			@click="onFiltersOpen"
			:class="[iconClass, btnClass]"
			data-qa="searchBox_button_openFilters"
		>
			<i class="fas fa-sliders-h fs" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'AqSearchBox',
	props: {
		iconClass: {
			type: Object,
			required: false,
		},
		btnClass: {
			type: String,
			required: false,
			default: 'btn-secondary',
		},
		isFilterEnabled: {
			type: Boolean,
			required: false,
			default: true,
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Search',
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		focused: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	mounted () {
		if (this.focused) {
			this.$refs.search.$refs.input.focus();
		}
	},
	data () {
		return {
			searchTerm: undefined,
		};
	},
	methods: {
		onFiltersOpen () {
			this.$emit('open-filters', this.searchTerm);
		},
		onSearch () {
			if (this.searchTerm) {
				this.$emit('search', this.searchTerm);
				this.searchTerm = null;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  ::v-deep .search-input {
    width: 100%;
    border: none;

    &:focus {
      outline: none;
    }
  }
</style>
