<template>
	<div class="h-100 d-flex flex-column">
		<diagnosis-condition
			:data="result"
			:header="diagnosisConditionHeader"
			@passed="onDiagnosisPassed"
			@cancel="onCancel"
			:validation="$v"
		>
			<template
				slot="diagnosisCondition"
			>
				<div v-if="this.isPreExistingCondition">
					<aq-select
						data-qa="conditionForm_select_exclusionType"
						:options="exclusionTypes"
						option-label="value"
						track-by="id"
						label="Exclusion Type"
						:is-valid="!$v.selectedExclusionType.$error"
						class="w-100"
						v-model="selectedExclusionType"
						@input="$v.selectedExclusionType.$touch(); $emit('changed')"
						:disabled="condition.hasRejectedClaims"
					/>
					<aq-form-input-error
						class="mt-8"
						:error-messages="$options.errorMessages"
						:validator="$v"
						property="selectedExclusionType"
					/>
					<div class="mt-20" />
					<aq-form-input
						data-qa="conditionForm_input_firstSignsDate"
						label="First Signs Date"
						type="date"
						v-model="firstSignsDate"
						:disabled="condition.hasRejectedClaims"
					/>
					<aq-form-input
						class="mt-20"
						data-qa="conditionForm_input_pageNumber"
						label="Page Number"
						type="number"
						:is-valid="!$v.pageNumber.$error"
						v-model="pageNumber"
						@input="$v.pageNumber.$touch"
					/>
					<aq-form-input-error
						property="pageNumber"
						:validator="$v"
						:error-messages="$options.errorMessages"
					/>
				</div>
				<div v-else>
					<aq-select
						data-qa="conditionForm_select_eligibleConditionType"
						:options="eligibleConditionTypes"
						option-label="value"
						track-by="id"
						label="Eligible Condition Type"
						:is-valid="!$v.selectedEligibleConditionType.$error"
						class="w-100"
						v-model="selectedEligibleConditionType"
						@input="$v.selectedEligibleConditionType.$touch(); $emit('changed')"
					/>
					<aq-form-input-error
						class="mt-8"
						:error-messages="$options.errorMessages"
						:validator="$v"
						property="selectedEligibleConditionType"
					/>
					<div class="mt-20" />
					<aq-form-input
						data-qa="conditionForm_input_assessmentDate"
						label="Assessment Date"
						type="date"
						v-model="assessmentDate"
					/>
				</div>
				<div
					v-if="condition.diagnosisOccurrences && condition.diagnosisOccurrences.length !== 0"
					class="mt-45"
				>
					<div>
						<i class="fas fa-history text-primary occurrence-history-icon mr-10 p-5 fs-18" />
						<span
							@click="isOccurrenceHistoryOpened = true"
							data-qa="conditionForm_btn_occurrenceHistory"
						>
							<strong class="fs-14 text-underline occurrence-history-btn cursor-pointer">Occurrence History</strong>
						</span>
					</div>
					<div
						class="occurrence-history-accordion mt-15"
						v-show="isOccurrenceHistoryOpened"
					>
						<DiagnosisOcurrence
							:condition="condition"
							class="mb-10 pl-15 pt-10"
						/>
						<div class="horizontal-rule ml-10 mr-10" />
						<div>
							<strong
								class="d-flex justify-content-end mr-10 pt-10 pb-10 fs-14 text-underline occurrence-history-btn cursor-pointer"
								@click="isOccurrenceHistoryOpened = false"
							>
								Close
							</strong>
						</div>
					</div>
				</div>
			</template>
		</diagnosis-condition>
		<VetDetails
			ref="vetDetails"
			:data="result"
			@passed="onVetDetailsPassed"
			@cancel="onCancel"
		/>
		<related-conditions
			ref="related"
			:data="result"
			:duplication-diagnosis-message="duplicationDiagnosisConditionMessage"
			@passed="onRelatedPassed"
			@cancel="onCancel"
		/>
		<condition-comments
			ref="comments"
			:data="{ comments: result.comments, documentCommentary: result.documentCommentary }"
			@passed="onCommentsPassed"
			@cancel="onCancel"
		>
			<template
				#documentCommentary
			>
				<div v-if="!isPreExistingCondition" />
			</template>
		</condition-comments>
	</div>
</template>
<script>
import VetDetails from '@commonView/Shared/ConditionsPanel/VetDetails';
import cloneDeep from 'lodash.clonedeep';
import { ConditionManagementEnum, ConditionManagementEnumMap } from '@commonServices/models/ConditionManagementEnum';
import { ExclusionTypeEnumMap } from '@commonServices/models/ExclusionTypeEnum';
import { EligibleConditionTypeEnumMap } from '@clientCommon/services/models/EligibleConditionTypeEnum';
import { requiredIf } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { moreThen, integerOnly } from '@commonServices/utils/validators';
import DiagnosisOcurrence from '@commonView/Shared/ConditionsPanel/DiagnosisOcurrence';
import { mapActions, mapState } from 'vuex';

export default {
	components: {
		VetDetails,
		DiagnosisOcurrence,
	},
	data () {
		return {
			saveDraft: !this.condition.id,
			result: cloneDeep(this.condition),
			exclusionTypes: [],
			eligibleConditionTypes: [],
			selectedExclusionType: null,
			selectedEligibleConditionType: null,
			firstSignsDate: this.condition.firstSignsDate,
			assessmentDate: this.condition.assessmentDate,
			isOccurrenceHistoryOpened: false,
			pageNumber: this.condition.pageNumber,
		};
	},
	mounted () {
		if (this.isPreExistingCondition) {
			this.exclusionTypes = Object.entries(ExclusionTypeEnumMap).map(([id, value]) =>
				({
					id: parseInt(id),
					value,
				}),
			);
			this.selectedExclusionType = this.exclusionTypes.find(type => parseInt(type.id) === parseInt(this.result.exclusionType));
		} else {
			this.eligibleConditionTypes = Object.entries(EligibleConditionTypeEnumMap).map(([id, value]) =>
				({
					id: parseInt(id),
					value,
				}),
			);
			this.selectedEligibleConditionType = this.eligibleConditionTypes.find(type => parseInt(type.id) === parseInt(this.result.eligibleConditionType));
		}
	},
	beforeDestroy () {
		if (this.saveDraft) {
			this.addDraftData({ draftName: this.draftName, key: this.draftKey, data: this.result });
		}
	},
	props: {
		condition: {
			type: Object,
			required: true,
		},
		diagnosisConditionType: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(['interactionContext']),
		isPreExistingCondition () {
			return this.diagnosisConditionType === ConditionManagementEnumMap[ConditionManagementEnum.PreExisting];
		},
		diagnosisConditionHeader () {
			return this.diagnosisConditionType + ' Condition';
		},
		duplicationDiagnosisConditionMessage () {
			return `Related condition has already been selected as ${this.diagnosisConditionType} condition`;
		},
		draftKey () {
			return `petId_${this.interactionContext.pet.id}`;
		},
		draftName () {
			return this.isPreExistingCondition ? 'preExistingConditions' : 'eligibleConditions';
		},
	},
	errorMessages: {
		selectedExclusionType: {
			required: ValidationMessages.required,
		},
		selectedEligibleConditionType: {
			required: ValidationMessages.required,
		},
		pageNumber: {
			minValue: ValidationMessages.greaterThanZero('Page Number'),
			integerOnly: 'The field can contain only integer numbers',
		},
	},
	validations () {
		return {
			selectedExclusionType: {
				required: requiredIf(() => this.isPreExistingCondition),
			},
			selectedEligibleConditionType: {
				required: requiredIf(() => !this.isPreExistingCondition),
			},
			pageNumber: {
				minValue: moreThen(0),
				integerOnly,
			},
		};
	},
	methods: {
		...mapActions(['addDraftData', 'clearDraftData']),
		onCancel () {
			this.clearDraft();
			this.$emit('cancel');
		},
		onDiagnosisPassed (conditionData) {
			this.$refs.vetDetails.expand();
			this.result.condition = conditionData.condition;
			this.result.bodyPart = conditionData.bodyPart;
			this.result.exclusionType = this.selectedExclusionType?.id;
			this.result.eligibleConditionType = this.selectedEligibleConditionType?.id;
			this.result.firstSignsDate = this.firstSignsDate;
			this.result.assessmentDate = this.assessmentDate;
			this.result.diagnosisOccurrences = this.condition.diagnosisOccurrences;
			this.result.pageNumber = this.pageNumber;
		},
		onVetDetailsPassed (vet) {
			this.$refs.related.expand();
			this.result.vet = vet;
		},
		onRelatedPassed (relatedConditionsData) {
			this.$refs.comments.expand();
			this.result.relatedConditions = relatedConditionsData;
		},
		onCommentsPassed ({ comments, documentCommentary }, isDraft) {
			this.result.comments = comments;
			this.result.documentCommentary = documentCommentary;
			if (!isDraft) {
				this.clearDraft();
				this.$emit('save', this.result);
			}
		},
		clearDraft () {
			this.clearDraftData({ draftName: this.draftName, key: this.draftKey });
			this.saveDraft = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.occurrence-history-btn {
  &:hover {
    opacity: 0.6;
  }
}

.occurrence-history-icon {
  border-radius: 50%;
  background: var(--controlBg);
}

.occurrence-history-accordion {
  background: var(--controlBg);
  border-radius: 2px;

  & > p {
    color: var(--occurrenceHistoryTextColor);
  }
}
</style>
