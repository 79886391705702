<template>
	<div class="position-relative">
		<aq-modal name="save-filterset-modal">
			<save-filters-modal
				label="Filter"
				title="Save filter"
				description="Please enter a name for your filter"
				:is-name-uniq="isFilterNameUniq"
				@save="onSaveFilter"
				@touch="isFilterNameUniq = true"
			/>
		</aq-modal>
		<div
			v-if="taskId"
			class="header"
		>
			<letters-zip-progress
				:progress-percentage="taskData.progress"
				label="Zipping Documents"
			/>
		</div>
		<div
			v-else
			class="header p-30"
		>
			<div
				class="d-flex align-items-center"
				v-if="!isFiltersOpen"
			>
				<button
					class="btn btn-primary px-14"
					@click="onFiltersOpen"
					data-qa="reportsPage_button_filters"
				>
					<i class="fas fa-filter" />
					&nbsp;
					Filters
				</button>
				<span
					class="ml-auto text-white mr-20"
					v-if="!searchTerms.length"
				>
					There {{ 'is'| pluralize(pendingCount) }} {{ pendingCount }} {{ 'letter' | pluralize(pendingCount) }} in your queue
				</span>
				<aq-dropdown
					v-if="searchTerms.length && $can.ManageLetters"
					data-qa="lettersPage_select_save"
					label="Save"
					:options="saveOptions"
					:btn-class="'btn btn-primary px-14'"
					:is-read-only-mode="false"
					@select="onSelectAction"
					@main-action="onToggleSave"
					:split-toggle="true"
					:height="37"
					class="ml-auto mr-10 ml-6"
				/>
				<button
					v-if="zipAvailable"
					class="btn btn-primary"
					@click="onZipLetters"
					:disabled="selectedLettersCount === 0 || (selectedLettersCount === -1 && pendingCount === 0)"
				>
					{{ zipButtonText }}
				</button>
			</div>
		</div>
		<letter-filters
			v-if="isFiltersOpen"
			:filter="filter"
			@apply-filters="onApplyFilters"
			@cancel="onCancel"
			class="position-absolute filters"
		/>
		<aq-search-term-container
			class="p-15"
			v-if="searchTerms.length"
			:search-terms="searchTerms"
			:filter="filter"
			:removable="allowRemovingTerms"
			@remove-search-term="onRemoveSearchTerm"
		/>
		<div
			class="section-content mx-25 mt-40 px-20 pb-10"
			v-if="activeTab && isTabViewAvailable"
		>
			<aq-tab-selector
				:items="tabItems"
				:active-tab="activeTab"
				@select-tab="onSelectTab"
				data-qa="lettersPage_select_letterType"
			>
				<pending-letters-view
					class="section-content pb-0"
					:letters="letters"
					:start-index="rowStartIndex"
					:selection="selection"
					:selection-disabled="!zipAvailable"
					@selectionChanged="onLettersSelectionChanged"
				/>
			</aq-tab-selector>
		</div>
		<div
			class="text-center text-gray"
			style="margin-top: 20%;"
			v-else
		>
			Apply filters to selectively zip letters in your queue
		</div>
		<div
			class="section-footer d-flex pl-45 mx-25"
			v-if="paging.rowCount"
		>
			<aq-page-control
				:total="paging.rowCount"
				:page-size="paging.pageSize"
				@page-changed="onPageChanged"
				class="ml-auto mr-20"
			/>
		</div>
	</div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import LettersService from '@commonServices/lettersService';
import { toSearchTerms, toViewFilter, toLettersApiFilters } from '@commonServices/utils/lettersConverter';
import Paging from '@commonServices/models/Paging';
import PendingLettersView from '@commonView/LettersPage/PendingLettersView';
import LetterFilters from '@commonView/LettersPage/LetterFilters';
import LettersZipProgress from '@commonView/LettersPage/LettersZipProgress';
import SaveFiltersModal from '@commonView/Shared/SaveFiltersModal';
import ErrorCode from '@commonServices/models/ErrorCode';
import eventBus from '@commonServices/eventBus';
import AqSearchTermContainer from '@commonView/Shared/AqSearchTermContainer';
import { isKnownError } from '@commonServices/utils/general';
import { getDayEnd, getDayStart } from '@commonServices/utils/dateUtils';

const getFilterValue = value => value == null ? null : value.id;

export default {
	components: {
		PendingLettersView,
		LetterFilters,
		LettersZipProgress,
		SaveFiltersModal,
		AqSearchTermContainer,
	},
	data () {
		return {
			isFiltersOpen: false,
			letters: [],
			letterId: null,
			selectedLettersCount: -1,
			selection: { all: true, ids: [] },
			pendingCount: 0,
			filter: null,
			filters: [],
			filterSetName: '',
			searchTerms: [],
			paging: new Paging(),
			activeTab: null,
			taskId: null,
			taskExpiration: 80, // zip task expiration in seconds
			tabItems: [
				{
					value: 'all',
					label: 'Pending',
					details: null,
				},
				{
					value: 'zipped',
					label: 'Zipped',
					details: null,
				},
			],
			isFilterNameUniq: true,
		};
	},
	async mounted () {
		this.resetInteractionContext();
		this.filterSetId = this.$route.params.filterSetId;
		let filterSetModel = {};
		if (this.filterSetId) {
			// getting user filters
			filterSetModel = await LettersService.getUserFilters(this.filterSetId);
			this.filterSetName = filterSetModel.name;
		}
		this.filter = toViewFilter(filterSetModel.filters);
		this.activeTab = this.tabItems[0];
		await this.getPendingCount();
		if (this.filterSetId) {
			await this.onApplyFilters();
		}
		eventBus.$on('letters-user-filter-deleted', this.onUserFilterDeleted);
	},
	computed: {
		...mapState({
			taskData (state) {
				return state.tasks[this.taskId] || { progress: 0 };
			},
		}),
		rowStartIndex () {
			return this.paging.rangeStart;
		},
		isTabViewAvailable () {
			return this.searchTerms.length;
		},
		saveOptions () {
			const saveOptions = [];
			if (this.filterSetId) {
				saveOptions.push({ name: 'Save as', value: 'saveAs', iconClass: 'fa-save text-primary' });
			}
			return saveOptions;
		},
		zipButtonText () {
			if (this.selectedLettersCount === -1) {
				return 'Zip all Letters';
			} else if (this.selectedLettersCount === 0) {
				return 'Zip Letters';
			}
			return `Zip Selected (${this.selectedLettersCount})`;
		},
		zipAvailable () {
			return this.$can.ZipLetters;
		},
		allowRemovingTerms () {
			return this.searchTerms.length > 1;
		},
	},
	watch: {
		taskData (newData) {
			if (newData.progress === 100) {
				this.onTaskComplete(newData);
			}
		},
	},
	methods: {
		...mapActions(['changeTaskData', 'resetInteractionContext']),
		onSelectTab () {
			this.$router.push({ name: 'zippedLetters' });
		},
		async onPageChanged (pageNumber) {
			this.paging.pageNumber = pageNumber;
			await this.loadData();
		},
		async getPendingCount () {
			this.pendingCount = await LettersService.getPendingLettersCount();
			const zippedLettersCount = await LettersService.getZippedLettersCount();
			this.tabItems[1].details = `(${zippedLettersCount})`;
		},
		onFiltersOpen () {
			this.isFiltersOpen = true;
		},
		async onZipLetters () {
			const filtersPayload = this.getFiltersPayload();
			const { value: taskId } = await LettersService.zipLetters(this.selection, filtersPayload);
			this.taskId = taskId;
			this.changeTaskData({ id: taskId, progress: 0, options: { maxLifeTime: this.taskExpiration * 1000, errorMessage: 'Document Zipping Failed' } });
		},
		async onApplyFilters () {
			this.isFiltersOpen = false;
			this.paging.pageNumber = 1; // reset paging after filters applied
			this.searchTerms = toSearchTerms(this.filter);
			this.filters = toLettersApiFilters(this.filter);
			this.selection = { all: true, ids: [] };
			await this.loadData();
		},
		getFiltersPayload () {
			const { letterType, dateFrom, dateTo, reportClaimStatus, letterRecipientType, zippedFileStatus, brand } = this.filter;
			return {
				letterType: getFilterValue(letterType.value),
				dateFrom: dateFrom.value ? getDayStart(dateFrom.value) : null,
				dateTo: dateTo.value ? getDayEnd(dateTo.value) : null,
				claimStatus: getFilterValue(reportClaimStatus.value),
				recipientType: getFilterValue(letterRecipientType.value),
				zippedFileStatus: getFilterValue(zippedFileStatus.value),
				brandId: getFilterValue(brand.value),
			};
		},
		async loadData () {
			const filtersPayload = this.getFiltersPayload();
			const { items, ...paging } = await LettersService.filterLetters(
				filtersPayload,
				this.paging,
			);
			this.paging.rowCount = paging.rowCount;
			this.letters = items;
			this.activeTab.details = `(${this.paging.rowCount})`;
		},
		onCancel () {
			this.isFiltersOpen = false;
		},
		onLettersSelectionChanged (selection) {
			this.selection = selection;
			if (selection.all) {
				this.selectedLettersCount = this.paging.rowCount - selection.ids.length;
			} else {
				this.selectedLettersCount = selection.ids.length;
			}
		},
		onTaskComplete (taskData) {
			this.taskId = null;
			const { data: { zipFileId, error } } = taskData;
			if (!error && zipFileId) {
				this.$router.push({ name: 'zippedLetters', params: { type: 'zipped', zipFileId } });
			}
		},
		onSelectAction (action) {
			switch (action) {
			case 'saveAs':
				this.$modal.show('save-filterset-modal');
				break;
			case 'delete': break;
			case 'export': break;
			}
		},
		async onToggleSave () {
			if (this.filterSetId) {
				await LettersService.updateUserFilterSet(this.filterSetId, this.filters);
			} else {
				this.$modal.show('save-filterset-modal');
			}
		},
		async onSaveFilter (filterName) {
			try {
				const saveFilterModel = {
					name: filterName,
					filters: this.filters,
				};
				const { value: filterSetId } = await LettersService.saveUserFilter(saveFilterModel, [ErrorCode.DuplicationErrorCode]);
				this.$modal.hide('save-filterset-modal');
				this.$router.push({ name: 'letters', params: { filterSetId } });
			} catch (error) {
				if (isKnownError(error, ErrorCode.DuplicationErrorCode)) {
					this.isFilterNameUniq = false;
				}
			}
		},
		onUserFilterDeleted ({ userFilterId }) {
			if (this.filterSetId === userFilterId) {
				this.$router.replace({ name: 'letters' });
			}
		},
		onRemoveSearchTerm () {
			this.onApplyFilters();
		},
	},
	beforeDestroy () {
		eventBus.$off('letters-user-filter-deleted', this.onUserFilterDeleted);
	},
};
</script>

<style lang="scss" scoped>
  .filters {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
</style>
