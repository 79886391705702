const components = {};
const context = require.context('.?vue-template', false, /\.svg$/);

context.keys().forEach(fileName => {
	const name = fileName.replace(/^\.\//, '').replace(/\.svg$/, '');
	const image = context(fileName);
	components[name] = image;
});

module.exports = components;
