<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid">
						<div class="row no-gutters">
							<div class="col-12 pb-12">
								Please enter a reason for rejection
							</div>
							<div class="col-12 pb-12">
								<aq-form-input
									label="Reason"
									type="textarea"
									v-model="rejectionMessage"
									:is-valid="!$v.rejectionMessage.$error"
									@input="$v.rejectionMessage.$touch"
								/>
								<aq-form-input-error
									class="mt-8"
									:error-messages="this.$options.errorMessages"
									:validator="$v"
									property="rejectionMessage"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onContinue"
						:disabled="$v.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Continue
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';

const errorMessages = {
	rejectionMessage: {
		required: ValidationMessages.required,
		maxLength: ValidationMessages.maxLength2000,
	},
};

export default {
	data () {
		return {
			title: 'Reject Claim',
			rejectionMessage: undefined,
		};
	},
	errorMessages,
	props: {},
	validations () {
		return {
			rejectionMessage: {
				required,
				maxLength: maxLength(2000),
			},
		};
	},
	methods: {
		onContinue () {
			this.$emit('rejected-claim', this.rejectionMessage);
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>
