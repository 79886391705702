import ReportModel from './ReportModel';

export default class ClaimStatusReportModel extends ReportModel {
	constructor (
		brandName,
		brandLocale,
		customerId,
		policyId,
		policyReference,
		claimId,
		customerFirstName,
		customerLastName,
		petName,
		petType,
		petBreed,
		ailments,
		conditionTypes,
		amountSubmitted,
		claimCreatedBy,
		claimCreatedDate,
		claimFormUploadedDate,
		dateOnSet,
		dateTreatmentStart,
		lastInteraction,
		lastInteractionDate,
		lastInteractionBy,
		assignedToUser,
		claimStatus,
		rejectionOverriddenBy,
		claimFormType,
		daysOpenClosed,
		claimClosedDate,
		claimClosedBy,
		isFastTrackReviewed,
		fastTrackReason,
		claimAssessedBy,
	) {
		super(brandName,
			brandLocale,
			customerId,
			policyId,
			policyReference,
			claimId,
			customerFirstName,
			customerLastName,
		);
		this.petName = petName;
		this.petType = petType;
		this.petBreed = petBreed;
		this.ailments = ailments;
		this.conditionTypes = conditionTypes;
		this.amountSubmitted = amountSubmitted;
		this.claimCreatedBy = claimCreatedBy;
		this.claimCreatedDate = claimCreatedDate;
		this.claimFormUploadedDate = claimFormUploadedDate;
		this.dateOnSet = dateOnSet;
		this.dateTreatmentStart = dateTreatmentStart;
		this.lastInteraction = lastInteraction;
		this.lastInteractionDate = lastInteractionDate;
		this.lastInteractionBy = lastInteractionBy;
		this.assignedToUser = assignedToUser;
		this.claimStatus = claimStatus;
		this.rejectionOverriddenBy = rejectionOverriddenBy;
		this.claimFormType = claimFormType;
		this.daysOpenClosed = daysOpenClosed;
		this.claimClosedDate = claimClosedDate;
		this.claimClosedBy = claimClosedBy;
		this.isFastTrackReviewed = isFastTrackReviewed;
		this.fastTrackReason = fastTrackReason;
		this.claimAssessedBy = claimAssessedBy;
	}
}
