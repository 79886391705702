<template>
	<div>
		<div class="row p-10">
			<div class="col">
				<aq-select
					data-qa="claimFiltersBdx_input_aggregate"
					:options="aggregateFunctions"
					:label="filter.selectedAggregate.label"
					option-label="description"
					class="w-100"
					v-model="filter.selectedAggregate.value"
					no-result-message="No Aggregates found"
				/>
			</div>
		</div>
		<div class="row p-10">
			<div class="col">
				<aq-select
					data-qa="claimFiltersBdx_input_column"
					:options="columns"
					label="Column"
					option-label="description"
					class="w-100"
					v-model="filter.selectedColumn.value"
					no-result-message="No Columns found"
				/>
			</div>
		</div>
		<div class="row p-10">
			<div class="col">
				<aq-select
					data-qa="claimFiltersBdx_input_groupBy"
					:options="groupByOptions"
					label="Group By"
					option-label="description"
					class="w-100"
					v-model="filter.selectedGroupBy.value"
					no-result-message="No Group By found"
					:disabled="!allowGroupBy"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { aggregateFunctionsList } from '@commonServices/models/ChartFilterModels';

export default {
	name: 'AqCharting',
	props: {
		filter: {
			type: Object,
			required: true,
		},
		filterColumns: {
			type: Array,
			required: true,
		},
		groupByOptions: {
			type: Array,
			required: true,
		},
	},
	data () {
		return {
			aggregateFunctions: [],
			columns: [],
		};
	},
	mounted () {
		this.aggregateFunctions = aggregateFunctionsList;
		this.columns = this.filterColumns;
	},
	computed: {
		allowGroupBy () {
			return this.filter.selectedColumn.value && this.filter.selectedAggregate.value;
		},
	},
	watch: {
		'filter.selectedAggregate.value' (value) {
			if (value) {
				const { appliedToTypes } = value;
				this.columns = this.filterColumns.filter((column) => appliedToTypes.includes(column.dataType));
				if (this.filter.selectedColumn.value && !appliedToTypes.includes(this.filter.selectedColumn.value.dataType)) {
					this.filter.selectedColumn.value = null;
				}
			} else {
				this.filter.selectedGroupBy.value = null;
			}
		},
		'filter.selectedColumn.value' (value) {
			if (value) {
				const { dataType } = value;
				this.aggregateFunctions = aggregateFunctionsList.filter((func) => func.appliedToTypes.includes(dataType));
				if (this.filter.selectedAggregate.value && !this.filter.selectedAggregate.value.appliedToTypes.includes(dataType)) {
					this.filter.selectedAggregate.value = null;
				}
			} else {
				this.filter.selectedGroupBy.value = null;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .text-underline {
    text-decoration: underline;
    color: var(--btnLink);

    &:hover {
      color: $primary;
    }
  }
</style>
