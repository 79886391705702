import ReportModel from './ReportModel';

export default class ClaimQualityAuditReportModel extends ReportModel {
	constructor (
		brandName,
		brandLocale,
		customerId,
		customerExtRef,
		customerExtId,
		policyId,
		policyReference,
		claimId,
		customerFirstName,
		customerLastName,
		claimFormType,
		petId,
		petName,
		petExtRef,
		petExtId,
		claimCreatedDate,
		claimDateOfLoss,
		claimClosedDateTime,
		claimAmountSubmitted,
		claimAmountPaid,
		claimStatus,
		claimAssessedBy,
		fraudCheckReasons,
		invoiceNumbers,
	) {
		super(brandName,
			brandLocale,
			customerId,
			policyId,
			policyReference,
			claimId,
			customerFirstName,
			customerLastName,
		);
		this.customerExtRef = customerExtRef;
		this.customerExtId = customerExtId;
		this.claimFormType = claimFormType;
		this.petId = petId;
		this.petName = petName;
		this.petExtRef = petExtRef;
		this.petExtId = petExtId;
		this.claimCreatedDate = claimCreatedDate;
		this.claimDateOfLoss = claimDateOfLoss;
		this.claimClosedDateTime = claimClosedDateTime;
		this.claimAmountSubmitted = claimAmountSubmitted;
		this.claimAmountPaid = claimAmountPaid;
		this.claimStatus = claimStatus;
		this.claimAssessedBy = claimAssessedBy;
		this.fraudCheckReasons = fraudCheckReasons;
		this.invoiceNumbers = invoiceNumbers;
	}
};
