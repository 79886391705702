<template>
	<modal
		:name="name"
		height="auto"
		width="100%"
		classes="v-aq-modal"
		adaptive
		focus-trap
		:click-to-close="clickToClose"
	>
		<slot />
	</modal>
</template>

<script>
export default {
	name: 'AqModal',
	data () {
		return {
			promiseResolver: null,
		};
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		clickToClose: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
