<template>
	<div class="section-content pb-20">
		<aq-modal name="interaction-details-modal">
			<missing-data-modal
				v-if="detailsModalName==='Missing Items'"
				title="Missing Items"
				:data="selectedDetails"
				:comment="comment"
				@close="onCloseModal"
			/>
			<claim-data-modal
				v-else-if="detailsModalName==='Online Claim Submission'"
				title="Online Claim Submission"
				:data="selectedDetails"
				:locale="locale"
				@close="onCloseModal"
			/>
			<claim-data-modal
				v-else-if="detailsModalName==='Hospital Claim Submission'"
				title="Hospital Claim Submission"
				:data="selectedDetails"
				:locale="locale"
				@close="onCloseModal"
			/>
			<ex-gratia-data-modal
				v-else-if="detailsModalName==='Ex-Gratia Payment Comment'"
				title="Ex Gratia Payment"
				:data="selectedDetails"
				:locale="locale"
				@close="onCloseModal"
			/>
			<interaction-details-modal
				v-else
				:title="detailsModalName"
				:data="selectedDetails"
				:adjuster-comment="adjusterComment"
				@close="onCloseModal"
			/>
		</aq-modal>
		<div class="container-fluid h-100 d-flex flex-column pr-5">
			<aq-level-switcher />
			<div class="p-10 d-flex flex-wrap search-terms">
				<aq-search-term
					v-for="(term, index) of searchTerms"
					:key="index"
					:value="term"
					@remove="onRemoveSearchTerm"
					class="px-10 mr-5 mb-5 py-6 bg-warning search-term__rounded"
				/>
			</div>
			<div>
				<aq-search-box
					@search="onSearchTermAdded"
					@input="onInput"
					@open-filters="onToggleFilters"
					:icon-class="{ 'bg-warning': isFiltersOpen }"
					:focused="true"
				/>
			</div>
			<div class="position-relative">
				<div class="p-10 d-flex flex-wrap search-terms">
					<aq-search-term
						v-for="(term, index) of displayFilters"
						:key="index"
						:value="term.value"
						:header="term.header"
						header-class="text-warning"
						icon-class="text-primary"
						@remove="onRemoveFilter"
						class="px-10 mr-5 mb-5 py-6 attribute search-term__rounded"
					/>
				</div>
				<interaction-filter
					:filter-options="filterOptions"
					:filter="selectedFilter"
					v-if="isFiltersOpen"
					@apply-filters="onApplyFilters"
					@filter-changed="onFilterChanged"
					class="interaction-filter mt-5"
				/>
			</div>
			<div
				class="text-danger text-center py-10"
				v-if="isDuplicationError"
			>
				{{ duplicationErrorMessage }}
			</div>
			<div class="mb-25 d-flex">
				<span class="font-weight-bold mr-2">
					{{ interactionList.length }} Interactions
				</span>&nbsp;match your search criteria
				<div
					class="mr-0 ml-auto text-primary font-weight-bolder reset-filters"
					@click="onResetFilters"
					data-qa="interactionsPanel_button_resetFilters"
				>
					Reset
					Filters
				</div>
			</div>
			<aq-list-navigation-control
				:list="interactionList"
				:current-item="currentItem"
				@current-changed="onSelect"
				class="flex-grow-1 d-flex flex-column"
			>
				<virtual-list
					class="interactions-wrapper"
					data-key="id"
					:data-component="interactionComponent"
					:data-sources="interactionList"
					item-class="mb-10"
					:item-class-add="getRowClassName"
					:extra-props="{ onShowDetails, onSelect, onPreviewInteraction, currentItem }"
					:estimate-size="135"
					:keeps="20"
					ref="interactions"
					@scroll="onScroll"
				>
					<template slot="footer">
						<div
							class="text-center"
							v-if="interactionList.length > 0 && !isInteractionsLoading"
						>
							<p>There are no more interactions beyond this point</p>
						</div>
						<div
							class="text-center"
							v-if="isInteractionsLoading"
						>
							<p>Loading...</p>
						</div>
					</template>
				</virtual-list>
			</aq-list-navigation-control>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import VirtualList from 'vue-virtual-scroll-list';
import UsersService from '@commonServices/usersService';
import InteractionsService, { toInteraction, toInteractionPreview } from '@commonServices/interactionsService';
import { toInteractionsApiFilters } from '@commonServices/utils/converter';
import { toDateSearchTerm } from '@commonServices/utils/dateUtils';
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import Interaction from './Interaction';
import InteractionFilter from './InteractionFilter';
import InteractionDetailsModal from './InteractionDetailsModal';
import ClaimDataModal from '@commonView/Shared/ClaimDataModal';
import ExGratiaDataModal from '@commonView/Shared/ExGratiaDataModal';
import eventBus from '@commonServices/eventBus';
import {
	generatedInteractionsTypes,
	requestedInteractionsTypes,
} from '@commonServices/models/InteractionTypeEnum';
import Paging from '@commonServices/models/Paging';
import LoadingStatus from '@commonServices/models/LoadingStatus';
import { mapState, mapActions } from 'vuex';

const pageSize = 30;

export default {
	name: 'AqInteractions',
	mixins: [rowHighlightMixin],
	components: {
		InteractionFilter,
		InteractionDetailsModal,
		VirtualList,
		ClaimDataModal,
		ExGratiaDataModal,
	},
	data () {
		return {
			interactionList: [],
			interactionPages: [],
			searchTerms: [],
			users: [],
			isDuplicationError: false,
			duplicationErrorMessage: 'Search term already exists',
			isFiltersOpen: false,
			selectedFilter: {
				types: [],
				statuses: [],
				users: [],
				communications: [],
				date: { label: 'Dates' },
			},
			displayFilters: [],
			detailsModalName: null,
			selectedDetails: null,
			adjusterComment: null,
			comment: null,
			interactionComponent: Interaction,
		};
	},
	async mounted () {
		if (!(this.levelFilter.claimId || this.levelFilter.petId || this.levelFilter.customerId)) {
			this.changeInteractionContext({});
		}

		this.users = await UsersService.getUsers(true);
		this.users.push({
			id: null,
			description: 'System',
		});

		eventBus.$on('interaction-added', this.onInteractionAdded);
	},
	computed: {
		...mapState(['levelFilter']),
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		filterOptions () {
			return [
				...InteractionsService.getFilterOptions(),
				{
					filterLabel: 'User',
					filterOptions: this.users,
				}];
		},
		isInteractionsLoading () {
			return this.interactionPages.length && this.interactionPages.some(page => page === LoadingStatus.Loading);
		},
	},
	watch: {
		searchTerms () {
			this.updateInteractions();
		},
		levelFilter: {
			handler: function () {
				if (this.levelFilter.claimId || this.levelFilter.petId || this.levelFilter.customerId) {
					this.updateInteractions();
				}
			},
			immediate: true,
		},
	},
	beforeDestroy () {
		eventBus.$off('interaction-added', this.onInteractionAdded);
	},
	methods: {
		...mapActions(['changeInteractionContext']),
		async	onInteractionAdded (interaction) {
			const mappedInteraction = toInteraction(interaction);
			const isMatchInteractionLevel = this.checkIfInteractionMatchesLevel(mappedInteraction);
			if (isMatchInteractionLevel) {
				let isRequestedExist = false;
				if (generatedInteractionsTypes.includes(mappedInteraction.actionType)) {
					const generatedInteractionValue = mappedInteraction.description.replace('generated', '');
					const requestedIndex = this.interactionList.findIndex(x =>
						requestedInteractionsTypes.includes(x.actionType)
						&& x.description.replace('queued', '') === generatedInteractionValue
						&& x.claimId === mappedInteraction.claimId);
					if (requestedIndex > -1) {
						isRequestedExist = true;
						this.interactionList[requestedIndex].dead = true;
						setTimeout(() => (this.interactionList.splice(requestedIndex, 1)), 500);
					}
				}
				mappedInteraction.live = true;
				if (isRequestedExist) {
					await this.sleep(500);
				}
				this.$refs.interactions.scrollToIndex(0);
				this.interactionList.unshift(mappedInteraction);
				setTimeout(() => (mappedInteraction.live = false), 500);
			}
		},
		sleep (ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		checkIfInteractionMatchesLevel (interaction) {
			const { claimId, petId, customerId } = this.levelFilter;
			return claimId === interaction.claimId
					|| petId === interaction.pet.id
					|| customerId === interaction.pet.customerId;
		},
		onShowDetails ({ details, comment }) {
			this.selectedDetails = details.data;
			this.detailsModalName = details.detailsName;
			this.adjusterComment = details.adjusterComment;
			this.comment = comment;
			this.$modal.show('interaction-details-modal');
		},
		onCloseModal () {
			this.detailsModalName = null;
			this.adjusterComment = null;
			this.selectedDetails = null;
		},
		getRowClassName (index) {
			const interactionByIndex = this.interactionList[index] || {};
			return this.currentItem && this.currentItem.id === interactionByIndex.id ? 'border-highlighted ' : 'border-transparent';
		},
		onSearchTermAdded (value) {
			this.isDuplicationError = this.searchTerms.includes(value);

			if (this.isDuplicationError) {
				return;
			}

			this.searchTerms.push(value);
		},
		onInput () {
			this.isDuplicationError = false;
		},
		onRemoveSearchTerm (term) {
			const removeItemIndex = this.searchTerms.indexOf(term.value);
			this.searchTerms.splice(removeItemIndex, 1);
		},
		onToggleFilters () {
			this.isFiltersOpen = !this.isFiltersOpen;
		},
		onApplyFilters () {
			this.isFiltersOpen = false;
			this.setupFilters();
		},
		onFilterChanged (newFilter) {
			this.selectedFilter = newFilter;
		},
		setupFilters () {
			const appliedFilter = toInteractionsApiFilters(this.selectedFilter);
			this.setDisplayFilters(appliedFilter);
			this.updateInteractions();
		},
		setDisplayFilters (appliedFilter) {
			this.displayFilters = ['Types', 'Statuses', 'Communications'].map(header => (
				{
					header,
					value: this.selectedFilter[header.toLowerCase()].map((item) => item.value || item).join(', '),
				}
			)).filter(item => !!item.value);

			if (this.selectedFilter.users.length) {
				this.displayFilters.push({
					header: 'Users',
					value: this.selectedFilter.users.map(user => user.description).join(', '),
				});
			}

			const dateDisplay = toDateSearchTerm(appliedFilter.dateFrom, appliedFilter.dateTo);
			if (dateDisplay) {
				this.displayFilters.push({
					header: this.selectedFilter.date.type,
					value: dateDisplay,
				});
			}
		},
		onScroll: debounce(function (_, range) {
			const { start, end } = range;
			this.updateInteractionsByRange(start, end);
		}, 200),
		updateInteractions () { // reset datasource and load initial data;
			this.interactionList = [];
			this.interactionPages = [LoadingStatus.None];
			this.updateInteractionsByRange(0, pageSize - 1);
		},
		updateInteractionsByRange (start, end) { // load interactions for specified range
			// calc pages for start and end indexes;
			const startPage = Math.floor(start / pageSize) + 1;
			const endPage = Math.floor(end / pageSize) + 1;

			const appliedFilter = toInteractionsApiFilters(this.selectedFilter);

			for (let page = startPage; page <= endPage; page++) {
				// skip if page is loaded
				if (this.interactionList.length && [LoadingStatus.Loaded, LoadingStatus.Loading].includes(this.interactionPages[page - 1])) { continue; }

				const pagingRequest = new Paging(pageSize, page);

				// load paged data
				this.interactionPages.splice(page - 1, 1, LoadingStatus.Loading);
				InteractionsService.getInteractions({
					...this.levelFilter,
					searchTerms: this.searchTerms,
					filter: appliedFilter,
				}, pagingRequest)
					.then(data => {
						const { items, ...paging } = data;
						// if data source is empty create it and fill with stub data
						if (this.interactionList.length === 0 && paging.rowCount > 0) {
							this.interactionList = new Array(paging.rowCount).fill(null).map((_, index) => ({ id: `_${index}`, empty: true }));
							this.interactionPages = new Array(paging.pageCount).fill(LoadingStatus.None);
						}
						// put requested data into data source with proper position
						for (let i = 0; i < items.length; i++) {
							this.interactionList[pagingRequest.pageSize * (pagingRequest.pageNumber - 1) + i] = items[i];
						}
					})
					.finally(_ => (this.interactionPages.splice(page - 1, 1, LoadingStatus.Loaded))); // mark interaction page is loaded);
			}
		},
		onResetFilters () {
			this.selectedFilter.types = [];
			this.selectedFilter.statuses = [];
			this.selectedFilter.users = [];
			this.selectedFilter.communications = [];
			this.selectedFilter.date = { label: 'Dates' };
			this.selectedFilter.selectedDateFrom = undefined;
			this.selectedFilter.selectedDateTo = undefined;

			this.displayFilters = [];
			this.updateInteractions();
		},
		onRemoveFilter (filter) {
			const removeItemIndex = this.displayFilters.findIndex((item) => item.value === filter.value);
			this.displayFilters.splice(removeItemIndex, 1);
			this.updateFilter(filter.header.toLowerCase());
		},
		updateFilter (property) {
			if (Array.isArray(this.selectedFilter[property])) {
				this.selectedFilter[property] = [];
			} else {
				this.selectedFilter.date = { label: 'Dates' };
			}
			this.updateInteractions();
		},
		onPreviewInteraction (interaction) {
			if (interaction.fileId) {
				this.$emit('preview-interaction', toInteractionPreview(interaction));
			} else {
				this.$emit('preview-interaction', null);
			}
		},
		onSelect (interaction) {
			this.currentItem = interaction;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./src/styles/general/mixins/scrollbars";

.search-term__rounded {
  border-radius: 4px;
}

.interactions-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding-right: 5px;
}

.reset-filters {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary-d-10;
    transition: color 0.2s;
  }
}

.search-terms {
  width: 450px;
}

::v-deep .border {
  &-transparent {
    border: 1px solid transparent;
  }

  &-highlighted {
    border: 1px solid $warning;
  }
}

::v-deep h3 {
  color: var(--bodyColour) !important;
  font-weight: normal !important;
}

::v-deep .btn {
  width: inherit;
}

.section-content {
  overflow: hidden auto;
}
</style>
