<template>
	<div
		class="position-relative mx-8 cursor-pointer"
		v-tooltip.bottom="tooltip"
	>
		<i :class="['opt-indicator', user.optInStatus ? 'opt-in' : 'opt-out']" />
		<div class="user-initials">
			{{ userInitials }}
		</div>
		<div class="aqv-user-simple" />
	</div>
</template>

<script>
import { initials } from '@commonServices/utils/general';

export default {
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	computed: {
		userInitials () {
			return initials([this.user.firstName, this.user.lastName]);
		},
		tooltip () {
			return this.user.fullName;
		},
	},
};
</script>

<style lang="scss" scoped>
.aqv-user-simple {
  font-size: 23px;
  line-height: 24px;
  color: var(--contentBg);
}

.user-initials {
  position: absolute;
  bottom: 0;
  left: 3px;
  font-size: 10px;
  line-height: 14px;
  font-weight: bold;
}

.opt-indicator {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  position: absolute;
  left: 0.36rem;
  top: 2px;

  &.opt-in {
    background: var(--optInIndicatorColour);
  }

  &.opt-out {
    background: var(--optOutIndicatorColour);
  }
}
</style>
