<template>
	<div>
		<div v-if="validator[property].$error && errorMessages">
			<div
				class="text-danger fs-12"
				v-for="value in errors(this.property)"
				:key="value"
			>
				{{ value }}
			</div>
		</div>
		<div v-if="validator[property].$error && warningMessages">
			<div
				class="text-warning fs-12"
				v-for="value in warnings(this.property)"
				:key="value"
			>
				{{ value }}
			</div>
		</div>
	</div>
</template>

<script>
import { shownErrors } from '@commonServices/utils/general';

export default {
	name: 'AqFormInputError',
	methods: {
		errors: function (property) {
			return shownErrors(
				this.validator,
				property,
				this.errorMessages[property],
			);
		},
		warnings: function (property) {
			return shownErrors(
				this.validator,
				property,
				this.warningMessages[property],
			);
		},
	},
	props: {
		validator: {
			type: Object,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		errorMessages: {
			type: Object,
			required: false,
		},
		warningMessages: {
			type: Object,
			required: false,
		},
	},
};
</script>
