<template>
	<div
		class="team-profile d-flex flex-column"
	>
		<div class="manage-team">
			<div
				v-if="isEditTeam"
				class="section-header fs-26"
			>
				<AddTeam />
				<label class="px-15">
					Add Team
				</label>
			</div>
			<div
				v-else
				class="section-header fs-26"
			>
				<EditTeam />
				<label class="px-15">
					Edit Team
				</label>
			</div>
			<div class="team-header px-15 d-flex align-items-center">
				<div
					class="align-self-center position-relative"
				>
					<aq-icon-avatar
						data-qa="teamProfile_icon_teamInitials"
						class="mr-10"
						:value="teamInitials"
					/>
				</div>
				<div class="mx-10">
					<aq-editable-label
						data-qa="teamProfile_label_name"
						class="w-100 my-10"
						v-model="team.name"
						input-class="team-name-input fs-18 font-weight-bold"
						:is-edit="isEditTeam"
						@input="$v.team.name.$touch(); isTeamNameUniq = true"
						placeholder="Name"
					>
						<template slot="label">
							<div class="team-name-input fs-18 font-weight-bold">
								{{ team.name }}
							</div>
						</template>
					</aq-editable-label>
					<aq-form-input-error
						class="mt-8"
						:error-messages="$options.errorMessages.team"
						:validator="$v.team"
						property="name"
					/>
					<aq-editable-label
						data-qa="teamProfile_label_description"
						class="w-100 my-10"
						v-model="team.description"
						input-class="team-name-input fs-12"
						:is-edit="isEditTeam"
						@input="$v.team.description.$touch"
						placeholder="Description"
					>
						<template slot="label">
							<div class="team-name-input fs-12 font-weight-bold">
								{{ team.description }}
							</div>
						</template>
					</aq-editable-label>
					<aq-form-input-error
						class="mt-8"
						:error-messages="$options.errorMessages.team"
						:validator="$v.team"
						property="description"
					/>
				</div>
				<div
					v-if="!isEditTeam"
					class="card-icon small ml-10 mt-40"
					data-qa="teamProfile_button_editTeam"
					@click="onToggleTeamEditing"
				>
					<i
						class="fas fa-pen cursor-pointer"
					/>
				</div>
			</div>
			<div class="team-assignee">
				<div class="d-flex flex-fill">
					<div class="tab">
						<div class="header">
							<TeamMembers class="h-100" />
							<label class="px-15">Team Members</label>
						</div>
						<div class="body p-20">
							<div class="row px-15">
								<aq-select
									data-qa="teamProfile_input_teamMembers"
									:options="allMembers"
									label="Select members"
									option-label="description"
									class="w-75 d-inline-block"
									v-model="team.selectedMembers"
									track-by="id"
									multiple
									no-result-message="No Users found"
									hide-tags
								/>
								<AlphabeticalSorting
									:class="{ 'opacity-03': selectedMembersDisplayList.length === 0 }"
									class="d-inline-block icon-small ml-15"
									@click="onSortTeamMembersAlphabetically"
									data-qa="teamProfile_sort_teamMembersAlphabetically"
								/>
							</div>
							<div
								v-if="selectedMembersDisplayList.length === 0"
							>
								<img
									class="no-members"
									alt="No Members Image"
									:src="require(`@/assets/images/${themeIconSettings.NoMembers}.svg`)"
								>
								<h3 class="text-gray text-center mb-0">
									No Members
								</h3>
							</div>
							<div
								v-else
								class="pt-15"
							>
								<div
									v-for="member of selectedMembersDisplayList"
									:key="member.id"
									class="team-member align-items-center justify-content-between d-inline-block w-33 fs-13 position-relative"
								>
									<div
										:class="{'danger-border': !!usersTooltips[member.id]}"
										class="d-flex member-item p-10 mt-10 mr-10"
									>
										<div>{{ member.description }}</div>
										<i
											@click="onRemoveMemberItem(member)"
											class="cursor-pointer fas fa-times ml-auto mr-0"
										/>
									</div>
									<TeamInfoIcon
										v-if="!!usersTooltips[member.id]"
										class="danger-tooltip-icon"
										data-qa="teamProfile_icon_errorIcon"
									/>
									<div
										v-if="!!usersTooltips[member.id]"
										class="pl-10 danger-tooltip"
										data-qa="teamProfile_text_errorTooltip"
									>
										{{ usersTooltips[member.id] ? usersTooltips[member.id] : '' }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-shrink-0">
					<div class="tab">
						<div class="header d-flex flex-row">
							<AssociatedQueues class="h-100" />
							<label class="p-13 flex-fill">Associated Queues</label>
							<button
								@click="onAddBucket"
								class="btn small btn-primary d-flex flex-row"
							>
								<div class="plus-button mr-5">
									<i class="fa fa-plus fa-sm" />
								</div>
								<div class="flex-shrink-0">
									Add Priority
								</div>
							</button>
						</div>
						<div class="body p-20">
							<div
								v-for="(bucket, index) in team.selectedBuckets"
								:key="index"
								class="mb-25 bucket pb-10 position-relative"
								:class="{'priority': index == 0}"
							>
								<div v-if="index === 0">
									<div class="auto-assigned-card__icon">
										<AutoAssignStarCard />
									</div>
								</div>
								<div v-else>
									<div
										@click="bucket.length === 0 && onRemoveBucket(index)"
										class="close"
										:class="{ 'disabled': bucket.length !== 0, 'cursor-pointer': bucket.length === 0 }"
										v-tooltip="bucket.length !== 0 ? 'Delete Priority Unavailable' : ''"
									>
										<i class="fas fa-times" />
									</div>
								</div>
								<div class="mt-5 mb-2 fs-13 font-weight-bold">
									<div>
										{{ index === 0 ? 'Priority 1 - Auto Assignment' : 'Priority ' + (index + 1) }}
									</div>
								</div>
								<aq-select
									data-qa="teamProfile_input_teamQueues"
									:options="bucketQueues(index)"
									:label="'Select Priority ' + (index + 1) + ' Queues'"
									option-label="name"
									class="w-100"
									v-model="team.selectedBuckets[index]"
									@input="onQueueSelection(bucket)"
									track-by="id"
									multiple
									group-values="queues"
									group-label="workQueueType"
									group-select
									no-result-message="No Queues found"
									no-options-message="No Queues"
									hide-tags
									:is-sorted="false"
								>
									<template #default="props">
										<div class="d-flex align-items-center">
											<aq-tooltip-on-truncate
												class="col"
												:tooltip-text="props.option.$isLabel ? props.option.$groupLabel : props.option.name"
												offset="12"
											>
												<div class="text-truncate">
													{{ props.option.$isLabel ? props.option.$groupLabel : props.option.name }}
												</div>
											</aq-tooltip-on-truncate>
											<AutoAssignQueue
												v-if="props.option.isPrioritized"
												class="auto-assign-queue-logo"
												v-tooltip="'Auto Assign'"
											/>
										</div>
									</template>
								</aq-select>
								<div
									v-if="bucket.length !== 0"
								>
									<div
										v-for="queue of bucket"
										:key="queue.id"
										class="d-flex member-item p-14 mt-10 w-100 fs-13"
										:class="{ 'bordered': draggingQueueId == queue.id }"
									>
										<div
											class="ellipsis"
											v-tooltip="`${queue.workQueueType} - ${queue.name}`"
										>
											{{ queue.workQueueType }} - {{ queue.name }}
										</div>
										<i
											@click="onRemoveQueueItem(queue, index)"
											class="cursor-pointer fas fa-times ml-auto mr-0"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section-footer">
				<div class="row my-10 ml-auto mr-20 float-right">
					<div
						v-if="$v.team.selectedMembers.$error || $v.team.selectedBuckets.$error"
						class="mt-8 ml-0 text-danger"
					>
						{{ $options.errorMessages.teamCompound }}
					</div>
					<button
						data-qa="teamProfile_button_cancel"
						class="btn btn-secondary mx-10"
						@click="onCancel"
					>
						Cancel
					</button>
					<button
						data-qa="teamProfile_button_save"
						class="btn btn-primary"
						@click="onSave"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AqEditableLabel from '@commonWidgets/AqEditableLabel';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { EverytingElseQueueName, UnfilteredTasksQueueName, ValidationMessages } from '@commonServices/utils/constants';
import usersService from '@commonServices/usersService';
import teamsService from '@commonServices/teamsService';
import workflowService from '@commonServices/workflowService';
import groupBy from 'lodash.groupby';
import { WorkQueueDisplayType } from '@commonServices/models/WorkQueueType';
import { isKnownError, teamInitials } from '@commonServices/utils/general';
import ErrorCode from '@commonServices/models/ErrorCode';
import { alphaNumAndSpace } from '@commonServices/utils/validators';
import { TeamMembers, AssociatedQueues, AddTeam, EditTeam, TeamInfoIcon, AlphabeticalSorting, AutoAssignQueue, AutoAssignStarCard } from '@assets/icons';
import { mapState } from 'vuex';

export default {
	name: 'TeamProfile',
	props: {
		// If id is passed team is being edited
		id: {
			type: Number,
			required: false,
			default: null,
		},
	},
	components: {
		AqEditableLabel,
		TeamMembers,
		AssociatedQueues,
		AddTeam,
		EditTeam,
		TeamInfoIcon,
		AlphabeticalSorting,
		AutoAssignQueue,
		AutoAssignStarCard,
	},
	data () {
		return {
			team: {
				selectedBuckets: [],
				selectedMembers: [],
			},
			isEditTeam: !this.id,
			allMembers: [],
			queues: [],
			lastAddedUserId: null,
			addedUserTeams: {},
			usersTooltips: {},
			isTeamNameUniq: true,
			isSortedAlphabetically: false,
			draggingQueueId: null,
		};
	},
	errorMessages: {
		team: {
			name: {
				required: ValidationMessages.required,
				maxLength: ValidationMessages.maxLength100,
				isUniq: 'Team with such name already exists',
				alphaNumAndSpace: 'The field can have digital and letter only',
			},
			description: {
				required: ValidationMessages.required,
				maxLength: ValidationMessages.maxLength(500),
				alphaNumAndSpace: 'The field can have digital and letter only',
			},
		},
		teamCompound: 'Team should consist of at least 1 team member and populated queues',
	},
	validations () {
		return {
			team: {
				name: {
					required,
					maxLength: maxLength(100),
					isUniq: () => this.isTeamNameUniq,
					alphaNumAndSpace,
				},
				description: {
					required,
					maxLength: maxLength(500),
					alphaNumAndSpace,
				},
				selectedMembers: {
					required,
					minLength: minLength(1),
				},
				selectedBuckets: {
					required,
					minLength: minLength(1),
				},
			},
		};
	},
	async created () {
		const [users, queues] = await Promise.all([usersService.getUsers(), workflowService.getAllQueues()]);

		this.allMembers = users.filter((user) => !user.system && user.enabled);
		this.queues = queues.map(q => ({ ...q, workQueueType: WorkQueueDisplayType[q.type], groupedOptionLabel: `${WorkQueueDisplayType[q.type]} - ${q.name}` }));

		if (this.id) {
			this.team = await teamsService.getTeam(this.id);

			for (let index = 0; index < Math.max(...this.team.selectedQueues.map(x => x.bucketId)); index++) {
				const queuesInBucket = this.team.selectedQueues.filter(x => x.bucketId === (index + 1)) ?? [];
				this.team.selectedBuckets[index] = queuesInBucket.map(sq => this.queues.find(q => q.id === sq.id));
			}
		} else {
			this.team.selectedBuckets = [[], [], []];
		}
	},
	watch: {
		'team.selectedMembers' (newValue, oldValue) {
			this.isSortedAlphabetically = false;
			if (newValue.length && newValue.length > oldValue.length) {
				this.lastAddedUserId = newValue[newValue.length - 1].id;
				this.getUserTeams();
			}
		},
	},
	methods: {
		async getUserTeams () {
			const userTeams = await teamsService.getUserTeams(this.lastAddedUserId);
			// Remove current team if editing
			if (this.id) {
				this.addedUserTeams[this.lastAddedUserId] = userTeams.filter(ut => ut.id !== this.id);
			} else {
				this.addedUserTeams[this.lastAddedUserId] = userTeams;
			}

			for (const member of this.team.selectedMembers) {
				if (member.associatedTeams) {
					const memberTeams = member.associatedTeams.filter(at => at.id !== this.id).map(ut => ut.name).join(', ');
					this.usersTooltips[member.id] = memberTeams.length > 0 ? `This user is already associated to ${memberTeams}` : '';
					continue;
				}

				const usersAssociatedTeams = this.addedUserTeams[member.id].map(ut => ut.name).join(', ');
				if (this.addedUserTeams && this.addedUserTeams[member.id] && usersAssociatedTeams) {
					this.usersTooltips[member.id] = `This user is already associated to ${usersAssociatedTeams}`;
				}

				this.usersTooltips = { ...this.usersTooltips };
			}
		},
		onCancel () {
			this.$emit('cancel');
		},
		async onSave () {
			this.$v.team.$touch();
			if (this.isTeamDataInvalid) return;
			const teamApiCall = this.id ? teamsService.updateTeam : teamsService.createTeam;
			try {
				await teamApiCall(this.toApiTeamRequest(), [ErrorCode.DuplicationErrorCode]);
				this.$emit('save-team');
			} catch (error) {
				if (isKnownError(error, ErrorCode.DuplicationErrorCode)) {
					this.isTeamNameUniq = false;
				}
			}
		},
		toApiTeamRequest () {
			const queues = this.team.selectedBuckets.flatMap((bucket, index) => bucket.map(queue => ({ bucketId: index + 1, id: queue.id })));

			return {
				id: this.id,
				name: this.team.name,
				description: this.team.description,
				userIds: this.team.selectedMembers.map(member => member.id),
				queues,
			};
		},
		onToggleTeamEditing () {
			this.isEditTeam = !this.isEditTeam;
		},
		onRemoveMemberItem ({ id }) {
			const index = this.team.selectedMembers.findIndex(item => item.id === id);
			this.team.selectedMembers.splice(index, 1);
		},
		onRemoveQueueItem ({ id }, bucketId) {
			this.$set(this.team.selectedBuckets, bucketId, this.team.selectedBuckets[bucketId].filter(item => item.id !== id));
		},
		onQueueSelection (bucket) {
			const typesWithUnfilteredTasksSelected = bucket.filter(q => q.name === UnfilteredTasksQueueName).map(q => q.type);
			bucket = bucket.filter(q => !typesWithUnfilteredTasksSelected.includes(q.type) || q.name === UnfilteredTasksQueueName);
		},
		onSortTeamMembersAlphabetically () {
			this.isSortedAlphabetically = true;
		},
		onAddBucket () {
			this.team.selectedBuckets.push([]);

			// Timeout in order to allow last bucket to be rendered in dom before scroll
			setTimeout(() => {
				const bucketList = this.$el.querySelectorAll('.bucket');
				const lastBucket = bucketList[bucketList.length - 1];
				lastBucket.scrollIntoView({ block: 'end', behavior: 'smooth' });
			}, 100);
		},
		onRemoveBucket (index) {
			this.team.selectedBuckets.splice(index, 1);
		},
		bucketQueues (index) {
			// Remove from all queues if it exists in another queue
			const groupedQueues = groupBy(this.queues, 'type');
			const queuesInBucket = this.team.selectedBuckets.flatMap(x => x.map(y => y.id));
			const allQueues = Object.values(groupedQueues)
				.map(group => {
					const isUnfilteredSelected = this.team.selectedBuckets.findIndex(q => q.name === UnfilteredTasksQueueName && q.type === group[0].type) > -1;
					// check if custom queues present to conditionaly show Everything Else queue
					const hasCustomQueues = group.filter(q => !q.readOnly).length > 0;
					// if Unfiltered is selected sort out other queues
					let queues = isUnfilteredSelected ? group.filter(q => q.name === UnfilteredTasksQueueName)
						: group.filter(q => q.name !== EverytingElseQueueName || hasCustomQueues);

					// Filter out queues in another bucket and by Priority
					const isPrioritized = index === 0;
					queues = queues.filter(q => !queuesInBucket.includes(q.id) && q.isPrioritized === isPrioritized);

					// sort queues to have Unfiltered at the begining and Everything Else at the end
					const sortedQueues = [queues.find(q => q.name === UnfilteredTasksQueueName), ...queues.filter(q => ![EverytingElseQueueName, UnfilteredTasksQueueName].includes(q.name)), queues.find(q => q.name === EverytingElseQueueName)].filter(Boolean);
					return {
						workQueueType: WorkQueueDisplayType[group[0].type],
						queues: sortedQueues,
					};
				});
			return allQueues.sort((a, b) => a.workQueueType > b.workQueueType ? 1 : -1);
		},
	},
	computed: {
		...mapState(['themeIconSettings']),
		selectedMembersDisplayList () {
			if (this.isSortedAlphabetically) {
				return [...this.team.selectedMembers].sort((a, b) => { return a.description > b.description ? 1 : -1; });
			} else {
				return this.team.selectedMembers.slice().reverse();
			}
		},
		teamInitials () {
			return teamInitials(this.team);
		},
		isTeamDataInvalid () {
			return this.$v.team.name.$error
			|| this.$v.team.description.$error
			|| this.$v.team.selectedBuckets.$error
			|| this.$v.team.selectedMembers.$error;
		},
	},
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
}

.team-profile {
  position: fixed;
  right: 0;
  width: calc(100% - #{$tasksPanelWidth});
  height: calc(100% - #{$headerHeight});
  z-index: 99;
  background: var(--bodyBg);

  .manage-team {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 15px;

    .header {
      height: 7%;
      padding: 12px;
      background: var(--headerBg);
      font-size: 17px;
    }

    .team-header {
      max-height: 10rem;
      height: auto;
      padding: 10px 0;

      ::v-deep .team-name-input {
        width: 100%;
        min-width: 545px;
        padding: 4px;
        border: none;
        outline: none;
        background: var(--cellIconSmallBg);
        color: var(--paragraphColour);

        &::placeholder {
          color: var(--placeholderText);
        }
      }

      .btn-disabled {
        color: $gray;

        &:hover {
          background: $gray-d-25;
        }
      }
    }

    @mixin flex-colomn-grow {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .team-assignee {
      display: flex;
      gap: 20px;
      height: calc(100% - #{$smallHeader} - 11rem);
      margin-bottom: 20px;

      .tab {
        @include flex-colomn-grow;

        width: 450px;
        background: var(--contentBg);
        box-shadow: #00000045 7px -5px 15px 0;

        .body {
          @include flex-colomn-grow;

          position: relative;
          height: calc(100% - #{$smallHeader});
          overflow-y: auto;
        }

        .header {
          margin: 0;
          padding: 14px;
          height: 61.35px;
          font-size: 17px;

          label {
            vertical-align: super;
            color: var(--sectionTextColour);
          }
        }

        .no-members {
          margin: auto;
          width: 34%;
          display: block;
          margin-top: 5%;
        }

        .no-queues {
          display: block;
          margin: auto;
          width: 42%;
          margin-top: 7%;
        }
      }

      .member-item {
        background: var(--attributeBg);
      }
    }

    .section-footer {
      padding: 0;
    }
  }
}

.cancel-btn {
  &:hover {
    background: $light-d-10;
  }
}

.danger-border {
  border: 1px solid var(--backgroundDanger);
}

.danger-tooltip-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 15px;
}

.danger-tooltip {
  display: none;
}

.team-member:nth-child(3n+0) {
  .danger-tooltip-icon:hover + .danger-tooltip {
    display: block;
    position: absolute;
    top: -6px;
    left: -35%;
    z-index: 1;
    width: 185px;
    color: var(--sectionColourWhite);
    background: var(--backgroundDanger);
    padding: 10px;
    box-shadow: #0000003d 3px 3px 5px 0;

    &::before {
      content: "\A";
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent var(--backgroundDanger);
      position: absolute;
      left: 100%;
    }
  }
}

.danger-tooltip-icon:hover + .danger-tooltip {
  display: block;
  position: absolute;
  top: -6px;
  left: 103%;
  z-index: 1;
  width: 185px;
  color: var(--sectionColourWhite);
  background: var(--backgroundDanger);
  padding: 10px;
  box-shadow: #0000003d 3px 3px 5px 0;

  &::before {
    content: "\A";
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent var(--backgroundDanger) transparent transparent;
    position: absolute;
    left: -6px;
  }
}

.section-header {
  background: var(--sectionFooterBg);

  svg {
    height: 30px;
  }
}

.fas {
  font-size: 12px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - #{$tasksPanelWidth});
  background: var(--sectionFooterBg);
}

.w-33 {
  width: 33%;
}

.icon-small {
  width: 25px;
}

.auto-assign-queue-logo {
  .multiselect__option--highlight & {
    fill: $body-color;
  }

  .multiselect__option--selected & {
    fill: $body-color;
  }

  .multiselect__option--highlight .multiselect__option--selected & {
    fill: $primary;
  }

  fill: $primary;
}

.tooltip-inner {
  background: var(--attributeBg);
  color: var(--bodyColour);
}

.text-black {
  color: $secondary;
}

.bordered {
  border: 1px solid var(--dangerText);
}

.bucket {
  border: 3px solid var(--bannerBg);
  border-radius: 3px;
  padding: 8px;

  &.priority {
    box-shadow: 0 0 10px 6px rgb(0 0 0 / 20%);
    border: 3px solid var(--selectedBorder)
  }

  .auto-assigned-card__icon {
    position: absolute;
    top: -9px;
    left: -12px;
    width: 50px;
    height: 65px;
  }
}

.close {
  color: $body-color;
  position: absolute;
  right: -7px;
  top: -7px;
  border-radius: 99px;
  background: var(--bannerBg);
  width: 19px;
  height: 19px;
  padding-top: 1px;
  padding-left: 5px;

  &.disabled {
    background: var(--tertiarySubSectionColour);
  }
}

.plus-button {
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-radius: 99px;
  position: relative;

  i {
    position: absolute;
    top: 7px;
    left: 2px;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
