export default class PaymentTransaction {
	constructor (claimPaymentId,
		paymentAccountId,
		payee,
		paymentType,
		paymentStatus,
		paymentMethod,
		paymentAccountName,
		paymentAccountNumber,
		grossAmount,
		instructions,
	) {
		this.claimPaymentId = claimPaymentId;
		this.paymentAccountId = paymentAccountId;
		this.payee = payee;
		this.paymentType = paymentType;
		this.paymentStatus = paymentStatus;
		this.paymentMethod = paymentMethod;
		this.paymentAccountName = paymentAccountName;
		this.paymentAccountNumber = paymentAccountNumber;
		this.grossAmount = grossAmount;
		this.instructions = instructions;
		this.lastInstruction = this.instructions[this.instructions.length - 1];
	}
};
