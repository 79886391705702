import { render, staticRenderFns } from "./AqCustomerCard.vue?vue&type=template&id=fbfc4590&scoped=true"
import script from "./AqCustomerCard.vue?vue&type=script&lang=js"
export * from "./AqCustomerCard.vue?vue&type=script&lang=js"
import style0 from "./AqCustomerCard.vue?vue&type=style&index=0&id=fbfc4590&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbfc4590",
  null
  
)

export default component.exports