import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';

const deductionTypeEnum = Object.freeze({
	PartialCoverageDeduction: 'PartialCoverageDeduction',
	FixedExcessDeduction: 'FixedExcessDeduction',
	CoInsuranceDeduction: 'CoInsuranceDeduction',
	ConditionLimitDeduction: 'ConditionLimitDeduction',
	SectionLimitDeduction: 'SectionLimitDeduction',
	NoCoverDeduction: 'NoCoverDeduction',
	NonFinancialLimitDeduction: 'NonFinancialLimitDeduction',
	RejectedDeduction: 'RejectedDeduction',
	TaxDeduction: 'TaxDeduction',
});

const deductionTypeDisplayMap = Object.freeze({
	[deductionTypeEnum.PartialCoverageDeduction]: 'Section % Cover',
	[deductionTypeEnum.FixedExcessDeduction]: 'Excess',
	[deductionTypeEnum.CoInsuranceDeduction]: 'Co-ins',
	[deductionTypeEnum.ConditionLimitDeduction]: 'Condition Limit',
	[deductionTypeEnum.SectionLimitDeduction]: 'Limit',
	[deductionTypeEnum.NoCoverDeduction]: 'No Cover',
	[deductionTypeEnum.NonFinancialLimitDeduction]: 'Non Financial Limit',
	[deductionTypeEnum.RejectedDeduction]: 'Rejected',
	[deductionTypeEnum.TaxDeduction]: 'Tax',
});

export function getDeductionDescription (deductionType, locale = null) {
	if (deductionTypeEnum.FixedExcessDeduction === deductionType) {
		return getUiSettingsByLocale(locale).excessLabel;
	}
	return deductionTypeDisplayMap[deductionType];
}

export function getDeductionOriginalLimitDescription (claimItemDeductions, name) {
	const deduction = claimItemDeductions.find(deduction => deduction.deductionType === name);
	return deduction ? `(${deduction.originalLimit}%)` : '';
}

export { deductionTypeEnum, deductionTypeDisplayMap };
