export default class ReportModel {
	constructor (
		brandName,
		brandLocale,
		customerId,
		policyId,
		policyReference,
		claimId,
		customerFirstName,
		customerLastName,
	) {
		this.brandName = brandName;
		this.customerId = customerId;
		this.policyId = policyId;
		this.policyReference = policyReference;
		this.claimId = claimId;
		this.customerFirstName = customerFirstName;
		this.customerLastName = customerLastName;
	}
};
