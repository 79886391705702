<template>
	<div
		class="panel-open"
		:class="{ 'no-overlay' : !useOverlay }"
	>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		useOverlay: {
			type: Boolean,
			default: true,
			required: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.panel-open:not(.no-overlay) {
  &::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0.5;
    left: 0;
  }
}
</style>
