<template>
	<div>
		<div class="section-content">
			<div class="container-fluid">
				<div class="mt-20 mb-10 font-weight-bold">
					Detected Multiple Conditions
				</div>
				<div class="mb-20">
					Please select which condition you would like to claim for
				</div>
				<div class="row no-gutters attribute mb-10">
					<div class="col-5 py-20 px-20">
						Condition One
					</div>
					<button
						v-if="!linkedClaimCreated"
						class="btn btn-radius--less my-10 mr-20 ml-auto d-block btn-fixed-width"
						:class="[isConditionOneSelected ? 'btn-warning' : 'btn-primary']"
						@click="onSelectConditionOne"
					>
						{{ isConditionOneSelected ? 'Selected' : 'Select' }}
					</button>
					<div
						v-else-if="conditionData.claimId == $route.params.claimId"
						class="py-20 px-20 ml-auto d-block font-weight-bold"
					>
						Current Claim
					</div>
					<div
						v-else-if="conditionData.claimId != $route.params.claimId"
						class="mx-20 my-12 ml-auto card-icon small"
						@click="onClaimRedirect(conditionData.claimId)"
					>
						<i
							class="fas fa-link"
							v-tooltip="`Linked ${conditionData.claimId} for ${conditionData.ailment ? conditionData.ailment : 'no ailment'}`"
						/>
					</div>
				</div>
				<div class="row no-gutters attribute mb-10">
					<div class="col-5 py-20 px-20">
						Condition Two
					</div>
					<button
						v-if="!linkedClaimCreated"
						class="btn btn-primary btn-radius--less my-10 mr-20 ml-auto d-block btn-fixed-width"
						:class="[isConditionTwoSelected ? 'btn-warning' : 'btn-primary']"
						@click="onSelectConditionTwo"
					>
						{{ isConditionTwoSelected ? 'Selected' : 'Select' }}
					</button>
					<div
						v-else-if="secondConditionData.claimId == $route.params.claimId"
						class="py-20 px-20 ml-auto d-block font-weight-bold"
					>
						Current Claim
					</div>
					<div
						v-else-if="secondConditionData.claimId != $route.params.claimId"
						class="mx-20 my-12 ml-auto card-icon small"
						@click="onClaimRedirect(secondConditionData.claimId)"
					>
						<i
							class="fas fa-link"
							v-tooltip="`Linked ${secondConditionData.claimId} for ${secondConditionData.ailment ? secondConditionData.ailment : 'no ailment'}`"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'MultiConditionSelector',
	data () {
		return {
			isConditionOneSelected: true,
			isConditionTwoSelected: false,
		};
	},
	props: {
		conditionData: {
			type: Object,
			required: false,
			default: undefined,
		},
		secondConditionData: {
			type: Object,
			required: false,
			default: undefined,
		},
		linkedClaimCreated: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	mounted () {
		this.isConditionOneSelected = this.conditionData.claimId !== 0;
		this.isConditionTwoSelected = this.secondConditionData.claimId !== 0;
	},
	methods: {
		onSelectConditionOne () {
			this.isConditionOneSelected = !this.isConditionOneSelected;
			this.$emit('conditionOneClicked', this.isConditionOneSelected);
		},
		onSelectConditionTwo () {
			this.isConditionTwoSelected = !this.isConditionTwoSelected;
			this.$emit('conditionTwoClicked', this.isConditionTwoSelected);
		},
		onClaimRedirect (claimId) {
			const routeData = this.$router.resolve({ name: 'claim-page', params: { customerId: this.$route.params.customerId, claimId } });
			window.open(routeData.href, '_blank');
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-fixed-width {
  width: 120px;
}

.btn-radius--less {
  border-radius: 2px;
}
</style>
