<template>
	<el-table
		:data="claims"
		style="width: 100%;"
		class="no-spacing claims-table"
		:height="tableHeight"
		:row-class-name="getRowClass"
		@row-click="onSelectClaim"
		empty-text="No claims found"
	>
		<el-table-column
			width="43"
			class-name="bookmark-column"
		>
			<template slot-scope="scope">
				<aq-bookmark
					class="claim-source"
					v-if="$options.claimSourceLabelMap[scope.row.source]"
					:label="$options.claimSourceLabelMap[scope.row.source]"
					:tooltip="`Source: ${$options.claimSourceDisplayMap[scope.row.source]}`"
				/>
			</template>
		</el-table-column>
		<el-table-column
			label="ClaimID"
			width="110"
		>
			<template slot-scope="scope">
				<div
					v-if="isParent(scope.row) && showParentIcon"
					class="parent-condition"
				>
					<div>
						{{ scope.row.claimId | claimIdFormat }}
					</div>
					<ParentIcon class="icon" />
				</div>
				<div v-else>
					{{ scope.row.claimId | claimIdFormat }}
				</div>
			</template>
		</el-table-column>
		<el-table-column
			label="Tmt. Start"
			width="80"
		>
			<template slot-scope="scope">
				<div>
					{{ scope.row.treatmentStart | shortDateNoCentury }}
				</div>
			</template>
		</el-table-column>
		<template v-if="showExtraColumns">
			<el-table-column
				label="Invoice Number"
				width="120"
			>
				<template slot-scope="scope">
					<ClaimInvoicesDetails
						:invoices="scope.row.invoices"
						:boundaries-element-selector="boundariesElementSelector"
					/>
				</template>
			</el-table-column>
		</template>
		<el-table-column
			label="Diagnosis"
			prop="diagnosis"
			min-width="130"
		>
			<template slot-scope="scope">
				<truncated-text-cell :value="scope.row.diagnosis" />
			</template>
		</el-table-column>
		<el-table-column label="Description">
			<template slot-scope="scope">
				<truncated-text-cell :value="scope.row.description" />
			</template>
		</el-table-column>
		<el-table-column
			label="Assigned"
			width="100"
		>
			<template slot-scope="scope">
				<truncated-text-cell :value="scope.row.assignedToUser?.description || 'Unassigned'" />
			</template>
		</el-table-column>
		<el-table-column
			label="Provider"
			width="140"
		>
			<template slot-scope="scope">
				<truncated-text-cell :value="getClaimVets(scope.row.claimVets)" />
			</template>
		</el-table-column>
		<el-table-column
			label="Amount"
			prop="amount"
			width="90"
		>
			<template slot-scope="scope">
				<div>{{ scope.row.amount | currency(locale) }}</div>
			</template>
		</el-table-column>
		<el-table-column
			label="Paid"
			width="90"
		>
			<template slot-scope="scope">
				<ClaimDeductionsDetails
					:paid="scope.row.paid"
					:claim-items="scope.row.claimItems"
					:locale="locale"
					:boundaries-element-selector="boundariesElementSelector"
				/>
			</template>
		</el-table-column>
		<el-table-column
			label="Status"
			prop="displayStatus"
			width="130"
		>
			<template slot-scope="scope">
				<v-popover
					class="claim-status-popover"
					trigger="hover"
					:popper-options="$options.popperOptions"
					placement="top"
					popover-inner-class="tooltip-inner"
					popover-arrow-class="tooltip-arrow"
				>
					<div class="text-truncate">
						<i
							:class="$options.claimStatusColors[scope.row.displayStatus]"
							class="fas fa-circle mr-4"
						/>
						{{ scope.row.displayStatus }}
					</div>
					<template slot="popover">
						<div class="text-white text-center">
							<div class="text-bold">
								{{ scope.row.displayStatus }}
							</div>
							<hr class="tiny-hr">
							<div>{{ getTooltipText(scope.row) }}</div>
						</div>
					</template>
				</v-popover>
			</template>
		</el-table-column>
		<el-table-column width="60">
			<template slot-scope="scope">
				<div class="claim-row-icons">
					<linked-claims
						v-if="scope.row.linkedClaims.length"
						class="mr-5"
						:linked-claims="scope.row.linkedClaims"
					/>
					<pre-authorisation-icon :form-type="scope.row.formType" />
					<v-popover
						class="d-flex"
						trigger="hover"
						:popper-options="$options.popperOptions"
						placement="top"
						popover-inner-class="tooltip-inner mb-3"
						popover-arrow-class="tooltip-arrow mb-3"
					>
						<div
							v-if="scope.row.wasReassessed"
							data-qa="claims-table_icon_reassesment"
							class="card-icon small"
						>
							<div class="reassessment-reason">
								<span>{{ scope.row.reassessmentReasonCode }}</span>
							</div>
						</div>
						<template slot="popover">
							<div class="text-white">
								<div>Reassessment Date: {{ longDate(scope.row.reassessmentDateTime) }}</div>
								<hr class="tiny-hr">
								<div>Reassessment Reason: {{ scope.row.reassessmentReason }}</div>
							</div>
						</template>
					</v-popover>
				</div>
			</template>
		</el-table-column>
		<template v-if="showExtraColumns">
			<el-table-column
				label="Date Submitted"
				width="150"
			>
				<template slot-scope="scope">
					<div>
						{{ scope.row.createdDateTime | shortDateTimeNoCentury }}
					</div>
				</template>
			</el-table-column>
			<el-table-column
				label="Date Closed"
				width="150"
			>
				<template slot-scope="scope">
					<div>
						{{ scope.row.closedDateTime | shortDateTimeNoCentury }}
					</div>
				</template>
			</el-table-column>
			<el-table-column
				label="Payment Details"
				min-width="170"
			>
				<template slot-scope="scope">
					<ClaimPaymentsDetails
						:claim-id="scope.row.claimId"
						:payments="scope.row.payments"
						:locale="locale"
						:boundaries-element-selector="boundariesElementSelector"
					/>
				</template>
			</el-table-column>
		</template>
		<el-table-column width="60">
			<template slot-scope="scope">
				<aq-dropdown
					:options="getOptions(scope.row)"
					:btn-class="'btn-row small'"
					:ref="scope.row.claimId"
					:is-shortkey-context="scope.row === currentItem"
					:keep-together="false"
					:boundaries-selector="tableBoundariesQuery"
					@select="onSelectOption($event, scope.row)"
				/>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
import {
	claimStatusColorsMap,
	claimNumberStatus,
	finalClaimStatuses,
	readOnlyClaimStates,
} from '@commonServices/models/ClaimStatusActionEnum';
import { claimIdFormat, longDate } from '@commonServices/utils/filters';
import { ParentIcon } from '@assets/icons';
import { DefaultFiltersEnum, DefaultFiltersEnumLabel } from '@commonServices/models/DefaultFiltersEnum';
import LinkedClaims from '@commonView/Shared/LinkedClaims';
import PreAuthorisationIcon from '@commonView/Shared/PreAuthorisationIcon';
import { claimSource, claimSourceDisplayMap, claimSourceLabelMap } from '@clientCommon/services/models/ClaimSource';
import ClaimInvoicesDetails from './ClaimInvoicesDetails';
import ClaimPaymentsDetails from './ClaimPaymentsDetails';
import ClaimDeductionsDetails from './ClaimDeductionsDetails';

export default {
	name: 'ClaimsTable',
	components: {
		ParentIcon,
		LinkedClaims,
		ClaimInvoicesDetails,
		ClaimPaymentsDetails,
		ClaimDeductionsDetails,
		PreAuthorisationIcon,
	},
	props: {
		claims: {
			type: Array,
			required: true,
		},
		onSelectOption: {
			type: Function,
			required: true,
		},
		onSelectClaim: {
			type: Function,
			required: true,
		},
		getRowClass: {
			type: Function,
			required: true,
		},
		currentItem: {
			type: Object,
			required: false,
			default: null,
		},
		brand: {
			type: Object,
			required: true,
		},
		tableHeight: {
			type: String,
			required: false,
			default: null,
		},
		showExtraColumns: {
			type: Boolean,
			required: true,
		},
		allowUngroup: {
			type: Boolean,
			required: false,
			default: false,
		},
		showParentIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
		boundariesElementSelector: {
			type: String,
			required: false,
			default: null,
		},
	},
	claimStatusColors: claimStatusColorsMap,
	claimSourceLabelMap,
	claimSourceDisplayMap,
	claimSource,
	computed: {
		locale () {
			return this.brand.brandLocale;
		},
		billingSyncEnabled () {
			return this.brand.billingSyncEnabled;
		},
	},
	methods: {
		claimIdFormat,
		longDate,
		getOptions (claim) {
			const dropdownOptions = [];
			if (!readOnlyClaimStates.includes(claim.status) && (this.$can.ClaimEdit || this.$can.ClaimEditContinuation)) {
				dropdownOptions.push({ name: 'Edit', value: 'edit', iconClass: 'fa-pencil-alt' });
			} else if (this.$can.ViewClaimData) {
				dropdownOptions.push({ name: 'View', value: 'view', iconClass: 'fa-eye' });
			}
			if (finalClaimStatuses.includes(claim.status) && (this.$can.ReassessClaim || this.$can.RequestReassessClaim)) {
				dropdownOptions.push({ name: 'Reassess', value: 'reassess', iconClass: 'fa-file-alt' });
			}

			if (claim.status === claimNumberStatus.Settled && this.$can.RejectClaimPayment) {
				dropdownOptions.push({ name: 'Edit', value: 'edit-reject-payment', iconClass: 'fa-pencil-alt' });
			}
			if (claim.status === claimNumberStatus.RejectionPending && this.$can.RejectManualRejectClaim) {
				dropdownOptions.push({ name: 'Edit', value: 'edit-reject-pending', iconClass: 'fa-pencil-alt' });
			}

			const claimCondition = claim.conditions.find(condition => condition.id === claim.claimConditionId);
			if (this.allowUngroup && (claimCondition.parentConditionId || claimCondition.isParent)) {
				dropdownOptions.push({ name: 'Ungroup', value: 'ungroup-condition', iconClass: 'aqv-ungroup' });
			}

			return dropdownOptions;
		},
		getTooltipText (claimData) {
			if (claimData.assessedByUser || claimData.closedByUser) {
				const user = (claimData.assessedByUser || claimData.closedByUser).description;
				const assessedDateTime = claimData.assessedDateTime || claimData.closedDateTime;
				return `Assessed by ${user} on ${longDate(assessedDateTime)}`;
			}
			if (claimData.assignedToUser) {
				return `Assigned to ${claimData.assignedToUser.description} on ${longDate(claimData.assignedToDateTime)}`;
			}
			return DefaultFiltersEnumLabel[DefaultFiltersEnum.Unassigned];
		},
		getClaimVets (claimVets) {
			return claimVets.map(cv => cv.practiceName).join('; ');
		},
		isParent (claim) {
			return claim.conditions.find(cc => claim.claimConditionId === cc.id)?.isParent;
		},
	},
	data () {
		return {
			tableBoundariesQuery: '.pet-claims .section-content',
		};
	},
};
</script>

<style lang="scss">
.el-table.claims-table {
  .el-table__header {
    th > div.cell {
      white-space: nowrap !important;
      color: $primary;
      font-weight: 600;
    }
  }

  .claim-row-icons {
    display: flex;
    width: fit-content;
  }

  .bookmark-column .cell {
    padding-left: 0 !important;
  }

  .claim-source {
    top: 6px;
    left: -1px;
  }
}

.tiny-hr {
  font-size: 6px;
  opacity: 0.2;
}

.claim-status-popover {
  display: flex;

  .trigger {
    overflow: hidden;
  }
}

.parent-condition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
  padding-left: 5px;
  background-color: var(--parentConditionColour);

  .icon {
    height: 23px;
    width: 18px;
  }
}

.reassessment-reason {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid $warning;
  background-color: var(--specialHighlightAvatarBackgroundColour);

  &:hover {
    transform: scale(1.1);

    span {
      font-size: 17px;
    }
  }

  span {
    margin-top: 1px;
    color: $warning;
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
