<template>
	<div>
		<div
			:class="['d-flex align-items-center ml-n3 mr-40', { 'cursor-pointer': status!==StatusEnum.WAIT }]"
			@click="$emit('click')"
		>
			<div class="circle mr-4">
				<i :class="[icon, getIconColor]" />
			</div>
			<div
				class="title fs-13"
				:class="getTitleStyles"
			>
				{{ title }}
			</div>
		</div>
	</div>
</template>

<script>
import StatusEnum from '@commonServices/models/steps/StepStatusEnum';
import StepTypeEnum from '@commonServices/models/steps/StepTypeEnum';

export default {
	name: 'AqWizardItem',
	props: {
		title: String,
		icon: String,
		description: String,
		status: String,
		type: String,
	},
	data () {
		return {
			StepTypeEnum,
			StatusEnum,
		};
	},
	beforeCreate () {
		this.$parent.steps.push(this);
	},
	computed: {
		getIconColor () {
			if (this.status === StatusEnum.PROCESS
					&& this.type === StepTypeEnum.MINOR) {
				return 'text-warning';
			}
			switch (this.status) {
			case StatusEnum.PROCESS: return 'text-primary';
			case StatusEnum.WAIT: return 'text-subtitle';
			case StatusEnum.SUCCESS: return 'text-success';
			default: return 'text-subtitle';
			}
		},
		getTitleStyles () {
			const titleColor = this.status === StatusEnum.WAIT ? 'text-opaque' : '';
			return `${titleColor}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.fa-stack {
  width: 2em;
}

.circle {
  border-radius: 50%;
  width: 31px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  box-shadow: 0 1px 2px -1px var(--bodyColour);
}

.opacity-70 {
  opacity: 0.7;
}
</style>
