<template>
	<div />
</template>

<script>
import TaskService from '@commonServices/taskService';

export default {
	name: 'AqTaskCheck',
	props: {
		taskId: {
			type: [Number, String],
			required: true,
		},
		options: {
			type: Object,
			default: () => ({}),
		},
	},
	mounted () {
		this.startTaskCheck();
	},
	data () {
		return {
			timer: 0,
			interval: 300,
		};
	},
	methods: {
		startTaskCheck () {
			let lastProgressUpdate = Date.now();
			let lastProgress = 0;
			const self = this;
			this.timer = setTimeout(function check () {
				TaskService.getTaskInfo(self.taskId)
					.then(taskData => {
						self.$emit('task-status-changed', taskData);
						if (lastProgress !== taskData.progress) {
							lastProgress = taskData.progress;
							lastProgressUpdate = Date.now();
						}
						if (taskData.progress === 100) {
							return;
						}
						if (self.options.maxLifeTime && (Date.now() - lastProgressUpdate) > self.options.maxLifeTime) {
							self.$emit('task-expired', { ...taskData, options: self.options });
						}
						self.timer = setTimeout(check, self.options.interval || self.interval);
					});
			}, this.options.interval || this.interval);
		},
	},
	beforeDestroy () {
		clearTimeout(this.timer);
	},
};
</script>
