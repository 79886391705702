import PermissionType from '@commonServices/settings/PermissionType';

const WorkQueueType = Object.freeze({
	PaymentApproval: 1,
	FraudCheck: 2,
	RejectionApproval: 3,
	Chasing: 4,
	ReassessmentPending: 5,
	DraftClaims: 6,
});

const WorkQueueDisplayType = Object.freeze({
	[WorkQueueType.PaymentApproval]: 'Payment Approval',
	[WorkQueueType.FraudCheck]: 'Fraud Check',
	[WorkQueueType.RejectionApproval]: 'Rejection Approval',
	[WorkQueueType.Chasing]: 'Missing Information',
	[WorkQueueType.ReassessmentPending]: 'Reassessment Pending',
	[WorkQueueType.DraftClaims]: 'Draft Claims',
});

const TaskTypeTitles = {
	[WorkQueueType.PaymentApproval]: 'Approval',
	[WorkQueueType.FraudCheck]: 'Fraud Flags',
	[WorkQueueType.RejectionApproval]: 'Rejection',
	[WorkQueueType.Chasing]: 'Missing Information',
	[WorkQueueType.ReassessmentPending]: 'Reassessment Pending',
	[WorkQueueType.DraftClaims]: 'Draft Claims',
};

const dropdownActions = {
	approve: { name: 'Approve', value: 'approve', iconClass: 'fa-check' },
	reject: { name: 'Reject', value: 'reject', iconClass: 'fa-times' },
	view: { name: 'View', value: 'view', iconClass: 'fa-eye' },
	chase: { name: 'Chase', value: 'chase', iconClass: 'fa-envelope' },
	reassess: { name: 'Reassess', value: 'reassess', iconClass: 'fa-file' },
};

const dropdownOptions = {
	[WorkQueueType.PaymentApproval]: [
		{ action: dropdownActions.approve, permission: PermissionType.ApproveClaimPayment },
		{ action: dropdownActions.reject, permission: PermissionType.RejectClaimPayment },
	],
	[WorkQueueType.FraudCheck]: [
		{ action: dropdownActions.approve, permission: PermissionType.ApproveManualFraudCheck },
		{ action: dropdownActions.reject, permission: PermissionType.RejectManualFraudCheck },
	],
	[WorkQueueType.RejectionApproval]: [
		{ action: dropdownActions.approve, permission: PermissionType.ApproveManualRejectClaim },
		{ action: dropdownActions.reject, permission: PermissionType.RejectManualRejectClaim },
	],
	[WorkQueueType.Chasing]: [
		{ action: dropdownActions.chase, permission: PermissionType.ChaseMissingInformation },
	],
	[WorkQueueType.ReassessmentPending]: [
		{ action: dropdownActions.reassess, permission: PermissionType.ReassessClaim },
	],
	[WorkQueueType.DraftClaims]: [],
};

function getDropdownOptionsForPot (type, can, { chaseCount, chaseLimit }) {
	let options = dropdownOptions[type]
		.filter(option => can[option.permission]).map(option => option.action);

	if ((type === WorkQueueType.Chasing) && (chaseCount >= chaseLimit)) {
		options = options.filter(option => option.value !== dropdownActions.chase.value);
	}

	return options;
}

export { WorkQueueType, WorkQueueDisplayType, getDropdownOptionsForPot, TaskTypeTitles, dropdownActions };
