<template>
	<div class="w-100">
		<div class="bottom mt-2">
			<div
				ref="text"
				:class="['no-space-wrap', 'white-space-pre-wrap', { 'more-less-toggle': !showFull, 'overflow-hidden': !showFull }]"
			>
				<div>{{ flattenedText }}</div>
			</div>
		</div>
		<slot
			name="toggler"
			v-if="isOverflowing"
		>
			<aq-more-less-toggler
				class="more-or-less-toggler"
				:opened="showFull"
				@show-more-less="showFull = $event"
			/>
		</slot>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			showFull: false,
			isOverflowing: false,
		};
	},
	computed: {
		flattenedText () {
			return this.text.replaceAll('\\n', '').replaceAll('\\r', '');
		},
	},
	mounted () {
		this.isOverflowing = this.checkOverflow();
	},
	methods: {
		checkOverflow () {
			const element = this.$refs.text;
			return (element.offsetHeight < element.scrollHeight);
		},
	},
};
</script>

<style lang="scss" scoped>
.more-less-toggle {
  max-height: 3.9rem;
  text-overflow: ellipsis;
  display: -webkit-box; // stylelint-disable value-no-vendor-prefix -- 'box' is not supported
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
