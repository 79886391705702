import axios from 'axios';
import { Loading } from 'element-ui';
import SecurityManager from './securityService';
import statusCode from '@commonServices/HttpStatusCode';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError, validationErrorSummary } from '@commonServices/utils/general';
import Vue from 'vue';

const spinnerOptions = {
	lock: false,
	spinner: 'fas fa-spinner',
	background: 'rgba(0, 0, 0, 0.7)',
};

class AuthenticationError extends Error {
	constructor (message) {
		super(message);
		this.name = 'AuthenticationError';
	}
}

/**
 * @param {Object} e The error instance.
 * @param {Array} handleErrorCodes The array of error codes which should be handled with toast message.
 */
async function errorHandler (e, handleErrorCodes) {
	if (e.response) {
		if (isKnownError(e, ...handleErrorCodes)) {
			throw e;
		} else if (e.response.status === statusCode.BadRequest && e.response.data) {
			if (e.response.data.message) {
				Vue.toasted.global.common_error({ message: e.response.data.message });
			}
			if (e.response.data.errors) {
				Vue.toasted.global.common_error({ message: validationErrorSummary(e) });
			}
			throw e;
		}	else if (e.response.status === statusCode.Unauthorized) {
			return await refreshToken();
		} else if (isKnownError(e, ErrorCode.InvalidActionError)) {
			Vue.toasted.global.common_error({ message: e.response.data.message });
		}
		Vue.toasted.global.common_error({ message: 'Something went wrong' });
	} else if (e instanceof AuthenticationError) {
		return await refreshToken();
	}

	console.log(e.response);
	throw e;

	async function refreshToken () {
		await SecurityManager.removeUser();
		await SecurityManager.getAccessToken();
	}
}

const minSpinnerTime = 500; // in ms
let spinnerDestroy; // store destroy spinner function to share between simultaneous requests
function createSpinner () {
	if (spinnerDestroy) { // spinner is already active therefore increase counter and return current destroy function
		spinnerDestroy.activeSpinnerCount++;
		return spinnerDestroy;
	}
	const start = Date.now();
	spinnerDestroy = () => {
		if (spinnerDestroy.activeSpinnerCount > 1) { // do not close spinner as there are other active requests (spinners)
			spinnerDestroy.activeSpinnerCount--; // just decrease counter instead
			return;
		}
		const actualDuration = Date.now() - start; // the last request ended and started spinner closing process
		if (actualDuration < minSpinnerTime) { // does not reach minimum spinner appearance time so postpone closing
			setTimeout(() => {
				if (spinnerDestroy.activeSpinnerCount > 1) { // it's possible that while closing was postponed, new request was initiated therefore just decrement counter and exit
					spinnerDestroy.activeSpinnerCount--;
					return;
				}
				spinnerInstance.close();
				spinnerDestroy = null;
			}, (minSpinnerTime - actualDuration));
		} else {
			spinnerInstance.close();
			spinnerDestroy = null;
		}
	};
	spinnerDestroy.activeSpinnerCount = 1;
	const spinnerInstance = Loading.service(spinnerOptions);
	return spinnerDestroy;
}
/**
 *
 * @param {String} type Type of request e.g. 'get', 'post'
 * @param {String} url Url to call API endpoints
 * @param {data} data Payload to pass to API
 * @param {object} requestOptions request options
 */

const defaultAxiosConfig = {
	headers: { 'Content-Type': 'application/json; charset=utf-8', timezoneoffset: new Date().getTimezoneOffset() },
};

function _processRequest (type, url, data, requestOptions) {
	const stopSpinner = requestOptions.showGlobalSpinner ? createSpinner() : () => {}; // create function for future spinner stop or just empty function (to avoid extra 'if' checks)
	const axiosConfig = { ...defaultAxiosConfig, ...requestOptions.config };
	return defineHeaderAxios().then(() => {
		switch (type) {
		case 'post': return axios.post(url, data, axiosConfig);
		case 'get': return axios.get(url, axiosConfig);
		case 'put': return axios.put(url, data, axiosConfig);
		case 'patch': return axios.patch(url, data, axiosConfig);
		case 'delete': return axios.request({ url, method: 'delete', data, ...axiosConfig });
		}
	})
		.then(response => { stopSpinner(); return response.data; })
		.catch(async (e) => { stopSpinner(); await errorHandler(e, requestOptions.handleErrorCodes); });
}

async function defineHeaderAxios () {
	const token = await SecurityManager.getAccessToken();
	if (!token) {
		throw new AuthenticationError('No active access token');
	}
	axios.defaults.headers.common.Authorization = 'Bearer ' + token;
}

/**
 * @param {String} relativeUrl realative url to call API endpoints
 * @param {Boolean} omitSpinner whether global spinner should be applied
 * @param {string} baseUrl base api url
 * @param {object} config axios config
 * @param {Array} handleErrorCodes The array of error codes which should be handled with toast message.
 */
export default function httpClient (relativeUrl, { omitSpinner = false, baseUrl = ClaimsConfig.CLAIMS_ROOT_API, config = {}, handleErrorCodes = [] } = {}) {
	const requestOptions = { showGlobalSpinner: !omitSpinner, config, handleErrorCodes };
	const url = `${baseUrl}${relativeUrl}`;
	return {
		post: (data) => _processRequest('post', url, data, requestOptions),
		get: () => _processRequest('get', url, null, requestOptions),
		put: data => _processRequest('put', url, data, requestOptions),
		patch: data => _processRequest('patch', url, data, requestOptions),
		delete: data => _processRequest('delete', url, data, requestOptions),
		getBlob: () => _processRequest('get', url, null, { ...requestOptions, config: { responseType: 'arraybuffer', ...requestOptions.config } }),
	};
};

export function usersHttpClient (relativeUrl, { omitSpinner = false, baseUrl = ClaimsConfig.CLAIMS_USERS_API, config = {} } = {}) {
	const usersApiBaseAddress = baseUrl.replace('/api/users', '') + '/api/users'; // 'replace' is temporary till config update (agreed to remove '/api/users' from the config and add path here) will be propagated to all envs.
	return httpClient(relativeUrl, { omitSpinner, baseUrl: usersApiBaseAddress, config });
};

export function reportingHttpClient (relativeUrl, { omitSpinner = false, baseUrl = ClaimsConfig.CLAIMS_REPORTING_API, config = {} } = {}) {
	return httpClient(relativeUrl, { omitSpinner, baseUrl, config });
};
