const PolicyStatus = Object.freeze({
	Live: 'Live',
	Canceled: 'Canceled',
	CancellationPending: 'Cancellation Pending',
	Reinstating: 'Reinstating',
	Lapsed: 'Lapsed',
});

const PolicyStatusDisplay = Object.freeze({
	[PolicyStatus.Live]: 'Live',
	[PolicyStatus.Canceled]: 'Cancelled',
	[PolicyStatus.CancellationPending]: 'Cancellation Pending',
	[PolicyStatus.Reinstating]: 'Reinstating',
	[PolicyStatus.Lapsed]: 'Lapsed',
});

export { PolicyStatus, PolicyStatusDisplay };
