<template>
	<div class="d-flex align-items-center">
		<aq-icon-avatar
			class="small mr-10"
			:value="[notification.createdBy.firstName, notification.createdBy.lastName]"
		/>
		<notification-item-info
			class="attribute py-15 px-20 d-flex"
			:header="notification.message"
			:rejection-comment="notification.rejectionComment"
			:paragraph="notification.comment"
			@navigate="onNavigateToClaim"
		/>
	</div>
</template>

<script>
export default {
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onNavigateToClaim () {
			this.$emit('close');
			this.$router.push({ name: 'claim-page', params: { customerId: this.notification.customerId, claimId: this.notification.claimId } });
		},
	},
};
</script>
