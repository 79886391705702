export default class Interaction {
	constructor (
		id,
		claimId,
		actionType,
		dateTime,
		user,
		value,
		description,
		pet,
		cause,
		fileId,
		fileName,
		details,
		iconType,
		arrowDirectionIcon,
		linkedClaims,
		commDetails,
		commType,
		attachments,
		externalDocUrl,
		comment,
		vetName,
	) {
		this.id = id;
		this.claimId = claimId;
		this.actionType = actionType;
		this.dateTime = dateTime;
		this.user = user;
		this.value = value;
		this.description = description;
		this.pet = pet;
		this.cause = cause;
		this.fileId = fileId;
		this.fileName = fileName;
		this.details = details;
		this.iconType = iconType;
		this.arrowDirectionIcon = arrowDirectionIcon;
		this.linkedClaims = linkedClaims;
		this.commDetails = commDetails;
		this.commType = commType;
		this.attachments = attachments;
		this.live = false;
		this.dead = false;
		this.externalDocUrl = externalDocUrl;
		this.comment = comment;
		this.vetName = vetName;
	}
}
