<template>
	<div>
		<div class="claim-fraud-review-banner d-flex justify-content-between align-items-center py-14 px-44">
			<div class="d-inline-flex">
				This claim has been flagged for fraud review.
				<div
					v-if="fraudCheckReasons.length"
					class="d-inline-flex ml-4"
				>
					Fraud Flags:
					<div
						class="text-underline ml-4 cursor-pointer"
						@click="$modal.show('flags-data-modal')"
					>
						<strong>{{ fraudCheckReasons.length }}</strong>
						{{ 'Flag' | pluralize(fraudCheckReasons.length) }}
					</div>
				</div>
			</div>
			<div class="mt-6">
				<button
					v-if="this.$can.ApproveManualFraudCheck"
					class="btn"
					@click="onApprove"
				>
					Approve
				</button>
				<button
					v-if="this.$can.RejectManualFraudCheck"
					class="btn ml-10"
					@click="$modal.show('fraud-reject-dialog')"
				>
					Reject
				</button>
			</div>
		</div>
		<aq-confirmation-modal
			ref="fraudApprovalDialog"
			name="fraud-approval-dialog"
			title="Approve Claim"
			:description="confirmationMessage"
			yes-label="Proceed"
			no-label="Cancel"
			data-qa="fraudApproval_modal"
		/>
		<aq-modal
			name="fraud-reject-dialog"
			data-qa="fraudReject_modal"
		>
			<reject-claim-modal @rejected-claim="rejectFraudCheckClaim" />
		</aq-modal>
		<aq-modal
			v-if="fraudCheckReasons.length"
			name="flags-data-modal"
		>
			<flags-data-modal
				title="Fraud Flags"
				:data="fraudCheckReasons"
			/>
		</aq-modal>
		<reassessment-outcome-modal
			data-qa="aqFraud_modal_reassessmentOutcomeModal"
			name="reassessment-outcome-modal-fraud-check"
			ref="rejectReassessmentOutcomeModal"
			:selected-reassessment-outcome="selectedReassessmentOutcome"
			:claim-id="claimId"
			@input="selectedReassessmentOutcome = $event"
		/>
	</div>
</template>

<script>
import { outcomeState } from '@commonServices/models/ClaimStatusActionEnum';
import WorkflowService from '@commonServices/workflowService';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError } from '@commonServices/utils/general';

export default {
	name: 'AqFraudCheckActions',
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		hasClaimUnsavedChanges: {
			type: Boolean,
			required: true,
		},
		isReassessment: {
			type: Boolean,
			required: false,
		},
		fraudCheckReasons: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	data () {
		return {
			selectedReassessmentOutcome: null,
		};
	},
	computed: {
		confirmationMessage () {
			return this.hasClaimUnsavedChanges
				? 'You have unsaved changes. To save any changes click cancel and proceed to the next page. Clicking proceed will complete the Fraud review without saving these changes. Do you wish to proceed?'
				: 'Clicking proceed will complete the Fraud review. Are you sure you wish to continue?';
		},
	},
	methods: {
		async onApprove  () {
			const userChoise = await this.$refs.fraudApprovalDialog.show();
			if (userChoise === 'yes') {
				try {
					await WorkflowService.approveFraudCheckClaim(this.claimId, [ErrorCode.ClaimStatusHasBeenChanged]);
				} catch (e) {
					if (!isKnownError(e, ErrorCode.ClaimStatusHasBeenChanged)) {
						throw e;
					}
				}
				this.postAction();
			}
		},
		async rejectFraudCheckClaim (rejectionMessage) {
			if (this.isReassessment) {
				const userChoice = await this.$refs.rejectReassessmentOutcomeModal.show(outcomeState.Rejected);
				if (userChoice === 'no' || userChoice === 'cross') {
					return;
				}
			}

			try {
				await WorkflowService.rejectFraudCheckClaim(this.claimId, rejectionMessage, this.selectedReassessmentOutcome?.id, [ErrorCode.ClaimStatusHasBeenChanged]);
				this.postAction(true);
			} catch (e) {
				if (isKnownError(e, ErrorCode.ClaimStatusHasBeenChanged)) {
					this.postAction();
				} else if (!isKnownError(e, ErrorCode.ClaimCannotMovedToStatus)) {
					throw e;
				}
			} finally {
				this.$modal.hide('fraud-reject-dialog');
			}
		},
		postAction (leaveClaimPage = false) {
			this.$emit('finish', leaveClaimPage);
		},
	},
};
</script>

<style lang="scss" scoped>
.claim-fraud-review-banner {
  background: rgba($yellow, 0.6);
}

.btn {
  background: rgba(black, 0.2);
  height: 30px;
  width: 130px;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    background: rgba(black, 0.5);
  }
}

</style>
