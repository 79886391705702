<template>
	<div class="position-absolute w-100">
		<div class="section-content">
			<div class="my-10">
				<aq-select
					label="Type"
					:options="getFilterOptions('Type')"
					:value="selectedFilter.types"
					multiple
					@input="onUpdateFilter($event, 'types')"
					no-result-message="No Type found"
					data-qa="interactionFilter_select_type"
				/>
			</div>
			<div class=" my-10">
				<aq-select
					label="Status"
					:options="getFilterOptions('Status')"
					:value="selectedFilter.statuses"
					multiple
					track-by="key"
					option-label="value"
					@input="onUpdateFilter($event, 'statuses')"
					no-result-message="No Status found"
					data-qa="interactionFilter_select_status"
				/>
			</div>
			<div class="my-10">
				<aq-select
					label="User"
					:options="getFilterOptions('User')"
					:value="selectedFilter.users"
					option-label="description"
					multiple
					track-by="id"
					@input="onUpdateFilter($event, 'users')"
					no-result-message="No User found"
					data-qa="interactionFilter_select_user"
				/>
			</div>
			<div class="my-10">
				<aq-select
					label="Communications"
					:options="communicationsFilterOptions"
					:value="selectedFilter.communications"
					option-label="value"
					multiple
					track-by="key"
					@input="onUpdateFilter($event, 'communications')"
					no-result-message="No Communications found"
					data-qa="interactionFilter_select_communications"
				/>
			</div>
			<aq-date-filter
				ref="dateFilter"
				:value="selectedFilter.date"
				@input="onUpdateFilter($event, 'date')"
			/>
			<div class="row">
				<div class="col d-flex">
					<div class="pt-20 pb-2 ml-auto">
						<button
							class="btn btn-secondary mr-10"
							@click="onResetFilters"
							data-qa="interactionFilter_button_clear"
						>
							Clear
						</button>
						<button
							class="btn btn-primary"
							@click="onApplyFilters"
							:disabled="!isValid()"
							data-qa="interactionFilter_button_filter"
						>
							Filter
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
	name: 'InteractionFilter',
	props: {
		filterOptions: {
			type: Array,
			required: true,
		},
		filter: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			selectedFilter: this.filter,
			communicationsFilterOptions: [],
		};
	},
	mounted () {
		this.selectedFilter.types = this.getFilterOptions('Type');
		this.communicationsFilterOptions = this.getCommunicationsFilterOptions();
	},
	computed: {
		...mapState(['appSettings']),
	},
	methods: {
		isValid () {
			return this.$refs.dateFilter && this.$refs.dateFilter.isValid;
		},
		onResetFilters () {
			this.selectedFilter = {
				types: [],
				statuses: [],
				users: [],
				communications: [],
				date: { label: 'Dates' },
			};
		},
		getFilterOptions (filterLabel) {
			return this.filterOptions.filter((item) => item.filterLabel === filterLabel)[0].filterOptions;
		},
		getCommunicationsFilterOptions () {
			const options = this.getFilterOptions('Communications');
			if (this.appSettings.allowMedicalRecordSummary) {
				return options;
			}
			return options.filter(option => option.value !== 'Medical Record Summary');
		},
		onUpdateFilter (value, key) {
			this.selectedFilter[key] = value;
			this.$emit('filter-changed', cloneDeep(this.selectedFilter));
		},
		onApplyFilters () {
			if (this.isValid()) {
				this.$emit('apply-filters');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.interaction-filter {
  z-index: 10;
  top: 0;
}

.section-content {
  z-index: 10;
}
</style>
