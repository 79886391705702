<template>
	<aq-panel-wrapper>
		<div
			class="panel-container"
		>
			<div class="panel d-flex flex-column">
				<div>
					<div class="section-header">
						<div class="section-header__title">
							Vet Details
						</div>
					</div>
				</div>

				<aq-tab-selector
					:items="tabItems"
					:active-tab="activeTab"
					@select-tab="onSelectTab"
					v-if="activeTab"
					class="flex-grow-1 d-flex flex-column"
				>
					<template #details="{ item }">
						<div
							v-if="item.label === 'Alerts' && selectedVetAlerts.length"
							class="counter"
						>
							{{ selectedVetAlerts.length }}
						</div>
					</template>
					<div
						v-if="isEditing"
						class="d-flex flex-grow-1 flex-column"
					>
						<VetAlertForm
							:vet-id="selectedVet.id"
							@save="onSave"
							@cancel="isEditing = false"
						/>
					</div>
					<div
						v-else
						class="section-content d-flex flex-grow-1 flex-column"
					>
						<aq-level-switcher
							class="p-10"
							:show-customer="false"
						/>
						<div
							v-if="selectedVet"
							class="container-fluid scrollbar"
						>
							<div class="my-20 position-relative">
								<div
									class="d-flex flex-column"
									v-if="!isAlertsTab"
								>
									<VetSelectionList
										:vets="panelVets"
										:selected-vet="selectedVet"
										@update-vet="onUpdateSelectedVet"
									/>
									<div class="d-flex flex-wrap">
										<aq-text-area
											class="mb-15 mr-3 rounded"
											title="Vet ID:"
											:description="selectedVet.id"
											inline-description
										/>
										<aq-text-area
											class="mb-15 mr-3 rounded"
											title="Status:"
											:description="selectedVet.active ? 'Active' : 'Inactive'"
											inline-description
										/>
										<aq-text-area
											class="mb-15 rounded"
											title="Care Flag:"
											inline-description
										>
											<div class="text-truncate text-area-description">
												{{ !!careFlagLabels ? careFlagLabels : 'Not supplied' }}
											</div>
										</aq-text-area>
									</div>
									<div class="mb-20">
										<div>
											<button
												v-for="subTab of subTabItems"
												:key="subTab.value"
												@click="onSelectSubTab(subTab.value)"
												class="vet-tab rounded-top mr-10"
												:class="{ active: subTab.active }"
											>
												{{ subTab.label }}
											</button>
										</div>
										<div v-if="isContactDetailsSubTab">
											<div class="vet-info">
												<i
													class="fas fa-user mr-10"
													v-tooltip="'Contact Name'"
												/>
												<div class="break-word">
													{{ selectedVet.hospitalContactName || "Not supplied" }}
												</div>
											</div>
											<div class="vet-info">
												<i
													class="fas fa-phone mr-10"
													v-tooltip="'Phone Number'"
												/>
												<div class="break-word">
													{{ selectedVet.phoneNumber || "Not supplied" }}
												</div>
											</div>
											<div class="vet-info">
												<i
													class="fas fa-print mr-10"
													v-tooltip="'Fax Number'"
												/>
												<div class="break-word">
													{{ selectedVet.faxNumber || "Not supplied" }}
												</div>
											</div>
											<div class="vet-info">
												<i
													class="fas fa-envelope mr-10"
													v-tooltip="'Email'"
												/>
												<div class="break-word">
													{{ selectedVet.email || "Not supplied" }}
												</div>
											</div>
										</div>
										<div v-if="isVetDetailsSubTab">
											<div class="vet-info">
												<i
													class="fas fa-wallet mr-15"
													v-tooltip="'Payment Method'"
												/>
												<div class="break-word">
													{{ claimPaymentMethodsLabel(selectedVet.claimPaymentMethod) || "Not supplied" }}
												</div>
											</div>
											<div class="vet-info">
												<i
													class="fas fa-address-book mr-15"
													v-tooltip="'Preferred method of Contact'"
												/>
												<div class="break-word">
													{{ preferredContactMethodLabel(selectedVet.preferredContactMethod) || "Not supplied" }}
												</div>
											</div>
											<div class="vet-info">
												<LanguageIcon
													class="aq-fa-icon small mr-10"
													v-tooltip="'Language Preference'"
												/>
												<div class="break-word">
													{{ selectedVet.languagePref || "Not supplied" }}
												</div>
											</div>
											<div class="vet-info">
												<i
													class="aqv-vet-direct-pay mr-15"
													v-tooltip="'Vet Direct Pay'"
												/>
												<div class="break-word">
													{{ isVetDirectPay ? "Yes" : "No" }}
												</div>
											</div>
										</div>
									</div>
									<button
										v-if="appSettings.vetExternalServices"
										class="d-flex float-left btn-row btn show-additional-vet-button px-0"
										data-qa="vetPanel_button_expandInformation"
										@click="showAdditionalInfo = !showAdditionalInfo"
									>
										<InfoIcon
											v-if="!showAdditionalInfo"
											class="mr-10"
										/>
										<InfoIconClose
											v-else
											class="mr-10"
										/>
										<span
											class="align-self-center"
											v-if="!showAdditionalInfo"
										>Show additional vet information</span>
										<span
											class="align-self-center"
											v-else
										>Hide additional vet information</span>
									</button>
									<div v-if="showAdditionalInfo">
										<div>
											<aq-text-area
												class="d-inline-block mb-15 rounded"
												title="TruEx/Medical Records status:"
												:description="getMedicalRecordsStatus(selectedVetInfo.medicalRecordsStatus)"
												inline-description
											/>
										</div>
										<div class="d-flex mb-3">
											<div class="w-50">
												<aq-text-area
													class="flex-fill rounded-left mr-3 h-100"
													title="Territory Partner"
													:description="selectedVetInfo.territoryPartner"
												/>
											</div>
											<aq-text-area
												class="flex-fill w-50 rounded-right"
												title="Secondary Territory Partner"
												:description="selectedVetInfo.secondaryTerritoryPartner"
											/>
										</div>
										<div class="d-flex mb-3">
											<div class="w-50">
												<aq-text-area
													class="flex-fill mr-3 mb-3"
													title="Records Contact"
												>
													<template
														slot="bullet-points"
													>
														<ul>
															<li
																v-for="bulletPoint of getContactDetailsInfo()"
																:key="bulletPoint"
															>
																<p class="bullet-point">
																	{{ bulletPoint }}
																</p>
															</li>
														</ul>
													</template>
												</aq-text-area>
												<aq-text-area
													class="flex-fill mr-3"
													title="Preferred method of Contact"
													:description="getContactTypeDescription(selectedVetInfo.contactInfo.preferredMethod)"
												/>
											</div>
											<aq-text-area
												class="w-50"
												title="Additional Info"
												:description="selectedVetInfo.contactInfo.additionalInfoHours"
											/>
										</div>
										<aq-text-area
											class="rounded-bottom"
											title="Description"
											:description="selectedVetInfo.description"
										/>
									</div>
								</div>
								<div
									v-else
									class="alerts-section position-relative"
								>
									<div
										v-if="panelVets && panelVets.length > 1"
										class="mt-5 d-flex align-items-center"
									>
										<div class="bar flex-grow-1" />
										<button
											class="registered-vet-card-icon small ml-5 mr-5 border-0"
											:class="{ active: selectedVet.id === vet.id }"
											v-for="vet of panelVets"
											:key="vet.id"
											@click="onSelectVet(vet.id)"
											v-tooltip="vet.practiceName"
										>
											<i class="aqv-vet" />
										</button>
										<div class="bar flex-grow-1" />
									</div>
									<div class="p-10 d-flex flex-wrap search-terms">
										<aq-search-term
											v-for="(term, index) of searchTerms"
											:key="index"
											:value="term"
											@remove="onRemoveSearchTerm"
											class="px-10 mr-5 mb-5 py-6 bg-warning search-term__rounded align-items-center"
										/>
									</div>
									<div>
										<aq-search-box
											@search="onSearchTermAdded"
											@input="onInput"
											:is-filter-enabled="false"
											:focused="true"
										/>
									</div>
									<div class="d-flex">
										<button
											class="btn btn-primary w-100 mt-5"
											@click="isEditing = true"
											v-if="$can.AddVetAlert"
										>
											Add Alert
										</button>
									</div>
									<div class="mt-20 scrollbar vet-alert">
										<aq-card
											class="mb-10 vet-alert-card"
											v-for="(alert) of selectedVetAlerts"
											:key="`${alert.id}`"
											:name="alert.title"
											:user="getAlertUser(alert)"
										>
											<template slot="card-icon">
												<div class="card-icon mr-20 medium align-self-center position-relative">
													<i class="fa fa-comment text-danger" />
												</div>
											</template>
											<template slot="subName">
												<p class="fs-12 my-3">
													{{ subName(alert) }}
												</p>
											</template>
											<template slot="content">
												<div class="bottom mt-2 py-10 px-20 d-flex align-items-center">
													<aq-more-less-toggle
														v-if="alert.details.length < alertLengthThreshold"
														:text="alert.details"
													/>
													<Comment
														v-else
														:data="alert.details"
														:line-clamp="3"
														class="toggler-below"
														popover-class="note-details-popover"
													>
														<template #commentTrigger="props">
															<aq-more-less-toggler :opened="props.opened" />
														</template>
														<template #commentTitle>
															<div class="text-truncate">
																<i class="fa fa-comments fs-20" />
																{{ alert.title }}
															</div>
														</template>
													</Comment>
												</div>
											</template>
										</aq-card>
										<div
											v-if="selectedVetAlerts.length <= 0"
											class="text-center my-20"
										>
											<p>There are no alerts for this vet</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aq-tab-selector>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>
import AqTextArea from '@commonWidgets/AqTextArea';
import { mapState, mapActions } from 'vuex';
import { getCareFlagLabels } from '@clientCommon/services/models/CareFlagEnum';
import { LanguageIcon, InfoIcon, InfoIconClose } from '@assets/icons';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { claimPaymentMethods, claimPaymentMethodsDisplayMap } from '@commonServices/models/ClaimPaymentMethodEnum';
import { CommunicationPreferenceEnumMap } from '@commonServices/models/CommunicationPreferenceEnum';
import { shortDateTime } from '@commonServices/utils/filters';
import { VetInfoContactEnum, VetInfoMedicalRecordStatus } from '@commonServices/models/Vet';
import VetSelectionList from '@clientCommon/components/views/Shared/Vet/VetSelectionList.vue';
import { multipleSortComparer, uniqueBy } from '@commonServices/utils/general';
import SortingDirection from '@clientCommon/services/models/SortingDirection';
import VetService from '@commonServices/vetService';
import VetAlertForm from '@commonLayout/vets/VetAlertForm';

export default {
	components: {
		AqTextArea,
		LanguageIcon,
		InfoIcon,
		InfoIconClose,
		Comment,
		VetSelectionList,
		VetAlertForm,
	},
	data () {
		return {
			selectedVet: null,
			showAdditionalInfo: false,
			tabItems: [],
			subTabItems: [
				{
					value: 'contactDetails',
					label: 'Contact Details',
					active: true,
				},
				{
					value: 'vetDetails',
					label: 'Vet Details',
					active: false,
				},
			],
			activeTab: undefined,
			searchTerms: [],
			isDuplicationError: false,
			alertLengthThreshold: 2000,
			isEditing: false,
		};
	},
	props: {
		vetAlerts: {
			type: Array,
			required: false,
		},
		vetInfos: {
			type: Array,
			required: false,
		},
		initialTab: {
			type: String,
			validator (value) {
				return ['information', 'alerts'].includes(value);
			},
			default: 'information',
		},
	},
	mounted () {
		this.activeSubTab = this.subTabItems[0];
		this.tabItems = [
			{
				value: 'information',
				label: 'Information',
				active: true,
				visible: true,
			},
			{
				value: 'alerts',
				label: 'Alerts',
				active: false,
				visible: this.appSettings.vetExternalServices,
			},
		];
		this.tabItems = this.tabItems.filter(tabItem => tabItem.visible);
		this.onSelectTab(this.initialTab);
		if (this.levelFilter.customerId) { // to avoid cutomer level on vet panel opening
			this.changeInteractionContext({});
		}
	},
	watch: {
		panelVets: {
			handler: function () {
				this.selectedVet = this.panelVets[0] ?? null;
			},
			immediate: true,
		},
	},
	computed: {
		...mapState(['currentUser', 'interactionContext', 'appSettings', 'levelFilter']),
		isAlertsTab () {
			return this.activeTab.value === 'alerts';
		},
		isContactDetailsSubTab () {
			return (
				this.subTabItems.find((item) => item.active).value === 'contactDetails'
			);
		},
		isVetDetailsSubTab () {
			return (
				this.subTabItems.find((item) => item.active).value === 'vetDetails'
			);
		},
		panelVets () {
			let panelVets = [];
			if (this.levelFilter.claimId) {
				const registetedVetIds = this.interactionContext.pet.registeredVets?.filter(vet => !vet.isHistorical).map(vet => vet.id);
				panelVets = this.interactionContext.claim.registeredVets?.map(v => ({ ...v, isRegisteredVet: registetedVetIds.includes(v.id) }));
			} else {
				panelVets = this.interactionContext.pet.registeredVets?.map(v => ({ ...v, isRegisteredVet: !v.isHistorical }));
			}
			const sortComparer = multipleSortComparer(['isRegisteredVet', SortingDirection.Descending], ['assignmentDate', SortingDirection.Descending]);
			return uniqueBy(panelVets.sort(sortComparer), 'id');
		},
		careFlagLabels () {
			return getCareFlagLabels(this.selectedVet?.careFlag).join(', ');
		},
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		selectedVetAlerts () {
			let alerts = this.vetAlerts.filter(vetAlert => vetAlert.externalVetId === this.selectedVet?.id);
			if (this.searchTerms.length) {
				alerts = alerts.filter(vetAlert => vetAlert.title.toLowerCase().includes(this.searchTerms) || vetAlert.details.toLowerCase().includes(this.searchTerms));
			}
			return alerts;
		},
		selectedVetInfo () {
			return this.vetInfos.find(vetInfo => vetInfo.externalVetId === this.selectedVet?.id);
		},
		isVetDirectPay () {
			return VetService.isDirectPay(this.selectedVet);
		},
	},

	methods: {
		...mapActions(['changeInteractionContext']),
		onShowAdditionalVetInfo () {
			this.showAdditionalInfo = !this.showAdditionalInfo;
		},
		onSelectTab (value) {
			this.activeTab = this.tabItems.find((item) => item.value === value);
			this.isEditing = false;
			this.searchTerms = [];
		},
		onSelectSubTab (value) {
			this.subTabItems.forEach((tab) => {
				tab.active = tab.value === value;
			});
		},
		onSelectVet (vetId) {
			this.selectedVet = this.panelVets.find((vet) => vet.id === vetId);
		},
		claimPaymentMethodsLabel (value) {
			const uiLocale = getUiSettingsByLocale(this.locale);
			if (value === claimPaymentMethods.ACH) {
				return uiLocale.paymentLabel;
			}
			return claimPaymentMethodsDisplayMap[value];
		},
		preferredContactMethodLabel (value) {
			return CommunicationPreferenceEnumMap[value];
		},
		subName (alert) {
			return alert.updatedBy ? `Updated ${shortDateTime(alert.updatedDate)}` : `Created ${shortDateTime(alert.createdDate)}`;
		},
		getAlertUser (alert) {
			return alert.updatedBy || alert.createdBy;
		},
		onSearchTermAdded (value) {
			this.isDuplicationError = this.searchTerms.includes(value);
			if (this.isDuplicationError) {
				return;
			}
			this.searchTerms.push(value.toLowerCase());
		},
		onRemoveSearchTerm (term) {
			const removeItemIndex = this.searchTerms.indexOf(term.value);
			this.searchTerms.splice(removeItemIndex, 1);
		},
		onInput () {
			this.isDuplicationError = false;
		},
		getMedicalRecordsStatus (statusId) {
			return VetInfoMedicalRecordStatus[statusId];
		},
		getContactTypeDescription (contactId) {
			return VetInfoContactEnum[contactId];
		},
		getContactDetailsInfo () {
			return Object.entries(this.selectedVetInfo.contactInfo)
				.filter(([name, value]) => name !== 'additionalInfoHours' && value !== null && value.length)
				.map(([name, value]) => (name.charAt(0) + ': ' + value));
		},
		onUpdateSelectedVet (vet) {
			this.selectedVet = vet;
		},
		async onSave (alert) {
			await VetService.addVetAlert(this.selectedVet.id, alert);
			this.isEditing = false;
			this.searchTerms = [];
			this.$emit('alerts-changed');
		},
	},
};
</script>

<style lang="scss" scoped>
.aq-fa-icon {
  fill: var(--bodyColour);
}

.panel-container {
  max-width: 500px;
  animation: slide 0.5s forwards;
}

.content-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding-right: 15px;
}

hr {
  border: 1px solid var(--contentBg);
  opacity: 0.2;
}

.vet-tab {
  border: none;
  background: var(--tertiarySubSectionColour);
  display: inline-block;
  padding: 10px 50px 10px 10px;
  font-weight: bold;
  font-size: 17px;

  &.active {
    background: var(--secondarySubSectionColour);
  }
}

.vet-info {
  background: var(--secondarySubSectionColour);
  padding: 15px 20px;
  margin-bottom: 5px;
  display: inline-flex;
  width: 100%;

  i {
    font-size: 20px;
  }
}

.scrollbar {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;

  &.vet-alert {
    height: auto;
  }
}

.bar {
  background: rgba($primary, 0.2) !important;
  height: 2px;
}

.show-additional-vet-button {
  background: none !important;
  color: var(--bodyColor);
  text-decoration: underline;

  &:hover:not([disabled]),
  &:active:not([disabled]) {
    background: none !important;
  }
}

.alerts-section {
  margin-top: -20px;
}

.registered-vet-card-icon {
  height: 40px;
  width: 40px;
  align-items: center;
  border-radius: 50%;
  background: rgba($primary, 0.2) !important;
  fill: var(--cardIconColour);

  &.active {
    border: 1px solid $active-color !important;
  }
}

.bullet-point::first-letter {
  font-weight: bold;
}

.counter {
  background: $active-color;
  position: absolute;
  border-radius: 9.5px;
  height: 19px;
  width: fit-content;
  min-width: 19px;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  font-weight: bold;
  top: 14px;
  right: 74px;
  transition: 0.2s ease;
  box-shadow: 0 0 2px 0 $secondary;
}

.aq-text-area {
  padding: 12px !important;
  flex: 0 0 auto;

  & .text-area-description {
    max-width: 80px;
  }
}
</style>
