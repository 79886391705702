<template>
	<div>
		<div class="section-content">
			<div class="container-fluid">
				<item-heading
					:heading="customerHeading"
					heading-class="mb-1 user-name text-truncate"
					:paragraph="fullAddress"
					paragraph-class="text-truncate"
				>
					<div class="mx-18">
						<aq-icon-avatar
							class="medium cursor-pointer"
							:value="[customer.firstName, customer.lastName]"
							@click.native="viewCustomer()"
						>
							<div class="aqv-customer">
								<i
									v-if="requireSpecialLicensing(customer.address.regionCounty)"
									class="fas fa-exclamation-triangle text-warning"
									v-tooltip="{
										content: 'Licensed permissions state',
										classes: 'tooltip-orange'
									}"
								/>
							</div>
						</aq-icon-avatar>
					</div>
				</item-heading>
				<div class="attributes">
					<item-attribute
						v-for="(contact, index) in customerContacts"
						:key="index"
						:header="contact.header"
						:text="contact.text"
						class="text-truncate"
					/>
				</div>
			</div>
		</div>
		<div class="section-footer p-7">
			<div class="container-fluid d-flex align-items-center">
				<div class="d-flex">
					<aq-pet-avatar
						class="small mr-4 cursor-pointer"
						v-for="(pet, index) in customer.pets"
						:key="index"
						:pet="pet"
						@click.native="viewPet(pet.id)"
					/>
				</div>
				<aq-brand-image-avatar
					class="cursor-inherit"
					:brand-name="customer.brandName"
					:brand-id="customer.brandId"
				/>
				<button
					@click="viewCustomer"
					class="d-block btn btn-primary ml-auto"
				>
					View
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import customerService from '@commonServices/customerService';

export default {
	name: 'AqCustomerCard',
	props: {
		customer: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState(['appSettings']),
		...mapGetters(['requireSpecialLicensing']),
		fullAddress: function () {
			return [
				this.customer.address.address1,
				this.customer.address.address2,
				this.customer.address.address3,
				this.customer.address.townCity,
				this.customer.address.regionCounty,
				this.customer.address.postalCode,
				this.customer.address.country,
			]
				.filter(Boolean)
				.join(', ');
		},
		customerContacts: function () {
			return [
				{
					header: 'Email',
					text: this.customer.email || 'Not Supplied',
				},
				{
					header: 'Phone',
					text: this.customer.phoneNumber || 'Not Supplied',
				},
				{
					header: 'Cell',
					text: this.customer.mobileNumber || 'Not Supplied',
				},
			];
		},
		customerHeading: function () {
			return customerService.toCustomerFullName(this.customer);
		},
	},
	methods: {
		viewCustomer () {
			this.$router.push({ name: 'customer', params: { customerId: this.customer.id } });
		},
		viewPet (id) {
			this.$router.push({ name: 'pet', params: { customerId: this.customer.id, petId: id } });
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .user-name {
  font-size: 18px;
  letter-spacing: -0.06em;
  font-weight: bold;
}

.fa-exclamation-triangle {
  position: absolute;
  top: 22px;
  left: 70px;
  font-size: 18px;
}
</style>
