<template>
	<aq-modal :name="name">
		<aq-modal-container
			@close="close('cross')"
			:title="title"
			:paragraph="paragraph"
		>
			<div
				class="modal-content"
				@keyup.esc="close('no')"
			>
				<slot>
					<div class="p-20">
						<div>{{ description }}</div>
						<div
							class="pt-22 font-weight-bold"
							v-if="question"
						>
							{{ question }}
						</div>
					</div>
				</slot>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="close('no')"
						type="button"
						class="btn btn-secondary"
					>
						{{ noLabel }}
					</button>
					<slot
						name="yesButton"
					>
						<button
							@click="close('yes')"
							type="button"
							class="btn btn-success ml-auto"
						>
							{{ yesLabel }}
						</button>
					</slot>
				</div>
			</div>
		</aq-modal-container>
	</aq-modal>
</template>

<script>
export default {
	data () {
		return {
			promiseResolver: null,
		};
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		paragraph: {
			type: String,
			required: false,
		},
		description: {
			type: String,
			required: true,
		},
		question: {
			type: String,
			required: false,
		},
		yesLabel: {
			type: String,
			required: false,
			default: 'Yes',
		},
		noLabel: {
			type: String,
			required: false,
			default: 'No',
		},
	},
	methods: {
		show () {
			this.$modal.show(this.name);
			return new Promise((resolve, reject) => {
				this.$modal.promiseResolver = resolve;
			});
		},
		close (result) {
			this.$modal.hide(this.name);
			this.$modal.promiseResolver(result);
		},
	},
};
</script>
