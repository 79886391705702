<template>
	<div
		v-if="data"
		class="d-flex align-items-center comment w-100"
		ref="commentContainer"
	>
		<aq-more-less-toggle
			class="comment-aq-more-less-toggle w-100"
			:class="`line-clamp-${lineClamp}`"
			:text="formattedText"
		>
			<template #toggler>
				<AqDetailsPopover
					class="comment-details-popover"
					:data="data"
					:width="containerWidth"
					:popover-class="popoverClass"
					placement="bottom-end"
				>
					<template #trigger="triggerProps">
						<slot
							name="commentTrigger"
							:opened="triggerProps.popupOpened"
						/>
					</template>
					<template #title>
						<slot name="commentTitle">
							<div class="d-flex align-items-center">
								<i class="aqv-comments mr-10 fs-20" />
								<div>Comment</div>
							</div>
						</slot>
					</template>
				</AqDetailsPopover>
			</template>
		</aq-more-less-toggle>
	</div>
</template>

<script>

import AqDetailsPopover from '@commonView/Shared/AqDetailsPopover';

export default {
	name: 'AqComment',
	components: {
		AqDetailsPopover,
	},
	props: {
		data: {
			type: String,
			required: false,
			default: '',
		},
		lineClamp: {
			type: Number,
			required: false,
			default: 1,
		},
		popoverClass: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			containerWidth: 0,
		};
	},
	computed: {
		formattedText () {
			return this.data.replaceAll('\\n', '\n').replaceAll('\\r', '\r');
		},
	},
	mounted () {
		this.containerWidth = this.$refs.commentContainer?.offsetWidth || 0;
	},
};
</script>

<style lang="scss" scoped>
.comment {
  &-aq-more-less-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.line-clamp {
      &-1 {
        ::v-deep .more-less-toggle {
          -webkit-line-clamp: 1 !important;
        }
      }

      &-2 {
        ::v-deep .more-less-toggle {
          -webkit-line-clamp: 2 !important;
        }
      }

      &-3 {
        ::v-deep .more-less-toggle {
          -webkit-line-clamp: 3 !important;
        }
      }
    }

    .comment-details-popover {
      margin-left: 10px;
    }
  }

  &.toggler-below {
    .comment-aq-more-less-toggle {
      flex-direction: column;
      align-items: flex-start;

      .comment-details-popover {
        align-self: flex-end;
        margin-left: 0;
      }
    }
  }
}

</style>
