const NoteTypesEnum = Object.freeze({
	Standard: 1,
	Alert: 2,
	Appeal: 3,
});

const NoteTypesEnumMap = Object.freeze({
	[NoteTypesEnum.Standard]: 'Standard',
	[NoteTypesEnum.Alert]: 'Alert',
	[NoteTypesEnum.Appeal]: 'Appeal',
});

export { NoteTypesEnum, NoteTypesEnumMap };
