<template>
	<div
		v-if="claimFormType"
		class="sidebar"
		:class="{ 'pre-auth': isPreAuthorisation, 'other-loss': isOtherLoss }"
	>
		<div class="d-flex flex-column align-items-center gap-2 py-20">
			<div
				v-if="claimFiles.length"
				class="action-item"
				@click="openFiles"
			>
				<FileIconInvoice class="file-icon clear" />
				<span>Open Invoice</span>
			</div>
			<div
				class="action-item"
				@click="openClaimHistory"
			>
				<FileIconClaim class="file-icon clear" />
				<span>Claim History</span>
			</div>
			<div
				v-if="invoiceFiles.length"
				class="action-item"
				@click="openScanPanel"
			>
				<InvoiceScanPanel class="file-icon clear mb-5" />
				<span>Open Scan Page</span>
			</div>
		</div>
	</div>
</template>

<script>
import eventBus from '@commonServices/eventBus';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import { mapState } from 'vuex';
import { FileIconClaim, FileIconInvoice, InvoiceScanPanel } from '@assets/icons';
import FileService from '@commonServices/fileService';
import { FileCategoryEnum } from '@commonServices/models/FileCategoryEnum';
import { MimeTypes, getFileExtension, multipleSortComparer } from '@commonServices/utils/general';
import { openPetNewWindow } from '@commonServices/utils/routerUtils';
import SortingDirection from '@commonServices/models/SortingDirection';

export default {
	name: 'ClaimSidebar',
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			claimFiles: [],
		};
	},
	components: {
		FileIconClaim,
		FileIconInvoice,
		InvoiceScanPanel,
	},
	beforeMount () {
		eventBus.$on('notify-claim-changed', this.onNotifyClaimChanged);
	},
	beforeDestroy () {
		eventBus.$off('notify-claim-changed', this.onNotifyClaimChanged);
	},
	mounted () {
		this.loadClaimFiles();
	},
	computed: {
		...mapState(['claimFormType', 'isOpenDocPanel']),
		isPreAuthorisation () {
			return this.claimFormType.id === ClaimFormType.PreAuthorisation;
		},
		isOtherLoss () {
			return this.claimFormType.id === ClaimFormType.OtherLosses;
		},
		invoiceFiles () {
			return this.claimFiles.filter(f => [FileCategoryEnum.Invoice, FileCategoryEnum.HospitalInvoice].includes(f.category));
		},
	},
	watch: {
		isOpenDocPanel (isOpen) {
			if (!isOpen && !this.sharedData.isReadOnlyMode) {
				this.loadClaimFiles();
			}
		},
	},
	methods: {
		openClaimHistory () {
			openPetNewWindow(this.$router, this.sharedData.customerId, this.sharedData.petId);
		},
		openFiles () {
			this.claimFiles.forEach(f => {
				FileService.getTempSrc(f.id, f.docMimeType).then(file => window.open(file, '_blank'));
			});
		},
		loadClaimFiles () {
			FileService.getClaimFiles(this.sharedData.claimId)
				.then(data => {
					this.claimFiles = data.filter(f => [FileCategoryEnum.Invoice, FileCategoryEnum.HospitalInvoice, FileCategoryEnum.ClaimForm].includes(f.category))
						.map((f) => ({
							...f,
							docMimeType: MimeTypes[getFileExtension(f.fileName)],
						}));
				});
		},
		openScanPanel () {
			const invoiceToOpen = [...this.invoiceFiles].sort(multipleSortComparer(['category'], ['createDate', SortingDirection.Descending]))[0];
			eventBus.$emit('document-panel_open-scan-file', { fileId: invoiceToOpen.id });
		},
		onNotifyClaimChanged (payload) {
			if (payload.claimId === this.sharedData.claimId) {
				this.loadClaimFiles();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-shrink: 0;
  width: 80px;
  flex-direction: column;
  background: rgba($primary, 0.6);

  &.pre-auth {
    background: var(--bannerBgAlt);
    color: var(--sectionTextColour);
  }

  &.other-loss {
    background: var(--otherLossBg);
    color: var(--sectionTextColour);
  }
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $body-color;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}
</style>
