import httpClient, { usersHttpClient } from './httpClient';
import { toUser } from '@commonServices/utils/converter';
import { flattenObject, stringifyJSON, unflattenObject } from '@commonServices/utils/general';
import { USER_STATE_PROPS } from '@constants/userState';

function toUserState (response) {
	const userState = unflattenObject(response);
	// convert strings to appropriate types
	if (userState[USER_STATE_PROPS.USER_OPT_IN] != null) {
		userState[USER_STATE_PROPS.USER_OPT_IN] = userState[USER_STATE_PROPS.USER_OPT_IN] === 'true';
	}

	if (userState[USER_STATE_PROPS.SHORTKEY_ENABLED] != null) {
		userState[USER_STATE_PROPS.SHORTKEY_ENABLED] = userState[USER_STATE_PROPS.SHORTKEY_ENABLED] === 'true';
	}
	return userState;
}
export default {
	// #region requests

	getCurrentUser () {
		return usersHttpClient('/current')
			.get()
			.then(toUser);
	},
	getUsers (omitSpinner = false) {
		return usersHttpClient('/all', { omitSpinner })
			.get()
			.then(users => users.map(toUser));
	},
	notifyUserActive (optIn, latestActivity) {
		return httpClient('/api/claims/user-activity', { omitSpinner: true })
			.post({ optIn, latestActivity });
	},
	checkAbilityToOptOut () {
		return httpClient('/api/claims/user-activity', { omitSpinner: true })
			.get();
	},
	optOutUser (userId) {
		return httpClient(`/api/claims/user-activity/${userId}/opt-out`)
			.post();
	},
	getUserState () {
		return usersHttpClient('/state', { omitSpinner: true })
			.get()
			.then(toUserState);
	},
	saveUserState (userState) {
		const flattenObj = flattenObject(userState);

		// part of the VIS-29936. It will be removed later with next release.
		for (const oldProp of Object.keys(flattenObj).filter(key => key.startsWith(USER_STATE_PROPS.SELECTED_POLICY_TYPE_OLD))) {
			flattenObj[oldProp] = null;
		}

		const payload = JSON.parse(stringifyJSON(flattenObj));
		return usersHttpClient('/state', { omitSpinner: true })
			.post(payload)
			.then(toUserState);
	},

	// #endregion

	// #region logic

	getAssignmentUserOptions (users, currentUser, permissions = { assignToUser: false, assignToMe: false, unassign: false }) {
		if (!currentUser || !users?.length) return [];

		const currentUserOption = users.find(
			(user) => user.id === currentUser.id,
		);
		const userOptions = permissions.assignToUser
			? users.filter((user) => user.id !== currentUser.id && !user.system && user.enabled)
			: [];

		if (permissions.assignToMe) {
			userOptions.unshift({
				...currentUserOption,
				description: 'Assign to me',
			});
		}
		if (permissions.unassign) {
			userOptions.unshift({ id: null, description: 'Unassign' });
		}
		return userOptions;
	},

	// #endregion
};
