import { UiLocaleSettings } from '@commonServices/settings/localeSettings';

const FilterType = Object.freeze({
	PetType: 1,
	PetGender: 2,
	PolicyStatus: 3,
	ClaimStatus: 4,
	PaymentType: 5,
	Brand: 6,
	Product: 7,
	Breed: 8,
	Ailment: 9,
	RejectionReason: 10,
	Aggregate: 11,
	Column: 12,
	GroupBy: 13,
	PetDateOfBirthFrom: 14,
	PetDateOfBirthTo: 15,
	PolicyInceptionDateFrom: 16,
	PolicyInceptionDateTo: 17,
	DatePolicyStartFrom: 18,
	DatePolicyStartTo: 19,
	ClaimDateOfLossFrom: 20,
	ClaimDateOfLossTo: 21,
	ClaimTreatmentStartDateFrom: 22,
	ClaimTreatmentStartDateTo: 23,
	ClaimTreatmentEndDateFrom: 24,
	ClaimTreatmentEndDateTo: 25,
	ClaimDatePaidFrom: 26,
	ClaimDatePaidTo: 27,
	ClaimCreatedDateFrom: 28,
	ClaimCreatedDateTo: 29,
	LastInteractionDateFrom: 30,
	LastInteractionDateTo: 31,
	ClaimFormUploadedDateFrom: 32,
	ClaimFormUploadedDateTo: 33,
	ConditionType: 34,
	LetterType: 35,
	ZippedFileStatus: 36,
	LetterCreatedDateFrom: 37,
	LetterCreatedDateTo: 38,
	RejectionOverriddenBy: 39,
	County: 40,
	ClaimAmountFrom: 41,
	ClaimAmountTo: 42,
	ClaimAmountEqual: 43,
	MissingItem: 44,
	MissingInfoDateFrom: 45,
	MissingInfoDateTo: 46,
	ChaseCountFrom: 47,
	ChaseCountTo: 48,
	ChaseCountEqual: 49,
	PolicySection: 50,
	FraudCheckRequestedDateFrom: 51,
	FraudCheckRequestedDateTo: 52,
	FraudFlag: 53,
	ClaimType: 54,
	IsDead: 55,
	PaymentAmountFrom: 56,
	PaymentAmountTo: 57,
	PaymentAmountEqual: 58,
	ReassessmentRequestedDateFrom: 59,
	ReassessmentRequestedDateTo: 60,
	ExGratiaPaymentFrom: 61,
	ExGratiaPaymentTo: 62,
	ExGratiaPaymentEqual: 63,
	ClaimAssignedToId: 64,
	UserDeductionType: 65,
	Country: 66,
	DaysCountToTreatmentStartFrom: 67,
	DaysCountToTreatmentStartTo: 68,
	DaysCountToTreatmentStartEqual: 69,
	ClaimFormType: 70,
	ReassessmentReason: 71,
	ClaimCreatedBy: 72,
	ClaimClosedBy: 73,
	ClaimClosedDateFrom: 74,
	ClaimClosedDateTo: 75,
	ReassessmentOutcome: 76,
	CareFlag: 77,
	RequestedBy: 78,
	IsFastTrackReviewed: 79,
	StatsTeams: 80,
	StatsUsers: 81,
	StatsUserActive: 82,
	Vet: 83,
	SettledReason: 84,
	RejectionStatus: 85,
	Claim: 86,
	DateTimeFrom: 87,
	DateTimeTo: 88,
	IsEscalated: 89,
	MissingInfoCompleted: 90,
	IsReassessment: 91,
	EscalationType: 92,
	LanguagePreference: 93,
	DaysOpenFrom: 94,
	DaysOpenTo: 95,
	DaysOpenEqual: 96,
	PaymentRecipient: 97,
	LetterRecipient: 98,
	CreatedBy: 99,
	CreatedDateFrom: 100,
	CreatedDateTo: 101,
	BodyPart: 102,
	ConditionArchived: 103,
	ConditionEligible: 104,
});

const Filter = Object.freeze({
	PaymentType: 'Payment Type',
	ClosedPaidDate: 'Closed-Paid Date',
	PaymentAmount: 'Payment Amount',
	Brand: 'Brand',
	Species: 'Species',
	Breed: 'Breed',
	Ailment: 'Ailment',
	ConditionType: 'Condition Type',
	ClaimCreatedDate: 'Claim Created Date',
	ClaimFormUploadedDate: 'Claim Form Uploaded Date',
	DateOnSet: 'Date On Set',
	TreatmentStart: 'Treatment Start',
	TreatmentEnd: 'Treatment End',
	LastInteractionDate: 'Last Interaction Date',
	OverriddenBy: 'Overridden By',
	ClaimStatus: 'Claim Status',
	FormType: 'Form Type',
	ClaimClosedDate: 'Claim Closed Date',
	ClaimClosedBy: 'Claim Closed By',
	ClaimAssignedTo: 'Claim Assigned To',
	StatsDate: 'Date',
	StatsTeams: 'Teams',
	StatsUsers: 'Users',
	StatsActiveUsers: 'Active Users',
	Product: 'Product',
	Genders: 'Genders',
	RejectionReasons: 'Rejection Reasons',
	PolicyStatus: 'Policy Status',
	ExGratiaAmount: 'Ex-Gratia Amount',
	DatePaid: 'Date Paid',
	PetDateOfBirth: 'Pet Date of Birth',
	InceptionDate: 'Inception Date',
	DatePolicyStart: 'Date Policy Start',
	UserDeductions: 'User Deductions',
	ReassessmentReason: 'Reassessment Reasons',
	ReassessmentOutcome: 'Reassessment Outcome',
	County: UiLocaleSettings.regionCountyLabel,
	Country: 'Country',
	CareFlag: 'Care Flag',
	Vet: 'Vet',
	ClaimType: 'Claim Type',
	DeceasedPet: 'Deceased Pet',
	PolicySections: 'Policy Sections',
	ClaimAmount: 'Claim Amount',
	ClaimCreatedBy: 'Created By',
	FastTrackReviewed: 'Fast Track Reviewed',
	DayCountToTreatmentStart: 'Day count to Treatment Start',
	MissingInfoRequestedDate: 'Missing Info Request Date',
	MissingItem: 'Missing Item',
	ChaseCount: 'Chase Count',
	RequestedBy: 'Requested By',
	FraudCheckRequestedDate: 'Fraud Check Requested Date',
	FraudFlags: 'Fraud Flags',
	SettledReason: 'Settled Reason',
	ReassessmentRequestedDate: 'Reassessment Request Date',
	Rejection: 'Rejection',
	Claim: 'Claim',
	Escalated: 'Escalated',
	MissingInfoCompleted: 'Missing info completed',
	IsReassessment: 'Is Reassessment',
	EscalationType: 'Escalation Type',
	LanguagePreference: 'Language Preference',
	DaysOpen: 'Days Open',
	PaymentRecipient: 'Payment Recipient',
	LetterRecipient: 'Recipient Type',
	CreatedDate: 'Created Date',
	ConditionCreatedBy: 'Condition Created By',
	BodyPart: 'Body Part',
	ConditionArchived: 'Archived',
	ConditionEligible: 'Eligible',
});
export { FilterType, Filter };
