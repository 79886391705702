import debounce from 'lodash.debounce';
import persistentStorage from '@commonServices/utils/persistentStorage';

const StorageKeyIdleActiveTime = 'idle.activeTime';

class Idle {
	constructor ({ timeout = 10, idleCallback = null, backToActiveCallback = null, backToActiveOnXHR = false } = {}) {
		this.timeout = timeout * 60000;
		this.idleCallback = idleCallback;
		this.backToActiveCallback = backToActiveCallback;
		this.backToActiveOnXHR = backToActiveOnXHR;

		this.idle = false;
		this.timer = null;
		this.events = ['scroll', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
		this.init();
	}

	init () {
		if (this.backToActiveOnXHR) {
			this.events.push('load');
		}
		this.events.forEach(name => {
			window.addEventListener(name, this.backToActive, true);
		});
		this.backToActive();
	}

	goIdle = () => {
		if (this._getDelay() < this.timeout) {
			this.backToActive(false);
			return;
		}

		if (this.idleCallback) {
			this.idleCallback();
		}
	};

	backToActive = debounce((resetTime = true) => {
		if (this.idle && this.backToActiveCallback) {
			this.backToActiveCallback();
		}
		let delay = 0;
		delay = this._getDelay();
		if (resetTime) {
			persistentStorage.set(StorageKeyIdleActiveTime, Date.now());
		}

		this.idle = false;
		clearTimeout(this.timer);
		this.timer = setTimeout(this.goIdle, this.timeout - delay);
	}, 400);

	tearDown () {
		this.events.forEach(name => {
			window.removeEventListener(name, this.backToActive);
		});
	}

	_getDelay () {
		return Date.now() - getLatestActivityTime();
	}
}

export default Idle;

export function getLatestActivityTime () {
	return persistentStorage.get(StorageKeyIdleActiveTime, Date.now(), Number);
}
