<template>
	<aq-modal-container
		@close="onCancel"
		title="Assign User"
	>
		<div class="modal-content">
			<div class="p-20">
				<div class="container-fluid">
					<div class="row no-gutters">
						<div class="col-12 pb-12">
							Please select user
						</div>
						<div class="col-12 pb-12">
							<aq-select
								:options="userOptions"
								placeholder="Select User"
								v-model="selectedUser"
								track-by="id"
								option-label="description"
								no-result-message="No Users found"
								class="w-100"
								:is-sorted="false"
								:allow-empty="false"
							/>
						</div>
						<div
							v-if="unassignFromPrioritizedQueue"
							class="col-9 pb-12"
						>
							<p class="text-warning">
								By un-assigning, previous assignment information will be lost. Please re-assign to another user
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-bottom d-flex align-items-center px-20 py-10">
				<button
					@click="onCancel"
					type="button"
					class="btn btn-secondary"
				>
					Cancel
				</button>
				<button
					@click="onSave"
					type="button"
					class="btn btn-success ml-auto"
				>
					Save
				</button>
			</div>
		</div>
	</aq-modal-container>
</template>

<script>

export default {
	name: 'TaskAssignmentModal',
	props: {
		userOptions: {
			type: Array,
			required: true,
		},
		user: {
			type: Object,
			required: false,
			default: null,
		},
		prioritizedQueue: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			selectedUser: this.userOptions.find(u => this.user && u.id === this.user.id),
		};
	},
	computed: {
		unassignFromPrioritizedQueue () {
			return this.selectedUser?.id === null && this.prioritizedQueue;
		},
	},
	methods: {
		onSave () {
			this.$emit('assign-user', this.selectedUser == null || this.selectedUser.id == null ? null : { ...this.selectedUser });
			this.onCancel();
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>
