import httpClient from './httpClient';
import { toPreExistingConditions, toEligibleConditions, toPreExistingConditionView, toEligibleConditionView } from '@commonServices/utils/converter';
import LocalDate from '@commonServices/models/LocalDate';
import fileService from './fileService';
import { FileCategoryEnum } from './models/FileCategoryEnum';
import SortingDirection from './models/SortingDirection';
import { FileTypes, getFileExtension, getFileType, MimeTypes, multipleSortComparer } from './utils/general';
import ProductType from './models/ProductType';

export default {
	// #region requests

	getConditionCount (petId) {
		return httpClient(`/api/claims/pets/${petId}/pre-existing-conditions/count`, { omitSpinner: true })
			.get();
	},
	getPreExistingConditions (petId) {
		return httpClient(`/api/claims/pets/${petId}/pre-existing-conditions`)
			.get()
			.then(toPreExistingConditions);
	},
	getEligibleConditions (petId) {
		return httpClient(`/api/claims/pets/${petId}/eligible-conditions`)
			.get()
			.then(toEligibleConditions);
	},
	createPreExistingCondition (petId, data) {
		data.firstSignsDate = new LocalDate(data.firstSignsDate);
		return httpClient(`/api/claims/pets/${petId}/pre-existing-conditions`)
			.post(data);
	},
	updatePreExistingCondition (id, model) {
		model.firstSignsDate = new LocalDate(model.firstSignsDate);
		return httpClient(`/api/claims/pets/pre-existing-conditions/${id}`)
			.put(model);
	},
	updatePetDateOfDeath (id, dateOfDeath) {
		dateOfDeath = new LocalDate(dateOfDeath);
		return httpClient(`/api/claims/pets/${id}/date-of-death`)
			.put({ petDateOfDeath: dateOfDeath });
	},
	updateCareFlags (id, careFlags) {
		return httpClient(`/api/claims/pets/${id}/care-flags`)
			.put({ careFlags });
	},
	createEligibleCondition (petId, data) {
		data.assessmentDate = new LocalDate(data.assessmentDate);
		return httpClient(`/api/claims/pets/${petId}/eligible-conditions`)
			.post(data);
	},
	updateEligibleCondition (id, model) {
		model.assessmentDate = new LocalDate(model.assessmentDate);
		return httpClient(`/api/claims/pets/eligible-conditions/${id}`)
			.put(model);
	},
	archivePreExistingCondition (id) {
		return httpClient(`/api/claims/pets/pre-existing-conditions/${id}/archive`, { omitSpinner: true })
			.put()
			.then(data => toPreExistingConditionView(data.value));
	},
	unarchivePreExistingCondition (id) {
		return httpClient(`/api/claims/pets/pre-existing-conditions/${id}/un-archive`, { omitSpinner: true })
			.put()
			.then(data => toPreExistingConditionView(data.value));
	},
	archiveEligibleCondition (id) {
		return httpClient(`/api/claims/pets/eligible-conditions/${id}/archive`, { omitSpinner: true })
			.put()
			.then(data => toEligibleConditionView(data.value));
	},
	unarchiveEligibleCondition (id) {
		return httpClient(`/api/claims/pets/eligible-conditions/${id}/un-archive`, { omitSpinner: true })
			.put()
			.then(data => toEligibleConditionView(data.value));
	},
	validateForPreExistingCondition (claimId, ailmentId, bodyPartId) {
		return httpClient(`/api/claims/pets/pre-existing-conditions/validation?claimId=${claimId}&ailmentId=${ailmentId}&bodyPartId=${bodyPartId}`)
			.get();
	},
	validateForEligibleCondition (claimId, ailmentId, bodyPartId) {
		return httpClient(`/api/claims/pets/eligible-conditions/validation?claimId=${claimId}&ailmentId=${ailmentId}&bodyPartId=${bodyPartId}`)
			.get();
	},
	getPetAilments (petId) {
		return httpClient(`/api/claims/pets/${petId}/ailments`)
			.get();
	},
	// #endregion

	// #region logic

	conditionsComparer (a, b) {
		return new Date(b.updatedDate) - new Date(a.updatedDate);
	},
	getCurrentPetPolicyTerm (policyTerms) {
		const now = new Date();
		return policyTerms.find(term => term.policyTermEndDate >= now && term.policyTermStartDate <= now && term.product.type === ProductType.Insurance)
		|| policyTerms.find(term => term.policyTermEndDate >= now && term.policyTermStartDate <= now)
		|| policyTerms.findLast(term => term.product.type === ProductType.Insurance)
		|| policyTerms[policyTerms.length - 1];
	},
	async getLatestMedicalRecordSummuryUrl (petId) {
		const petFiles = await fileService.getPetFiles(petId);
		const medicalRecordSummaryFile = petFiles
			?.filter(file => file.category === FileCategoryEnum.MedicalRecordSummary)
			.sort(multipleSortComparer(['externalDocUrl', SortingDirection.Ascending], ['createDate', SortingDirection.Descending]))[0];

		let medicalRecordSummaryUrl = null;
		let toastMessage = null;
		if (medicalRecordSummaryFile) {
			if (medicalRecordSummaryFile.externalDocUrl) {
				medicalRecordSummaryUrl = medicalRecordSummaryFile.externalDocUrl;
			} else if ([FileTypes.pdf, FileTypes.image].includes(getFileType(medicalRecordSummaryFile.fileName))) {
				const mimeType = MimeTypes[getFileExtension(medicalRecordSummaryFile.fileName)];
				medicalRecordSummaryUrl = await fileService.getTempSrc(medicalRecordSummaryFile.id, mimeType);
			} else {
				toastMessage = 'Preview not available';
			}
		} else {
			toastMessage = 'No available Medical Record Summary document';
		}

		return { medicalRecordSummaryUrl, toastMessage };
	},

	// #endregion
};
