<template>
	<div class="text-center footer text-white">
		<span>© Copyright Aquarium Software Limited</span>
	</div>
</template>

<script>
export default {
	name: 'AqFooter',
	data () {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.footer {
  height: 30px;
  line-height: 30px;
  font-size: 10px;
  color: $gray-transp-70;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  span {
    opacity: 0.5;
  }
}
</style>
