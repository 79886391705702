<template>
	<div class="calc-input">
		<div
			v-if="calcMode"
			class="calc_mode position-relative"
		>
			<aq-form-input
				:label="label"
				:value="valueExpression"
				:is-valid="valid"
				:disabled="disabled"
				@input="onExpressionChanged"
				data-qa="calc_input_amount-expression"
			/>
			<div
				class="calc-input_button"
				@click="onCalculate"
				v-tooltip="'Calculate'"
			>
				<i class="fa-solid fa-calculator" />
			</div>
		</div>
		<div
			v-else
			class="amount_mode position-relative"
			:class="{ 'has-expression': this.valueExpression != null, 'disabled': disabled }"
		>
			<aq-form-input
				:label="label"
				:icon-class="iconClass"
				type="money"
				:value="value"
				@input="onValueUpdated($event, null)"
				:is-valid="valid"
				:disabled="disabled || this.valueExpression != null"
				:locale="locale"
				data-qa="calc_input_amount"
			/>
			<div
				class="badge-input_button"
				v-tooltip="{
					content: amoutModeTooltip,
					placement:'top'
				}"
			>
				<div
					class="badge"
					@click="enableCalcMode"
				>
					<i class="fa-solid fa-calculator" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { evaluate } from 'mathjs';
import { currency } from '@commonServices/utils/filters';

export default {
	name: 'AqCalcInput',
	props: {
		label: {
			type: String,
		},
		value: {
			type: [String, Number],
		},
		valueExpression: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		valid: {
			type: Boolean,
			default: true,
		},
		iconClass: {
			type: String,
		},
	},
	data () {
		return {
			calcMode: false,
		};
	},
	computed: {
		amoutModeTooltip () {
			return this.valueExpression ?? 'Calculator';
		},
	},
	methods: {
		onExpressionChanged (expressionValue) {
			const expression = expressionValue == null || expressionValue === '' ? null : expressionValue;
			this.onValueUpdated(this.value, expression);
		},
		enableCalcMode () {
			this.calcMode = true;
			this.onValueUpdated(0, this.valueExpression);
		},
		onCalculate () {
			if (!this.valid) return;

			if (this.valueExpression == null) {
				this.onValueUpdated(this.value, this.valueExpression);
			} else {
				const amount = parseFloat(currency(evaluate(this.valueExpression), this.locale, true));
				this.onValueUpdated(amount, this.valueExpression);
			}
			this.calcMode = false;
		},
		onValueUpdated (value, expression) {
			this.$emit('input', { value, valueExpression: expression });
		},
		reset () {
			this.calcMode = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.calc-input {
  .calc_mode {
    .calc-input_button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      color: var(--calcInputTextThree);
      background-color: var(--calcInputBgOne);
      position: absolute;
      right: 0;
      top:0;
      bottom: 0;
      font-size: 24px;

      &:hover {
        cursor: pointer;
        background-color: var(--calcInputBgTwo);
        color: #fff
      }
    }
  }

  .amount_mode {
    overflow: hidden;

    .badge {
      background-color: var(--calcInputBgThree);
      width: 60px;
      height: 60px;
      position: absolute;
      right: -30px;
      top: -30px;
      transform: rotate(45deg);

      i {
        font-size: 18px;
        color: var(--calcInputTextOne);
        transform: rotate(-45deg);
        position: absolute;
        top: 38px;
        left: 25px;
      }

      &:hover {
        background-color: var(--calcInputBgFive);
        cursor: pointer;

        i {
          color: #FFF;
        }
      }
    }

    .badge-input_button {
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.has-expression {
      .badge {
        &:hover {
          background-color: var(--calcInputBgFour);
        }

        i {
          color: var(--calcInputTextTwo);
        }
      }

      // restyle disabled (readonly) input
      ::v-deep .control-container.disabled {
        .locked {
          display: none;
        }

        .input-border.disabled-border {
          border-bottom: none;
          padding-right: 0 !important;
        }

        &::before {
          content: none;
        }
      }
    }

    &.disabled {
      overflow: visible;

      .badge-input_button {
        display: none;
      }
    }
  }

}
</style>
