<template>
	<div
		:class="{ 'active' : opened }"
		class="header-toggler d-flex align-items-center justify-content-between"
	>
		<div class="d-flex py-13">
			<div class="header-toggler_icon">
				<slot name="header-icon" />
			</div>
			<div>
				<div class="section-header__title">
					{{ title }}
				</div>
				<p class="header-subtitle">
					{{ subTitle }}
				</p>
			</div>
		</div>
		<slot
			name="toggler"
			:toggle-state="toggleState"
		>
			<i
				v-if="!disabled"
				@click="toggleState(!opened)"
				:class="[opened ? 'fa-chevron-down' : 'fa-chevron-right']"
				class="fs-22 fas cursor-pointer skip-click-outside"
				data-qa="header-toggler_button"
			/>
		</slot>
		<div
			class="header-toggler_content"
			v-click-outside="hideContent"
		>
			<template v-if="opened">
				<slot :close-content="hideContent" />
			</template>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AqHeaderToggler',
	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subTitle: {
			type: String,
			required: false,
			default: '',
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data () {
		return {
			opened: false,
		};
	},
	methods: {
		hideContent () {
			this.toggleState(false);
		},
		toggleState (state) {
			this.opened = state;
			this.$emit('on-state-changed', this.opened);
		},
	},
};
</script>

<style lang="scss" scoped>
.header-toggler {
  position: relative;
  padding: 0 2.5rem;
  color: var(--sectionTextColour);

  p {
    color: var(--subSectionColour);
  }

  &.active {
    background: var(--cardIconColour);

    p {
      color: var(--sectionTextColour);
    }
  }

  &_content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 20;
    background: var(--contentBg);
    color: var(--bodyColour);
    box-shadow: 15px 15px 25px 1px rgb(0 0 0 / 70%);

    .checkbox {
      font-size: 12px;
    }
  }

  &_icon {
    fill: var(--subSectionColour);
  }
}
</style>
