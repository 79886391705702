const interactionType = Object.freeze({
	ChangedClaimStatus: 'ChangedClaimStatus',
	OnlineClaimSubmission: 'OnlineClaimSubmission',
	ClaimFormDocument: 'ClaimFormDocument',
	InvoiceDocument: 'InvoiceDocument',
	MedicalHistoryDocument: 'MedicalHistoryDocument',
	MedicalRecordSummaryDocument: 'MedicalRecordSummaryDocument',
	OtherDocument: 'OtherDocument',
	DisputeDocument: 'DisputeDocument',
	DeclarationPageDocument: 'DeclarationPageDocument',
	ProofOfSecondaryInsuranceDocument: 'ProofOfSecondaryInsuranceDocument',
	GeneratedWaitPeriodLetter: 'GeneratedWaitPeriodLetter',
	GeneratedMissingInfoLetter: 'GeneratedMissingInfoLetter',
	GeneratedNoLimitLetter: 'GeneratedNoLimitLetter',
	GeneratedSettlementLetter: 'GeneratedSettlementLetter',
	GeneratedWaitPeriodEmail: 'GeneratedWaitPeriodEmail',
	GeneratedAcknowledgementEmail: 'GeneratedAcknowledgementEmail',
	GeneratedMissingInfoEmail: 'GeneratedMissingInfoEmail',
	GeneratedNoLimitEmail: 'GeneratedNoLimitEmail',
	GeneratedSettlementEmail: 'GeneratedSettlementEmail',
	GeneratedAcknowledgementSms: 'GeneratedAcknowledgementSms',
	ClaimReassessment: 'ClaimReassessment',
	ClaimReassessmentCancelled: 'ClaimReassessmentCancelled',
	GeneratedReassessmentLetter: 'GeneratedReassessmentLetter',
	GeneratedReassessmentEmail: 'GeneratedReassessmentEmail',
	GeneratedRejectedManuallyEmail: 'GeneratedRejectedManuallyEmail',
	GeneratedRejectedManuallyLetter: 'GeneratedRejectedManuallyLetter',
	GeneratedChaseLetter: 'GeneratedChaseLetter',
	GeneratedChaseEmail: 'GeneratedChaseEmail',
	GeneratedChaseSms: 'GeneratedChaseSms',
	GeneratedRejectedPreExistingConditionEmail: 'GeneratedRejectedPreExistingConditionEmail',
	GeneratedRejectedPreExistingConditionLetter: 'GeneratedRejectedPreExistingConditionLetter',
	GeneratedRejectedNoActiveCoverPeriodEmail: 'GeneratedRejectedNoActiveCoverPeriodEmail',
	GeneratedRejectedNoActiveCoverPeriodLetter: 'GeneratedRejectedNoActiveCoverPeriodLetter',
	RequestedWaitPeriodLetter: 'RequestedWaitPeriodLetter',
	RequestedMissingInfoLetter: 'RequestedMissingInfoLetter',
	RequestedNoLimitLetter: 'RequestedNoLimitLetter',
	RequestedSettlementLetter: 'RequestedSettlementLetter',
	RequestedWaitPeriodEmail: 'RequestedWaitPeriodEmail',
	RequestedAcknowledgementEmail: 'RequestedAcknowledgementEmail',
	RequestedMissingInfoEmail: 'RequestedMissingInfoEmail',
	RequestedNoLimitEmail: 'RequestedNoLimitEmail',
	RequestedSettlementEmail: 'RequestedSettlementEmail',
	RequestedAcknowledgementSms: 'RequestedAcknowledgementSms',
	RequestedReassessmentLetter: 'RequestedReassessmentLetter',
	RequestedReassessmentEmail: 'RequestedReassessmentEmail',
	RequestedRejectedManuallyEmail: 'RequestedRejectedManuallyEmail',
	RequestedRejectedManuallyLetter: 'RequestedRejectedManuallyLetter',
	RequestedChaseLetter: 'RequestedChaseLetter',
	RequestedChaseEmail: 'RequestedChaseEmail',
	RequestedChaseSms: 'RequestedChaseSms',
	RequestedRejectedPreExistingConditionLetter: 'RequestedRejectedPreExistingConditionLetter',
	RequestedRejectedPreExistingConditionEmail: 'RequestedRejectedPreExistingConditionEmail',
	RequestedRejectedNoActiveCoverPeriodEmail: 'RequestedRejectedNoActiveCoverPeriodEmail',
	RequestedRejectedNoActiveCoverPeriodLetter: 'RequestedRejectedNoActiveCoverPeriodLetter',
	OverrideRejection: 'OverrideRejection',
	RequestedSettlementSms: 'RequestedSettlementSms',
	GeneratedSettlementSms: 'GeneratedSettlementSms',
	TaskAssignment: 'TaskAssignment',
	TaskUnAssignment: 'TaskUnAssignment',
	ExGratiaPayment: 'ExGratiaPayment',
	HospitalClaimSubmission: 'HospitalClaimSubmission',
	RequestedPreAuthorisationCompletedEmail: 'RequestedPreAuthorisationCompletedEmail',
	GeneratedPreAuthorisationCompletedEmail: 'GeneratedPreAuthorisationCompletedEmail',
	RequestedPreAuthorisationCompletedLetter: 'RequestedPreAuthorisationCompletedLetter',
	GeneratedPreAuthorisationCompletedLetter: 'GeneratedPreAuthorisationCompletedLetter',
	InboundEmailDocument: 'InboundEmailDocument',
	ClaimBotRequest: 'ClaimBotRequest',
	ClaimBotResponse: 'ClaimBotResponse',
	ClaimBotResponseApproval: 'ClaimBotResponseApproval',
	ClaimBotResponseDenial: 'ClaimBotResponseDenial',
	ClaimBotResponseFailed: 'ClaimBotResponseFailed',
	RequestedReassessmentAcknowledgementEmail: 'RequestedReassessmentAcknowledgementEmail',
	GeneratedReassessmentAcknowledgementEmail: 'GeneratedReassessmentAcknowledgementEmail',
	RequestedReassessmentAcknowledgementSms: 'RequestedReassessmentAcknowledgementSms',
	GeneratedReassessmentAcknowledgementSms: 'GeneratedReassessmentAcknowledgementSms',
	RequestedMedicalRecordsSummaryLetter: 'RequestedMedicalRecordsSummaryLetter',
	RequestedMedicalRecordsSummaryEmail: 'RequestedMedicalRecordsSummaryEmail',
	GeneratedMedicalRecordsSummaryLetter: 'GeneratedMedicalRecordsSummaryLetter',
	GeneratedMedicalRecordsSummaryEmail: 'GeneratedMedicalRecordsSummaryEmail',
	ClaimPayeeUpdated: 'ClaimPayeeUpdated',
	HospitalNotification: 'HospitalNotification',
	RequestedGreyMedicalRecordsSummaryLetter: 'RequestedGreyMedicalRecordsSummaryLetter',
	RequestedGreyMedicalRecordsSummaryEmail: 'RequestedGreyMedicalRecordsSummaryEmail',
	GeneratedGreyMedicalRecordsSummaryLetter: 'GeneratedGreyMedicalRecordsSummaryLetter',
	GeneratedGreyMedicalRecordsSummaryEmail: 'GeneratedGreyMedicalRecordsSummaryEmail',
	RequestedRejectedNoProductCoverageEmail: 'RequestedRejectedNoProductCoverageEmail',
	GeneratedRejectedNoProductCoverageEmail: 'GeneratedRejectedNoProductCoverageEmail',
	RequestedRejectedNoProductCoverageLetter: 'RequestedRejectedNoProductCoverageLetter',
	GeneratedRejectedNoProductCoverageLetter: 'GeneratedRejectedNoProductCoverageLetter',
	RequestedRejectedMultipleEmail: 'RequestedRejectedMultipleEmail',
	GeneratedRejectedMultipleEmail: 'GeneratedRejectedMultipleEmail',
	RequestedRejectedMultipleLetter: 'RequestedRejectedMultipleLetter',
	GeneratedRejectedMultipleLetter: 'GeneratedRejectedMultipleLetter',
	PaymentEdit: 'PaymentEdit',
	StopAndReissuePayment: 'StopAndReissuePayment',
	FastTrackReviewedClaim: 'FastTrackReviewedClaim',
	PreExCondition: 'PreExCondition',
	EscalatedClaim: 'EscalatedClaim',
	DeEscalatedClaim: 'DeEscalatedClaim',
	ExtTranscriptionSuccessful: 'ExtTranscriptionSuccessful',
	ExtTranscriptionFailed: 'ExtTranscriptionFailed',
	RequestedOpenClaimReminderEmail: 'RequestedOpenClaimReminderEmail',
	GeneratedOpenClaimReminderEmail: 'GeneratedOpenClaimReminderEmail',
	RequestedOpenClaimReminderLetter: 'RequestedOpenClaimReminderLetter',
	GeneratedOpenClaimReminderLetter: 'GeneratedOpenClaimReminderLetter',
	RequestedOpenClaimReminderSms: 'RequestedOpenClaimReminderSms',
	GeneratedOpenClaimReminderSms: 'GeneratedOpenClaimReminderSms',
	RequestedDuplicateClaimRejectionEmail: 'RequestedDuplicateClaimRejectionEmail',
	GeneratedDuplicateClaimRejectionEmail: 'GeneratedDuplicateClaimRejectionEmail',
	RequestedDuplicateInvoiceRejectionEmail: 'RequestedDuplicateInvoiceRejectionEmail',
	GeneratedDuplicateInvoiceRejectionEmail: 'GeneratedDuplicateInvoiceRejectionEmail',
	RequestedDuplicateClaimRejectionLetter: 'RequestedDuplicateClaimRejectionLetter',
	GeneratedDuplicateClaimRejectionLetter: 'GeneratedDuplicateClaimRejectionLetter',
	RequestedDuplicateInvoiceRejectionLetter: 'RequestedDuplicateInvoiceRejectionLetter',
	GeneratedDuplicateInvoiceRejectionLetter: 'GeneratedDuplicateInvoiceRejectionLetter',
	EditedDocument: 'EditedDocument',
	DeletedDocument: 'DeletedDocument',
	FailedAutomation: 'FailedAutomation',
	RequestedMissingInfoSms: 'RequestedMissingInfoSms',
	GeneratedMissingInfoSms: 'GeneratedMissingInfoSms',
});

const outCommunicationsDisplayMap = Object.freeze({
	ClaimAcknowledgement: 'Claim Acknowledgement',
	MissingInformation: 'Missing Information',
	MissingInformationChase: 'Missing Information Chase',
	WaitPeriodRejection: 'Wait Period Rejection',
	NoSectionLimitRejection: 'No Section Limit Rejection',
	NoConditionLimitRejection: 'No Condition Limit Rejection',
	ManualRejection: 'Manual Rejection',
	ClaimSettlement: 'Claim Settlement',
	ClaimReassessment: 'Reassessing',
	ClaimReassessmentCommunication: 'Claim Reassessment',
	OverrideRejection: 'Claim Rejection Overridden',
	PreExistingConditionRejection: 'Pre-Existing Condition Rejection',
	NoActiveCoverPeriodRejection: 'No Active Cover Period Rejection',
	PreAuthorisationCompleted: 'Pre-Authorisation Completed',
	ReassessmentAcknowledgement: 'Reassessment Acknowledgement',
	MedicalRecordsSummaryCommunication: 'Medical Records Summary',
	GreyMedicalRecordsSummaryCommunication: 'Grey Medical Records Summary',
	NoProductCoverageRejection: 'No Product Coverage Rejection',
	MultipleRejection: 'Multiple Reasons Rejection',
	FastTrackReviewedClaim: 'Fast Track Reviewed',
	EscalatedClaim: 'Escalated',
	DeEscalatedClaim: 'De-escalated',
	OpenClaimReminder: 'Claim Update/Appeal Update',
	DuplicateClaimRejection: 'Duplicate Claim Rejection',
	DuplicateInvoiceRejection: 'Duplicate Invoice Rejection',
});

const interactionDetailType = Object.freeze({
	Rejection: 1,
	MissingInfo: 2,
	FraudCheck: 3,
	Email: 4,
	Sms: 5,
	Payment: 6,
	Claim: 7,
	Reassessment: 8,
	HospitalClaim: 9,
	AdjusterComment: 10,
	SettledReason: 11,
	PreExistingCondition: 12,
});

const generatedInteractionsTypes = [
	interactionType.GeneratedWaitPeriodLetter,
	interactionType.GeneratedMissingInfoLetter,
	interactionType.GeneratedNoLimitLetter,
	interactionType.GeneratedSettlementLetter,
	interactionType.GeneratedWaitPeriodEmail,
	interactionType.GeneratedAcknowledgementEmail,
	interactionType.GeneratedMissingInfoEmail,
	interactionType.GeneratedMissingInfoSms,
	interactionType.GeneratedNoLimitEmail,
	interactionType.GeneratedSettlementEmail,
	interactionType.GeneratedAcknowledgementSms,
	interactionType.GeneratedReassessmentLetter,
	interactionType.GeneratedReassessmentEmail,
	interactionType.GeneratedRejectedManuallyEmail,
	interactionType.GeneratedRejectedManuallyLetter,
	interactionType.GeneratedChaseLetter,
	interactionType.GeneratedChaseEmail,
	interactionType.GeneratedChaseSms,
	interactionType.GeneratedSettlementSms,
	interactionType.GeneratedRejectedPreExistingConditionEmail,
	interactionType.GeneratedRejectedPreExistingConditionLetter,
	interactionType.GeneratedRejectedNoActiveCoverPeriodEmail,
	interactionType.GeneratedRejectedNoActiveCoverPeriodLetter,
	interactionType.GeneratedPreAuthorisationCompletedEmail,
	interactionType.GeneratedPreAuthorisationCompletedLetter,
	interactionType.GeneratedReassessmentAcknowledgementEmail,
	interactionType.GeneratedReassessmentAcknowledgementSms,
	interactionType.GeneratedMedicalRecordsSummaryLetter,
	interactionType.GeneratedMedicalRecordsSummaryEmail,
	interactionType.GeneratedGreyMedicalRecordsSummaryLetter,
	interactionType.GeneratedGreyMedicalRecordsSummaryEmail,
	interactionType.GeneratedRejectedNoProductCoverageEmail,
	interactionType.GeneratedRejectedNoProductCoverageLetter,
	interactionType.GeneratedRejectedMultipleEmail,
	interactionType.GeneratedRejectedMultipleLetter,
	interactionType.GeneratedOpenClaimReminderEmail,
	interactionType.GeneratedOpenClaimReminderLetter,
	interactionType.GeneratedOpenClaimReminderSms,
	interactionType.GeneratedDuplicateClaimRejectionEmail,
	interactionType.GeneratedDuplicateClaimRejectionLetter,
	interactionType.GeneratedDuplicateInvoiceRejectionEmail,
	interactionType.GeneratedDuplicateInvoiceRejectionLetter,
];

const requestedInteractionsTypes = [
	interactionType.RequestedWaitPeriodLetter,
	interactionType.RequestedMissingInfoLetter,
	interactionType.RequestedNoLimitLetter,
	interactionType.RequestedSettlementLetter,
	interactionType.RequestedWaitPeriodEmail,
	interactionType.RequestedAcknowledgementEmail,
	interactionType.RequestedMissingInfoEmail,
	interactionType.RequestedMissingInfoSms,
	interactionType.RequestedNoLimitEmail,
	interactionType.RequestedSettlementEmail,
	interactionType.RequestedAcknowledgementSms,
	interactionType.RequestedReassessmentLetter,
	interactionType.RequestedReassessmentEmail,
	interactionType.RequestedRejectedManuallyEmail,
	interactionType.RequestedRejectedManuallyLetter,
	interactionType.RequestedChaseLetter,
	interactionType.RequestedChaseEmail,
	interactionType.RequestedChaseSms,
	interactionType.RequestedSettlementSms,
	interactionType.RequestedRejectedPreExistingConditionEmail,
	interactionType.RequestedRejectedPreExistingConditionLetter,
	interactionType.RequestedRejectedNoActiveCoverPeriodEmail,
	interactionType.RequestedRejectedNoActiveCoverPeriodLetter,
	interactionType.RequestedPreAuthorisationCompletedEmail,
	interactionType.RequestedPreAuthorisationCompletedLetter,
	interactionType.RequestedReassessmentAcknowledgementEmail,
	interactionType.RequestedReassessmentAcknowledgementSms,
	interactionType.RequestedMedicalRecordsSummaryLetter,
	interactionType.RequestedMedicalRecordsSummaryEmail,
	interactionType.RequestedGreyMedicalRecordsSummaryLetter,
	interactionType.RequestedGreyMedicalRecordsSummaryEmail,
	interactionType.RequestedRejectedNoProductCoverageEmail,
	interactionType.RequestedRejectedNoProductCoverageLetter,
	interactionType.RequestedRejectedMultipleEmail,
	interactionType.RequestedRejectedMultipleLetter,
	interactionType.RequestedOpenClaimReminderEmail,
	interactionType.RequestedOpenClaimReminderLetter,
	interactionType.RequestedOpenClaimReminderSms,
	interactionType.RequestedDuplicateClaimRejectionEmail,
	interactionType.RequestedDuplicateClaimRejectionLetter,
	interactionType.RequestedDuplicateInvoiceRejectionEmail,
	interactionType.RequestedDuplicateInvoiceRejectionLetter,
];

const uploadedDocumentActions = [
	interactionType.ClaimFormDocument,
	interactionType.InvoiceDocument,
	interactionType.MedicalHistoryDocument,
	interactionType.MedicalRecordSummaryDocument,
	interactionType.OtherDocument,
	interactionType.HospitalClaimSubmission,
	interactionType.InboundEmailDocument,
];

function isUploadedDocumentAction (action) {
	return uploadedDocumentActions.includes(action);
}

export {
	interactionType,
	interactionDetailType,
	outCommunicationsDisplayMap,
	generatedInteractionsTypes,
	requestedInteractionsTypes,
	isUploadedDocumentAction,
};
