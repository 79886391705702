<template>
	<div
		class="h-100"
		:style="barStyle"
	>
		<div :class="[{ 'text-truncate': isLabelTruncated}, 'px-10']">
			{{ label }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		percentage: {
			type: Number,
			required: true,
			validator: val => val >= 0 && val <= 100,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		isLabelTruncated: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data () {
		return {};
	},
	computed: {
		barStyle: function () {
			if (this.isLabelTruncated) {
				return {
					width: `${this.percentage}%`,
					'min-width': 0,
				};
			}
			return {
				'flex-basis': `${this.percentage}%`,
				'flex-shrink': 1,
				'white-space': 'nowrap',
			};
		},
	},
};
</script>
