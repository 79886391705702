<template>
	<div>
		<div class="d-flex justify-content-between py-6 px-10 section-content bordered">
			<div>Min opt-in number</div>
			<div class="opt-section font-weight-bold cursor-default">
				{{ queue.minOptInNumber }}
			</div>
		</div>
		<div class="d-flex justify-content-between py-6 px-10 section-content">
			<div>Users in queue</div>
			<div class="opt-section font-weight-bold cursor-default">
				{{ queue.users.length }}
			</div>
		</div>
		<div class="d-flex justify-content-between p-10 bordered bg">
			<div
				class="flex-grow-1 text-center opt-section opt-section__in mx-4"
				:class="{ 'active': activeUserType === 'opt-in' }"
				@click="onSwitchUsers('opt-in')"
			>
				<div> Opted In <span class="font-weight-bold"> {{ optInUsersCount }} </span> </div>
			</div>
			<div
				class="flex-grow-1 text-center opt-section opt-section__out mx-4"
				:class="{ 'active': activeUserType === 'opt-out' }"
				@click="onSwitchUsers('opt-out')"
			>
				<div> Opted Out <span class="font-weight-bold"> {{ optOutUsersCount }} </span> </div>
			</div>
		</div>
		<div class="d-flex p-6 bg">
			<aq-user-opt-in-status
				v-for="(user, i) in activeUsers"
				:key="i"
				:user="user"
				@click.native="$emit('opt-out-user', user)"
			/>
		</div>
	</div>
</template>

<script>
import AqUserOptInStatus from '@commonWidgets/AqUserOptInStatus';

const UsersType = Object.freeze({
	OptIn: 'opt-in',
	OptOut: 'opt-out',
});

export default {
	components: {
		AqUserOptInStatus,
	},
	props: {
		queue: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			activeUserType: UsersType.OptIn,
		};
	},
	computed: {
		activeUsers () {
			return this.queue.users.filter(user => user.optInStatus === (this.activeUserType === UsersType.OptIn));
		},
		optInUsersCount () {
			return	this.queue.users.filter(user => user.optInStatus).length;
		},
		optOutUsersCount () {
			return	this.queue.users.filter(user => !user.optInStatus).length;
		},
	},
	methods: {
		onSwitchUsers (type) {
			this.activeUserType = type;
		},
	},
};
</script>

<style lang="scss" scoped>
.bordered {
  border-bottom: 1px solid var(--tableGroupBg);
}

.opt-section {
  border: 1px solid var(--cardIconColour);
  border-radius: 8px;
  padding: 0 10px;
  text-align: center;
  min-width: 40px;
  cursor: pointer;

  &__in {
    border: 1px solid var(--optInIndicatorColour);

    &.active {
      background: var(--optInIndicatorColour);
      border-style: transparent;
    }
  }

  &__out {
    border: 1px solid var(--optOutIndicatorColour);

    &.active {
      background: var(--optOutIndicatorColour);
      border-style: transparent;
    }
  }
}
</style>
