const claimItemsValidationResultEnum = Object.freeze({
	PROCEED: 1,
	INVALID: 2,
	INVALID_TOTAL_AMOUNT: 3,
	REJECTED_BY_NO_POLICY_COVER: 4,
	REJECTED_BY_NO_PRODUCT_COVER: 5,
	REJECTED_MANUALLY: 6,
	REJECTED_WAITING_PERIOD: 7,
	REJECTED_BY_PREEXISTING_CONDITION: 8,
	REJECTED_BY_RELATED_CONDITION: 9,
	REJECTED_SECTION_LIMIT: 10,
	REJECTED_BY_CONDITION_LIMIT: 11,
	REJECTED_BY_MULTIPLE_CONDITIONS: 12,
	REJECTED_MULTIPLE: 13,
	CONDITIONS_CLAIM_ITEMS_COUNT_MISMATCH: 14,
});

export default claimItemsValidationResultEnum;
