import httpClient from '@commonServices/httpClient';
import { toNotes } from '@commonServices/utils/converter';
import { NoteTypesEnum, NoteTypesEnumMap } from '@commonServices/models/NoteTypesEnum';

export default {
	// #region requests

	getNotes (payload) {
		return httpClient('/api/claims/notes/filter')
			.post(payload)
			.then(toNotes);
	},
	getNotesCount ({ customerId, petId, claimId }) {
		let filter = '';
		if (customerId) {
			filter = `?customerId=${customerId}`;
		} else if (petId) {
			filter = `?petId=${petId}`;
		} else if (claimId) {
			filter = `?claimId=${claimId}`;
		}
		return httpClient(`/api/claims/notes/count${filter}`, { omitSpinner: true })
			.get();
	},
	createNote (data) {
		return httpClient('/api/claims/notes')
			.post(data);
	},
	editNote (data) {
		return httpClient(`/api/claims/notes/${data.id}`)
			.put(data);
	},
	deleteNote (id) {
		return httpClient(`/api/claims/notes/${id}`)
			.delete();
	},

	// #endregion

	// #region logic

	getNoteTypes (can) {
		const defaultNoteTypes = [
			{ key: NoteTypesEnum.Standard, value: NoteTypesEnumMap[NoteTypesEnum.Standard] },
			{ key: NoteTypesEnum.Alert, value: NoteTypesEnumMap[NoteTypesEnum.Alert] },
		];
		if (can.ViewAppealNotes) {
			return [...defaultNoteTypes, { key: NoteTypesEnum.Appeal, value: NoteTypesEnumMap[NoteTypesEnum.Appeal] }];
		}
		return defaultNoteTypes;
	},

	// #endregion
};
