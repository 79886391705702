<template>
	<div
		class="search-block d-flex"
		:class="{ 'large' : isFocus }"
		v-shortkey="$shortkeys.search"
		@shortkey="onTriggerSearchInput"
	>
		<aq-input
			:input-class="'rounded-3 search-input' + (searchValue ? ' left-align' : '')"
			placeholder="Search"
			@focus="focus"
			v-model="searchValue"
			@keyup.enter.native="onSearch"
			ref="search"
		/>
		<aq-search-button
			class="search-button"
			:class="{ 'active': isFocus }"
			@click.native="onSearch"
		/>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	name: 'AqSearchInput',
	data () {
		return {
			searchValue: '',
			isFocus: false,
		};
	},
	watch: {
		value: {
			handler (newVal) {
				this.searchValue = newVal;
			},
			immediate: true,
		},
	},
	props: {
		value: {
			type: String,
			required: false,
			default: '',
		},

	},
	methods: {
		...mapMutations([
			'triggerSearchButtonClick',
		]),
		onSearch () {
			if (this.searchValue) {
				this.triggerSearchButtonClick();
				this.$emit('search', this.searchValue);
				this.$refs.search.$refs.input.blur();
			}
		},
		focus (value) {
			this.isFocus = value;
		},
		onTriggerSearchInput () {
			this.$refs.search.$refs.input.focus();
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .search-input {
  width: 250px;
  padding: 12px 20px;
  transition: 0.2s ease;
  font-size: 14px;
  border: none;
  text-align: center;

  &:focus {
    outline: none;

    &::after {
      width: 100%;
    }
  }

  &:focus,
  &.left-align {
    text-align: left;
    width: 400px;
  }
}

.search-block {
  &::after {
    content: "";
    width: 0;
    transition: 0.2s ease;
    position: absolute;
    bottom: 0;
    background: $primary;
    height: 2px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  &.large::after {
    width: 100%;
  }
}

.search-button {
  position: absolute;
  right: -24px;
  top: 0;
  border-radius: 50%;
  width: 43px;
  height: 43px;
}
</style>
