import { shortDate, shortDateTime, pad, pluralize, formatBytes } from '@commonServices/utils/filters';
import { FilterType } from '@commonServices/models/FilterType';
import LetterView from '@commonServices/models/LetterView';
import ZipFileView from '@commonServices/models/ZipFileView';
import { DateTypes, getDayEnd, getDayStart } from '@commonServices/utils/dateUtils';
import { setSelectedFilterValue } from './filtersConverter';
import groupBy from 'lodash.groupby';

const dateFrom = 'dateFrom';
const dateTo = 'dateTo';

export function toViewFilter (apiFiltersList) {
	const apiFilters = groupBy(apiFiltersList, 'type');
	return {
		letterType: setSelectedFilterValue(apiFilters[FilterType.LetterType], 'Template', FilterType.LetterType),
		reportClaimStatus: setSelectedFilterValue(apiFilters[FilterType.ClaimStatus], 'Claim Status', FilterType.ClaimStatus),
		letterRecipientType: setSelectedFilterValue(apiFilters[FilterType.LetterRecipient], 'Recipient Type', FilterType.LetterRecipient),
		zippedFileStatus: setSelectedFilterValue(apiFilters[FilterType.ZippedFileStatus], 'Zipped Files', FilterType.ZippedFileStatus, false, { id: 'Exclude', description: 'Exclude' }),
		[dateFrom]: setSelectedDateFilterValue(apiFilters[FilterType.LetterCreatedDateFrom], 'Date From', FilterType.LetterCreatedDateFrom),
		[dateTo]: setSelectedDateFilterValue(apiFilters[FilterType.LetterCreatedDateTo], 'Date To', FilterType.LetterCreatedDateTo),
		brand: setSelectedFilterValue(apiFilters[FilterType.Brand], 'Brand', FilterType.Brand),
	};
}

function setSelectedDateFilterValue ([filterData] = [], label, type, defaulValue = null) {
	return {
		type,
		value: filterData ? new Date(filterData.value) : defaulValue,
		label,
	};
}
const dateFields = ['dateFrom', 'dateTo'];

export function toLettersApiFilters (filterData) {
	return Object.entries(filterData)
		.map(([key, filter]) => {
			if (!filter.value) { return null; }
			if ([dateFrom, dateTo].includes(key)) {
				return { type: filter.type, value: (filter.type === FilterType.LetterCreatedDateFrom ? getDayStart : getDayEnd)(filter.value), description: DateTypes.SelectDate };
			} else {
				return {
					type: filter.type,
					value: filter.value.id.toString(),
					description: filter.value.description,
				};
			}
		}).filter(Boolean);
}

export function toSearchTerms (filterData) {
	const searchTerms = Object.entries(filterData)
		.map(([key, filter]) => {
			if (!filter.value) { return null; }
			if (dateFields.includes(key)) {
				return {
					label: filter.label,
					value: shortDate(filter.value),
				};
			} else {
				return {
					label: filter.label,
					value: filter.value.description,
				};
			}
		}).filter(Boolean);

	return searchTerms;
}

export function toLetterView ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((letter) => {
			const generatedBy = letter.generatedBy
				? `${letter.generatedBy.firstName} ${letter.generatedBy.lastName}`
				: 'System Generated';

			return new LetterView(
				letter.id,
				pad(letter.policyId, 6),
				letter.policyReference,
				letter.letterType,
				letter.vetName || 'No Vets',
				letter.claimStatus,
				letter.customerId,
				letter.customerFirstName,
				letter.customerLastName,
				generatedBy,
				shortDateTime(letter.generatedDateTime),
				letter.claimId,
				letter.petId,
				letter.petName,
				letter.petType,
				letter.petGender,
				letter.petDateOfDeath,
				letter.zippedFileName,
			);
		}),
	};
}

export function toZipFileView ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((zipFile) => {
			const createdBy = zipFile.createdBy
				? `${zipFile.createdBy.firstName} ${zipFile.createdBy.lastName}`
				: 'No User';

			const lettersCount = `${zipFile.filesCount} ${pluralize('Letter', zipFile.filesCount)}`;
			const size = formatBytes(+zipFile.size);
			return new ZipFileView(
				zipFile.id,
				zipFile.fileName,
				createdBy,
				shortDateTime(zipFile.createdDateTime),
				lettersCount,
				size,
			);
		}),
	};
}
