<template>
	<aq-select
		data-qa="filter_input_boolean"
		:options="booleanOpions"
		:label="filter.label"
		option-label="description"
		class="w-100"
		v-model="filter.value"
		track-by="id"
	/>
</template>
<script>

export default {
	name: 'AqBooleanFilter',
	data () {
		return {
			booleanOpions: [{ id: 'True', description: 'Yes' }, { id: 'False', description: 'No' }],
		};
	},
	props: {
		filter: {
			type: Object,
			required: true,
		},

	},
};
</script>
