<template>
	<div class="task-check">
		<div class="label py-20 fs-12 text-center">
			{{ label }} {{ progress.toFixed() }}%
		</div>
		<div>
			<aq-progress
				:percentage="progress"
				color-class="bg-primary"
				bg-light
				class="progress-bar"
				:height="5"
			/>
		</div>
	</div>
</template>

<script>

export default {
	name: 'LettersZipProgress',
	props: {
		progressPercentage: {
			type: [Number, String],
			required: true,
		},
		label: {
			type: String,
			default: '',
		},
	},

	data () {
		return {
			progress: 0,
			progressAutoIncrement: 0.25,
			progressAutoIncrementInterval: 250,
		};
	},
	mounted () {
		const self = this;
		this.autoIncrementTimer = setTimeout(function autoIncrement () {
			if (self.progress < 95) {
				self.progress += self.progressAutoIncrement;
				self.autoIncrementTimer = setTimeout(autoIncrement, self.progressAutoIncrementInterval);
			}
		}, this.progressAutoIncrementInterval);
	},
	watch: {
		progressPercentage (newValue) {
			if (newValue > this.progress) {
				this.progress = newValue;
			} else { // slow down progress autoincrement
				this.progressAutoIncrementInterval = this.progressAutoIncrementInterval * 1.5;
			}
		},
	},
	beforeDestroy () {
		clearTimeout(this.autoIncrementTimer);
	},
};
</script>
