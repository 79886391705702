const RuleType = Object.freeze({
	ReinstatementAllConditions: 'Reinstatement All Conditions',
	ReinstatementPerConditions: 'Reinstatement Per Condition',
	MaximumBenefitPerCondition: 'Max Benefit',
	TimeLimitedPerCondition: 'Time Limit',
	MaximumLimitAllConditions: 'Maximum Limit All Conditions',
	PerClaim: 'Per Claim',
	PerYear: 'Per Year',
	PerConditionPerYear: 'Per Condition, Per Year',
	PerConditionLifetime: 'Per Condition Lifetime',
	PerCondition: 'Per Condition',
});

export default RuleType;
