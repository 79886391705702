<template>
	<div class="money-bar-wrapper d-flex align-items-center">
		<aq-bar
			:class="[isSeparated ? 'section-separated' : 'section', { 'expanded' : item.label && isSeparated }, item.class]"
			:percentage="computePercentage(item.value)"
			v-for="(item, index) in computedSections"
			:key="index"
			:label="item.label"
			:style="getBarStyle(item, index)"
			@click.native="onSelectSegment(index)"
			:is-label-truncated="isLabelTruncated"
		/>
	</div>
</template>

<script>

export default {
	props: {
		total: {
			type: Number,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
		isSeparated: {
			type: Boolean,
			required: false,
			default: false,
		},
		isLabelTruncated: {
			type: Boolean,
			default: true,
			required: false,
		},
		barHeight: {
			type: Number,
			required: false,
			default: 17,
		},
	},
	data () {
		return {
			isLabelVisible: true,
			sectionsCopy: [],
		};
	},
	mounted () {
		this.setSections();
	},
	watch: {
		sections () {
			this.setSections();
		},
	},
	computed: {
		computedSections () {
			return this.isSeparated ? this.sectionsCopy : this.sections;
		},
	},
	methods: {
		computePercentage (value) {
			const positiveValue = Math.abs(value);
			if (positiveValue > this.total) {
				return 100;
			}
			return (positiveValue / this.total * 100) || 0;
		},
		clearSectionLabel () {
			this.sectionsCopy = this.sectionsCopy.map((item) => {
				return {
					value: item.value,
					label: '',
					class: item.class,
				};
			});
		},
		getBarStyle (item, index) {
			if (this.isSeparated) {
				return {
					'margin-right': '3px',
					'line-height': `${this.barHeight}px`,
				};
			}
			if (index) {
				return {
					'z-index': this.sections.length - index,
					'margin-left': '-5px',
					'box-sizing': 'content-box',
					'line-height': `${this.barHeight}px`,
				};
			} else {
				return {
					'z-index': this.sections.length - index,
					'line-height': `${this.barHeight}px`,
				};
			}
		},
		onSelectSegment (index) {
			this.setSelection(index);
			this.$emit('select-segment', index);
		},
		setSelection (index) {
			if (this.isSeparated) {
				this.clearSectionLabel();
				this.sectionsCopy[index] = this.sections[index];
			}
		},
		setSelectionWithoutClear (index) {
			if (this.isSeparated) {
				this.sectionsCopy[index] = this.sections[index];
				this.$forceUpdate();
			}
		},
		setSections () {
			if (this.isSeparated) {
				this.sectionsCopy = [...this.sections];
				this.clearSectionLabel();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.money-bar-wrapper {
  color: $body-color;
  border-radius: 3px;
}

.section {
  border-radius: 0 3px 3px 0;
  transition: 0.2s ease;

  &:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  &:first-child {
    border-radius: 3px;
  }

  &:last-child,
  &:nth-child(2) {
    padding-left: 5px;

    &:hover {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &:last-child {
    border-radius: 3px;
  }
}

.section-separated {
  border-radius: 1px;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    padding: 0 5px;
  }
}

::v-deep .expanded {
  transform: scale(1, 1.5);
  cursor: default;

  div {
    transform: scale(1, 0.666);
  }
}
</style>
