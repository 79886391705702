export default class UserStatsReportModel {
	constructor (
		user,
		brandName,
		userActive,
		teams,
		claimsCreated,
		claimsClosed,
		optInDurationSec,
		optOutDurationSec,
		logInTimeSec,
	) {
		this.user = user;
		this.brandName = brandName;
		this.teamName = teams;
		this.claimsCreated = claimsCreated;
		this.claimsClosed = claimsClosed;
		this.userActive = userActive;
		this.optInDurationSec = optInDurationSec;
		this.optOutDurationSec = optOutDurationSec;
		this.logInTimeSec = logInTimeSec;
	}
}
