<template>
	<div
		class="d-flex align-items-center"
		style="height: 42px;"
	>
		<button
			type="button"
			class="btn small left-btn btn-primary h-100"
			@click="onPrev"
			:class="{ 'disabled' : prevDisabled }"
			data-qa="aqShifter_button_prev"
		>
			<i class="fas fa-caret-left text-white fs-16" />
		</button>
		<div
			class="flex-fill text-center list-item--bg h-100 py-10"
			data-qa="aqShifter_label_content"
		>
			{{ items[initialIndex].content }}
		</div>
		<button
			type="button"
			class="btn small right-btn btn-primary h-100"
			@click="onNext"
			:class="{ 'disabled' : nextDisabled }"
			data-qa="aqShifter_button_next"
		>
			<i class="fas fa-caret-right text-white fs-16" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'AqShifter',
	data () {
		return {
			prevDisabled: false,
			nextDisabled: false,
		};
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
		initialIndex: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	methods: {
		onPrev () {
			if (this.initialIndex - 1 >= 0) {
				this.$emit('change', this.initialIndex - 1);
			}
		},
		onNext () {
			if (this.items.length - 1 >= this.initialIndex + 1) {
				this.$emit('change', this.initialIndex + 1);
			}
		},
		updateArrowsVisibility () {
			this.prevDisabled = false;
			this.nextDisabled = false;

			if (this.initialIndex - 1 < 0) {
				this.prevDisabled = true;
			}

			if (this.items.length === this.initialIndex + 1) {
				this.nextDisabled = true;
			}
		},
	},
	watch: {
		initialIndex: {
			handler () {
				this.updateArrowsVisibility();
			},
			immediate: true,
		},
		items () {
			this.updateArrowsVisibility();
		},
	},
};
</script>

<style lang="scss" scoped>
.left-btn {
  border-radius: 3px 0 0 3px;
}

.right-btn {
  border-radius: 0 2px 2px 0;
}

.disabled {
  &:hover {
    cursor: default;
  }

  i {
    color: $gray !important;
  }
}

.bg-secondary--darken {
  background: $secondary-d-3;
}
</style>
