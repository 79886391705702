<template>
	<aq-select-filter
		data-qa="filter_input_breed"
		class="w-100"
		v-model="filter.value"
		:options="breeds"
		:loading="breedLoading"
		:async-find="searchBreed"
		:label="filter.label"
		:multiple="filter.multiselect"
		:excluded="filter.excluded"
		@toggle-mode="filter.excluded = $event"
		no-result-message="No Breeds found"
	/>
</template>
<script>

import debounce from 'lodash.debounce';
import ExtraService from '@commonServices/extraService';

export default {
	name: 'BreedFilter',
	props: {
		filter: {
			type: Object,
			required: true,
		},
		petType: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data () {
		return {
			breedLoading: false,
			breeds: [],
		};
	},
	mounted () {
		this.breeds = (this.filter.value ? [this.filter.value] : []).flat();
	},

	methods: {
		searchBreed: debounce(async function (term) {
			if (term.length >= 3) {
				this.breedLoading = true;
				const petTypeId = this.petType.value ? this.petType.value.id : null;
				this.breeds = (await ExtraService.searchBreed(term, petTypeId))
					.map(breed => ({
						id: breed.id,
						description: breed.name,
						petType: breed.petTypeId,
					}),
					);
				this.breedLoading = false;
			}
		}, 500),
	},
};
</script>
