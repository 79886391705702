import resolveService from '@commonServices/resolveService';

const PA_INTEGRATED = 'Integrated';
const OPEN_INTERNAL = ClaimsConfig.PA_MODE === PA_INTEGRATED;

const routePrefix = OPEN_INTERNAL ? '/quote' : '';
const PolicyAdminRoutes = Object.freeze({
	CustomerRoute: (ref) => ({ path: `${routePrefix}/mta/customer/${ref}` }),
	PetRoute: (ref) => ({ path: `${routePrefix}/mta/pet/${ref}` }),
	ProductRoute: (ref) => ({ path: `${routePrefix}/mta/product-change`, query: { ref } }),
});

let paServiceInstance;

class PolicyAdminNavigateService {
	constructor (router) {
		this._router = router;
	}

	async navigateCustomerPage (customerId) {
		const customerContext = await resolveService.toCustomerContext(customerId);
		const paCustomerLocation = PolicyAdminRoutes.CustomerRoute(customerContext.customerRef);
		this.navigate(paCustomerLocation);
	}

	async navigatePetPage (petId) {
		const petContext = await resolveService.toPetContext(petId);
		const paPetLocation = PolicyAdminRoutes.PetRoute(petContext.petRef);
		this.navigate(paPetLocation);
	}

	async navigatePolicyPage (policyId) {
		const policyContext = await resolveService.toPolicyContext(policyId);
		const paPolicyLocation = PolicyAdminRoutes.ProductRoute(policyContext.policyRef);
		this.navigate(paPolicyLocation);
	}

	navigate (location) {
		if (!ClaimsConfig.PA_ENABLED) return;
		if (OPEN_INTERNAL) {
			this._router.push(location);
		} else {
			const resolved = this._router.resolve(location);
			window.open(`${ClaimsConfig.PA_HOST_NAME}${resolved.href}`, '_blank');
		}
	}
}

export default {
	install (Vue, router) {
		if (paServiceInstance == null) {
			paServiceInstance = new PolicyAdminNavigateService(router);
		}
	},
};

export { paServiceInstance };
