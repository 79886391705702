<template>
	<div>
		<aq-task-check
			v-for="task of tasks"
			:key="task.id"
			:task-id="task.id"
			:options="task.options"
			@task-status-changed="onTaskStatusChanged"
			@task-expired="onTaskExpired"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AqTaskCheck from './AqTaskCheck';
import { toastActions } from '@commonServices/settings/toastSettings';

const getToastOptionsZip = (params) => ({
	icon: 'file-acrhive',
	duration: 8000,
	action: [
		{
			text: 'View',
			push: {
				name: 'zippedLetters',
				params: {
					type: 'zipped',
					...params,
				},
			},
		},
		toastActions.close,
	],
});

export default {
	name: 'AqTasks',
	components: {
		'aq-task-check': AqTaskCheck,
	},
	computed: {
		...mapState({
			tasks: state => Object.values(state.tasks).filter(task => task.progress !== 100),
		}),
	},
	methods: {
		...mapActions(['changeTaskData']),
		onTaskStatusChanged (taskData) {
			this.changeTaskData(taskData);
			if (taskData.progress === 100) {
				if (taskData.data && taskData.data.zipFileId) {
					const { data: { zipFileId, zipFileName } } = taskData;
					const message = `Document Zip ${zipFileName} Complete`;
					this.$toasted.show(message, getToastOptionsZip({ zipFileId, zipFileName }));
				}
				if (taskData.data && taskData.data.error) {
					this.reportTaskError(taskData.data.error);
				}
			}
		},
		onTaskExpired (taskData) {
			const taskErrorMessage = taskData.options.errorMessage || 'Task Execution Failed';
			this.changeTaskData({ ...taskData, progress: 100, data: { error: true, message: taskErrorMessage } });
			this.reportTaskError(taskErrorMessage);
		},
		reportTaskError (error) {
			this.$toasted.global.common_error({ message: error });
		},
	},
};
</script>
