<template>
	<aq-select-filter
		data-qa="filter_input_ailment"
		class="w-100"
		v-model="filter.value"
		:options="ailments"
		:loading="ailmentLoading"
		:async-find="searchAilment"
		:label="filter.label"
		:multiple="filter.multiselect"
		:excluded="filter.excluded"
		@toggle-mode="filter.excluded = $event"
		no-result-message="No Ailments found"
	/>
</template>
<script>

import debounce from 'lodash.debounce';
import claimService from '@commonServices/claimService';
import { updateAilmentOnConditionSelection } from '@commonServices/filterServices';

export default {
	name: 'AilmentFilter',
	props: {
		filter: {
			type: Object,
			required: true,
		},
		conditionType: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data () {
		return {
			ailmentLoading: false,
			ailments: [],
		};
	},
	mounted () {
		this.ailments = (this.filter.value ? [this.filter.value] : []).flat();
	},
	watch: {
		'conditionType.value' (value) {
			updateAilmentOnConditionSelection(this.filter.value, value, ailments => { this.filter.value = ailments; });
		},
	},
	methods: {
		searchAilment: debounce(async function (term) {
			if (term.length >= 3) {
				this.ailmentLoading = true;
				const conditionTypeId = this.conditionType.value ? this.conditionType.value.id : null;
				this.ailments = (await claimService.getAilments(term, conditionTypeId))
					.map((ailment) => {
						return {
							...ailment,
							description: ailment.firstCause,
						};
					});
				this.ailmentLoading = false;
			}
		}, 500),
	},
};
</script>
