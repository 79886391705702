<template>
	<div
		v-shortkey="navigationControls"
		@shortkey="onNavigateList"
	>
		<slot
			:list="list"
			:current-item="selectedItem"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'AqListNavigationControl',
	props: {
		list: {
			type: Array,
			required: true,
		},
		currentItem: {
			type: Object,
			required: false,
			default: null,
		},
		navControls: {
			type: Object,
			required: false,
			default () { return { down: this.$shortkeys.down, up: this.$shortkeys.up }; },
		},
	},
	data () {
		return {
			selectedItem: this.currentItem,
		};
	},
	watch: {
		currentItem (newItem) {
			this.selectedItem = newItem;
		},
	},
	computed: {
		...mapState(['shortKeyEnabled']),
		navigationControls () {
			return this.shortKeyEnabled ? this.navControls : null;
		},
	},
	methods: {
		onNavigateList (event) {
			const listLength = this.list.length;
			const currentItemIndex = this.list.findIndex(item => item === this.selectedItem);
			if (currentItemIndex === -1) {
				this.selectedItem = this.list[0];
			}
			if (event.srcKey === 'up') {
				if (currentItemIndex === 0) {
					this.selectedItem = this.list[listLength - 1];
				} else {
					this.selectedItem = this.list[currentItemIndex - 1];
				}
			} else if (event.srcKey === 'down') {
				if (currentItemIndex === listLength - 1) {
					this.selectedItem = this.list[0];
				} else {
					this.selectedItem = this.list[currentItemIndex + 1];
				}
			}
			this.$emit('current-changed', this.selectedItem);
		},
	},
};
</script>
