<template>
	<el-table
		v-if="waitingPeriodData.length"
		:data="waitingPeriodData"
		style="width: 100%;"
		header-row-class-name="table-wait-period-header"
		:row-class-name="getRowClassName"
		class="table-wait-period"
	>
		<el-table-column>
			<template slot-scope="props">
				<i
					v-if="props.row.hasOverride"
					class="fas fa-exclamation-triangle"
					v-tooltip="{
						content: 'Wait Period Override',
						classes: ['tooltip-error', 'text-bold'],
						boundariesElement: 'aq-modal-container'
					}"
				/>
			</template>
		</el-table-column>
		<el-table-column
			prop="conditionType"
			label="Condition Type"
		/>
		<el-table-column
			prop="waitAmountDays"
			label="Number of Days"
		>
			<template slot-scope="props">
				<div class="d-flex">
					<label :class="{'light': props.row.hasOverride}">{{ props.row.waitAmountDays }}</label>
					<div v-if="props.row.hasOverride">
						<i class="fas fa-long-arrow-alt-right m-5" />
						<label class="font-weight-bold danger">{{ props.row.waitAmountDaysOverride }}</label>
					</div>
				</div>
			</template>
		</el-table-column>
		<el-table-column
			prop="endDate"
			label="End Date"
			class-name="text-left"
		>
			<template slot-scope="props">
				<div class="d-flex">
					<label :class="{'light': props.row.hasOverride}">{{ props.row.endDate | shortDate }}</label>
					<div v-if="props.row.hasOverride">
						<i class="fas fa-long-arrow-alt-right m-5" />
						<label class="font-weight-bold danger">{{ props.row.endDateOverride | shortDate }}</label>
					</div>
				</div>
			</template>
		</el-table-column>
	</el-table>
	<div
		class="empty-block"
		v-else
	>
		No Waiting Periods
	</div>
</template>

<script>
import { toWaitingPeriodsView } from '@commonServices/utils/converter';

export default {
	name: 'AqWaitPeriodsTable',
	props: {
		waitingPeriods: {
			type: Array,
			required: true,
		},
	},
	methods: {
		getRowClassName ({ row }) {
			return row.hasOverride ? 'table-wait-period-row highlighted' : 'table-wait-period-row';
		},
	},
	computed: {
		waitingPeriodData () {
			return toWaitingPeriodsView(this.waitingPeriods);
		},
	},
};
</script>

<style lang="scss" scoped>
.empty-block {
  min-height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: $info;
}

.fa-exclamation-triangle {
  position: absolute;
  top: -8px;
  left: -11px;
  font-size: 24px;
  color: var(--backgroundDanger);
}

.danger {
  color: var(--backgroundDanger);
}

.light {
  color: $light;
}
</style>
