<template>
	<aq-panel-wrapper>
		<div
			class="panel-container"
		>
			<interaction-content
				v-if="interactionContent"
				:preview-content="interactionContent"
				@close="onCloseContent"
				@resend-comms="onResendComms"
			/>
			<div class="panel">
				<div class="h-100 d-flex flex-column">
					<div class="section-header d-flex">
						<div class="section-header__title flex-grow-1">
							History
						</div>
						<button
							class="btn btn-row"
							@click="hidePanel"
						>
							Close
						</button>
					</div>
					<aq-tab-selector
						:items="tabItems"
						:active-tab="activeTab"
						@select-tab="onSelectTab"
						class="flex-grow-1 d-flex flex-column"
					>
						<interactions
							class="flex-grow-1"
							v-if="isInteractionsActiveTab"
							@preview-interaction="onPreviewInteraction"
						/>
						<notes
							class="flex-grow-1"
							v-else-if="isNotesActiveTab"
							@alert-notes-changed="$emit('on-alert-notes-changed', $event)"
						/>
					</aq-tab-selector>
				</div>
			</div>
		</div>
	</aq-panel-wrapper>
</template>

<script>

import { mapState } from 'vuex';
import Interactions from '@commonLayout/interactions/Interactions';
import Notes from '@commonLayout/Notes';
import InteractionContent from '@commonLayout/interactions/InteractionContent/InteractionContent';
import eventBus from '@commonServices/eventBus';
import { HistoryPanelEnum, HistoryPanelEnumMap } from '@commonServices/models/HistoryPanelEnum';
import CommsService from '@commonServices/commsService';

export default {
	components: {
		Interactions,
		InteractionContent,
		Notes,
	},
	data () {
		return {
			tabItems: [],
			activeTab: {},
			interactionContent: null,
			interactionsTab: HistoryPanelEnumMap[HistoryPanelEnum.Interactions],
			notesTab: HistoryPanelEnumMap[HistoryPanelEnum.Notes],
		};
	},
	props: {
		initialTab: {
			type: String,
			default: HistoryPanelEnumMap[HistoryPanelEnum.Interactions],
		},
	},
	mounted () {
		this.tabItems = [{
			value: this.interactionsTab,
			label: 'Interactions',
			active: true,
		}];
		if (this.$can.ViewNotes) {
			this.tabItems.push({
				value: this.notesTab,
				label: 'Notes',
				active: false,
			});
		}
		this.onSelectTab(this.initialTab);
	},
	computed: {
		...mapState(['levelFilter']),
		HistoryPanelEnum: () => HistoryPanelEnum,
		isNotesActiveTab () {
			return this.activeTab.value === this.notesTab;
		},
		isInteractionsActiveTab () {
			return this.activeTab.value === this.interactionsTab;
		},
		isClaimContext () {
			return !!this.levelFilter.claimId;
		},
	},
	methods: {
		hidePanel () {
			this.$emit('hide-panel');
		},
		onSelectTab (activeTab) {
			this.activeTab = this.tabItems.find(item => item.value === activeTab);
		},
		onCloseContent () {
			this.interactionContent = null;
		},
		onPreviewInteraction (previewContent) {
			this.interactionContent = previewContent;
		},
		onResendComms () {
			CommsService.resendCommunication(this.interactionContent.id);
		},
	},
	beforeDestroy () {
		if (this.isClaimContext && this.isNotesActiveTab) {
			eventBus.$emit('notes-updated');
		}
	},
};
</script>

<style lang="scss" scoped>
  .panel-container {
    animation: slide 0.5s forwards;
  }

  .btn {
    width: inherit;
  }
</style>
