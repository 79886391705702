<template>
	<div
		class="d-flex aq-text-area px-20 py-10"
		:class="{ 'flex-column': !inlineDescription }"
	>
		<div
			class="title"
			:class="{ 'd-inline-block': inlineDescription }"
		>
			{{ title }}
		</div>
		<div
			class="d-inline-block"
			v-if="inlineDescription"
		>
			&nbsp;
		</div>
		<div :class="{ 'd-inline-block': inlineDescription }">
			<slot>
				{{ description }}
			</slot>
		</div>
		<slot name="bullet-points">
			<ul>
				<li
					v-for="bulletPoint of bulletPointDescription"
					:key="bulletPoint"
				>
					{{ bulletPoint }}
				</li>
			</ul>
		</slot>
	</div>
</template>

<script>
export default {
	name: 'AqTextArea',
	props: {
		title: {
			type: [String, Number],
			required: true,
		},
		description: {
			type: [String, Number],
			required: false,
		},
		bulletPointDescription: {
			type: Array,
			required: false,
		},
		inlineDescription: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.aq-text-area {
  background: var(--cardIconBg);
}

.title {
  color: $primary;

  &:not(.d-inline-block) {
    font-size: 13px;
    margin-bottom: -2px;
  }
}

li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
