<template>
	<div
		class="position-relative"
		:x-placement="position"
	>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		position: {
			type: String,
			required: false,
			default: 'right',
		},
	},
};
</script>
