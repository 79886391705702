import { CareIconCare, CareIconFraud, CareIconRisk, CareIconVip, CareIconStaff, CareIconBlockAuto } from '@assets/icons';
import { hasFlag } from '@commonServices/utils/general';

const CareFlagEnum = Object.freeze({
	Care: 1,
	Staff: 2,
	VIP: 4,
	Risk: 8,
	Fraud: 16,
	BlockAuto: 32,
});

const CareFlags = Object.entries(CareFlagEnum)
	.map(([key, value]) => {
		return {
			id: value,
			description: key,
		};
	});

const CareFlagEnumLabel = Object.freeze({
	[CareFlagEnum.Care]: 'Care',
	[CareFlagEnum.Staff]: 'Staff',
	[CareFlagEnum.VIP]: 'VIP',
	[CareFlagEnum.Risk]: 'Risk',
	[CareFlagEnum.Fraud]: 'Fraud',
	[CareFlagEnum.BlockAuto]: 'Block Auto',
});

const CareFlagEnumIcon = Object.freeze({
	[CareFlagEnum.Care]: CareIconCare,
	[CareFlagEnum.Staff]: CareIconStaff,
	[CareFlagEnum.VIP]: CareIconVip,
	[CareFlagEnum.Risk]: CareIconRisk,
	[CareFlagEnum.Fraud]: CareIconFraud,
	[CareFlagEnum.BlockAuto]: CareIconBlockAuto,
});

const getCareFlags = (careFlag) => {
	return careFlag > 0 ? Object.values(CareFlagEnum).filter(key => hasFlag(careFlag, key)) : [];
};

const getCareFlagLabels = (careFlag) => {
	return careFlag > 0 ? Object.entries(CareFlagEnumLabel).filter(([key]) => hasFlag(careFlag, parseInt(key))).map(([key, value]) => value) : [];
};

export { CareFlagEnum, CareFlagEnumLabel, CareFlagEnumIcon, CareFlags, getCareFlags, getCareFlagLabels };
