const statusCode = Object.freeze({
	BadRequest: 400,
	Unauthorized: 401,
	Forbidden: 403,
	NotFound: 404,
	MethodNotAllowed: 405,
	UnsupportedMediaType: 415,
	InternalServerError: 500,
});

export default statusCode;
