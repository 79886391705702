<template>
	<div class="summary">
		<span class="text-white">Number of Tasks:</span> <span class="full-count">{{ tasks.length }}</span>
		<span class="text-white">Types:</span>
		<span class="items">
			{{ workQueueTypeTitle }}
		</span>
	</div>
</template>

<script>
import { WorkQueueDisplayType } from '@commonServices/models/WorkQueueType';

export default {
	name: 'TaskSummary',
	props: {
		tasks: {
			type: Array,
			required: true,
		},
	},
	data () {
		return {
			workQueueDisplayType: WorkQueueDisplayType,
		};
	},
	computed: {
		workQueueTypeTitle () {
			return this.tasks.map(d => d.workQueueType)
				.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])
				.map(t => `${this.workQueueDisplayType[t]} ${this.tasks.filter(d => d.workQueueType === t).length}`)
				.join(', ');
		},
	},
};
</script>

<style lang="scss" scoped>
  .summary {
    font-size: 26px;
    font-weight: bold;
    margin: 15px 35px;
    padding: 10px 35px;
    background-color: var(--searchBtnBg);
  }

  .full-count {
    color: var(--cardIconColour);
    margin-right: 50px;
  }

  .items {
    color: var(--subSectionColour);
    font-weight: normal;
  }
</style>
