<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Copy and split multiple treatment lines"
			modal-width="1400"
		>
			<div class="modal-content w-100">
				<div class="treatment-lines-container p-30">
					<div
						v-for="(lineGroup, groupIndex) of lineGroups"
						:key="groupIndex"
						class="treatment-line-group mb-5"
						:data-qa="`splitLinesModal_container${currentLineGroupIndex === groupIndex ? '_active' : ''}`"
					>
						<div
							class="treatment-line-header pl-10 position-relative"
							:class="[currentLineGroupIndex === groupIndex ? 'expanded': 'collapsed', lineGroups.length === 1 && 'single-line']"
							@click="onLineGroupClick(groupIndex)"
							data-qa="splitLinesModal_header"
						>
							<span>
								{{ `${lineGroup.originalLine.quantity.value} x ${lineGroup.originalLine.description.value}` }}
								<template v-if="lineGroup.lineItemCopies.length > 1 && currentLineGroupIndex !== groupIndex">
									-
									<span class="number-circle mx-3">{{ lineGroup.lineItemCopies.length - 1 }}</span> {{ 'copy' | pluralize(lineGroup.lineItemCopies.length - 1) }}
								</template>
							</span>
							<div
								v-if="lineGroups.length > 1"
								class="treatment-line-header-toggler"
								data-qa="splitLinesModal_header-toggler"
							>
								<i :class="['fas', currentLineGroupIndex === groupIndex ? 'fa-angle-down' : 'fa-angle-right']" />
							</div>
						</div>
						<div
							v-if="currentLineGroupIndex === groupIndex"
							class="no-gutters my-20"
						>
							<div
								class="row d-flex align-items-center mb-10"
								data-qa="splitLinesModal_copy-options"
							>
								<span class="text-bold ml-15 pr-0">Enter number of copies (max 3):</span>
								<aq-max-min-number-input
									data-qa="splitLinesModal_input_copiesNumber"
									class="mx-10"
									:min-number="0"
									:max-number="3"
									input-class="control-container copies-number-input"
									v-model="lineGroup.copiesNumber"
								/>
								<div class="col-2">
									<aq-checkbox
										data-qa="splitLinesModal_checkbox_splitAmountsEqually"
										label="Split amounts equally"
										v-model="lineGroup.splitAmountsEqually"
									/>
								</div>
								<button
									type="button"
									@click="onCopy(lineGroup)"
									class="btn btn-secondary"
								>
									Enter
								</button>
							</div>
							<div v-if="lineGroup.lineItemCopies.length">
								<div
									v-for="(lineItemCopy, index) of lineGroup.lineItemCopies"
									:key="index"
									:class="[ index && index === hoveredLineItemIndex ? 'border-treatment-line' : 'border-invisible', !hasCopiesCreated(lineGroup) ? 'transparent' : '']"
									class="d-flex align-items-center row p-0 mt-2 mb-5 ml-1 mr-1 treatment-line"
									@mouseover="hoveredLineItemIndex = index"
									@mouseleave="hoveredLineItemIndex = null"
									:data-qa="`splitLinesModal_line_${ index === 0 ? 'original' : 'copy' }`"
								>
									<aq-read-only-input
										data-qa="splitLinesModal_readonly_parent"
										class="col-2 mr-10"
										label="Parent"
										:description="getParent(lineItemCopy)"
									/>
									<aq-select
										data-qa="splitLinesModal_select_ailment"
										class="col-2 mr-10"
										:options="conditions"
										label="Ailment"
										option-label="description"
										track-by="id"
										searchable
										:is-valid="!$v.lineGroups.$each.$iter[groupIndex].lineItemCopies.$each.$iter[index].claimCondition.value.$error"
										v-model="lineItemCopy.claimCondition.value"
										no-result-message="No Ailment found"
										@input="onSelectAilment(groupIndex, index)"
										:disabled="!hasCopiesCreated(lineGroup)"
									/>
									<aq-select
										data-qa="splitLinesModal_select_policySection"
										class="col-2 mr-10"
										:options="conditionPolicySections(lineItemCopy)"
										label="Policy Section"
										option-label="description"
										track-by="id"
										searchable
										:is-valid="!$v.lineGroups.$each.$iter[groupIndex].lineItemCopies.$each.$iter[index].policySection.value.$error"
										v-model="lineItemCopy.policySection.value"
										no-result-message="No Policy Section found"
										@input="$v.lineGroups.$each.$iter[groupIndex].lineItemCopies.$each.$iter[index].policySection.value.$touch"
										:disabled="!hasCopiesCreated(lineGroup)"
									/>
									<aq-select
										data-qa="splitLinesModal_select_deductions"
										:options="conditionDeductionTypes(lineItemCopy)"
										class="col-2 mr-10 option-fit-content"
										label="User Deduction"
										option-label="description"
										track-by="id"
										multiple
										hide-tags
										v-model="lineItemCopy.selectedUserDeductions"
										no-result-message="No User Deduction found"
										:disabled="!hasCopiesCreated(lineGroup)"
									/>
									<aq-form-input
										data-qa="splitLinesModal_input_tax"
										class="col-1 mr-10"
										label="Tax"
										icon-class="text-primary fa-lg"
										type="money"
										:locale="locale"
										v-model="lineItemCopy.tax.value"
										:disabled="!hasCopiesCreated(lineGroup)"
									/>
									<aq-form-input
										data-qa="splitLinesModal_input_discount"
										class="col-1 mr-10"
										label="Discount"
										icon-class="text-primary fa-lg"
										type="money"
										:locale="locale"
										v-model="lineItemCopy.discountAmount.value"
										:disabled="!hasCopiesCreated(lineGroup)"
									/>
									<div class="col px-0">
										<aq-form-input
											data-qa="splitLinesModal_input_amount"
											label="Amount"
											:class="{'border-deduction': lineItemCopy.selectedUserDeductions.length}"
											icon-class="text-primary fa-lg"
											type="money"
											:locale="locale"
											v-model="lineItemCopy.amount.value"
											:disabled="!hasCopiesCreated(lineGroup)"
										/>
										<div
											data-qa="splitLinesModal_deductions-icon"
											v-if="lineItemCopy.selectedUserDeductions.length"
											class="deductions"
											v-tooltip="{
												content: lineItemCopy.selectedUserDeductions.map(d => d.description).join(', '),
												classes: [ 'tooltip-red' ]
											}"
										>
											D
										</div>
									</div>
									<div
										data-qa="splitLinesModal_button_remove"
										v-if="index && index === hoveredLineItemIndex"
										class="circle-btn yellow d-flex align-items-center"
										@click="onDelete(lineGroup, index)"
										v-tooltip="{
											content: 'Delete Item',
											classes: [ 'tooltip-orange' ]
										}"
									>
										<i class="aqv-delete m-auto" />
									</div>
									<div
										v-if="index === 0 && hasCopiesCreated(lineGroup)"
										class="w-100 mt-10 dashed-line"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="splitLinesModal_button_cancel"
						type="button"
						@click="onCancel"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="splitLinesModal_button_create"
						type="button"
						@click="onSave"
						:disabled="!anyCopyCreated || $v.$invalid"
						class="btn btn-success ml-auto"
					>
						Create
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import AqMaxMinNumberInput from '@commonView/Shared/AqMaxMinNumberInput';
import cloneDeep from 'lodash.clonedeep';
import { required } from 'vuelidate/lib/validators';

export default {
	components: {
		AqMaxMinNumberInput,
	},
	data () {
		return {
			lineGroups: [],
			hoveredLineItemIndex: null,
			currentLineGroupIndex: 0,
		};
	},
	props: {
		selectedLineItems: {
			type: Array,
			required: true,
		},
		policySections: {
			type: Array,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		deductions: {
			type: Array,
			required: true,
		},
	},
	validations () {
		return {
			lineGroups: {
				$each: {
					lineItemCopies: {
						$each: {
							claimCondition: {
								value: {
									required,
								},
							},
							policySection: {
								value: {
									required,
								},
							},
						},
					},
				},
			},
		};
	},
	mounted () {
		this.lineGroups = this.selectedLineItems.map(selectedLineItem => {
			const originalLine = cloneDeep(selectedLineItem);
			originalLine.amount.value = originalLine.amount.value || 0;
			originalLine.discountAmount.value = originalLine.discountAmount.value || 0;
			originalLine.tax.value = originalLine.tax.value || 0;
			originalLine.selectedUserDeductions = originalLine.deductions?.map((deduction) =>
				({
					id: deduction.value.id,
					description: deduction.value.value,
				})) ?? [];

			return {
				originalLine,
				lineItemCopies: [originalLine],
				copiesNumber: 0,
				splitAmountsEqually: false,
			};
		});
	},
	computed: {
		anyCopyCreated () {
			return this.lineGroups.some(group => group.lineItemCopies.length > 1);
		},
	},
	methods: {
		conditionPolicySections (lineItem) {
			return this.policySections.filter(ps => ps.claimConditionId === lineItem.claimCondition?.value?.id);
		},
		conditionDeductionTypes (lineItem) {
			return this.deductions.filter(d => d.claimConditionId === lineItem.claimCondition?.value?.id && !d.isSystem);
		},
		getParent (line) {
			const lineCondition = this.conditions.find(c => c.id === line.claimCondition?.value?.id);
			return lineCondition?.parentConditionAilment || 'No Parent';
		},
		onSave () {
			const lineGroupCopies = this.lineGroups.filter(lineGroup => lineGroup.lineItemCopies.length > 1)
				.map(lineGroup => lineGroup.lineItemCopies.map(({ selectedUserDeductions, ...lineItemCopy }) => ({
					...lineItemCopy,
					deductions: (selectedUserDeductions ?? []).map((deduction, index) =>
						({
							value: {
								id: deduction.id,
								value: deduction.description,
								amount: index === 0 ? lineItemCopy.amount.value : 0,
							},
						}),
					),
				})),
				);

			this.$emit('create-line-item-copies', lineGroupCopies);
			this.$modal.hide(this.$parent.name);
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onDelete (lineGroup, index) {
			lineGroup.lineItemCopies.splice(index, 1);
			lineGroup.copiesNumber--;
		},
		onCopy (linesGroup) {
			const totalCopiesCount = linesGroup.copiesNumber + 1; // Including Original
			const originalLine = linesGroup.originalLine;

			const copies = new Array(totalCopiesCount);

			const amounts = splitAmount(originalLine.amount.value);
			const discountAmounts = splitAmount(originalLine.discountAmount.value);
			const taxAmounts = splitAmount(originalLine.tax.value);

			for (let i = 0; i < totalCopiesCount; i++) {
				copies[i] = cloneDeep(originalLine);
				copies[i].id = i === 0 ? originalLine.id : 0;
				copies[i].parentId = i === 0 ? null : originalLine.id;
				copies[i].amount.value = amounts[i];
				copies[i].discountAmount.value = discountAmounts[i];
				copies[i].tax.value = taxAmounts[i];
			}

			linesGroup.lineItemCopies = copies;

			function splitAmount (originalAmount) {
				const amounts = new Array(totalCopiesCount).fill(originalAmount);

				if (linesGroup.splitAmountsEqually) {
					const amount = originalAmount / totalCopiesCount;
					const splitCopy = Math.floor(amount * 100) / 100;
					const calculatedTotal = splitCopy * totalCopiesCount;
					// Find remainder and add it to the splitCopy amount so that the sum of all amounts is equal to the original amount.
					const splitOriginal = splitCopy + originalAmount - calculatedTotal;
					amounts.fill(splitCopy);
					amounts[0] = splitOriginal;
				}

				return amounts;
			}
		},
		onSelectAilment (groupIndex, index) {
			this.$v.lineGroups.$each.$iter[groupIndex].lineItemCopies.$each.$iter[index].claimCondition.value.$touch();
			this.$v.lineGroups.$each.$iter[groupIndex].lineItemCopies.$each.$iter[index].policySection.value.$model = null;
		},
		hasCopiesCreated (group) {
			return group.lineItemCopies.length > 1;
		},
		async onLineGroupClick (groupIndex) {
			if (this.lineGroups.length === 1) return;
			this.currentLineGroupIndex = this.currentLineGroupIndex === groupIndex ? null : groupIndex;
			await this.$nextTick();
			this.$el.querySelector(`.treatment-line-group:nth-child(${groupIndex + 1})`).scrollIntoView({ block: 'start', behavior: 'smooth' });
		},
	},
};
</script>

<style lang="scss" scoped>
.treatment-lines-container {
  min-height: 400px;
  max-height: 600px;
  overflow-y: auto;
}

.treatment-line-header {
  display: flex;
  align-items: center;
  background-color: #004870;
  color: $body-color;
  height: 40px;
  border: 2px solid transparent;

  &:not(.single-line):hover {
    cursor: pointer;
  }

  .treatment-line-header-toggler {
    position: absolute;
    right: 10px;
  }

  &.expanded {
    border: 2px solid $warning;
  }
}

.number-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 2px solid $body-color;
  color: $body-color;
  text-align: center;
}

.dashed-line {
  border-bottom: 3px dashed #d7f0ff;
}

.treatment-line {
  position: relative;
}

.border-treatment-line {
  border: 1px solid $warning;
}

.border-invisible {
  border: 1px solid transparent;
}

.circle-btn {
  position: absolute;
  right: -17px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 31px;
  cursor: pointer;

  &.yellow {
    color: #ffff;
    background-color: $warning;

    &:hover {
      color: #ffff;
      background-color: $warning-d-20;
    }
  }
}

::v-deep .control-container {
  padding-right: 10px;
  padding-left: 10px;

  .label {
    left: 10px;
  }

  .locked {
    display: none;
  }

  &.disabled {
    &::before {
      content: none;
    }
  }
}

::v-deep .copies-number-input {
  width: 55px;
  min-height: 38px;
  border: none;
  outline: none;
  resize: none;
  cursor: text;
}

.horizontal-rule {
  border-bottom: 3px solid #d7f0ff;
}

.transparent {
  opacity: 0.71;
}

.deductions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: $danger;
  border-radius: 10px;
  color: #fff;
  padding-left: 5px;
  padding-top: 2px;
}

.border-deduction {
  border: 1px solid $danger;
}

.option-fit-content {
  ::v-deep .multiselect__content-wrapper {
    width: fit-content;
    max-width: 500px;
  }
}
</style>
