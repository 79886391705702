<template>
	<div
		class="form-category-container pb-30"
		data-qa="enterBasic_container_basic"
	>
		<div class="status-line d-flex flex-column">
			<div v-if="invalid">
				<i class="fas fa-times-circle fa-lg text-danger" />
			</div>
			<div v-else>
				<i class="fas fa-check-circle fa-lg text-primary" />
			</div>
			<div :class="['bottom-line flex-fill', invalid ? 'bg-danger': 'bg-primary']" />
		</div>
		<div class="section-header pl-45">
			<div class="section-header__title">
				{{ title }}
			</div>
			<p v-if="subTitle">
				{{ subTitle }}
			</p>
		</div>
		<div class="section-content ml-25">
			<slot />
		</div>
	</div>
</template>

<script>

export default {
	name: 'ClaimEnterFormSection',
	props: {
		invalid: {
			type: Boolean,
			required: false,
			default: false,
		},
		title: {
			type: String,
			required: false,
			default: '',
		},
		subTitle: {
			type: String,
			required: false,
			default: '',
		},
	},
};
</script>

<style lang="scss">
.form-category-container {
  position: relative;
  padding-right: 25px;

  .status-line {
    z-index: 10;
    position: absolute;
    top: 23px;
    bottom: 0;
    left: 15px;
    height: 101%;

    .fas {
      line-height: 1;

      &.fa-check-circle,
      &.fa-times-circle {
        font-size: 1em;

        &::after {
          content: "";
          position: absolute;
          background: #fff;
          left: 6px;
          top: 4px;
          width: 8px;
          height: 7px;
          z-index: -1;
        }

        &::before {
          left: 3px;
          position: absolute;
          top: 1px;
        }
      }
    }

    .bottom-line {
      width: 2px;
      margin-left: 9px;
      margin-top: -1px;
      transition: 0.2s ease;
    }
  }
}
</style>
