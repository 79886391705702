<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="">
						<div class="pb-12">
							{{ description }}
						</div>
						<div class="pb-12">
							<aq-form-input
								:label="`${label} Name`"
								type="text"
								v-model="name"
								@input="onTouch"
							/>
							<aq-form-input-error
								class="mt-8"
								:error-messages="this.errorMessages"
								:validator="$v"
								property="name"
							/>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onSave"
						:disabled="$v.$invalid"
						type="button"
						class="btn btn-success ml-auto"
					>
						Save
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>

import { maxLength, required } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { alphaNumAndSpace } from '@commonServices/utils/validators';

const getErrorMessages = (label) => ({
	name: {
		required: ValidationMessages.required,
		maxLength: ValidationMessages.maxLength100,
		isUniq: `${label} with such name already exists`,
		alphaNumAndSpace: 'The field can have digital and letter only',
		reservedNames: 'This name is reserved by the system',
	},
});

export default {
	name: 'SaveFiltersModal',
	props: {
		isNameUniq: {
			type: Boolean,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: null,
		},
		reservedNames: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	data () {
		return {
			name: this.value,
			errorMessages: getErrorMessages(this.label),
		};
	},
	validations () {
		return {
			name: {
				required,
				maxLength: maxLength(100),
				alphaNumAndSpace,
				isUniq: () => this.isNameUniq,
				reservedNames: (value) => !this.reservedNames.includes(value.toLowerCase()),
			},
		};
	},
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onSave () {
			this.$emit('save', this.name);
		},
		onTouch () {
			this.$v.name.$touch();
			this.$emit('touch');
		},
	},
};
</script>
