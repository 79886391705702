<template>
	<div class="flex-fill">
		<div>
			<aq-scan-form-select
				label="Ailment"
				:value="searchValue"
				:class="[{ 'bg-danger-darken': false },'attribute']"
				:loading="isAilmentLoading"
				@search-async="searchAilmentsDebounce"
				@focused="$emit('focused', recognizedAilment)"
				:disabled="isReadonly"
			/>
			<div v-if="!changedAilment">
				<div class="font-weight-bold py-14">
					Found {{ ailments.length }} matching Ailments
				</div>
				<div v-if="ailments.length > 0">
					<div class="row no-gutters overflow-scroll">
						<div
							v-for="(ailment, index) in ailments"
							:key="index"
							@click="selectAilment(ailment)"
							class="search-item mb-10 px-25 py-14"
							:class="selectedAilment && selectedAilment.id === ailment.id ? 'selected' : ''"
						>
							<div class="cause pb-4">
								{{ ailment.firstCause }}
							</div>
							<p>{{ ailment.secondCause }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash.clonedeep';
import ClaimService from '@commonServices/claimService';

export default {
	name: 'ClaimDiagnosisScan',
	data () {
		return {
			ailments: [],
			selectedAilment: null,
			isAilmentLoading: false,
			searchValue: null,
			isLoaded: false,
			changedAilment: true,
		};
	},
	props: {
		recognizedAilment: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	async mounted () {
		if (this.recognizedAilment) {
			this.searchValue = this.recognizedAilment.value ? this.recognizedAilment.value : this.recognizedAilment.originalValue;
		}
		await this.searchAilmentsAsync(this.searchValue);
		if (this.recognizedAilment.id) {
			this.selectAilment(this.ailments.find(ailment => ailment.id === this.recognizedAilment.id));
		} else {
			this.selectAilment(this.ailments.find(ailment => ailment.firstCause === this.searchValue));
		}
		this.isLoaded = true;
	},
	methods: {
		searchAilmentsDebounce: debounce(async function (term) {
			await this.searchAilmentsAsync(term);
		}, 500),
		async searchAilmentsAsync (term) {
			this.searchValue = term;
			this.isAilmentLoading = true;
			if (term) {
				this.ailments = (await ClaimService.getAilments(term));
				this.isAilmentLoading = false;
				this.changedAilment = false;
			}
		},
		selectAilment (ailment) {
			if (this.isReadonly || !ailment) {
				return;
			}

			if (this.selectedAilment?.id === ailment?.id) {
				this.selectedAilment = null;
			} else {
				this.changedAilment = true;
				this.selectedAilment = ailment;
				this.searchValue = ailment.firstCause;
			}

			this.$emit('ailment-selected', cloneDeep(this.selectedAilment));
		},
	},
	watch: {
		ailments (newValue) {
			if (this.selectedAilment) {
				if (!newValue || !newValue.some(x => x.id === this.selectedAilment.id)) {
					this.selectedAilment = null;
				}
			}
		},
		selectedAilment () {
			this.$emit('changed');
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-danger-darken {
  color: $body-color;
}

.search-item {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease;

  &:last-child {
    border: none;
  }

  &:hover,
  &.selected {
    p {
      color: $body-color;
    }
  }

  &:hover {
    background: $primary;
  }

  &.selected {
    background: $active-color;
  }
}

.overflow-scroll {
  overflow-y: scroll;
  max-height: 300px;
}
</style>
