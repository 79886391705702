<template>
	<div class="h-100 d-flex flex-column">
		<div class="section-content flex-grow-1">
			<div class="container-fluid">
				<aq-form-input
					type="textarea"
					data-qa="alertForm_input_alert"
					label="Alert"
					v-model="alert.text"
					:number-visible-lines="10"
					@input="$v.alert.text.$touch"
					:is-valid="!$v.alert.text.$error"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="$v.alert"
					property="text"
				/>
			</div>
		</div>
		<div class="section-footer">
			<div class="container-fluid">
				<div class="d-flex text-right">
					<button
						class="btn btn-row mr-10 ml-auto"
						@click="onCancel"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						:disabled="$v.$invalid"
						@click="onSave()"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { mapGetters, mapActions } from 'vuex';

const ALERT_MAX_SIZE = 500;

export default {
	name: 'VetAlertForm',
	data () {
		return {
			saveDraft: true,
			draftKey: `vetId_${this.vetId}`,
			draftName: 'vetAlerts',
			alert: {
				text: null,
			},
		};
	},
	props: {
		vetId: {
			type: Number,
			required: true,
		},
	},
	mounted () {
		const draftAlert = this.getDraftData(this.draftName, this.draftKey);
		if (draftAlert) {
			this.alert.text = draftAlert;
		}
	},
	beforeDestroy () {
		if (this.saveDraft) {
			this.addDraftData({ draftName: this.draftName, key: this.draftKey, data: this.alert.text });
		}
	},
	computed: {
		...mapGetters(['getDraftData']),
		errorMessages () {
			return {
				text: {
					required: ValidationMessages.required,
					maxLength: ValidationMessages.maxLength(ALERT_MAX_SIZE),
				},
			};
		},
	},
	validations () {
		return {
			alert: {
				text: {
					required,
					maxLength: maxLength(ALERT_MAX_SIZE),
				},
			},
		};
	},
	methods: {
		...mapActions(['addDraftData', 'clearDraftData']),
		onCancel () {
			this.clearDraft();
			this.$emit('cancel');
		},
		onSave () {
			if (!this.$v.$invalid) {
				this.clearDraft();
				this.$emit('save', this.alert);
			}
		},
		clearDraft () {
			this.clearDraftData({ draftName: this.draftName, key: this.draftKey });
			this.saveDraft = false;
		},
	},

};
</script>
