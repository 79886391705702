import { WorkQueueType } from '@commonServices/models/WorkQueueType';
import WorkflowService from '@commonServices/workflowService';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError } from '@commonServices/utils/general';
import ClaimService from '@commonServices/claimService';
import { outcomeState } from '@commonServices/models/ClaimStatusActionEnum';

const taskRowActionsHandlerMixin = {
	data () {
		return {
			currentWorkQueueType: null,
			outcomeState: outcomeState.Rejected,
		};
	},
	methods: {
		async onRowAction (value, row) {
			this.currentClaimId = row.claimId;
			this.currentWorkQueueType = row.workQueueType;
			this.isReassessment = row.isReassessment;
			switch (value) {
			case 'view':
				this.$router.push({
					name: 'claim-page',
					params: { customerId: row.customerId, claimId: row.claimId },
				});
				return;
			case 'approve':
				if (!(await this.canUserProceedSettledClaim(row))) {
					return;
				}
				await this.onApproveClaim(row);
				break;
			case 'reject':
				this.$modal.show('reject-task');
				break;
			case 'chase':
				await this.onChaseClaim(row);
				break;
			case 'reassess':
				await this.onReassessClaim(row);
				break;
			}
		},
		async canUserProceedSettledClaim () {
			if (this.currentWorkQueueType === WorkQueueType.PaymentApproval) {
				const claimPayment = this.tasks.find(
					(item) => item.claimId === this.currentClaimId,
				);
				if (claimPayment && !claimPayment.canProceedPayment) {
					await this.$refs.proceedClaimPaymentAlert.show();
					return false;
				}
			}
			return true;
		},
		async onApproveClaim () {
			if (!this.currentClaimId) {
				return;
			}

			switch (this.currentWorkQueueType) {
			case WorkQueueType.PaymentApproval:
				await this.tryApproveAndReload(WorkflowService.approveSettledClaim,
					this.currentClaimId, [ErrorCode.ClaimStatusHasBeenChanged]);
				break;
			case WorkQueueType.FraudCheck:
				await this.tryApproveAndReload(WorkflowService.approveFraudCheckClaim,
					this.currentClaimId, [ErrorCode.ClaimStatusHasBeenChanged]);
				break;
			case WorkQueueType.RejectionApproval:
				await this.tryApproveAndReload(WorkflowService.approvePendingClaim,
					this.currentClaimId, [ErrorCode.ClaimStatusHasBeenChanged]);
				break;
			}
		},
		async onRejectClaim (rejectionMessage) {
			if (!this.currentClaimId) {
				return;
			}
			let rejectCallback = async () => {
			};
			switch (this.currentWorkQueueType) {
			case WorkQueueType.PaymentApproval:
				rejectCallback = WorkflowService.rejectSettledClaim;
				break;
			case WorkQueueType.FraudCheck:
				if (this.isReassessment) {
					const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(this.outcomeState);
					if (userChoice === 'no' || userChoice === 'cross') {
						return;
					}
				}
				rejectCallback = WorkflowService.rejectFraudCheckClaim;
				break;
			case WorkQueueType.RejectionApproval:
				rejectCallback = WorkflowService.rejectPendingClaim;
				break;
			}
			try {
				await rejectCallback(this.currentClaimId, rejectionMessage, this.selectedReassessmentOutcome?.id, [
					ErrorCode.ClaimStatusHasBeenChanged,
				]);
				this.$modal.hide('reject-task');
				this.currentClaimId = null;
			} catch (e) {
				if (
					isKnownError(e, ErrorCode.ClaimCannotMovedToStatus)
					&& this.currentWorkQueueType === WorkQueueType.FraudCheck
				) {
					return;
				} else if (isKnownError(e, ErrorCode.ClaimStatusHasBeenChanged)) {
					this.$modal.hide('reject-task');
					await this.reloadData();
					return;
				} else {
					throw e;
				}
			}

			await this.loadData();
		},
		async onChaseClaim (row) {
			if (!this.currentClaimId) {
				return;
			}

			const userChoice = await this.$refs.confirmChaseModal.show();
			if (userChoice === 'no' || userChoice === 'cross') {
				return;
			}

			try {
				const chaseDetails = await WorkflowService.chase(
					this.currentClaimId,
					row.customerId,
					[ErrorCode.ClaimStatusHasBeenChanged],
				);
				if (chaseDetails) {
					row.chaseCount = chaseDetails.chaseCount;
					row.chased = chaseDetails.chaseDateTime;
					row.chaseLimit = chaseDetails.chaseLimit;
				}
			} catch (e) {
				if (isKnownError(e, ErrorCode.ClaimStatusHasBeenChanged)) {
					await this.reloadData();
				} else {
					throw e;
				}
			}
		},
		async onReassessClaim (row) {
			if (!this.currentClaimId) {
				return;
			}
			try {
				await ClaimService.reassessPendingClaim(this.currentClaimId, [
					ErrorCode.ClaimStatusHasBeenChanged,
				]);
				this.$router.push({
					name: 'claim-page',
					params: { customerId: row.customerId, claimId: this.currentClaimId },
				});
			} catch (e) {
				if (isKnownError(e, ErrorCode.ClaimStatusHasBeenChanged)) {
					await this.reloadData();
				} else {
					throw e;
				}
			}
		},
		onSelectFlags (flags) {
			this.selectedData = flags;
			this.$modal.show('flags-data-modal');
		},
		onSelectPayees (data) {
			this.selectedData = { payees: data.payees, locale: data.brandLocale };
			this.$modal.show('payees-data-modal');
		},
		onSelectMissingInfo (data) {
			this.selectedData = data;
			this.$modal.show('missing-data-modal');
		},
		onCloseModal () {
			this.selectedData = null;
		},
		async tryApproveAndReload (approveCallback, ...callbackParams) {
			try {
				await approveCallback(...callbackParams);
			} catch (e) {
				if (isKnownError(e, ErrorCode.ClaimStatusHasBeenChanged)) {
					await this.reloadData();
					return;
				} else {
					throw e;
				}
			}
			await this.loadData();
		},
	},
};

export default taskRowActionsHandlerMixin;
