<template>
	<aq-tab-selector
		:items="tabItems"
		:active-tab="activeTab"
		@select-tab="onSelectTab"
		v-if="onSelectTab"
		class="flex-grow-1 d-flex flex-column"
	>
		<div class="section-content flex-grow-1">
			<div class="container-fluid h-100 d-flex flex-column">
				<button
					class="btn btn-primary w-100 mb-20"
					@click="onEditCondition(null)"
					v-if="isActiveTab && this.$can.ModifyEligibleCondition"
				>
					Add Eligible Condition
				</button>
				<div
					v-if="filteredConditions.length > 0"
					class="content-wrapper"
				>
					<aq-card
						v-for="condition of filteredConditions"
						:key="condition.id"
						:name="`${condition.condition.firstCause} - ${condition.bodyPart.description}`"
						:description="condition.eligibleConditionTypeDisplay"
						:user="condition.createdBy"
						type="condition"
						:action="getOptionsList()"
						@select-action="onSelectAction(condition.id, $event)"
						class="mb-10 eligible-condition-card"
						icon-class="eligible-icon"
					>
						<template slot="subName">
							<p
								class="fs-11 my-3"
								v-tooltip="getSubNameTooltip(condition)"
							>
								{{ getSubName(condition) }}
							</p>
							<p class="fs-11 my-3">
								Assessment Date {{ getAssessmentDate(condition) }}
							</p>
						</template>
						<template slot="content">
							<div
								class="bottom mt-2 py-15 px-20"
								v-if="condition.vet"
							>
								<div class="fs-14">
									<strong>Vet Details</strong>
								</div>
								<span class="fs-13">
									{{ condition.vet.practiceName }}
								</span>
								<div
									class="mt-10"
									v-show="!!showMoreList[condition.id]"
								>
									<vet-details-info :vet-info="condition.vet" />
								</div>
								<hr class="mt-15">
								<aq-more-less-toggler
									:opened="!!showMoreList[condition.id]"
									@show-more-less="onUpdateShowMoreList(condition.id, $event)"
								/>
							</div>
							<div
								class="bottom mt-2 py-15 px-20"
								v-if="condition.relatedConditions.length > 0"
							>
								<div class="fs-14">
									<strong>Related Conditions</strong>
								</div>
								<span
									class="fs-13"
									v-for="(relatedCondition, index) of condition.relatedConditions"
									:key="`${relatedCondition.condition.id}${relatedCondition.bodyPart.id}`"
									v-tooltip="condition.relatedConditions"
								>
									{{ relatedCondition.condition.firstCause }} - {{ relatedCondition.bodyPart.description }}<span v-if="index !== condition.relatedConditions.length -1">,</span>
								</span>
							</div>
							<div
								v-if="condition.comments"
								class="bottom mt-2 py-10 px-20 d-flex align-items-center"
							>
								<aq-more-less-toggle
									:text="condition.comments"
								/>
							</div>
						</template>
					</aq-card>
				</div>
				<div
					v-else
					class="text-center my-20"
				>
					<p v-if="isActiveTab">
						There are no Eligible conditions recorded for this pet
					</p>
					<p v-if="isArchivedTab">
						There are no Archived conditions recorded for this pet
					</p>
				</div>
			</div>
		</div>
	</aq-tab-selector>
</template>
<script>
import { shortDate } from '@commonServices/utils/filters';
import PetService from '@commonServices/petService';
import diagnosisConditionsMixin from '@mixins/diagnosisConditionsMixin';

export default {
	mixins: [diagnosisConditionsMixin],
	methods: {
		getOptionsList () {
			const options = [];
			if (this.$can.ModifyEligibleCondition) {
				options.push({ name: 'Edit', value: 'edit', iconClass: 'fa-edit' });
			}
			if (this.isActiveTab && this.$can.ArchiveEligibleCondition) {
				options.push({ name: 'Archive', value: 'archive', iconClass: 'fa-archive' });
			} else if (this.isArchivedTab && this.$can.ArchiveEligibleCondition) {
				options.push({ name: 'Unarchive', value: 'un-archive', iconClass: 'fa-archive' });
			}
			return options;
		},
		async onSelectAction (id, action) {
			switch (action) {
			case 'archive': {
				const result = await PetService.archiveEligibleCondition(id);
				const archivedCondition = this.conditions.find(cond => cond.id === id);
				archivedCondition.archived = result.archived;
				archivedCondition.updatedDate = result.updatedDate;
				archivedCondition.updatedBy = this.currentUser;
				this.activeTab = this.tabItems[1];
				break;
			}
			case 'edit': {
				const condition = this.conditions.find(item => item.id === id);
				this.onEditCondition(condition);
				break;
			}
			case 'un-archive': {
				const condition = this.conditions.find(item => item.id === id);
				const isDuplicate = this.isDuplicateCondition(condition);
				if (isDuplicate) {
					this.$emit('duplication-error', 'Current pet already contains active Eligible Condition with such eligible condition and eligible condition type.');
					return;
				}
				const result = await PetService.unarchiveEligibleCondition(id);
				const archivedCondition = this.conditions.find(cond => cond.id === id);
				archivedCondition.archived = result.archived;
				archivedCondition.updatedDate = result.updatedDate;
				archivedCondition.updatedBy = this.currentUser;
				this.activeTab = this.tabItems[0];
				break;
			}
			}
		},
		onEditCondition (condition) {
			if (condition) {
				this.selectedCondition = condition;
			} else {
				this.selectedCondition = {
					id: null,
					condition: null,
					bodyPart: null,
					eligibleConditionType: null,
					createdBy: {},
					comments: null,
					relatedConditions: [],
					updatedDate: null,
				};
			}
			this.$emit('edit-condition', this.selectedCondition);
		},
		isDuplicateCondition (condition) {
			return this.conditions
				.some(cond => !cond.archived
					&& cond.eligibleConditionType === condition.eligibleConditionType
					&& cond.bodyPart.id === condition.bodyPart.id
					&& cond.condition.id === condition.condition.id,
				);
		},
		getAssessmentDate (condition) {
			return condition.assessmentDate ? shortDate(condition.assessmentDate) : '-';
		},
	},
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding-right: 15px;
}

::v-deep .card-icon.eligible-icon {
  color: var(--infoColor);
}
</style>
