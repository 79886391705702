<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="py-20 px-5 scrollbar">
					<div class="container-fluid pt-10">
						<div
							class="row no-gutters mb-10 p-10 missing-item"
							:class="{ 'resolved': item.resolved }"
							v-for="(item, index) in data"
							:key="index"
						>
							<div class="col-7 d-flex align-items-center p-10 list-item--bg">
								<i class="aqv-description row-icon" />
								{{ item.missingInfoDescription }}
							</div>
							<div class="col-5 d-flex align-items-center p-10 list-item--bg">
								<i :class="[item.contactType === 'Customer' ? 'aqv-customer' : 'aqv-vet', 'row-icon']" />
								{{ item.contactName }}
							</div>
							<div
								class="col-12 mt-2 p-10 list-item--bg"
								v-if="item.missingInfoComment"
							>
								<div class="d-flex">
									<i class="aqv-comments mr-10 fs-20" />
									<aq-comment
										:data="item.missingInfoComment"
										:line-clamp="1"
										class="toggler-below"
									/>
								</div>
							</div>
							<i
								v-if="item.resolved"
								class="aqv-circle-thumbs-up missing-icon"
								v-tooltip="'Resolved'"
							/>
						</div>
					</div>
					<div
						v-if="comment"
						class="mt-10 container-fluid"
					>
						<div class="section-header">
							<div class="section-header__title fs-14">
								Returning Commentary
							</div>
						</div>
						<div class="section-content list-item--bg px-20">
							<aq-more-less-toggle
								:text="comment"
							/>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Array,
			required: true,
		},
		comment: {
			type: String,
			required: false,
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-content {
  min-width: 600px;

  .missing-item {
    position: relative;
    background-color: rgba(var(--attributeBgRgb), 0.6);

    &.resolved {
      border: 2px solid;
      border-color: var(--optInIndicatorColour);

      .missing-icon {
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        background-color: var(--infoColor);
        padding: 6px;
        color: var(--userIcon);
        font-size: 22px;
      }
    }
  }

  ::v-deep .more-less-toggle {
    -webkit-line-clamp: 1;
  }
}

.scrollbar {
  max-height: 700px;
  overflow-y: auto;
}

.row-icon {
  margin-right: 10px;
  font-size: 20px;
}

.section-header {
  min-height: 35px;
}
</style>
