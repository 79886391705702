<template>
	<div class="position-relative">
		<div
			class="btn-primary back rounded-circle"
			@click="onActiveCarouselChanged(activeCarouselItem - 1)"
			:class="{ disabled: activeCarouselItem == 0 }"
		>
			<i class="fas fa-caret-left" />
		</div>
		<div class="ml-25 mr-25">
			<el-carousel
				ref="carousel"
				:height="height"
				:autoplay="false"
				trigger="click"
				arrow="never"
				indicator-position="none"
				:initial-index="activeCarouselItem"
			>
				<el-carousel-item
					v-for="(_, itemIndex) in carouselItemsCount"
					:key="itemIndex"
				>
					<div class="carousel-item">
						<div
							v-for="(__, cardIndex) in getCardsAmount(itemIndex)"
							class="max-width-33"
							:key="cardIndex"
						>
							<slot :card="data[calculateIndex(cardIndex, itemIndex)]" />
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div
			class="btn-primary next rounded-circle"
			@click="onActiveCarouselChanged(activeCarouselItem + 1)"
			:class="{ disabled: isLastCarouselItem() }"
		>
			<i class="fas fa-caret-right" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'AqCarousel',
	props: {
		height: {
			type: String,
			required: false,
			default: '20rem',
		},
		data: {
			type: Array,
			required: true,
		},
		carouselCapacity: {
			type: Number,
			required: false,
			default: 3,
		},
	},
	data () {
		return {
			isVisible: true,
			activeCarouselItem: 0,
		};
	},
	computed: {
		carouselItemsCount: function () {
			return Math.ceil(this.data.length / this.carouselCapacity);
		},
	},
	methods: {
		getCardsAmount (itemIndex) {
			return this.data.length / (this.carouselCapacity * (itemIndex + 1)) >= 1
				? this.carouselCapacity
				: this.data.length % this.carouselCapacity;
		},
		calculateIndex (cardIndex, itemIndex) {
			return itemIndex * this.carouselCapacity + cardIndex;
		},
		isLastCarouselItem () {
			if (this.data.length === this.carouselCapacity) {
				return true;
			}
			const substractor = this.data.length % this.carouselCapacity ? 0 : 1;
			return this.activeCarouselItem
				>= Math.floor(this.data.length / this.carouselCapacity) - substractor;
		},
		onActiveCarouselChanged (index) {
			this.activeCarouselItem = index;
			this.$refs.carousel.setActiveItem(index);
			this.$emit('changed-carousel');
		},
		onChangeState (isActive) {
			this.isVisible = isActive;
		},
	},
};
</script>

<style lang="scss" scoped>
.carousel-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.max-width-33 {
  flex: 0 1 33%;
  min-width: 0;
}

.main {
  position: relative;

  .btn-primary {
    position: absolute;
    margin: 0.3rem;
    width: 24px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;

    &.disabled {
      background: $secondary;
      color: $body-color;
      opacity: 0.3;
      pointer-events: none;
    }

    &.back {
      top: calc(50% - 1.5rem);

      i {
        margin-left: 8px;
      }
    }

    &.next {
      top: calc(50% - 1.5rem);
      right: 0;

      i {
        margin-left: 8px;
      }
    }
  }

  .ml-25 {
    padding: 0.5rem;
  }
}
</style>
