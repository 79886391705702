export default class ClaimBasicView {
	constructor (
		claimId,
		petId,
		pet,
		policyId,
		basicData,
		conditions,
		vets,
		isRequestTriggered,
		hasMissingItems,
		hasClaimItems,
		hasPayments,
		wasReassessed,
		isParent,
		wasIgnoredNoCoverPeriod,
		wasIgnoredWaitingPeriod,
		wasIgnoredPreExistingCondition,
		wasIgnoredNoProductCoverage,
		wasIgnoredDuplicateClaim,
		wasIgnoredDuplicateInvoice,
		duplicateClaimIds,
		formType,
		preAuthorisationCompleted,
		payees,
		isFastTrackReviewed,
		claimSource,
		createdDateTime,
		createdByUser,
		closedDateTime,
		closedByUser,
		claimAssignment,
		reassessmentDateTime,
		reassessmentCreatedByUser,
		reassessmentReason,
		status,
		nextStatus,
		invoiceNumbers,
		continuation,
		allowRestoring,
		failedRestoreReason,
		hospitalNotificationEnabled,
		hospitalNotificationGenerated,
		billingSyncEnabled,
		multiSubmissionClaimIds,
		hasPreAuthCopy,
		isEscalated,
		escalationType,
	) {
		this.claimId = claimId;
		this.petId = petId;
		this.pet = pet;
		this.policyId = policyId;
		this.basicData = basicData;
		this.vets = vets;
		this.conditions = conditions;
		this.isRequestTriggered = isRequestTriggered;
		this.hasMissingItems = hasMissingItems;
		this.hasClaimItems = hasClaimItems;
		this.hasPayments = hasPayments;
		this.wasReassessed = wasReassessed;
		this.isParent = isParent;
		this.wasIgnoredNoCoverPeriod = wasIgnoredNoCoverPeriod;
		this.wasIgnoredWaitingPeriod = wasIgnoredWaitingPeriod;
		this.wasIgnoredPreExistingCondition = wasIgnoredPreExistingCondition;
		this.wasIgnoredNoProductCoverage = wasIgnoredNoProductCoverage;
		this.wasIgnoredDuplicateClaim = wasIgnoredDuplicateClaim;
		this.wasIgnoredDuplicateInvoice = wasIgnoredDuplicateInvoice;
		this.duplicateClaimIds = duplicateClaimIds;
		this.formType = formType;
		this.preAuthorisationCompleted = preAuthorisationCompleted;
		this.payees = payees;
		this.isFastTrackReviewed = isFastTrackReviewed;
		this.claimSource = claimSource;
		this.createdDateTime = createdDateTime;
		this.createdByUser = createdByUser;
		this.closedDateTime = closedDateTime;
		this.closedByUser = closedByUser;
		this.claimAssignment = claimAssignment;
		this.reassessmentDateTime = reassessmentDateTime;
		this.reassessmentCreatedByUser = reassessmentCreatedByUser;
		this.reassessmentReason = reassessmentReason;
		this.status = status;
		this.nextStatus = nextStatus;
		this.invoiceNumbers = invoiceNumbers;
		this.continuation = continuation;
		this.allowRestoring = allowRestoring;
		this.failedRestoreReason = failedRestoreReason;
		this.hospitalNotificationEnabled = hospitalNotificationEnabled;
		this.hospitalNotificationGenerated = hospitalNotificationGenerated;
		this.billingSyncEnabled = billingSyncEnabled;
		this.multiSubmissionClaimIds = multiSubmissionClaimIds;
		this.hasPreAuthCopy = hasPreAuthCopy;
		this.isEscalated = isEscalated;
		this.escalationType = escalationType;
	}
};
