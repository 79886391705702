export default class LetterView {
	constructor (
		id,
		policyId,
		policyReference,
		letterType,
		vetName,
		claimStatus,
		customerId,
		customerFirstName,
		customerLastName,
		generatedBy,
		generatedDateTime,
		claimId,
		petId,
		petName,
		petType,
		petGender,
		petDateOfDeath,
		zippedFileName,
	) {
		this.id = id;
		this.policyId = policyId;
		this.policyReference = policyReference;
		this.letterType = letterType;
		this.vetName = vetName;
		this.claimStatus = claimStatus;
		this.customerId = customerId;
		this.customerFirstName = customerFirstName;
		this.customerLastName = customerLastName;
		this.generatedBy = generatedBy;
		this.generatedDateTime = generatedDateTime;
		this.claimId = claimId;
		this.petId = petId;
		this.petName = petName;
		this.petType = petType;
		this.petGender = petGender;
		this.petDateOfDeath = petDateOfDeath;
		this.zippedFileName = zippedFileName;
	}
}
