export default class WaitPeriod {
	constructor (
		claimId,
		dateOfLoss,
		ailmentId,
		claimConditionTypeId) {
		this.claimId = claimId;
		this.dateOfLoss = dateOfLoss;
		this.ailmentId = ailmentId;
		this.claimConditionTypeId = claimConditionTypeId;
	}
};
