import { ClaimFormCondition } from '@commonServices/models/documents/ClaimFormCondition';
import { TableRecognitionResult } from '@commonServices/models/documents/TableRecognitionResult';
import { DocumentRecognitionResult } from '@commonServices/models/documents/DocumentRecognitionResult';
import { shortDate } from '@commonServices/utils/filters';
import { fromStringToDate } from '@commonServices/utils/dateUtils';
import LocalDate from '@commonServices/models/LocalDate';

const dateFormat = rawDate => shortDate(fromStringToDate(rawDate));
function toDocumentScanResult (scanResult) {
	return new DocumentRecognitionResult(
		formatField(scanResult.customerName),
		formatField(scanResult.petName),
		createClaimFormCondition(scanResult.condition),
		createClaimFormCondition(scanResult.secondCondition),
		scanResult.isMultiCondition,
		formatField(scanResult.totalAmount),
		formatField(scanResult.veterinaryPracticeName),
		scanResult.invoiceNumber,
		scanResult.payPolicyHolder,
		scanResult.payVeterinaryHospital,
		createTableRecognitionResultArray(scanResult.items),
		formatField(scanResult.vendorName),
		scanResult.totalTax,
		scanResult.totalDiscount,
		scanResult.invoiceDate,
		scanResult.taxesIncluded,
		scanResult.multiPet,
	);
}

function emptyDocumentScanResult (claimId) {
	return new DocumentRecognitionResult(
		formatField(null),
		formatField(null),
		new ClaimFormCondition(
			formatField(null),
			formatField(null, dateFormat),
			formatField(null, dateFormat),
			formatField(null, dateFormat),
			formatField(null, dateFormat),
			formatField(null),
			formatField(null),
			claimId,
		),
		null,
		false,
		formatField(null),
		formatField(null),
		formatField(null),
		formatField(null),
		formatField(null),
		createTableRecognitionResultArray(null),
		formatField(null),
		formatField(null),
		formatField(null),
		formatField(null),
		formatField(null),
		formatField(null),
		formatField(null),
	);
}

function convertScanResultDates (scanResult) {
	if (scanResult) {
		const convertedResult = {
			...scanResult,
			condition: formatConditionDates(scanResult.condition),
		};
		if (scanResult.isMultiCondition) {
			convertedResult.secondCondition = formatConditionDates(scanResult.secondCondition);
		}
		if (scanResult.items && scanResult.items.length) { // for invoice scan
			convertedResult.items = 	scanResult.items.map(item => ({
				...item,
				date: formatDateFieldToLocal(item.date),
			}));
		}
		if (scanResult.payPolicyHolder) {
			scanResult.payPolicyHolder.value = scanResult.payPolicyHolder.selected;
		}
		if (scanResult.payVeterinaryHospital) {
			scanResult.payVeterinaryHospital.value = scanResult.payVeterinaryHospital.selected;
		}
		return convertedResult;
	}
	return scanResult;
}

function formatConditionDates (condition) {
	if (condition) {
		return {
			...condition,
			dateOfLoss: formatDateFieldToLocal(condition.dateOfLoss),
			dateOfDeath: formatDateFieldToLocal(condition.dateOfDeath),
			treatmentStart: formatDateFieldToLocal(condition.treatmentStart),
			treatmentEnd: formatDateFieldToLocal(condition.treatmentEnd),
		};
	}
	return condition;
}

function formatDateFieldToLocal (dateField) {
	if (dateField && dateField.value) {
		const dateAsString = fromStringToDate(dateField.value);
		return { ...dateField, value: new LocalDate(dateAsString) };
	}
	return dateField;
}

function createClaimFormCondition (condition) {
	if (condition) {
		return new ClaimFormCondition(
			formatField(condition.description),
			formatField(condition.dateOfLoss, dateFormat),
			formatField(condition.dateOfDeath, dateFormat),
			formatField(condition.treatmentStart, dateFormat),
			formatField(condition.treatmentEnd, dateFormat),
			formatField(condition.ailment),
			formatField(condition.claimAmount),
			condition.claimId,
			formatField(condition.claimFormType),
		);
	}
	return null;
}

function createTableRecognitionResultArray (items) {
	if (!items) {
		return [];
	}

	return items.map(item => {
		return new TableRecognitionResult(
			item.id,
			item.parentId,
			formatField(item.date, dateFormat),
			item.quantity,
			item.description,
			item.amount,
			item.policySection,
			item.excluded,
			item.deductions,
			item.editedByUser,
			item.createdByUser,
			item.claimCondition,
			item.nonFinancialNumber,
			formatField(item.discountAmount),
			formatField(item.discountPercent),
			formatField(item.itemType),
			item.tax,
			item.petName,
		);
	});
}

function formatField (field, format = x => x) {
	if (!field) {
		return {
			value: null,
			originalValue: null,
			boundingBox: null,
			confidence: 1,
			page: 0,
		};
	}

	if (field.value === 0) {
		return field;
	}

	const result = format(field.value || field.originalValue);
	if (!result) {
		return field;
	}

	if (typeof result === 'string' && result.toLowerCase().includes('invalid')) {
		return field;
	}
	field.value = result;

	return field;
}

export {
	toDocumentScanResult,
	emptyDocumentScanResult,
	convertScanResultDates,
};
