<template>
	<div
		:style="{ height }"
		v-shortkey="toggleDdlShortCut"
		@shortkey="onHandleDropdownToggling"
	>
		<dropdown
			ref="dropdown"
			class="dropdown"
			trigger="click"
			:boundaries-selector="boundariesSelector"
			:options="{
				positionFixed: true,
				modifiers: {
					keepTogether: { enabled: keepTogether }
				},
			}"
			@show="onToggle(true)"
			@hide="onToggle(false)"
			:class="{ 'no-label': !label, 'split-toggle': splitToggle }"
		>
			<template
				v-if="isCustomLabel"
				slot="reference"
			>
				<slot />
			</template>
			<template v-else>
				<template v-if="!splitToggle">
					<button
						data-qa="aqDropdown_button_proceed"
						slot="reference"
						:disabled="disabled"
						class="btn dropdown-toggle"
						:class="btnClass"
						type="button"
					>
						{{ label }}
					</button>
				</template>
				<template
					v-else
					slot="reference"
				>
					<button
						data-qa="aqDropdown_button_toggle"
						class="btn small dropdown-toggle"
						:class="btnClass"
						:disabled="isReadOnlyMode || options.length === 0"
						type="button"
					/>
					<button
						data-qa="aqDropdown_button_proceed"
						class="btn main-button"
						:disabled="disabled"
						:class="btnClass"
						type="button"
						v-shortkey="mainActionShortkey"
						@shortkey="$emit('main-action')"
						@click="$emit('main-action')"
					>
						{{ label }}
					</button>
				</template>
			</template>
			<div class="popper">
				<div v-if="isDisplay">
					<div
						v-shortkey="$shortkeys.proceed"
						@shortkey="onHandleDropdownOptionSelection"
					>
						<aq-list-navigation-control
							:list="options"
							:current-item="currentItem"
							@current-changed="onSelect"
							:nav-controls="{ down: $shortkeys.arrDown, up: $shortkeys.arrUp }"
						>
							<template v-for="(option, index) in options">
								<div
									:key="index"
									@click="onSelectOption(option)"
									class="dropdown-item"
									v-tooltip="option.tooltip"
									:class="[{ 'disabled': option.disabled}, { 'bg-primary': option === currentItem}]"
									:data-qa="'aqDropdown_button_' + camelConverter(option.value)"
								>
									<div>{{ option.name }}</div>
									<div
										class="pl-20 flex-grow-1 text-right"
										v-if="option.iconClass"
									>
										<i
											class="fas"
											:class="option.iconClass"
										/>
									</div>
								</div>
							</template>
						</aq-list-navigation-control>
					</div>
				</div>
			</div>
		</dropdown>
	</div>
</template>

<script>
import Popper from 'vue-popperjs';
import { camelCase } from '@commonServices/utils/filters';
import rowHighlightMixin from '@mixins/rowHighlightMixin';

export default {
	name: 'AqDropdown',
	components: {
		dropdown: Popper,
	},
	mixins: [rowHighlightMixin],
	props: {
		isCustomLabel: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: false,
		},
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		btnClass: {
			type: String,
			required: false,
		},
		splitToggle: {
			type: Boolean,
			required: false,
		},
		height: {
			type: Number,
			required: false,
			default: 33,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		isShortkeyContext: {
			type: Boolean,
			required: false,
		},
		boundariesSelector: {
			type: String,
			required: false,
			default: null,
		},
		keepTogether: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data () {
		return {
			isDisplay: false,
		};
	},
	computed: {
		toggleDdlShortCut () {
			if (this.isReadOnlyMode || this.options.length === 0 || !this.isShortkeyContext) {
				return null;
			}
			return this.isDisplay ? { closeDropdown: this.$shortkeys.close } : { openDropdown: this.$shortkeys.openDdl };
		},
		mainActionShortkey () {
			return this.disabled ? null : this.$shortkeys.proceed;
		},
	},
	methods: {
		onSelectOption (option) {
			if (option.disabled) {
				return;
			}
			this.$refs.dropdown.doClose();
			this.$emit('select', option.value);
		},
		camelConverter (name) {
			return camelCase(name);
		},
		onToggle (isDisplay) {
			this.isDisplay = isDisplay;
			this.$emit('open', isDisplay);
		},
		onHandleDropdownOptionSelection () {
			if (this.currentItem) {
				this.onSelectOption(this.currentItem);
			}
		},
		onHandleDropdownToggling (event) {
			switch (event.srcKey) {
			case 'openDropdown': {
				this.currentItem = this.options[0];
				this.$refs.dropdown.doShow();
				break;
			}
			case 'closeDropdown': {
				this.currentItem = null;
				this.$refs.dropdown.doClose();
				break;
			}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.dropdown {
  &.no-label .dropdown-toggle::after {
    margin-left: 0;
    transition: 0.2s ease;
  }
}
</style>
