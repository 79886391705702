<template>
	<div>
		<div
			class="payment-instruction-status-circle"
			:class="statusColor"
		>
			<i
				:class="statusIcon"
			/>
		</div>
		<div
			class="payment-instruction-status-bar"
			:class="statusColor"
		>
			{{ statusText }}
		</div>
	</div>
</template>

<script>
import { PaymentInstructionStatusIcon, PaymentInstructionStatusText, PaymentInstructionStatusColor } from '@commonServices/models/PaymentInstructionStatus';

export default {
	name: 'PaymentInstructionStatusLabel',
	props: {
		paymentInstructionStatus: {
			type: String,
			required: true,
		},
	},
	computed: {
		statusIcon () {
			return PaymentInstructionStatusIcon[this.paymentInstructionStatus];
		},
		statusText () {
			return PaymentInstructionStatusText[this.paymentInstructionStatus];
		},
		statusColor () {
			return PaymentInstructionStatusColor[this.paymentInstructionStatus];
		},
	},
};
</script>

<style lang="scss" scoped>
  .payment-instruction-status-circle {
    width: 27px;
    height: 27px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    line-height: 25px;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    color: var(--contentBg);
    position: relative;
    z-index: 1;
  }

  .payment-instruction-status-bar {
    min-width: 80px;
    padding-left: 15px;
    padding-right: 5px;
    position: absolute;
    top: 21px;
    left: 30px;
    font-size: 12px;
    line-height: 18px;
    color: var(--bodyColour);
  }

  .warning {
    background: var(--paymentWarning);

    &.payment-instruction-status-bar {
      border: 2px solid var(--paymentWarning);
      background: var(--paymentWarningLight);
    }
  }

  .success {
    background: var(--paymentSuccess);

    &.payment-instruction-status-bar {
      border: 2px solid var(--paymentSuccess);
      background: var(--paymentSuccessLight);
    }
  }

  .danger {
    background: var(--paymentDanger);

    &.payment-instruction-status-bar {
      border: 2px solid var(--paymentDanger);
      background: var(--paymentDangerLight);
    }
  }

</style>
