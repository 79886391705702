export default class NewVetInput {
	constructor (practiceName,
		address1,
		address2,
		address3,
		townCity,
		postalCode,
		regionCounty,
		country,
		email,
		phoneNumber,
		locale) {
		this.practiceName = practiceName;
		this.address1 = address1;
		this.address2 = address2;
		this.address3 = address3;
		this.townCity = townCity;
		this.postalCode = postalCode;
		this.regionCounty = regionCounty;
		this.country = country;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.locale = locale;
	}
};
