<template>
	<div>
		<aq-modal name="adjuster-comment-modal">
			<adjuster-comment-modal
				:claim-id="claimId"
			/>
		</aq-modal>
		<aq-modal name="hospital-notification-modal">
			<hospital-notification-modal
				:claim-id="claimId"
				:can-generate-notification="!sharedData.hospitalNotificationGenerated"
				@hospital-notification-generated="onHospitalNotificationGenerated"
			/>
		</aq-modal>
		<div class="status-wrapper">
			<claim-base-bar
				class="py-0 px-25"
				:class="barProps.class"
				:next-label="barProps.nextLabel"
				:next-class="barProps.nextClass"
				:is-next-disabled="barProps.isNextDisabled"
				:split-toggle="barProps.splitToggle"
				:is-back-visible="isBackVisible"
				:option-list="buttonOptions"
				:is-read-only-mode="isReadOnlyMode"
				@next="onNext"
				@back="onBack"
				@cancel="onCancel"
				@select-action="onSelect"
			>
				<slot :name="barProps.slotName" />
			</claim-base-bar>
		</div>
	</div>
</template>

<script>
import { claimNumberStatus } from '@commonServices/models/ClaimStatusActionEnum';
import { pageStatusEnum, allPageStatuses, invalidPageStatuses } from '@commonServices/models/PageStatusEnum';
import AdjusterCommentModal from '@commonView/ClaimPage/Shared/AdjusterCommentModal';
import HospitalNotificationModal from '@commonView/ClaimPage/Shared/HospitalNotificationModal';

export default {
	components: {
		AdjusterCommentModal,
		HospitalNotificationModal,
	},
	props: {
		status: {
			type: String,
			required: true,
			validator: function (value) {
				return allPageStatuses.indexOf(value) !== -1;
			},
		},
		isBackVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		sharedData: {
			type: Object,
			required: true,
		},
		isLastStep: {
			type: Boolean,
			required: false,
			default: false,
		},
		multicondition: {
			type: Boolean,
			required: false,
			default: false,
		},
		hasClaimItems: {
			type: Boolean,
			required: false,
			default: false,
		},
		rejectionState: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	computed: {
		singlecondition () {
			return !this.multicondition;
		},
		claimId () {
			return this.sharedData.claimId;
		},
		barProps: function () {
			const barProps = {
				nextLabel: this.buttonLabel,
				splitToggle: this.buttonOptions.length > 0,
				class: 'bg-success-darken',
				isNextDisabled: false,
				nextClass: 'btn-success',
				slotName: 'success',
			};

			if (this.status === pageStatusEnum.Warning) {
				barProps.class = 'bg-warning-darken';
				barProps.nextClass = 'btn-warning';
				barProps.slotName = 'warning';
			} else if (this.status === pageStatusEnum.Incomplete) {
				barProps.class = 'bg-disabled ';
				barProps.slotName = 'incomplete';
			} else if (this.status === pageStatusEnum.Danger) {
				barProps.class = 'bg-danger-darken';
				barProps.slotName = 'danger';
				barProps.nextClass = 'btn-danger';
				barProps.isNextDisabled = !this.isReadOnlyMode;
			}

			return barProps;
		},
		buttonLabel: function () {
			if (this.isReadOnlyMode) {
				return (this.isLastStep || invalidPageStatuses.includes(this.status)) ? 'Close' : 'Next';
			}
			return this.status === pageStatusEnum.Warning ? 'Reject' : 'Proceed';
		},
		isInInitialRejection () { // page 1 (basic enter) related
			return this.rejectionState.hasSystemRejection || this.rejectionState.isManuallyRejected || false;
		},
		isDuplicate () {
			return this.rejectionState.isDuplicateInvoice || this.rejectionState.isDuplicateClaim;
		},
		pageStatusValid () {
			return this.status !== pageStatusEnum.Danger;
		},
		buttonOptions () {
			const options = [];
			if (this.isReadOnlyMode) return options;

			if (this.sharedData.hospitalNotificationEnabled && this.$can.GenerateHospitalNotification && !this.sharedData.claimVets[0]?.disableSLANotification) {
				options.push({ name: 'Generate Notification', value: 'hospital-notification-generation', iconClass: 'fa-bell' });
			}

			if (this.isInInitialRejection && this.$can.OverrideClaimRejection) {
				if (this.rejectionState.initialStep) {
					options.push({ name: 'Refine Rejection', value: 'refine-rejection', iconClass: 'fa-search-plus' });
				}
				if (this.singlecondition && this.rejectionState.hasSystemRejection && !this.rejectionState.isManuallyRejected && this.rejectionState.overrideOption) {
					options.push({ name: this.rejectionState.overrideOption, value: 'override-rejection', iconClass: 'fa-undo-alt' });
				}
			}

			if (this.pageStatusValid && this.rejectionState.isNoPolicyLimits && this.$can.OverrideClaimRejection) {
				options.push({ name: 'Override No Policy Limits', value: 'override-no-policy-limits', iconClass: 'fa-undo-alt' });
			}

			if (this.pageStatusValid && !this.rejectionState.isManuallyRejected && this.$can.OverrideClaimRejection && this.isDuplicate) {
				if (this.rejectionState.isDuplicateInvoice) {
					options.push({ name: 'Override Duplicate Invoice', value: 'override-rejection', iconClass: 'fa-undo-alt' });
				} else if (this.rejectionState.isDuplicateClaim) {
					options.push({ name: 'Override Duplicate Claim', value: 'override-rejection', iconClass: 'fa-undo-alt' });
				}
			}

			if ((this.singlecondition || this.rejectionState.hasDuplicateOverride)
					&& this.rejectionState.hasRejectionOverride && this.$can.OverrideClaimRejection) {
				options.push({ name: 'Cancel Rejection Override', value: 'cancel-override-condition', iconClass: 'fa-eject' });
			}

			if (this.singlecondition && this.$can.ManualRejectClaim) {
				if (this.rejectionState.initialStep) {
					if (this.rejectionState.isManuallyRejected) {
						options.push({ name: 'Remove Manual Rejection', value: 'manual-remove-reject', iconClass: 'fa-thumbs-up' });
					} else {
						options.push({ name: 'Manual Rejection', value: 'manual-reject', iconClass: 'fa-thumbs-down' });
						options.push({ name: 'Manual Rejection (Refine)', value: 'manual-refine-reject', iconClass: 'fa-search-plus' });
					}
				} else if (this.pageStatusValid) {
					options.push({ name: 'Manual Rejection', value: 'manual-reject', iconClass: 'fa-thumbs-down' });
				}
			}

			if (this.pageStatusValid && (!this.isInInitialRejection || this.rejectionState.hasRejectionOverride) && !this.isDuplicate) {
				if (this.$can.ManualFraudCheck && claimNumberStatus.MissingInfo !== this.sharedData.claimStatus) {
					options.push({ name: 'Fraud Check', value: 'fraud', iconClass: 'fa-user-secret text-primary' });
				}
			}

			if (this.$can.ViewAdjusterComment) {
				options.push({ name: 'Adjuster Comment', value: 'adjuster-comment', iconClass: 'fa-comment' });
			}

			if (this.$can.FlagEscalated && !this.sharedData.claim?.isEscalated && this.sharedData.claimStatus !== claimNumberStatus.New) {
				options.push({ name: 'Escalate', value: 'escalate-claim', iconClass: 'aqv-escalate' });
			}

			if (this.$can.RemoveFlagEscalated && this.sharedData.claim?.isEscalated) {
				options.push({ name: 'Remove Escalation', value: 'remove-escalation', iconClass: 'aqv-escalate' });
			}

			return options;
		},
	},
	methods: {
		onNext () {
			this.$emit('next', this.status);
		},
		onBack () {
			this.$emit('back', this.status);
		},
		onCancel () {
			this.$emit('cancel', this.status);
		},
		onSelect (action) {
			if (action === 'adjuster-comment') {
				this.$modal.show('adjuster-comment-modal');
				return;
			}
			if (action === 'hospital-notification-generation') {
				this.$modal.show('hospital-notification-modal');
				return;
			}
			this.$emit(action);
		},
		onHospitalNotificationGenerated () {
			this.sharedData.hospitalNotificationGenerated = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.status-wrapper {
  color: $body-color;
  height: 58px;
  position: absolute;
  bottom: 30px;
  left: 80px;
  right: 0;
}
</style>
