export default class ClaimItemSummaryView {
	constructor (
		policySectionId,
		policySectionName,
		treatmentPeriodStart,
		treatmentPeriodEnd,
		amount,
		userDeductions,
		systemDeductions,
		totalAmount,
		totalDeductions) {
		this.policySectionId = policySectionId;
		this.policySectionName = policySectionName;
		this.treatmentPeriodStart = treatmentPeriodStart;
		this.treatmentPeriodEnd = treatmentPeriodEnd;
		this.amount = amount;
		this.userDeductions = userDeductions;
		this.systemDeductions = systemDeductions;
		this.totalAmount = totalAmount;
		this.totalDeductions = totalDeductions;
	}
}
