<template>
	<div
		class="control-container min-height-0 p-0"
		:class="{
			'disabled': disabled,
		}"
	>
		<div class="row no-gutters">
			<div :class="[labelClass, 'col-5 py-20 pl-25']">
				{{ label }}
			</div>
			<div :class="[{ 'focused': isFocused }, 'col-7 scan-form-input py-20']">
				<aq-input
					:type="type"
					:value="value"
					:input-class="inputClass"
					@input="updateSearchTerm"
					@focus="onFocus"
					:disabled="disabled"
				/>
			</div>
		</div>
		<div
			class="locked"
			v-if="disabled"
		>
			<i class="fas fa-lock" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'AqScanFormSelect',
	props: {
		label: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
		},
		isValid: {
			type: Boolean,
			required: false,
			default: true,
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		labelClass: {
			type: String,
			required: false,
			default: 'aq-scan-label',
		},
		inputClass: {
			type: String,
			required: false,
			default: 'aq-scan-input',
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isFocused: false,
		};
	},
	methods: {
		onFocus (isFocused) {
			this.isFocused = isFocused;
			if (isFocused) {
				this.$emit('focused');
			}
		},
		updateSearchTerm (term) {
			this.$emit('search-async', term);
		},
	},
};
</script>
