<template>
	<div>
		<aq-modal-container
			@close="onCancel"
			title="Undo Edits"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="no-gutters">
						<div class="text-center">
							<i class="fa-solid fa-triangle-exclamation reset-invoice-icon" />
						</div>
						<div class="text-center pt-5 text-bold">
							WARNING
						</div>
						<div class="text-center pt-5 w-75 mx-auto">
							You are about to remove <span class="text-bold text-underline">ALL</span> edits, changes and updates.
							This will revert to the original scanned data and you will not
							be able to retrieve the previous edits.
						</div>
						<div class="text-center pt-10 text-bold">
							Do you wish to proceed?
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="resetInvoiceModal_button_cancel"
						@click="onCancel"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="resetInvoiceModal_button_proceed"
						@click="onProceed"
						type="button"
						class="btn btn-success btn-height ml-auto"
					>
						Proceed
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>

export default {
	methods: {
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onProceed () {
			this.onCancel();
			this.$emit('reset-invoice');
		},
	},
};
</script>

<style lang="scss" scoped>
.reset-invoice-icon {
  font-size: 40px;
  width: 43px;
  color: var(--backgroundWarning);
}
</style>
