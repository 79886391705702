<template>
	<div class="h-100 d-flex flex-column">
		<aq-confirmation-modal
			ref="saveNoteChangesDialog"
			name="save-note-changes-dialog"
			title="Save changes"
			description="You have unsaved changes, Click Save to continue or Cancel to Close without saving."
			yes-label="Save"
			no-label="Cancel"
			data-qa="noteForm_modal_saveChanges"
		/>
		<aq-alert-modal
			ref="saveNoteChangesAlert"
			name="save-note-changes-alert"
			title="Invalid data"
			description="Save aborted due to a validation error. Please correct it before saving"
			data-qa="noteForm_modal_saveChangesAlert"
		/>
		<div class="section-content flex-grow-1">
			<div class="container-fluid">
				<div
					v-if="$can.EditDashboardNotes"
					class="d-flex justify-content-end font-weight-bold"
					:class="[ isClaimContext ? (showDashboardNoteCheckbox ? 'mb-10' : 'mb-26') : '']"
				>
					<aq-checkbox
						v-show="showDashboardNoteCheckbox"
						data-qa="noteForm_checkbox_dashboardNote"
						class="tick dashboard-note-checkbox"
						v-model="isDashboardNote"
						label="Dashboard"
						@input="onDashboardNoteChanged"
					/>
				</div>
				<aq-form-input
					data-qa="noteForm_input_title"
					label="Title"
					v-model="note.title"
					@input="$v.note.title.$touch"
					:disabled="isDashboardNote"
					:is-valid="!$v.note.title.$error"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="$v.note"
					property="title"
				/>
				<div class="mt-20" />
				<aq-form-input
					type="textarea"
					data-qa="noteForm_input_details"
					label="Details"
					v-model="note.details"
					:number-visible-lines="10"
					@input="$v.note.details.$touch"
					:is-valid="!$v.note.details.$error"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="errorMessages"
					:validator="$v.note"
					property="details"
				/>
				<aq-slider
					data-qa="noteForm_button_importance"
					:options="sliderOptions"
					v-model="note.type"
					class="mt-25"
				/>
			</div>
		</div>
		<div class="section-footer">
			<div class="container-fluid">
				<div class="d-flex text-right">
					<button
						class="btn btn-row mr-10 ml-auto"
						@click="onCancel"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						:disabled="$v.$invalid"
						@click="onSave()"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import NoteSubtypesEnum from '@clientCommon/services/models/NoteSubtypesEnum';
import { NoteTypesEnum } from '@commonServices/models/NoteTypesEnum';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
	name: 'NoteForm',
	data () {
		return {
			saveDraft: true,
			note: {
				subtype: null,
				...this.data,
			},
			draftName: 'notes',
		};
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
		noteTypes: {
			type: Array,
			required: true,
		},
	},
	mounted () {
		if (!this.note.id) {
			this.note = this.getDraftData(this.draftName, this.draftKey) ?? this.note;
		}
	},
	beforeDestroy () {
		if (this.saveDraft) {
			this.addDraftData({ draftName: this.draftName, key: this.draftKey, data: this.note });
		}
	},
	computed: {
		...mapState(['levelFilter']),
		...mapGetters(['getDraftData']),
		sliderOptions () {
			return this.noteTypes.map(noteType => {
				return {
					type: noteType.key,
					value: noteType.value,
					class: this.noteTypeClass(noteType.key),
					disabled: this.disableNoteType(noteType.key),
				};
			});
		},
		noteSize () {
			return this.noteTypes.find(type => type.key === this.note.type)?.key === NoteTypesEnum.Appeal ? 10000 : 2000;
		},
		errorMessages () {
			return {
				title: {
					required: ValidationMessages.required,
					maxLength: ValidationMessages.maxLength100,
				},
				details: {
					required: ValidationMessages.required,
					maxLength: ValidationMessages.maxLength(this.noteSize),
				},
			};
		},
		isDashboardNote: {
			get () {
				return this.note.subtype === NoteSubtypesEnum.Dashboard;
			},
			set (newValue) {
				this.note.subtype = newValue ? NoteSubtypesEnum.Dashboard : null;
			},
		},
		showDashboardNoteCheckbox () {
			return this.note.type !== NoteTypesEnum.Alert && this.isClaimContext;
		},
		isClaimContext () {
			return !!this.levelFilter.claimId;
		},
		draftKey () {
			return `${this.levelFilter.claimId}_${this.levelFilter.petId}_${this.levelFilter.customerId}`;
		},
	},
	validations () {
		return {
			note: {
				title: {
					required,
					maxLength: maxLength(100),
				},
				details: {
					required,
					maxLength: maxLength(this.noteSize),
				},
			},
		};
	},
	methods: {
		...mapActions(['clearDraftData', 'addDraftData']),
		clearDraft () {
			this.clearDraftData({ draftName: this.draftName, key: this.draftKey });
			this.saveDraft = false;
		},
		async onCancel () {
			if (!this.$v.note.title.$dirty && !this.$v.note.details.$dirty) {
				this.clearDraft();
				this.$emit('cancel');
			} else {
				const userAction = await this.$refs.saveNoteChangesDialog.show();
				if (userAction === 'no') {
					this.clearDraft();
					this.$emit('cancel');
				}
				if (userAction === 'yes') {
					if (!this.$v.$invalid) {
						this.onSave();
						this.$emit('cancel');
					}
					await this.$refs.saveNoteChangesAlert.show();
				}
			}
		},
		onSave () {
			if (!this.$v.$invalid) {
				this.clearDraft();
				this.$emit('save', this.note);
			}
		},
		noteTypeClass (noteType) {
			switch (noteType) {
			case NoteTypesEnum.Alert:
				return 'bg-danger';
			case NoteTypesEnum.Appeal:
				return 'bg-warning';
			case NoteTypesEnum.Standard:
				return 'bg-primary';
			}
		},
		disableNoteType (noteType) {
			return noteType === NoteTypesEnum.Alert && this.isDashboardNote;
		},
		onDashboardNoteChanged () {
			this.$v.note.title.$reset();
			this.note.title = this.isDashboardNote ? 'Dashboard Note' : null;
		},
	},
	watch: {
		'note.type' (newValue, oldValue) {
			if (newValue !== oldValue && this.note.details) {
				this.$v.note.details.$touch();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
    ::v-deep .dashboard-note-checkbox .label::before {
      border: 2px solid var(--bodyColour);
    }
</style>
