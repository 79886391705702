<template>
	<div />
</template>

<script>
import Idle from '@commonServices/utils/Idle';
import SecurityManager from '@commonServices/securityService';

export default {
	name: 'AqActivityTracker',
	beforeMount () {
		const idleCallback = () => SecurityManager.logout();
		this.idle = new Idle({
			timeout: ClaimsConfig.LOG_OFF_TIME_PERIOD,
			idleCallback,
		});
	},
	beforeDestroy () {
		this.idle.tearDown();
	},
};
</script>
