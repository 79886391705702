<template>
	<div class="d-inline-flex flex-wrap">
		<span class="">{{ label }}:</span>
		<a
			v-for="claim of claimIds"
			:key="claim"
			class="claim-link mx-2"
			@click.prevent="onOpenClaim(claim)"
		>
			{{ claim | claimIdFormat }}
		</a>
	</div>
</template>

<script>
import { openClaimNewWindow } from '@clientCommon/services/utils/routerUtils';

export default {
	name: 'AqInlineClaimsList',
	props: {
		label: {
			type: String,
			required: false,
			default: 'View associated claims',
		},
		claimIds: {
			type: Array,
			required: true,
		},
	},
	methods: {
		onOpenClaim (claimId) {
			openClaimNewWindow(this.$router, claimId);
		},
	},
};
</script>
