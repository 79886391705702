export default class NotificationItem {
	constructor (id, claimId, message, comment, rejectionComment, isViewed, createdDate, createdBy, customerId) {
		this.id = id;
		this.claimId = claimId;
		this.message = message;
		this.comment = comment;
		this.rejectionComment = rejectionComment;
		this.isViewed = isViewed;
		this.createdDate = new Date(createdDate);
		this.createdBy = createdBy;
		this.customerId = customerId;
	}
}
