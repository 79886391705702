import SortingColumn from '@commonServices/models/SortingColumn';
import { FilterGroupEnum } from '@commonServices/models/FilterGroup';
import { WorkQueueType } from '@commonServices/models/WorkQueueType';

const ClaimReportSortingColumns = [
	new SortingColumn('Claim Ref', 'Id'),
	new SortingColumn('Policy Ref', 'Policy.Reference'),
	new SortingColumn('Date Onset', 'DateOfLoss'),
	new SortingColumn('Date Treatment Start', 'TreatmentStartDate'),
	new SortingColumn('Date Treatment End', 'TreatmentEndDate'),
	new SortingColumn('Amount Submitted', 'Amount'),
	new SortingColumn('Ailment', 'Ailment.FirstCause'),
	new SortingColumn('Condition', 'ConditionType.Name'),
	new SortingColumn('Policy Status', 'Policy.Status'),
	new SortingColumn('Breed', 'Policy.Pet.Breed.Name'),
	new SortingColumn('Species', 'Policy.Pet.Breed.PetType.Description'),
	new SortingColumn('Pet Date Of Birth', 'Policy.Pet.BirthDate'),
	new SortingColumn('Inception Date', 'Policy.InceptionDate'),
	new SortingColumn('Claim Created Date', 'CreatedDateTime'),
	new SortingColumn('Claim Status', 'Status'),
];

const ClaimPaymentSortingColumns = [
	new SortingColumn('Customer Ref', 'PaymentAccount.Customer.Id'),
	new SortingColumn('Policy Ref', 'Claim.Policy.Reference'),
	new SortingColumn('Claim Ref', 'ClaimId'),
	new SortingColumn('Vet Business Name', 'PaymentAccount.Vet.PracticeName'),
	new SortingColumn('Payment Type', 'PaymentAccount.PaymentMethod'),
	new SortingColumn('Account Name', 'PaymentAccount.AccountName'),
	new SortingColumn('Sort Code', 'PaymentAccount.SortCode'),
	new SortingColumn('Account Number', 'PaymentAccount.AccountNumber'),
	new SortingColumn('Amount', 'Gross'),
	new SortingColumn('Closed-Paid Date', 'TransactionDate'),
];

const ClaimStatusSortingColumns = [
	new SortingColumn('Customer Ref', 'CustomerId'),
	new SortingColumn('Policy Ref', 'PolicyReference'),
	new SortingColumn('Claim Ref', 'ClaimId'),
	new SortingColumn('Pet Name', 'PetName'),
	new SortingColumn('Species', 'PetType'),
	new SortingColumn('Breed', 'Breed'),
	new SortingColumn('Ailment', 'Ailment'),
	new SortingColumn('Amount Submitted', 'ClaimAmount'),
	new SortingColumn('Claim Created Date', 'CreatedDate'),
	new SortingColumn('Claim Form Uploaded Date', 'LastFormUpload'),
	new SortingColumn('Date Onset', 'DateOfLoss'),
	new SortingColumn('Date Treatment Start', 'TreatmentStartDate'),
	new SortingColumn('Last Interaction Date', 'LastInteractionDate'),
	new SortingColumn('Last Interaction', 'LastInteractionValue'),
	new SortingColumn('Claim Status', 'ClaimStatus'),
	new SortingColumn('Claim Closed Date', 'ClosedDateTime'),
	new SortingColumn('Claim Closed By', 'ClosedById'),
	new SortingColumn('Claim Assigned To', 'ClaimAssignedToId'),
];

const UserStatsSortingColumns = [
	new SortingColumn('User', 'UserName'),
	new SortingColumn('Brand', 'BrandName'),
	new SortingColumn('Team', 'TeamName'),
	new SortingColumn('Claims Created', 'ClaimsCreated'),
	new SortingColumn('Claims Closed', 'ClaimsClosed'),
];

const ClaimQualityAuditSortingColumns = [
	new SortingColumn('Claim Ref', 'Id'),
	new SortingColumn('Created Date', 'CreatedDateTime'),
	new SortingColumn('Date of Loss', 'DateOfLoss'),
	new SortingColumn('Closed Date', 'ClosedDateTime'),
	new SortingColumn('Amount Submitted', 'Amount'),
	new SortingColumn('Status', 'Status'),
];

const ConditionsReportSortingColumns = [
	new SortingColumn('Created Date', 'CreatedDate'),
];

const CommonTasksSortingColumns = [
	new SortingColumn('Pet', 'PetName'),
	new SortingColumn('Amount', 'ClaimAmount'),
	new SortingColumn('Ailment', 'Ailment'),
	new SortingColumn('Policy Status', 'PolicyStatus'),
];

const RequestedDateSortingColumn = new SortingColumn('Requested', 'LastChangeStatusDate');

const DraftClaimsSortingColumns = [
	...CommonTasksSortingColumns,
	new SortingColumn('Created Date', 'CreatedDate'),
	new SortingColumn('Claim Type', 'IsNew'),
	new SortingColumn('Reassessment Date', 'ReassessmentDateTime'),
];

const MissingInfoClaimsSortingColumns = [
	...CommonTasksSortingColumns,
	new SortingColumn('Chase Count', 'ChaseCount'),
	RequestedDateSortingColumn,
];

const FraudClaimsSortingColumns = [
	...CommonTasksSortingColumns,
	RequestedDateSortingColumn,
];

const PaymentApprovalClaimsSortingColumns = [
	...CommonTasksSortingColumns,
	RequestedDateSortingColumn,
];

const RejectionPendingClaimsSortingColumns = [
	...CommonTasksSortingColumns,
	RequestedDateSortingColumn,
];

const ClaimsToReassessSortingColumns = [
	...CommonTasksSortingColumns,
	RequestedDateSortingColumn,
];

const SortingColumnsByReportGroup = Object.freeze({
	[FilterGroupEnum.ClaimPayments]: ClaimPaymentSortingColumns,
	[FilterGroupEnum.ClaimReport]: ClaimReportSortingColumns,
	[FilterGroupEnum.ClaimStatus]: ClaimStatusSortingColumns,
	[FilterGroupEnum.UserStats]: UserStatsSortingColumns,
	[FilterGroupEnum.QualityAuditReport]: ClaimQualityAuditSortingColumns,
	[FilterGroupEnum.ConditionsReport]: ConditionsReportSortingColumns,

});

const SortingColumnsByQueueType = Object.freeze({
	[WorkQueueType.DraftClaims]: DraftClaimsSortingColumns,
	[WorkQueueType.Chasing]: MissingInfoClaimsSortingColumns,
	[WorkQueueType.FraudCheck]: FraudClaimsSortingColumns,
	[WorkQueueType.PaymentApproval]: PaymentApprovalClaimsSortingColumns,
	[WorkQueueType.RejectionApproval]: RejectionPendingClaimsSortingColumns,
	[WorkQueueType.ReassessmentPending]: ClaimsToReassessSortingColumns,
});
export {
	SortingColumnsByReportGroup,
	SortingColumnsByQueueType,
	ClaimReportSortingColumns,
	ClaimPaymentSortingColumns,
	ClaimStatusSortingColumns,
	DraftClaimsSortingColumns,
	MissingInfoClaimsSortingColumns,
	FraudClaimsSortingColumns,
	PaymentApprovalClaimsSortingColumns,
	ClaimsToReassessSortingColumns,
	RejectionPendingClaimsSortingColumns,
	UserStatsSortingColumns,
	ClaimQualityAuditSortingColumns,
	ConditionsReportSortingColumns,
};
