<template>
	<div
		class="fast-track-reviewed-bar"
		:class="[ disabled ? 'inactive' : 'active' ]"
	>
		<FastTrackModal
			ref="fastTrackModal"
			data-qa="fastTrackBar_modal_reasons"
			:claim-id="claimId"
		/>
		<div class="d-flex justify-content-center fast-track-reviewed-line" />
		<div class="d-flex justify-content-center position-relative">
			<div class="d-flex align-items-center row fast-track-reviewed-block">
				<input
					class="col-1 ml-15 checkbox"
					type="checkbox"
					v-model="isChecked"
					:disabled="disabled"
					@click="onClick"
				>
				<div class="col-1 pr-0 pl-5 mt-5 fast-track-reviewed-icon">
					<i class="aqv-fast-track" />
				</div>
				<span class="col-9 ml-5 pr-0">
					Fast Track Reviewed
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import FastTrackModal from '@commonView/ClaimPage/FastTrack/FastTrackModal';

export default {
	name: 'FastTrackReviewedBar',
	components: {
		FastTrackModal,
	},
	data () {
		return {
			isChecked: this.isFastTrack,
		};
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		isFastTrack: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		async onClick () {
			this.isChecked = await this.$refs.fastTrackModal.show();
			if (this.isChecked) {
				this.$emit('complete-fast-track');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.fast-track-reviewed-block {
  position: absolute;
  top: -22px;
  z-index: 1;
  width: 255px;
  height: 37px;
  border-radius: 4px;
  background-color: var(--fastTrackBarColor);
  color: #fff;
  font-size: 17px;
}

.fast-track-reviewed-bar {
  background-color: var(--fastTrackBarColor);
}

.active:hover {
  background: $primary-d-5;
}

.active:hover .fast-track-reviewed-block {
  background: $primary-d-5;
}

.inactive {
  background-color: var(--fastTrackBarColor);
}

.fast-track-reviewed-line {
  height: 6px;
}

.fast-track-reviewed-icon {
  fill: $body-color;
}

.active .checkbox {
  cursor: pointer;
}
</style>
