<template>
	<div
		v-if="value"
		class="text-truncate"
		v-tooltip.top-start="value"
	>
		{{ value }}
	</div>
</template>

<script>

export default {
	name: 'TruncatedTextCell',
	props: {
		value: {
			type: [String, Number, Object],
			default: null,
		},
	},
};
</script>
