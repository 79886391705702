const errorCode = Object.freeze({
	DuplicationErrorCode: 2,
	ClaimCannotMovedToStatus: 100,
	ClaimStatusHasBeenChanged: 101,
	InvalidActionError: 120,
	FileTypeMismatch: 150,
	FileTypeNotAllowed: 151,
	FormNotRecognised: 152,
	UserHasMaxTasks: 153,
});

const customErrorCodes = Object.values(errorCode);

export { errorCode as default, customErrorCodes };
