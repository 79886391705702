<template>
	<div>
		<div
			v-if="!showSideMenu"
			class="px-20 py-13 d-flex align-items-center"
		>
			<button
				class="btn btn-sm btn-secondary mr-20"
				@click="$emit('close')"
			>
				Back
			</button>
			<p class="flex-grow-1">
				Return to upload documents
			</p>
		</div>
		<MarkdownWrapper
			v-if="(isHospitalHtml || emailData) && htmlContent"
			:class="{'with-header': !showSideMenu }"
			:content="htmlContent"
			:source="docSrc"
			data-qa="documentPreview_view_htmlContent"
		>
			<template slot="tools">
				<button
					v-if="showCloseButton"
					class="btn btn-secondary close-button"
					@click="$emit('hide')"
				>
					Close
				</button>
			</template>
		</MarkdownWrapper>
		<div
			v-else
			class="viewer-container"
			:class="{'with-header': !showSideMenu }"
		>
			<div class="viewer-tools">
				<button
					class="btn btn-secondary"
					@click="scale = 1"
					:disabled="scale == 1"
				>
					<i class="fas aqv-zoom-reset" />
				</button>
				<button
					class="btn btn-secondary"
					:disabled="scale == maxScale"
					@click="scale += scaleStep"
				>
					<i class="aqv-zoom-in" />
				</button>
				<button
					class="btn btn-secondary"
					:disabled="scale == minScale"
					@click="scale -= scaleStep"
				>
					<i class="fas aqv-zoom-out" />
				</button>
				<button
					class="btn btn-secondary rotate-btn"
					@click="rotate += 90"
				>
					<i class="fas fa-redo" />
				</button>
				<button
					class="btn btn-secondary"
					@click="onScanDocument"
					v-if="isPreviewScanAvailable"
				>
					<i class="fas fa-search" />
				</button>
				<button
					class="btn btn-secondary rotate-btn"
					@click="rotate -= 90"
				>
					<i class="fas fa-undo" />
				</button>
				<button
					v-if="showCloseButton"
					class="btn btn-secondary close-button"
					@click="$emit('hide')"
				>
					Close
				</button>
				<a
					target="_blank"
					class="btn btn-secondary open-window"
					:href="docSrc"
				>
					<i class="fas fa-external-link-alt" />
				</a>
			</div>
			<div
				v-if="isDocument || isImage"
				class="viewer-wrapper"
				v-dragscroll
			>
				<div
					ref="viewer"
					class="position-relative w-100"
					:style="viewerStyle"
				>
					<AqPdfViewer
						v-if="isDocument"
						:src="docSrc"
						:page="page"
						@num-pages="pageCount = $event"
					/>
					<AqImageViewer
						v-else
						:src="docSrc"
						:page="page"
						@num-pages="pageCount = $event"

						:doc-mime-type="docMimeType"
						:file-id="fileId"
					/>
					<div
						class="highlight-area"
						:style="highlightAreaStyle"
					/>
				</div>
			</div>
			<div class="page-controls">
				<button
					class="btn btn-secondary rotate-btn"
					:disabled="page == pageCount"
					@click="page ++"
				>
					<i class="fas fa-chevron-right" />
				</button>
				<button
					class="btn btn-secondary rotate-btn"
					:disabled="page == 1"
					@click="page --"
				>
					<i class="fas fa-chevron-left" />
				</button>
				<div>{{ pageLabel }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';
import { FileTypes, getFileType } from '@commonServices/utils/general';
import MarkdownWrapper from '@commonView/Shared/MarkdownWrapper';
import AqPdfViewer from '@commonWidgets/AqPdfViewer';

export default {
	name: 'DocumentPreview',
	components: {
		AqImageViewer: () => import('@commonWidgets/AqImageViewer'),
		AqPdfViewer,
		MarkdownWrapper,
	},
	directives: {
		dragscroll,
	},
	props: {
		docSrc: {
			type: String,
			required: false,
		},
		highlightArea: {
			type: Object,
			required: false,
		},
		fileName: {
			type: String,
			required: true,
		},
		isPreviewScanAvailable: {
			type: Boolean,
			required: false,
			default: false,
		},
		docMimeType: {
			type: String,
			required: true,
		},
		fileId: {
			type: Number,
			required: false,
		},
		emailData: {
			type: Object,
			required: false,
			default: null,
		},
		showCloseButton: {
			type: Boolean,
			required: false,
			default: true,
		},
		showSideMenu: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data () {
		return {
			rotate: 0,
			page: 1,
			pageCount: 0,
			htmlContent: null,
			scale: 1,
			minScale: 0.8,
			maxScale: 2,
			scaleStep: 0.1,
		};
	},
	mounted () {
		this.generateHtmlContent(this.docSrc);
	},
	watch: {
		highlightArea: {
			handler (highlightArea) {
				if (highlightArea && highlightArea.page !== this.page) {
					this.page = highlightArea.page;
				}
			},
			immediate: true,
		},
	},
	computed: {
		fileType () {
			return getFileType(this.fileName);
		},
		isImage () {
			return this.fileType === FileTypes.image;
		},
		isDocument () {
			return this.fileType === FileTypes.pdf;
		},
		isHospitalHtml () {
			return this.fileType === FileTypes.hosp;
		},
		pageLabel () {
			return this.pageCount ? `Page: ${this.page} of ${this.pageCount}` : '';
		},
		viewerStyle () {
			return {
				transform: `rotate(${this.rotate}deg) scale(${this.scale})`,
				margin: this.scale < 1 ? '0' : `${(this.scale - 1) / 0.1 * 5}%`,
			};
		},
		highlightAreaStyle () {
			const container = this.$refs.viewer;
			if (this.highlightArea == null || container == null || this.highlightArea.page !== this.page) {
				return {};
			}

			const containerWidth = container.offsetWidth;
			const containerHeight = container.offsetHeight;

			const { points } = this.highlightArea;
			const x = points.topLeft.x * containerWidth;
			const y = points.topLeft.y * containerHeight;
			const areaWidth = (points.bottomRight.x - points.topLeft.x) * containerWidth;
			const areaHeight = (points.bottomRight.y - points.topLeft.y) * containerHeight;

			return {
				left: `${x}px`,
				top: `${y}px`,
				width: `${areaWidth}px`,
				height: `${areaHeight}px`,
			};
		},
	},
	methods: {
		onScanDocument () {
			this.$emit('scan');
		},
		async generateHtmlContent (docSrc) {
			if (this.fileType === FileTypes.hosp) {
				const blob = await fetch(docSrc).then(r => r.blob());
				this.htmlContent = await this.read(blob);
			}
			if (this.fileType === FileTypes.email) {
				this.htmlContent = this.emailData.htmlBody;
			}
		},
		async read (fileBlob) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = (event) => resolve(event.target.result);
				reader.onerror = reject;
				reader.readAsText(fileBlob);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.viewer-container {
  position: relative;
  background-color: var(--headerBg);
  padding: 15px;
  height: 100%;

  &.with-header {
    height: calc(100% - 63px);
  }

  .viewer-tools {
    position: absolute;
    z-index: 2;
    padding: 5px 20px;
    top: 15px;
    left: 15px;
    right: 15px;

    .btn {
      margin: 0 5px;
      width: 35px;
      height: 33px;
      line-height: 33px;
      padding: 0;
      font-size: 17px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .open-window {
      float: right;
    }
  }

  .page-controls {
    position: absolute;
    z-index: 2;
    padding: 5px 25px;
    bottom: 15px;
    left: 15px;
    right: 15px;

    button,
    div {
      margin: 0 5px;
      line-height: 33px;
      padding: 0;
    }

    button {
      float: right;
      width: 35px;
      height: 33px;
    }

    div {
      float: left;
      color: var(--sectionTextColour);
    }
  }

  .viewer-wrapper {
    overflow: hidden scroll;
    height: 100%;
    cursor: grab;
    position: relative;

    .highlight-area {
      position: absolute;
      background-color: #42adf5;
      opacity: 0.5;
    }
  }
}

.html-container {
  background-color: white;
  overflow: hidden auto;
  height: calc(100% - 70px);
}

.close-button {
  float: right;
  width: 90px !important;
}

</style>
