import PermissionType from '@commonServices/settings/PermissionType';

class Access {
	#permissions;

	definePermissions (permissions) {
		this.#permissions = permissions;
	}

	has (permission) {
		return this.#permissions.includes(permission);
	}
}

const access = new Access();

const can = new Proxy(access, {
	get (target, name, receiver) {
		if (!PermissionType[name]) {
			console.warn(`There is no permission with such name: ${name}`);
			return null;
		}
		return target.has(name);
	},
	set (target, name, value, receiver) {
		console.warn(`Can't set property ${name} - forbidden`);
		return false;
	},
});

function definePermissions (permissions) {
	access.definePermissions(permissions);
}

function defineAccess () {
	return can;
}

export { definePermissions, defineAccess };
