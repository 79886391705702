const PaymentStatus = Object.freeze({
	Draft: 0,
	Paid: 1,
	Edited: 2,
	Reversal: 3,
	StoppedAndReissued: 4,
});

const PaymentStatusText = Object.freeze({
	[PaymentStatus.Draft]: 'Draft',
	[PaymentStatus.Paid]: 'Paid',
	[PaymentStatus.Edited]: 'Edited',
	[PaymentStatus.Reversal]: 'Reversal',
	[PaymentStatus.StoppedAndReissued]: 'Stop/Reissue',
});

const PaymentStatusIcon = Object.freeze({
	[PaymentStatus.Draft]: '',
	[PaymentStatus.Paid]: 'fas fa-check',
	[PaymentStatus.Edited]: 'fas fa-pencil-alt',
	[PaymentStatus.Reversal]: 'fas fa-undo',
	[PaymentStatus.StoppedAndReissued]: 'fas fa-x',
});

const PaymentStatusColor = Object.freeze({
	[PaymentStatus.Draft]: 'draft',
	[PaymentStatus.Paid]: 'paid',
	[PaymentStatus.Edited]: 'edited',
	[PaymentStatus.Reversal]: 'reversal',
	[PaymentStatus.StoppedAndReissued]: 'stopped-and-reissued',
});

export { PaymentStatus, PaymentStatusIcon, PaymentStatusText, PaymentStatusColor };
