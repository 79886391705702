const PaymentInstructionStatus = Object.freeze({
	Pending: 'Pending',
	AttemptCreated: 'AttemptCreated',
	Issued: 'Issued',
	Success: 'Success',
	Failure: 'Failure',
	Clawback: 'Clawback',
	Stopped: 'Stopped',
	StopAttemptCreated: 'StopAttemptCreated',
	PendingStop: 'PendingStop',
	PendingReissue: 'PendingReissue',
});

const PaymentInstructionStatusText = Object.freeze({
	[PaymentInstructionStatus.Pending]: 'Pending',
	[PaymentInstructionStatus.AttemptCreated]: 'Attempt Created',
	[PaymentInstructionStatus.Issued]: 'Issued',
	[PaymentInstructionStatus.Success]: 'Success',
	[PaymentInstructionStatus.Failure]: 'Failure',
	[PaymentInstructionStatus.Clawback]: 'Clawback',
	[PaymentInstructionStatus.Stopped]: 'Stopped',
	[PaymentInstructionStatus.StopAttemptCreated]: 'Stop Attempt Created',
	[PaymentInstructionStatus.PendingStop]: 'Pending Stop',
	[PaymentInstructionStatus.PendingReissue]: 'Pending Reissue',
});

const PaymentInstructionStatusIcon = Object.freeze({
	[PaymentInstructionStatus.Pending]: 'fas fa-check',
	[PaymentInstructionStatus.AttemptCreated]: 'fas fa-check',
	[PaymentInstructionStatus.Issued]: 'fas fa-check',
	[PaymentInstructionStatus.Success]: 'fas fa-check',
	[PaymentInstructionStatus.Failure]: 'fas fa-x',
	[PaymentInstructionStatus.Clawback]: 'fas fa-x',
	[PaymentInstructionStatus.Stopped]: 'fas fa-x',
	[PaymentInstructionStatus.StopAttemptCreated]: 'fas fa-check',
	[PaymentInstructionStatus.PendingStop]: 'fas fa-check',
	[PaymentInstructionStatus.PendingReissue]: 'fas fa-check',
});

const PaymentInstructionStatusColor = Object.freeze({
	[PaymentInstructionStatus.Pending]: 'warning',
	[PaymentInstructionStatus.AttemptCreated]: 'warning',
	[PaymentInstructionStatus.Issued]: 'success',
	[PaymentInstructionStatus.Success]: 'success',
	[PaymentInstructionStatus.Failure]: 'danger',
	[PaymentInstructionStatus.Clawback]: 'danger',
	[PaymentInstructionStatus.Stopped]: 'danger',
	[PaymentInstructionStatus.StopAttemptCreated]: 'warning',
	[PaymentInstructionStatus.PendingStop]: 'warning',
	[PaymentInstructionStatus.PendingReissue]: 'warning',
});

export { PaymentInstructionStatus, PaymentInstructionStatusIcon, PaymentInstructionStatusText, PaymentInstructionStatusColor };
