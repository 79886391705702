<template>
	<div
		v-if="isPreAuth()"
		data-qa="pre-authorisation_icon"
		class="card-icon small mr-5"
		v-tooltip="'Pre-Authorisation'"
	>
		<div class="aqv-pre-authorisation" />
	</div>
</template>

<script>
import { ClaimFormType } from '@commonServices/models/ClaimFormType';

export default {
	name: 'PreAuthorisationIcon',
	props: {
		formType: {
			type: Number,
			required: true,
		},
	},
	methods: {
		isPreAuth () {
			return this.formType === ClaimFormType.PreAuthorisation;
		},
	},
};
</script>

<style lang="scss" scoped>
.aqv-pre-authorisation {
  color: #5b235b;
  align-self: center;
  font-size: 21px;
}
</style>
