<template>
	<aq-modal
		:name="name"
		:click-to-close="clickToClose"
	>
		<aq-modal-container
			@close="close"
			:title="title"
		>
			<div
				class="modal-content"
			>
				<div
					class="p-20"
				>
					<div class="multiline-content">
						{{ description }}
					</div>
				</div>
				<div
					:class="[footerClass, 'modal-bottom d-flex align-items-center px-20 py-10']"
				>
					<div
						class="ml-auto"
					>
						<button
							@click="close"
							type="button"
							:class="[btnClass, 'btn']"
						>
							{{ buttonLabel }}
						</button>
					</div>
				</div>
			</div>
		</aq-modal-container>
	</aq-modal>
</template>

<script>
export default {
	data () {
		return {
			promiseResolver: null,
		};
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		buttonLabel: {
			type: String,
			required: false,
			default: 'OK',
		},
		className: {
			type: String,
			required: false,
			default: 'danger',
		},
		clickToClose: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		btnClass () {
			return `btn-${this.className}`;
		},
		footerClass () {
			return `bg-${this.className}-darken`;
		},
	},
	methods: {
		show () {
			this.$modal.show(this.name);
			return new Promise((resolve, reject) => {
				this.promiseResolver = resolve;
			});
		},
		close () {
			this.$modal.hide(this.name);
			this.promiseResolver();
		},
	},
};
</script>
