<template>
	<div class="container-fluid">
		<div class="section-header row">
			<div class="icon">
				<i class="fas fa-table" />
			</div>
			<div class="section-header__title">
				Edit Filters
			</div>
		</div>
		<div class="section-content row">
			<div class="col">
				<aq-select
					data-qa="letterFilters_input_template"
					:options="$options.letterTypes"
					option-label="description"
					:label="filter.letterType.label"
					class="w-100"
					track-by="id"
					v-model="filter.letterType.value"
				/>
			</div>
			<div class="col">
				<aq-form-input
					data-qa="letterFilters_input_dateFrom"
					:label="filter.dateFrom.label"
					type="date"
					v-model="filter.dateFrom.value"
				/>
			</div>
			<div class="col">
				<aq-form-input
					data-qa="letterFilters_input_dateTo"
					:label="filter.dateTo.label"
					type="date"
					v-model="filter.dateTo.value"
				/>
			</div>
			<div class="col">
				<aq-select
					data-qa="letterFilters_input_status"
					:options="claimStatuses"
					option-label="description"
					:label="filter.reportClaimStatus.label"
					class="w-100"
					track-by="id"
					:multiple="filter.reportClaimStatus.multiselect"
					v-model="filter.reportClaimStatus.value"
				/>
			</div>
			<div class="col">
				<aq-select
					data-qa="letterFilters_input_recipientType"
					:options="recipientTypes"
					option-label="description"
					:label="filter.letterRecipientType.label"
					class="w-100"
					track-by="id"
					:multiple="filter.letterRecipientType.multiselect"
					v-model="filter.letterRecipientType.value"
				/>
			</div>
			<div class="col">
				<aq-select
					data-qa="letterFilters_input_zippedStatus"
					:options="$options.zippedFileStatus"
					option-label="description"
					:label="filter.zippedFileStatus.label"
					class="w-100"
					track-by="id"
					:multiple="filter.zippedFileStatus.multiselect"
					v-model="filter.zippedFileStatus.value"
				/>
			</div>
			<div class="col">
				<aq-select
					data-qa="filter_input_brand"
					:options="brands"
					:label="filter.brand.label"
					option-label="description"
					class="w-100"
					v-model="filter.brand.value"
					track-by="id"
					:multiple="filter.brand.multiselect"
					no-result-message="No Brands found"
				/>
			</div>
		</div>
		<div class="row section-header d-flex justify-content-end">
			<button
				class="btn btn-row mr-8"
				@click="$emit('cancel')"
				data-qa="letterFilters_button_cancel"
			>
				Cancel
			</button>
			<button
				class="btn btn-primary"
				@click="$emit('apply-filters')"
				data-qa="letterFilters_button_run"
				:disabled="runDisabled"
			>
				Run
			</button>
		</div>
	</div>
</template>
<script>

import { letterTypes } from '@commonServices/models/LetterType';
import LettersService from '@commonServices/lettersService';

export default {
	props: {
		filter: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			brands: [],
			claimStatuses: [],
			recipientTypes: [],
		};
	},
	letterTypes: letterTypes.map(item => ({ id: item.value, description: item.description })),
	zippedFileStatus: [
		{ id: 'Include', description: 'Include' },
		{ id: 'Exclude', description: 'Exclude' },
	],
	computed: {
		runDisabled () {
			return Object.values(this.filter)
				.every((value) => !value.value);
		},
	},
	async mounted () {
		this.filterDictionaries = await LettersService.getFiltersLookupData();
		this.brands = this.filterDictionaries.Brand || [];
		this.claimStatuses = this.filterDictionaries.ClaimStatus || [];
		this.recipientTypes = this.filterDictionaries.LetterRecipient || [];
	},
};
</script>

<style lang="scss" scoped>
  .icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: $primary;
    font-size: 26px;
  }

  .section-header {
    background: var(--headerItemBg);
  }
</style>
