const commonToastOptions = {
	iconPack: 'fontawesome',
	position: 'bottom-right',
};

const toastActions = {
	close: {
		icon: 'times',
		onClick: (e, toastObject) => {
			toastObject.goAway(0);
		},
	},
};

const toastErrorOptions = {
	type: 'error',
	action: [
		toastActions.close,
	],
	icon: 'exclamation-triangle',
	duration: 10000,
};

// register toasts for reuse
function initToasted (toasted) {
	toasted.register('common_error',
		payload => {
		// if there is no message passed show default message
			if (!payload.message) {
				return 'Oops.. Something Went Wrong..';
			}

			// if there is a message show it with the message
			return payload.message;
		}, toastErrorOptions);
}

export { initToasted, commonToastOptions, toastActions };
