<template>
	<div>
		<aq-select
			data-qa="aqLossTypes_input_losstype"
			:options="lossTypes"
			label="Loss Type"
			option-label="firstCause"
			:is-valid="isValid"
			class="w-100"
			:value="value"
			@input="input($event)"
			:disabled="disabled"
		/>
	</div>
</template>

<script>
import { ValidationMessages } from '@commonServices/utils/constants';
import { mapActions, mapState } from 'vuex';

const errorMessages = {
	selectedLossType: {
		required: ValidationMessages.required,
	},
};

export default {
	name: 'AqLossTypes',
	props: {
		value: {
			type: Object,
			required: false,
			default: null,
		},
		isValid: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	mounted () {
		this.loadLossTypes();
	},
	computed: {
		...mapState(['lossTypes']),
		errorMessages () {
			return errorMessages;
		},
	},
	methods: {
		...mapActions(['loadLossTypes']),
		input (value) {
			this.$emit('input', value);
		},
	},
};
</script>
