<template>
	<div class="position-absolute w-100">
		<div class="section-content">
			<div class="my-10">
				<aq-select
					label="Type"
					:options="getFilterOptions('Type')"
					:value="selectedFilter.type"
					track-by="key"
					option-label="value"
					@input="onUpdateFilter($event, 'type')"
					no-result-message="No Type found"
					data-qa="noteFilter_select_type"
				/>
			</div>
			<div class="my-10">
				<aq-select
					label="User"
					:options="getFilterOptions('User')"
					:value="selectedFilter.users"
					option-label="description"
					multiple
					track-by="id"
					@input="onUpdateFilter($event, 'users')"
					no-result-message="No User found"
					data-qa="noteFilter_select_user"
				/>
			</div>
			<aq-date-filter
				ref="dateFilter"
				:value="selectedFilter.date"
				@input="onUpdateFilter($event, 'date')"
			/>
			<div class="row">
				<div class="col d-flex">
					<div class="pt-20 pb-2 ml-auto">
						<button
							class="btn btn-secondary mr-10"
							@click="onResetFilters"
							data-qa="noteFilter_button_clear"
						>
							Clear
						</button>
						<button
							class="btn btn-primary"
							@click="onApplyFilters"
							:disabled="!isValid()"
							data-qa="noteFilter_button_filter"
						>
							Filter
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import cloneDeep from 'lodash.clonedeep';

export default {
	name: 'NoteFilter',
	props: {
		filterOptions: {
			type: Array,
			required: true,
		},
		filter: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			selectedFilter: this.filter,
		};
	},
	methods: {
		isValid () {
			return this.$refs.dateFilter && this.$refs.dateFilter.isValid;
		},
		onResetFilters () {
			this.selectedFilter = {
				type: null,
				users: [],
				date: { label: 'Dates' },
			};
			this.$refs.dateFilter.dateFilter = { label: 'Dates' };
			this.$emit('filter-changed', cloneDeep(this.selectedFilter));
		},
		getFilterOptions (filterLabel) {
			return this.filterOptions.filter((item) => item.filterLabel === filterLabel)[0].filterOptions;
		},
		onUpdateFilter (value, key) {
			this.selectedFilter[key] = value;
			this.$emit('filter-changed', cloneDeep(this.selectedFilter));
		},
		onApplyFilters () {
			if (this.isValid()) {
				this.$emit('apply-filters');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .note-filter {
    z-index: 10;
    top: 0;
    max-height: 35vh;
  }
</style>
