<template>
	<div class="section-content ml-25 payment-transactions">
		<aq-modal name="stop-payment">
			<base-payment-modal
				title="Stop/Reissue Payment"
				finish-btn-label="Proceed"
				:payment="selectedPayment"
				:recipients="recipients"
				:disabled="disableStopAndReissue(selectedPayment)"
				@finish="onStopPayment"
			>
				<div class="text-center">
					<div v-if="brand.brandManualStopReissue">
						<i class="fas fa-exclamation-triangle fs-40 mb-10" />
						<p class="stop-payment-label mb-20">
							You are about to <br>Stop/Reissue this Payment
						</p>
						<div class="manual-stop-reissue mb-20 px-25 py-15">
							<p class="fs-16 font-weight-bold">
								This payment cannot be automatically Stopped.
							</p>
							<div class="fs-14">
								<div v-if="canProceedManualStopAndReissuePayment(selectedPayment)">
									Confirm if the payment has been manually stopped.
								</div>
								<div v-else>
									Please raise the request to Finance to Stop manually.
								</div>
							</div>
						</div>
						<p class="mb-6">
							Please confirm the following account details are correct:
						</p>
					</div>
					<div v-else>
						<p class="stop-payment-label mb-20">
							You are about to <br>Stop/Reissue this Payment
						</p>
						<p class="mb-6">
							Please confirm the following account details are correct:
						</p>
					</div>
				</div>
			</base-payment-modal>
		</aq-modal>
		<div class="container-fluid">
			<el-table
				ref="transactionHistory"
				empty-text="The Transaction History table is empty"
				:data="transactions"
				data-qa="transactionHistory_table_transactionHistory"
				:row-class-name="getRowClassName"
			>
				<el-table-column
					label="Payee"
					data-qa="transactionHistory_column_payee"
				>
					<template slot-scope="scope">
						<div class="text-break">
							{{ scope.row.payee }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Payment Method"
					data-qa="transactionHistory_column_paymentMethod"
				>
					<template slot-scope="scope">
						<div>{{ getPaymentMethod(scope.row) }}</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Payment Type"
					data-qa="transactionHistory_column_paymentType"
					width="100"
				>
					<template slot-scope="scope">
						<div>{{ scope.row.paymentType === 1 ? 'Claim' : 'Ex Gratia' }}</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Payment Account Name"
					data-qa="transactionHistory_column_paymentAccountName"
				>
					<template slot-scope="scope">
						<div class="text-break">
							{{ scope.row.paymentAccountName }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					label="Payment Amount"
					data-qa="transactionHistory_column_paymentAmount"
					width="140"
				>
					<template slot-scope="scope">
						<div class="text-left">
							{{ scope.row.grossAmount | currency(locale) }}
						</div>
					</template>
				</el-table-column>
				<template v-if="billingSyncEnabled">
					<el-table-column
						label="Status"
						width="200"
						data-qa="transactionHistory_column_status"
					>
						<template slot-scope="scope">
							<div v-if="scope.row.lastInstruction">
								<payment-instruction-status-label
									:payment-instruction-status="scope.row.lastInstruction.status"
								/>
							</div>
						</template>
					</el-table-column>
					<el-table-column
						width="40"
						data-qa="transactionHistory_column_buttons"
					>
						<template slot-scope="scope">
							<button
								type="button"
								:class="['expand-icon btn small btn-primary', {'expand-icon--expanded': expandedRowsFlags.find((item) => item.key === scope.$index).isExpanded}]"
								@click="toggleExpansion(scope)"
								data-qa="transactionHistory_button_expandInstructions"
							>
								<i :class="['fas', expandedRowsFlags.find((item) => item.key === scope.$index).isExpanded ? 'fa-angle-down' : 'fa-angle-right']" />
							</button>
						</template>
					</el-table-column>
					<el-table-column
						type="expand"
						width="20"
						data-qa="transactionHistory_column_expandInstructions"
					>
						<template slot-scope="scope">
							<div :class="getInstructionClassName(scope.row)">
								<el-table
									ref="transactionInstructions"
									empty-text="The Transaction Instructions table is empty"
									:data="scope.row.instructions"
									data-qa="transactionHistory_table_transactionInstructions"
								>
									<el-table-column
										label="Payment Issue Date"
										width="150"
										data-qa="transactionInstruction_column_paymentIssueDate"
									>
										<template slot-scope="props">
											<div>
												<div>{{ props.row.paymentIssueDate | shortDate }}</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column
										label="Payee Name"
										data-qa="transactionInstruction_column_payeeName"
									>
										<template slot-scope="props">
											<div class="text-break">
												{{ props.row.payeeName }}
											</div>
										</template>
									</el-table-column>
									<el-table-column
										label="Payee Address"
										data-qa="transactionInstruction_column_payeeAddress"
									>
										<template slot-scope="props">
											<div>
												<truncated-text-cell :value="props.row.address" />
											</div>
										</template>
									</el-table-column>
									<el-table-column
										label="Payment Method"
										width="130"
										data-qa="transactionInstruction_column_paymentMethod"
									>
										<template slot-scope="props">
											<div>
												{{ getPaymentMethodDescription(props.row) }}
											</div>
										</template>
									</el-table-column>
									<el-table-column
										label="Transaction Id"
										width="120"
										data-qa="transactionInstruction_column_transactionId"
									>
										<template slot-scope="props">
											<div>
												<div>{{ props.row.transactionId }}</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column
										label="Batch Date & Time"
										data-qa="transactionInstruction_column_batchDateTime"
										class-name="bg-batch"
										width="200"
									>
										<template slot-scope="props">
											<div>{{ getBatchDateTime(props.row.batchDateTime) }}</div>
										</template>
									</el-table-column>
									<el-table-column
										label="Status"
										width="200"
										data-qa="transactionInstruction_column_status"
									>
										<template slot-scope="props">
											<div>
												<payment-instruction-status-label
													:payment-instruction-status="props.row.status"
												/>
											</div>
										</template>
									</el-table-column>
									<el-table-column
										data-qa="transactionInstruction_column_actions"
										width="200"
									>
										<template slot-scope="props">
											<button
												v-if="isStopBtnAvailable(scope.row, props.row)"
												type="button"
												class="btn action-btn stop-payment"
												@click="onStopPaymentModal(scope.row)"
											>
												<i class="aqv-stop-reissue fs-24 mr-14" />
												<span>Stop/Reissue</span>
											</button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</template>
					</el-table-column>
				</template>
			</el-table>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { shortDateTime } from '@commonServices/utils/filters';
import { PaymentStatus, PaymentStatusColor } from '@commonServices/models/PaymentStatus';
import { PaymentInstructionStatus, PaymentInstructionStatusColor } from '@commonServices/models/PaymentInstructionStatus';
import BasePaymentModal from '@commonView/ClaimPage/Shared/BasePaymentModal';
import PaymentInstructionStatusLabel from '@commonView/ClaimPage/ClaimPayment/PaymentInstructionStatusLabel';
import { claimNumberStatus } from '@commonServices/models/ClaimStatusActionEnum';
import ClaimService from '@commonServices/claimService';
import { PaymentMethod } from '@commonServices/models/PaymentMethod';

export default {
	name: 'PaymentTransactions',
	components: {
		BasePaymentModal,
		PaymentInstructionStatusLabel,
	},
	props: {
		transactions: {
			type: Array,
			required: true,
		},
		paymentTypes: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		recipients: {
			type: Array,
			required: true,
		},
		claimStatus: {
			type: Number,
			required: true,
		},
		billingSyncEnabled: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			selectedPayment: null,
			expandedRowsFlags: [],
		};
	},
	mounted () {
		this.resetRowsFlags();
	},
	computed: {
		...mapState(['brand']),
	},
	methods: {
		resetRowsFlags () {
			this.expandedRowsFlags = this.transactions.map((_, index) => {
				return {
					key: index,
					isExpanded: false,
				};
			}).filter(Boolean);
		},
		getPaymentMethodDescription (paymentInfo) {
			return this.paymentTypes.find((type) => type.value === paymentInfo.paymentMethod).description;
		},
		getPaymentMethod (paymentInfo) {
			let paymentMethodDescription = this.getPaymentMethodDescription(paymentInfo);
			if (this.billingSyncEnabled
			&& paymentInfo.lastInstruction
			&& (paymentInfo.paymentStatus === PaymentStatus.Paid || paymentInfo.paymentStatus === PaymentStatus.StoppedAndReissued)) {
				const { paymentIssueDate, transactionId } = paymentInfo.lastInstruction;
				paymentMethodDescription = `${paymentMethodDescription} (No: ${transactionId || 'N/A'}, Printed Date: ${shortDateTime(paymentIssueDate) || 'N/A'})`;
			}
			return paymentMethodDescription;
		},
		getBatchDateTime (batchDateTime) {
			return batchDateTime ? `${shortDateTime(batchDateTime)}` : 'N/A';
		},
		getRowClassName ({ row, rowIndex }) {
			let className = '';
			if (row.lastInstruction) {
				className = PaymentInstructionStatusColor[row.lastInstruction.status].toLowerCase();
			} else {
				className = PaymentStatusColor[row.paymentStatus].toLowerCase();
			}

			const expandedRow = this.expandedRowsFlags.find((item) => item.key === rowIndex);
			if (expandedRow?.isExpanded) {
				className = '';
			}

			if (row.paymentStatus !== PaymentStatus.Paid
			&& row.paymentStatus !== PaymentStatus.StoppedAndReissued) {
				className += ' payment-row-disabled';
			}

			return `payment-border ${className}`;
		},
		getInstructionClassName (row) {
			let className = '';
			if (row.lastInstruction) {
				className = PaymentInstructionStatusColor[row.lastInstruction.status].toLowerCase();
			}
			return `instructions-container ${className}`;
		},
		toggleExpansion (scope) {
			this.$refs.transactionHistory.toggleRowExpansion(scope.row);
			const expandedRow = this.expandedRowsFlags.find((item) => item.key === scope.$index);
			expandedRow.isExpanded = !expandedRow.isExpanded;
		},
		disableStopAndReissue (payment) {
			return this.brand.brandManualStopReissue && !this.canProceedManualStopAndReissuePayment(payment);
		},
		canProceedManualStopAndReissuePayment (payment) {
			return payment && (this.$can.ManualStopAndReissueChequePayment || !payment.lastInstruction.batchDateTime);
		},
		isStopBtnAvailable (payment, instruction) {
			return (this.$can.StopAndReissueChequePayment || this.$can.EditPayment || this.$can.ManualStopAndReissueChequePayment)
			&& payment.lastInstruction
			&& payment.lastInstruction.id === instruction.id
			&& payment.grossAmount > 0
			&& (payment.paymentStatus === PaymentStatus.Paid || payment.paymentStatus === PaymentStatus.StoppedAndReissued)
			&& this.claimStatus === claimNumberStatus.ClosedPaid
			&& (instruction.status === PaymentInstructionStatus.Pending || instruction.status === PaymentInstructionStatus.AttemptCreated)
			&& (instruction.paymentMethod === PaymentMethod.Cheque || (instruction.paymentMethod === PaymentMethod.Bacs && !instruction.batchDateTime));
		},
		onStopPaymentModal (payment) {
			this.selectedPayment = payment;
			this.$modal.show('stop-payment');
		},
		async onStopPayment (paymentId, paymentAccountId) {
			await ClaimService.stopAndReissuePayment(paymentId, paymentAccountId);
			this.resetRowsFlags();
			this.$emit('finish');
		},
	},
};
</script>

<style lang="scss">
.payment-transactions {
  td.bg-batch {
    background-color: var(--progressBar);
    background-clip: content-box;
    padding: 8px 10px;
  }

  .btn-disabled {
    .fa-ban {
      position: absolute;
      left: 12px;
      top: 5px;
      font-size: 32px;
      z-index: 2;
    }
  }

  .expand-icon {
    &.expand-icon--expanded {
      background-color: $warning;
    }
  }

  .el-table__expanded-cell {
    padding: 0;
    border-left: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
  }

  .payment-row-disabled {
    opacity: 0.7;
  }

  tbody > tr {
    height: 66px;
  }

  tr.payment-border {
    &.reversal {
      td {
        border-bottom: 2px solid var(--paymentWarning);
      }
    }

    &.paid {
      td {
        border-bottom: 2px solid var(--paymentSuccess);
      }
    }

    &.edited {
      td {
        border-bottom: 2px solid var(--cardIconColour);
      }
    }

    &.stopped-and-reissued {
      td {
        border-bottom: 2px solid var(--paymentSuccess);
      }
    }

    &.warning {
      td {
        border-bottom: 2px solid var(--paymentWarning);
      }
    }

    &.success {
      td {
        border-bottom: 2px solid var(--paymentSuccess);
      }
    }

    &.danger {
      td {
        border-bottom: 2px solid var(--paymentDanger);
      }
    }
  }

  .instructions-container {
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--searchBg);

    th {
      background-color: var(--searchBg);
    }

    &.warning {
      border-bottom: 2px solid var(--paymentWarning);
    }

    &.success {
      border-bottom: 2px solid var(--paymentSuccess);
    }

    &.danger {
      border-bottom: 2px solid var(--paymentDanger);
    }
  }

}

.action-btn {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.stop-payment {
    background-color: var(--backgroundDanger);
  }
}

.stop-payment-label {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.manual-stop-reissue {
  border: 2px solid var(--tableGroupBg);
  line-height: 1.4;
}
</style>
