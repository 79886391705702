import { PaymentMethod } from '@commonServices/models/PaymentMethod';
import { IconBacs, IconCheque } from '@assets/icons';

const ThemeIndex = {
	Dark: 0,
	Light: 1,
};

const ThemeIconsSettings = {
	[ThemeIndex.Dark]: {
		NoMembers: 'NoMembersDark',
		NoQueues: 'NoQueuesDark',
		NoTeams: 'NoTeamsDark',
		NoTasks: 'NoTasksDark',
		DynamicFilters: 'DynamicFiltersDark',
		ConditionsSelector: 'ConditionsSelectorDark',
		TaskNotification: 'TaskNotificationBackgroundDark',
		Timer: 'IconTimerDark',
	},
	[ThemeIndex.Light]: {
		NoMembers: 'NoMembers',
		NoQueues: 'NoQueues',
		NoTeams: 'NoTeams',
		NoTasks: 'NoTasks',
		DynamicFilters: 'DynamicFilters',
		ConditionsSelector: 'ConditionsSelector',
		TaskNotification: 'TaskNotificationBackground',
		Timer: 'IconTimer',
	},
};

const PaymentMethodIconSettings = {
	[PaymentMethod.Cheque]: IconCheque,
	[PaymentMethod.Bacs]: IconBacs,
};

export { ThemeIndex, ThemeIconsSettings, PaymentMethodIconSettings };
