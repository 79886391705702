<template>
	<div>
		<div
			v-if="isEdit"
			class="d-flex align-items-center"
		>
			<aq-input
				:value="value"
				:input-class="inputClass"
				:placeholder="placeholder"
				class="w-100"
				@input="$emit('input', $event)"
			/>
		</div>
		<template v-else>
			<slot name="label">
				<div class="w-100">
					{{ value }}
				</div>
			</slot>
		</template>
	</div>
</template>

<script>
export default {
	name: 'AqEditableLabel',
	props: {
		value: {
			type: [String, Number],
			required: false,
			default: '',
		},
		isEdit: {
			type: Boolean,
			required: false,
			default: true,
		},
		inputClass: {
			type: String,
			required: false,
			default: 'aq-editable-input',
		},
		placeholder: {
			type: String,
			required: false,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
  ::v-deep .aq-editable-input {
    border: none;
    outline: none;
    background: var(--bodyBg);
    font-size: 26px;
  }
</style>
