<template>
	<div :class="{ 'line-item-edit': isEditing }">
		<div class="row no-gutters attribute py-10 mb-10 align-items-center">
			<div class="col-6 pl-10">
				{{ label }}
			</div>
			<div class="col-2">
				{{ totalDiscount | currency(locale) }}
			</div>
			<div class="col-4 pr-10">
				<div
					v-if="!isReadOnlyMode"
					class="circle float-right"
					:class="[ isEditing ? 'edited' : 'original']"
					@click="onEdit"
					v-tooltip="'Edit'"
				>
					<i class="fas fa-pencil-alt" />
				</div>
			</div>
		</div>
		<div
			v-if="isEditing"
			class="container-fluid pt-24 section-content"
		>
			<div class="row no-gutters">
				<div class="discount-input-container d-flex">
					<div class="discount-input">
						<aq-form-input
							:label="label"
							icon-class="text-primary fa-lg"
							v-model="totalDiscountEdit"
							type="money"
							:is-valid="!$v.totalDiscountEdit.$error"
							:locale="locale"
							@input="$v.totalDiscountEdit.$touch"
							@focused="onFocus(recognizedField)"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages"
							:validator="$v"
							property="totalDiscountEdit"
						/>
					</div>
					<aq-checkbox
						data-qa="totalDiscount-checkbox-showDiscountSplit"
						class="show-split-checkbox"
						:value="showDiscountSplit"
						label="Apply to rows"
						@input="$emit('select-split-discount', $event)"
					/>
				</div>
			</div>
			<div class="d-flex justify-content-end mt-15">
				<button
					class="btn btn-secondary mr-10"
					type="button"
					@click="onCancel"
				>
					Cancel
				</button>
				<div v-tooltip="isFinishDisabled ? 'Discount greater than line item values' : ''">
					<button
						class="btn btn-primary"
						type="button"
						@click="onFinish"
						:disabled="isFinishDisabled"
					>
						Finish
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { moreThenOrEqual } from '@commonServices/utils/validators';
import { roundCurrency } from '@commonServices/utils/filters';

export default {
	name: 'InvoiceTotalDiscountLine',
	inject: ['setScannedItemCoordinates'],
	data () {
		return {
			isEditing: false,
			totalDiscountEdit: null,
		};
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		recognizedField: {
			type: Object,
			required: false,
			default: null,
		},
		totalDiscount: {
			type: Number,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			default: false,
		},
		showDiscountSplit: {
			type: Boolean,
			default: false,
		},
		selectedLineItems: {
			type: Array,
			default: () => [],
		},
	},
	validations () {
		return {
			totalDiscountEdit: {
				required,
				minValue: moreThenOrEqual(0),
			},
		};
	},
	computed: {
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		errorMessages () {
			return {
				totalDiscountEdit: {
					minValue: ValidationMessages.noNegative,
				},
			};
		},
		discountsSplit () {
			if (!this.showDiscountSplit || !this.selectedLineItems.length) {
				this.$emit('discounts-split-update', []);
				return [];
			}
			const totalSumAmount = this.selectedLineItems.reduce((partialSum, a) => partialSum + a.amount, 0);

			const result = this.selectedLineItems.map(value => {
				const proportion = value.amount / totalSumAmount;
				const discount = roundCurrency(this.totalDiscountEdit * proportion);

				return {
					...value,
					discount,
					invalid: value.amount < discount,
				};
			});

			result[0].percent = 100 - result.slice(1).reduce((partialSum, a) => partialSum + a.percent, 0);
			result[0].discount = this.totalDiscountEdit - result.slice(1).reduce((partialSum, a) => partialSum + a.discount, 0);
			result[0].invalid = result[0].amount < result[0].discount;

			this.$emit('discounts-split-update', result);
			return result;
		},
		isFinishDisabled () {
			return this.discountsSplit.some(item => item.invalid);
		},
	},
	methods: {
		onEdit () {
			this.isEditing = true;
			this.totalDiscountEdit = this.totalDiscount;
		},
		onCancel () {
			this.isEditing = false;
			this.$emit('select-split-discount', false);
		},
		onFinish () {
			this.$v.totalDiscountEdit.$touch();
			if (!this.$v.totalDiscountEdit.$error) {
				this.isEditing = false;
				this.$emit('on-total-item-edit', { value: this.totalDiscountEdit });

				this.$emit('select-split-discount', false);
			}
		},
		onFocus (field) {
			if (field?.boundingBox) {
				this.setScannedItemCoordinates(field.boundingBox, field.page);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.discount-input-container {
  width: 100%;
  display: flex;
  gap: 10px;

  .discount-input {
    width: 33%;
    margin-left: 10px;
    position: relative;
  }

  .show-split-checkbox {
    align-self: center;
  }
}
</style>
