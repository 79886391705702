export default class PolicySectionGraph {
	constructor (
		policySectionId,
		description,
		type,
		level,
		limitType,
		productType,
		productLevel,
		sectionLimit,
		noMaximumLimit,
		segments,
		sectionLimitEndsDate,
		unit,
	) {
		this.policySectionId = policySectionId;
		this.description = description;
		this.type = type;
		this.level = level;
		this.limitType = limitType;
		this.productType = productType;
		this.productLevel = productLevel;
		this.sectionLimit = sectionLimit;
		this.noMaximumLimit = noMaximumLimit;
		this.segments = segments;
		this.sectionLimitEndsDate = sectionLimitEndsDate;
		this.unit = unit;
	}
};
