<template>
	<aq-modal :name="name">
		<aq-modal-container
			@close="close('cross')"
			title="Record eligible conditions"
		>
			<div class="modal-content">
				<div class="pt-20 pb-5 pl-30">
					<div class="row no-gutters">
						<div class="col-12 font-weight-bold">
							Please select conditions to record as Eligible
						</div>
					</div>
				</div>
				<div class="condition-list">
					<div
						v-for="condition of eligibleConditions"
						:key="condition.conditionId"
						class="condition-list__item"
					>
						<aq-checkbox
							class="tick my-10 w-100 text-truncate"
							v-model="condition.isSelected"
							:label="condition.description"
						/>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="close('no')"
						type="button"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						@click="onFinish"
						type="button"
						class="btn btn-success ml-auto"
					>
						Finish
					</button>
				</div>
			</div>
		</aq-modal-container>
	</aq-modal>
</template>

<script>
import { ClaimConditionState } from '@clientCommon/services/models/ClaimConditionState';
import ProductType from '@clientCommon/services/models/ProductType';
import { toClaimConditionDescription } from '@clientCommon/services/utils/converter';

export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		claim: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			promiseResolver: null,
			eligibleConditions: null,
			claimInfo: null,
		};
	},
	methods: {
		show () {
			this.eligibleConditions = this.claim.conditions
				.filter(condition =>
					condition.state === ClaimConditionState.Proceed
					&& !condition.eligibleConditionId
					&& condition.conditionType.description !== ProductType.Wellness)
				.map(condition => ({
					conditionId: condition.id,
					description: toClaimConditionDescription(condition),
					isSelected: false,
				}));

			if (this.eligibleConditions.length > 0) {
				this.$modal.show(this.name);

				return new Promise((resolve) => {
					this.promiseResolver = resolve;
				});
			}

			return new Promise((resolve) => resolve(''));
		},
		close (result) {
			this.$modal.hide(this.name);
			this.promiseResolver(result);
		},
		onFinish () {
			this.$emit('finished', this.eligibleConditions);
			this.close('finish');
		},
	},
};
</script>

<style lang="scss" scoped>
.condition-list {
  margin: 0 30px 10px;
  font-size: 13px;

  .condition-list__item {
    display: flex;
    background: var(--attributeBg);
    padding: 0 11px;
    margin: 5px 0;
    border-radius: 3px;
  }
}
</style>
