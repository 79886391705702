export default class NewPayeeInput {
	constructor (paymentMethod,
		payee,
		sortCode,
		accountNumber,
		bankName,
		locale,
		isActive,
	) {
		this.paymentMethod = paymentMethod;
		this.payee = payee;
		this.sortCode = sortCode;
		this.accountNumber = accountNumber;
		this.bankName = bankName;
		this.locale = locale;
		this.isActive = isActive;
	}
};
