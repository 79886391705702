const PermissionType = Object.freeze({
	Search: 'Search',
	ViewCustomerAndPet: 'ViewCustomerAndPet',
	ViewClaims: 'ViewClaims',
	ViewClaimData: 'ViewClaimData',
	ViewInteractions: 'ViewInteractions',
	ClaimEdit: 'ClaimEdit',
	CreateNewVet: 'CreateNewVet',
	EditMissingInformation: 'EditMissingInformation',
	FilesManagement: 'FilesManagement',
	ViewFiles: 'ViewFiles',
	ManualRejectClaim: 'ManualRejectClaim',
	ManualFraudCheck: 'ManualFraudCheck',
	ClaimEditContinuation: 'ClaimEditContinuation',
	EditMissingInformationContinuation: 'EditMissingInformationContinuation',
	FilesManagementContinuation: 'FilesManagementContinuation',
	ScanClaimForm: 'ScanClaimForm',
	EditClaimFormRecognition: 'EditClaimFormRecognition',
	PopulateClaimForm: 'PopulateClaimForm',
	ScanVetInvoice: 'ScanVetInvoice',
	EditInvoiceRecognition: 'EditInvoiceRecognition',
	AddClaimItems: 'AddClaimItems',
	EditClaimItems: 'EditClaimItems',
	PopulateClaimItemsFromInvoiceScan: 'PopulateClaimItemsFromInvoiceScan',
	ManagePaymentAccount: 'ManagePaymentAccount',
	AddPayment: 'AddPayment',
	ViewManualRejectClaim: 'ViewManualRejectClaim',
	ApproveManualRejectClaim: 'ApproveManualRejectClaim',
	RejectManualRejectClaim: 'RejectManualRejectClaim',
	ViewFraudCheck: 'ViewFraudCheck',
	ApproveManualFraudCheck: 'ApproveManualFraudCheck',
	RejectManualFraudCheck: 'RejectManualFraudCheck',
	ViewClaimPayment: 'ViewClaimPayment',
	ApproveClaimPayment: 'ApproveClaimPayment',
	RejectClaimPayment: 'RejectClaimPayment',
	ViewMissingInformation: 'ViewMissingInformation',
	ChaseMissingInformation: 'ChaseMissingInformation',
	ViewReassessPendingCard: 'ViewReassessPendingCard',
	ReassessClaim: 'ReassessClaim',
	RequestReassessClaim: 'RequestReassessClaim',
	ViewReport: 'ViewReport',
	ExportReport: 'ExportReport',
	SaveReport: 'SaveReport',
	ViewPreExistingCondition: 'ViewPreExistingCondition',
	ViewEligibleCondition: 'ViewEligibleCondition',
	ModifyPreExistingCondition: 'ModifyPreExistingCondition',
	ModifyEligibleCondition: 'ModifyEligibleCondition',
	ViewNotes: 'ViewNotes',
	ViewLetters: 'ViewLetters',
	ManageLetters: 'ManageLetters',
	ZipLetters: 'ZipLetters',
	AddNotes: 'AddNotes',
	EditNotes: 'EditNotes',
	DeleteNotes: 'DeleteNotes',
	EditAlertNotes: 'EditAlertNotes',
	DeleteAlertNotes: 'DeleteAlertNotes',
	ArchivePreExistingCondition: 'ArchivePreExistingCondition',
	ArchiveEligibleCondition: 'ArchiveEligibleCondition',
	OverrideClaimRejection: 'OverrideClaimRejection',
	ViewDraftClaims: 'ViewDraftClaims',
	DeleteReport: 'DeleteReport',
	CreateQueue: 'CreateQueue',
	DeleteQueue: 'DeleteQueue',
	ViewTeams: 'ViewTeams',
	EditTeams: 'EditTeams',
	DeleteTeams: 'DeleteTeams',
	AssignTaskToMe: 'AssignTaskToMe',
	AssignTaskToUser: 'AssignTaskToUser',
	UnAssignTask: 'UnAssignTask',
	EditExGratia: 'EditExGratia',
	ViewAppealNotes: 'ViewAppealNotes',
	UpdatePetDateOfDeath: 'UpdatePetDateOfDeath',
	UnlimitedTasksAssignment: 'UnlimitedTasksAssignment',
	GenerateHospitalNotification: 'GenerateHospitalNotification',
	ViewAdjusterComment: 'ViewAdjusterComment',
	SaveAdjusterComment: 'SaveAdjusterComment',
	PolicyAdminUpdateCustomer: 'PolicyAdminUpdateCustomer',
	PolicyAdminUpdatePet: 'PolicyAdminUpdatePet',
	PolicyAdminUpdateProduct: 'PolicyAdminUpdateProduct',
	EditPayment: 'EditPayment',
	FlagFastTrackReviewed: 'FlagFastTrackReviewed',
	ViewAllAutomaticQueuesStat: 'ViewAllAutomaticQueuesStat',
	ViewProducts: 'ViewProducts',
	EditProducts: 'EditProducts',
	ViewPaymentDetails: 'ViewPaymentDetails',
	DeleteFiles: 'DeleteFiles',
	AddVetAlert: 'AddVetAlert',
	FlagEscalated: 'FlagEscalated',
	EditDashboardNotes: 'EditDashboardNotes',
	RemoveFlagEscalated: 'RemoveFlagEscalated',
	ViewScannedData: 'ViewScannedData',
	ManageOthersState: 'ManageOthersState',
	EditCareFlags: 'EditCareFlags',
	RebalanceExcess: 'RebalanceExcess',
	UngroupClaim: 'UngroupClaim',
	AllowFinalizeClaims: 'AllowFinalizeClaims',
	StopAndReissueChequePayment: 'StopAndReissueChequePayment',
	ManualStopAndReissueChequePayment: 'ManualStopAndReissueChequePayment',
	ExportQueue: 'ExportQueue',
});

export default PermissionType;
