<template>
	<div class="d-flex">
		<div
			v-for="option in options"
			:key="option.id"
			class="d-flex align-items-center mx-10"
		>
			<aq-checkbox
				class="round medium mx-4"
				:disabled="disabled"
				:value="isSelected(option)"
				@input="select(option)"
				:data-qa="`${option.label.replace(' ','')}_input`"
			/>
			<slot
				name="content"
				:value="option"
				:is-selected="isSelected"
				:select="select"
			>
				<div
					class="mx-4"
					:class="{'cursor-pointer' : !disabled}"
					@click="select(option)"
				>
					{{ option.label }}
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AqMultiCheckBox',
	props: {
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			required: true,
			default: () => false,
		},
		value: {
			type: Array,
			required: true,
		},
		multiple: {
			type: Boolean,
			required: false,
			default: true,
		},
		trackBy: {
			type: String,
			required: false,
			default: 'id',
		},
	},
	computed: {
		valueKeys () {
			if (this.trackBy) {
				return this.value.map(element => element[this.trackBy]);
			} else {
				return this.value;
			}
		},
	},
	methods: {
		select (option) {
			if (this.disabled) {
				return;
			}
			const isSelected = this.isSelected(option);

			if (this.multiple) {
				// if option is selected - filter the array of options to exclude the selected
				const newValue = isSelected
					? this.value.filter(val => this.trackBy ? val[this.trackBy] !== option[this.trackBy] : val !== option)
					: [...this.value, option];
				this.$emit('input', newValue);
			} else {
				this.$emit('input', isSelected ? [] : [option]);
			}
		},
		isSelected (option) {
			const optIndex = this.getOptionIndex(option);
			return optIndex > -1;
		},
		getOptionIndex (option) {
			const opt = this.trackBy
				? option[this.trackBy]
				: option;
			return this.valueKeys.indexOf(opt);
		},
	},
};
</script>
