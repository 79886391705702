<template>
	<div :class="[{ 'toggled': isShowingMoreAttributes, 'mb-16' : hasAdditionalAttributes }, 'pb-10 w-100']">
		<div
			class="main-attributes grid-attributes no-gutters"
			:style="columnsCss"
		>
			<item-attribute
				v-for="({ header, text }, index) in mainAttributes"
				class="attribute-icon"
				:key="index"
				:header="header"
				:text="text"
				data-qa="card_block_attribute"
			/>
		</div>
		<div :style="{ position:'relative' }">
			<div class="decreased additional-attributes-wrapper">
				<div
					class="additional-attributes grid-attributes no-gutters"
					:style="columnsCss"
					v-if="isShowingMoreAttributes"
				>
					<item-attribute
						v-for="({ header, text }, index) in additionalAttributes"
						:key="index"
						class="attribute-icon"
						:header="header"
						:text="text"
						data-qa="card_block_additional_attribute"
					/>
				</div>
				<button
					v-if="hasAdditionalAttributes"
					@click.stop="toggleAdditionalAttrs(!isShowingMoreAttributes)"
					@blur="toggleAdditionalAttrs(false)"
					class="container-fluid btn btn-primary btn-view-more py-0"
				>
					{{ additionalInfoButtonText }}
				</button>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'ToggleCardInfo',
	props: {
		attributes: {
			type: Array,
			required: true,
		},
		rows: {
			type: Number,
			required: true,
		},
		columns: {
			type: Number,
			required: false,
			default: 2,
		},
	},
	data () {
		return {
			isShowingMoreAttributes: false,
		};
	},
	computed: {
		filteredAttributes () {
			const filteredAttrs = this.attributes.filter(a => a.text);
			while (filteredAttrs.length % this.columns !== 0) {
				filteredAttrs.push({ header: '', text: '' });
			}
			return filteredAttrs;
		},
		mainAttributes: function () {
			return this.filteredAttributes.slice(0, this.additionalAttributesIndex);
		},
		additionalAttributes: function () {
			return this.filteredAttributes.slice(this.additionalAttributesIndex, this.filteredAttributes.length);
		},
		additionalInfoButtonText: function () {
			return this.isShowingMoreAttributes ? 'Put Away' : 'Show More';
		},
		additionalAttributesIndex () {
			return this.rows * this.columns;
		},
		hasAdditionalAttributes () {
			return this.additionalAttributes.length > 0;
		},
		columnsCss () {
			return `grid-template-columns: repeat(${this.columns}, 1fr)`;
		},
	},
	methods: {
		toggleAdditionalAttrs (value) {
			this.isShowingMoreAttributes = value;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .attribute {
  margin-right: 0;

  i {
    font-size: 20px;
  }
}

.main-attributes {
  z-index: 2;
  position: relative;
  box-shadow: 0 5px 3px -1px rgba($body-bg, 0.1);
}

.decreased {
  margin: 0 10px;
}

.additional-attributes-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: var(--contentBg);

  .additional-attributes {
    box-shadow: 0 3px 6px 4px rgba($body-bg, 0.2);
  }
}

.btn-view-more {
  border-radius: 0 0 2px 2px;
  opacity: 1;
  box-shadow: 0 3px 6px 4px rgba($body-bg, 0.2);
  height: 25px;
}

.grid-attributes {
  display: grid;
  grid-gap: 3px;
}

.attribute-icon {
  fill: var(--bodyColour);
}
</style>
