export default class PetView {
	constructor (
		id,
		ref,
		gender,
		name,
		type,
		breedName,
		birthDate,
		dateOfDeath,
		color,
		purchasePrice,
		microchipNumber,
		photo,
		underwritingStatusId,
		underwritingStatusDescription,
		purchaseAdoptionDate,
		neutered,
		registeredVets,
		hasPreEnrollmentDentalExam,
		totalClaimsCount,
		policyTerms,
		careFlag) {
		this.id = id;
		this.ref = ref;
		this.gender = gender;
		this.name = name;
		this.type = type;
		this.breedName = breedName;
		this.birthDate = birthDate;
		this.dateOfDeath = dateOfDeath;
		this.color = color;
		this.purchasePrice = purchasePrice;
		this.microchipNumber = microchipNumber;
		this.photo = photo;
		this.underwritingStatusId = underwritingStatusId;
		this.underwritingStatusDescription = underwritingStatusDescription;
		this.purchaseAdoptionDate = purchaseAdoptionDate;
		this.neutered = neutered;
		this.registeredVets = registeredVets;
		this.hasPreEnrollmentDentalExam = hasPreEnrollmentDentalExam;
		this.totalClaimsCount = totalClaimsCount;
		this.policyTerms = policyTerms;
		this.careFlag = careFlag;
	}
}
