<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="fluid-container">
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Illness/Injury:
							</div>
							<div class="col text-right">
								{{ details.ailment ? details.ailment : 'No ailment' }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								{{ dateOfLossLabel }}:
							</div>
							<div class="col text-right">
								{{ details.dateofLoss | shortDate }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Treatment Date From:
							</div>
							<div class="col text-right">
								{{ details.treatmentFrom | shortDate }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Treatment Date To:
							</div>
							<div class="col text-right">
								{{ details.treatmentTo | shortDate }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Claim Amount:
							</div>
							<div class="col text-right">
								{{ details.claimAmount | currency(locale) }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Payee:
							</div>
							<div class="col text-right">
								{{ details.paymentTarget }}
							</div>
						</div>
						<div
							v-if="details.vetName"
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
						>
							<div class="col">
								Vet Details:
							</div>
							<div class="col text-right">
								{{ details.vetName }}
							</div>
						</div>
						<div
							v-if="details.vetAddress"
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
						>
							<div class="col text-right">
								{{ details.vetAddress }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Documents Uploaded:
							</div>
							<div class="col text-right">
								{{ details.documentsUploaded }}
							</div>
						</div>
						<div class="row no-gutters list-item--bg mb-10 py-14 px-20">
							<div class="col">
								Pets Date Of Death:
							</div>
							<div class="col text-right">
								{{ dateOfDeath }}
							</div>
						</div>
						<div
							v-if="isHospitalClaim"
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
						>
							<div class="col">
								Continuation:
							</div>
							<div class="col text-right">
								{{ details.continuation }}
							</div>
						</div>
						<div
							v-if="isHospitalClaim"
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
						>
							<div class="col">
								PreAuth/Claim:
							</div>
							<div class="col text-right">
								{{ details.preAuthOrClaim }}
							</div>
						</div>
						<div
							v-if="isHospitalClaim"
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
						>
							<div class="col">
								Condition Type:
							</div>
							<div class="col text-right">
								{{ details.conditionType }}
							</div>
						</div>
						<div
							v-if="isHospitalClaim"
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
						>
							<div class="col">
								Payment Method:
							</div>
							<div class="col text-right">
								{{ details.paymentMethod }}
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { shortDate } from '@commonServices/utils/filters';
import { mapGetters } from 'vuex';
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			details: Object,
		};
	},
	mounted () {
		this.details = this.data[0];
	},
	computed: {
		...mapGetters(['dateOfLossLabel']),
		dateOfDeath () {
			if (this.details.dateOfDeath) {
				return shortDate(this.details.dateOfDeath);
			}

			return 'N/A';
		},
		isHospitalClaim () {
			return (this.title === 'Hospital Claim Submission');
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-content {
  min-width: 600px;
}

.row-icon {
  margin-right: 5px;
  font-size: 17px;
}
</style>
