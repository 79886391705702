export default class MenuItem {
	/**
	 *
	 * @param {String} label How it looks like in menu bar
	 * @param {String} routerLink Where the router should go
	 * @param {String} iconClass Fontawesome icon if that one isn't specified as our own icon
	 * @param {Boolean} isPanelItem  Used to open panel instead of URL
	 */
	constructor (label, routerLink, iconClass, isPanelItem) {
		this.label = label;
		this.routerLink = routerLink;
		this.iconClass = iconClass;
		this.isPanelItem = isPanelItem;
	}
};
