<template>
	<div>
		<aq-input
			:input-class="inputClass"
			:value="currentValue"
			:placeholder="placeholder"
			:disabled="disabled"
			:id="id"
			type="number"
			@input="updateValue"
			ref="input"
		/>
	</div>
</template>

<script>
export default {
	name: 'AqMaxMinNumberInput',
	props: {
		value: {
			type: Number,
			required: false,
		},
		placeholder: {
			type: String,
			required: false,
		},
		inputClass: {
			type: String,
			required: false,
		},
		id: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		maxNumber: {
			type: Number,
			required: false,
			default: Number.MAX_VALUE,
		},
		minNumber: {
			type: Number,
			required: false,
			default: Number.MIN_VALUE,
		},
	},
	data () {
		return {
			currentValue: this.value,
		};
	},
	methods: {
		updateValue (value) {
			if (value === '') { return; }

			value = parseInt(value);

			if (isNaN(value) || value > this.maxNumber) {
				if (this.currentValue === this.maxNumber) {
					this.$refs.input.$forceUpdate();
				} else {
					this.currentValue = this.maxNumber;
				}
			} else if (value < this.minNumber) {
				if (this.currentValue === this.minNumber) {
					this.$refs.input.$forceUpdate();
				} else {
					this.currentValue = this.minNumber;
				}
			} else {
				this.currentValue = value;
			}

			this.$emit('input', this.currentValue);
		},
	},
	watch: {
		value (newValue) {
			this.currentValue = newValue;
		},
	},
};
</script>
