<template>
	<div :class="[{ 'copy': isCopy }, 'enter-claim-view container-fluid']">
		<div class="row no-gutters align-items-center justify-content-between">
			<div class="d-flex align-items-center">
				<div
					data-qa="enterClaimView_icon_enter"
					class="circle mr-8 cursor-default"
				>
					<i class="fas fa-file-invoice cursor-default" />
				</div>
				<span class="font-weight-bold fs-16">Enter Claim</span>
			</div>
			<div
				v-if="isCopy"
				class="d-flex"
			>
				<div
					class="status-info p-6"
					:class="{ 'warning-border': isObjectValueDifferent(claim, claimComparison, 'status')}"
				>
					<span>Status:</span>
					<span class="font-weight-bold">{{ toClaimDisplayStatus(claim.status) }}</span>
				</div>
				<div />
			</div>
		</div>
		<div class="row no-gutters my-10">
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Basic details
			</div>
		</div>
		<div class="p-16">
			<div class="row no-gutters">
				<div class="col">
					<claim-info-term
						data-qa="enterClaimView_infoTerm_payee"
						label="Payee Information"
						:class="{ 'warning-border': !!claimComparison &&
							(isPolicyHolderPayee(claim) !== isPolicyHolderPayee(claimComparison) ||
								isVetPayee(claim) !== isVetPayee(claimComparison))}"
					>
						<template #description>
							<div
								class="d-flex payee-info"
							>
								<aq-checkbox
									:class="[{ 'parent-payee' : !isCopy }, 'round mr-10']"
									:value="isPolicyHolderPayee(claim)"
									disabled
									label="Policy Holder"
								/>
								<aq-checkbox
									:class="[{ 'parent-payee' : !isCopy }, 'round']"
									:value="isVetPayee(claim)"
									disabled
									label="Vet"
								/>
							</div>
						</template>
					</claim-info-term>
				</div>
			</div>
			<div class="row no-gutters my-10">
				<div class="col">
					<claim-info-term
						data-qa="enterClaimView_infoTerm_description"
						label="Description"
						:description="claim.basic.description"
						:class="{ 'warning-border': isObjectValueDifferent(claim.basic, claimComparisonBasic, 'description')}"
					/>
				</div>
			</div>
			<div class="row no-gutters my-10">
				<div class="col mr-10">
					<claim-info-term
						:class="{ 'warning-border': isObjectValueDifferent(claim.basic, claimComparisonBasic, 'dateOfLoss')}"
						data-qa="enterClaimView_infoTerm_dateOfLoss"
						label="Date of Loss"
						:description="claim.basic.dateOfLoss"
					/>
				</div>
				<div class="col mr-10">
					<claim-info-term
						:class="{ 'warning-border': isObjectValueDifferent(claim.basic, claimComparisonBasic, 'dateOfDeath')}"
						data-qa="enterClaimView_infoTerm_dateOfDeath"
						label="Date of Death"
						:description="claim.basic.dateOfDeath"
					/>
				</div>
				<div class="col mr-10">
					<claim-info-term
						:class="{ 'warning-border': isObjectValueDifferent(claim.basic, claimComparisonBasic, 'treatmentStartDate')}"
						data-qa="enterClaimView_infoTerm_treatmentFrom"
						label="Treat From"
						:description="claim.basic.treatmentStartDate"
					/>
				</div>
				<div class="col mr-10">
					<claim-info-term
						:class="{ 'warning-border': isObjectValueDifferent(claim.basic, claimComparisonBasic, 'treatmentEndDate')}"
						data-qa="enterClaimView_infoTerm_treatmentTo"
						label="Treat To"
						:description="claim.basic.treatmentEndDate"
					/>
				</div>
				<div class="col">
					<claim-info-term
						:class="{ 'warning-border': isObjectValueDifferent(claim.basic, claimComparisonBasic, 'amount')}"
						data-qa="enterClaimView_infoTerm_amount"
						label="Amount"
						:description="claim.basic.amount"
					/>
				</div>
			</div>
		</div>
		<div class="row no-gutters my-10">
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Diagnosis
			</div>
		</div>
		<div class="p-16">
			<template v-if="claim.basic.conditions.length">
				<div
					class="row no-gutters my-10"
					v-for="(condition, index) of getConditions(claim)"
					:key="index"
				>
					<div class="col mr-10">
						<claim-info-term
							:class="{ 'warning-border': isObjectValueDifferent(condition, claimComparisonConditions[index], 'parentConditionInfo')}"
							data-qa="enterClaimView_infoTerm_parentFirstCause"
							label="Parent"
							:description="condition.parentConditionInfo"
						/>
					</div>
					<div class="col mr-10">
						<claim-info-term
							:class="{ 'warning-border': isObjectValueDifferent(condition, claimComparisonConditions[index], 'ailment')}"
							data-qa="enterClaimView_infoTerm_firstCause"
							label="Ailment"
							:description="condition.ailment"
						/>
					</div>
					<div class="col mr-10">
						<claim-info-term
							:class="{ 'warning-border': isObjectValueDifferent(condition, claimComparisonConditions[index], 'bodyPart')}"
							data-qa="enterClaimView_infoTerm_bodyPart"
							label="Body Part"
							:description="condition.bodyPart"
						/>
					</div>
					<div class="col">
						<claim-info-term
							:class="{ 'warning-border': isObjectValueDifferent(condition, claimComparisonConditions[index], 'conditionType')}"
							data-qa="enterClaimView_infoTerm_conditionType"
							label="Condition Type"
							:description="condition.conditionType"
						/>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="row no-gutters mb-10">
					<span class="mx-auto font-weight-bold">No Conditions </span>
				</div>
			</template>
		</div>
		<div class="row no-gutters my-10">
			<div class="section-label col fs-18 px-23 py-10 text-white">
				Vet Details
			</div>
		</div>
		<div class="p-16">
			<template v-if="claim.basic.vets.length">
				<div
					class="row no-gutters my-10"
					v-for="(vet, index) of claim.basic.vets"
					:key="index"
				>
					<div class="col">
						<claim-info-term
							:class="{ 'warning-border': isObjectValueDifferent(vet, claimComparisonVets[index], 'practiceName') ||
								isObjectValueDifferent(vet, claimComparisonVets[index], 'address1')}"
							data-qa="enterClaimView_infoTerm_vetInfo"
							label="Search for Vet"
							:description="getVetDescription(vet)"
						/>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="row no-gutters mb-10">
					<span class="mx-auto font-weight-bold">No Vets</span>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import ClaimInfoTerm from '@commonView/ClaimPage/ClaimVersions/ClaimInfoTerm';
import { toClaimDisplayStatus, toFullAddress } from '@commonServices/utils/converter';
import { PayeeType } from '@commonServices/utils/payeeOptions';
import { isObjectValueDifferent } from '@commonServices/utils/general';

export default {
	name: 'EnterClaimView',
	props: {
		isCopy: {
			type: Boolean,
			required: false,
			default: false,
		},
		claim: {
			type: Object,
			required: true,
		},
		claimComparison: {
			type: Object,
			required: false,
		},
	},
	components: {
		ClaimInfoTerm,
	},
	computed: {
		claimComparisonBasic () {
			return this.claimComparison?.basic ?? null;
		},
		claimComparisonConditions () {
			return this.getConditions(this.claimComparison);
		},
		claimComparisonVets () {
			return this.claimComparison?.basic.vets ?? [];
		},
	},
	methods: {
		toClaimDisplayStatus,
		isObjectValueDifferent,
		getVetDescription (vet) {
			if (!vet) {
				return null;
			}
			return `${vet.practiceName} - ${this.toAddress(vet)}`;
		},
		getConditions (claim) {
			if (!claim) {
				return [];
			}
			// vue 2 doesnt support optional chaining in template, should be removed after upgrade to vue 3
			return claim.basic.conditions.map(condition => ({
				ailment: condition.ailment?.firstCause,
				bodyPart: condition.bodyPart?.description,
				conditionType: condition.conditionType?.description,
				parentConditionInfo: condition.parentClaimId !== null ? `${condition.parentConditionAilment.firstCause} - ${condition.parentClaimId}` : 'No Parent',
			}));
		},
		toAddress (value) {
			return toFullAddress(value);
		},
		isPolicyHolderPayee (claim) {
			if (!claim) {
				return false;
			}
			return claim.basic.payeeTypes.includes(PayeeType.PolicyHolder);
		},
		isVetPayee (claim) {
			if (!claim) {
				return false;
			}
			return claim.basic.payeeTypes.includes(PayeeType.Vet);
		},
	},
};
</script>
<style lang="scss" scoped>
  .enter-claim-view {
    padding: 14px 24px;

    .circle {
      background: rgba($primary, 0.2);

      i {
        color: var(--cardIconColour);
      }
    }

    ::v-deep .payee-info {
      .checkbox {
        &.disabled {
          &.parent-payee {
            input:checked + label::after {
              background-color: var(--cardIconColour);
            }
          }
        }
      }
    }

    .status-info {
      border-radius: 3px;
      background: var(--claimInfoTerm);
    }

    .section-label {
      background: $primary;
    }

    &.copy {
      .section-label {
        background: var(--claimVersionsSection);
      }

      .circle {
        background: var(--copyStepIcon);

        i {
          color: var(--bodyColour);
        }
      }

      ::v-deep .claim-info-term {
        background: var(--claimInfoTerm);

        .label {
          color: var(--bodyColour);
        }

        .description {
          color: var(--claimInfoTermText);
        }
      }
    }

    .warning-border {
      border: 2px solid $warning;
      border-radius: 3px;
    }
  }

</style>
