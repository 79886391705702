export default class ZipFileView {
	constructor (
		id,
		fileName,
		createdBy,
		createdDateTime,
		lettersCount,
		size,
	) {
		this.id = id;
		this.fileName = fileName;
		this.createdBy = createdBy;
		this.createdDateTime = createdDateTime;
		this.lettersCount = lettersCount;
		this.size = size;
	}
}
