export default class ParentPetClaim {
	constructor (
		claimId,
		continuationConditionsCount,
		conditionId,
		dateOfLoss,
		dateOfDeath,
		treatmentStart,
		treatmentEnd,
		amount,
		canBeParent,
		ailment,
		conditionType,
		bodyPart,
		formType,
		preExistingConditionId,
		eligibleConditionId,
	) {
		this.claimId = claimId;
		this.continuationConditionsCount = continuationConditionsCount;
		this.conditionId = conditionId;
		this.dateOfLoss = dateOfLoss;
		this.dateOfDeath = dateOfDeath;
		this.treatmentStart = treatmentStart;
		this.treatmentEnd = treatmentEnd;
		this.amount = amount;
		this.canBeParent = canBeParent;
		this.ailment = ailment;
		this.conditionType = conditionType;
		this.bodyPart = bodyPart;
		this.formType = formType;
		this.preExistingConditionId = preExistingConditionId;
		this.eligibleConditionId = eligibleConditionId;
	}
}
