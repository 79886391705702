<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			:title="title"
		>
			<div class="modal-content">
				<div class="p-20">
					<div class="container-fluid scrollbar">
						<div
							class="row no-gutters list-item--bg mb-10 py-14 px-20"
							v-for="(item, index) in data"
							:key="index"
						>
							<div class="col-12">
								<i class="fas fa-flag text-danger mr-10" /> {{ item.description }}
								<aq-claims-list
									v-if="isDuplicateInvoiceReason(item.type) && !!claimIds.length"
									:claim-ids="claimIds"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						@click="onClose"
						type="button"
						class="btn btn-secondary"
					>
						Close
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>

import FraudCheckReason from '@commonServices/models/FraudCheckReason';
import AqClaimsList from '@commonWidgets/AqClaimsList';

export default {
	components: {
		AqClaimsList,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Array,
			required: true,
		},
	},
	methods: {
		onClose () {
			this.$emit('close');
			this.$modal.hide(this.$parent.name);
		},
		isDuplicateInvoiceReason (reason) {
			return reason === FraudCheckReason.DuplicateInvoices;
		},
	},
	computed: {
		claimIds () {
			return this.data.find(r => r.type === FraudCheckReason.DuplicateInvoices)?.comment?.split(',') ?? [];
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollbar {
  max-height: 400px;
  overflow-y: auto;
}
</style>
