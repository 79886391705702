<template>
	<v-popover
		class="d-flex policy-term-progress-bar-popover"
		trigger="hover"
		placement="bottom"
		:popover-class="`tooltip-${policyTerm.progressColor}`"
		:popover-inner-class="['tooltip-inner p-3', `bg-${policyTerm.progressColor}`]"
		popover-arrow-class="tooltip-arrow"
	>
		<div
			class="rounded-2 position-relative"
		>
			<div
				class="rounded-2 animate policy-term-progress-bar"
				:class="[`bg-${policyTerm.progressColor}`, {'selected': selected}]"
				:style="{ maxWidth: `${policyTerm.progress}%` }"
				data-qa="aqProgress_block_bar"
			/>
		</div>

		<template slot="popover">
			<div>
				<div class="text-center text-white text-bold font-20 policy-term-content">
					{{ policyTerm.content }}
				</div>
				<div
					class="additional-info p-4 mt-2"
					v-if="hasAdditionalInfo"
				>
					<div class="d-flex">
						<div
							class="additional-info-item w-100 text-left px-5 py-3 mr-2"
							v-if="policyTerm.product.coInsurance"
						>
							<b>Copay:</b> {{ policyTerm.product.coInsurance.limit }}%
						</div>
						<div
							class="additional-info-item w-100 text-left px-5 py-3"
							v-if="policyTerm.product.fixedExcess"
						>
							<b>{{ this.uiLocaleSettings.deductibleLabel }}:</b> {{ policyTerm.product.fixedExcess.limit | currency(locale) }}
						</div>
					</div>
					<div
						class="additional-info-item text-left px-5 py-3 mt-2"
						v-if="policyTerm.addons.length"
					>
						<span class="text-bold pb-2">Optional Coverage</span>
						<ul class="optional-coverage">
							<li
								class="mt-3"
								v-for="addon of policyTerm.addons"
								:key="addon.product.id"
							>
								- {{ addon.product.name }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</template>
	</v-popover>
</template>

<script>
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';

export default {
	props: {
		selected: {
			type: Boolean,
			require: true,
		},
		policyTerm: {
			type: Object,
			require: true,
		},
	},
	computed: {
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
		hasAdditionalInfo () {
			return this.policyTerm.product.coInsurance || this.policyTerm.product.fixedExcess || this.policyTerm.addons.length;
		},
	},
};
</script>

<style lang="scss" scoped>
  .animate {
    transition: width 0.2s;
    animation: progress 2s 1;
  }

  .policy-term-content {
    min-width: 310px;
  }

  .policy-term-progress-bar {
    height: 11px;
    opacity: 0.66;
  }

  .selected {
    opacity: 1;
  }

  .additional-info{
    background: var(--contentBg);
    color: var(--bodyColour);

    &-item{
      background: var(--attributeBg);
      gap: 20px;
    }

    .optional-coverage{
      list-style-type: none
    }
  }
</style>
