const claimPaymentMethods = Object.freeze({
	Other: 0,
	ACH: 1,
	Check: 2,
	None: 3,
});

const claimPaymentMethodsDisplayMap = Object.freeze({
	[claimPaymentMethods.Other]: 'Other',
	[claimPaymentMethods.ACH]: 'Bacs',
	[claimPaymentMethods.Check]: 'Cheque',
	[claimPaymentMethods.None]: 'None',
});

export {
	claimPaymentMethods,
	claimPaymentMethodsDisplayMap,
};
