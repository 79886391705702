export function updateBreedOnPetType (breeds, petType) {
	if (breeds && petType) {
		return breeds.filter(breed => breed.petType === petType.id);
	}
}

export function updateProductOnBrandSelection (products, brands) {
	if (products && products.length && brands && brands.length) {
		return products.filter((product) => brands.findIndex(brand => product.brandId === brand.id) !== -1);
	}
	return products;
}

export function updateBrandOnProductSelection (brands, products) {
	if (products && products.length && brands && brands.length) {
		return brands.filter((brand) => products.findIndex(product => brand.id === product.brandId) !== -1);
	}
	return brands;
}

export function updateConditionTypeOnAilmentSelection (conditionTypes, ailments, assignArray) {
	if (conditionTypes && conditionTypes.length && ailments && ailments.length && conditionTypes.some(conditionType => !conditionTypeCheck(ailments, conditionType))) {
		assignArray(conditionTypes.filter(conditionType => conditionTypeCheck(ailments, conditionType)));
	}
}

export function updateAilmentOnConditionSelection (ailments, conditionTypes, assignArray) {
	if (ailments && ailments.length && conditionTypes && conditionTypes.length && ailments.some(ailment => !ailmentCheck(ailment, conditionTypes))) {
		assignArray(ailments.filter(ailment => ailmentCheck(ailment, conditionTypes)));
	}
}

export function removeFilter (filterObj, label) {
	for (const filter of Object.values(filterObj)) {
		if (filter.label === label) {
			filter.value = null;
			return;
		}
	}
	for (const dateFilter of Object.values(filterObj.dates)) {
		if (label.startsWith(dateFilter.label)) {
			dateFilter.dateFrom = null;
			dateFilter.dateTo = null;
			dateFilter.type = null;
			return;
		}
	}

	for (const numberFilter of Object.values(filterObj.numbers)) {
		if (label.startsWith(numberFilter.label)) {
			numberFilter.from = null;
			numberFilter.to = null;
			numberFilter.type = null;
			return;
		}
	}
}

function ailmentCheck (ailment, conditionTypes) {
	return ailment.conditionTypes.some(ailmentCondition => conditionTypes.findIndex(conditionType => ailmentCondition.id === conditionType.id) !== -1);
}

function conditionTypeCheck (ailments, conditionType) {
	return ailments.some(ailment => ailment.conditionTypes.findIndex(ailmentCondition => ailmentCondition.id === conditionType.id) !== -1);
}
