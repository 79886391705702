export class DocumentRecognitionResult {
	constructor (customerName, petName, condition, secondCondition, isMultiCondition, totalAmount, veterinaryPracticeName, invoiceNumber, payPolicyHolder, payVeterinaryHospital, items, vendorName, totalTax, totalDiscount, invoiceDate, taxesIncluded, multiPet) {
		this.customerName = customerName;
		this.petName = petName;
		this.condition = condition;
		this.secondCondition = secondCondition;
		this.isMultiCondition = isMultiCondition;
		this.totalAmount = totalAmount;
		this.veterinaryPracticeName = veterinaryPracticeName;
		this.invoiceNumber = invoiceNumber;
		this.payPolicyHolder = payPolicyHolder;
		this.payVeterinaryHospital = payVeterinaryHospital;
		this.items = items;
		this.vendorName = vendorName;
		this.totalTax = totalTax;
		this.totalDiscount = totalDiscount;
		this.invoiceDate = invoiceDate;
		this.taxesIncluded = taxesIncluded;
		this.multiPet = multiPet;
	}
}
