import { formatDate } from '../utils/dateUtils';

const LOCAL_DATE_FORMAT = 'yyyy-MM-dd'; // is used for no convertion date submiting

export default class LocalDate {
	constructor (date) {
		this.date = date;
	}

	toJSON () {
		return this.date == null ? null : formatDate(this.date, LOCAL_DATE_FORMAT);
	}
}
