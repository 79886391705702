<template>
	<aq-sandwich
		header="Related Conditions"
		ref="sandwich"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
	>
		<template slot="content">
			<div class="section-content flex-grow-1">
				<div class="container-fluid h-100 d-flex flex-column">
					<div>
						<aq-ailments
							data-qa="conditionForm_select_ailment"
							v-model="selectedAilment"
							@input="$v.selectedAilment.$touch"
							:is-valid="!$v.selectedAilment.$error"
							:disabled="data.hasRejectedClaims"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="$options.errorMessages"
							:validator="$v"
							property="selectedAilment"
						/>
						<div class="mt-20" />
						<aq-body-parts
							data-qa="conditionForm_select_bodyParts"
							v-model="selectedBodyPart"
							@input="$v.selectedBodyPart.$touch"
							:is-valid="!$v.selectedBodyPart.$error"
							:disabled="data.hasRejectedClaims"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="$options.errorMessages"
							:validator="$v"
							property="selectedBodyPart"
						/>
						<div class="mt-20" />
						<div
							class="mt-4"
							v-if="duplicationError"
						>
							<div class="text-danger">
								{{ duplicationError }}
							</div>
						</div>
						<div class="mt-20" />
						<div class="d-flex justify-content-end">
							<button
								class="btn btn-success"
								type="button"
								data-qa="conditionForm_button_addRelatedCondition"
								:disabled="$v.$invalid"
								@click="onAddRelatedCondition"
							>
								Add
							</button>
						</div>
					</div>
					<div class="mt-30 items-wrapper">
						<div
							v-for="relatedCondition of relatedConditions"
							:key="`${relatedCondition.condition.id}${relatedCondition.bodyPart.id}`"
							class="list-item--bg mt-8 py-20 px-20 position-relative d-flex align-items-center justify-content-between"
						>
							<div
								class="text-truncate"
								v-tooltip="relatedCondition.condition.firstCause "
							>
								{{ relatedCondition.condition.firstCause }}
								<p class="fs-12 font-weight-bold">
									{{ relatedCondition.bodyPart.description }}
								</p>
							</div>
							<button
								type="button"
								class="ml-10 btn btn-row remove-condition"
								:disabled="data.hasRejectedClaims"
								@click="onRemoveCondition(relatedCondition)"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<div class="d-flex text-right">
						<button
							class="btn btn-row mr-10 ml-auto"
							@click="$emit('cancel')"
						>
							Cancel
						</button>
						<button
							class="btn btn-primary"
							@click="onProceed()"
						>
							Proceed
						</button>
					</div>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';

export default {
	name: 'RelatedConditions',
	data () {
		return {
			selectedAilment: null,
			selectedBodyPart: null,
			relatedConditions: this.data.relatedConditions,
			duplicationError: null,
		};
	},
	beforeDestroy () {
		this.onProceed();
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
		duplicationDiagnosisMessage: {
			type: String,
			required: true,
		},
	},
	errorMessages: {
		selectedAilment: {
			required: ValidationMessages.required,
		},
		selectedBodyPart: {
			required: ValidationMessages.required,
		},
	},
	validations () {
		return {
			selectedAilment: {
				required,
			},
			selectedBodyPart: {
				required,
			},
		};
	},
	methods: {
		onProceed () {
			this.$refs.sandwich.onToggle();
			this.$emit('passed', this.relatedConditions);
		},
		onRemoveCondition (relatedCondition) {
			const { condition, bodyPart } = relatedCondition;
			this.relatedConditions = this.relatedConditions.filter(item => !(item.condition.id === condition.id && item.bodyPart.id === bodyPart.id));
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		onAddRelatedCondition () {
			this.duplicationError = null;

			if (!this.selectedAilment || !this.selectedBodyPart) {
				return;
			}

			if (this.data.condition) {
				if (this.selectedAilment.id === this.data.condition.id && this.selectedBodyPart.id === this.data.bodyPart.id) {
					this.duplicationError = this.duplicationDiagnosisMessage;
					return;
				}
			}

			const index = this.relatedConditions.findIndex(rc => rc.condition.id === this.selectedAilment.id && rc.bodyPart.id === this.selectedBodyPart.id);
			if (index >= 0) {
				this.duplicationError = 'Related condition has already been selected';
				return;
			}
			this.relatedConditions.push({ condition: this.selectedAilment, bodyPart: this.selectedBodyPart });
		},
	},
};
</script>

<style lang="scss" scoped>
.items-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
}
</style>
