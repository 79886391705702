<template>
	<div>
		<aq-modal-container
			:title="'Keyboard shortcuts'"
			@close="onClose()"
		>
			<div
				class="info-modal section-content p-20"
			>
				<div class="container-fluid">
					<div
						class="row"
					>
						<div
							class="col-6"
							v-for="(shortcut, shortcutIndex) in shortcuts"
							:key="shortcutIndex"
						>
							<h5>{{ shortcut.category }}</h5>
							<ul class="list-style-none pl-16">
								<li
									v-for="(key, shortcutKeyIndex) in shortcut.keys"
									:key="shortcutKeyIndex"
									class="pb-10 row"
								>
									<div class="col-5">
										{{ key.description }}:&nbsp;
									</div>
									<div class="col-7">
										<span
											v-for="(value, index) in key.values"
											:key="index"
										>
											<code class="bg-light mx-4 px-4 py-2">{{ value }}</code>
											<span v-if="index !== key.values.length - 1">then</span>
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import { shortcutsHelp } from '@commonServices/settings/shortcutsSettings';

export default {
	name: 'AqShortcutsModal',
	data () {
		return {
			shortcuts: [],
		};
	},
	mounted () {
		this.shortcuts = shortcutsHelp;
	},
	methods: {
		onClose () {
			this.$modal.hide('shortcuts-modal');
		},
	},
};
</script>

<style lang="scss" scoped>
  .info-modal {
    width: 768px;

    ul {
      list-style-type: none;
    }

    h5 {
      font-size: 14px;
    }

    code {
      border-radius: 5px;
    }
  }
</style>
